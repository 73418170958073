
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL } from './Constants';
import '../App.css';
import { Link } from 'react-router-dom';
import CopyrightIcon from '@mui/icons-material/Copyright';
import axios from "axios";
import Colors from '../Components/Colors';

function SpFooter() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      
      const menus = [
       // { label: "Home", link: "/" },           
        { label: "About us", link: "/spabout-us" },
        { label: "Contact us", link: "/spcontact-us" } , 
       
    ];

    const menuColumn2 = [
        { label: "Disclaimer", link: "/sp-disclaimer" },
        { label: "Privacy policy", link: "/sp-privacy-policy " },
        { label: "Terms of use", link: "/sp-terms-of-use" },
        
    ];

   

    const menuColumn4 = [
        { label: "Youtube", link: "https://www.youtube.com/" },
        { label: "Instagram", link: "https://www.instagram.com/" },
        { label: "Facebook", link: "https://www.facebook.com/" },
        
    ];

   

    const handleServiceProviderAreaButtonClick = async() => {
     // setSignInPopup(true)
      window.location.href = '/signin'; 
    }

  

   
    return (
        <Grid container sx={{ width:{ md: 'calc(100%-150px)' , sm:  'calc(100%-70px)' , xs:  'calc(100%-32px)'  },
         height:{ md: 'calc(100%-32px)' , sm:  'calc(100%-32px)' , xs: '100%' }, margin: 0 }}>
             <Box  sx={{display:'flex',flexDirection:'column',width:'100%'
             ,background: Colors.secondary, height: '100%',padding:{ md:'16px 75px 16px 75px' , sm:'16px 35px 16px 35px' , xs:'16px 16px 16px 16px'},   }}>
           <Box sx={{display: 'flex', flexDirection:'row', }}>
            <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
            gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%',                     }}>
               <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' }, justifyContent: 'flex-start', alignItems: 'flex-start'
               ,width:'100%' }}>
               {menus.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px",
                                     lineHeight: "20px",  fontWeight: "400", 
                                     '&:hover': {
                                      fontWeight: "700", 
                                    },                                  
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
               <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' },  justifyContent: 'flex-start', 
               alignItems: 'flex-start' ,width:'100%', }}>
               {menuColumn2.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px", 
                                    lineHeight: "20px",   fontWeight: "400",'&:hover': {   fontWeight: "700", 
                                    },                                  
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 
             
               <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' },  justifyContent: 'flex-start', alignItems: 'flex-start',
               width:'100%',}}>
               {menuColumn4.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                               // to={menu.link}
                                sx={{
                                    color:Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px", lineHeight: "20px",
                                    fontWeight: "400", '&:hover': {
                                      fontWeight: "700", 
                                    },                                    
                                }}>
                                {menu.label}
                            </Button>
                        ))}
               </Box> 

               <Box  sx={{display:{ md: 'flex', sm: 'flex', xs:'none' },flexDirection:'column',gap:'10px', 
                justifyContent: 'center', alignItems: 'center',height:'100%',
               width:'100%', }}>
               
                            {/* <Button
                              
                                sx={{color: Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "16px",
                                border:'1px solid ',borderColor:Colors.primary,lineHeight: "30px",fontWeight: "400",
                                width:'100%',height:'40px',  borderRadius:'28px', background:Colors.primary ,
                                '&:hover': {
                                  background: Colors.Hovering, border:'1px solid ',borderColor:Colors.Hovering,
                                },                                
                                }}   onClick={handleServiceProviderAreaButtonClick}>
                                Service provider area
                            </Button> */}
                       
               </Box> 
               
            </Box>

            <Box  sx={{display:{ md: 'none', sm: 'none' , xs: 'flex'  },flexDirection:'column',gap:'10px',
               width:'100%', paddingTop:'8px'}}>
               
                            {/* <Button
                              
                                sx={{color: Colors.white,textTransform: "none", fontFamily: "Roboto", fontSize: "12px",
                                border:'1px solid ',borderColor:Colors.primary,lineHeight: "30px",fontWeight: "400",
                                width:'100%',height:'40px',  borderRadius:'28px', background:Colors.primary ,
                                '&:hover': {
                                  background: Colors.Hovering, border:'1px solid ',borderColor:Colors.Hovering,
                                },                                
                                }}   onClick={handleServiceProviderAreaButtonClick}>
                                Service provider area
                            </Button> */}
                       
               </Box> 
               </Box>
            <Box sx={{display:'flex',flexDirection:'row',gap:'5px', justifyContent: 'center', alignItems: 'center'
               ,width:'100%',paddingTop:'20px'}}>
                <CopyrightIcon sx={{color:Colors.white,fontSize: '9px',lineHeight: '18px', }}></CopyrightIcon>
                <Typography sx={{
              fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',  color:Colors.white,
            }}>2023-2025</Typography>
 <Typography sx={{
              fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',  color:Colors.white,
            }}>all rights reserved by</Typography>
          <Link
  to="https://www.varsha-info.com/"
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Recommended for security
  style={{
    textDecoration: 'none', // Remove default underline
    color: Colors.white, fontFamily: "Roboto", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',
    transition: 'text-decoration 0.2s',  textUnderlineOffset: "5px",
  }}
  onMouseEnter={(e) => {
    e.target.style.textDecoration = 'underline'; // Add underline on hover
  }}
  onMouseLeave={(e) => {
    e.target.style.textDecoration = 'none'; // Remove underline on mouse leave
  }}
>
  www.varsha-info.com
</Link>







               </Box>
               </Box> 
       


        </Grid>
        );

    }
    

    export default SpFooter;