import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

function ListComponent({ items }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={index}
          onClick={() => handleItemClick(index)}
          sx={{
            backgroundColor: selectedIndex === index ? "#016D87" : "inherit",
            color:selectedIndex === index ? "#ffffff" : "inherit",
            borderRadius:'8px', 
            "&:hover": {
              backgroundColor: "#016D87",
              borderRadius:'8px',
              color:'#FFFFFF'
            },
          }}
        >
          {item}
        </ListItem>
      ))}
    </List>
  );
}

export default ListComponent;
