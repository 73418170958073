import React from 'react';
import { Typography, List, ListItem, ListItemText, Box, Grid } from '@mui/material';
import Colors from '../Components/Colors';
//import UserFooter from '../Components/UserFooter';
//import UserHeader from '../Components/UserHeader';
import SpHeader from '../Components/SpHeader';
import SpFooter from '../Components/SpFooter';
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from './Constants';

function PrivacyPolicySp() {
    const [isCompleteProfile, setIsCompleteProfile] = useState(true);   
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);


    async function fetchServiceProviderDetails() {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
        
          if (response.data.success) {
        
            console.log("service-details", response.data.data.serviceProvider.service_details)
         
            const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
            setServiceProviderDtls(response.data.data.serviceProvider)
            setServiceDetails(response.data.data.serviceProvider.service_details)
            setMessageDetails(
            response.data.data.serviceProvider.message_details.sort((a, b) => b.message_id - a.message_id)
          );
            if (response.data.data.serviceProvider.service_details.length === 0) {
              setIsCompleteProfile(false)
            }
            const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
              if (message.read === 'Unread') {
                return acc + 1;
              }
              return acc;
            }, 0);
            setUnreadMessageCount(unreadCount);      
         
          } else {
            console.error('Error:', response.data.error);
          }
    
    
    
        } catch (error) {
          console.error('Error:', error.message);
        }
      }
      useEffect(() => {
        fetchServiceProviderDetails();
      }, [messageDetails])


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <SpHeader isCompleteProfile = {isCompleteProfile} 
                                serviceProviderDtls={serviceProviderDtls}
                                unreadMessageCount={unreadMessageCount} serviceDetails={serviceDetails}></SpHeader>
        </Box>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
         alignItems: 'center',paddingTop: '2.5%', paddingBottom: '2.5%', }}>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', background: Colors.grey_20
            }}>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                    maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', gap: '24px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '26px', xs: '22px' }, lineHeight: '24px', fontWeight: 700,
                        color: Colors.black_80,
                    }}>
                        Privacy Policy
                    </Typography>

                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '24px', xs: '20px' }, lineHeight: '24px',
                        fontWeight: 700, color: Colors.black_80,
                    }}>
                        Last Updated: May 15, 2024
                    </Typography>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                        gap: '16px', justifyContent: 'center'
                    }}>
                       
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                           Introduction
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80, gap: '16px'
                        }}>
                             Welcome to BroHandy. This privacy statement explains the types of information we collect from our users and how it is used. We value your privacy and strive to protect your personal information.
                           
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                           Definitions
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                            <strong>Service:</strong> The service provided by BroHandy as described in the relative terms (if available) and on this platform.<br />
                            <strong>Public user:</strong> Any individual or entity that uses our website to search for service providers.<br />
                            <strong>Service provider:</strong> Any individual or entity that uses our website to post information about their services.
                        </Typography>
                       
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Public users
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             <strong>Information collection and use: </strong> As a public user, you can browse service providers without the need to register or provide personal information. <br />
                             <strong>Data collected: </strong> We automatically collect data related to your IP address, geographical location, the time of your visit, and your activities on our site. This information is used solely to improve the functionality and performance of BroHandy and to enhance user experience.<br />
                             <strong>Use of data: </strong> The data collected is used for analytics, site optimization, and to ensure the security of our website. We do not share this information with any third parties except for compliance with legal obligations or to protect our rights and those of our users.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Service providers
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             <strong>Information collection and use: </strong> As a service provider, you are required to upload your business contact details to facilitate connections with potential clients.<br />
                             <strong>Data collected: </strong> Similar to public users, we collect your IP address, geographical location, time of access, and activities on our site. Additionally, we collect business-related contact information you provide, such as your business name, address, and contact number.<br />
                             <strong>Use of data: </strong> The information you provide is used to create and manage your profile on BroHandy. Your business contact details are displayed to public users as part of your profile. We also use collected data for analytics and site management purposes. We do not share personal information with third parties except where required by law or to ensure the safety and security of our platform.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            General provisions
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             <strong>Security: </strong> While we do not collect personal information that is sensitive in nature, we employ industry-standard measures to protect the data we do collect.<br />
                             <strong>Data retention: </strong> We retain collected information for as long as it is necessary to provide our services effectively, and thereafter for analytical and recordkeeping purposes.<br />
                             <strong>Changes to this privacy statement: </strong> We may update this privacy statement from time to time.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Contact us
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize:  '16px' , lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80, textAlign: 'left'
                        }}>
                          If you have any questions about this privacy statement or our treatment of the information you provide us, please write to us by email at <a href="mailto:contact@brohandy.com">contact@brohandy.com</a>.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
        <SpFooter></SpFooter>
        </Grid>
    );
}

export default PrivacyPolicySp;
