import { Box, Button, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from './Constants';
import TypographyComponent from './TypographyComponent';
import TextFieldComponent from './TextFieldComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Colors from "./Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { TableSortLabel } from '@mui/material';



function AdminCat({ updateTrigger }) {

  const token = localStorage.getItem('adminToken');
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [editId, setEditId] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [addingCategory, setAddingCategory] = useState(false);
  const [newCategoryInput, setNewCategoryInput] = useState('');
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('category');

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    // Code to fetch or update data goes here
    console.log('AdminCat component updated');
  }, [updateTrigger]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const sortedCategories = categoryDetails.sort((a, b) => {
    if (orderBy === 'category') {
      if (order === 'asc') {
        return a.service_category.localeCompare(b.service_category);
      } else {
        return b.service_category.localeCompare(a.service_category);
      }
    }
    return 0;
  });
  const handleDeleteClick = (categoryId) => {
    setCategoryToDelete(categoryId);
    setOpenConfirmDialog(true);
  };
  const handleConfirmDelete = async () => {
    try {
      const response = await axios.post(`${API_URL}/admin_delete_category`, {
        token,
        category_id: categoryToDelete
      });

      if (response.data.success) {
        setCategoryDetails(categoryDetails.filter(cat => cat.service_category_id !== categoryToDelete));
        setOpenConfirmDialog(false);
      } else {
        console.error('Error:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };


  const handleSaveClick = async (category) => {
    console.log("category", category);
    if (!newCategoryName || newCategoryName.trim() === '') {
      console.error('Category name cannot be empty.');
      return; // Prevent the API call if the input is null or empty
    }
    try {
      const response = await axios.post(`${API_URL}/admin_update_category`, {
        token,
        category_id: category.service_category_id,
        category_name: newCategoryName
      });

      if (response.data.success) {
        fetchAllCategories();
        showSnackbar('Category updated successfully!');
        // Update the local state with the new category name
        // setCategoryDetails((prevDetails) =>
        //   prevDetails.map((cat) =>
        //     cat.service_category_id === category.service_category_id
        //       ? { ...cat, service_category: newCategoryName }
        //       : cat
        //   )
        // );

        setEditId(null); // Exit edit mode
      } else {
        console.error('Error:', response.data.error);
        showSnackbar('Error updating category.', 'error');
      }
    } catch (error) {
      console.error('Error:', error.message);
      showSnackbar('Error updating category.', 'error');
    }
  };
  const handleClosePopUp = () => {
    setOpenPopUp(false)

  };
  const handleCancelClick = () => {
    setEditId(null);
    setNewCategoryName('');
  };
  const handleEditClick = (category) => {
    setAddingCategory(false)
    setEditId(category.service_category_id);
    setNewCategoryName(category.service_category);
  };

  useEffect(() => {
    fetchAllCategories();
  }, [])

  async function fetchAllCategories() {
    try {
      const response = await axios.post(`${API_URL}/admin_get_all_categories`, { token });
      console.log(response.data.data)
      if (response.data.success) {
        setCategoryDetails(response.data.data)
      } else {
        console.error('Error:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }
  const handleAddClick = () => {
    setAddingCategory(true);
    setEditId(null);
    setNewCategoryName('');
  };

  const handleAddSaveClick = async () => {
    console.log("newCategoryInput", newCategoryInput)
    if (!newCategoryInput || newCategoryInput.trim() === '') {
      console.error('Category name cannot be empty.');
      return; // Prevent the API call if the input is null or empty
    }
    try {
      const response = await axios.post(`${API_URL}/admin_add_category`, {
        token,
        category_name: newCategoryInput
      });

      if (response.data.success) {
        // setCategoryDetails([...categoryDetails, response.data.data]);
        fetchAllCategories();
        setAddingCategory(false);
        setNewCategoryInput('');
        showSnackbar('Category added successfully!');
      } else {
        console.error('Error:', response.data.error);
        showSnackbar('Error adding category.', 'error');
      }
    } catch (error) {
      console.error('Error:', error.message);
      showSnackbar('Error adding category.', 'error');
    }
  };

  const handleAddCancelClick = () => {
    setAddingCategory(false);
    setNewCategoryInput('');
  };
  const handleStatusToggle = async (category) => {
    const newStatus = category.status === 'Active' ? 'Inactive' : 'Active';
    try {
      const response = await axios.post(`${API_URL}/admin_update_category_status`, {
        token,
        category_id: category.service_category_id,
        status: newStatus
      });

      if (response.data.success) {
        setCategoryDetails((prevDetails) =>
          prevDetails.map((cat) =>
            cat.service_category_id === category.service_category_id
              ? { ...cat, status: newStatus }
              : cat
          )
        );
      } else {
        console.error('Error:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TypographyComponent variant="h6">Category Management</TypographyComponent>
        <Button variant="contained" color="primary" onClick={handleAddClick}>Add Category</Button>
      </Box>
      {addingCategory && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextFieldComponent
            label="New Category Name"
            value={newCategoryInput}
            onChange={(e) => setNewCategoryInput(e.target.value)}
            sx={{ mr: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleAddSaveClick}>Save</Button>
          <Button variant="contained" color="secondary" onClick={handleAddCancelClick} sx={{ ml: 1 }}>Cancel</Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category ID</TableCell>
              
              <TableCell><TableSortLabel
                active={orderBy === 'category'}
                direction={orderBy === 'category' ? order : 'asc'}
                onClick={() => handleSortRequest('category')}
              >
                Category
              </TableSortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCategories.map((row, index) => (
              <TableRow key={row.service_category_id} sx={{ bgcolor: index % 2 === 0 ? 'background.paper' : '#f9f9f9' }}>
                <TableCell>{row.service_category_id}</TableCell>
                <TableCell>
                  {editId === row.service_category_id ? (
                    <TextFieldComponent
                      value={newCategoryName}
                      onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                  ) : (
                    row.service_category
                  )}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={row.status === 'Active'}
                    onChange={() => handleStatusToggle(row)}

                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: '8px' }}>
                    {editId === row.service_category_id ? (
                      <>
                        <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                        <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button variant="contained" color="primary" onClick={() => handleEditClick(row)}>Edit</Button>
                        {/* <Button variant="contained" color="error" onClick={() => handleDeleteClick(row.service_category_id)}>Delete</Button> */}
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <TypographyComponent>Are you sure you want to delete this category?</TypographyComponent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>


      {openPopUp && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
          }}
        >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton sx={{
                width: '36px', height: '36px',

              }} onClick={handleClosePopUp} >
                <CancelIcon sx={{
                  width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                    color: Colors.close,
                  },
                }} ></CancelIcon>
              </IconButton>
            </Box>

            <Box
              sx={{

                width: { md: '450px', sm: '400px', xs: '85%' },
                // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                display: 'flex',
                height: 'fit-content',
                backgroundColor: '#fff',
                border: '1px solid #E6E6E6',
                borderRadius: '28px ',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',
                zIndex: 3,
                padding: '35px',
                alignItems: 'center',
                overflow: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                  width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#7f7f7f',
                  borderRadius: '28px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#e0e0e0',
                  borderRadius: '28px',
                },
              }}
            >

              <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

              }}>
                <Box sx={{
                  display: 'flex', flexDirection: 'column',
                  justifyContent: 'flex-start', gap: '22px',
                  alignItems: 'flex-start', width: '100%'
                }}>

                  <Box sx={{
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'flex-start', gap: '8px',
                    alignItems: 'flex-start', width: '100%',
                  }}>
                    <TypographyComponent sx={{ fontWeight: 600, fontFamily: '24px' }}>
                      Category table updated
                    </TypographyComponent>


                  </Box>


                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AdminCat