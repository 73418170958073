import { Box, Container, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import { useState } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from "axios";
import { API_URL } from './Constants';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Colors from '../Components/Colors';
import mainImage from '../Images/mainImage.png'
import mainBg from '../Images/mainBg.png'
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";


function PasswordRecovery() {
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
   
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);

  // const token =localStorage.getItem('token');
//   const queryParams = new URLSearchParams(window.location.search);
//   const token=queryParams.get('service_provider');
  
  const token = localStorage.getItem('token');

  const handleSubmit = async(event) => {
    event.preventDefault();

    if (newPassword.trim() === '' || confirmPassword.trim() === '') {
     
     
      if (newPassword.trim() === "") {
        setNewPasswordError("Please enter new password");
       
      }
      else {
        setNewPasswordError("");
      }
      if (confirmPassword.trim() === "") {
        setConfirmPasswordError("Please enter confirm password");
       
      }
      else {
        setConfirmPasswordError("");
      }
      return;
    }
    if (newPassword !== confirmPassword) {
        setPasswordError("New password and confirm password do not match.");
        return;
      }
      try {
        const phone=JSON.parse(localStorage.getItem('phone'));
        const country_code=JSON.parse(localStorage.getItem('country_code'));
       // console.log("",phone,country_code)
        const response = await axios.post(`${API_URL}/create-new-password`, { phone,country_code,newPassword });
       
        if (response.data.success===true) {
            setChangePasswordPopUp(true)
            setPasswordError(null);
            setNewPassword('');
          }
          if (response.data.success===false) {
          //  console.log("response message",response.data.message)
            setPasswordError(response.data.message)
          }

      }
      catch (error) {
        console.log('Error updating password. Please try again later.');
      }
    
  };

 
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setNewPasswordError("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError("");
  };


const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
};

const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
};

const handlechangePasswordPopUpClose=() =>{
  window.location.href = `/signin`;
}

  return (
    <>
        <UserHeader></UserHeader>
    <Stack direction={{xs:"column",sm:"row",md:"row"}} height='100vh' spacing={2} justifyContent="space-between">
    <Box flex={2} p={{ xs: '5%', sm: '5%', md: '5%' }} sx={{
                backgroundImage: `url(${mainBg})`, height: 'auto',
                backgroundSize: 'cover', position: 'relative',
            }}>
                <Box sx={{ flex: 1 }}>
                    <TypographyComponent sx={{ fontSize: { xs: '26px', sm: '42px', md: '60px' }, color: Colors.white, fontWeight: 800 }}>Welcome</TypographyComponent>
                    <TypographyComponent sx={{ fontSize: { xs: '8px', sm: '10px', md: '14px' }, color: Colors.white, fontWeight: 400 }}>
                        Find the help you need, right in your neighborhood
                    </TypographyComponent>
                </Box>
                <Box sx={{
                    flex: 1,
                    width: { xs: '50%', sm: '100%', md: '100%' },
                    maxWidth: '100%',
                    // left:'50%',
                    position: { xs: 'absolute', sm: 'absolute', md: 'absolute' },
                    transform: { xs: 'translateY(30%) translateX(36%)', sm: 'translateX(20%)', md: 'translateX(20%)' },
                    top: { xs: '23%', sm: '30%', md: '40%' }, height: { xs: 'max(100%,190px)', sm: '300px', md: '400px', }
                }}>
                    <img src={mainImage} alt="Main" style={{ width: '100%' }} />
                </Box>


            </Box>
    <Box flex={4} p={{ xs: '10%', sm: '10%', md: '15%' }} sx={{display:'flex',flexDirection:'column',gap: '48px'}}>
        <Box sx={{ gap: '8px' }}>
            <TypographyComponent sx={{ fontSize: {xs:'20px',sm:'24px',md:'32px'}, color: '#5B5555', }}>Create new  password</TypographyComponent>
            <TypographyComponent sx={{ fontSize: {xs:'10px',sm:'14px',md:'16px'}, color: '#454545', }}>Create strong and secure password that protect your account.</TypographyComponent>
        </Box>
        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>

            <Box sx={{ gap: '8px' }}>
                <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>New Password*</TypographyComponent>
                <TextFieldComponent placeholder="Enter your password"
                    onChange={handleNewPasswordChange} 
                    error={!!passwordError || newPasswordError}
                    id='new_pass'
                    type={showNewPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleNewPasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    color="primary">
                                    {showNewPassword ? (
                                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextFieldComponent>
                {newPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{newPasswordError}</TypographyComponent>}
            </Box>
            <Box sx={{ gap: '8px' }}>
                <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Confirm Password*</TypographyComponent>
                <TextFieldComponent placeholder="Enter your password"
                    onChange={handleConfirmPasswordChange} error={!!passwordError || confirmPasswordError}
                    id='confirm_pass'
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleConfirmPasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    color="primary">
                                    {showConfirmPassword ? (
                                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextFieldComponent>
                {confirmPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{confirmPasswordError}</TypographyComponent>}
            </Box>
            <Box sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}> 
            {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>
                    {passwordError}</TypographyComponent>}
                    </Box>
            <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
                <ButtonComponent onClick={handleSubmit}>Save password</ButtonComponent>
              
            </Box>
            {/* {changePasswordPopUp &&
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '2',
          
                  }}>
                       <Box sx={{width:'100%', height:'100%',gap:'5px',
                               justifyContent:'center',alignItems:'center', position: 'fixed',
                                 display:'flex',flexDirection:'column', }}>
                               <Box sx={{width:{ md: '375px', sm: '380px', xs: '85%' }, height:'36px',
                               justifyContent:'right',alignItems:'right', display:'flex',}}>
                              <IconButton onClick={handlechangePasswordPopUpClose} sx={{
                         width: '36px', height: '36px',
                        
                      }} >
                        <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close}} ></CancelIcon>
                      </IconButton>
                      </Box>
                    <Box
                      sx={{
                      
                        width: { md: '375px', sm: '380px', xs: '85%' },
                        height: 'fit-content',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '28px ',
                        boxShadow: 'none',
                        boxSizing: 'border-box',
                        zIndex: '3',
                        padding: '35px',
                        alignItems: 'center',
          
                      }}
                    >
                       
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                alignContent: 'center', width: '100%'
                            }}>
                                <CheckCircleRoundedIcon sx={{ color: '#0BEA14', width: '45px', height: '45px' }} />
                               
                            </Box>

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                alignContent: 'center', width: '100%'
                            }}>

                                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 700, color: '#313131' }}>
                                    Password changed!
                                </TypographyComponent>
                                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 400, color: '#1a1a1a' }}>
                                    Your password  has been changed successfully!
                                </TypographyComponent>

                            </Box>




                        </Box>
                    </Box>
                </Box>} */}


                {changePasswordPopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}>
             <Box sx={{width:'100%', height:'100%',gap:'5px',
                     justifyContent:'center',alignItems:'center', position: 'fixed',
                       display:'flex',flexDirection:'column', }}>
                     <Box sx={{width:{ md: '375px', sm: '380px', xs: '85%' }, height:'36px',
                     justifyContent:'right',alignItems:'right', display:'flex',}}>
                    <IconButton onClick={handlechangePasswordPopUpClose} sx={{
               width: '36px', height: '36px',
              
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close}} ></CancelIcon>
            </IconButton>
            </Box>
          <Box
            sx={{
            
              width: { md: '375px', sm: '380px', xs: '85%' },
              height: 'fit-content',
              backgroundColor: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '28px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '28px 36px',gap:'16px',
              alignItems: 'center',
            display:'flex', flexDirection:'column',
            }}
          >
           
           <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                alignContent: 'center', width: '100%'}}>
                   <CheckCircleRoundedIcon sx={{ color: '#0BEA14', width: '45px', height: '45px' }} />
                               
                            </Box>

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                alignContent: 'center', width: '100%',gap:'8px'
                            }}>

                                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 700, color: '#313131' }}>
                                    Password changed!
                                </TypographyComponent>
                                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 400, color: '#1a1a1a' }}>
                                    Your password  has been changed successfully!
                                </TypographyComponent>

                            </Box>
          </Box>
          </Box>
        </Box>}


        </Box>
    </Box>

</Stack>
<UserFooter></UserFooter>
</>
  )
}
export default PasswordRecovery;