import React, { useState } from "react";
import Cookies from "js-cookie";
import ButtonComponent from "./ButtonComponent";
import { Box, IconButton, Grid } from "@mui/material";
import TypographyComponent from "./TypographyComponent";
import Colors from "./Colors";
import Button from "@mui/material/Button";
import CancelIcon from '@mui/icons-material/Cancel';
function CookiesConsent(props) {
const {isCookie, setIsCookie} = props
  const [isCookieSet, setCookie] = useState(Cookies.get("cookieConsent"));
  const [isCookieSettings, setIsCookieSettings] = useState(false)
  // Function to handle accepting cookies
  const handleAcceptCookies = () => {
    Cookies.set("cookieConsent", true);
    setCookie(true);
  };

  // Function to handle rejecting cookies
  const handleRejectCookies = () => {
    Cookies.remove("cookieConsent");
    setCookie(false);
  };

  const handleCookiesSettings = () => {    
    setIsCookie(false);
    setIsCookieSettings(true)
  };

  const handleCloseIsCookiesSettings = () => {    
    setIsCookie(true);
    setIsCookieSettings(false)
  };

  

  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
    <Box sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', background:Colors.quinary}}>
      <Box sx={{width:'100%', height:'100%',maxWidth:'94%', maxHeight:'90%', display:'flex', justifyContent:'left', alignItems:'left',flexDirection:'column',
    gap:'24px',paddingTop:'24px' }}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '18px', color: Colors.black_80, textAlign:'left'}}>
      Cookies consent
        </TypographyComponent >
        <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_80, textAlign:'left'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore 
        </TypographyComponent>
        <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',  gap:'24px'
        ,marginBottom:'24px' , }}>
        <Box sx={{width:'65%', height:'100%',display:'flex', flexDirection:'row',  gap:'24px',justifyContent:'left', alignItems:'center', }}>
       
        <Button onClick={handleCookiesSettings} variant="contained"          
            sx={{boxShadow: 'none',width:'28%', height:{ md:'48px' , sm: '48px', xs: '38px'  }, backgroundColor:Colors.quinary,
            borderRadius:'28px',color:Colors.black_80,textTransform: 'none',fontFamily: 'Roboto', border:'1px solid', borderColor:Colors.black_80,
            fontSize:'16px', fontWeight:'400',
            '&:hover': {
                background: Colors.white ,textTransform: 'none',color: Colors.Hovering,boxShadow: 'none',
                border:'1px solid', borderColor:Colors.Hovering
              },
              
            }} > 
           Cookies settings
         </Button>
        </Box>
        <Box sx={{width:'35%', height:'100%',display:'flex', flexDirection:'row',  gap:'24px',justifyContent:'right', alignItems:'right', }}>
        <ButtonComponent onClick={handleRejectCookies}>Disable all cookies</ButtonComponent>
        <ButtonComponent onClick={handleAcceptCookies} >Allow all cookies</ButtonComponent>
        </Box>
        </Box>
      </Box> 
    </Box>
    
    </Grid>
  );
}

export default CookiesConsent;