import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Colors from "./Colors";
import bg1 from '../Images/bg1.jpg';
import bg2 from '../Images/bg2.jpg';
import bg3 from '../Images/bg3.jpg';
import bg4 from '../Images/bg4.jpg';
import bg5 from '../Images/bg5.jpg';
import TypographyComponent from './TypographyComponent';

function BusinessCardBack({ serviceDetailsDataToShow, serviceProviderDtls, isEditEnable }) {
  const CardbgColor = serviceProviderDtls.card_color;

  // Define background images for different card colors
  let CardbgImage;
  switch (CardbgColor) {
    case 'b1':
      CardbgImage = bg1;
      break;
    case 'b2':
      CardbgImage = bg2;
      break;
    case 'b3':
      CardbgImage = bg3;
      break;
    case 'b4':
      CardbgImage = bg4;
      break;
    case 'b5':
      CardbgImage = bg5;
      break;
    default:
      CardbgImage = bg1; // Default image if color doesn't match any case
  }

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEditIconClick = async () => {
    localStorage.setItem('editservice', 'true');
    localStorage.removeItem('addservice');
    window.location.href = '/add-service';
  };

  const selectedService = serviceDetailsDataToShow[selectedTab] || {};

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        backgroundImage: `url(${CardbgImage})`,
        gap: '20px',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '90%',
          paddingTop: '3%',
          paddingBottom: '5%',
          gap: '16px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'stretch',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="service tabs"
            TabIndicatorProps={{
              style: { backgroundColor: Colors.white_80 }, // Customize the underline color here
            }}
            sx={{           
              '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {
              padding: 0, minWidth:'fit-content',paddingRight: '8px',paddingLeft:'8px'
               },
               '.css-ptiqhd-MuiSvgIcon-root': {
                   color: Colors.white_80
               },
               '.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root': {
                width:'16px'
               }
              }}
          >
            {serviceDetailsDataToShow.map((service, index) => (
              <Tab
                key={service.services_id}
                label={
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 700,
                      color: Colors.white_80,
                      cursor: 'pointer',
                      minWidth: 'fit-content',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textTransform: 'none',
                     
                    }}
                  >
                    {console.log(service.services)}
                    {/* {service.services || 'New service'} */}
                    {service.services.split(' ')[0]} {service.services.split(' ').length > 1 ? '...' : ''} 
                    {service.services.length === 1 ? 'new service' : ''}
                  </Typography>
                }
              />
            ))}
          </Tabs>

          {isEditEnable && (
            <EditOutlinedIcon
              sx={{ cursor: 'pointer', color: Colors.white, paddingTop:'16px' }}
              onClick={handleEditIconClick}
            />
          )}
        </Box>

        {/* Display service details based on selectedTab */}
        {selectedService.services === '' || selectedService.services ===  undefined && (
          <Box
            sx={{
              width: '100%',
              height: '100%',minHeight: '125px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{ color: Colors.white, fontSize: '14px' }}
            >
              Please add your services to find your customers
            </Typography>
          </Box>
        )}

        {selectedService && (
          <Box sx={{ width: 'calc(100%)', height: '100%', display: 'flex', flexDirection: 'column', gap: { md: '12px', sm: '12px', xs: '10px' },  maxWidth: '90%', }} >

          <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '25px' }} >
          {selectedService.type_details && (
              <Box sx={{
                minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                gap: '2px',
              }} >
                <TypographyComponent sx={{ fontWeight: 700, fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.white_80, }}>
                  Type of service
                </TypographyComponent>
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: { md: '12px', sm: '12px', xs: '10px' }, color: Colors.white,
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                }}> 
                 {selectedService.type_details.map(item => item.types).join(', ')}
                </TypographyComponent>
              </Box>)}

              {selectedService.certifications && (
              <Box sx={{
                minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                gap: '2px',
              }} >
                <TypographyComponent sx={{ fontWeight: 700, fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.white_80, }}>
                  Certifications
                </TypographyComponent>
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: { md: '12px', sm: '12px', xs: '10px' }, color: Colors.white, overflow: 'hidden',
                  textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                }}>
                  {selectedService.certifications}
                </TypographyComponent>
              </Box>)}

              {selectedService.license && (
              <Box sx={{ minWidth: '28%', maxWidth: '28%', height: '100%', display: 'flex', flexDirection: 'column', gap: '2px', }} >
                <TypographyComponent sx={{ fontWeight: 700, fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.white_80, }}>
                  License No
                </TypographyComponent>
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: { md: '12px', sm: '12px', xs: '10px' }, color: Colors.white,
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                }}>
                  {selectedService.license}
                </TypographyComponent>
              </Box>)}

          </Box>
          {(selectedService.franchise || selectedService.office_address) && (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '25px' }} >
              {selectedService.franchise && (
                <Box sx={{
                  minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                  gap: '2px',
                }} >
                  <TypographyComponent sx={{ fontWeight: 700, fontSize:{ md: '14px', sm: '14px', xs: '12px' }, color: Colors.white_80, }}>
                    Franchise
                  </TypographyComponent>
                  <TypographyComponent sx={{
                    fontWeight: 400, fontSize: { md: '12px', sm: '12px', xs: '10px' }, color: Colors.white, overflow: 'hidden',
                    textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                  }}>
                     {selectedService.franchise}
                  </TypographyComponent>
                </Box>)}
                {selectedService.office_address && (
                <Box sx={{
                  minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                  gap: '2px',
                }} >
                  <TypographyComponent sx={{ fontWeight: 700, fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.white_80, }}>
                    Office address
                  </TypographyComponent>
                  <TypographyComponent sx={{
                    fontWeight: 400, fontSize: { md: '12px', sm: '12px', xs: '10px' }, color: Colors.white, overflow: 'hidden',
                    textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                  }}>
                    {selectedService.office_address}
                  </TypographyComponent>
                </Box>)}



            </Box>)}
            {selectedService.about_us && (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '2px', }} >

              <TypographyComponent sx={{ fontWeight: 700, fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.white_80, }}>
                About
              </TypographyComponent>
            
              <TypographyComponent sx={{
                fontWeight: 400, fontSize: { md: '12px', sm: '12px', xs: '10px' }, color: Colors.white,
                ...(selectedService.franchise || selectedService.office_address ? {
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                } : { display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden',
                  WebkitLineClamp: 5, whiteSpace: 'normal' })}}>
                {selectedService.about_us}
              </TypographyComponent>


            </Box>)}

        </Box>)}
      </Box>
    </Box>
  );
}

export default BusinessCardBack;
