import { Box, Grid, InputBase, Button, IconButton, List, ListItem } from "@mui/material"
import TypographyComponent from "../Components/TypographyComponent"
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from './Constants';
import SearchComponent from "../Components/SearchComponent";
import HomeIcon from '@mui/icons-material/Home';
import AssistanceIcon from '@mui/icons-material/Hail';
import ProfessionalIcon from '@mui/icons-material/BusinessCenter';
import EducationIcon from '@mui/icons-material/School';
import FinancialIcon from '@mui/icons-material/TrendingUp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchIcon from '@mui/icons-material/Search';
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";
import mainImage from '../Images/mainImage.png';
import Colors from '../Components/Colors';
import Divider from '@mui/material/Divider';
import UserHeaderMobile from "../Components/UserHeaderMobile";
import CookiesConsent from "../Components/CookiesConsent";
import Cookies from "js-cookie";
import ButtonComponent from "../Components/ButtonComponent";
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';

const iconMapping = {
    'home': HomeIcon,
    'assistance': AssistanceIcon,
    'professional': ProfessionalIcon,
    'education': EducationIcon,
    'financial': FinancialIcon,

};
function UserLanding() {

    const [serviceCategoriesName, setserviceCategoriesName] = useState([]);
    const [isCookie, setIsCookie] = useState(false)
   // const [isCookie, setIsCookie] = useState(true)// to enable cookie
    const fetchServiceCategories = async () => {
        try {

            const response = await axios.get(`${API_URL}/select_all_service_categories`);
            setserviceCategoriesName(response.data);
            console.log("category", response.data)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchServiceCategories();
        localStorage.removeItem("service") ||
      localStorage.removeItem("serviceId")||
      localStorage.removeItem("Type")||
      localStorage.removeItem("TypeId")||
      localStorage.removeItem("TypeserviceId")||
      localStorage.removeItem("SPName")||
      localStorage.removeItem("SPId")
    }, []);



    const handleBoxClick = (id, name) => {
      //  console.log(id)
      //  console.log(name)
        localStorage.setItem("service_category_id", id)
        localStorage.setItem("service_category", name)
        window.location.href = '/service-data';
    };


  const [isCookieSet, setCookie] = useState(Cookies.get("cookieConsent"));
  const [isCookieSettings, setIsCookieSettings] = useState(false)
  const [selectedCookie, setSelectedCookie] = useState("Your privacy");

  const handleAcceptCookies = () => {
    Cookies.set("cookieConsent", true);
    setCookie(true);
    setIsCookie(false)
    setIsCookieSettings(false)
  };

 
  const handleRejectCookies = () => {
    Cookies.remove("cookieConsent");
    setCookie(false);
    setIsCookie(false)
    setIsCookieSettings(false)
  };

  const handleCookiesSettings = () => {    
    setIsCookie(false);
    setIsCookieSettings(true)
  };

  const handleCloseIsCookiesSettings = () => {    
    setIsCookie(true);
    setIsCookieSettings(false)
  };

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };


  const handleReadAll = () => {
    window.location.href = '/cookie-policy';    
  };

  const cookiesType = [
    "Your privacy",
    "Generic cookie policy",
    "Authentication",
    "Security, site and product integrity",
    "Advertising, recommendations, insights and measurement",
    "Site features and services",
    "Performance",
    "Analytics and research",
    "Third-party websites and apps",   
  ];

  const handleCookiesClick = (cookie) => {    
    setSelectedCookie(cookie);
  };


  const renderCookieDetails = () => {
    switch (selectedCookie) {
      case "Your privacy":
        return (
            <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',
            alignItems: 'flex-start'}}> 
            <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                 Cookie Policy
             </TypographyComponent>
             <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
         </TypographyComponent>
         </Box>
        );
      case "Generic cookie policy":
        return (
            <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex',
             flexDirection:'column',alignItems: 'flex-start'}}> 
              <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
            <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
            Generic Cookie Policy
             </TypographyComponent>
             <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
             Please read this cookie policy ("cookie policy", "policy") carefully before using [website] website ("website", "service") operated by [name] ("us", "we", "our").
         </TypographyComponent>
         </Box>
         <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
            <TypographyComponent sx={{fontWeight:500, fontSize: '14px', color: Colors.black_80, }}>
            What are cookies?
             </TypographyComponent>
             <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
             Cookies are simple text files that are stored on your computer or mobile device by a website’s
        server. Each cookie is unique to your web browser. It will contain some anonymous information
        such as a unique identifier, website’s domain name, and some digits and numbers.
         </TypographyComponent>
         </Box>
         <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'left',
          alignItems:'center', }}>
             <TypographyComponent onClick={handleReadAll} 
             sx={{ color: Colors.link, fontSize: '16px', fontWeight: 400, cursor:'pointer' }}>Read more
                   </TypographyComponent>
                   </Box>
         </Box>
        );
        case "Authentication":
            return (
                <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',alignItems: 'flex-start'}}>
                    <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                Authentication
                 </TypographyComponent>
                 <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                 </Box>
                 <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                 We use cookies to verify your account and determine when you’re logged in so that we can make it easier for you to access our products and show you the appropriate experience and features. For example, cookies help us remember your browser so you don't have to keep logging in and so you can more easily access our services via third-party apps and websites. 
             </TypographyComponent>
             </Box>
            );
            case "Security, site and product integrity":
                return (
                    <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',
                    alignItems: 'flex-start'}}> 
                     <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                    <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                    Security, site and product integrity
                     </TypographyComponent>
                     <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                     </Box>
                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                     We use cookies to help us keep your account, data, and our products safe and secure.
                 </TypographyComponent>
                 <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                 For example, cookies can assist us in identifying and imposing additional security measures when someone may be attempting unauthorized access to an account, such as by rapidly guessing different passwords. We also utilize cookies to store information that allows us to recover your account in case you forget your password or to require additional authentication if you report that your account has been compromised.
                 </TypographyComponent>
                 <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                 Additionally, cookies are used to combat activity that violates our policies or otherwise degrades our ability to provide our products.
                 </TypographyComponent>
                 <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                 For instance, cookies help us combat spam and phishing attacks by identifying patterns of suspicious behavior. They also aid in detecting computers infected with malware and taking measures to prevent further harm. Cookies also assist in preventing underage individuals from registering for accounts.
                 </TypographyComponent>
                 </Box>
                );
                case "Advertising, recommendations, insights and measurement":
                    return (
                        <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',
                        alignItems: 'flex-start', }}> 
                         <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                    <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                    Advertising, recommendations, insights and measurement
                     </TypographyComponent>
                     <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                     </Box>
                         <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                         We use cookies to help us show ads and make recommendations for businesses and other organizations to people who may be interested in the products, services, or causes they promote.
                     </TypographyComponent>                    
                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                     We also use cookies to measure the performance of ad campaigns for businesses.
                     </TypographyComponent>
                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                     Cookies help us serve and measure ads across different browsers and devices used by the same person.
                     </TypographyComponent>
                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                     Cookies also allow us to provide insights about the people who use our platform, as well as those who interact with ads, websites, and apps of our advertisers and partners.
                     </TypographyComponent>
                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                     We use cookies to help businesses understand their audience so they can provide relevant content and develop features that interest their customers.
                     </TypographyComponent>
                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                     Additionally, we use cookies to offer controls for opting out of seeing ads based on your activity on third-party websites."
                     </TypographyComponent>
                     
                     </Box>
                    );
                    case "Site features and services":
                        return (
                            <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',alignItems: 'flex-start'}}> 
                             <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                    <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                    Site features and services
                     </TypographyComponent>
                     <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                     </Box>
                             <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                             We use cookies to enable functionality that helps us provide our services.
                         </TypographyComponent>
                         <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                         For example, cookies help us store preferences, track interactions with our content, and provide customized experiences. Cookies allow us to make suggestions and customize content on third-party sites.
                         </TypographyComponent>
                         <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                         We also use cookies to provide content relevant to your location. For example, we store information in a cookie to display the site in your preferred language.
                         </TypographyComponent>
                         </Box>
                        );
                        case "Performance":
                            return (
                                <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',alignItems: 'flex-start'}}> 
                                <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                    <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                    Performance
                     </TypographyComponent>
                     <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                     </Box>
                                 <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                                 We use cookies to enhance your browsing experience.
                             </TypographyComponent>
                             <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                             For example, cookies help us optimize server traffic and understand the loading speed of our website for different users. Cookies also assist us in recording screen ratios and dimensions, as well as determining if high-contrast mode is enabled, ensuring proper rendering of our sites and apps.
                             </TypographyComponent>
                             </Box>
                            );
                            case "Analytics and research":
                                return (
                                    <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',alignItems: 'flex-start'}}> 
                                     <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                    <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                    Analytics and research
                     </TypographyComponent>
                     <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                     </Box>
                                     <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                                     We use cookies to gain insights into how users interact with our products so that we can enhance their functionality.
                                 </TypographyComponent>
                                 <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                                     For example, cookies assist us in analyzing user behavior on our platform, identifying the most useful and engaging features, and pinpointing areas for improvement.
                                     </TypographyComponent>
                                 </Box>
                                );
                                case "Third-party websites and apps":
                                    return (
                                        <Box sx={{width:{ md: '100%', sm: '100%', xs: '95%' }, height:'100%',gap:'16px',display:'flex', flexDirection:'column',alignItems: 'flex-start'}}> 
                                        <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                    <TypographyComponent sx={{fontWeight:500, fontSize: '16px', color: Colors.black_80, }}>
                    Third-party websites and apps
                     </TypographyComponent>
                     <Switch      checked={checked}       onChange={handleChange}      color="primary"     />
                     </Box>
                                         <TypographyComponent sx={{fontWeight:400, fontSize: '14px', color: Colors.black_70,lineHeight:'21px' }}>
                                         Our business partners may also choose to share information with us from cookies set on their own websites' domains, whether or not you have an account or are logged in. Cookies may be set on the domain of the business partner whose site you're visiting. Unlike cookies that are set on our own domains, these cookies aren’t accessible by us when you're on a site other than the one on which they were set, including when you are on one of our domains. They serve the same purposes as cookies set on our own domain, which are to personalize content (including ads), measure ads, produce analytics, and provide a safer experience, as outlined in this Cookies Policy.
                                     </TypographyComponent>
                                     
                                     </Box>
                                    );
      default:
        return null;
    }
  };

    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            
            <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
                <UserHeader currentPage={"Home"}></UserHeader>
            </Box>
            <Box sx={{
                width: '100%', height: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', overflow:'hidden'
            }}>

            <Box sx={{
                width: '100%', height: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', gap:  { md:'20px',  xs: '8px' },maxWidth :{ md: '90%', sm: '94%', xs: '90%' }
            }}>
                <Box sx={{
                    width: '100%', height: '94%', display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' },
                    justifyContent: 'center',
                    alignItems: 'stretch', gap:  { md:'16px', sm:'16px', xs: '4px' }, paddingTop: { md: '3%', sm:'3%', xs: '10px' } , paddingBottom: { md: '3%', sm:'3%', xs: '10px' }
                }}>
                    <Box sx={{
                         width: '100%', height: '100%',display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'row',
                        justifyContent: 'center', alignItems: 'center', gap:'8px'
                    }}>
                         <Box sx={{
                        width: '70%', height: '200px', display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'row',
                        justifyContent: 'center', alignItems: 'center',
                    }}>
                        <img src={mainImage} alt="" style={{ width: '100%', height: '100%', }} /></Box>
                    </Box>
                    <Box sx={{
                        width: { md: 'calc(50% - 130px)', sm: 'calc(50% - 50px)', xs: 'calc(100% - 16px)' }, height: { md: 'calc(100% - 130px)', sm: 'calc(100% - 50px)', xs: 'calc(100% )' }, display: 'flex', flexDirection: 'column',
                        justifyContent: 'left', alignItems: 'center', 
                        padding: { md: '0px 65px 0px 65px', sm: '0px 25px 0px 25px', xs: '0px 8px 0px 8px' }, flex: 1, 
                        gap:  { md:'20px',  xs: '14px' },
                    }}>
                        <Box sx={{
                            width: '100%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' }, flexDirection: 'column',
                             justifyContent: 'left', alignItems: 'left', gap:{ md:'20px',  xs: '8px' }, 
                        }}>
                            <TypographyComponent sx={{
                                fontSize: { md: "54px", sm: "42px", xs: '26px' }, fontWeight: 500,
                                backgroundColor: Colors.primary,
                                width: 'fit-content', color: Colors.white, padding: '0px 10px 0px 10px'
                            }}>
                                What
                            </TypographyComponent>
                            <TypographyComponent sx={{
                                fontSize: { md: "68px", sm: "54px", xs: '32px' }, fontWeight: 600,
                                lineHeight: { md: '74px', sm: "64px", xs: '42px' }, color: Colors.black_80
                            }}>
                                Services are you looking for?
                            </TypographyComponent>
                            <TypographyComponent sx={{ fontSize:  { md: '16px',  xs: '14px' }, color: Colors.black_70, fontWeight: 400, lineHeight: '24px' }}>
                            Find the help you need, right in your neighborhood
                            </TypographyComponent>
                        </Box>
                        <Box sx={{
                            width: '100%', height: '100%', display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'column', justifyContent: 'left',
                            alignItems: 'left', gap:{ md:'20px',  xs: '8px' } ,
                        }}>
                            
                            <TypographyComponent sx={{
                                fontSize: { md: "68px", sm: "54px", xs: '32px' }, fontWeight: 600,
                                lineHeight: { md: '74px', sm: "64px", xs: '42px' }, color: Colors.black_80
                            }}>
                                What services are you looking for?
                            </TypographyComponent>
                            <TypographyComponent sx={{ fontSize:  { md: '16px',  xs: '14px' }, color: Colors.black_70, fontWeight: 400, lineHeight: '24px' }}>
                            Find the help you need, right in your neighborhood
                            </TypographyComponent>
                        </Box>
                        <Box sx={{
                            width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                            alignItems: 'left', gap: { md:'20px',  xs: '8px' },
                        }}>
                            <SearchComponent isHome={true} ></SearchComponent>

                        </Box>
                    </Box>
                    <Box sx={{
                        width: '50%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' }, flexDirection: 'row', 
                        justifyContent: 'center', alignItems: 'center', flex: 1,
                    }}>
                        <img src={mainImage} alt="" style={{ width: '100%', height: '100%', }} />
                    </Box>
                </Box>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Divider orientation="horizontal" flexItem sx={{ height: '100%', width: '100%', borderColor: Colors.grey_60 }}></Divider>
                </Box>
                <Box sx={{
                    width: '100%', height: 'calc(100%- 130px )', display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center', gap: '16px'
                }}>
                    <Box sx={{
                        width: '100%', height: 'calc(100%- 130px )', display: 'flex', flexDirection: 'column',
                        justifyContent: 'left', alignItems: 'left', gap: '16px', paddingTop: { md: '20px', sm: '20px', xs: '8px' },
                         paddingLeft: { md: '130px', sm: '120px', xs: '8px' }
                    }}>
                        <TypographyComponent sx={{ fontSize: "24px", fontWeight: 800, lineHeight: '28px', color: Colors.black_80 }}>
                            Explore our services
                        </TypographyComponent>
                    </Box>
                    <Box sx={{
                        width: '100%', height: 'calc(100%- 130px )', display: { md: 'flex', sm: 'flex', xs: 'none' }, flexDirection: 'row',
                        justifyContent: 'center', alignItems: 'center', gap: '16px', padding: '60px 0px 60px 0px'
                    }}>
                        {serviceCategoriesName.map((category, index) => {
                            const firstWord = category.service_category.split(' ')[0].toLowerCase();
                            const IconComponent = iconMapping[firstWord] || ProfessionalIcon;
                            return (
                                <Box
                                    key={category.service_category_id}
                                    onClick={() => handleBoxClick(category.service_category_id, category.service_category)}
                                    sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', fontWeight: 400,
                                        color: Colors.Hovering, borderRadius: '16px', backgroundColor: Colors.quinary,
                                        cursor: 'pointer', width: '15%', height: '110px',
                                        justifyContent: 'center', alignContent: 'center', textAlign: 'center', alignContent: 'center',
                                        display: 'flex', flexDirection: 'column', gap: '16px', border: '1px solid', borderColor: Colors.quinary,
                                        '&:hover': {
                                            border: '1px solid', borderColor: Colors.Hovering
                                        },
                                    }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <IconComponent />
                                    </div>
                                    {category.service_category}

                                </Box>
                            );
                        })}
                    </Box>
                    <Box sx={{
                        width: 'calc(100%)', height: 'calc(100% )', display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'row',
                        justifyContent: 'center', alignItems: 'center', gap: '8px',
                    }}>
                        {serviceCategoriesName.map((category, index) => {
                            const firstWord = category.service_category.split(' ')[0].toLowerCase();
                            const firstWordlabel = category.service_category.split(' ')[0];
                            const IconComponent = iconMapping[firstWord] || ProfessionalIcon;
                            return (
                                <Box
                                    key={category.service_category_id}
                                    onClick={() => handleBoxClick(category.service_category_id, category.service_category)}
                                    sx={{
                                        fontFamily: 'Roboto', fontSize: '12px', fontWeight: 400,
                                        color: Colors.Hovering, borderRadius: '16px', backgroundColor: Colors.quinary,
                                        cursor: 'pointer', width: '25%', height: '90px',
                                        justifyContent: 'center', alignContent: 'center', textAlign: 'center', alignContent: 'center',
                                        display: 'flex', flexDirection: 'column',border: '1px solid', borderColor: Colors.quinary,
                                        '&:hover': {
                                            border: '1px solid', borderColor: Colors.Hovering
                                        },
                                    }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <IconComponent />
                                    </div>
                                    {firstWordlabel}


                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            </Box>
            <Box sx={{ display: { md: 'none', sm: 'none', xs: 'flex' }, width: '100%', height: '30px' }}></Box>
            <UserFooter></UserFooter>
            {isCookie && (
                <Box sx={{ position: "fixed", bottom: 0, width: "100%",  justifyContent:'center', alignItems:'center',
                maxWidth:{ md: '100%', sm: '100%', xs: '100%' },}}>
                    <Box sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',
                     background:Colors.quinary,}}>
      <Box sx={{width:'100%', height:'100%',maxWidth:{ md: '94%', sm: '90%', xs: '90%' },
      minWidth:{ md: '94%', sm: '90%', xs: '90%' } ,maxHeight:'90%', display:'flex', justifyContent:'left', alignItems:'left',flexDirection:'column',
    gap:'24px',paddingTop:'24px' }}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '18px', color: Colors.black_80, textAlign:'left'}}>
      Cookies consent
        </TypographyComponent >
        <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_80, textAlign:'left'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore 
        </TypographyComponent>
        <Box sx={{width:'100%', height:'100%',display:'flex', flexDirection:{ md:'row' , sm: 'row', xs: 'column'  }, 
         gap:'24px' ,marginBottom:'24px' , }}>
        <Box sx={{width:{ md: '65%', sm: '40%', xs: '100%' }, height:'100%',display:'flex', flexDirection:'row',  gap:'24px',justifyContent:'left', alignItems:'center', }}>
       
        <Button onClick={handleCookiesSettings} variant="contained"          
            sx={{boxShadow: 'none',width:{ md: '28%', sm: '80%', xs: '90%' }, height:{ md:'48px' , sm: '48px', xs: '38px'  }, backgroundColor:Colors.quinary,
            borderRadius:'28px',color:Colors.black_80,textTransform: 'none',fontFamily: 'Roboto', border:'1px solid', borderColor:Colors.black_80,
            fontSize:'16px', fontWeight:'400',
            '&:hover': {
                background: Colors.white ,textTransform: 'none',color: Colors.Hovering,boxShadow: 'none',
                border:'1px solid', borderColor:Colors.Hovering
              },
              
            }} > 
           Cookies settings
         </Button>
        </Box>
        <Box sx={{width:{ md: '35%', sm: '60%', xs: '90%' }, height:'100%',display:'flex', 
        flexDirection:{ md:'row' , sm: 'row', xs: 'column'  },  gap:'24px',justifyContent:'right', alignItems:'right', }}>
        <ButtonComponent onClick={handleRejectCookies}>Disable all cookies</ButtonComponent>
        <ButtonComponent onClick={handleAcceptCookies} >Allow all cookies</ButtonComponent>
        </Box>
        </Box>
      </Box> 
    </Box>
                </Box>)}
                {isCookieSettings &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { md: '70%', sm: '80%', xs: '90%' },
              height:{ md:' 75%', sm:'52%', xs: '50%' } ,
              backgroundColor: '#fff',
              border: '1px solid ',
              borderColor:'Colors.grey_30',
              borderRadius: '16px ',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: { md:'32px 35px', sm:'24px', xs: '24px' },
              alignItems: 'center',
              overflow: 'auto',
              
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '20px',
                borderRadius: '16px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#d9d9d9',
                borderRadius: '10px',
                borderRight: '2px solid #d9d9d9',
                 // Set a fixed height for the scrollbar thumb
              },
              '&::-webkit-scrollbar-track': {
                borderRight: '2px solid #d9d9d9'
              },
            }}
          >
            <IconButton onClick={handleCloseIsCookiesSettings} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left:{ md:'82.5%', sm:'85%', xs: '88%' } , top: { md:'6%', sm:'20%', xs: '20%' }
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color:Colors.quinary, '&:hover': {
                      color: Colors.close,
                    },  }} ></CancelIcon>
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: { md:'24px', sm:'16px', xs: '16px' } }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

           <Box sx={{display: "flex" ,width:'100%',flexDirection:'column',justifyContent:'left'
           ,alignItems:'left', borderBottom:'1px solid', borderColor:Colors.grey_30, minHeight:'50px'}}>
          <Box sx={{width:'100px', height:'40px', background:'#D9D9D9'}}>            
          </Box>
          </Box>    
          <Box sx={{display: "flex" ,width:'100%',flexDirection:{ md:'row', sm:'row', xs: 'column' },
          gap:'16px', paddingTop:'24px' ,}}>
          <Box sx={{width:{ md:'50%', sm:'50%', xs: '100%' }, height:'100%', maxWidth:{ md:'47%', sm:'47%', xs: '100%' },
          justifyContent:{ md:'left', sm:'left', xs: 'left' },gap:'16px',display: "flex" ,
          flexDirection:{ md:'row', sm:'row', xs: 'column' } ,alignItems:{ md:'flex-start', sm:'flex-start', xs: 'flex-start' } , 
            borderRight:{ md:'1px solid', sm:'1px solid', xs: '0px solid' }, 
            borderColor:{ md:Colors.grey_30, sm:Colors.grey_30, xs: Colors.grey_30 }, }}> 
           <List sx={{width:'100%',gap:'16px',display: "flex" ,flexDirection:{ md:'column', sm:'column', xs: 'column' },
           }}>
                                        {cookiesType.map((cookies, index) => (
                                            <ListItem
                                            key={index}
                                            onClick={() => handleCookiesClick(cookies)}   
                                                sx={{
                                                   fontFamily:'Roboto', fontSize:'16px',alignItems:{ md:'flex-start', sm:'flex-start', xs:'flex-start' } ,
                                                    color: Colors.black_80, justifyContent:{ md:'left', sm:'left', xs: 'left' },width:'100%',
                                                    borderRadius: '8px',display: "flex" ,flexDirection:{ md:'column', sm:'column', xs: 'column' },
                                                    "&:hover": {
                                                        backgroundColor:{ md: Colors.primary, sm: Colors.primary, xs: 'white' },
                                                        borderRadius: '8px',
                                                        color: { md:Colors.white, sm: Colors.white, xs: Colors.black_80 },
                                                        cursor: 'pointer'
                                                    },
                                                }}
                                            >
                                                {cookies}
                                                {selectedCookie === cookies && (
                                                <Box
                                                    sx={{paddingTop:'30px',
                                                    width: '100%',
                                                    height: 'auto',                                                 
                                                    display: { md: 'none', sm: 'none', xs: 'flex' }
                                                    }}
                                                >
                                                    {renderCookieDetails(cookies)}
                                                </Box> )}
                                            </ListItem>
                                            
                                        ))}
                                      
                                    </List>      
          </Box>
          <Box sx={{width:'50%', height:'100%',alignItems: 'flex-start', display:{ md:'flex' , sm: 'flex', xs: 'none'  }}}> 
          {selectedCookie && renderCookieDetails()}        
          </Box>
          
          </Box> 
          <Box sx={{display: "flex" ,width:'100%',flexDirection:{ md:'row' , sm: 'row', xs: 'column'  },justifyContent:'left',
           marginTop:'24px', gap:{ md:'24px' , sm: '16px', xs: '24px' }
           ,alignItems:'left', borderTop:{ md:'1px solid', sm:'1px solid', xs: '0px solid' }, borderColor:{ md:Colors.grey_30, sm:Colors.grey_30, xs: Colors.grey_30 }, minHeight:'50px', paddingTop:'10px'}}>
            <Box sx={{width:{ md: '55%', sm: '35%', xs: '100%' }, height:'100%',display:'flex', flexDirection:'row',
              gap:{ md:'24px' , sm: '16px', xs: '24px' }, justifyContent:'left', alignItems:'center' , }}>
       
       <Button onClick={handleCookiesSettings} variant="contained"          
           sx={{boxShadow: 'none',width:{ md:'40%' , sm: '90%', xs: '100%' }, height:{ md:'48px' , sm: '48px', xs: '38px'  }, backgroundColor:Colors.quinary,
           borderRadius:'28px',color:Colors.black_80,textTransform: 'none',fontFamily: 'Roboto', border:'1px solid', borderColor:Colors.black_80,
           fontSize:'16px', fontWeight:'400',
           '&:hover': {
               background: Colors.white ,textTransform: 'none',color: Colors.Hovering,boxShadow: 'none',
               border:'1px solid', borderColor:Colors.Hovering
             },
             
           }} > 
          Confirm my choices
        </Button>
       </Box>
       <Box sx={{width:{ md:'45%' , sm: '65%', xs: '100%' }, height:'100%',display:'flex',
        flexDirection:{ md:'row' , sm: 'row', xs: 'column'  }, 
        gap:{ md:'24px' , sm: '16px', xs: '24px' },justifyContent:'right', alignItems:'right' }}>
       <ButtonComponent onClick={handleRejectCookies}>Disable all cookies</ButtonComponent>
       <ButtonComponent onClick={handleAcceptCookies} >Allow all cookies</ButtonComponent>
       </Box>         
          </Box>     
              </Box>


              </Box>
          </Box>
        </Box>}
        </Grid>
    )
} export default UserLanding