import { Box, Button } from "@mui/material";
import TypographyComponent from './TypographyComponent';
import TextFieldComponent from "./TextFieldComponent";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useEffect, useState } from "react";
import Colors from "./Colors";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonComponentWhite from './ButtonComponentWhite';
import ButtonComponent from './ButtonComponent';
import axios from "axios";
import { API_URL } from './Constants';
import zIndex from "@mui/material/styles/zIndex";

function SpMessageMobile(props){ 
  const { messageDetails, setMessageDetails } = props;
  const [isMsg, setIsMsg] = useState(false);   
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
  //  console.log(messageDetails)
    if(Array.isArray(messageDetails) && messageDetails.length > 0) {
      setIsMsg(true)   
    }
    else{
      setIsMsg(false)  
    }
     
   }, [messageDetails]);

   const [isReadDelete, setIsReadDelete] = useState(false); 

   const handleIconClick = (index, id) => {
    // console.log(id)
     setIsReadDelete(!isReadDelete)
     setSelectedMessageIndex(index )
     setSelectedMessageId(id)
    };

   const [isDeletePopUp, setIsDeletePopUp] = useState(false); 

   const handleMenuItemClick = async(value) => {  

    if (value === "read") {
      try {      
        const response = await axios.post(`${API_URL}/update-read-status`, {           
           token: token,
           msgId: selectedMessageId, 
             });
          //  console.log ("response",response); 
            if(response.data.success === true)  {
              const updatedMessageDetails = messageDetails.map((message, index) => {
                if (index === selectedMessageIndex) {
                  return { ...message, read: 'read' };
                }
                return message;
              });
              // Update messageDetails state
              setMessageDetails(updatedMessageDetails);
            }
            } catch (error) {
            console.error('Error:', error.message);
            }       
        
     
    }
    if (value === "delete") {
      setIsDeletePopUp(true)
    }
    setIsReadDelete(false);
  }

  const formatDateTime = (dateTimeString) => {
    // Split the input string into date and time parts
    const [datePart, timePart] = dateTimeString.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hour, minute, second] = timePart.split(':');
  
    // Create a new Date object in UTC
    const dateUTC = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  
    // Define options for formatting the time in Canada time zone
    const timeOptions = {
      timeZone: 'America/Toronto',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour format with AM/PM
    };
  
    // Create a formatter for the time
    const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
  
    // Format the time
    const formattedTime = timeFormatter.format(dateUTC);
  
    // Get the components of the date in the desired time zone
    const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
    const dayInToronto = String(dateInToronto.getDate()).padStart(2, '0');
    const monthInToronto = String(dateInToronto.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const yearInToronto = dateInToronto.getFullYear();
  
    // Combine date and time with the desired format (dd-mm-yyyy)
    return `${dayInToronto}-${monthInToronto}-${yearInToronto} ${formattedTime}`;
  };


  
  const handleDeletePopUpCancelButtonClick = () => {
    setIsDeletePopUp(false)
   };

   const handleDeletePopUpOkButtonClick = async() => {
    try {      
      const response = await axios.post(`${API_URL}/delete-message`, {           
         token: token,
         msgId: selectedMessageId, 
           });
       //   console.log ("response",response); 
          if(response.data.success === true)  {
            console.log('Message deleted successfully');       
            const updatedMessageDetails = messageDetails.filter((message, index) => index !== selectedMessageIndex);     
            setMessageDetails(updatedMessageDetails);
            setIsDeletePopUp(false)
          }
          } catch (error) {
          console.error('Error:', error.message);
          }       
   };
   
   const handleAllRead = async() => {
    try {      
      const response = await axios.post(`${API_URL}/mark-all-as-read`, {           
         token: token,        
           });
          console.log ("response",response); 
          if(response.data.success === true)  {
            const updatedMessageDetails = messageDetails.map((message, index) => {
              if (index === selectedMessageIndex) {
                return { ...message, read: 'read' };
              }
              return message;
            });
            // Update messageDetails state
            setMessageDetails(updatedMessageDetails);
          }
          } catch (error) {
          console.error('Error:', error.message);
          }     
   };
  
   const handleViewAll = () => {
    window.location.href = '/message';    
  };
   const lastMessage = messageDetails[messageDetails.length - 1];
  // console.log(messageDetails[messageDetails.length - 1])
    return(
      <>
    
     <Box sx={{width:'calc(100%)', height:'calc(100%-56px)',
     display: 'flex' , flexDirection:'column', borderRadius:'16px',maxWidth:'98%',justifyContent:'center',alignItems:'center',
     background:Colors.grey_20, gap:'20px',paddingBottom:'28px', paddingTop:'28px' ,  }}>
      <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'center',
         alignItems:'center',  maxWidth:'94%',}}>
            <TextFieldComponent placeholder="Search"></TextFieldComponent>
         </Box>
     
      {!isMsg && <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center',
         alignItems:'center',gap:'8px',  maxWidth:'94%'}}>
       <TypographyComponent sx={{ color: Colors.grey_60, fontSize: '20px'}}>No messages</TypographyComponent>
       <QuestionAnswerIcon sx={{color:Colors.grey_60,width:'36px', height:'36px'}}></QuestionAnswerIcon>
       </Box>}
       {isMsg &&  messageDetails.length > 0 && (
        <Box sx={{width:'100%', gap:'20px', display:'flex', flexDirection:'column', justifyContent:'left',
         alignItems:'center',maxWidth:'94%', }}>
           <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'right',
         alignItems:'center', }}>
            <TypographyComponent onClick={handleAllRead} 
            sx={{ color: Colors.link, fontSize: '16px', fontWeight: 400, cursor:'pointer' }}>Mark all as read
                  </TypographyComponent>
                  </Box>
                  {/* {messageDetails.slice().reverse().map((message, index) => ( */}
               <Box  sx={{width:'calc(100%-24px)', height:'100%', display:'flex', flexDirection:'column',padding:'12px',
               background: messageDetails[messageDetails.length - 1].read === 'read' ? Colors.white : Colors.quinary,borderRadius:'8px', position:'relative'}}>
                  <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column',}}>
                  <TypographyComponent sx={{ color: Colors.black_80, fontSize: '20px', fontWeight: 700 }}>User {messageDetails.length }
                  </TypographyComponent>
                   <TypographyComponent sx={{ color: Colors.black_80, fontSize: '14px' }}>
                   Seeking {messageDetails[messageDetails.length - 1].type_of_service} service within the {messageDetails[messageDetails.length - 1].location} area. If you're available, reach out to me at this number + {messageDetails[messageDetails.length - 1].country_code} {messageDetails[messageDetails.length - 1].user_phone_number} 
                   </TypographyComponent>
                   <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px',textAlign:'right' }}>
                   {formatDateTime(messageDetails[messageDetails.length - 1].send_time)} 
                    </TypographyComponent>
                   </Box>
                   <MoreVertIcon style={{cursor:'pointer'}} onClick={() => handleIconClick(messageDetails.length - 1, lastMessage.message_id)}></MoreVertIcon>
                   </Box>
                  
                   {isReadDelete && (

                   <Box className="dropdown__menu" sx={{
                     position: 'absolute', right: '30px', top: '10px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                     lineHeight: '20px', width: '200px', background: Colors.grey_20, borderRadius: '8px', boxShadow: 'none', zIndex: 1,
                     color: 'white', padding: '8px'
                   }}>
                     <style>
                       {`
                   .dropdown__menu .MuiMenuItem-root:hover {
                   background-color: #415FE2;
                   color: #FFFFFF !important;
                   }
                   `}
                     </style>

               <MenuItem onClick={() => handleMenuItemClick("read")} value="option2" style={{
                 fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                 fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
               }}>
                 Mark as read
               </MenuItem>
               <MenuItem onClick={() => handleMenuItemClick("delete")} value="option2" style={{
                 fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                 fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
               }}>
                 Delete
               </MenuItem>
             </Box>

             )} 

               </Box> 
               <Box sx={{width:'100%',display:'flex', flexDirection:'row', justifyContent:'center',height:'100%',
                  alignItems:'center', background:Colors.grey_20,}}>
                      <TypographyComponent onClick={handleViewAll} 
                      sx={{ color: Colors.link, fontSize: '16px', fontWeight: 400, cursor:'pointer' }}>View all
                            </TypographyComponent>
                  </Box>               
              {/* ))}    */}
             


           
       </Box>
      ) }
      
       {isDeletePopUp &&
       <Box sx={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0, 0, 0, 0.6)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: '2',

       }} >
       
                      <Box sx={{width:'100%', height:'100%',gap:'5px',
                     justifyContent:'center',alignItems:'center', position: 'fixed',
                       display:'flex',flexDirection:'column', }}>
                     <Box sx={{width:{ md: '375px', sm: '380px', xs: '85%' }, height:'36px',
                     justifyContent:'right',alignItems:'right', display:'flex',}}>
                    <IconButton onClick={handleDeletePopUpCancelButtonClick} sx={{
                  width: '36px', height: '36px',
                 
                }} >
                  <CancelIcon sx={{
                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                      color: Colors.close,
                    },
                  }} ></CancelIcon>
                </IconButton>
                     </Box>
         <Box
           sx={{
            
             width: { md: '375px', sm: '380px', xs: '85%' },
             height: 'fit-content',
             backgroundColor: '#FFFFFF',
             border: '1px solid #FFFFFF',
             borderRadius: '28px ',
             boxShadow: 'none',
             boxSizing: 'border-box',
             zIndex: '3',
             padding: '35px',
             alignItems: 'center',

           }}
         >
          
           <Box sx={{
             display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
             justifyContent: 'center', alignContent: 'center', height: '100%'
           }}>
             <Box sx={{
               display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
               alignContent: 'center', width: '100%', gap: '16px'
             }}>
               <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
               Are you sure you want to delete this message from User {selectedMessageIndex + 1} ?</TypographyComponent>

             </Box>


             <Box sx={{
               display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
               alignContent: 'center', alignItems: 'center', width: '100%'
             }}>
               <ButtonComponentWhite onClick={handleDeletePopUpCancelButtonClick}>  Cancel </ButtonComponentWhite>
               <ButtonComponent onClick={handleDeletePopUpOkButtonClick}> Delete </ButtonComponent>
              

             </Box>


           </Box>
         </Box>
         </Box>
       </Box>}

       {/* {isMsg && <Box sx={{width:'100%',display:'flex', flexDirection:'row', justifyContent:'center',height:'100%',
         alignItems:'center', background:Colors.grey_20,}}>
            <TypographyComponent onClick={handleViewAll} 
            sx={{ color: Colors.link, fontSize: '16px', fontWeight: 400, cursor:'pointer' }}>View all
                  </TypographyComponent>
         </Box>} */}
   </Box>
   </>
    )
}
export default SpMessageMobile;