
import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent"
import TextFieldComponent from "../Components/TextFieldComponent"
import ButtonComponent from "../Components/ButtonComponent"
import { API_URL } from "../Components/Constants"
import GoogleIcon from '../Images/google.png';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useGoogleLogin } from '@react-oauth/google';
import Colors from '../Components/Colors';
import mainImage from '../Images/mainImage.png'
import mainBg from '../Images/mainBg.png'
import CancelIcon from '@mui/icons-material/Cancel';
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";

function AssociateLogin() {

    let tokenvar;
    
    const [signInEmail, setSignInEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [signInPassword, setSignInPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isCheckedSignIn, setisCheckedSignIn] = useState(false);
    const [emailGoogleSignInError, setEmailGoogleSignInError] = useState("");
    const [testpurpose, settestpurpose] = useState("");
    const [loginError, setLoginError] = useState("");
    const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [verificationAttempted, setVerificationAttempted] = useState(false);
    const [otpPopUp, setOtpPopUp] = useState(false);
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [forgotPhoneError, setForgotPhoneError] = useState("");
    const [forgotCountryError, setForgotCountryError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [errorPopUp, setErrorPopUp] = useState(false);


    const handleCloseErrorPopUp = () => {
        setErrorPopUp(false)
    }


    const handleSendOtpClick = async () => {
        let hasError = false;
        setOtp(['', '', '', '']);
        setOtpError("");
        setErrorPopUp(false);
        setVerificationAttempted(false)
        if (phone.trim() === "") {
            setPhoneError("Please add your phone number");
            setLoginError("")
            setForgotPhoneError("you can't use forgot password without adding phone number")
            hasError = true;
        }

        if (countryCode.trim() === "" || countryCode.trim() === null) {
            // console.log("....", countryCode.trim())
            setCountryCodeError("Please add your country code");
            setForgotCountryError("you can't use forgot password without adding country code")
            hasError = true;
        }
        if (hasError) {
            return; // Stop execution if there are errors
        }
        else {
            // setConfirmQuoteSend(true)
          // console.log(phone)
            try {
                const response = await axios.post(`${API_URL}/otp-creation`,
                    {
                        countryCode: countryCode,
                        phone: phone,
                    });

                // console.log("response", response);

                if (response.data.success === true) {
                    setOtpPopUp(true)
                    // setOpenQuote(false)
                }
                else {

                    // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                    console.log("an error occured");

                }
            }
            catch (error) {
                // setConfirmQuoteSend(true)
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }


        }
    };

    const handleVerifyOTP = async () => {
        const isAnyFieldEmpty = otp.some((digit) => digit.length === 0);
        setVerificationAttempted(true);
        if (isAnyFieldEmpty) {
            console.log("field empty")
        } else {           
            const response = await axios.post(`${API_URL}/associate-otp-verification`,
                {
                    countryCode: countryCode,
                    phone: phone,
                    otp: otp,
                    password:signInPassword
                    
                });

            if (response.data.success === true) {
                // console.log("otp verification success")
                const token=response.data.token;
                const user=response.data.user;
                console.log("token",token);
                console.log("user",user);

                setOtpPopUp(false);
              
                localStorage.setItem('associatetocken', token);
              

                window.location.href = '/associate_landing';
                // setConfirmQuoteSend(true)
            }
            else {
                setOtpError("Please enter a valid OTP");
            }
        }
    };

   

    const handleKeyDown = (index, e) => {
        // Handle Enter key press to move to next TextField
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or new line
            if (otp[index].length === 1 && index < otp.length - 1) {
                const nextTextField = document.getElementById(`otp${index + 1}`);
                if (nextTextField) {
                    nextTextField.focus();
                }
            }
        }

        // Handle Backspace key press to clear current field and move focus
        if (e.key === 'Backspace') {
            e.preventDefault(); // Prevent default backspace behavior
            const updatedOtp = [...otp];
            updatedOtp[index] = ''; // Clear the current field
            setOtp(updatedOtp);

            // Move cursor to previous TextField if index is greater than 0
            if (index > 0) {
                const prevTextField = document.getElementById(`otp${index - 1}`);
                if (prevTextField) {
                    prevTextField.focus();
                }
            }
        }
    };

    const handleInputChange = (index, value) => {
        if (!isNaN(value) && value.length <= 1) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleEmailChange = (event) => {
        setSignInEmail(event.target.value);
        setEmailError("");
        const lebrememberMeValue = JSON.parse(localStorage.getItem('lebrememberMeValue')) || [];
        const foundData = lebrememberMeValue.find((data) => data.email === event.target.value);
        // console.log("foundData", rememberMeValue)
        if (foundData) {
            setSignInEmail(event.target.value)
            setSignInPassword(foundData.password)
            setisCheckedSignIn(true);
        }
    };

    const handlePasswordChange = (event) => {
        setSignInPassword(event.target.value);
        setPasswordError("");
        setLoginError("")
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        setPhoneError("");
        setForgotPhoneError("");
        setLoginError("")
        const lebrememberMeValue = JSON.parse(localStorage.getItem('lebrememberMeValue')) || [];
        const foundData = lebrememberMeValue.find((data) => data.phone === event.target.value);
        // console.log("foundData", rememberMeValue)
        if (foundData) {
            setPhone(event.target.value)
            setSignInPassword(foundData.password)
            setisCheckedSignIn(true);
        }
    };

    const handleCheckboxChange = (event) => {
        setisCheckedSignIn(event.target.checked);
    };

    const handleSignUpClick = () => {
        window.location.href = '/signup'
    }

    // const handleForgotPassword = () => {
    //     window.location.href = '/password-recovery-send-mail'
    // };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };
    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
        setForgotCountryError("");
    };
    // const handleForgotPassword = () => {
    //     if (phoneError === "") {
    //         setOtpPopUp(true);
    //     } else {
    //         // Optionally, handle the case where there is an error
    //         console.error("Phone error exists:", phoneError);
    //     }
    //     setOtpPopUp(true);
    // };
    const handleCloseForgotPasswordPopUp = () => {
        setOtpPopUp(false);
    };


    const handleSignInClick = async () => {
        setOtp(['', '', '', '']);
        setOtpError("");
        setForgotCountryError("");
        setForgotPhoneError("");
        setErrorPopUp(false);
        setPhoneNumberError("");
        // setCatchError("");
        if (
            phone.trim() === "" ||
            signInPassword.trim() === "" ||
            countryCode.trim === ""
        ) {
            if (phone.trim() === "") {
                setPhoneError("Please enter your phone");
                setLoginError("")
            }
            if (countryCode.trim() === "") {
                setCountryCodeError("Please enter your country code");
            }
            if (signInPassword.trim() === "") {
                setPasswordError("Please enter your password");
                setLoginError("")
            }
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/associate_login`, {
                countryCode: countryCode,
                phone: phone,
                password: signInPassword,
                // rememberMe: isCheckedSignIn,
            });
          
        console.log(response);
            if (response.data.error) {

                if (response.data.error === 'sorry! there is no profile attached to this phone number.') {
                    // setPhoneError(response.data.error)                   
                    setLoginError("");
                    setPhoneNumberError(response.data.error)
                }
                else if (response.data.error === 'Invalid password. Please try again.') {
                    setPasswordError(response.data.error)
                    setLoginError("")
                }
                else {
                    setLoginError("Something went wrong! please try again later.")
                }

                // console.log("", response.data.error);
                // setLoginError(response.data.error)
            }
            else{
                if (response.data.success) {
                    setOtpPopUp(true);
                    handleSendOtpClick();
                    console.log(response);
                    console.log(response.data.data);
                    tokenvar=response.data.data;
                    localStorage.setItem("associatetocken",response.data.data)
                }
            }

        }
        catch (error) {
           
            setErrorPopUp(true);
            
        }

    };
    const handlePaste = (event) => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === otp.length) {
          const newOtp = [...otp];
          for (let i = 0; i < otp.length; i++) {
            newOtp[i] = pastedData[i] || '';
          }
          setOtp(newOtp);
          event.preventDefault();
        }
      };
    

    const firstOtpInputRef = useRef(null);

    useEffect(() => {
        if (otpPopUp && firstOtpInputRef.current) {
            firstOtpInputRef.current.focus();
        }
    }, [otpPopUp]);

    return (
        <>
          
            <Stack direction={{ xs: "column", sm: "row", md: "row" }} height='100vh' spacing={2} justifyContent="space-between">

                <Box flex={2} p={{ xs: '5%', sm: '5%', md: '5%' }} sx={{
                    backgroundImage: `url(${mainBg})`, height: 'auto',
                    backgroundSize: 'cover', position: 'relative',
                }}>
                    <Box sx={{ flex: 1 }}>
                        <TypographyComponent sx={{ fontSize: { xs: '26px', sm: '42px', md: '60px' }, color: Colors.white, fontWeight: 800 }}>Welcome</TypographyComponent>
                        <TypographyComponent sx={{ fontSize: { xs: '8px', sm: '10px', md: '14px' }, color: Colors.white, fontWeight: 400 }}>
                            Find the help you need, right in your neighborhood
                        </TypographyComponent>
                    </Box>
                    <Box sx={{
                        flex: 1,
                        width: { xs: '50%', sm: '100%', md: '100%' },
                        maxWidth: '100%',
                        // left:'50%',
                        position: { xs: 'absolute', sm: 'absolute', md: 'absolute' },
                        transform: { xs: 'translateY(30%) translateX(36%)', sm: 'translateX(20%)', md: 'translateX(20%)' },
                        top: { xs: '23%', sm: '30%', md: '40%' }, height: { xs: 'max(100%,190px)', sm: '300px', md: '400px', }
                    }}>
                        <img src={mainImage} alt="Main" style={{ width: '100%' }} />
                    </Box>


                </Box>
                <Box flex={4} p={{ xs: '5%', sm: '10%', md: '10%' }} sx={{
                    display: 'flex', m: '0px', pt: '5%',
                    flexDirection: 'column', gap: { xs: '16px', md: '36px' }, height: 'auto',
                }}>
                    <Box sx={{ gap: '8px' }}>
                        <TypographyComponent sx={{
                            fontSize: { xs: '20px', sm: '24px', md: '32px' },
                            color: '#5B5555',
                        }}>Lets sign in </TypographyComponent>

                    </Box>
                    <Box sx={{ gap: { xs: '16px', md: '24px' }, display: 'flex', flexDirection: 'column', }}>

                       
                        <Box sx={{width:'100%',display:'flex',flexDirection:'column'}}>
                            <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Box sx={{ width: { md: '20%', sm: '40%', xs: '40%' } }}>
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Country code*</TypographyComponent>
                                    <TextFieldComponent placeholder="+1"
                                        value={countryCode}
                                        onKeyDown={handleKeyDownCountryCode}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={handleCountryCodeChange}
                                        error={!!countryCodeError || !!forgotCountryError || !!phoneNumberError}></TextFieldComponent>
                                    {forgotCountryError ? (
                                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                            {forgotCountryError}
                                        </TypographyComponent>
                                    ) : (
                                        countryCodeError && (
                                            <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                {countryCodeError}
                                            </TypographyComponent>
                                        )
                                    )}
                                </Box>
                                <Box sx={{ width: { md: '80%', sm: '60%', xs: '60%' } }}>
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Phone number*</TypographyComponent>
                                    <TextFieldComponent placeholder="Add phone number"
                                        onKeyDown={handleKeyDownPhone}
                                        inputProps={{ maxLength: 10 }}
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        error={!!phoneError || !!forgotPhoneError || !!phoneNumberError}></TextFieldComponent>

                                    {forgotPhoneError ? (
                                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                            {forgotPhoneError}
                                        </TypographyComponent>
                                    ) : (
                                        phoneError && (
                                            <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                {phoneError}
                                            </TypographyComponent>
                                        )
                                    )}
                                </Box>

                            </Box>
                            {phoneNumberError && (
                                <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                    {phoneNumberError}
                                </TypographyComponent>
                            )}
                        </Box>
                        <Box >
                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}
                            >
                                Password*</TypographyComponent>
                            <TextFieldComponent placeholder="Enter your password"
                                onChange={handlePasswordChange}
                                error={!!passwordError}
                                value={signInPassword}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                color="primary"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextFieldComponent>
                            {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}

                            
                        </Box>
                        <Box>
                            {loginError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                {loginError}</TypographyComponent>}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <Box sx={{ gap: { xs: '16px', md: '24px' }, display: 'flex', flexDirection: 'column', }}>
                                <ButtonComponent onClick={handleSignInClick}>Sign in</ButtonComponent>
                               
                            </Box>
                           
                        </Box>
                    </Box>

                </Box>
                {otpPopUp &&
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2,

                        }}
                    >
                        <Box sx={{
                            width: '100%', height: '100%', gap: '5px',
                            justifyContent: 'center', alignItems: 'center', position: 'fixed',
                            display: 'flex', flexDirection: 'column',
                        }}>
                            <Box sx={{
                                width: { md: '568px', sm: '450px', xs: '90%' }, height: '36px',
                                justifyContent: 'right', alignItems: 'right', display: 'flex',
                            }}>
                                <IconButton onClick={handleCloseForgotPasswordPopUp} sx={{
                                    width: '36px', height: '36px',

                                }} >
                                    <CancelIcon sx={{
                                        width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                            color: Colors.close,
                                        },
                                    }} ></CancelIcon>
                                </IconButton>
                            </Box>
                            <Box
                                sx={{

                                    width: { md: '568px', sm: '450px', xs: '90%' },
                                    height: 'fit-content',
                                    backgroundColor: '#fff',
                                    border: '1px solid #E6E6E6',
                                    borderRadius: '28px ',
                                    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                    boxSizing: 'border-box',
                                    zIndex: 3,
                                    padding: '35px',
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '12px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#7f7f7f',
                                        borderRadius: '28px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#e0e0e0',
                                        borderRadius: '28px',
                                    },
                                }}
                            >


                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%',

                                }}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', gap: '20px',
                                        alignItems: 'center', width: '100%'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                            OTP Verification </TypographyComponent>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'flex-start', gap: '8px',
                                            alignItems: 'center', width: '100%', paddingTop: '15px'
                                        }}>
                                            <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                            We have sent you a one-time password to the mobile number
                                                <span style={{ color: '#626262' }}> XXXXXX{phone.slice(-4)}</span>  </TypographyComponent>
                                            <TypographyComponent>
                                                Enter your OTP here
                                            </TypographyComponent>
                                            <Box display="flex" justifyContent="center" mt={2}>

                                                {otp.map((digit, index) => (
                                                    <TextField
                                                        key={index}
                                                        id={`otp${index}`}
                                                        value={digit}
                                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                                        onPaste={handlePaste}
                                                        error={(verificationAttempted && !digit) || !!otpError}
                                                        onInput={(e) => {
                                                            const input = e.target.value;
                                                            const digitValue = input.replace(/\D/, '').slice(0, 1);
                                                            handleInputChange(index, digitValue);
                                                            if (digitValue && index < otp.length - 1) {
                                                                const nextTextField = document.getElementById(`otp${index + 1}`);
                                                                if (nextTextField) {
                                                                    nextTextField.focus();
                                                                }
                                                            }
                                                        }}
                                                        inputRef={index === 0 ? firstOtpInputRef : null}
                                                        style={{
                                                            width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',


                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                            {otpError && (
                                                <Box display="flex" justifyContent="center" mt={1}>
                                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                                    </TypographyComponent>
                                                </Box>
                                            )}
                                            <TypographyComponent>Don't receive the OTP?</TypographyComponent>

                                            <Button
                                                onClick={handleSendOtpClick}
                                                sx={{
                                                    textDecoration: 'none',
                                                    color: Colors.link,
                                                    textTransform: 'none',
                                                    padding: 0,
                                                    minWidth: 'unset',
                                                    '&:hover': {
                                                        backgroundColor: 'transparent', // This removes the hover effect
                                                    },
                                                }}
                                            >
                                                Resend OTP
                                            </Button>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', marginTop: '16px', justifyContent: 'center',
                                            alignContent: 'center', alignItems: 'center', width: '100%'
                                        }}>
                                            <Box sx={{ width: '100%' }}>
                                                <ButtonComponent onClick={handleVerifyOTP} >Verify</ButtonComponent>
                                            </Box>

                                        </Box>

                                    </Box>

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
                {errorPopUp &&
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2,

                        }}
                    >
                        <Box sx={{
                            width: '100%', height: '100%', gap: '5px',
                            justifyContent: 'center', alignItems: 'center', position: 'fixed',
                            display: 'flex', flexDirection: 'column',
                        }}>
                            <Box sx={{
                                width: { md: '394px', sm: '394px', xs: '90%' }, height: '36px',
                                justifyContent: 'right', alignItems: 'right', display: 'flex',
                            }}>
                                <IconButton onClick={handleCloseErrorPopUp} sx={{
                                    width: '36px', height: '36px',

                                }} >
                                    <CancelIcon sx={{
                                        width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                            color: Colors.close,
                                        },
                                    }} ></CancelIcon>
                                </IconButton>
                            </Box>
                            <Box
                                sx={{

                                    width: { md: '394px', sm: '394px', xs: '90%' },
                                    height: 'fit-content',
                                    backgroundColor: '#fff',
                                    border: '1px solid #E6E6E6',
                                    borderRadius: '28px ',
                                    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                    boxSizing: 'border-box',
                                    zIndex: 3,
                                    padding: '35px',
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '12px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#7f7f7f',
                                        borderRadius: '28px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#e0e0e0',
                                        borderRadius: '28px',
                                    },
                                }}
                            >


                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%',
                                    alignItems: 'center', gap: "22px"
                                }}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', gap: '16px',
                                        alignItems: 'center', width: '100%'
                                    }}>
                                        <TypographyComponent sx={{
                                            fontSize: '24px', fontWeight: 600,
                                            color: Colors.black_80,
                                        }}>
                                            Login failed </TypographyComponent>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'flex-start', gap: '8px',
                                            alignItems: 'center', width: '100%'
                                        }}>
                                            <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_70 }}>
                                                You cannot login to the website. Try again.
                                            </TypographyComponent>

                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '30%' }}>
                                        <ButtonComponent onClick={handleCloseErrorPopUp} >ok</ButtonComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            </Stack >
            {/* <UserFooter></UserFooter> */}
        </>

    )

}
export default AssociateLogin;