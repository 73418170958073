import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypographyComponent from './TypographyComponent';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import camera from '../Images/camera_Icon.png';
import profile from '../Images/profile.png';
import Colors from './Colors';


const ProgressbarEdit = ({ currentStage, setNewProfilePic,
    updateImage,setUpdateImage, newProfilePic,setSelectedImage,selectedImage}) => {
   // console.log("image in progressbar", newProfilePic)
    const isValidURL = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        return pattern.test(url);
    };

   
    const stages = [
        { number: 1, name: "Profile" },
        { number: 2, name: "Add your location" },
        { number: 3, name: "Add/Edit skills" }
    ];

   // console.log("newProfilePic", newProfilePic);
   // console.log("selectedImage", selectedImage);
   // console.log("profile", profile);
  //  console.log("currentStage", currentStage);
    // useEffect(() => {
    //     // Update the selected image when newProfilePic changes
    //     if (newProfilePic && newProfilePic.imageUrl) {
    //         setSelectedImage(newProfilePic.imageUrl);
    //     }
    //     console.log("selected", selectedImage)
    // }, [newProfilePic, selectedImage]);


    const handleImageUpload = (e) => {
        setUpdateImage(true);
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', (event) => {
            const imageUrl = URL.createObjectURL(event.target.files[0]);
           // console.log("imageUrl...........", imageUrl);
            setSelectedImage(imageUrl);
            // onImageChange(imageUrl);
            setNewProfilePic(imageUrl)
        });

        // Trigger a click on the dynamically created input to open the file dialog.
        input.click();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', gap: '48px' }}>
            <Box sx={{
                position: 'relative', display: 'flex', flexDirection: 'column',
                alignItems: 'center', height: '136px', width: '136px'
            }}
            >
              
                <label htmlFor="imageUpload">
                    <img src={camera} alt="camera" style={{
                        width: '40px',
                        height: '40px', position: 'absolute', bottom: '10%', left: '72%',
                        transform: 'translate(-50%, 50%)', zIndex: 1,cursor: 'pointer'
                    }} onClick={handleImageUpload} />
                </label>
              
                { newProfilePic? (

                    <img
                        src={newProfilePic}
                        alt="Profile"
                        style={{
                            color: '#C5C5C5',
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                            borderRadius: '100px'
                        }}
                    />

                )  : (
                        <img
                            src={profile}
                            alt="profile"
                            style={{
                                color: '#C5C5C5',
                                width: '100%',
                                height: '100%',
                                zIndex: 0,
                                borderRadius: '100px'
                            }}
                        />

                    
                )}

            </Box>
            <TypographyComponent sx={{ color: '#313131', fontSize: '16px', }}>
                Complete your profile{currentStage}</TypographyComponent>
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                {stages.map((stage, index) => (
                    <React.Fragment key={stage}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {index === currentStage - 1 ? (
                                <Box
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        backgroundColor: Colors.quinary,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // border: '1px solid #C5C5C5',
                                        color: Colors.primary,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                </Box>

                            ) : (
                                <Box
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        backgroundColor: Colors.quinary,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // border: '1px solid #C5C5C5',
                                        color: Colors.primary,
                                        fontWeight: 400,
                                    }}
                                >
                                    {stage.number}
                                </Box>)}
                            <Box sx={{ paddingLeft: '10px' }}>
                                {stage.name}
                            </Box>
                        </Box>
                        {index < stages.length - 1 && (
                            <Box
                                sx={{
                                    marginLeft: '15px',
                                    width: '2px',
                                    height: '100px', // Adjust as needed
                                    backgroundColor: Colors.quinary,
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </Box> */}
              <Box sx={{ width: { xs: 'calc(100%-80px)'}, maxWidth: { xs: 'calc(100%-80px)'}}}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
    {stages.map((stage, index) => (
        <React.Fragment key={stage}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {index < currentStage - 1 ? (
                    <Box
                        sx={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            backgroundColor: Colors.quinary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: Colors.primary,
                            fontWeight: 400,
                        }}
                    >
                        <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                    </Box>
                ) : index === currentStage - 1 || index === currentStage || index === currentStage + 1 ? (
                    <Box
                        sx={{
                            width: '31px',
                            height: '31px',
                            borderRadius: '50%',
                            backgroundColor: Colors.quinary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: index !== currentStage?  '1px solid ': 'none',
                            borderColor: Colors.secondary,
                            color: Colors.primary,
                            fontWeight: 'bold',
                        }}
                    >
                        {stage.number}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            backgroundColor: Colors.quinary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: Colors.primary,
                            fontWeight: 400,
                        }}
                    >
                        <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                    </Box>
                )}
                <Box sx={{ paddingLeft: '10px' }}>
                    {stage.name}
                </Box>
            </Box>
            {index < stages.length - 1 && (
                <Box
                    sx={{
                        marginLeft: '15px',
                        width: '2px',
                        height: '100px', // Adjust as needed
                        backgroundColor: Colors.quinary,
                    }}
                />
            )}
        </React.Fragment>
    ))}
</Box>
        </Box>
        </Box>
    );
};

export default ProgressbarEdit;
