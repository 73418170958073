import { Box, Button } from "@mui/material";
import TypographyComponent from './TypographyComponent';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Colors from "./Colors";
import B1 from '../Images/BusinesscardBack1.png'
import bg1 from '../Images/bg1.jpg';
import bg2 from '../Images/bg2.jpg';
import bg3 from '../Images/bg3.jpg';
import bg4 from '../Images/bg4.jpg';
import bg5 from '../Images/bg5.jpg';

function BusinessCardBack(props) {
  const { serviceDetails, isEditEnable, setServiceDetailsDataToShow, serviceDetailsDataToShow, serviceProviderDtls  } = props;
  const CardbgColor = serviceProviderDtls.card_color 
 const typeDetails = serviceDetailsDataToShow?.type_details || [];
  const handleEditIconClick = async () => {
    localStorage.setItem('editservice', 'true');
    window.location.href = '/add-service';
  }
  let CardbgImage;

  switch (CardbgColor) {
    case 'b1':
      CardbgImage = bg1;
      break;
    case 'b2':
      CardbgImage = bg2;
      break;
    case 'b3':
      CardbgImage = bg3;
      break;
    case 'b4':
      CardbgImage = bg4;
      break;
    case 'b5':
      CardbgImage = bg5;
      break;
    default:
      CardbgImage = bg1; // Default image if color doesn't match any case

  }
 // console.log("serviceDetailsDataToShow.services", serviceDetailsDataToShow)

  return (
    <Box sx={{
      width: '100%', height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '16px',
      backgroundImage: `url(${CardbgImage})`, gap: '20px', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat',
      justifyContent:'center',alignItems:'center'
    }}>
        <Box  sx={{
      width: '100%', height: '90%', display: 'flex', flexDirection: 'column',maxWidth:'90%',
      paddingTop:'5%',paddingBottom:'5%', gap:'16px'}}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'stretch', width: '100%',
          justifyContent: 'space-between'
        }}>

          <Typography sx={{
            fontFamily: 'Roboto', fontSize: '16px', fontWeight: 700, color: Colors.white,
            cursor: 'pointer', minWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
          }}       >
            {serviceDetailsDataToShow ? (
              serviceDetailsDataToShow.services || 'New service'
            ) : (
              'New service'
            )}
          </Typography>
          {isEditEnable && <EditOutlinedIcon sx={{ cursor: 'pointer', color: Colors.white, }} onClick={handleEditIconClick}></EditOutlinedIcon>}


        </Box>
        {serviceDetailsDataToShow.services === '' && !serviceDetailsDataToShow.about_us && (
        <Box sx={{
          width: '100%', height: '100vh', display: 'flex', 
          justifyContent: 'center', alignItems: 'center',
        }}>
          <TypographyComponent sx={{ color: Colors.white, fontSize: '14px', }}>Please add your services to find your customers
          </TypographyComponent>
         
        </Box>)}
        {serviceDetailsDataToShow.services === '' && serviceDetailsDataToShow.about_us && (
        <Box sx={{
          width: '100%', height: '100vh', display: 'flex', 
          justifyContent: 'center', alignItems: 'center',
        }}>
          <TypographyComponent sx={{ color: Colors.white, fontSize: '14px', }}>Add your new service to reach more customers.
          </TypographyComponent>
         
        </Box>)}
        {serviceDetailsDataToShow && (
        <Box sx={{ width: 'calc(100%)', height: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }} >

          <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '25px' }} >
            {typeDetails.length > 0 && (
              <Box sx={{
                minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                gap: '2px',
              }} >
                <TypographyComponent sx={{ fontWeight: 700, fontSize: '14px', color: Colors.white_80, }}>
                  Type of service
                </TypographyComponent>
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: '12px', color: Colors.white,
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                }}> 
                {console.log(typeDetails)}                
                  {typeDetails.map((item) => item.types).join(', ')}
                </TypographyComponent>
              </Box>)}

            {serviceDetailsDataToShow.certifications && (
              <Box sx={{
                minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                gap: '2px',
              }} >
                <TypographyComponent sx={{ fontWeight: 700, fontSize: '14px', color: Colors.white_80, }}>
                  Certifications
                </TypographyComponent>
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: '12px', color: Colors.white, overflow: 'hidden',
                  textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                }}>
                  {serviceDetailsDataToShow.certifications}
                </TypographyComponent>
              </Box>)}

            {serviceDetailsDataToShow.license && (
              <Box sx={{ minWidth: '28%', maxWidth: '28%', height: '100%', display: 'flex', flexDirection: 'column', gap: '2px', }} >
                <TypographyComponent sx={{ fontWeight: 700, fontSize: '14px', color: Colors.white_80, }}>
                  License No
                </TypographyComponent>
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: '12px', color: Colors.white,
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                }}>
                  {serviceDetailsDataToShow.license}
                </TypographyComponent>
              </Box>)}

          </Box>
          {(serviceDetailsDataToShow.franchise || serviceDetailsDataToShow.office_address) && (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '25px' }} >
              {serviceDetailsDataToShow.franchise && (
                <Box sx={{
                  minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                  gap: '2px',
                }} >
                  <TypographyComponent sx={{ fontWeight: 700, fontSize: '14px', color: Colors.white_80, }}>
                    Franchise
                  </TypographyComponent>
                  <TypographyComponent sx={{
                    fontWeight: 400, fontSize: '12px', color: Colors.white, overflow: 'hidden',
                    textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                  }}>
                    {serviceDetailsDataToShow.franchise}
                  </TypographyComponent>
                </Box>)}
              {serviceDetailsDataToShow.office_address && (
                <Box sx={{
                  minWidth: 'calc(95% / 3)', maxWidth: 'calc(95% / 3)', height: '100%', display: 'flex', flexDirection: 'column',
                  gap: '2px',
                }} >
                  <TypographyComponent sx={{ fontWeight: 700, fontSize: '14px', color: Colors.white_80, }}>
                    Office address
                  </TypographyComponent>
                  <TypographyComponent sx={{
                    fontWeight: 400, fontSize: '12px', color: Colors.white, overflow: 'hidden',
                    textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                  }}>
                    {serviceDetailsDataToShow.office_address}
                  </TypographyComponent>
                </Box>)}



            </Box>)}
          {serviceDetailsDataToShow.about_us && (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '2px', }} >

              <TypographyComponent sx={{ fontWeight: 700, fontSize: '14px', color: Colors.white_80, }}>
                About
              </TypographyComponent>
            
              <TypographyComponent sx={{
                fontWeight: 400, fontSize: '12px', color: Colors.white,
                ...(serviceDetailsDataToShow.franchise || serviceDetailsDataToShow.office_address ? {
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                } : { display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden',
                  WebkitLineClamp: 5, whiteSpace: 'normal' })}}>
                {serviceDetailsDataToShow.about_us}
              </TypographyComponent>


            </Box>)}

        </Box>)}

        </Box>
     
    </Box>
  )
}
export default BusinessCardBack;