import Box from '@mui/material/Box';
import ButtonComponent from '../Components/ButtonComponent';
import TextFieldComponent from '../Components/TextFieldComponent';
import TypographyComponent from '../Components/TypographyComponent';
import { Grid, colors } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import Button from '@mui/material/Button';
import GoogleIcon from '../Images/google.png';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { API_URL } from './Constants';
import axios from "axios";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { jwtDecode } from 'jwt-decode';
import { useGoogleLogin } from '@react-oauth/google';
import Colors from '../Components/Colors';
import mainImage from '../Images/mainImage.png'
import mainBg from '../Images/mainBg.png'


function SignUp() {

    const [businessName, setBusinessName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [businessNameError, setBusinessNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");   
    const [emailExist, setEmailExist] = useState(false);
    const [emailVerifyPopup, setEmailVerifyPopup] = useState(false);
    const [emailGoogleSignUpError, setEmailGoogleSignUpError] = useState("");
    const handleBusinessNameChange = (event) => {
        setBusinessName(event.target.value);
        setBusinessNameError("");        
    };

    const handleEmailChange = async (event) => {
        setEmail(event.target.value);
        setEmailError(""); 
    //     try{
    //     const emailExistsResponse = await axios.post(`${API_URL}/check-email-exists`,
    //     {
    //       email:event.target.value,
    //     });
    //     const { emailExists } = emailExistsResponse.data;
    //     console.log('Email already exists in the database',emailExistsResponse.data);
    //     if (emailExists) {         
    //       setEmailError("Email already in use. Please use a different email address."); 
    //       setEmailExist(true)
    //     } else {
    //         setEmailExist(false) 
    //         setEmailError(""); 
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }      
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");       
    };


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };


    // const [isChecked, setIsChecked] = useState(false);



    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);
    // };



    const handleTermsClick = () => {
      window.location.href = '/terms-and-condition'
    }



    const handleSignInClick = () => {
        window.location.href = '/signin'
      }


      
      const handleSignUpClick = async () => {
        if (businessName.trim() === "") {
            setBusinessNameError("Please enter your name"); 
                    
        }
        if (email.trim() === "") {
            setEmailError("Please enter your email id"); 
                       
        }
        if (password.trim() === "") {
            setPasswordError("Please create a password");                         
        }
        // if(emailExist){
        //     setEmailError("Email already in use. Please use a different email address.");   
        // }
        else{
            const response = await axios.post(`${API_URL}/insert-serviceprovider-details`,
                    {
                      businessName: businessName,
                      email: email,
                      password: password,
                     
                    });
                 // console.log ("response",response.data.token);                 
                  if(response.data.token){                    
                   const emailresponse = await axios.post(`${API_URL}/email-verify`, {        
                        token: response.data.token,
                    });
                   // console.log(emailresponse.data.result.message)
                    if(emailresponse.data.result.message ==='Email sent successfully'){
                      setEmailVerifyPopup(true)
                    }
                    else{
                      setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.") 
                    }
                   
                    }
                    
            }        
        };

      

        const handleGoogleLoginSuccess = async (argresponse) => {
            try {
             // console.log("argresponse", argresponse);
              const { access_token } = argresponse;          
             const response =   await axios.post(`${API_URL}/signup-with-google`,
              {
                access_token: argresponse.access_token,
               
              });
              if(response.data.emailExists){
                setEmailGoogleSignUpError("Sorry, this Google account is already registered. Please use a different email to sign up.")

               }
              if (response.ok) {
                console.log('User signed up successfully');               
                  const token = response.data.token
                 // console.log('token',response.data.token);
                  window.location.href = '/splanding'
              
                
              } else {
                console.error('Failed to sign up user:', response.statusText);
                // Handle signup failure
              }
            } catch (error) {
              console.error('Error signing up user:', error);
              // Handle error
            }
          };
          
          const handleGoogleLoginFailure = (error) => {
            console.log('Login Failed:', error);
            setEmailGoogleSignUpError("Login Failed")
          };
    
          const login = useGoogleLogin({
            onSuccess: handleGoogleLoginSuccess,
            onFailure: handleGoogleLoginFailure,
          });


          const handleSignUpWithGoogleClick = () => {           
            
        };


        const handleCloseEmailVerify = () => {
            setEmailVerifyPopup(false)
            window.location.href = '/signin'
            
        };

    return (

        // <Grid sx={{ width: '100%', height: '100vh', margin: 0, padding: 0, display: 'flex',  }}>
        // <Box sx={{ width: '100%',  height: '100%', display:'flex', flexDirection:'column', padding: 0,
        //               flexDirection: { md: 'row', sm: 'row', xs: 'column', }}}>
        //     <Box sx={{width: { md: 'calc(30% - 10%)', sm: 'calc(40% - 10%)', xs: 'calc(90% - 10%)' },
        // height: { md: 'calc(100% - 10%)', sm: 'calc(100% - 10%)', xs: 'calc(53% - 10%)' },border:'1px solid red',
        //      backgroundImage:`url(${mainBg})`, backgroundSize: 'cover', backgroundPosition: 'center', 
        //      padding:{ md:'5% 10%', sm: '5%', xs: '5%' } , display:'flex', flexDirection:'column',
        //       gap:{ md:'24px', sm: '10px', xs: '10px' }}}>
        //       <Box sx={{ width: '100%', height: { md: '30%', sm: '50%', xs: '50%' }, display:'flex', flexDirection:'column',
        //        gap:'16px',border:'1px solid red',}}>                           
        //       <TypographyComponent sx={{ fontSize:{ md:'60px', sm: '30px', xs: '30px' } , color: Colors.white, fontWeight:800 }}>Welcome</TypographyComponent>
        //       <TypographyComponent sx={{ fontSize: '14px', color: Colors.white, fontWeight:600 }}>
        //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt 
        //         ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        //       </TypographyComponent>
        //       </Box>
        //       <Box sx={{ width: { md:'calc(100% + 38%)', sm: 'calc(100% + 20%)', xs: 'calc(100%)' }, 
        //       height:{ md: 'calc(70% - 24px)', sm: '60%', xs: 'calc(60%)' },  display:'flex',border:'1px solid red',
        //        flexDirection:'row', paddingLeft:{ md:'38%', sm: '15%', xs: 0 } , paddingTop:{ md:0, sm: 0, xs: '16%' }
        //       }}>
        //       <img src={mainImage} alt="" style={{ width: '100%', height: '100%',}} />
        //       </Box>
        //     </Box>
           
        //     <Box sx={{
        //          width: { md: 'calc(70% - 10%)', sm: 'calc(50% - 10%)', xs: 'calc(90% - 10%)' },
        //          height: { md: 'calc(100% - 10%)', sm: 'calc(100% - 10%)', xs: 'calc(53% - 10%)' },
        //          padding: { md:'5% 10%', sm: '5%', xs: '5%' }, display: 'flex', flexDirection: 'column', gap: '48px',
        //         justifyContent: 'center', paddingTop:{ md:0, sm: 0, xs: '10%' }
        //     }}>
        //         <Box sx={{ gap: '8px' }}>
        //             <TypographyComponent sx={{ fontSize: '32px', color: Colors.black_70, }}>Create an account</TypographyComponent>
        //             <TypographyComponent sx={{ fontSize: '20px', color: Colors.grey_60, }}>Welcome to leboco</TypographyComponent>
        //         </Box>
        //         <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
        //             <Box sx={{ gap: '8px' }}>
        //                 <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Business name*</TypographyComponent>
        //                 <TextFieldComponent placeholder="Enter your business name"
        //                  onChange={handleBusinessNameChange} error={!!businessNameError}></TextFieldComponent>
        //                  {businessNameError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{businessNameError}</TypographyComponent>}
        //             </Box>
        //             <Box sx={{ gap: '8px' }}>
        //                 <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Email*</TypographyComponent>
        //                 <TextFieldComponent placeholder="Enter your business email"
        //                  onChange={handleEmailChange} error={!!emailError}></TextFieldComponent>
        //                  {emailError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{emailError}</TypographyComponent>}
        //             </Box>
        //             <Box sx={{ gap: '8px' }}>
        //                 <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Password*</TypographyComponent>
        //                 <TextFieldComponent placeholder="Enter your password"
        //                  onChange={handlePasswordChange} error={!!passwordError}
        //                  type={showPassword ? 'text' : 'password'}
        //                  InputProps={{endAdornment: (
        //                  <InputAdornment position="end">
        //                       <IconButton
        //                         onClick={togglePasswordVisibility}
        //                         edge="end"
        //                         aria-label="toggle password visibility"
        //                         color="primary">
        //                         {showPassword ? (
        //                           <VisibilityOutlinedIcon sx={{ color: Colors.grey_60 }} />
        //                         ) : (
        //                           <VisibilityOffOutlinedIcon sx={{ color: Colors.grey_60 }} />
        //                         )}
        //                       </IconButton>
        //                   </InputAdornment>
        //                   ),}}></TextFieldComponent>
        //                  {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}
        //             </Box>
        //             {/* <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row', }}>
        //                 <Checkbox
        //                     checked={isChecked}
        //                     onChange={(event) => handleCheckboxChange(event)}
        //                     sx={{
        //                         '&.MuiCheckbox-root.Mui-checked': {
        //                             color: '#fff', background: '#212427', height:'5px', width:'5px',
        //                             borderRadius: '4px', 
        //                         },
        //                         '& .MuiSvgIcon-root': {
        //                             width: '19.8px', height: '19px', 
        //                           },
        //                         color: Colors.black_80, background: 'white', height:'5px', width:'5px',                               
        //                     }}
        //                 />
        //                 <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>I agree to the </TypographyComponent>
        //                 <TypographyComponent sx={{ fontSize: '14px', color: '#504FA4', cursor: 'pointer', }}
        //                 onClick={handleTermsClick}>terms & condition</TypographyComponent>
        //             </Box> */}
        //         </Box>
        //         <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
        //             <ButtonComponent  onClick={handleSignUpClick}>Sign up</ButtonComponent>
        //              <Button onClick={() => login()} variant="contained" sx={{
        //                 width: '100%', height: '48px', color: Colors.black_70, borderRadius: '28px', border: '1px solid #C5C5C5',
        //                 backgroundColor: '#fff', textTransform: 'none', boxShadow: 'none',
        //                 fontFamily: 'Roboto', fontSize:'16px', fontWeight:'400',
        //                 '&:hover': { color: Colors.black_70, background: '#fff', boxShadow: 'none', },
        //             }} startIcon={<img src={GoogleIcon} alt="Google Logo"
        //                 style={{ width: '24px', height: '24px', }} />}>Sign up with google

        //             </Button> 
        //             {emailGoogleSignUpError && <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{emailGoogleSignUpError}</TypographyComponent>}

        //             <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' , 
        //             marginBottom:{ md:0, sm: 0, xs: '5%' }}}>
        //                 <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, }}>Already have an account?</TypographyComponent>
        //                 <TypographyComponent sx={{ fontSize: '16px', color: Colors.link, cursor: 'pointer', }}
        //                  onClick={handleSignInClick}>  Sign in</TypographyComponent>

        //             </Box>
        //         </Box>
        //     </Box>

        // </Box>
        //     {emailVerifyPopup &&
        // <Box sx={{
        //   position: 'fixed',
        //   top: 0,
        //   left: 0,
        //   width: '100%',
        //   height: '100%',
        //   backgroundColor: 'rgba(0, 0, 0, 0.6)',
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   zIndex: '2',

        // }}
        // >
        //   <Box
        //     sx={{
        //       position: 'absolute',
        //       width: { md: '450px', sm: '420px', xs: '320px' },
        //       height:{ md:' 52%', sm:'52%', xs: '50%' } ,
        //       backgroundColor: '#fff',
        //       border: '1px solid ',
        //       borderColor:'Colors.grey_30',
        //       borderRadius: '28px ',
        //       boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
        //       boxSizing: 'border-box',
        //       zIndex: '3',
        //       padding: { md:'35px', sm:'24px', xs: '24px' },
        //       alignItems: 'center',
        //       overflow: 'auto',
             
        //     }}
        //   >
        //     <IconButton onClick={handleCloseEmailVerify} sx={{
        //       position: 'fixed', width: '36px', height: '36px',
        //       left:{ md:'62.5%', sm:'65%', xs: '82%' } , top: '17%'
        //     }} >
        //       <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close }} ></CancelIcon>
        //     </IconButton>
        //     <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: { md:'24px', sm:'16px', xs: '16px' } }}>
        //       <Box sx={{
        //         display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        //         alignContent: 'center', width: '100%'
        //       }}>

        //         <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: Colors.black_70, }}>
        //         Verify your email </TypographyComponent>
        //         <MailOutlineOutlinedIcon style={{width:'50px', height:'50px', paddingTop:'10px', color:Colors.secondary}}>                    
        //         </MailOutlineOutlinedIcon>
        //       </Box>
              
        //       <Box sx={{
        //         display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        //         alignContent: 'center', width: '100%'
        //       }}>

        //         <TypographyComponent sx={{ fontSize:{ md: '16px', sm: '14px', xs: '14px' },color: Colors.black_80 }}>
        //         Thank you for taking the time to create a profile on Leboco! Your verification email has been sent. 
        //         Please check your inbox to complete the process. </TypographyComponent>

        //       </Box>


        //       <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px',justifyContent:'center',
        //         alignContent:'center', alignItems:'center' }}>
        //              <Box sx={{ width:'40%' }}>
        //         <ButtonComponent onClick={handleCloseEmailVerify} >Ok</ButtonComponent>               
        //         </Box>
             
        //       </Box>


        //     </Box>
        //   </Box>
        // </Box>}





        // </Grid>
        <Grid sx={{ width: '100%', height: '100%', margin: 0, padding: 0,   }}>
        <Box sx={{width:'100%',  height:'100vh', display:'flex', flexDirection:{ md: 'row', sm: 'row', xs: 'column'},
        alignItems: 'stretch',}}>
          <Box sx={{width:{ md:'35%', sm:'35%', xs: '100%'}, flex: 1,display:'flex', flexDirection:'column',
          backgroundImage:`url(${mainBg})`,backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
           backgroundPosition: 'center',  justifyContent:'center',alignContent:'center', alignItems:'center'}}>
            <Box sx={{width:'100%', height:'100%', display:'flex',flexDirection:'column',maxWidth:{ md:'75%', sm:'75%', xs: '90%'},
            maxHeight:{ md:'90%', sm:'90%', xs: '85%'},          }}>
            <Box sx={{ width: '100%', height: '30%', display:'flex', flexDirection:'column',
               gap:'16px',  }}>                           
              <TypographyComponent sx={{ fontSize:{ md:'60px', sm: '30px', xs: '30px' } , color: Colors.white, fontWeight:800 }}>Welcome</TypographyComponent>
              <TypographyComponent sx={{ fontSize: '14px', color: Colors.white, fontWeight:400 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              </TypographyComponent>
              </Box>
              <Box sx={{ width: '100%', flexDirection:'column', justifyContent:'center',alignItems:'center',
              height:'70%',alignContent:'center',  display:'flex',
              }}>
                <Box sx={{ width: '100%', flexDirection:'column', justifyContent:'center',alignItems:'center',
              height:'100%',maxHeight:'80%',alignContent:'center',  display:'flex',maxWidth:{ md:'100%', sm:'100%', xs: '80%'},
              paddingLeft:{ md:'95%', sm:'95%', xs: '0%'},paddingTop:{ md:'0%', sm:'0%', xs: '25%'}

              }}>
             <img src={mainImage} alt="" style={{ width: '100%', height: '100%',}} />
              </Box>
             
              </Box>
            </Box>
           </Box>
          <Box sx={{width:{ md:'65%', sm:'65%', xs: '100%'}, flex: 2,display:'flex',
           flexDirection:'column',justifyContent:'center', alignItems:'center',}}>
          <Box sx={{width:'100%', height:'100%', display:'flex',flexDirection:'column',maxWidth:{ md:'70%', sm:'70%', xs: '90%'},
            maxHeight:{ md:'90%', sm:'90%', xs: '85%'},   gap: '48px',}}>
 <Box sx={{ gap: '8px' }}>
                 <TypographyComponent sx={{ fontSize: '32px', color: Colors.black_70, }}>Create an account</TypographyComponent>
                   <TypographyComponent sx={{ fontSize: '20px', color: Colors.grey_60, }}>Welcome to leboco</TypographyComponent>
               </Box>
               <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
                   <Box sx={{ gap: '8px' }}>
                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Business name*</TypographyComponent>
                        <TextFieldComponent placeholder="Enter your business name"
                         onChange={handleBusinessNameChange} error={!!businessNameError}></TextFieldComponent>
                         {businessNameError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{businessNameError}</TypographyComponent>}
                    </Box>
                    <Box sx={{ gap: '8px' }}>
                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Email*</TypographyComponent>
                        <TextFieldComponent placeholder="Enter your business email"
                         onChange={handleEmailChange} error={!!emailError}></TextFieldComponent>
                         {emailError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{emailError}</TypographyComponent>}
                    </Box>
                    <Box sx={{ gap: '8px' }}>
                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Password*</TypographyComponent>
                        <TextFieldComponent placeholder="Enter your password"
                         onChange={handlePasswordChange} error={!!passwordError}
                         type={showPassword ? 'text' : 'password'}
                         InputProps={{endAdornment: (
                         <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                                aria-label="toggle password visibility"
                                color="primary">
                                {showPassword ? (
                                  <VisibilityOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                ) : (
                                  <VisibilityOffOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                )}
                              </IconButton>
                          </InputAdornment>
                          ),}}></TextFieldComponent>
                         {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}
                    </Box>
                   <Box sx={{ gap: { md:'6px', sm:'4px', xs: '4px'}, display: 'flex', flexDirection: 'row', }}>
                        {/* <Checkbox
                            checked={isChecked}
                            onChange={(event) => handleCheckboxChange(event)}
                            sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                    color: '#fff', background: '#212427', height:'5px', width:'5px',
                                    borderRadius: '4px', 
                                },
                                '& .MuiSvgIcon-root': {
                                    width: '19.8px', height: '19px', 
                                  },
                                color: Colors.black_80, background: 'white', height:'5px', width:'5px',                               
                            }}
                        /> */}
                        <TypographyComponent sx={{ fontSize: { md:'14px', sm:'14px', xs: '12px'}, color: Colors.black_70, }}>
                          By clicking the Sign up button, you agree to our</TypographyComponent>
                        <TypographyComponent sx={{ fontSize: { md:'14px', sm:'14px', xs: '12px'}, color: Colors.link, 
                        cursor: 'pointer', }}
                        onClick={handleTermsClick}>terms & condition</TypographyComponent>
                    </Box> 
                </Box>
                <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
                    <ButtonComponent  onClick={handleSignUpClick}>Sign up</ButtonComponent>
                     <Button onClick={() => login()} variant="contained" sx={{
                        width: '100%', height: { md:'48px' , sm: '48px', xs: '38px'  }, color: Colors.black_70, borderRadius: '28px', border: '1px solid #C5C5C5',
                        backgroundColor: '#fff', textTransform: 'none', boxShadow: 'none',
                        fontFamily: 'Roboto', fontSize:'16px', fontWeight:'400',
                        '&:hover': { color: Colors.black_70, background:Colors.grey_20, boxShadow: 'none', },
                    }} startIcon={<img src={GoogleIcon} alt="Google Logo"
                        style={{ width: '24px', height: '24px', }} />}>Sign up with google

                    </Button> 
                    {emailGoogleSignUpError && <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{emailGoogleSignUpError}</TypographyComponent>}

                    <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' , 
                   }}>
                        <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, }}>Already have an account?</TypographyComponent>
                        <TypographyComponent sx={{ fontSize: '16px', color: Colors.link, cursor: 'pointer',fontWeight:600, '&:hover': { color: '#00669F', fontWeight:600} }}
                         onClick={handleSignInClick}>  Sign in</TypographyComponent>

                    </Box>
                </Box>

              </Box>
          </Box>
        </Box>
        {emailVerifyPopup &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
           <Box sx={{width:'100%', height:'100%',gap:'5px',
                     justifyContent:'center',alignItems:'center', position: 'fixed',
                       display:'flex',flexDirection:'column', }}>
                     <Box sx={{width:{ md: '450px', sm: '420px', xs: '85%' }, height:'36px',
                     justifyContent:'right',alignItems:'right', display:'flex',}}>
 <IconButton onClick={handleCloseEmailVerify} sx={{
             width: '36px', height: '36px',
              
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color:Colors.quinary, '&:hover': {
                      color: Colors.close,
                    },  }} ></CancelIcon>
            </IconButton>
                     </Box>
          <Box
            sx={{
              
              width: { md: '450px', sm: '420px', xs: '85%' },
              height: 'fit-content',
              backgroundColor: '#fff',
              border: '1px solid ',
              borderColor:'Colors.grey_30',
              borderRadius: '28px ',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: { md:'35px', sm:'24px', xs: '24px' },
              alignItems: 'center',
              overflow: 'auto',
             
            }}
          >
           
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: { md:'24px', sm:'16px', xs: '16px' } }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

                <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: Colors.black_70, }}>
                Verify your email </TypographyComponent>
                <MailOutlineOutlinedIcon style={{width:'50px', height:'50px', paddingTop:'10px', color:Colors.secondary}}>                    
                </MailOutlineOutlinedIcon>
              </Box>
              
              <Box sx={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

                <TypographyComponent sx={{ fontSize:{ md: '16px', sm: '14px', xs: '14px' },color: Colors.black_80 }}>
                Thank you for taking the time to create a profile on Leboco! Your verification email has been sent. 
                Please check your inbox to complete the process. </TypographyComponent>

              </Box>


              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px',justifyContent:'center',
                alignContent:'center', alignItems:'center' }}>
                     <Box sx={{ width:'40%' }}>
                <ButtonComponent onClick={handleCloseEmailVerify} >Ok</ButtonComponent>               
                </Box>
             
              </Box>


            </Box>
          </Box>
          </Box>
        </Box>}


        </Grid>
    )

}
export default SignUp;