import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, useMediaQuery, useTheme } from "@mui/material";
import TypographyComponent from './TypographyComponent';
import { useEffect, useState } from 'react';
import profile from '../Images/profileIcon.png';
import { IMG_URL } from './Constants';
import bg1 from '../Images/bg1.jpg';
import bg2 from '../Images/bg2.jpg';
import bg3 from '../Images/bg3.jpg';
import bg4 from '../Images/bg4.jpg';
import bg5 from '../Images/bg5.jpg';
import Colors from '../Components/Colors';
import axios from "axios";
import { API_URL } from './Constants';


function SpBusinessCardFront1(props) {
    const { serviceProviderDtls, serviceProviderOnchangeDtls, selectedImage, serviceDetails,
        serviceProviderQrCode, editbtn, backgroundColor, selectedBgButton, city } = props
    // console.log('serviceProviderOnchangeDtls2', serviceProviderOnchangeDtls)
    // console.log('serviceProviderDtls', serviceProviderDtls)
    //  console.log("sp..", serviceProviderDtls);
    //  console.log("backgroundColor", backgroundColor)
    //  console.log("sssssssssssssppppppppppppppppp", serviceDetails)
    const colors = [ Colors.card_color_1,
        Colors.card_color_2,
        Colors.card_color_3,
        Colors.card_color_4,
        Colors.card_color_5];
        const cardColor = selectedBgButton ? selectedBgButton.replace('b', '') : '1';
        const profileIndex = parseInt(cardColor-1);
    const professionalNames = serviceDetails
        .filter(service => service.professional_name)
        .map(service => service.professional_name)
        .join(', ');
// console.log(".......",serviceProviderDtls)
    let detail
    const theme = useTheme();
    // console.log("newprofile pic check   ,.,.,,.", newProfilePic)
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //  console.log("extrasmall", isXsScreen)
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //  console.log('small', isSmScreen)
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    //  console.log('med', isMdScreen)
    const token = localStorage.getItem('token');

    const avatarSize = isSmScreen ? '65px' : '90px';
    if (serviceDetails.length !== 0) {
        detail = serviceDetails[0];
       
    }
    const displayEmail = serviceProviderOnchangeDtls.signInEmail || serviceProviderDtls.email;

    const CardbgColor = selectedBgButton ? selectedBgButton :
        serviceProviderDtls.card_color;

    let CardbgImage;

    switch (CardbgColor) {
        case 'b1':
            CardbgImage = bg1;
            break;
        case 'b2':
            CardbgImage = bg2;
            break;
        case 'b3':
            CardbgImage = bg3;
            break;
        case 'b4':
            CardbgImage = bg4;
            break;
        case 'b5':
            CardbgImage = bg5;
            break;
        default:
            CardbgImage = bg1; // Default image if color doesn't match any case

    }
    // console.log("CardbgColor2", CardbgColor)
    const fontColor = Colors.white;
    const iconColor = Colors.white;
    useEffect(() => {
        //  console.log("Component mounted or backgroundColor changed:", backgroundColor);
    }, [backgroundColor]);
    function formatDate(dateString) {
        if (!dateString) {
            return ''; // or any default value you want to return
        }
        const parts = dateString.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    const handleClick = () => {
        // localStorage.setItem('businessCardEdit', 'true');
        // Check if editbtn is true before navigating

        const businessCardEdit = editbtn ? 'true' : 'false';
        window.location.href = `/profile-form/${businessCardEdit}`;

    }
    const [professionName, setProfessionName] = useState("");
    const fetchProfession = async () => {
        // const serviceId = serviceDetails[0].services_id
        // console.log( serviceDetails[0].services_id)
        try {
            const response = await axios.post(`${API_URL}/select_profession`,
                { serviceId: serviceDetails[0].services_id, });
            console.log(response.data[0].professional_name)
            setProfessionName(response.data[0].professional_name)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchProfession();

    }, [serviceDetails.services_id]);


    return (
        <Box sx={{
            width: 'calc(100% - 10%)', height: 'calc(100% - 66px)', display: 'flex',
            flexDirection: 'column', borderRadius: '16px',
            backgroundImage: `url(${CardbgImage})`, backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // padding: '33px 16px', 
            pl:'5%',
            pr:'5%',
          pt:'33px',
          pb:'33px',
            gap: '16px'
        }}>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    height: '100%',
                    width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
                    gap: { sm: '16px', md: '8px', xs: '8px', justifyContent: 'flex-start' }
                }}>
                    <Box sx={{ width: "5%" }}>

                    </Box>
                    <Box sx={{
                        flex: 1, width: '25%', alignItems: 'center', display: 'flex',
                        justifyContent: 'center'
                    }}>
                        {selectedImage ? (
                            <Box
                                component="img"
                                src={selectedImage}
                                alt="Profile"
                                sx={{
                                    width: '100%',
                                    maxWidth: { md: '90px', sm: '65px', xs: '65px' },
                                    maxHeight: { md: '90px', sm: '65px', xs: '65px' },
                                    minWidth: { md: '90px', sm: '65px', xs: '65px' },
                                    minHeight: { md: '90px', sm: '65px', xs: '65px' },
                                }}
                            />
                        ) : (
                            serviceProviderDtls?.profile_image ? (
                                <Box
                                    component="img"
                                    src={`${IMG_URL}${serviceProviderDtls.profile_image}`}
                                    alt="Profile"
                                    sx={{
                                        width: '100%',
                                        maxWidth: { md: '90px', sm: '65px', xs: '65px' },
                                        maxHeight: { md: '90px', sm: '65px', xs: '65px' },
                                        minWidth: { md: '90px', sm: '65px', xs: '65px' },
                                        minHeight: { md: '90px', sm: '65px', xs: '65px' },
                                    }}
                                />
                            ) : (
                               
                                <Box
                                    sx={{
                                        width: avatarSize,
                                        height: avatarSize,
                                        borderRadius: '50%',
                                        backgroundColor:  (profileIndex !== null || profileIndex !== undefined ) ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: isSmScreen ? '24px' : '48px',
                                        fontWeight: 'bold',
                                        color: Colors.white,
                                       // border: '2px solid #ffffff',
                                      //  boxShadow: '0px 4px 4px 0px #00000040',
                                    }}
                                >
                                    {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
                                </Box>
                            )
                        )}
                    </Box>
                    <Box sx={{
                        width: '70%', display: 'flex', flexDirection: 'row',
                        gap: '8px', justifyContent: 'space-between'
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', alignItems: 'flex-start',

                            }}>

                                <TypographyComponent sx={{
                                    color: Colors.white, fontSize: { sm: '24px', md: '24px', xs: '16px' }
                                    , fontWeight: 400
                                }}>
                                    {serviceProviderDtls.business_name}</TypographyComponent>
                             
                                <TypographyComponent sx={{
                                    color: Colors.white,
                                    fontSize: { sm: '16px', md: '16px', xs: '12px' },
                                    fontWeight: 400,
                                    maxWidth: '200px', /* Adjust this value as needed */
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {professionalNames}
                                </TypographyComponent>
                                {serviceProviderDtls.contact_person && <TypographyComponent sx={{
                                    color: Colors.white,
                                    fontSize: { sm: '16px', md: '16px', xs: '12px' }
                                    , fontWeight: 400
                                }}>
                                   Contact person: {serviceProviderDtls.contact_person}
                                </TypographyComponent>}

                            </Box>
                        </Box>
                    
                        <Box
                            sx={{
                                height: '100%',
                                cursor: editbtn ? 'pointer' : 'default', // Set cursor based on editbtn
                            }}
                            onClick={handleClick}
                        >
                            {editbtn && <EditOutlinedIcon style={{ color: Colors.white }} />}
                        </Box>
                    </Box>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: { sm: '16px', md: '8px', xs: '8px', justifyContent: 'flex-start' }, marginTop: '-5px' }}>
                    <Divider orientation="horizontal" flexItem sx={{ height: '100%', width: '5%', borderColor: '#C5C5C5' }} />
                    <Box sx={{ width: '25%' }}></Box>
                    <Divider orientation="horizontal" flexItem sx={{ height: '100%', width: '70%', borderColor: '#C5C5C5' }} />
                </Box>
            </Box>
           

            <Box sx={{
                width: '100%', height: '100%', display: 'flex',
                flexDirection: 'row', alignItems: 'center'
            }}>
                <Box sx={{ width: '5%' }}>

                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '25%', height: '100%'
                }}>

                    <Box
                        component="img"
                        src={serviceProviderQrCode}
                        alt="qrcode"
                        sx={{
                            width: { md: '62px', sm: '56px', xs: '40px' },
                            height: { md: '62px', sm: '56px', xs: '40px' }
                        }}

                    />
                    <TypographyComponent sx={{
                        color: fontColor, fontSize: { md: '12px', sm: '12px', xs: '8px' }
                        , letterSpacing: '2px'
                    }}>
                        {serviceProviderDtls.service_provider_code}
                    </TypographyComponent>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '70%',
                    gap: { md: '14px', sm: '14px', xs: '0px' }
                }}>
                    {serviceProviderOnchangeDtls && (
                        <Box sx={{
                            width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                            gap: '8px', alignItems: 'center'
                        }}>
                            <PhoneAndroidIcon sx={{
                                color: iconColor, width: { sm: '26px', md: '20px', xs: '16px' },
                                height: { sm: '16px', md: '20px', xs: '26px' }
                            }}></PhoneAndroidIcon>
                            <TypographyComponent sx={{ color: fontColor, fontSize: { md: '16px', sm: '16px', xs: '8px' } }}>
                                +  {serviceProviderOnchangeDtls.countryCode}
                            </TypographyComponent>
                            <TypographyComponent sx={{ color: fontColor, fontSize: { md: '16px', sm: '16px', xs: '8px' } }}>
                                {serviceProviderOnchangeDtls.phoneNumber}
                            </TypographyComponent>
                        </Box>
                    )}
                    {(serviceProviderOnchangeDtls.signInEmail) && (
                        <Box sx={{
                            width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                            gap: '8px', alignItems: 'center'
                        }}>
                            <EmailIcon sx={{
                                color: iconColor, width: { sm: '26px', md: '20px', xs: '16px' },
                                height: { sm: '16px', md: '20px', xs: '26px' }
                            }}></EmailIcon>
                            <TypographyComponent sx={{ color: fontColor, fontSize: { md: '14px', sm: '14px', xs: '8px' } }}>
                                {serviceProviderOnchangeDtls.signInEmail}
                            </TypographyComponent>
                        </Box>
                    )}
                    {serviceProviderOnchangeDtls && (
                        <Box sx={{
                            width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                            gap: '8px', alignItems: 'center'
                        }}>
                            <LocationOnIcon sx={{
                                color: iconColor, width: { sm: '26px', md: '20px', xs: '16px' },
                                height: { sm: '16px', md: '20px', xs: '26px' }
                            }} />
                            <TypographyComponent sx={{ color: fontColor, fontSize: { md: '14px', sm: '14px', xs: '8px' },
                           }}>
                                {city ? `${city},` : ''} { serviceProviderOnchangeDtls.province}, {serviceProviderOnchangeDtls.country}
                            </TypographyComponent>
                           
                        </Box>
                    )}
                </Box>
            </Box>

        </Box>
    )
}
export default SpBusinessCardFront1;