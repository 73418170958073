import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import TypographyComponent from '../Components/TypographyComponent'
import Colors from '../Components/Colors'
import TextFieldComponent from '../Components/TextFieldComponent'
import ButtonComponent from '../Components/ButtonComponent'
import mainBg from '../Images/mainBg.png'
import mainImage from '../Images/mainImage.png'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from 'axios'
import { API_URL } from './Constants'
import GoogleIcon from '../Images/google.png';
import { useGoogleLogin } from '@react-oauth/google';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";



function SignUpWithPhone() {

    const [businessName, setBusinessName] = useState("");
    const [countryCode, setCountryCode] = useState("1");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");

    const [phoneError, setPhoneError] = useState("");
    const [businessNameError, setBusinessNameError] = useState("");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [emailExist, setEmailExist] = useState(false);
    const [emailVerifyPopup, setEmailVerifyPopup] = useState(false);
    const [emailGoogleSignUpError, setEmailGoogleSignUpError] = useState("");
    const token = localStorage.getItem('token');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState("");
    const [verificationAttempted, setVerificationAttempted] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [termsAndConditionPopup, setTermsAndConditionPopup] = useState(false);
    const handleCloseErrorPopUp = () => {
        setErrorPopUp(false)
    }

    const handleBusinessNameChange = (event) => {
        setBusinessName(event.target.value);
        setBusinessNameError("");
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleCloseEmailVerify = () => {
        setEmailVerifyPopup(false)
        // window.location.href = '/'

    };


    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");

    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        setPhoneError("");
        setEmailGoogleSignUpError("")
    };
    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
    };
    const handleSignInClick = () => {
        window.location.href = '/signin'
    }
    const handleTermsClick = () => {
        // window.location.href = '/terms-and-condition'
        setTermsAndConditionPopup(true)
    }
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // const handleKeyDown = (index, e) => {
    //     // Handle Backspace key press to delete value
    //     if (e.key === 'Enter') {
    //         e.preventDefault(); // Prevent form submission or new line

    //         // Move cursor to next TextField if current value is not empty
    //         if (otp[index].length === 1 && index < otp.length - 1) {
    //             const nextTextField = document.getElementById(`otp${index + 1}`);
    //             if (nextTextField) {
    //                 nextTextField.focus();
    //             }
    //         }
    //     }

    //     // Handle Backspace key press to delete value
    //     if (e.key === 'Backspace') {
    //         const updatedOtp = [...otp];
    //         updatedOtp[index] = '';
    //         setOtp(updatedOtp);

    //         // Move cursor to previous TextField if current value is empty
    //         if (index > 0) {
    //             const prevTextField = document.getElementById(`otp${index - 1}`);
    //             if (prevTextField) {
    //                 prevTextField.focus();
    //             }
    //         }
    //     }
    // };
    // const isAllFieldsFilled = otp.every((digit) => digit.length === 1);
    // const [otp1, setOTP1] = useState('');
    // const [otp2, setOTP2] = useState('');
    // const [otp3, setOTP3] = useState('');
    // const [otp4, setOTP4] = useState('');


    const handleVerifyOTP = async () => {
        const isAnyFieldEmpty = otp.some((digit) => digit.length === 0);
        setVerificationAttempted(true);
        if (isAnyFieldEmpty) {
            console.log("field empty")
        } else {
            const response = await axios.post(`${API_URL}/verify-otp`,
                {
                    countryCode: countryCode,
                    phone: phone,
                    otp: otp,
                    name: businessName,
                    password: password

                });

            // console.log(" verify response", response.data);

            if (response.data.success === true) {

                console.log("otp verification success")
                setEmailVerifyPopup(false);
                window.location.href = '/signin'
            }

            else {
                console.log("otp verification failed")
                setOtpError("Please enter a valid OTP");
            }
        }
    };

    const handleGoogleLoginSuccess = async (argresponse) => {
        try {
            // console.log("argresponse", argresponse);
            const { access_token } = argresponse;
            const response = await axios.post(`${API_URL}/signup-with-google`,
                {
                    access_token: argresponse.access_token,

                });
            if (response.data.emailExists) {
                setEmailGoogleSignUpError("Sorry, this Google account is already registered. Please use a different email to sign up.")

            }
            if (response.ok) {
                console.log('User signed up successfully');
                const token = response.data.token
                // console.log('token', response.data.token);
                window.location.href = '/splanding'


            } else {
                console.error('Failed to sign up user:', response.statusText);
                // Handle signup failure
            }
        } catch (error) {
            console.error('Error signing up user:', error);
            // Handle error
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.log('Login Failed:', error);
        setEmailGoogleSignUpError("Login Failed")
    };
    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };
    const login = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onFailure: handleGoogleLoginFailure,
    });

    // const handleInputChange = (index, value) => {
    //     // Validate input to allow only numbers
    //     if (/^\d*$/.test(value)) {
    //         switch (index) {
    //             case 0:
    //                 setOTP1(value);
    //                 break;
    //             case 1:
    //                 setOTP2(value);
    //                 break;
    //             case 2:
    //                 setOTP3(value);
    //                 break;
    //             case 3:
    //                 setOTP4(value);
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }
    // };

    // const handleInputChange = (index, value) => {

    //     if (!isNaN(value) && value.length <= 1) {
    //         const updatedOtp = [...otp];
    //         updatedOtp[index] = value;
    //         setOtp(updatedOtp);
    //     }
    // };


    const handleSignUpClick = async () => {
        setOtp(['', '', '', '']);
        setOtpError("");
        setErrorPopUp(false);
        setVerificationAttempted(false)
        if (
            businessName.trim() === "" ||
            countryCode.trim() === "" ||
            password.trim() === "" ||
            phone.trim() === "" || phone.length !== 10
        ) {
            // Set individual error messages for each field
            if (businessName.trim() === "") {
                setBusinessNameError("Please enter your name");
            }
            if (countryCode.trim() === "") {
                setCountryCodeError("Please enter your country code");
            }
            if (password.trim() === "") {
                setPasswordError("Please create a password");
            }
            if (phone.trim() === "") {
                setPhoneError("Please enter your phone number");
            }
            if (phone.trim() !== "" && phone.length !== 10) {
                setPhoneError('Phone number must be 10 digits');
            }
            return;
        }
        else {
            try {
                const response = await axios.post(`${API_URL}/otp-creation-signup`,
                    {
                        countryCode: countryCode,
                        phone: phone,
                        name: businessName,
                        password: password

                    });

                //  console.log("response", response);

                if (response.data.success === true) {
                    setEmailVerifyPopup(true)
                }
                else {
                    setEmailGoogleSignUpError("This phone number is already in use.")
                    console.log("an error occured");
                }
            }
            catch (error) {
                setErrorPopUp(true)
            }

        }
    };

    const handleKeyDown = (index, e) => {
        // Handle Enter key press to move to next TextField
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or new line
            if (otp[index].length === 1 && index < otp.length - 1) {
                const nextTextField = document.getElementById(`otp${index + 1}`);
                if (nextTextField) {
                    nextTextField.focus();
                }
            }
        }

        // Handle Backspace key press to clear current field and move focus
        if (e.key === 'Backspace') {
            e.preventDefault(); // Prevent default backspace behavior
            const updatedOtp = [...otp];
            updatedOtp[index] = ''; // Clear the current field
            setOtp(updatedOtp);

            // Move cursor to previous TextField if index is greater than 0
            if (index > 0) {
                const prevTextField = document.getElementById(`otp${index - 1}`);
                if (prevTextField) {
                    prevTextField.focus();
                }
            }
        }
    };

    const handleInputChange = (index, value) => {
        if (!isNaN(value) && value.length <= 1) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
        }
    };

    const handleCloseTerm = () => {

        setTermsAndConditionPopup(false)

    };
    const firstOtpInputRef = useRef(null);

    useEffect(() => {
        if (emailVerifyPopup && firstOtpInputRef.current) {
            firstOtpInputRef.current.focus();
        }
    }, [emailVerifyPopup]);

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').slice(0, otp.length);
        const newOtp = [...otp];

        for (let i = 0; i < pasteData.length && i < otp.length; i++) {
            newOtp[i] = pasteData[i];
        }

        setOtp(newOtp);

        const nextIndex = pasteData.length < otp.length ? pasteData.length : otp.length - 1;
        const nextTextField = document.getElementById(`otp${nextIndex}`);
        if (nextTextField) {
            nextTextField.focus();
        }
    };

    const handleMobilePaste = (e) => {
        e.preventDefault(); // Prevent default paste behavior to avoid issues on mobile

        // Get the pasted data from the clipboard
        const clipboardData = e.clipboardData || window.clipboardData || {};
        const pasteData = clipboardData.getData ? clipboardData.getData('text') : '';

        if (pasteData) {
            console.log('Pasted data:', pasteData); // Log the pasted data for debugging

            // Split the pasted data into individual characters
            const pastedChars = pasteData.split('');

            // Update the OTP array with the pasted characters
            const newOtp = [...otp];
            pastedChars.forEach((char, index) => {
                if (index < otp.length) {
                    newOtp[index] = char;
                }
            });

            // Update the state with the new OTP
            setOtp(newOtp);
        } else {
            console.error('Failed to retrieve paste data'); // Log an error if paste data is not available
        }
    };
    
    const handlePasteOtp = (event) => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === otp.length) {
          const newOtp = [...otp];
          for (let i = 0; i < otp.length; i++) {
            newOtp[i] = pastedData[i] || '';
          }
          setOtp(newOtp);
          event.preventDefault();
        }
      };


    return (
        <Grid sx={{ width: '100%', height: '100%', margin: 0, padding: 0, display: 'flex', flexDirection: 'column' }}>

            <UserHeader></UserHeader>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' },
                alignItems: 'stretch',
            }}>
                <Box sx={{
                    width: { md: '35%', sm: '35%', xs: '100%' }, flex: 1, display: 'flex', flexDirection: 'column',
                    backgroundImage: `url(${mainBg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                    backgroundPosition: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center'
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', maxWidth: { md: '75%', sm: '75%', xs: '90%' },
                        maxHeight: { md: '90%', sm: '90%', xs: '85%' },
                    }}>
                        <Box sx={{
                            width: '100%', height: '30%', display: 'flex', flexDirection: 'column',
                            gap: '16px',
                        }}>
                            <TypographyComponent sx={{ fontSize: { md: '60px', sm: '30px', xs: '30px' }, color: Colors.white, fontWeight: 800 }}>Welcome</TypographyComponent>
                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.white, fontWeight: 400 }}>
                                Find the help you need, right in your neighborhood
                            </TypographyComponent>
                        </Box>
                        <Box sx={{
                            width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                            height: '70%', alignContent: 'center', display: 'flex',
                        }}>
                            <Box sx={{
                                width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                height: '100%', maxHeight: '80%', alignContent: 'center', display: 'flex', maxWidth: { md: '100%', sm: '100%', xs: '80%' },
                                paddingLeft: { md: '95%', sm: '95%', xs: '0%' }, paddingTop: { md: '0%', sm: '0%', xs: '25%' }

                            }}>
                                <img src={mainImage} alt="" style={{ width: '100%', height: '100%', }} />
                            </Box>

                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    width: { md: '65%', sm: '65%', xs: '100%' }, flex: 2, display: 'flex',
                    flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', maxWidth: { md: '70%', sm: '70%', xs: '90%' },
                        maxHeight: { md: '90%', sm: '90%', xs: '85%' }, gap: '24px',
                    }}>
                        <Box sx={{ gap: '8px' }}>
                            <TypographyComponent sx={{ fontSize: '32px', color: Colors.black_70, }}>Create an account</TypographyComponent>
                            <TypographyComponent sx={{ fontSize: '20px', color: Colors.grey_60, }}>Welcome to Brohandy</TypographyComponent>
                        </Box>
                        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
                            <Box sx={{ gap: '8px' }}>
                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Business name*</TypographyComponent>
                                <TextFieldComponent placeholder="Enter your business name"
                                    value={businessName}
                                    onChange={handleBusinessNameChange} error={!!businessNameError}></TextFieldComponent>
                                {businessNameError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{businessNameError}</TypographyComponent>}
                            </Box>

                            <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Box sx={{ width: { md: '20%', sm: '40%', xs: '40%' } }}>
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Country code*</TypographyComponent>
                                    <TextFieldComponent placeholder="+1"
                                        value={countryCode}
                                        onKeyDown={handleKeyDownCountryCode}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={handleCountryCodeChange}
                                        error={!!countryCodeError}></TextFieldComponent>
                                    {countryCodeError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{countryCodeError}</TypographyComponent>}
                                </Box>
                                <Box sx={{ width: { md: '80%', sm: '60%', xs: '60%' } }}>
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Phone number*</TypographyComponent>
                                    <TextFieldComponent placeholder="Add phone number"
                                        onKeyDown={handleKeyDownPhone}
                                        inputProps={{ maxLength: 10 }}
                                        onChange={handlePhoneChange} error={!!phoneError}></TextFieldComponent>
                                    {phoneError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{phoneError}</TypographyComponent>}
                                </Box>
                            </Box>

                            <Box sx={{ gap: '8px' }}>
                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>Password*</TypographyComponent>
                                <TextFieldComponent placeholder="Enter your password"
                                    onChange={handlePasswordChange} error={!!passwordError}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    color="primary">
                                                    {showPassword ? (
                                                        <VisibilityOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}></TextFieldComponent>
                                {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}

                            </Box>
                            {/* <Box sx={{ gap: { md: '6px', sm: '4px', xs: '2px' }, display: 'flex', flexDirection: 'row',flexWrap: 'wrap', }}>

                                <TypographyComponent sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70, }}>
                                    By clicking the Sign up button, you agree to our
                                    <TypographyComponent sx={{  fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.link,
                                    cursor: 'pointer', }}
                                    onClick={handleTermsClick}>terms & condition</TypographyComponent></TypographyComponent>
                                
                            </Box> */}
                            <Box
                                sx={{
                                    gap: { md: '6px', sm: '4px', xs: '2px' }, display: 'flex', flexDirection: 'row',
                                    alignItems: 'center', flexWrap: 'wrap',paddingBottom:{ md: '16px', sm: '16px', xs: '8px' }
                                }}>
                                <TypographyComponent
                                    sx= {{fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,}}  >
                                    By clicking the Sign up button, you agree to our&nbsp;
                                </TypographyComponent>
                                <TypographyComponent
                                    sx={{fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.link, cursor: 'pointer',
                                    }}
                                    onClick={handleTermsClick}  >
                                    terms & condition
                                </TypographyComponent>
                            </Box>

                            {emailGoogleSignUpError && <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{emailGoogleSignUpError}</TypographyComponent>}
                        </Box>

                        <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column',
                             paddingBottom: { md: '64px', sm: '42px', xs: '28px' } }}>
                            <ButtonComponent onClick={handleSignUpClick}>Sign up</ButtonComponent>
                            {/* <Button onClick={() => login()} variant="contained" sx={{
                                width: '100%', height: { md: '48px', sm: '48px', xs: '38px' }, color: Colors.black_70, borderRadius: '28px', border: '1px solid #C5C5C5',
                                backgroundColor: '#fff', textTransform: 'none', boxShadow: 'none',
                                fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                                '&:hover': { color: Colors.black_70, background: Colors.grey_20, boxShadow: 'none', },
                            }} startIcon={<img src={GoogleIcon} alt="Google Logo"
                                style={{ width: '24px', height: '24px', }} />}>Sign up with google

                            </Button> */}
                            {/* {emailGoogleSignUpError && <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{emailGoogleSignUpError}</TypographyComponent>} */}

                            <Box sx={{
                                gap: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                            }}>
                                <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, }}>Already have an account?</TypographyComponent>
                                <TypographyComponent sx={{ fontSize: '16px', color: Colors.link, cursor: 'pointer', fontWeight: 600, '&:hover': { color: '#00669F', fontWeight: 600 } }}
                                    onClick={handleSignInClick}>  Sign in</TypographyComponent>

                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
            <UserFooter></UserFooter>
            {emailVerifyPopup &&
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '2',

                }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '450px', sm: '420px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseEmailVerify} sx={{
                                position: 'fixed', width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '450px', sm: '420px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                // border: '1px solid ',
                                //borderColor: 'Colors.grey_30',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: '3',
                                padding: { md: '35px', sm: '24px', xs: '24px' },
                                alignItems: 'center',
                                overflow: 'auto',

                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column',
                                width: '100%', gap: { md: '38px', sm: '34px', xs: '24px' }
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    alignContent: 'center', width: '100%'
                                }}>

                                    <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                        OTP Verification </TypographyComponent>

                                </Box>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                                    alignContent: 'center', width: '100%'
                                }}>

                                    <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                        We have sent you a one-time password to the mobile number
                                        <span style={{ color: '#626262' }}> XXXXXX{phone.slice(-4)}</span> </TypographyComponent>

                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px' }}>
                                    <TypographyComponent>
                                        Enter your OTP here
                                    </TypographyComponent>
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        {/* <TextField
                                        id="otp1"
                                        value={otp1}
                                        onChange={(e) => handleInputChange(0, e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(0, e)}
                                        InputProps={{
                                            style: { width: '48px', height: '56px', 
                                            margin: '0 5px', background: '#f0f0f0' },
                                             maxLength: 1 
                                        }}
                                    />
                                    <TextField
                                        id="otp2"
                                        value={otp2}
                                        onChange={(e) => handleInputChange(1, e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(1, e)}
                                        InputProps={{
                                            style: { width: '48px', height: '56px', margin: '0 5px', 
                                            background: '#f0f0f0' }, maxLength: 1 
                                        }}
                                    />
                                    <TextField
                                        id="otp3"
                                        value={otp3}
                                        onChange={(e) => handleInputChange(2, e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(2, e)}
                                        InputProps={{
                                            style: { width: '48px', height: '56px', margin: '0 5px', 
                                            background: '#f0f0f0', border: 'none' }, maxLength: 1 
                                        }}
                                    />
                                    <TextField
                                        id="otp4"
                                        value={otp4}
                                        onChange={(e) => handleInputChange(3, e.target.value)}
                                        onKeyPress={(e) => handleKeyPress(3, e)}
                                        InputProps={{
                                            style: { width: '48px', height: '56px', margin: '0 5px', 
                                            background: '#f0f0f0', border: 'none' }, maxLength: 1 
                                        }}
                                        

                                    /> */}
                                        {otp.map((digit, index) => (
                                            <TextField
                                                key={index}
                                                id={`otp${index}`}
                                                value={digit}
                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                onPaste={handlePasteOtp}
                                                error={(verificationAttempted && !digit) || !!otpError}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const digitValue = input.replace(/\D/, '').slice(0, 1);
                                                    handleInputChange(index, digitValue);
                                                    if (digitValue && index < otp.length - 1) {
                                                        const nextTextField = document.getElementById(`otp${index + 1}`);
                                                        if (nextTextField) {
                                                            nextTextField.focus();
                                                        }
                                                    }
                                                }}
                                                inputRef={index === 0 ? firstOtpInputRef : null}
                                                autocomplete="one-time-code"
                                                // onPaste={handlePaste}
                                                onInput={handleMobilePaste}
                                                style={{
                                                    width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',
                                                    //  borderColor: otp[index].length === 0 ? 'red' : '#f0f0f0'

                                                }}
                                            />
                                        ))}
                                    </Box>
                                    {otpError && (
                                        <Box display="flex" justifyContent="center" mt={1}>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                            </TypographyComponent>
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
                                        <TypographyComponent>Don't receive the OTP?</TypographyComponent>
                                        {/* <Link href="/forgot-password" style={{ textDecoration: 'none', color: Colors.link }}>
                                        Resend OTP</Link> */}
                                        <Button
                                            onClick={handleSignUpClick}
                                            sx={{
                                                textDecoration: 'none',
                                                color: Colors.link,
                                                textTransform: 'none',
                                                padding: 0,
                                                minWidth: 'unset',
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // This removes the hover effect
                                                },
                                            }}
                                        >
                                            Resend OTP
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px', justifyContent: 'center',
                                    alignContent: 'center', alignItems: 'center'
                                }}>
                                    <Box sx={{ width: '40%' }}>
                                        <ButtonComponent onClick={handleVerifyOTP} >Verify</ButtonComponent>
                                    </Box>

                                </Box>


                            </Box>
                        </Box>
                    </Box>
                </Box>}
            {errorPopUp &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,

                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '394px', sm: '394px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseErrorPopUp} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '394px', sm: '394px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',
                                alignItems: 'center', gap: "22px"
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '16px',
                                    alignItems: 'center', width: '100%'
                                }}>
                                    <TypographyComponent sx={{
                                        fontSize: '24px', fontWeight: 600,
                                        color: Colors.black_80,
                                    }}>
                                        Sign up failed </TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'center', width: '100%'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_70 }}>
                                            You cannot signup to the website. Try again.
                                        </TypographyComponent>

                                    </Box>
                                </Box>
                                <Box sx={{ width: '30%' }}>
                                    <ButtonComponent onClick={handleCloseErrorPopUp} >ok</ButtonComponent>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

            {termsAndConditionPopup && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '540px', sm: '540px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseTerm} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>

                        <Box
                            sx={{

                                width: { md: '540px', sm: '540px', xs: '85%' },
                                //height: 'fit-content',
                                display: 'flex', flexDirection: 'column',
                                height: { md: '80%', sm: '80%', xs: '85%' },
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px 35px 35px 35px',
                                justifyContent: 'center', alignItems: 'center', paddingTop: '7%',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                '&::-webkit-scrollbar': {
                                    width: '12px',

                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',

                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                    marginTop: '24px',
                                    marginBottom: '24px'
                                },
                            }}
                        >






                            <Box sx={{
                                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                alignItems: 'left', gap: '24px', marginBottom: '5%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                                    gap: '16px',
                                }}>
                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '26px', xs: '22px' }, lineHeight: '24px', fontWeight: 700,
                                        color: Colors.black_80,
                                    }}>
                                        Terms And Conditions
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '24px', xs: '20px' }, lineHeight: '24px',
                                        fontWeight: 700, color: Colors.black_80,
                                    }}>
                                        Last Updated: May 15, 2024
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                                    gap: '16px',
                                }}>




                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                                        fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                                    }}>
                                        User types
                                    </Typography>
                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                                        fontWeight: 400, color: Colors.black_80,
                                    }}>
                                        <strong>Public users:</strong> Individuals who use BroHandy to search for and contact service providers. These users can browse listings without creating an account, ensuring ease of access and privacy.<br />
                                        <strong>Service provider:</strong> Businesses or individual entrepreneurs who register on BroHandy to list their services. They provide business contact details and information about their offerings. Service Providers are responsible for the accuracy and legality of the information they post.
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                                        fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                                    }}>
                                        Disclaimer
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                                        fontWeight: 400, color: Colors.black_80,
                                    }}>
                                        BroHandy operates on a best-effort basis to ensure the availability and reliability of the data on our platform. However, BroHandy is not responsible for any losses, deceit, or misrepresentation that may arise from the information provided by service providers or the actions of public users. Users should exercise due diligence before engaging in any transactions.
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                                        fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                                    }}>
                                        Terms and conditions for service providers
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                                        fontWeight: 400, color: Colors.black_80, paddingBottom: '35px'
                                    }}>
                                        <strong>Account responsibility: </strong> Service Providers must ensure the security of their account details and notify BroHandy immediately if they suspect their account has been compromised. If a Service Provider wishes to delete their profile, they may do so, but they remain responsible for any legal issues arising from their profile while it was active.<br />
                                        <strong>Content and conduct: </strong> Service Providers must ensure that the data they provide does not include offensive, illegal content, and does not pertain to adult, sexual, or narcotic services. All Service Providers must be over the age of 18.<br />
                                        <strong>Privacy and data usage: </strong> Service Providers agree that by entering their business contact details on BroHandy, they relinquish any privacy claims over this information. It is intended solely for business use and to be visible to Public Users of BroHandy.<br />
                                        <strong>Legal liability: </strong> Service Providers are solely responsible for any legal issues that arise from their services or the use of BroHandy. This includes ensuring compliance with local laws and regulations relevant to their service offering.<br />
                                        <strong>Modifications and continuity: </strong> BroHandy reserves the right to modify these terms and conditions at any time. Continued use of the platform after such changes constitutes acceptance of the new terms.

                                    </Typography>


                                </Box>
                            </Box>




                        </Box>
                    </Box>
                </Box>
            )}

        </Grid>
    )
}

export default SignUpWithPhone;