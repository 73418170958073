



import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF, Circle, Autocomplete } from '@react-google-maps/api';
import { Box, InputBase } from "@mui/material";
import TypographyComponent from "./TypographyComponent";
import Slider from '@mui/material/Slider';
import Colors from './Colors';
import axios from "axios";
import { API_URL } from './Constants';
import ButtonComponent from "../Components/ButtonComponent";
import SearchIcon from '@mui/icons-material/Search';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100%',
};


function GooglemapPage({ serviceProviderDtls, editworkingradius, setChoosedLocation, setSelectedLatitude, 
  setSelectedLongitude, choosedLocation, selectedLatitude, selectedLongitude, setEditMap, setProvinceError, provinceError }) {
  //console.log(selectedLatitude)
 // console.log(selectedLongitude)
  const [selectedLatLng, setSelectedLatLng] = useState(null);
const [isLocationSelected, setIsLocationSelected] = useState(false);
const [isRadiusUpdated, setIsRadiusUpdated] = useState(false);
  const [workingRadius, setWorkingRadius] = useState(0);
  const token = localStorage.getItem('token');
  const [autocomplete, setAutocomplete] = useState(null);
 const [selectedLocation, setSelectedLocation]= useState('');
 const [searhCharLocationpopup, setsearhCharLocationpopup] = useState('');
 const [isProvinceMatch, setIsProvinceMatch] = useState(false);
 const [selectedProvince, setSelectedProvince]= useState('');
let province;
const provinceOld = localStorage.getItem("province")
  const handleDistanceChange = (event, newValue) => {
    setWorkingRadius(newValue);
    setIsRadiusUpdated(true)
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const AutocompleteOptions = {
    componentRestrictions: { country: 'CA' }, // Limit results to Canada
  };

  const getCityNameFromLatLng = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.status === 'OK' && data.results.length > 0) {
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('locality')) {
              return component.long_name; // City name
            }
          }
        }
        // If no locality found, return a more general administrative area
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('administrative_area_level_1')) {
              return component.long_name; // State or province name
            }
          }
        }
      } else {
        console.error('Error fetching city name:', data.status, data.error_message || '');
        return null;
      }
    } catch (error) {
      console.error('Error fetching city name:', error);
      return null;
    }
  };

  

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      // console.log('Place object:', place);
      setIsLocationSelected(true);
      let city = 'Location not found';
      let province = 'Province not found';
  
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        const selectedLatLng = [lat(), lng()];
        setSelectedLatLng(selectedLatLng);
        setSelectedLatitude(lat());
        setSelectedLongitude(lng());
        localStorage.setItem('Latitude', lat());
        localStorage.setItem('Longitude', lng());
        // Extract city and province names from address components
        let cityFound = false;
        let provinceFound = false;
        
        for (const component of place.address_components) {
          if (component.types.includes('locality')) {
            city = component.long_name;
            cityFound = true;
          }
          if (component.types.includes('administrative_area_level_1')) {
            province = component.long_name;
            provinceFound = true;
          }
        }
  
        // If city name not found, fetch it using coordinates
        if (!cityFound) {
          city = await getCityNameFromLatLng(lat, lng);
        }
  
        // Handle province validation
        if (province !== serviceProviderDtls.province ) {
          setProvinceError("The chosen location does not belong to the selected province.");
        } else {
          setProvinceError("");
        }
  
        setSelectedProvince(province);
        localStorage.setItem("provinceAddLocation", province);
        setSelectedLocation(city);
        setChoosedLocation(city);
        setsearhCharLocationpopup(city);
  
      } else {
        console.log('No geometry found for the selected place.');
      }
    } else {
      console.log('Autocomplete is null');
    }
  };

  const fetchLocation = async () => {
    try {
  
      const response = await fetch('https://ipapi.co/json/');
    
      const data = await response.json();

      const city = data.city;
      const province = data.region;
   
      const latitude = data.latitude;
      const longitude = data.longitude;
      const selectedLatLng = [data.latitude, data.longitude];
      setSelectedLatLng(selectedLatLng);
      //setSelectedLatLng([latitude, longitude])
      //console.log(selectedLatLng)
      setSelectedLatitude(data.latitude)
      setSelectedLongitude(data.longitude)
      setSelectedProvince(province) 
      localStorage.setItem("provinceAddLocation",province)
      setChoosedLocation(city);
      setSelectedLocation(city)
      setsearhCharLocationpopup(city)
      localStorage.setItem('Latitude', latitude);
      localStorage.setItem('Longitude', longitude);
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };
  

  useEffect(() => { 
    if(serviceProviderDtls){
      setSelectedLocation(serviceProviderDtls.city)
    }
    if (serviceProviderDtls.latitude && serviceProviderDtls.longitude) {
      const initialLocation = {
        lat: parseFloat(serviceProviderDtls.latitude),
        lng: parseFloat(serviceProviderDtls.longitude),
      };
   
      setSelectedLatitude(parseFloat(serviceProviderDtls.latitude));
      setSelectedLongitude(parseFloat(serviceProviderDtls.longitude));
    }

   
  }, [serviceProviderDtls, selectedLatitude, selectedLongitude]);

  useEffect(() => {
   console.log(serviceProviderDtls)
    if (serviceProviderDtls.city) {
      const initialLocation = {
        lat: parseFloat(serviceProviderDtls.latitude),
        lng: parseFloat(serviceProviderDtls.longitude),
      };
     
      setSelectedLatLng([parseFloat(serviceProviderDtls.latitude) , parseFloat(serviceProviderDtls.longitude)]);
    }
    else{
     fetchLocation() ;
    }
     if (serviceProviderDtls.working_radius) {
      setWorkingRadius(serviceProviderDtls.working_radius);
    }

  
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const handleMapClick = async (event) => {
    setIsLocationSelected(true)
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLatLng([lat, lng]);
    setSelectedLatitude(lat);
    setSelectedLongitude(lng);

    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find(
          (component) => component.types.includes('locality')
        );
        const provinceComponent = addressComponents.find(
          (component) => component.types.includes('administrative_area_level_1')
      );
        const city = cityComponent ? cityComponent.long_name : 'Location not found';
       province = provinceComponent ? provinceComponent.long_name : 'Province not found';
        if(province !== serviceProviderDtls.province)
          {
            setProvinceError(" The chosen location does not belong to the selected province. ")
          }
          if(province === serviceProviderDtls.province){
            setProvinceError("")
          }
          setSelectedProvince(province) 
          localStorage.setItem("provinceAddLocation",province)
      //  console.log(province)
        setChoosedLocation(city);
        setSelectedLocation(city)
        setsearhCharLocationpopup(city)
        localStorage.setItem('Latitude', lat);
        localStorage.setItem('Longitude', lng);
      } else {
        console.error('Error fetching location details:', data.error_message);
      }
    } catch (error) {
      console.error('Error fetching location details:', error.message);
    }
  };

 
   
      



  const handleMapDoneClick = async () => {
    setEditMap(true);
   console.log(isLocationSelected)
    if(!isLocationSelected && !isRadiusUpdated){
      setChoosedLocation(serviceProviderDtls.city)
      setSelectedLatLng([parseFloat(serviceProviderDtls.latitude) , parseFloat(serviceProviderDtls.longitude)])
      setEditMap(false);
    }
    else if(!isLocationSelected && isRadiusUpdated){
      setChoosedLocation(serviceProviderDtls.city)
      setSelectedLatLng([parseFloat(serviceProviderDtls.latitude) , parseFloat(serviceProviderDtls.longitude)])
      try {
        const response = await axios.post(`${API_URL}/add-location`, {
          workingRadius: workingRadius,
          city: serviceProviderDtls.city,
          latitude: parseFloat(serviceProviderDtls.latitude),
          longitude: parseFloat(serviceProviderDtls.longitude),
          token,
        });
        if (response.data.success) {
          setEditMap(false);
         
          if(selectedProvince){
          localStorage.setItem("province",selectedProvince)
          }
          else{
            localStorage.setItem("province",provinceOld)
          }
        }
        else{
          localStorage.setItem("province",provinceOld)
        }
      } catch (error) {     
        console.error('Error:', error.message);
      }
    } 
   
 
    else{
      console.log(workingRadius)
    try {
      const response = await axios.post(`${API_URL}/add-location`, {
        workingRadius: workingRadius,
        city: choosedLocation,
        latitude: selectedLatLng[0],
        longitude: selectedLatLng[1],
        token,
      });
      if (response.data.success) {
        setEditMap(false);
       
        if(selectedProvince){
        localStorage.setItem("province",selectedProvince)
        }
        else{
          localStorage.setItem("province",provinceOld)
        }
      }
      else{
        localStorage.setItem("province",provinceOld)
      }
    } catch (error) {     
      console.error('Error:', error.message);
    }}
  };
//console.log(selectedLatLng)
  const selectedLat = selectedLatLng ? selectedLatLng[0] :  43.732506485371125;
  const selectedLng = selectedLatLng ? selectedLatLng[1] :  -79.76642535889613;
  // const selectedLat = serviceProviderDtls.latitude ? parseFloat(serviceProviderDtls.latitude) : selectedLatLng[0] ;
  // const selectedLng = serviceProviderDtls.longitude ? parseFloat(serviceProviderDtls.longitude) : selectedLatLng[1] ;
  
  //console.log(selectedLat)
  
  return (
    <Box sx={{
      width: {md:  'calc(100% - 56px)', sm:  'calc(100% - 56px)', xs:  'calc(100% - 16px)' },
      height: {md: '80vh', sm:  '80vh', xs:  '60vh' },
      display: 'flex',
      flexDirection: 'column',
      gap:editworkingradius? '16px': '32px',
      borderRadius: '16px',
      background: '#F9F9F9',
      padding: {md: '28px 28px 0 28px', sm: '28px 28px 0 28px', xs: '16px 8px 0 8px' } ,
    }}>
      {!editworkingradius && (
        <Box sx={{
          width: '100%',
          height:{ md: '106px', sm: '104px', xs: '136px' },
          display: 'flex',
          flexDirection: 'column',
          gap:{ md: '16px', sm: '12px', xs: '8px' },
        }}>
        { !provinceError && 
        <Box sx={{ width: '100%', height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80 }}>
              Click here to update the location and working radius
            </TypographyComponent>
            <ButtonComponent disabled={searhCharLocationpopup === 'Location not found'|| !!provinceError} sx={{ width: '17%' }}
             onClick={handleMapDoneClick}>
              Done
            </ButtonComponent>
          </Box>}
          {provinceError && 
          <Box sx={{ width: '100%', height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
           <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{provinceError}</TypographyComponent>
          <ButtonComponent disabled={searhCharLocationpopup === 'Location not found' || !!provinceError} sx={{ width: '17%' }}
           onClick={handleMapDoneClick}>
            Done
          </ButtonComponent>
        </Box>}
        
          <Box sx={{
            width: '100%',
            height: { md: '32px', sm: '32px', xs: '72px' },
            display: 'flex',
            flexDirection: { md: 'row', sm: 'row', xs: 'column' },
            justifyContent: 'space-between',
          }}>
            <div >
          <TypographyComponent sx={{ fontSize:  { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80,
               display: 'inline',  }}>
                Set your work territory : <span style={{ fontWeight: 500 }}>{choosedLocation}</span>
              </TypographyComponent>
            
             
            </div>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              width: { md: '65%', sm: '65%', xs: '100%' },
              height: '100%',
              gap:  { md: '24px', sm: '16px', xs: '12px' },
              justifyContent: { md: 'right', sm: 'right', xs: 'left' },
              alignItems:'center'
            }}>
              <TypographyComponent sx={{ fontSize:  { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80 }}>
                Working Radius
              </TypographyComponent>
              <Slider
                value={workingRadius}
                onChange={handleDistanceChange}
                min={0}
                max={200}
                valueLabelDisplay="auto"
                aria-labelledby="distance-slider"
                style={{ color: Colors.Hovering, width: '42%',minWidth:'42%',maxWidth:'42%' }}
              />
              <TypographyComponent sx={{ fontSize:  { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80,width:'75px' }}>
                {workingRadius} KM
              </TypographyComponent>
            </Box>           
          </Box>
        
          <Box sx={{ width: '100%', height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center',
             justifyContent: 'space-between' }}>
          <Box sx={{display:'flex',flexDirection:'column',width:{ md: '50%', sm: '50%', xs: '100%' }
               }}>
               
                <Autocomplete id="autocomplete_id" onLoad={onLoad} onPlaceChanged={onPlaceChanged} options={AutocompleteOptions} style={{width:'100% !important'}}>
                  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width:'100%', }}>
                   <SearchIcon
                      style={{ position: 'absolute', right: '2.5%', pointerEvents: 'none', color: 'gray' }}
                    /> 
                    <InputBase
                      placeholder="Search here"
                      sx={{
                         border: 'none', outline: 'none', background: 'transparent', color: 'black',
                        paddingLeft: '8px', border: '1px solid', borderColor: Colors.default, borderRadius: '16px',
                        height: { md: '38px', sm: '38px', xs: '36px' },width:'100%'
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Roboto', fontSize: '16px', color: 'black',width: '100%',
                          '::placeholder': {
                            fontFamily: 'Roboto', fontSize: '16px', color: 'black', paddingLeft: '24px'
                          },
                          '&:hover::placeholder': {
                            color: 'black'
                          }
                        }
                      }}
                    />
                  </Box> 
                </Autocomplete>
                </Box>
          </Box>
        </Box>
      )}
        {editworkingradius && (
        <Box sx={{
          width: '100%',
          height: '32px',
          display: 'flex',
          flexDirection: 'column',
         
        }}>
          
          <Box sx={{ width: '100%', height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center', 
          justifyContent: 'space-between' }}>
          <Box sx={{display:'flex',flexDirection:'column',width:{ md: '50%', sm: '50%', xs: '100%' },zIndex:9
               }}>
               
                <Autocomplete id="autocomplete_id" onLoad={onLoad} onPlaceChanged={onPlaceChanged} options={AutocompleteOptions}
                 style={{width:'100% !important'}}>
                  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width:'100%', }}>
                   <SearchIcon
                      style={{ position: 'absolute', right: '2.5%', pointerEvents: 'none', color: 'gray' }}
                    /> 
                    <InputBase
                      placeholder="Search here"
                      sx={{
                         border: 'none', outline: 'none', background: 'transparent', color: 'black',
                        paddingLeft: '8px', border: '1px solid', borderColor: Colors.default, borderRadius: '16px',
                        height: { md: '38px', sm: '38px', xs: '36px' },width:'100%'
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Roboto', fontSize: '16px', color: 'black',width: '100%',
                          '::placeholder': {
                            fontFamily: 'Roboto', fontSize: '16px', color: 'black', paddingLeft: '24px'
                          },
                          '&:hover::placeholder': {
                            color: 'black'
                          }
                        }
                      }}
                    />
                  </Box> 
                </Autocomplete>
                </Box>
          </Box>
        </Box>
      )}     {console.log(selectedLatLng)}  
           <GoogleMap
                mapContainerStyle={{ ...mapContainerStyle, cursor: 'pointer !important' }}
                zoom={12}
                      
                center={selectedLatLng ? { lat: selectedLatLng[0], lng: selectedLatLng[1] } : { lat:  43.732506485371125, lng:  -79.76642535889613}}
                onClick={handleMapClick}
              >
                 
                  {selectedLatLng && (
                  <MarkerF position={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }} />
                )}
              </GoogleMap>
    </Box>
  );
}

export default GooglemapPage;
