

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Badge from '@mui/material/Badge';
import { useState, useEffect, useRef } from 'react';
import Colors from './Colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypographyComponent from './TypographyComponent';
import ButtonComponentWhite from './ButtonComponentWhite';
import ButtonComponent from './ButtonComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from './CircularProgress';
import axios from 'axios';
import { API_URL } from './Constants';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import logo from '../Images/logo.png'
import { useNavigate } from 'react-router-dom';


const pages = ['Home', 'About us', 'Contact us'];

function SpHeader(props) {
  const { percentageComplete, currentPage, isCompleteProfile } = props
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState('');
  const colors = [Colors.card_color_1,
  Colors.card_color_2,
  Colors.card_color_3,
  Colors.card_color_4,
  Colors.card_color_5];
  const token = localStorage.getItem('token');
  let profileIndex;
  const [messageDetails, setMessageDetails] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [serviceProviderDtls, setServiceProviderDtls] = useState(null);
  const [crdcolor, setCrdcolor] = useState(null);
 
  async function fetchServiceProviderDetails() {

    try {
      const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
      console.log("service-provider-details", response.data)
      if (response.data.success) {
        console.log(response.data.data.serviceProvider.card_color)
        setCrdcolor(response.data.data.serviceProvider.card_color)

        console.log("qr", response.data.data.serviceProvider)
        setServiceProviderDtls(response.data.data.serviceProvider)
        setMessageDetails(response.data.data.serviceProvider.message_details)
        const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
          if (message.read === 'Unread') {
            return acc + 1;
          }
          return acc;
        }, 0);
        setUnreadMessageCount(unreadCount);


      } else {

        console.error('Error:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }

  useEffect(() => {
    fetchServiceProviderDetails();
    const intervalId = setInterval(fetchServiceProviderDetails, 1000);
    return () => clearInterval(intervalId); 
  }, []);
  console.log(serviceProviderDtls)



    console.log(crdcolor)
    const cardColor = crdcolor ? crdcolor.replace('b', '') : '1';
    profileIndex = parseInt(cardColor - 1);
    console.log(profileIndex)

 

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleMenuItemClick = (page) => {
    setSelectedPage(page);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSignOutPopUp, setIsSignOutPopUp] = useState(false);
  const [isDeletePopUp, setIsDeletePopUp] = useState(false);

  const handleSignOutMenuItemClick = (value) => {
    if (value === "signout") {
      setIsSignOutPopUp(true);
    }
    setShowDropdown(false);
  }
  const handleDeleteProfileMenuItemClick = (value) => {
    if (value === "delete") {
      setIsDeletePopUp(true);
    }
    setShowDropdown(false);
  }
  const navigate = useNavigate();
  const handleSignOutPopUpOkButtonClick = async () => {
    localStorage.removeItem('token');
    navigate('/signin', { replace: true }); 
  };

  const handleSignOutPopUpCancelButtonClick = () => {
    setIsSignOutPopUp(false);

  };
  const handleDeletePopUpCancelButtonClick = () => {
    setIsDeletePopUp(false);

  };
  const handleDeletePopUpOkButtonClick = async () => {
    // localStorage.removeItem('token');
    // window.location.href = '/'

    // console.log("ok clicked");
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${API_URL}/delete-profile`, {
        token
      });
      //  console.log("response", response);
      if (response.data.success === true) {
        window.location.href = '/';
        localStorage.removeItem('token');
      }

    } catch (error) {
      console.error('Error:', error.message);
    }

  };


  const completionPercentage = { percentageComplete };

  const [newProfilePic, setNewProfilePic] = useState(null);


  useEffect(() => {
    console.log(serviceProviderDtls)
    if (serviceProviderDtls && serviceProviderDtls.profile_image) {
      const imgurl = `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${serviceProviderDtls.profile_image}`;
      //  console.log("image ", imgurl)
      setNewProfilePic(imgurl);
    }


  }, [serviceProviderDtls]);

  const handleMessageClick = () => {
    window.location.href = '/sp-landing';
  };
  const handleMessageMobileClick = () => {
    window.location.href = '/message';
  };



  const dropdownRef = useRef(null);
  const profilePicRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      profilePicRef.current &&
      !profilePicRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  return (
    <AppBar position="static" sx={{
      height: { xs: '48px', md: '86px' }, justifyContent: 'center',
      boxShadow: 'none', background: currentPage === 'Home' ? 'white' : 'transparent'
    }}>
      <Container maxWidth="94%" sx={{ marginLeft: '0px', height: { xs: '48px', md: '86px' } }}>
        <Toolbar disableGutters sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ width: { xs: '92px', md: '220px' }, height: { xs: '36px', md: '38px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'left', alignItems: 'center' }}>
            <IconButton
              size="large"
              aria-label="open drawer"
              onClick={handleOpenDrawer}
              color={Colors.black_80}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={openDrawer}
              onClose={handleCloseDrawer}
              sx={{
                '& .MuiDrawer-paper': {
                  backgroundColor: Colors.secondary, // Your desired background color
                },
                backdropFilter: 'none', // Remove blur effect
              }}
              BackdropProps={{ // Customize backdrop properties
                sx: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, // Semi-transparent black background
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <IconButton
                  aria-label="close drawer"
                  onClick={handleCloseDrawer}
                  sx={{ ml: 'auto', color: 'white' }} // Move the close button to the right
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{ width: 200 }}
              >
                {/* {pages.map((page, index) => (
                  <MenuItem
                    key={page}
                    selected={selectedPage === page}
                    onClick={() => handleMenuItemClick(page)}
                    sx={{
                      color: "white", display: "block", textTransform: "none", background: currentPage === page ? '#FFFFFF33' : Colors.secondary,
                      fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: currentPage === page ? 600 : 400,
                      '&:hover': { color: "white", bgcolor: 'white', fontWeight: "600" }
                    }}
                  >
                    <Link to={page === 'Home' ? '/sp-landing' : page === 'About us' ? '/spabout-us' : '/spcontact-us'} style={{ textDecoration: 'none', color: 'inherit' }}>{page}</Link>
                  </MenuItem>
                ))} */}
                {pages.map((page) => (
                  <Link
                    key={page}
                    to={page === 'Home' ? '/sp-landing' : page === 'About us' ? '/spabout-us' : '/spcontact-us'}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <MenuItem
                      selected={selectedPage === page}
                      onClick={() => handleMenuItemClick(page)}
                      sx={{
                        color: "white", display: "block", textTransform: "none", background: currentPage === page ? '#FFFFFF33' : Colors.secondary,
                        fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: currentPage === page ? 600 : 400,
                        '&:hover': { color: "white", bgcolor: 'white', fontWeight: "600" }
                      }}
                    >
                      {page}
                    </MenuItem>
                  </Link>
                ))}
              </Box>
            </Drawer>
          </Box>

          <Box sx={{
            flexGrow: 1, display: { xs: 'none', md: 'flex' }, height: '100%', paddingLeft: '54px', justifyContent: 'left', alignItems: 'center'
          }}>
            {pages.map((page) => (
              <Button
                key={page}
                sx={{
                  color: "#313131", display: "block", textTransform: "none", paddingRight: '56px',
                  fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: currentPage === page ? 700 : 400,
                  '&:hover': { color: "#313131", bgcolor: 'white', fontWeight: "700" }
                }}
              >
                <Link to={page === 'Home' ? '/sp-landing' : page === 'About us' ? '/spabout-us' : '/spcontact-us'} style={{ textDecoration: 'none', color: 'inherit' }}>{page}</Link>
              </Button>
            ))}
          </Box>
          <Box sx={{
            display: "flex", width: { xs: !isCompleteProfile? '60%': '35%', md: '20%' } , flexDirection: 'row', 
            justifyContent: 'right', alignItems: 'center', gap:{ xs: !isCompleteProfile ? '4px':'6px' , md: '12px' },
          }}>
            {!isCompleteProfile && <>
              <CircularProgress radius={16} strokeWidth={2} progress={percentageComplete} />
              <TypographyComponent sx={{ color: '#000000', fontSize: '18px', marginLeft:{ xs: '4px', md: '-10px' }  }}>Completed</TypographyComponent>
            </>}
            <Box sx={{
              width: { xs: '20px', md: '30px'}, height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' },
              justifyContent: 'center', alignItems: 'center',paddingTop:'8px',
            }}>
              <Badge badgeContent={unreadMessageCount} color='error' overlap="circular" >
                <MessageOutlinedIcon color='action' onClick={handleMessageClick} style={{
                  height: "26px", width: '26px',
                  color: Colors.black_80, cursor: 'pointer',
                }}></MessageOutlinedIcon>
              </Badge>
            </Box>

            <Box sx={{
              width: '35px', height: '100%', display: { md: 'none', sm: 'none', xs: 'flex' },
              justifyContent: 'center', alignItems: 'center',paddingTop: isCompleteProfile ? '10px':'0px',
            }}>
              <Badge badgeContent={unreadMessageCount} color='error' overlap="circular" >
                <MessageOutlinedIcon color='action' onClick={handleMessageMobileClick} style={{
                  height: "22px", width: '22px',
                  color: Colors.black_80, cursor: 'pointer',
                }}></MessageOutlinedIcon>
              </Badge>
            </Box>
           
            {(!newProfilePic || newProfilePic === null) && (

              <Box ref={profilePicRef} onClick={() => setShowDropdown(!showDropdown)}

                sx={{
                  width:{ xs: '28px', md:  '32px' }, height: { xs: '28px', md:  '32px' }, borderRadius: '50%',
                  backgroundColor: (profileIndex !== null || profileIndex !== undefined) ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                  fontSize: { xs: '14px', md: '24px' }, fontWeight: 'bold', color: Colors.white,
                  // border: '2px solid #ffffff',
                  // boxShadow: '0px 4px 4px 0px #00000040',cursor:'pointer'
                }}
              >
                {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
              </Box>
            )}
            {newProfilePic && (
              <img src={newProfilePic} alt="" onClick={() => setShowDropdown(!showDropdown)} ref={profilePicRef} style={{
                height: "32px", width: '32px', objectFit: 'cover', borderRadius: '50%',
                cursor: 'pointer',
              }} />
            )}
          </Box>

          {showDropdown && (

            <Box ref={dropdownRef} className="dropdown__menu" sx={{
              position: 'absolute', right: '0px', top: '65px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
              lineHeight: '20px', width: '170px', background: Colors.white, borderRadius: '8px', boxShadow: '0px 4px 4px 0px #00000040', zIndex: 1,
              color: 'white', padding: '8px', border: '1px solid', borderColor: Colors.grey_30
            }}>


              <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
       background-color: #415FE2;
       color: #FFFFFF !important;
     }
     .dropdown-menu__triangle {
      position: absolute;
      top: -10px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      border-top: 10px solid ${Colors.grey_30};
      box-shadow: 0px 4px 4px 0px #00000040;
    }
   
   `}
              </style>

              <MenuItem onClick={() => handleSignOutMenuItemClick("signout")} value="option2" style={{
                fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                Sign out
              </MenuItem>
              <MenuItem onClick={() => handleDeleteProfileMenuItemClick("delete")} value="option3" style={{
                fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                Delete profile
              </MenuItem>
            </Box>

          )}
          {isSignOutPopUp &&
            <Box sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',

            }}
            >
              <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
              }}>
                <Box sx={{
                  width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                  justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                  <IconButton onClick={handleSignOutPopUpCancelButtonClick} sx={{
                    width: '36px', height: '36px',

                  }} >
                    <CancelIcon sx={{
                      width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                        color: Colors.close,
                      },
                    }} ></CancelIcon>
                  </IconButton>
                </Box>
                <Box
                  sx={{

                    width: { md: '375px', sm: '380px', xs: '85%' },
                    height: 'fit-content',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '28px ',
                    boxShadow: 'none',
                    boxSizing: 'border-box',
                    zIndex: 999,
                    padding: '35px',
                    alignItems: 'center',

                  }}
                >

                  <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                    justifyContent: 'center', alignContent: 'center', height: '100%'
                  }}>
                    <Box sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                      alignContent: 'center', width: '100%', gap: '16px'
                    }}>
                      <TypographyComponent sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                        Sign out</TypographyComponent>

                      <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                        Are you sure you would like to sign out your account?</TypographyComponent>

                    </Box>


                    <Box sx={{
                      display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                      alignContent: 'center', alignItems: 'center', width: '100%'
                    }}>
                      <ButtonComponentWhite onClick={handleSignOutPopUpCancelButtonClick}>  Cancel </ButtonComponentWhite>
                      <ButtonComponent onClick={handleSignOutPopUpOkButtonClick}> Sign out </ButtonComponent>
                     

                    </Box>


                  </Box>
                </Box>
              </Box>
            </Box>}
          {isDeletePopUp &&
            <Box sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '2',

            }}
            >
              <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
              }}>
                <Box sx={{
                  width: { md: '568px', sm: '380px', xs: '85%' }, height: '36px',
                  justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                  <IconButton onClick={handleDeletePopUpCancelButtonClick} sx={{
                    width: '36px', height: '36px',

                  }} >
                    <CancelIcon sx={{
                      width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                        color: Colors.close,
                      },
                    }} ></CancelIcon>
                  </IconButton>
                </Box>
                <Box
                  sx={{

                    width: { md: '568px', sm: '380px', xs: '85%' },
                    height: 'fit-content',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '28px ',
                    boxShadow: 'none',
                    boxSizing: 'border-box',
                    zIndex: '3',
                    padding: '35px',
                    alignItems: 'center',

                  }}
                >

                  <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                    justifyContent: 'center', alignContent: 'center', height: '100%'
                  }}>
                    <Box sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                      alignContent: 'center', width: '100%', gap: '16px'
                    }}>
                      <TypographyComponent sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                        Delete account</TypographyComponent>
                      <Box sx={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
                        <Box>
                          <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                            Are you sure you want to delete your account?</TypographyComponent>
                          <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                            This action is permanent.</TypographyComponent>
                        </Box>
                        <Box>
                          <TypographyComponent sx={{
                            fontSize: '16px',
                            color: Colors.black_70, fontWeight: 600
                          }}>
                            Please note:</TypographyComponent>

                        </Box>
                        <Box>
                          <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                            1.Your account and all associated data will be permanently deleted.
                          </TypographyComponent>
                        </Box>
                        <Box>
                          <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                            2.You will no longer have access to your account.
                          </TypographyComponent>
                        </Box>
                        <Box>
                          <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                            3.You will be unable to sign up again using the same contact number.
                          </TypographyComponent>
                        </Box>
                        <Box>
                          <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                            If you're certain about this decision, please click "Delete Account" below. Otherwise, click "Cancel" to return to your account.
                          </TypographyComponent>
                        </Box>
                      </Box>

                    </Box>


                    <Box sx={{
                      display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                      alignContent: 'center', alignItems: 'center', width: '100%'
                    }}>
                      <ButtonComponentWhite onClick={handleDeletePopUpCancelButtonClick}>  Cancel </ButtonComponentWhite>
                      <ButtonComponent onClick={handleDeletePopUpOkButtonClick}> Delete </ButtonComponent>
                    

                    </Box>


                  </Box>
                </Box>
              </Box>
            </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default SpHeader;

