import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent"
import TextFieldComponent from "../Components/TextFieldComponent"
import ButtonComponent from "../Components/ButtonComponent"
import { API_URL } from "../Components/Constants"
import { useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import axios from "axios";
import Colors from '../Components/Colors';
import mainImage from '../Images/mainImage.png'
import mainBg from '../Images/mainBg.png'


function PasswordRecoverySendMail() {

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [resetLinkPopup, setResetLinkPopup] = useState(false);

  const token = localStorage.getItem('token');

  const handleCloseResetLink = () => {
    setResetLinkPopup(false)
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const sendLinkClick = async () => {
   // console.log("link button clicked", email)
    try {
      const response = await axios.post(`${API_URL}/send-password-recovery-mail`, { token, email });
      //console.log("Mail sent successfully", response.data);
      setResetLinkPopup(true);
    }
    catch (error) {
      console.log('Error sending mail');
    }
  }

  return (
    <Stack direction={{ xs: "column", sm: "row", md: "row" }} spacing={2} justifyContent="space-between">
      <Box flex={2} p={{ xs: '5%', sm: '5%', md: '5%' }} sx={{
        backgroundImage: `url(${mainBg})`, height: 'auto',
        backgroundSize: 'cover', position: 'relative'
      }}>
        <Box sx={{ flex: 1 }}>
          <TypographyComponent sx={{ fontSize: '60px', color: Colors.white, fontWeight: 800 }}>Welcome</TypographyComponent>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.white, fontWeight: 600 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          </TypographyComponent>
        </Box>
        <Box sx={{flex: 1,
          width: '100%', maxWidth: '100%', height: 'auto', position: { sm: 'absolute', md: 'absolute' },
          transform: { xs: 'translatey(20%)', sm: 'translateX(20%)', md: 'translateX(20%)' }, top: '40%'
        }}>
          <img src={mainImage} alt="Main" style={{ width: '100%', height: 'auto' }} />
        </Box>


      </Box>
      <Box flex={4} p={{ xs: '10%', sm: '10%', md: '15%' }} sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '36px' }}>
          <TypographyComponent sx={{ fontSize: '32px', color: '#5B5555', }}>Password recovery</TypographyComponent>
          <TypographyComponent sx={{ fontSize: '16px', color: '#5B5555', }}>Enter registered email below. We will email you instructions to reset your password. </TypographyComponent>
          <Box sx={{ gap: '8px' }}>
            <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555' }}
            >
              Email*</TypographyComponent>
            <TextFieldComponent placeholder="Enter your email id"
              error={!!emailError}
              onChange={(event) => handleEmailChange(event)}>
            </TextFieldComponent>
            {emailError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{emailError}</TypographyComponent>}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', }}>
            <ButtonComponent onClick={sendLinkClick}>Send reset link
            </ButtonComponent>
          </Box>
        </Box>

      </Box>
      {resetLinkPopup &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { md: '424px', sm: '400px', xs: '300px' },
              height:{ md: '38%', sm: '25%', xs: '30%' } ,
              backgroundColor: '#fff',
              border: '1px solid #E6E6E6',
              borderRadius: '28px ',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '12px' // Set the width of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#7f7f7f', // Set the color of the thumb
                borderRadius: '28px', // Set the border radius of the thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#e0e0e0', // Set the color of the track
                borderRadius: '28px', // Set the border radius of the track
              },
            }}
          >
            <IconButton onClick={handleCloseResetLink} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: { md:'62.5%', sm:'72%', xs: '80%' }, top:{ md:'25%', sm:'32%', xs:'31%' } 
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color:Colors.quinary, '&:hover': {
                      color: Colors.close,
                    }, }} ></CancelIcon>
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '25px' }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>
                <div style={{ borderRadius: '50%', background: '#0BEA14', width: '45px', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DoneOutlinedIcon style={{ width: '36px', height: '36px', color: 'white' }} />
                </div>
              </Box>

              <Box sx={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

                <TypographyComponent sx={{ fontSize: '16px',textAlign: 'center' }}>
                  Account reset link has been sent to your registered email. please click on the link to reset the password </TypographyComponent>

              </Box>
              {/* <Box sx={{
                display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px', justifyContent: 'center',
                alignContent: 'center', alignItems: 'center'
              }}>
                <Box sx={{ width:'40%' }}>
                <ButtonComponent onClick={handleCloseEmailVerify} >Ok</ButtonComponent>               
                </Box> 

              </Box>*/}


            </Box>
          </Box>
        </Box>}
    </Stack>
  )

}
export default PasswordRecoverySendMail;