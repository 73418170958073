import { Box, Grid, Stack, List, ListItem } from "@mui/material"
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from './Constants';
import SearchComponent from "../Components/SearchComponent";
import UserHeader from "../Components/UserHeader";
import ProfileComponent from "../Components/ProfileComponent";
import ButtonComponent from "../Components/ButtonComponent";
import TypographyComponent from "../Components/TypographyComponent";
import Divider from '@mui/material/Divider';
import ServiceProviders from "../Components/ServiceProviders";
import UserFooter from "../Components/UserFooter";
import Colors from "../Components/Colors";



async function getCoordinates(location) {
  const apiKey = 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs';
  const encodedLocation = encodeURIComponent(location);
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
      throw new Error('Location not found');
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error.message);
    throw error;
  }
}

function SearchDataNew() {
//     const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
//     const [serviceDtls, setServiceDtls] = useState([]);
//     const [selectedIndex, setSelectedIndex] = useState(0);
//     const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
//     const [serviceProviderUrl, setServiceProviderUrl] = useState(null);
//     const [types, setTypes] = useState([]);
//     const [services, setservices] = useState([]);
//     const service = localStorage.getItem("service")
//     const serviceId = localStorage.getItem("serviceId")
//     const Type = localStorage.getItem("Type")
//     const TypeId = localStorage.getItem("TypeId")
//     const [selectedService, setSelectedService] = useState(null);
//     const [serviceProviders, setserviceProviders] = useState([]);
//     const [selectedType, setSelectedType] = useState(null);
//     const [selectedTypeId, setSelectedTypeId] = useState(null);
//     const location = localStorage.getItem("Location")
//     const userLocation = localStorage.getItem("Location")
//     const [spIdChange, setSpIdChange] = useState(false);
//     const [spIdDefaultValue, setSpIdDefaultValue] = useState(null);
//     const [typeAllTypeId, setTypeAllTypeId] = useState(null);
  
//     let ChoosedTypeId
//     useEffect(() => {
//      // console.log("se", serviceProviders)
//       const fetchService = async () => {
//         try {
//          // console.log('serviceId:', serviceId);
//         //  console.log('TypeId:', TypeId);
//           let selectServiceId;
//           if (serviceId || (typeAllTypeId && selectedType === 'All')) {
//            // console.log('Hiii')
//             const coordinates = await getCoordinates(location);
//          //   console.log('Coordinates:', coordinates);
//             if (typeAllTypeId) {
//               selectServiceId = typeAllTypeId;
//             }
//             else {
//               selectServiceId = serviceId
//             }
//             const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers_with_service`, {
//               service_id: selectServiceId,
//               location: userLocation,
//               coordinates: coordinates
//             });
//           //  console.log(serviceProvidersResponse.data.serviceProviders)
//             setserviceProviders(serviceProvidersResponse.data.serviceProviders);
//             const sp = serviceProvidersResponse.data.serviceProviders[0]
//            // console.log(localStorage.getItem("SPId"))
//             localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
//             if (!localStorage.getItem("SPId")) {
//             //  console.log('Entering if condition');
//               localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
//             } else {
//               console.log('SPId not found in localStorage');
//             }
  
  
//           }
//           if ((TypeId && selectedType !== 'All') || (selectedTypeId && selectedType !== 'All')) {
  
//             if (localStorage.getItem("TypeId")) {
//               ChoosedTypeId = localStorage.getItem("TypeId")
//               setSelectedType(Type)
//               setSelectedTypeId(localStorage.getItem("TypeId"))
//             }
//             else {
//               ChoosedTypeId = selectedTypeId
  
//             }
//             const coordinates = await getCoordinates(location);
//            // console.log('ChoosedTypeId:', ChoosedTypeId);
//             const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers_with_types`, {
//               type_id: ChoosedTypeId,
//               location: userLocation,
//               coordinates: coordinates
//             });
//           //  console.log(serviceProvidersResponse.data.serviceProviders)
//             setserviceProviders(serviceProvidersResponse.data.serviceProviders);
//             const sp = serviceProvidersResponse.data.serviceProviders[0]
//             localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
//           //  console.log(localStorage.getItem("SPId"))
//             if (!localStorage.getItem("SPId")) {
//              // console.log('Entering if condition');
//               localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
//             } else {
//               console.log('SPId not found in localStorage');
//             }
  
  
//           }
  
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         }
//       };
//      // console.log(getCoordinates(location))
//       fetchService();
//     }, [serviceId, selectedTypeId, selectedType, ChoosedTypeId]);
  
//     let SelectedServiceId
//     const fetchTypes = async () => {
//     //  console.log(localStorage.getItem("serviceId"))
//     //  console.log(localStorage.getItem("TypeserviceId"))
//       try {
//         if (localStorage.getItem("serviceId")) {
//           SelectedServiceId = localStorage.getItem("serviceId")
//         }
//         else {
//           SelectedServiceId = localStorage.getItem("TypeserviceId")
//         }
//         const response = await axios.post(`${API_URL}/select_all_types`,
//           { serviceId: SelectedServiceId, });
//         setTypes(response.data);
//        // console.log("all types", response.data)
//       } catch (error) {
//         console.error('Error fetching service categories:', error);
//       }
//     };
//     useEffect(() => {
//       if (localStorage.getItem("serviceId") || localStorage.getItem("TypeserviceId")) {
//         fetchTypes();
//       }
  
//     }, [serviceId, selectedTypeId, selectedType, ChoosedTypeId]);
  
  
//     async function fetchServiceProviderDetails() {
//       const spId = localStorage.getItem('SPId');
//      // console.log("spId", spId)
//       try {
//         const response = await axios.post(`${API_URL}/sp-details`, { spId });
//        // console.log("service-provider-details", response.data.data.serviceProvider)
//         if (response.data.success) {
//         //  console.log(response.data.data.serviceProvider)
//           setServiceProviderDtls(response.data.data.serviceProvider)
//           setServiceDtls(response.data.data.serviceProvider.service_details)
//         //  console.log("service-details", response.data.data.serviceProvider.service_details[0])
//           setServiceProviderQrCode(response.data.data.qrCode)
//           setServiceProviderUrl(response.data.data.serviceProviderURL)
//         } else {
//           console.error('Error:', response.data.error);
//         }
//       } catch (error) {
//         console.error('Error:', error.message);
//       }
//     }
//     useEffect(() => {
//       fetchServiceProviderDetails();
//     }, [spIdChange, localStorage.getItem('SPId')]);
//     const service_category = localStorage.getItem("service_category");
//     const service_category_id = localStorage.getItem("service_category_id");
//     const [selectedServiceId, setSelectedServiceId] = useState(null);
//     useEffect(() => {
//       const fetchData = async () => {
//           try {
//               // Fetch services
//               const coordinates = await getCoordinates(location);
//              // console.log('Coordinates:', coordinates);
//               const servicesResponse = await axios.post(`${API_URL}/select_services`, { service_category_id });
//               setservices(servicesResponse.data);
//              // console.log("services", servicesResponse.data);
//               setSelectedService(servicesResponse.data[0].services);
//               setSelectedServiceId(servicesResponse.data[0].service_id)

//               // Fetch service providers based on the first result
//               if (servicesResponse.data.length > 0) {
//                   const serviceId = servicesResponse.data[0].service_id;
//                   const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers`, {
//                       service_id: serviceId,
//                       location: userLocation,
//                       coordinates: coordinates
//                   });
//                 //  console.log(serviceProvidersResponse.data.serviceProviders)
//                   setserviceProviders(serviceProvidersResponse.data.serviceProviders);
//               } else {
//                   console.warn('No services available to fetch service providers.');
//               }
//               if (servicesResponse.data.length > 0) {
//                   const serviceId = servicesResponse.data[0].service_id;
//                   const response = await axios.post(`${API_URL}/select_all_types`, {
//                       serviceId: serviceId,
//                   });
//                   setTypes(response.data);
//                   console.log("Initial types data:", response.data);
//               }
//               else {
//                   console.warn('No services available to fetch types.');
//               }

//           } catch (error) {
//               console.error('Error fetching data:', error);
//           }
//       };

//       fetchData();
//   }, []);

//   return (
//     <>
//     < UserHeader />
//     <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
//         <SearchComponent isHome={false}></SearchComponent>
//     </Box>
//     <Box sx={{display:'flex', flexDirection: 'row', width:'100%', height:'100%', justifyContent:'center', alignItems:'center'}}>
//     <Box sx={{display:'flex', flexDirection: 'row', width:'100%', height:'100%', justifyContent:'center', alignItems:'center',
//          maxWidth:'95%', maxHeight:'95%', gap:'2%',paddingTop:'2.5%', paddingBottom:'2.5%'
//     }}> 
//      <Box  sx={{ display: 'flex' , flexDirection: 'column', width: '20%',maxWidth:'90%',
//                 gap: '48px', justifyContent:'center', alignItems:'center'}}> 
//             <Box sx={{ width: '98%', display:'flex', flexDirection:'column'  }}>
//                         {service_category && (
//                             <>
//                                 <TypographyComponent sx={{ fontSize: '18px', fontColor: Colors.black_80 }}>
//                                     {service_category}
//                                 </TypographyComponent>
//                                 <Box sx={{ width: '100%' }}>
//                                     <List>
//                                         {services.map((service, index) => (
//                                             <ListItem
//                                                 key={index}
//                                                 onClick={() => handleServiceClick(index, service.service_id,
//                                                     service.services)}
//                                                 sx={{
//                                                     backgroundColor: selectedIndex === index ? Colors.primary : "inherit",
//                                                     color: selectedIndex === index ? Colors.white : "inherit",
//                                                     borderRadius: '8px',
//                                                     "&:hover": {
//                                                         backgroundColor:selectedIndex === index ? Colors.primary :Colors.quinary,
//                                                         borderRadius: '8px',
//                                                         // color: Colors.white,
//                                                         cursor: 'pointer'
//                                                     },
//                                                 }}
//                                             >
//                                                 {service.services}
//                                             </ListItem>
//                                         ))}
//                                     </List>
//                                 </Box>
//                             </>
//                         )}
//                     </Box>
//   </Box>
//   <Divider orientation="vertical" flexItem /> 
//         <Box  sx={{
//                 display: 'flex', flexDirection: 'column', overflowY: 'auto', width: '40%',
//                 gap: '48px', maxHeight: '650px',maxWidth:'90%',
//                 '&::-webkit-scrollbar': {
//                     width: '8px', // Width of the scrollbar
//                 },
//                 '&::-webkit-scrollbar-thumb': {
//                     background: '#d9d9d9',
//                     borderRadius: '10px',
//                     borderRight: '2px solid #d9d9d9'
//                 },
//                 '&::-webkit-scrollbar-track': {
//                     borderRight: '2px solid #d9d9d9'
//                 },
//             }}>
//               <Box            sx={{
//                 display: 'flex' , flexDirection: 'column', width: '98%',
//                 gap: '48px', justifyContent:'center', alignItems:'center',
//             }}>

//             <ServiceProviders serviceProviders={serviceProviders} setserviceProviders={setserviceProviders}
//                 selectedService={selectedService} types={types} selectedType={selectedType}
//                 setSelectedType={setSelectedType} setSelectedTypeId={setSelectedTypeId}
//                 selectedTypeId={selectedTypeId} setSpIdChange={setSpIdChange}
//                 spIdChange={spIdChange} setTypeAllTypeId={setTypeAllTypeId} 
//                 serviceProviderQrCode={serviceProviderQrCode} serviceProviderUrl={serviceProviderUrl}> </ServiceProviders>
//         </Box>
//         </Box>
       
//         <Box            sx={{
//                 display: 'flex' , flexDirection: 'column', width: '40%',maxWidth:'90%',
//                 gap: '48px', justifyContent:'center', alignItems:'center'
//             }}>
//                <Box            sx={{
//                 display: 'flex' , flexDirection: 'column', width: '98%',
//                 gap: '48px', justifyContent:'center', alignItems:'center',
//             }}>
//             <ProfileComponent serviceProviderDtls={serviceProviderDtls} isContactEnable={false}
//                 serviceDtls={serviceDtls} serviceProviderQrCode={serviceProviderQrCode} serviceProviderUrl={serviceProviderUrl}>
//             </ProfileComponent>
//             </Box>
//         </Box>
//         </Box>
//         </Box>
//         <UserFooter></UserFooter>
// </>
 // )
}

export default SearchDataNew