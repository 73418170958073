import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import ProgressbarEdit from '../Components/ProgressbarEdit';
import TypographyComponent from '../Components/TypographyComponent';
import TextFieldComponent from '../Components/TextFieldComponent';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonComponent from '../Components/ButtonComponent';
import ButtonComponentWhite from '../Components/ButtonComponentWhite';
import ImageButton from '../Components/ImageButton';
import Frame1 from '../Images/Frame1.jpg';
import Frame2 from '../Images/Frame2.jpg';
import Frame3 from '../Images/Frame3.jpg';
import Frame4 from '../Images/Frame4.jpg';
import Frame5 from '../Images/Frame5.jpg';
import SpHeader from '../Components/SpHeader';
import SpBusinessCardFront1 from '../Components/SpBusinessCardFront1';
import SpBusinessCardFront2 from '../Components/SpBusinessCardFront2';
import SpBusinessCardFront3 from '../Components/SpBusinessCardFront3';
import SpBusinessCardFront4 from '../Components/SpBusinessCardFront4';
import SpBusinessCardFront5 from '../Components/SpBusinessCardFront5';
import axios from 'axios';
import { API_URL } from './Constants';
import { IMG_URL } from '../Components/Constants';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Colors from '../Components/Colors';
import SideBar from '../Components/SideBar';
import EditForm from '../Components/EditForm';
import EditRightBar from '../Components/EditRightBar';
import SpHeaderMobile from '../Components/SpHeaderMobile';

function SpProfileForm() {
    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [postalCodeError, setPostalCodeError] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [signInEmail, setSignInEmail] = useState("");
    const [signInPassword, setSignInPassword] = useState("");
    const [data, setData] = useState([]);
    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [btn, setbtn] = useState(1);
    const [selectedColor, setSelectedColor] = useState('#f9f9f9');
    const [businessName, setBusinessName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [country, setCountry] = useState("Canada");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [province, setProvince] = useState("Ontario");
    const [newProfilePic, setNewProfilePic] = useState(null);
    const { businessCardEdit } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [updateImage, setUpdateImage] = useState(false);
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    // const isEditEnabled = localStorage.getItem('businessCardEdit') === 'true';
    const isEditEnabled = businessCardEdit === 'true';
    const token = localStorage.getItem('token');
    const [selectedBgButton, setSelectedBgButton] = useState(null)
    // console.log(selectedBgButton);

    useEffect(() => {
        // Make the API call using Axios
        const fetchData = async () => {

            // console.log("token", token);
            try {
                const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
                // console.log("service-provider-details", response.data)
                if (response.data.success) {
                    // console.log("service-provider-details profile-form", response.data.data.serviceProvider)
                    setData(response.data.data.serviceProvider)
                    const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
                    // console.log("imgurl", imgurl);
                    setBusinessName(response.data.data.serviceProvider.business_name);
                    setContactPerson(response.data.data.serviceProvider.contact_person);
                    setSignInEmail(response.data.data.serviceProvider.email);
                    setCity(response.data.data.serviceProvider.city)
                    setSignInPassword(response.data.data.serviceProvider.password);
                    if (response && response.data && response.data.data && response.data.data.serviceProvider && response.data.data.serviceProvider.card_number) {
                        setbtn(response.data.data.serviceProvider.card_number);
                    }
                    // setbtn(response.data.data.serviceProvider.card_number)
                    setSelectedColor(response.data.data.serviceProvider.card_color)

                    // if (isEditEnabled) {
                        if (response.data.data.serviceProvider.country != null) {
                    setCountry(response.data.data.serviceProvider.country);
                        }
                    setCountryCode(response.data.data.serviceProvider.country_code);
                    setPhoneNumber(response.data.data.serviceProvider.phone_number);
                    setPostalCode(response.data.data.serviceProvider.postal_code);
                   
                    if (response.data.data.serviceProvider.province != null) {
                    setProvince(response.data.data.serviceProvider.province);
                    }
                    setNewProfilePic(imgurl);
                    // setNewProfilePic({ imageUrl: `${IMG_URL}${response.data.data.serviceProvider.profile_image}` });
                    // }

                    setServiceProviderQrCode(response.data.data.qrCode)


                    setServiceProviderDtls(response.data.data.serviceProvider)
                   // console.log(response.data.data.serviceProvider.service_details)
                    setServiceDetails(response.data.data.serviceProvider.service_details)
                    setMessageDetails(response.data.data.serviceProvider.message_details)
                  //  console.log(response.data.data.serviceProvider.message_details)
                    if(response.data.data.serviceProvider.service_details.length === 0 ){
                        setIsCompleteProfile(false)
                    }

                    const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                        if (message.read === 'Unread') {
                            return acc + 1;
                        }
                        return acc;
                    }, 0);
                    setUnreadMessageCount(unreadCount);

                } else {
                    console.error('Error:', response.data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, []);


    const buttons = [
        { id: 1, imgSrc: Frame1, altText: "Button 1" },
        { id: 2, imgSrc: Frame2, altText: "Button 2" },
        { id: 3, imgSrc: Frame3, altText: "Button 3" },
        { id: 4, imgSrc: Frame4, altText: "Button 4" },
        { id: 5, imgSrc: Frame5, altText: "Button 5" },
    ];

    const handleButtonClick = (buttonNumber) => {
        // Handle button click logic
      //  console.log(`Button ${buttonNumber} Clicked!`);
        const btnno = buttonNumber;
        setbtn(btnno);

    };

    const handleColorButtonClick = (color) => {
        setSelectedColor(color);
        switch (color) {
            case Colors.card_color_1:
                setSelectedBgButton('b1');
                break;
            case Colors.card_color_2:
                setSelectedBgButton('b2')
                break;
            case Colors.card_color_3:
                setSelectedBgButton('b3')
                break;
            case Colors.card_color_4:
                setSelectedBgButton('b4')
                break;
            case Colors.card_color_5:
                setSelectedBgButton('b5')
                break;
            default:
                setSelectedBgButton('b1')
        }
       // console.log("selected button color", color);

    };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setCountryError("");
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
    };

    const handleProvinceChange = (event) => {
        //console.log("setProvince................", event.target.value);
        setProvince(event.target.value);
        setProvinceError("");
    };

    const handlePostalCodeChange = (event) => {
       // console.log("setPostalCode", event.target.value);
        setPostalCode(event.target.value);
        setPostalCodeError("");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (event) => {
        setSignInEmail(event.target.value);
        setEmailError("");
    };

    const handlePasswordChange = (event) => {
        setSignInPassword(event.target.value);
        setPasswordError("");
    };

    const handleBackButtonClick = () => {
        window.location.href = '/sp-landing'
    };

    const handleCancelButtonClick = () => {
        window.location.href = '/sp-landing'
    };
    // remove_profile_image
    const handleSaveButtonClick = async () => {

       // console.log("save click", signInEmail, signInPassword,
          //  country, countryCode, postalCode, province, phoneNumber, token)
        //console.log("save click", signInEmail, signInPassword,
         //   country, countryCode, postalCode, province, phoneNumber, token);
        //console.log("selected image", newProfilePic)
        if (countryCode.trim() === "") {
            setCountryCodeError("Please enter your countryCode");
            return;
        }
        else if (phoneNumber.trim() === "") {
            setPhoneNumberError("Please enter your phoneNumber");
            return;
        }
        else if (province.trim() === "") {
            setProvinceError("Please enter your province");
            return;
        }
        else if (country.trim() === "") {
            setCountryError("Please enter your country");
            return;
        }
        else if (postalCode.trim() === "") {
            setPostalCodeError("Please enter your postalCode");
            return;
        }
        else {
          //  console.log("tesssssttttt", postalCode, province)
            const data1 = {
                token: token,
                country: country,
                countryCode: countryCode,
                postalCode: postalCode,
                province: province,
                phoneNumber: phoneNumber,
                contactPerson:contactPerson,
                cardColor: selectedBgButton,
                cardNumber: btn,
                email: signInEmail
            };
            if (updateImage === true) {
                const formData = new FormData();
                formData.append('data1', JSON.stringify(data1));
                formData.append('profilepic', newProfilePic);
                fetch(newProfilePic)
                    .then((res) => res.blob())
                    .then((blob) => {
                        formData.append('profilepic', blob, 'leboco');
                      //  console.log("formData.........", formData)
                        axios.post(`${API_URL}/sp-edit-next`, formData)
                            .then((response) => {
                              //  console.log('Insert response:', response);
                                if (response.data.success) {
                                    setUpdateImage(false);
                                    window.location.href = '/sp-landing';
                                  //  console.log("success");
                                }
                            })
                            .catch((error) => {
                                console.error('Error inserting service provider:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error fetching image:', error);

                    });
            }
            else {
                try {
                    const response = await axios.post(`${API_URL}/sp-save-without-image`, { data1 });
                   // console.log("kmkl", response)
                    if (response.data.success) {
                        window.location.href = '/sp-landing';
                        console.log("success");
                    }
                }
                catch (error) {
                    console.log("error", error)
                }

            }
        }

    };

    async function handleNextButtonClick() {
       // console.log("Next click", signInEmail, signInPassword,
        //    country, countryCode, postalCode, province, phoneNumber, token);
       // console.log("selected image", newProfilePic)
        if (!countryCode || countryCode.trim() === "") {
            setCountryCodeError("Please enter your countryCode");
            return;
        } else if (!phoneNumber || phoneNumber.trim() === "") {
            setPhoneNumberError("Please enter your phoneNumber");
            return;
        } else if (!province || province.trim() === "") {
            setProvinceError("Please enter your province");
            return;
        } else if (!country || country.trim() === "") {
            setCountryError("Please enter your country");
            return;
        } else if (!postalCode || postalCode.trim() === "") {
            setPostalCodeError("Please enter your postalCode");
            return;
        }
        else {

            const data1 = {
                token: token,
                country: country,
                countryCode: countryCode,
                postalCode: postalCode,
                province: province,
                phoneNumber: phoneNumber,
                contactPerson:contactPerson,
                cardColor: selectedBgButton,
                cardNumber: btn,
                email:signInEmail
            };
            if (updateImage === true) {
                const formData = new FormData();
                formData.append('data1', JSON.stringify(data1));
             //   console.log("data1.........", data1)
                formData.append('profilepic', newProfilePic);
                fetch(newProfilePic)
                    .then((res) => res.blob())
                    .then((blob) => {
                        formData.append('profilepic', blob, 'leboco');
                     //   console.log("formData.........", formData)
                        axios.post(`${API_URL}/sp-edit-next`, formData)
                            .then((response) => {
                              //  console.log('Insert response:', response);
                                if (response.data.success) {
                                    window.location.href = '/add-location';
                                    console.log("success")
                                }
                            })
                            .catch((error) => {
                                console.error('Error inserting service provider:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error fetching image:', error);

                    });
            } else {
                try {
                    const response = await axios.post(`${API_URL}/sp-save-without-image`, { data1 });
                    console.log("kmkl", response)
                    if (response.data.success) {
                        window.location.href = '/add-location';
                        console.log("success");
                    }
                }
                catch (error) {
                    console.log("error", error)
                }
            }
        }
    };

    const handleImageChange = (imageUrl) => {
        // Update the newProfilePic state with the new image data
        setNewProfilePic({ imageUrl });
    };



    return (

        <Box >
            <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
                <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={25}
                    unreadMessageCount={unreadMessageCount} isCompleteProfile= {isCompleteProfile}></SpHeader>
            </Box>

            <Stack direction={{ xs: "column", sm: "row", md: "row" }} height='100vh'
                spacing={2} justifyContent="space-between" id='containerBox'>
                <SideBar currentStage={1} setNewProfilePic={setNewProfilePic} newProfilePic={newProfilePic}
                    selectedImage={selectedImage} setSelectedImage={setSelectedImage} selectedBgButton={selectedBgButton}
                    updateImage={updateImage} setUpdateImage={setUpdateImage} serviceProviderDtls={serviceProviderDtls}>

                </SideBar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} flex={4}>
                    <EditForm businessName={businessName}
                    setContactPerson={setContactPerson}
                    contactPerson={contactPerson}
                        emailError={emailError}
                        signInEmail={signInEmail}
                        setSignInEmail={setSignInEmail}
                        passwordError={passwordError}
                        signInPassword={signInPassword}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        countryCode={countryCode}
                        countryCodeError={countryCodeError}
                        setCountryCode={setCountryCode}
                        setCountryCodeError={setCountryCodeError}
                        phoneNumber={phoneNumber}
                        phoneNumberError={phoneNumberError}
                        setPhoneNumber={setPhoneNumber}
                        setPhoneNumberError={setPhoneNumberError}
                        setPostalCode={setPostalCode}
                        setPostalCodeError={setPostalCodeError}
                        postalCode={postalCode}
                        postalCodeError={postalCodeError}
                        province={province}
                        setProvince={setProvince}
                        setProvinceError={setProvinceError}
                        provinceError={provinceError}
                        country={country}
                        setCountry={setCountry}
                        countryError={countryError}
                        setCountryError={setCountryError}
                        isEditEnabled={isEditEnabled}
                    >


                    </EditForm>
                    <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' }, pt: '16px' }}>
                        {isEditEnabled ? (<Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            marginLeft: 'auto',

                        }}>
                            <ButtonComponentWhite sx={{ width: '120px', height: '44px' }}
                                onClick={handleCancelButtonClick}>
                                Cancel
                            </ButtonComponentWhite>
                            <ButtonComponent sx={{ width: '120px', height: '44px' }}
                                onClick={handleSaveButtonClick}>
                                Save
                            </ButtonComponent>
                        </Box>) : (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                                marginLeft: 'auto',

                            }}>
                                <ButtonComponentWhite sx={{ width: '120px', height: '44px' }}
                                    onClick={handleCancelButtonClick}>
                                    Back
                                </ButtonComponentWhite>
                                <ButtonComponent sx={{ width: '120px', height: '44px' }}
                                    onClick={handleNextButtonClick}>
                                    Next
                                </ButtonComponent>
                            </Box>
                        )
                        }
                    </Box>
                </Box>


                <EditRightBar
                    btn={btn}
                    setbtn={setbtn}
                    country={country}
                    postalCode={postalCode}
                    phoneNumber={phoneNumber}
                    province={province}
                    newProfilePic={newProfilePic}
                    signInEmail={signInEmail}
                    signInPassword={signInPassword}
                    countryCode={countryCode}
                    businessName={businessName}
                    data={data}
                    serviceDetails={serviceDetails}
                    serviceProviderQrCode={serviceProviderQrCode}
                    selectedImage={selectedImage}
                    selectedColor={selectedColor}
                    selectedBgButton={selectedBgButton}
                    setSelectedColor={setSelectedColor}
                    setSelectedBgButton={setSelectedBgButton}
                    setProvince={setProvince}
                    setProvinceError={setProvinceError}
                    setPostalCode={setPostalCode}
                    setPostalCodeError={setPostalCodeError}
                    city={city}

                >


                </EditRightBar>
                <Box sx={{ display: { xs: 'flex', sm: 'none', md: 'none' }, pt: '16px', pr:'5%'}}>
                    {isEditEnabled ? (<Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        marginLeft: 'auto',
                        alignItems: 'center'
                    }}>
                        <ButtonComponentWhite sx={{ width: '120px', height: '44px' }}
                            onClick={handleCancelButtonClick}>
                            Cancel
                        </ButtonComponentWhite>
                        <ButtonComponent sx={{ width: '120px', height: '44px' }}
                            onClick={handleSaveButtonClick}>
                            Save
                        </ButtonComponent>
                    </Box>) : (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            marginLeft: 'auto',

                        }}>
                            <ButtonComponentWhite sx={{ width: '120px', height: '44px' }}
                                onClick={handleCancelButtonClick}>
                                Back
                            </ButtonComponentWhite>
                            <ButtonComponent sx={{ width: '120px', height: '44px' }}
                                onClick={handleNextButtonClick}>
                                Next
                            </ButtonComponent>
                        </Box>
                    )
                    }
                </Box>

            </Stack>
        </Box>
    );
}

export default SpProfileForm;
