import { Box, InputBase, Select, MenuItem, FormControlLabel, Checkbox, Button, TextField, Tabs, Tab, Typography, InputAdornment } from "@mui/material";
import ButtonComponent from "../Components/ButtonComponent";
import TypographyComponent from "../Components/TypographyComponent";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState, useRef, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import SpBusinessCardFront1 from '../Components/SpBusinessCardFront1';
import SpBusinessCardFront2 from '../Components/SpBusinessCardFront2';
import SpBusinessCardFront3 from '../Components/SpBusinessCardFront3';
import SpBusinessCardFront4 from '../Components/SpBusinessCardFront4';
import SpBusinessCardFront5 from '../Components/SpBusinessCardFront5';
import SpBusinessCardBack from "../Components/SpBusinessCardBack";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MessageIcon from '@mui/icons-material/Message';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Colors from "./Colors";
import TextFieldComponent from "./TextFieldComponent";
import ButtonComponentWhite from "./ButtonComponentWhite";
import axios from "axios";
import { API_URL } from './Constants';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


function ProfileComponent(props) {

    const { serviceProviderDtls, serviceDtls, serviceProviderQrCode, isContactEnable, serviceProviderUrl } = props
  //console.log(serviceDtls[0].associate_status)
    //  console.log(serviceProviderUrl)

    const colors = ['#F0624E', '#45DCDC', '#A14C93', '#F22B7E', '#5F90DB'];
    const profileIndex = localStorage.getItem("profileIndex")
    console.log(serviceDtls)
    const typesString = serviceDtls.type_details?.map(item => item.types).join(', ');
    const typeIdString = serviceDtls.type_details?.map(item => item.types_id).join(', ');
    const [types, setTypes] = useState([]);
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [otpPopUp, setOtpPopUp] = useState(false);
    const [checkboxes, setCheckboxes] = useState([]);
    const [checkboxesService, setCheckboxesService] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState('');
    const [selectedServices, setSelectedServices] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [verificationAttempted, setVerificationAttempted] = useState(false);
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [otpAssossiateError, setOtAssossiateError] = useState("");
    const [isTermPopUp, setIsTermPopUp] = useState(false);
    const [professionName, setProfessionName] = useState([]);
    const [serviceDtlsShow, setServiceDtlsShow] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedServiceData, setSelectedServiceData] = useState([]);
    const [isAcceptRejectClick, setIsAcceptRejectClick] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          if (serviceDtls && serviceDtls.length > 0 && serviceDtls[0]) {
            const status = serviceDtls[0].associate_status;
            if (status === "Accepted" || status === "Rejected") {
              setIsAcceptRejectClick(true);
            } else {
              setIsAcceptRejectClick(false);
            }
          } else {
            setIsAcceptRejectClick(false);
          }
        }, 1000);
        return () => clearTimeout(timer);
      }, [serviceDtls]); 

    let searchServiceId;
    searchServiceId = localStorage.getItem("serviceId") || localStorage.getItem("TypeserviceId") || localStorage.getItem("ServiceserviceId")
    console.log(searchServiceId)

    useEffect(() => {
        if (searchServiceId !== null) {
            const tabIndex = serviceDtls.findIndex(service => service.services_id === parseInt(searchServiceId));
            console.log(tabIndex)
            if (tabIndex !== -1) {
                setSelectedTab(tabIndex)
                setSelectedServiceData(serviceDtls[tabIndex])
                console.log(serviceDtls[tabIndex])
                console.log(tabIndex)
            }
        }
        else {
            if (serviceDtls && localStorage.getItem("serviceId") === null && localStorage.getItem("TypeserviceId") === null && localStorage.getItem("ServiceserviceId")) {
                searchServiceId = serviceDtls[0].services_id
            }
            setSelectedTab(0)
            setSelectedServiceData(serviceDtls[0])
            console.log(serviceDtls)
        }
    }, [searchServiceId, serviceDtls]);

    useEffect(() => {
        if (selectedServiceData) {
            setServiceDtlsShow(selectedServiceData)
        }
    }, [selectedServiceData]);

    useEffect(() => {
        if (serviceDtls) {
            const extractedProfessionalNames = Array.isArray(serviceDtls)
                ? serviceDtls.map(detail => ({
                    professional_name: detail.professional_name,
                    services_id: detail.services_id
                }))
                : [{
                    professional_name: serviceDtls.professional_name,
                    services_id: serviceDtls.services_id
                }];
            setProfessionName(extractedProfessionalNames);
            // setServiceDtlsShow(serviceDtls[0])
        }
        setServiceDtlsShow(selectedService)
    }, [serviceDtls]);
    const selectedService = serviceDtls[selectedTab] || {};

    const handleCloseAssossiateTerm = () => {
        setIsTermAssossiatePopUp(false)
       
    };
    // console.log(newProfilePic)
    useEffect(() => {
        setSelectedTypes('')

        // console.log("image ",serviceProviderDtls.profile_image )
        if (serviceProviderDtls.profile_image !== undefined || serviceProviderDtls.profile_image !== null || serviceProviderDtls.profile_image !== '') {
            const imgurl = `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${serviceProviderDtls.profile_image}`;
            //  console.log("image ",imgurl)
            setNewProfilePic(`https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${serviceProviderDtls.profile_image}`);
            // const allType = serviceDtls.types;
            // const typesArray = allType.split(',').map(type => type.trim());
            // setTypes(typesArray);

            //console.log(serviceDtls.types)
        }
        else {
            setNewProfilePic(null)
        }


    }, [serviceProviderDtls.profile_image]);

    //console.log(newProfilePic)


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        const selectedService = serviceDtls[newValue];
        setServiceDtlsShow(selectedService)
    };


    const fetchTypes = async () => {
        const serviceId = serviceDtls.services_id
        // console.log(serviceId)
        try {
            const response = await axios.post(`${API_URL}/select_all_types`,
                { serviceId: serviceDtls.services_id, });
            setTypes(response.data);
            // console.log("all types",response.data)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchTypes();

    }, [serviceDtls.services_id]);

    // const fetchProfession = async () => {
    //     const serviceId = serviceDtls.services_id
    //     // console.log(serviceId)
    //     try {
    //         const response = await axios.post(`${API_URL}/select_profession`,
    //             { serviceId: serviceDtls.services_id, });
    //         console.log(response.data[0].professional_name)
    //         setProfessionName(response.data[0].professional_name)
    //     } catch (error) {
    //         console.error('Error fetching service categories:', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchProfession();

    // }, [serviceDtls.services_id]);


    const joinedDate = new Date(serviceProviderDtls.joined_date);
    const formattedDate = `${joinedDate.getDate()}/${joinedDate.getMonth() + 1}/${joinedDate.getFullYear()}`;



    const [isViewCard, setIsViewCard] = useState(false);

    const handleViewCardButtonClick = () => {
        setIsViewCard(true)
    }

    const handleViewCardPopUpCancelButtonClick = () => {
        setIsViewCard(false)
    }

    function downloadPopupContentAsImage() {

    }

    const [isContact, setIsContact] = useState(false);
    const [isShare, setIsShare] = useState(false);

    const handleShareButtonClick = () => {
        setIsShare(true)
    }
    const handleMobileShareButtonClick = () => {
        setIsShare(true)
        // if (navigator.share) {
        //     navigator.share({                
        //         url: serviceProviderUrl
        //     }).then(() => {
        //         console.log('Share was successful');
        //     }).catch((error) => {
        //         console.error('Error sharing:', error);
        //     });
        // } else {
        //     console.error('Web Share API not supported');
        // }

    }

    const handleSharePopUpCancelButtonClick = () => {
        setIsShare(false)
    }


    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };
    // const handleCopyButtonClick = () => {
    //   navigator.clipboard.writeText(serviceProviderUrl)
    //   .then(() => {
    //     console.log('Link copied to clipboard');
    //     setIsShare(false)
    //   })
    //   .catch((error) => {
    //     console.error('Failed to copy:', error);
    //   });
    // }
    const handleCopyButtonClick = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(serviceProviderUrl)
                .then(() => {
                    console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);
                    // Fallback for copying manually
                    fallbackCopyTextToClipboard(serviceProviderUrl);
                });
        } else {
            console.warn('Clipboard API not supported, unable to copy text');
            // Fallback for copying manually
            fallbackCopyTextToClipboard(serviceProviderUrl);
        }
    };

    function fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed'; // To avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Fallback: Copying text ' + msg);
            setIsShare(false);
        } catch (err) {
            console.error('Fallback: Unable to copy:', err);
        }

        document.body.removeChild(textArea);
    }


    const [openQuote, setOpenQuote] = useState(false);
    const [confirmQuoteSend, setConfirmQuoteSend] = useState(false);
    const [choosedTypes, setChoosedTypes] = useState([]);
    const [typesName, setTypesName] = useState([]);
    const [typesshowName, setTypesshowName] = useState([]);
    const [typesId, setTypesId] = useState([]);
    const [typesError, setTypesError] = useState("");
    const [serviceError, setServiceError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const token = localStorage.getItem('token');
    const location = localStorage.getItem("Location")
    const [sendQuoteTypes, setSendQuoteTypes] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);


    const handleContactButtonClick = (id) => {
        console.log(id)
        console.log(serviceDtls)
        console.log(searchServiceId)
        const combinedTypeDetails = serviceDtls.flatMap(service =>
            service.type_details.map(typeDetail => ({
                ...typeDetail,
                services: service.services,
                serviceId: service.services_id,
            }))
        );
        setSendQuoteTypes(combinedTypeDetails);
        const allTypeDetails = serviceDtls.flatMap(service =>
            service.type_details.map(detail => ({
                ...detail,
                services: service.services,
                serviceId: service.services_id,
            }))
        );

        console.log(allTypeDetails);

        const initialCheckboxes = allTypeDetails.map(data => ({
            types_id: data.types_id,
            types: data.types,
            services: data.services,
            serviceId: data.serviceId,
            checked: false, // Set initial checked state
        }));

        setCheckboxes(initialCheckboxes);


        console.log(searchServiceId)
        console.log(allTypeDetails)
        let initialCheckboxesService
        console.log(searchServiceId)
        //  if(serviceDtls && localStorage.getItem("serviceId") === null && localStorage.getItem("TypeserviceId")  === null && localStorage.getItem("ServiceserviceId")){
        if (serviceDtls && searchServiceId === null) {
            console.log("hii")
            const sid = serviceDtls.map(item => item.services_id);
            initialCheckboxesService = allTypeDetails.map(data => ({
                types_id: data.types_id,
                types: data.types,
                services: data.services,
                serviceId: data.serviceId,
                checked: sid.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
            }));
        }
        else {
            console.log("hii")
            initialCheckboxesService = allTypeDetails.map(data => ({
                types_id: data.types_id,
                types: data.types,
                services: data.services,
                serviceId: data.serviceId,
                checked: searchServiceId.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
            }));
        }
        setCheckboxesService(initialCheckboxesService);

        const selectedServices = [...new Set(initialCheckboxesService
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.services)
        )];

        setSelectedServices(selectedServices.join(', '));

        const filteredTypes = allTypeDetails.map(item => item.types_id);
        setFilteredTypes(filteredTypes);

        setChoosedTypes([])
        setSelectedTypes('')
        setTypesName([]);
        setTypesshowName([])
        setTypesId([])
        setTypesError("")
        setServiceError("")
        setPhoneNumberError("");
        setCountryCodeError("");
        setOtpError("")
        setPhoneNumber("");
        setCountryCode("1")
        setOtp(['', '', '', ''])
        setOpenQuote(true);
        localStorage.setItem("ToId", id)
        //  console.log(id)
        setVerificationAttempted(false)
    };

    const handleCloseQuote = () => {
        setOpenQuote(false);
    };

    const handleSendQuote = async () => {
        if (!typesName || typesName.length === 0) {
            setTypesError("Please choose your types of service");

        }
        if (phoneNumber.trim() === "") {
            setPhoneNumberError("Please add your phone number");
        }
        else {
            setConfirmQuoteSend(true)
            setOpenQuote(false)
        }
    };


    const handleYesSendQuote = async () => {
        const formattedSelectedTypes = typesshowName.split(', ').map((type) => {
            const typeName = type.trim();
            const serviceName = checkboxesService.find(service => service.types === typeName)?.services;

            return `${typeName} (${serviceName})`;
        }).join(', ');

        try {
            const response = await axios.post(`${API_URL}/sent-message`, {
                types: formattedSelectedTypes,
                user_phone_number: phoneNumber,
                countryCode: countryCode,
                location: location,
                spId: localStorage.getItem("ToId"),
            });

            if (response.data.success === true) {
                setOpenQuote(false)
                setConfirmQuoteSend(false)
            }

        } catch (error) {
            console.error('Error:', error.message);
        }

    };

    const handleKeyDown = (index, e) => {
        setOtpError("")
        // Handle Enter key press to move to next TextField
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or new line
            if (otp[index].length === 1 && index < otp.length - 1) {
                const nextTextField = document.getElementById(`otp${index + 1}`);
                if (nextTextField) {
                    nextTextField.focus();
                }
            }
        }

        // Handle Backspace key press to clear current field and move focus
        if (e.key === 'Backspace') {
            e.preventDefault(); // Prevent default backspace behavior
            const updatedOtp = [...otp];
            updatedOtp[index] = ''; // Clear the current field
            setOtp(updatedOtp);

            // Move cursor to previous TextField if index is greater than 0
            if (index > 0) {
                const prevTextField = document.getElementById(`otp${index - 1}`);
                if (prevTextField) {
                    prevTextField.focus();
                }
            }
        }
    };

    const handleInputChange = (index, value) => {
        setOtpError("")
        if (!isNaN(value) && value.length <= 1) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
        }
    };

    const handleNoSendQuote = () => {
        setConfirmQuoteSend(false);
        setOpenQuote(true)
    };




    const handleInputTypesChange = (selectedTypes) => {
        // console.log("selectedTypes", selectedTypes)
        if (choosedTypes.length < 5) {
            setChoosedTypes(selectedTypes)
            const typeNames = selectedTypes.map(type => {
                const foundType = types.find(item => item.types_id === type);
                return foundType ? foundType.types : null;
            });
            const typeIds = selectedTypes.map(type => type);
            const typesshowingName = typeNames.join(', ');
            setTypesName(typeNames);
            setTypesshowName(typesshowingName)
            setTypesId(typeIds);
            setTypesError("");
            // console.log("selectedIds", typeNames, typeIds)
        }

    };


  const [clickCount, setClickCount] = useState(0);
  const [firstClickTime, setFirstClickTime] = useState(null);
  const [blockUntil, setBlockUntil] = useState(null);



    const handleSendOtpClick = async () => {
       
        setOtp(['', '', '', ''])
        let hasError = false;
        const anyChecked = checkboxes.some((checkbox) => checkbox.checked);
        const anyCheckedservice = checkboxesService.some((checkbox) => checkbox.checked);
        console.log("anyChecked", anyChecked)
        if (!anyChecked) {
            // console.log("!!!!!1anyChecked",!anyChecked)
            setTypesError("Please choose your types of service");
            hasError = true;
        }
        if (!anyCheckedservice) {
            // console.log("!!!!!1anyChecked",!anyChecked)
            setServiceError("Please choose your service");
            hasError = true;
        }
        if (phoneNumber.trim() === "") {
            setPhoneNumberError("Please add your phone number");
            hasError = true;
        }
        //  console.log(countryCode.trim())
        if (countryCode.trim() === "" || countryCode.trim() === null) {
            //  console.log("....",countryCode.trim())
            setCountryCodeError("Please add your country code");
            hasError = true;
        }
        if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
            setPhoneNumberError('Phone number must be 10 digits');
            hasError = true;
        }

        if (hasError) {
            return; // Stop execution if there are errors
        }
        else {
            // setConfirmQuoteSend(true)
            // setOtpPopUp(true)
            try {
                const response = await axios.post(`${API_URL}/otp-creation`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    });

                //  console.log("response", response);

                if (response.data.success === true) {
                    setOtpPopUp(true)
                    setOpenQuote(false)
                }
                else {


                    // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                    console.log("an error occured");

                }
            }
            catch (error) {
                // setConfirmQuoteSend(true)
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }


        }
    };

    const handleCloseOtpPopUp = () => {
        setOtpPopUp(false);
    }

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
    };



    const handleVerifyOTP = async () => {
        const isAnyFieldEmpty = otp.some((digit) => digit.length === 0);
        setVerificationAttempted(true);
        if (isAnyFieldEmpty) {
            console.log("field empty")
        } else {
            const response = await axios.post(`${API_URL}/otp-verification`,
                {
                    countryCode: countryCode,
                    phone: phoneNumber,
                    otp: otp,

                });

            console.log(" verify response", response);

            if (response.data.success === true) {
                console.log("otp verification success")
                setOtpPopUp(false);
                setConfirmQuoteSend(true)
                setOtp(['', '', '', ''])

            }
            else {
                setOtpError(response.data.message)
            }
        }
    };


    // const handleInputChange = (index, value) => {
    //   // Check if the input value is a number and update the OTP state
    //   if (!isNaN(value) && value.length <= 1) {
    //       const updatedOtp = [...otp];
    //       updatedOtp[index] = value;
    //       setOtp(updatedOtp);
    //   }
    // };
    const [open, setOpen] = useState(false);

    const handleToggle = (e) => {
        e.stopPropagation();
        //setOpen((prevOpen) => !prevOpen);
        setOpen(!open)

    };
    const [serviceOpen, setServiceOpen] = useState(false);
    const handleToggleService = (e) => {
        e.stopPropagation();
        // setServiceOpen((prevOpen) => !prevOpen);
        setServiceOpen(!serviceOpen)
    };

    useEffect(() => {
        if (serviceDtls?.type_details) {
            setSendQuoteTypes(serviceDtls.type_details)
        } else {
            console.warn("serviceDtls or type_details is undefined");
            setSendQuoteTypes([]);
        }


    }, []);



    useEffect(() => {
        const combinedTypeDetails = serviceDtls.flatMap(service =>
            service.type_details.map(typeDetail => ({
                ...typeDetail,
                services: service.services,
                serviceId: service.services_id,

            }))
        );
        setSendQuoteTypes(combinedTypeDetails);


        if (sendQuoteTypes) {
            const allTypeDetails = serviceDtls.flatMap(service =>
                service.type_details.map(detail => ({
                    ...detail,
                    services: service.services,
                    serviceId: service.services_id,
                }))
            );

            console.log(allTypeDetails);

            const initialCheckboxes = allTypeDetails.map(data => ({
                types_id: data.types_id,
                types: data.types,
                services: data.services,
                serviceId: data.serviceId,
                checked: false, // Set initial checked state
            }));
            setCheckboxes(initialCheckboxes)
            const searchServiceIds = Array.isArray(searchServiceId) ? searchServiceId : [searchServiceId];
            console.log(allTypeDetails)
            console.log(searchServiceId)
            const initialCheckboxesService = allTypeDetails.map(data => ({
                types_id: data.types_id,
                types: data.types,
                services: data.services,
                serviceId: data.serviceId,
                checked: searchServiceId.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
            }));

            setCheckboxesService(initialCheckboxesService);

            const selectedServices = [...new Set(initialCheckboxesService
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => checkbox.services)
            )];

            setSelectedServices(selectedServices.join(', '));
        }
        // console.log(initialCheckboxes)
    }, []);


    const handleCheckboxChange = (index, types, types_id, services) => (event) => {
        console.log(index, types, types_id, services, event.target.checked)
        const newCheckboxes = checkboxes.map((checkbox) => {
            if (checkbox.types_id === types_id) {
                return {
                    ...checkbox,
                    checked: event.target.checked,
                };
            }
            return checkbox;
        });

        setCheckboxes(newCheckboxes);

        console.log(newCheckboxes)
        const selected = newCheckboxes
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.types);
        setSelectedTypes(newCheckboxes
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.types).join(', '));

        // setSelectedServices(newCheckboxes
        //     .filter((checkbox) => checkbox.checked)
        //     .map((checkbox) => checkbox.services).join(', ')); 

        // Update TextFieldComponent display
        const selectedText = selected.join(', ');
        setTypesshowName(selectedText);
        setTypesError("")
    };

    // const handleCheckboxChangeService = (service, serviceId) => (event) => {
    //     const newCheckboxes = checkboxesService.map((checkbox) => {
    //         if (checkbox.serviceId === serviceId) {
    //             return {
    //                 ...checkbox,
    //                 checked: event.target.checked,
    //             };
    //         }
    //         return checkbox;
    //     });

    //     setCheckboxesService(newCheckboxes);

    //     const selectedServices = [...new Set(newCheckboxes
    //         .filter((checkbox) => checkbox.checked)
    //         .map((checkbox) => checkbox.services)
    //     )];

    //     setSelectedServices(selectedServices.join(', '));
    //     setServiceError("")
    // };


    const handleCheckboxChangeService = (service, serviceId) => (event) => {
        const newCheckboxesService = checkboxesService.map((checkbox) => {
            if (checkbox.serviceId === serviceId) {
                return {
                    ...checkbox,
                    checked: event.target.checked,
                };
            }
            return checkbox;
        });

        setCheckboxesService(newCheckboxesService);

        const selectedServices = [...new Set(newCheckboxesService
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.services)
        )];

        setSelectedServices(selectedServices.join(', '));
        setServiceError("");

        if (!event.target.checked) {
            // Find and uncheck all corresponding types of service
            const associatedTypeIds = newCheckboxesService
                .filter((checkbox) => checkbox.serviceId === serviceId)
                .map((checkbox) => checkbox.types_id);

            const newCheckboxesType = checkboxes.map((checkbox) => {
                if (associatedTypeIds.includes(checkbox.types_id)) {
                    return {
                        ...checkbox,
                        checked: false,
                    };
                }
                return checkbox;
            });

            setCheckboxes(newCheckboxesType);

            const selectedTypes = newCheckboxesType
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => checkbox.types);

            setSelectedTypes(selectedTypes.join(', '));
            setTypesshowName(selectedTypes.join(', '));
        }
    };





    const boxRef = useRef(null);
    const buttonRef = useRef(null);
    const serviceRef = useRef(null);
    const serviceboxRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            boxRef.current && !boxRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setOpen(false);

        }
    };

    const handleClickOutsideService = (event) => {
        if (
            serviceboxRef.current && !serviceboxRef.current.contains(event.target) &&
            serviceRef.current && !serviceRef.current.contains(event.target)
        ) {
            setServiceOpen(false);

        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [open]);
    useEffect(() => {
        if (serviceOpen) {
            document.addEventListener('mousedown', handleClickOutsideService);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideService);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideService);
        };

    }, [serviceOpen]);


    const handleTermsAndCondtionClick = () => {
        setIsTermPopUp(true)
        setOpenQuote(false)
    };

    const handleCloseTerm = () => {
        setIsTermPopUp(false)
        setOpenQuote(true)
    };

    const firstOtpInputRef = useRef(null);

    useEffect(() => {
        if (otpPopUp && firstOtpInputRef.current) {
            firstOtpInputRef.current.focus();
        }
    }, [otpPopUp]);

    const groupByService = (checkboxes) => {
        return checkboxes.reduce((groups, checkbox) => {
            if (!groups[checkbox.services]) {
                groups[checkbox.services] = [];
            }
            groups[checkbox.services].push(checkbox);
            return groups;
        }, {});
    };

    const filteredCheckboxes = checkboxes.filter(checkbox => selectedServices.includes(checkbox.services));
    const groupedCheckboxes = groupByService(filteredCheckboxes);


    const uniqueCheckboxes = checkboxesService.filter((checkbox, index, self) =>
        index === self.findIndex((c) => (
            c.services === checkbox.services
        ))
    );
    const [otpPopUpAssossiate, setOtpPopUpAssossiate] = useState(false);
    const [disablePopUpAssossiate, setDisablePopUpAssossiate] = useState(false);
    const [passwordPopup, setPasswordPopup] = useState(false);
    const [isTermAssossiatePopUp, setIsTermAssossiatePopUp] = useState(false);

    const handlePaste = (event) => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === otp.length) {
            const newOtp = [...otp];
            for (let i = 0; i < otp.length; i++) {
                newOtp[i] = pastedData[i] || '';
            }
            setOtp(newOtp);
            event.preventDefault();
        }
    };
    const [otpAssossiate, setOtpAssossiate] = useState(['', '', '', '']);

    const handleEnableClick = async (phoneno, countryCodeno) => {
        setOtpAssossiate(['', '', '', ''])       
        setOtpError(false)
        console.log(phoneno, countryCodeno)
           
            try {
                const response = await axios.post(`${API_URL}/otp-creation`,
                    {
                        countryCode: countryCodeno,
                        phone: phoneno,
                    });

                if (response.data.success === true) {
                    setOtpPopUpAssossiate(true) 
                    setOtpAssossiate(['', '', '', ''])  
                    setOtpError(false) 
                    setIsAcceptRejectClick(true)                  
                }
                else {
                    // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                    console.log("an error occured");

                }
            }
            catch (error) {
                // setConfirmQuoteSend(true)
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }


        
    };

    const handleDisableClick = async (phoneno, countryCodeno) => {
        setDisablePopUpAssossiate(true)
            }

            const handleClosePassWordPopup = () => {
                setPasswordPopup(false)
            }
        
            
            const handleCancelDisablePopup = () => {
                setDisablePopUpAssossiate(false)
            }
        
            const handleYesDisablePopup = async() => {
       
                try {
                const spid = serviceProviderDtls.service_provider_id;
               
                  const response = await axios.post(`${API_URL}/disable-assossiate-status`, { spid });
                  console.log("response message",response.data)
                  if (response.data.success===true) {
                      setDisablePopUpAssossiate(false)
                      console.log("response message",response.data)
                       window.location.href = '/'
                       setIsAcceptRejectClick(true)
                    }
                    if (response.data.success===false) {             
                      setPasswordError(response.data.message)
                    }
          
                }
                catch (error) {
                  console.log('Error updating password. Please try again later.');
                }
              
            };
      
            const handleTermsClickAssossiate = () => {        
              setIsTermAssossiatePopUp(true)
          }

          const handleCloseOtpPopUpAssossiate = () => {
            setOtpPopUpAssossiate(false);
        }


        const handlePasteAssossiate = (event) => {
            const pastedData = event.clipboardData.getData('Text');
            if (pastedData.length === otpAssossiate.length) {
              const newOtp = [...otpAssossiate];
              for (let i = 0; i < otpAssossiate.length; i++) {
                newOtp[i] = pastedData[i] || '';
              }
              setOtpAssossiate(newOtp);
              event.preventDefault();
            }
          };

          const handleSendOtpClickAssossiate  = async () => {
            const now = new Date();

            console.log(firstClickTime)
            if (firstClickTime === null) {
              setFirstClickTime(now);
            }       
            console.log(firstClickTime)
            const diffMinutes = (now - firstClickTime) / (1000 * 60);
            console.log("First Click Time:", firstClickTime);
            console.log("Now:", now);
            console.log("Difference in minutes:", diffMinutes);
           
            if (diffMinutes < 1) {
                console.log("diffMinutes")
              setClickCount(prevCount => prevCount + 1);
            } else {  
                console.log("diffMinutes1")           
              setClickCount(1);
              setFirstClickTime(now);
            }
           console.log(clickCount)
            if (clickCount === 2) {
                setOtAssossiateError('Warning: You have only two attempts left. Exceeding this limit may block OTP requests for 24 hours.');      
                return; 
            }
            if (clickCount === 4) {
                setOtAssossiateError('You have clicked too many times. Please try again later.');      
                return; 
            }
        
            setOtpAssossiate(['', '', '', ''])       
            setOtpError(false)
             console.log(serviceProviderDtls.country_code, serviceProviderDtls.phone_number)  
                try {
                    const response = await axios.post(`${API_URL}/otp-creation`,
                        {
                            countryCode: serviceProviderDtls.country_code,
                            phone: serviceProviderDtls.phone_number,
                        });
    
                    if (response.data.success === true) {
                        setOtpPopUpAssossiate(true) 
                        setOtpAssossiate(['', '', '', ''])  
                        setOtpError(false)                   
                    }
                    else {
                        // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                        console.log("an error occured");
    
                    }
                }
                catch (error) {
                    // setConfirmQuoteSend(true)
                    console.error("Request failed:", error);
                    if (error.response) {
                        console.error("Server responded with status code:", error.response.status);
                        console.error("Response data:", error.response.data);
                    } else if (error.request) {
                        console.error("No response received:", error.request);
                    } else {
                        console.error("Error setting up request:", error.message);
                    }
                }
    
    
            
        };

        const handleVerifyOTPAssossiate = async () => {
            setPasswordError("")
            setNewPasswordError("")
            setConfirmPasswordError("")
            const isAnyFieldEmpty = otpAssossiate.some((digit) => digit.length === 0);
            setVerificationAttempted(true);
            if (isAnyFieldEmpty) {
                console.log("field empty")
            } else {
                const response = await axios.post(`${API_URL}/otp-verification`,
                    {
                        countryCode: serviceProviderDtls.country_code,
                        phone: serviceProviderDtls.phone_number,
                        otp: otpAssossiate,
    
                    });
    
              //  console.log(" verify response", response);
    
                if (response.data.success === true) {
                    console.log("otp verification success")
                    setOtpPopUpAssossiate(false);
                    
                        setPasswordPopup(true)
                     
                    setOtpAssossiate(['', '', '', ''])
                }
                else{
                    setOtpError(response.data.message)
                  }
            }
        };

        const [newPassword, setNewPassword] = useState("");
        const [newPasswordError, setNewPasswordError] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [confirmPasswordError, setConfirmPasswordError] = useState("");
        const [passwordError, setPasswordError] = useState("");
       
        const [showNewPassword, setShowNewPassword] = useState(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);
        const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);
  
  
        const handleNewPasswordChange = (event) => {
          setNewPassword(event.target.value);
          setPasswordError("")
          setNewPasswordError("")
          setConfirmPasswordError("")
        };
      
        const handleConfirmPasswordChange = (event) => {
          setConfirmPassword(event.target.value);
          setPasswordError("")
          setNewPasswordError("")
          setConfirmPasswordError("")
        };
      
      
      const toggleNewPasswordVisibility = () => {
          setShowNewPassword(!showNewPassword);
      };
      
      const toggleConfirmPasswordVisibility = () => {
          setShowConfirmPassword(!showConfirmPassword);
      };
  
      const handleSubmit = async(event) => {
          event.preventDefault();
      
          if (newPassword.trim() === '' || confirmPassword.trim() === '') {
           
           
            if (newPassword.trim() === "") {
              setNewPasswordError("Please enter password");
             
            }
            else {
              setNewPasswordError("");
            }
            if (confirmPassword.trim() === "") {
              setConfirmPasswordError("Please enter confirm password");
             
            }
            else {
              setConfirmPasswordError("");
            }
            return;
          }
          if (newPassword !== confirmPassword) {
              setPasswordError("New password and confirm password do not match.");
              return;
            }
            try {
             
              const spid = serviceProviderDtls.service_provider_id;
             console.log(serviceProviderDtls.service_provider_id)
              const response = await axios.post(`${API_URL}/create-new-password-assossiate`, { spid, newPassword });
              console.log("response message",response.data)
              if (response.data.success===true) {
                  console.log("response message",response.data)
                   window.location.href = '/signin'
                }
                if (response.data.success===false) {
                //  console.log("response message",response.data.message)
                  setPasswordError(response.data.message)
                }
      
            }
            catch (error) {
              console.log('Error updating password. Please try again later.');
            }
          
        };

        const handleKeyDownAssossiate = (index, e) => {
            setOtpError("")
            // Handle Enter key press to move to next TextField
            if (e.key === 'Enter') {
                e.preventDefault(); // Prevent form submission or new line
                if (otpAssossiate[index].length === 1 && index < otpAssossiate.length - 1) {
                    const nextTextField = document.getElementById(`otpAssossiate${index + 1}`);
                    if (nextTextField) {
                        nextTextField.focus();
                    }
                }
            }
        
            // Handle Backspace key press to clear current field and move focus
            if (e.key === 'Backspace') {
                e.preventDefault(); // Prevent default backspace behavior
                const updatedOtp = [...otpAssossiate];
                updatedOtp[index] = ''; // Clear the current field
                setOtpAssossiate(updatedOtp);
        
                // Move cursor to previous TextField if index is greater than 0
                if (index > 0) {
                    const prevTextField = document.getElementById(`otpAssossiate${index - 1}`);
                    if (prevTextField) {
                        prevTextField.focus();
                    }
                }
            }
        };

        
    const handleInputChangeAssossiate = (index, value) => {
        setOtpError("")
        if (!isNaN(value) && value.length <= 1) {
            const updatedOtp = [...otpAssossiate];
            updatedOtp[index] = value;
            setOtpAssossiate(updatedOtp);
        }
    };
  


    return (
        <>
            {(!localStorage.getItem('SPCode')) && (
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center', gap: '16px',
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', minWidth: { md: '98%', sm: '98%', xs: '90%' },
                        justifyContent: 'center', alignItems: 'center', gap: '8px', maxWidth: { md: '98%', sm: '98%', xs: '90%' },
                        marginBottom: { md: '3%', sm: '3%', xs: '0%' }
                    }}>

                        {isContactEnable && (<Box sx={{
                            width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                            justifyContent: 'right', alignItems: 'center', gap: '16px',
                        }}>


                            <ButtonComponent sx={{ width: { md: '25%', sm: '35%', xs: '35%' } }}
                                onClick={() => handleContactButtonClick(serviceProviderDtls.service_provider_id)}>
                                Contact</ButtonComponent>

                        </Box>)}

                        <Box sx={{
                            width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                            alignItems: 'center', gap: '16px',
                        }}>
                            <TypographyComponent sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, color: Colors.black_80 }}>Profile</TypographyComponent>

                        </Box>
                        <Box sx={{
                            width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                            alignItems: 'center', borderRadius: '18px',
                            backgroundColor: Colors.grey_20,
                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
                                maxWidth: { md: '95%', sm: '95%', xs: '95%' }
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', padding: '18px 44px 0px 8%',
                                    gap: '20px', justifyContent: 'space-between', alignItems: 'stretch'
                                }}>

                                    <Box
                                        sx={{
                                            width: '22%',
                                            height: '100%',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'flex-start'

                                        }}
                                    >
                                        {(newProfilePic === null ||
                                            newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' ||
                                            newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/undefined') && (
                                                <Box
                                                    sx={{
                                                        width: { md: '82px', sm: '75px', xs: '56px' },
                                                        height: { md: '82px', sm: '75px', xs: '56px' },
                                                        borderRadius: '50%',
                                                        backgroundColor: profileIndex !== null ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: { xs: '24px', md: '48px' },
                                                        fontWeight: 'bold',
                                                        color: Colors.white,
                                                        // border: '2px solid #ffffff',
                                                        // boxShadow: '0px 4px 4px 0px #00000040',
                                                    }}
                                                >
                                                    {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
                                                </Box>
                                            )}
                                        {(newProfilePic &&
                                            newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' &&
                                            newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/undefined') && (
                                                <img src={newProfilePic} alt="" style={{ width: '82px', height: '82px', borderRadius: '50%' }} />
                                            )}
                                    </Box>


                                    <Box flex={3} sx={{
                                        width: '75%', display: 'flex',
                                        justifyContent: 'left', alignItems: 'flex-end', paddingBottom: '16px'
                                    }}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <TypographyComponent sx={{ color: Colors.primary, fontSize: { md: '24px', sm: '24px', xs: '16px' }, fontWeight: 600 }}>
                                                {serviceProviderDtls.business_name}</TypographyComponent>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>
                                                {serviceProviderDtls.contact_person}</TypographyComponent>
                                        </Box>

                                    </Box>

                                    <Box flex={1} sx={{
                                        width: '5%', display: { md: 'flex', sm: 'flex', xs: 'none' }, gap: '25%',
                                        justifyContent: 'right',
                                    }}>

                                        <MessageOutlinedIcon onClick={() => handleContactButtonClick(serviceProviderDtls.service_provider_id)} sx={{
                                            color: Colors.default, cursor: 'pointer', '&:hover': {
                                                color: Colors.black_80,
                                            }
                                        }} />
                                        <ShareOutlinedIcon onClick={handleShareButtonClick} sx={{
                                            color: Colors.default, cursor: 'pointer', '&:hover': {
                                                color: Colors.black_80,
                                            }
                                        }} />
                                    </Box>
                                    <Box sx={{ width: '5%', display: { md: 'none', sm: 'none', xs: 'flex' }, gap: '8px' }}>
                                        <MessageOutlinedIcon onClick={() => handleContactButtonClick(serviceProviderDtls.service_provider_id)} sx={{
                                            color: Colors.default, cursor: 'pointer', '&:hover': {
                                                color: Colors.black_80,
                                            }
                                        }} />
                                        <ShareOutlinedIcon onClick={handleMobileShareButtonClick} sx={{
                                            color: Colors.default, cursor: 'pointer', '&:hover': {
                                                color: Colors.black_80,
                                            }
                                        }} />
                                    </Box>

                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                                    <Divider orientation="horizontal" flexItem sx={{
                                        height: '100%', width: '8%', borderColor: Colors.black_80,
                                        borderWidth: '1px', backgroundColor: Colors.black_80,
                                    }} />
                                    <div style={{ width: '15%' }}></div>
                                    <Divider orientation="horizontal" flexItem sx={{
                                        height: '100%', width: '72%', borderColor: Colors.black_80,
                                        borderWidth: '1px', backgroundColor: Colors.black_80,
                                    }} />
                                </Box>
                                <Box sx={{
                                    height: '100%', padding: { md: '18px 9.8%', sm: '8px 8px', xs: '8px 8px' },
                                    display: 'flex', flexDirection: 'row', gap: '16px'
                                }}>
                                    <Box sx={{
                                        width: '20%', height: '100%', display: 'flex', flexDirection: 'column',
                                        gap: '8px', justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, letterSpacing: '2px' }}>
                                            {serviceProviderDtls.service_provider_code}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                        {serviceProviderDtls && (
                                            <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '3px',
                                                justifyContent: 'left', alignItems: 'center'
                                            }}>
                                                <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }}></PhoneAndroidIcon>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, paddingLeft: '5px' }}>
                                                    +{serviceProviderDtls.country_code}
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                    {serviceProviderDtls.phone_number}
                                                </TypographyComponent>
                                            </Box>
                                        )}
                                        {serviceProviderDtls.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                                            <EmailIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }}></EmailIcon>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                {serviceProviderDtls.email}
                                            </TypographyComponent>
                                        </Box>}
                                        {serviceProviderDtls && (
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                                                <LocationOnIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }} />
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                    {serviceProviderDtls.city}, {serviceProviderDtls.province}, {serviceProviderDtls.country}

                                                </TypographyComponent>

                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', }} >
                                    <Tabs
                                        value={selectedTab}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="service tabs"
                                        TabIndicatorProps={{ style: { display: 'none' } }}
                                        sx={{
                                            gap: '0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {

                                            }, '& .MuiTab-root': {  // Updated selector
                                                padding: '0px',
                                                minWidth: 'unset !important',  // Remove nested selectors
                                            }
                                        }}

                                    >
                                        {console.log(professionName)}
                                        {professionName.map((service, index) => (
                                            <Tab
                                                key={service.services_id}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: '16px',
                                                            fontWeight: 500,
                                                            color: Colors.black_80,
                                                            cursor: 'pointer',
                                                            minWidth: 'fit-content',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis', textAlign: 'center',
                                                            whiteSpace: 'nowrap', border: '1px solid', borderColor: Colors.grey_30, padding: selectedTab === index ? '10px 12px' : '8px 12px', borderRadius: '4px',
                                                            textTransform: 'none', backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent', marginLeft: '4px',
                                                            //   '&:hover': {
                                                            //     backgroundColor: Colors.grey_30,
                                                            //   },

                                                        }}
                                                    >
                                                        {console.log(service)}
                                                        {service.professional_name}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                    </Tabs>


                                </Box>


                                <Box sx={{ height: '100%', padding: { md: '18px 44px 18px 44px', sm: '18px 24px 18px 24px', xs: '16px 16px 16px 16px' }, display: 'flex', flexDirection: 'column', gap: '12px', }}>

                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>

                                        <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}>
                                        </PsychologyOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' }, minWidth: 'fit-content', }}>
                                            Type of service :
                                        </TypographyComponent>
                                        {serviceDtlsShow.type_details && (
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                {serviceDtlsShow.type_details.map(item => item.types).join(', ')}
                                            </TypographyComponent>)}
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></ApartmentOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Office :
                                        </TypographyComponent>
                                        {serviceDtlsShow.office_address && (
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                {serviceDtlsShow.office_address}
                                            </TypographyComponent>)}
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <LanOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></LanOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Franchise :
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceDtlsShow.franchise}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></BadgeOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            License No :
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceDtlsShow.license}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></WysiwygOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Certification :
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceDtlsShow.certifications}
                                        </TypographyComponent>
                                    </Box>

                                </Box>



                                <Box sx={{
                                    height: '100%', padding: { md: '18px 44px', sm: '18px 44px', xs: '18px' }, display: 'flex', flexDirection: 'row', gap: '8px',
                                    overflow: 'hidden', wordWrap: 'break-word', maxHeight: '100%', justifyContent: 'left', alignItems: 'left'
                                }}>
                                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                        About :
                                    </TypographyComponent>
                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                        {serviceDtlsShow.about_us}
                                    </TypographyComponent>
                                </Box>
                                <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, textAlign: 'center' }}>
                                        Joined by {formattedDate}
                                    </TypographyComponent>

                                </Box>
                            </Box>


                        </Box>

                    </Box>



                    {openQuote && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleCloseQuote} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{

                                        width: { md: '450px', sm: '400px', xs: '85%' },
                                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                                        display: 'flex',
                                        height: 'fit-content',
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        overflowX: 'hidden',
                                        '&::-webkit-scrollbar': {
                                            width: '11px',
                                            height: '8px', // Added height for horizontal scrollbars
                                            borderRadius: '16px',

                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.grey_60,
                                            borderRadius: '16px',
                                            border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px',
                                            backgroundColor: Colors.white,
                                            marginTop: '20px',
                                            marginBottom: '20px'
                                        },
                                    }}
                                >

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%',

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', gap: '22px',
                                            alignItems: 'flex-start', width: '100%'
                                        }}>
                                            <TypographyComponent sx={{
                                                fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 600,
                                                color: Colors.black_80
                                            }}>
                                                Send a message
                                            </TypographyComponent>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%',
                                            }}>
                                                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                    Select the service you are looking for*
                                                </TypographyComponent>



                                                <Box display="flex" alignItems="center" position="relative" width="100%">
                                                    <TextFieldComponent
                                                        variant="outlined"
                                                        value={selectedServices}
                                                        placeholder="Select service"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton ref={serviceRef} onClick={handleToggleService}>
                                                                    {serviceOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        error={!!serviceError}
                                                    />
                                                    {serviceOpen && (
                                                        <Box
                                                            ref={serviceboxRef}
                                                            position="absolute"
                                                            top="100%"
                                                            left="0"
                                                            right="0"
                                                            mt={1}
                                                            p={2}
                                                            bgcolor="white"
                                                            border="1px solid rgba(0, 0, 0, 0.12)"
                                                            boxShadow={3}
                                                            zIndex={1}
                                                            display="flex"
                                                            flexDirection="column"
                                                            borderRadius="8px"
                                                            sx={{
                                                                overflow: 'auto', height: 'fit-content', maxHeight: '150px',
                                                                overflowX: 'hidden',
                                                                '&::-webkit-scrollbar': {
                                                                    width: '11px',
                                                                    height: '8px', // Added height for horizontal scrollbars
                                                                    borderRadius: '16px',

                                                                },
                                                                '&::-webkit-scrollbar-thumb': {
                                                                    backgroundColor: Colors.grey_60,
                                                                    borderRadius: '16px',
                                                                    border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                                },
                                                                '&::-webkit-scrollbar-track': {
                                                                    borderRadius: '16px',
                                                                    backgroundColor: Colors.white,
                                                                    marginTop: '10px',
                                                                    marginBottom: '10px'
                                                                },
                                                            }}
                                                        >
                                                            {uniqueCheckboxes.map((checkbox, index) => (
                                                                <Box key={checkbox.types_id} sx={{
                                                                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
                                                                    alignItems: 'center', gap: '2px'
                                                                }}>
                                                                    {console.log(checkboxes)}
                                                                    <Checkbox
                                                                        checked={checkbox.checked} sx={{
                                                                            '&.Mui-checked': {
                                                                                color: '#0026CC',
                                                                            },
                                                                        }}
                                                                        onChange={handleCheckboxChangeService(checkbox.services, checkbox.serviceId)}
                                                                    />
                                                                    <TypographyComponent sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70 }}>
                                                                        {checkbox.services}</TypographyComponent>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    )}


                                                </Box>

                                                {serviceError &&
                                                    <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                        {serviceError}</TypographyComponent>}
                                            </Box>


                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%',
                                            }}>
                                                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                    Select the type of service you are looking for*
                                                </TypographyComponent>



                                                <Box display="flex" alignItems="center" position="relative" width="100%">
                                                    <TextFieldComponent
                                                        variant="outlined"
                                                        value={selectedTypes}
                                                        placeholder="Select type of service"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton ref={buttonRef} onClick={handleToggle}>
                                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        error={!!typesError}
                                                    />
                                                    {open && (
                                                        <Box
                                                            ref={boxRef}
                                                            position="absolute"
                                                            top="100%"
                                                            left="0"
                                                            right="0"
                                                            mt={1}
                                                            p={2}
                                                            bgcolor="white"
                                                            border="1px solid rgba(0, 0, 0, 0.12)"
                                                            boxShadow={3}
                                                            zIndex={1}
                                                            display="flex"
                                                            flexDirection="column"
                                                            borderRadius="8px"
                                                            sx={{
                                                                overflow: 'auto', height: 'fit-content', maxHeight: '150px',
                                                                overflowX: 'hidden',
                                                                '&::-webkit-scrollbar': {
                                                                    width: '11px',
                                                                    height: '8px', // Added height for horizontal scrollbars
                                                                    borderRadius: '16px',

                                                                },
                                                                '&::-webkit-scrollbar-thumb': {
                                                                    backgroundColor: Colors.grey_60,
                                                                    borderRadius: '16px',
                                                                    border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                                },
                                                                '&::-webkit-scrollbar-track': {
                                                                    borderRadius: '16px',
                                                                    backgroundColor: Colors.white,
                                                                    marginTop: '10px',
                                                                    marginBottom: '10px'
                                                                },
                                                            }}
                                                        >
                                                            {Object.keys(groupedCheckboxes).map((service) => (
                                                                <Box key={service} sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column', gap: '8px'
                                                                }} >
                                                                    <TypographyComponent sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.default }}>
                                                                        {service}</TypographyComponent>
                                                                    {/* {groupedCheckboxes[service].map((checkbox, index) => (
                                                        <Box key={checkbox.types_id} sx={{display:'flex',flexDirection:'row',
                                                            justifyContent:'flex-start', alignItems:'center',gap:'2px'
                                                        }}>
                                                             {console.log(checkboxes)}
                                                         <Checkbox
                                                            checked={checkbox.checked} sx={{ '&.Mui-checked': {
                                                                color: '#0026CC',marginRight:'8px',flex:1
                                                            },}}
                                                            onChange={handleCheckboxChange(index, checkbox.types, checkbox.types_id, checkbox.services)}
                                                        />                                                     
                                                        <TypographyComponent sx={{ fontSize:{ md: '14px', sm: '14px', xs: '12px' },alignItems:'stretch',
                                                         color: Colors.black_70, }}>
                                                        
                                                       {checkbox.types}</TypographyComponent>
                                                        </Box>
                                                    ))}  */}
                                                                    {groupedCheckboxes[service].map((checkbox, index) => (
                                                                        <Box
                                                                            key={checkbox.types_id}
                                                                            sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                justifyContent: 'flex-start',
                                                                                alignItems: 'flex-start', // Align items at the top
                                                                                gap: '8px' // Adjust gap as needed
                                                                            }}
                                                                        >
                                                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', }}>
                                                                                <Checkbox
                                                                                    checked={checkbox.checked}
                                                                                    sx={{
                                                                                        '&.Mui-checked': {
                                                                                            color: '#0026CC',
                                                                                        }, color: Colors.default,
                                                                                        padding: 0, // Remove padding for better alignment
                                                                                        // Ensure space between checkbox and text
                                                                                    }}
                                                                                    onChange={handleCheckboxChange(index, checkbox.types, checkbox.types_id, checkbox.services)}
                                                                                />
                                                                            </Box>
                                                                            <TypographyComponent
                                                                                sx={{
                                                                                    fontSize: { md: '14px', sm: '14px', xs: '12px' },
                                                                                    color: Colors.black_70,
                                                                                    display: 'inline-block' // Keep text inline for proper wrapping
                                                                                }}
                                                                            >
                                                                                {checkbox.types}
                                                                            </TypographyComponent>
                                                                        </Box>
                                                                    ))}



                                                                </Box>
                                                            ))}

                                                        </Box>
                                                    )}
                                                </Box>

                                                {typesError &&
                                                    <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                        {typesError}</TypographyComponent>}
                                            </Box>

                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%'
                                            }}>
                                                <Box sx={{ width: { md: '25%', sm: '25%', xs: '35%' } }}>
                                                    <TypographyComponent >Country*</TypographyComponent>
                                                    <TextFieldComponent placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                        inputProps={{ maxLength: 3 }}
                                                        sx={{
                                                            fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                        value={countryCode}
                                                        onChange={handleCountryCodeChange} error={!!countryCodeError}></TextFieldComponent>
                                                    {countryCodeError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{countryCodeError}</TypographyComponent>}
                                                </Box>
                                                <Box sx={{ width: { md: '75%', sm: '75%', xs: '65%' } }}>
                                                    <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                        Phone number*
                                                    </TypographyComponent>
                                                    <TextFieldComponent placeholder="Add phone number"
                                                        onKeyDown={handleKeyDownPhone}
                                                        inputProps={{ maxLength: 10 }}
                                                        value={phoneNumber}
                                                        sx={{
                                                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                        onChange={handlePhoneNumberChange} error={!!phoneNumberError}>
                                                    </TextFieldComponent>
                                                    {phoneNumberError &&
                                                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                            {phoneNumberError}</TypographyComponent>}
                                                </Box>

                                            </Box>
                                            <TypographyComponent sx={{ fontSize: '12px', }}>
                                                By clicking the send button, you agree to our{' '}
                                                <Link onClick={handleTermsAndCondtionClick} style={{ textDecoration: 'none', color: Colors.link }}>
                                                    Terms and conditions
                                                </Link>.
                                            </TypographyComponent>

                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', gap: '16px',
                                            marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                        }}>
                                            <Box sx={{ width: '40%' }}>
                                                <ButtonComponent onClick={handleSendOtpClick}>Send</ButtonComponent>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    )}

                    {isTermPopUp && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleCloseTerm} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{

                                        width: { md: '450px', sm: '400px', xs: '85%' },
                                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                                        display: 'flex',
                                        height: { md: '400px', sm: '400px', xs: '45%' },
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        overflowX: 'hidden',
                                        '&::-webkit-scrollbar': {
                                            width: '12px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#7f7f7f',
                                            borderRadius: '28px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '28px',
                                        },
                                    }}
                                >

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'flex-start', gap: '22px',
                                            alignItems: 'flex-start', width: '100%'
                                        }}>

                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%',
                                            }}>
                                                <TypographyComponent sx={{ fontWeight: 600, fontFamily: '24px' }}>
                                                    Terms And Condition
                                                </TypographyComponent>


                                            </Box>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%'
                                            }}>
                                                <TypographyComponent>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                                                </TypographyComponent>


                                            </Box>

                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {confirmQuoteSend && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '400px', sm: '400px', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleNoSendQuote} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        width: { md: '420px', sm: '420px', xs: '85%' },
                                        height: 'fit-content',
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '12px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#7f7f7f',
                                            borderRadius: '28px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '28px',
                                        },
                                    }}
                                >


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', gap: '22px',
                                            alignItems: 'flex-start', width: '100%'
                                        }}>

                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%'
                                            }}>
                                                <TypographyComponent>
                                                    Thank you for submitting your quote request.
                                                </TypographyComponent>
                                                {/* <TypographyComponent>
                                          You have selected {typesshowName}services, along with the phone number +{countryCode} {phoneNumber}. 
                                        </TypographyComponent> */}
                                                <TypographyComponent>
                                                    You have selected {typesshowName.split(', ').map((type, index) => {
                                                        const typeName = type.trim();
                                                        const serviceName = checkboxesService.find(service => service.types === typeName)?.services;

                                                        return (
                                                            <span key={index}>
                                                                {typeName} ({serviceName})
                                                                {index < typesshowName.split(', ').length - 1 ? ', ' : ''}
                                                            </span>
                                                        );
                                                    })} services, along with the phone number +{countryCode} {phoneNumber}.
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    Are you sure you want to proceed?"
                                                </TypographyComponent>

                                            </Box>

                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', gap: '16px',
                                            marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                        }}>
                                            <Box sx={{
                                                width: '20%', display: 'flex', flexDirection: 'row', gap: '16px',
                                            }}>  </Box>
                                            <Box sx={{
                                                width: '80%', display: 'flex', flexDirection: 'row', gap: '16px',
                                                justifyContent: 'right',
                                            }}>
                                                <ButtonComponentWhite onClick={handleNoSendQuote}>No</ButtonComponentWhite>
                                                <ButtonComponent onClick={handleYesSendQuote}>Yes</ButtonComponent>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {isShare &&
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '2',

                        }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleSharePopUpCancelButtonClick} sx={{
                                        position: 'fixed', width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{

                                        width: { md: '35%', sm: '35%', xs: '85%' },
                                        height: 'fit-content',
                                        backgroundColor: Colors.white,
                                        border: '1px solid',
                                        borderColor: Colors.white,
                                        borderRadius: '16px ',
                                        boxShadow: 'none',
                                        boxSizing: 'border-box',
                                        zIndex: '3',
                                        padding: '20px',
                                        alignItems: 'center',

                                    }}
                                >

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignContent: 'center', height: '100%',
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                        }}>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '18px', textAlign: 'left' }}>
                                                Share link
                                            </TypographyComponent>

                                            <Box sx={{
                                                width: 'calc(100%-32px)', height: '28px', border: '1px solid', padding: '10px 16px 10px 16px',
                                                borderColor: Colors.grey_60, borderRadius: '28px', display: 'flex', flexDirection: 'row',
                                            }}>
                                                <InputBase
                                                    value={serviceProviderUrl}
                                                    style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent' }}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: 'Roboto', fontSize: '16px',
                                                            '::placeholder': {
                                                                fontFamily: 'Roboto', fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{
                                                width: 'calc(100%)', height: '100%',
                                                display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                            }}
                                            >
                                                <ButtonComponent sx={{ width: 'fit-content', padding: '16px' }} onClick={handleCopyButtonClick}><ContentCopyIcon style={{ paddingRight: '5px' }} />Copy link</ButtonComponent>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}
                    {otpPopUp &&
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '568px', sm: '450px', xs: '320px' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleCloseOtpPopUp} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{

                                        width: { md: '568px', sm: '450px', xs: '320px' },
                                        height: 'fit-content',
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '12px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#7f7f7f',
                                            borderRadius: '28px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '28px',
                                        },
                                    }}
                                >


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%',

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', gap: '20px',
                                            alignItems: 'center', width: '100%'
                                        }}>
                                            <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                                OTP Verification </TypographyComponent>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'center', width: '100%', paddingTop: typesError ? '0px' : '15px'
                                            }}>
                                                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                                    We have sent you a one-time password to the mobile number
                                                    <span style={{ color: '#626262' }}> XXXXXX{phoneNumber.slice(-4)}</span>  </TypographyComponent>
                                                <TypographyComponent>
                                                    Enter your OTP here
                                                </TypographyComponent>
                                                <Box display="flex" justifyContent="center" alignContent="center" textAlign="center" mt={2}>
                                                    {otp.map((digit, index) => (
                                                        <TextField
                                                            key={index}
                                                            id={`otp${index}`}
                                                            value={digit}
                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                            onPaste={handlePaste}
                                                            error={(verificationAttempted && !digit) || !!otpError}
                                                            onInput={(e) => {
                                                                const input = e.target.value;
                                                                const digitValue = input.replace(/\D/, '').slice(0, 1); // Ensure it's a single digit number
                                                                handleInputChange(index, digitValue);
                                                                if (digitValue && index < otp.length - 1) {
                                                                    const nextTextField = document.getElementById(`otp${index + 1}`);
                                                                    if (nextTextField) {
                                                                        nextTextField.focus();
                                                                    }
                                                                }
                                                            }}
                                                            inputRef={index === 0 ? firstOtpInputRef : null}
                                                            style={{
                                                                width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                                {otpError && (
                                                    <Box display="flex" justifyContent="center" mt={1}>
                                                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                                        </TypographyComponent>
                                                    </Box>
                                                )}
                                                <TypographyComponent>Don't receive the OTP?</TypographyComponent>
                                                <Button
                                                    onClick={handleSendOtpClick}
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: Colors.link,
                                                        textTransform: 'none',
                                                        padding: 0,
                                                        minWidth: 'unset',
                                                        '&:hover': {
                                                            backgroundColor: 'transparent', // This removes the hover effect
                                                        },
                                                    }}
                                                >
                                                    Resend OTP
                                                </Button>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column', marginTop: '16px', justifyContent: 'center',
                                                alignContent: 'center', alignItems: 'center', width: '100%'
                                            }}>
                                                <Box sx={{ width: '100%' }}>
                                                    <ButtonComponent onClick={handleVerifyOTP} >Verify</ButtonComponent>
                                                </Box>

                                            </Box>

                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }






                </Box>)}

            {localStorage.getItem('SPCode') && (
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center', gap: '16px',
                }}>
                    <Box sx={{
                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', minWidth: { md: '98%', sm: '98%', xs: '90%' },
                        justifyContent: 'center', alignItems: 'center', gap: '8px', maxWidth: { md: '98%', sm: '98%', xs: '90%' },
                        marginBottom: { md: '3%', sm: '3%', xs: '0%' }
                    }}>

                        {/* {isContactEnable && (<Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                    justifyContent: 'right', alignItems: 'center', gap: '16px',
                }}>

                  
                    <ButtonComponent sx={{ width: { md: '25%', sm: '35%', xs: '35%' } }}
                        onClick={() => handleContactButtonClick(serviceProviderDtls.service_provider_id)}>
                        Contact</ButtonComponent>

                </Box>)} */}

                        <Box sx={{
                            width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                            alignItems: 'center', gap: '16px',
                        }}>
                            <TypographyComponent sx={{ fontSize: { md: '18px', sm: '18px', xs: '16px' }, color: Colors.black_80 }}>Profile</TypographyComponent>

                        </Box>
                        <Box sx={{
                            width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                            alignItems: 'center', borderRadius: '18px',
                            backgroundColor: Colors.grey_20,
                        }}>
                            <Box sx={{
                                width: '100%', height: '97.5%', display: 'flex', flexDirection: 'column',
                                maxWidth: { md: '95%', sm: '95%', xs: '95%' }, paddingTop: '2.5%',
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', padding: '18px 44px 0px 8%',
                                    justifyContent: 'space-between', alignItems: 'stretch',
                                }}>

                                    <Box
                                        sx={{
                                            width: '22%', height: '100%', justifyContent: 'center', display: 'flex',
                                             alignItems: 'center',

                                        }}
                                    >
                                        {(newProfilePic === null ||
                                            newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' ||
                                            newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/undefined') && (
                                                <Box
                                                    sx={{
                                                        width: { md: '82px', sm: '75px', xs: '56px' },
                                                        height: { md: '82px', sm: '75px', xs: '56px' },
                                                        borderRadius: '50%',
                                                        backgroundColor: profileIndex !== null ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: { xs: '24px', md: '48px' },
                                                        fontWeight: 'bold',
                                                        color: Colors.white,border:'1px solid red'
                                                        // border: '2px solid #ffffff',
                                                        // boxShadow: '0px 4px 4px 0px #00000040',
                                                    }}
                                                >
                                                    {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
                                                </Box>
                                            )}
                                        {(newProfilePic &&
                                            newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' &&
                                            newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/undefined') && (
                                                <img src={newProfilePic} alt="" style={{ width: '82px', height: '82px', borderRadius: '50%' }} />
                                            )}
                                    </Box>


                                  <Box sx={{
                                       display: 'flex',width:'88%',flexDirection:'row',
                                        justifyContent: 'space-between', alignItems: 'flex-end', paddingBottom: '16px',
                                    }}>
                                   <Box sx={{
                                       display: 'flex',width:'50%',  justifyContent: 'left', alignItems: 'flex-end',
                                        
                                    }}>
                                         
                                            
                                             <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '18px', sm: '18px', xs: '12px' } }}>
                                                {`${professionName.map(detail => detail.professional_name).join(', ')}`}</TypographyComponent>
                                        

                                    </Box> 
                                    <Box sx={{
                                       display: 'flex',width:'50%',  justifyContent: 'right', alignItems: 'flex-start',
                                       gap:'16px',height:'100%'
                                        
                                    }}>
                                       <ButtonComponent disabled={isAcceptRejectClick} onClick={() => handleEnableClick(serviceProviderDtls.phone_number, serviceProviderDtls.country_code)}
                                       sx={{width:'100%', height:'38px'}}>Accept</ButtonComponent>  
                                       <ButtonComponentWhite disabled={isAcceptRejectClick}  onClick={() => handleDisableClick(serviceProviderDtls.phone_number, serviceProviderDtls.country_code)}
                                        sx={{width:'100%', height:'38px'}}>Reject</ButtonComponentWhite> 
                                    </Box> 
                                    </Box> 

                                   


                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', marginTop: '-5px' }}>
                                    <Divider orientation="horizontal" flexItem sx={{
                                        height: '100%', width: '8%', borderColor: Colors.black_80,
                                        borderWidth: '1px', backgroundColor: Colors.black_80,
                                    }} />
                                    <div style={{ width: '15%' }}></div>
                                    <Divider orientation="horizontal" flexItem sx={{
                                        height: '100%', width: '72%', borderColor: Colors.black_80,
                                        borderWidth: '1px', backgroundColor: Colors.black_80,
                                    }} />
                                </Box>
                                <Box sx={{
                                    height: '100%', padding: { md: '18px 9.8%', sm: '8px 8px', xs: '8px 8px' },
                                    display: 'flex', flexDirection: 'row', gap: '16px'
                                }}>
                                    <Box sx={{
                                        width: '20%', height: '100%', display: 'flex', flexDirection: 'column',
                                        gap: '8px', justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, letterSpacing: '2px' }}>
                                            {serviceProviderDtls.service_provider_code}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                        {serviceProviderDtls && (
                                            <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '3px',
                                                justifyContent: 'left', alignItems: 'center'
                                            }}>
                                                <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }}></PhoneAndroidIcon>
                                                {/* <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, paddingLeft: '5px' }}>
                                            +{serviceProviderDtls.country_code}
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceProviderDtls.phone_number}
                                        </TypographyComponent> */}
                                            </Box>
                                        )}
                                        {serviceProviderDtls.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                                            <EmailIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }}></EmailIcon>
                                            {/* <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                        {serviceProviderDtls.email}
                                    </TypographyComponent> */}
                                        </Box>}
                                        {serviceProviderDtls && (
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                                                <LocationOnIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }} />
                                                {/* <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceProviderDtls.city}, {serviceProviderDtls.province}, {serviceProviderDtls.country}

                                        </TypographyComponent> */}

                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', }} >
                                    <Tabs
                                        value={selectedTab}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="service tabs"
                                        TabIndicatorProps={{ style: { display: 'none' } }}
                                        sx={{
                                            gap: '0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {

                                            }, '& .MuiTab-root': {  // Updated selector
                                                padding: '0px',
                                                minWidth: 'unset !important',  // Remove nested selectors
                                            }
                                        }}

                                    >                                      
                                        {professionName.map((service, index) => (
                                            <Tab
                                                key={service.services_id}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: '16px',
                                                            fontWeight: 500,
                                                            color: Colors.black_80,
                                                            cursor: 'pointer',
                                                            minWidth: 'fit-content',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis', textAlign: 'center',
                                                            whiteSpace: 'nowrap', border: '1px solid', borderColor: Colors.grey_30, padding: selectedTab === index ? '10px 12px' : '8px 12px', borderRadius: '4px',
                                                            textTransform: 'none', backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent', marginLeft: '4px',
                                                            //   '&:hover': {
                                                            //     backgroundColor: Colors.grey_30,
                                                            //   },

                                                        }}
                                                    >
                                                        {console.log(service)}
                                                        {service.professional_name}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                    </Tabs>


                                </Box>


                                <Box sx={{ height: '100%', padding: { md: '18px 44px 18px 44px', sm: '18px 24px 18px 24px', xs: '16px 16px 16px 16px' }, display: 'flex', flexDirection: 'column', gap: '12px', }}>

                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>

                                        <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}>
                                        </PsychologyOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' }, minWidth: 'fit-content', }}>
                                            Type of service :
                                        </TypographyComponent>
                                        {serviceDtlsShow.type_details && (
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                {serviceDtlsShow.type_details.map(item => item.types).join(', ')}
                                            </TypographyComponent>)}
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></ApartmentOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Office :
                                        </TypographyComponent>
                                        {serviceDtlsShow.office_address && (
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                {serviceDtlsShow.office_address}
                                            </TypographyComponent>)}
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <LanOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></LanOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Franchise :
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceDtlsShow.franchise}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></BadgeOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            License No :
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceDtlsShow.license}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                                        justifyContent: 'left', alignItems: 'left'
                                    }}>
                                        <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></WysiwygOutlinedIcon>
                                        <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Certification :
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            {serviceDtlsShow.certifications}
                                        </TypographyComponent>
                                    </Box>

                                </Box>



                                <Box sx={{
                                    height: '100%', padding: { md: '18px 44px', sm: '18px 44px', xs: '18px' }, display: 'flex', flexDirection: 'row', gap: '8px',
                                    overflow: 'hidden', wordWrap: 'break-word', maxHeight: '100%', justifyContent: 'left', alignItems: 'left'
                                }}>
                                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                        About :
                                    </TypographyComponent>
                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                        {serviceDtlsShow.about_us}
                                    </TypographyComponent>
                                </Box>
                                <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, textAlign: 'center' }}>
                                        Joined by {formattedDate}
                                    </TypographyComponent>

                                </Box>
                            </Box>


                        </Box>

                    </Box>



                    {openQuote && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleCloseQuote} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{

                                        width: { md: '450px', sm: '400px', xs: '85%' },
                                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                                        display: 'flex',
                                        height: 'fit-content',
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        overflowX: 'hidden',
                                        '&::-webkit-scrollbar': {
                                            width: '11px',
                                            height: '8px', // Added height for horizontal scrollbars
                                            borderRadius: '16px',

                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.grey_60,
                                            borderRadius: '16px',
                                            border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px',
                                            backgroundColor: Colors.white,
                                            marginTop: '20px',
                                            marginBottom: '20px'
                                        },
                                    }}
                                >

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%',

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', gap: '22px',
                                            alignItems: 'flex-start', width: '100%'
                                        }}>
                                            <TypographyComponent sx={{
                                                fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 600,
                                                color: Colors.black_80
                                            }}>
                                                Send a message
                                            </TypographyComponent>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%',
                                            }}>
                                                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                    Select the service you are looking for*
                                                </TypographyComponent>



                                                <Box display="flex" alignItems="center" position="relative" width="100%">
                                                    <TextFieldComponent
                                                        variant="outlined"
                                                        value={selectedServices}
                                                        placeholder="Select service"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton ref={serviceRef} onClick={handleToggleService}>
                                                                    {serviceOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        error={!!serviceError}
                                                    />
                                                    {serviceOpen && (
                                                        <Box
                                                            ref={serviceboxRef}
                                                            position="absolute"
                                                            top="100%"
                                                            left="0"
                                                            right="0"
                                                            mt={1}
                                                            p={2}
                                                            bgcolor="white"
                                                            border="1px solid rgba(0, 0, 0, 0.12)"
                                                            boxShadow={3}
                                                            zIndex={1}
                                                            display="flex"
                                                            flexDirection="column"
                                                            borderRadius="8px"
                                                            sx={{
                                                                overflow: 'auto', height: 'fit-content', maxHeight: '150px',
                                                                overflowX: 'hidden',
                                                                '&::-webkit-scrollbar': {
                                                                    width: '11px',
                                                                    height: '8px', // Added height for horizontal scrollbars
                                                                    borderRadius: '16px',

                                                                },
                                                                '&::-webkit-scrollbar-thumb': {
                                                                    backgroundColor: Colors.grey_60,
                                                                    borderRadius: '16px',
                                                                    border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                                },
                                                                '&::-webkit-scrollbar-track': {
                                                                    borderRadius: '16px',
                                                                    backgroundColor: Colors.white,
                                                                    marginTop: '10px',
                                                                    marginBottom: '10px'
                                                                },
                                                            }}
                                                        >
                                                            {uniqueCheckboxes.map((checkbox, index) => (
                                                                <Box key={checkbox.types_id} sx={{
                                                                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
                                                                    alignItems: 'center', gap: '2px'
                                                                }}>
                                                                    {console.log(checkboxes)}
                                                                    <Checkbox
                                                                        checked={checkbox.checked} sx={{
                                                                            '&.Mui-checked': {
                                                                                color: '#0026CC',
                                                                            },
                                                                        }}
                                                                        onChange={handleCheckboxChangeService(checkbox.services, checkbox.serviceId)}
                                                                    />
                                                                    <TypographyComponent sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70 }}>
                                                                        {checkbox.services}</TypographyComponent>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    )}


                                                </Box>

                                                {serviceError &&
                                                    <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                        {serviceError}</TypographyComponent>}
                                            </Box>


                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%',
                                            }}>
                                                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                    Select the type of service you are looking for*
                                                </TypographyComponent>



                                                <Box display="flex" alignItems="center" position="relative" width="100%">
                                                    <TextFieldComponent
                                                        variant="outlined"
                                                        value={selectedTypes}
                                                        placeholder="Select type of service"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton ref={buttonRef} onClick={handleToggle}>
                                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        error={!!typesError}
                                                    />
                                                    {open && (
                                                        <Box
                                                            ref={boxRef}
                                                            position="absolute"
                                                            top="100%"
                                                            left="0"
                                                            right="0"
                                                            mt={1}
                                                            p={2}
                                                            bgcolor="white"
                                                            border="1px solid rgba(0, 0, 0, 0.12)"
                                                            boxShadow={3}
                                                            zIndex={1}
                                                            display="flex"
                                                            flexDirection="column"
                                                            borderRadius="8px"
                                                            sx={{
                                                                overflow: 'auto', height: 'fit-content', maxHeight: '150px',
                                                                overflowX: 'hidden',
                                                                '&::-webkit-scrollbar': {
                                                                    width: '11px',
                                                                    height: '8px', // Added height for horizontal scrollbars
                                                                    borderRadius: '16px',

                                                                },
                                                                '&::-webkit-scrollbar-thumb': {
                                                                    backgroundColor: Colors.grey_60,
                                                                    borderRadius: '16px',
                                                                    border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                                },
                                                                '&::-webkit-scrollbar-track': {
                                                                    borderRadius: '16px',
                                                                    backgroundColor: Colors.white,
                                                                    marginTop: '10px',
                                                                    marginBottom: '10px'
                                                                },
                                                            }}
                                                        >
                                                            {Object.keys(groupedCheckboxes).map((service) => (
                                                                <Box key={service} sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column', gap: '8px'
                                                                }} >
                                                                    <TypographyComponent sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.default }}>
                                                                        {service}</TypographyComponent>
                                                                    {/* {groupedCheckboxes[service].map((checkbox, index) => (
                                                        <Box key={checkbox.types_id} sx={{display:'flex',flexDirection:'row',
                                                            justifyContent:'flex-start', alignItems:'center',gap:'2px'
                                                        }}>
                                                             {console.log(checkboxes)}
                                                         <Checkbox
                                                            checked={checkbox.checked} sx={{ '&.Mui-checked': {
                                                                color: '#0026CC',marginRight:'8px',flex:1
                                                            },}}
                                                            onChange={handleCheckboxChange(index, checkbox.types, checkbox.types_id, checkbox.services)}
                                                        />                                                     
                                                        <TypographyComponent sx={{ fontSize:{ md: '14px', sm: '14px', xs: '12px' },alignItems:'stretch',
                                                         color: Colors.black_70, }}>
                                                        
                                                       {checkbox.types}</TypographyComponent>
                                                        </Box>
                                                    ))}  */}
                                                                    {groupedCheckboxes[service].map((checkbox, index) => (
                                                                        <Box
                                                                            key={checkbox.types_id}
                                                                            sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                justifyContent: 'flex-start',
                                                                                alignItems: 'flex-start', // Align items at the top
                                                                                gap: '8px' // Adjust gap as needed
                                                                            }}
                                                                        >
                                                                            <Box sx={{ display: 'flex', alignItems: 'flex-start', }}>
                                                                                <Checkbox
                                                                                    checked={checkbox.checked}
                                                                                    sx={{
                                                                                        '&.Mui-checked': {
                                                                                            color: '#0026CC',
                                                                                        }, color: Colors.default,
                                                                                        padding: 0, // Remove padding for better alignment
                                                                                        // Ensure space between checkbox and text
                                                                                    }}
                                                                                    onChange={handleCheckboxChange(index, checkbox.types, checkbox.types_id, checkbox.services)}
                                                                                />
                                                                            </Box>
                                                                            <TypographyComponent
                                                                                sx={{
                                                                                    fontSize: { md: '14px', sm: '14px', xs: '12px' },
                                                                                    color: Colors.black_70,
                                                                                    display: 'inline-block' // Keep text inline for proper wrapping
                                                                                }}
                                                                            >
                                                                                {checkbox.types}
                                                                            </TypographyComponent>
                                                                        </Box>
                                                                    ))}



                                                                </Box>
                                                            ))}

                                                        </Box>
                                                    )}
                                                </Box>

                                                {typesError &&
                                                    <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                        {typesError}</TypographyComponent>}
                                            </Box>

                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%'
                                            }}>
                                                <Box sx={{ width: { md: '25%', sm: '25%', xs: '35%' } }}>
                                                    <TypographyComponent >Country*</TypographyComponent>
                                                    <TextFieldComponent placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                        inputProps={{ maxLength: 3 }}
                                                        sx={{
                                                            fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                        value={countryCode}
                                                        onChange={handleCountryCodeChange} error={!!countryCodeError}></TextFieldComponent>
                                                    {countryCodeError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{countryCodeError}</TypographyComponent>}
                                                </Box>
                                                <Box sx={{ width: { md: '75%', sm: '75%', xs: '65%' } }}>
                                                    <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                        Phone number*
                                                    </TypographyComponent>
                                                    <TextFieldComponent placeholder="Add phone number"
                                                        onKeyDown={handleKeyDownPhone}
                                                        inputProps={{ maxLength: 10 }}
                                                        value={phoneNumber}
                                                        sx={{
                                                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                        onChange={handlePhoneNumberChange} error={!!phoneNumberError}>
                                                    </TextFieldComponent>
                                                    {phoneNumberError &&
                                                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                            {phoneNumberError}</TypographyComponent>}
                                                </Box>

                                            </Box>
                                            <TypographyComponent sx={{ fontSize: '12px', }}>
                                                By clicking the send button, you agree to our{' '}
                                                <Link onClick={handleTermsAndCondtionClick} style={{ textDecoration: 'none', color: Colors.link }}>
                                                    Terms and conditions
                                                </Link>.
                                            </TypographyComponent>

                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', gap: '16px',
                                            marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                        }}>
                                            <Box sx={{ width: '40%' }}>
                                                <ButtonComponent onClick={handleSendOtpClick}>Send</ButtonComponent>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    )}

                    {isTermPopUp && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleCloseTerm} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{

                                        width: { md: '450px', sm: '400px', xs: '85%' },
                                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                                        display: 'flex',
                                        height: { md: '400px', sm: '400px', xs: '45%' },
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        overflowX: 'hidden',
                                        '&::-webkit-scrollbar': {
                                            width: '12px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#7f7f7f',
                                            borderRadius: '28px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '28px',
                                        },
                                    }}
                                >

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'flex-start', gap: '22px',
                                            alignItems: 'flex-start', width: '100%'
                                        }}>

                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%',
                                            }}>
                                                <TypographyComponent sx={{ fontWeight: 600, fontFamily: '24px' }}>
                                                    Terms And Condition
                                                </TypographyComponent>


                                            </Box>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%'
                                            }}>
                                                <TypographyComponent>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                                                </TypographyComponent>


                                            </Box>

                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {confirmQuoteSend && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '400px', sm: '400px', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleNoSendQuote} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        width: { md: '420px', sm: '420px', xs: '85%' },
                                        height: 'fit-content',
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '12px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#7f7f7f',
                                            borderRadius: '28px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '28px',
                                        },
                                    }}
                                >


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', gap: '22px',
                                            alignItems: 'flex-start', width: '100%'
                                        }}>

                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'flex-start', width: '100%'
                                            }}>
                                                <TypographyComponent>
                                                    Thank you for submitting your quote request.
                                                </TypographyComponent>
                                                {/* <TypographyComponent>
                                          You have selected {typesshowName}services, along with the phone number +{countryCode} {phoneNumber}. 
                                        </TypographyComponent> */}
                                                <TypographyComponent>
                                                    You have selected {typesshowName.split(', ').map((type, index) => {
                                                        const typeName = type.trim();
                                                        const serviceName = checkboxesService.find(service => service.types === typeName)?.services;

                                                        return (
                                                            <span key={index}>
                                                                {typeName} ({serviceName})
                                                                {index < typesshowName.split(', ').length - 1 ? ', ' : ''}
                                                            </span>
                                                        );
                                                    })} services, along with the phone number +{countryCode} {phoneNumber}.
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    Are you sure you want to proceed?"
                                                </TypographyComponent>

                                            </Box>

                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', gap: '16px',
                                            marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                        }}>
                                            <Box sx={{
                                                width: '20%', display: 'flex', flexDirection: 'row', gap: '16px',
                                            }}>  </Box>
                                            <Box sx={{
                                                width: '80%', display: 'flex', flexDirection: 'row', gap: '16px',
                                                justifyContent: 'right',
                                            }}>
                                                <ButtonComponentWhite onClick={handleNoSendQuote}>No</ButtonComponentWhite>
                                                <ButtonComponent onClick={handleYesSendQuote}>Yes</ButtonComponent>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {isShare &&
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '2',

                        }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleSharePopUpCancelButtonClick} sx={{
                                        position: 'fixed', width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{

                                        width: { md: '35%', sm: '35%', xs: '85%' },
                                        height: 'fit-content',
                                        backgroundColor: Colors.white,
                                        border: '1px solid',
                                        borderColor: Colors.white,
                                        borderRadius: '16px ',
                                        boxShadow: 'none',
                                        boxSizing: 'border-box',
                                        zIndex: '3',
                                        padding: '20px',
                                        alignItems: 'center',

                                    }}
                                >

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignContent: 'center', height: '100%',
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                        }}>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '18px', textAlign: 'left' }}>
                                                Share link
                                            </TypographyComponent>

                                            <Box sx={{
                                                width: 'calc(100%-32px)', height: '28px', border: '1px solid', padding: '10px 16px 10px 16px',
                                                borderColor: Colors.grey_60, borderRadius: '28px', display: 'flex', flexDirection: 'row',
                                            }}>
                                                <InputBase
                                                    value={serviceProviderUrl}
                                                    style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent' }}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: 'Roboto', fontSize: '16px',
                                                            '::placeholder': {
                                                                fontFamily: 'Roboto', fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{
                                                width: 'calc(100%)', height: '100%',
                                                display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                            }}
                                            >
                                                <ButtonComponent sx={{ width: 'fit-content', padding: '16px' }} onClick={handleCopyButtonClick}><ContentCopyIcon style={{ paddingRight: '5px' }} />Copy link</ButtonComponent>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}

                    {otpPopUp &&
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '568px', sm: '450px', xs: '320px' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                    <IconButton onClick={handleCloseOtpPopUp} sx={{
                                        width: '36px', height: '36px',

                                    }} >
                                        <CancelIcon sx={{
                                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                                color: Colors.close,
                                            },
                                        }} ></CancelIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{

                                        width: { md: '568px', sm: '450px', xs: '320px' },
                                        height: 'fit-content',
                                        backgroundColor: '#fff',
                                        border: '1px solid #E6E6E6',
                                        borderRadius: '28px ',
                                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                        boxSizing: 'border-box',
                                        zIndex: 3,
                                        padding: '35px',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '12px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#7f7f7f',
                                            borderRadius: '28px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '28px',
                                        },
                                    }}
                                >


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%',

                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', gap: '20px',
                                            alignItems: 'center', width: '100%'
                                        }}>
                                            <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                                OTP Verification </TypographyComponent>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                justifyContent: 'flex-start', gap: '8px',
                                                alignItems: 'center', width: '100%', paddingTop: typesError ? '0px' : '15px'
                                            }}>
                                                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                                    We have sent you a one-time password to the mobile number
                                                    <span style={{ color: '#626262' }}> XXXXXX{phoneNumber.slice(-4)}</span>  </TypographyComponent>
                                                <TypographyComponent>
                                                    Enter your OTP here
                                                </TypographyComponent>
                                                <Box display="flex" justifyContent="center" alignContent="center" textAlign="center" mt={2}>
                                                    {otp.map((digit, index) => (
                                                        <TextField
                                                            key={index}
                                                            id={`otp${index}`}
                                                            value={digit}
                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                            onPaste={handlePaste}
                                                            error={(verificationAttempted && !digit) || !!otpError}
                                                            onInput={(e) => {
                                                                const input = e.target.value;
                                                                const digitValue = input.replace(/\D/, '').slice(0, 1); // Ensure it's a single digit number
                                                                handleInputChange(index, digitValue);
                                                                if (digitValue && index < otp.length - 1) {
                                                                    const nextTextField = document.getElementById(`otp${index + 1}`);
                                                                    if (nextTextField) {
                                                                        nextTextField.focus();
                                                                    }
                                                                }
                                                            }}
                                                            inputRef={index === 0 ? firstOtpInputRef : null}
                                                            style={{
                                                                width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                                {otpError && (
                                                    <Box display="flex" justifyContent="center" mt={1}>
                                                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                                        </TypographyComponent>
                                                    </Box>
                                                )}
                                                <TypographyComponent>Don't receive the OTP?</TypographyComponent>
                                                <Button
                                                    onClick={handleSendOtpClick}
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: Colors.link,
                                                        textTransform: 'none',
                                                        padding: 0,
                                                        minWidth: 'unset',
                                                        '&:hover': {
                                                            backgroundColor: 'transparent', // This removes the hover effect
                                                        },
                                                    }}
                                                >
                                                    Resend OTP
                                                </Button>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column', marginTop: '16px', justifyContent: 'center',
                                                alignContent: 'center', alignItems: 'center', width: '100%'
                                            }}>
                                                <Box sx={{ width: '100%' }}>
                                                    <ButtonComponent onClick={handleVerifyOTP} >Verify</ButtonComponent>
                                                </Box>

                                            </Box>

                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }

{otpPopUpAssossiate &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '568px', sm: '450px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseOtpPopUpAssossiate} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '568px', sm: '450px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '20px',
                                    alignItems: 'center', width: '100%'
                                }}>
                                    <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                        OTP Verification </TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'center', width: '100%', paddingTop: typesError ? '0px' : '15px'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                        We have sent you a one-time password to the mobile number
                                        <span style={{ color: '#626262' }}> XXXXXX{serviceProviderDtls.phone_number.slice(-4)}</span>  </TypographyComponent>
                                        <TypographyComponent>
                                            Enter your OTP here
                                        </TypographyComponent>
                                        <Box display="flex" justifyContent="center" mt={2}>

                                            {otpAssossiate.map((digit, index) => (
                                                <TextField
                                                    key={index}
                                                    id={`otpAssossiate${index}`}
                                                    value={digit}
                                                    onKeyDown={(e) => handleKeyDownAssossiate(index, e)}
                                                    onPaste={handlePasteAssossiate}
                                                    error={(verificationAttempted && !digit) || !!otpError}
                                                    onInput={(e) => {
                                                        const input = e.target.value;
                                                        const digitValue = input.replace(/\D/, '').slice(0, 1); // Ensure it's a single digit number
                                                        handleInputChangeAssossiate(index, digitValue);
                                                        if (digitValue && index < otpAssossiate.length - 1) {
                                                            const nextTextField = document.getElementById(`otpAssossiate${index + 1}`);
                                                            if (nextTextField) {
                                                                nextTextField.focus();
                                                            }
                                                        }
                                                    }}
                                                    inputRef={index === 0 ? firstOtpInputRef : null}                                                    
                                                    style={{
                                                        width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        {otpError && (
                                    <Box display="flex" justifyContent="center" mt={1}>
                                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                        </TypographyComponent>
                                    </Box>                                    
                                )}
                                {otpAssossiateError && (
                                    <Box display="flex" justifyContent="center" mt={1}>
                                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpAssossiateError}
                                        </TypographyComponent>
                                    </Box>
                                )}
                                        <TypographyComponent>Don't receive the OTP?</TypographyComponent>
                                        {/* <Link href="/forgot-password" style={{ textDecoration: 'none', color: Colors.link }}>
                                        Resend OTP</Link> */}
                                        <Button
                                            onClick={handleSendOtpClickAssossiate}
                                            sx={{
                                                textDecoration: 'none',
                                                color: Colors.link,
                                                textTransform: 'none',
                                                padding: 0,
                                                minWidth: 'unset',
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // This removes the hover effect
                                                },
                                            }}
                                        >
                                            Resend OTP
                                        </Button>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', marginTop: '16px', justifyContent: 'center',
                                        alignContent: 'center', alignItems: 'center', width: '100%'
                                    }}>
                                        <Box sx={{ width: '100%' }}>
                                            <ButtonComponent onClick={handleVerifyOTPAssossiate} >Verify</ButtonComponent>
                                        </Box>

                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

{passwordPopup &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '568px', sm: '450px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleClosePassWordPopup} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '568px', sm: '450px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',gap:'16px'}}>
                                <TypographyComponent sx={{fontSize:'18px', fontWeight:600, color: Colors.black_80, textAlign:'center'}}>Set your password</TypographyComponent>
                                <TypographyComponent sx={{fontSize:'16px', fontWeight:400, color: Colors.grey_60, textAlign:'center'}}>Create strong and secure password that protect your account.</TypographyComponent>
                           
                           
                                <Box sx={{ gap: '8px' }}>
                <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Password*</TypographyComponent>
                <TextFieldComponent placeholder="Enter your password"
                    onChange={handleNewPasswordChange} 
                    error={!!passwordError || newPasswordError}
                    id='new_pass'
                    type={showNewPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleNewPasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    color="primary">
                                    {showNewPassword ? (
                                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextFieldComponent>
                {newPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{newPasswordError}</TypographyComponent>}
            </Box>
            <Box sx={{ gap: '8px' }}>
                <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Confirm Password*</TypographyComponent>
                <TextFieldComponent placeholder="Enter your password"
                    onChange={handleConfirmPasswordChange} error={!!passwordError || confirmPasswordError}
                    id='confirm_pass'
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleConfirmPasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    color="primary">
                                    {showConfirmPassword ? (
                                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextFieldComponent>
                {confirmPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{confirmPasswordError}</TypographyComponent>}
            </Box>
            <Box sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}> 
            {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>
                    {passwordError}</TypographyComponent>}
                    </Box>
            <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
                <ButtonComponent onClick={handleSubmit}>Confirm</ButtonComponent>
              
            </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }


{disablePopUpAssossiate && (
                <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                }}
            >
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '420px', sm: '420px', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCancelDisablePopup} sx={{
                            width: '36px', height: '36px',

                        }} >
                            <CancelIcon sx={{
                                width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                    color: Colors.close,
                                },
                            }} ></CancelIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{

                            width: { md: '420px', sm: '420px', xs: '85%' },
                            height: 'fit-content',
                            backgroundColor: '#fff',
                            border: '1px solid #E6E6E6',
                            borderRadius: '28px ',
                            boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                            boxSizing: 'border-box',
                            zIndex: 3,
                            padding: '35px',
                            alignItems: 'center',
                            overflow: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '12px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#7f7f7f',
                                borderRadius: '28px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#e0e0e0',
                                borderRadius: '28px',
                            },
                        }}
                    >



                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '22px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', gap: '8px',
                                        alignItems: 'center', width: '100%'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: '18px', color: '#000000', fontWeight: 600,  }}>
                                            Reject
                                        </TypographyComponent>
                                        
                                        <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70, textAlign:'center' }}>
                                        If you reject, you won't be able to use your profile. Are you sure you want to proceed?"
                                        </TypographyComponent>

                                    </Box>

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px',
                                    marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                }}>
                                    <Box sx={{
                                        width: '20%', display: 'flex', flexDirection: 'row', gap: '16px',
                                    }}>  </Box>
                                    <Box sx={{
                                        width: '80%', display: 'flex', flexDirection: 'row', gap: '16px',
                                        justifyContent: 'right',
                                    }}>
                                        <ButtonComponentWhite onClick={handleCancelDisablePopup}>No</ButtonComponentWhite>
                                        <ButtonComponent onClick={handleYesDisablePopup}>Yes</ButtonComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}

{isTermAssossiatePopUp && (
                <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                }}
            >
                <Box sx={{width:'100%', height:'100%',gap:'5px',
                 justifyContent:'center',alignItems:'center', position: 'fixed',
                   display:'flex',flexDirection:'column', }}>
                 <Box sx={{width:{ md: '450px', sm: '400px', xs: '85%' }, height:'36px',
                 justifyContent:'right',alignItems:'right', display:'flex',}}>
                    <IconButton onClick={handleCloseAssossiateTerm} sx={{
                         width: '36px', height: '36px',
                       
                    }} >
                        <CancelIcon sx={{
                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                color: Colors.close,
                            },
                        }} ></CancelIcon>
                    </IconButton>
                  </Box>
               
                <Box
                    sx={{
                       
                        width: { md: '450px', sm: '400px', xs: '85%' },
                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                        display:'flex',
                        height:'fit-content',
                        backgroundColor: '#fff',
                        border: '1px solid #E6E6E6',
                        borderRadius: '28px ',
                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                        boxSizing: 'border-box',
                        zIndex: 3,
                        padding: '35px',
                        alignItems: 'center',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: '12px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#7f7f7f',
                            borderRadius: '28px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#e0e0e0',
                            borderRadius: '28px',
                        },
                    }}
                >

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column',
                                justifyContent: 'flex-start', gap: '22px',
                                alignItems: 'flex-start', width: '100%'
                            }}>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'flex-start', gap: '8px',
                                    alignItems: 'flex-start', width: '100%',
                                }}>
                                    <TypographyComponent sx={{fontWeight:600, fontFamily:'24px'}}>
                                        Terms And Condition
                                    </TypographyComponent>
                                   

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'flex-start', gap: '8px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>
                                    <TypographyComponent>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    
                                    </TypographyComponent>
                                   

                                </Box>

                            </Box>
                           
                        </Box>
                    </Box>
                    </Box>
                </Box>
            )}







                </Box>)}

        </>
    )
} export default ProfileComponent;