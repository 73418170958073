// import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ children }) => {
//   const token = localStorage.getItem('token');
//   return token ? children : <Navigate to="/" />;
// };

// export default PrivateRoute;

import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const AssossiateRoute = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('associatetocken'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setToken(localStorage.getItem('associatetocken'));
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return token ? children : <Navigate to="/associate_login" />;
};

export default AssossiateRoute;
