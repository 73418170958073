import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Slider from '@mui/material/Slider';
import TypographyComponent from "../Components/TypographyComponent";
import axios from "axios";
import { API_URL } from './Constants';
import Progressbar from "../Components/Progressbar";
import Divider from '@mui/material/Divider';
import GoogleMap from "../Components/GooglemapComponent";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import SpHeader from "../Components/SpHeader";
import Colors from "../Components/Colors";
import SpHeaderMobile from "../Components/SpHeaderMobile";

function AddLocation() {
    const [choosedLocation, setChoosedLocation] = useState(null);
    const [selectedLatitude, setSelectedLatitude] = useState(null);
    const [selectedLongitude, setSelectedLongitude] = useState(null);
    const [workingRadius, setWorkingRadius] = useState(15);
    const [messageDetails, setMessageDetails] = useState([]);
    const [locationError, setLocationError] = useState("");
    const [workingRadiusError, setWorkingRadiusError] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [serviceProviderDtls, setServiceProviderDtls] = useState(null);
    

    const handleDistanceChange = async (event) => {
        setWorkingRadius(event.target.value);
        setWorkingRadiusError("")
    };

    const [isEdit, setIsEdit] = useState(false);

    const token = localStorage.getItem('token');

    async function fetchServiceProviderDetails() {
       
        try {
            const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            console.log("service-provider-details", response.data)
            if (response.data.success) {
              //  console.log("service-provider-details", response.data.data.serviceProvider)
              console.log("qr", response.data.data.serviceProvider)
                setServiceProviderDtls(response.data.data.serviceProvider)
                setMessageDetails(response.data.data.serviceProvider.message_details)
                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

                if(response.data.data.serviceProvider.city){                    
                    setWorkingRadius(response.data.data.serviceProvider.working_radius)
                    setChoosedLocation(response.data.data.serviceProvider.city)
                    setSelectedLatitude(response.data.data.serviceProvider.latitude)
                    setSelectedLongitude(response.data.data.serviceProvider.longitude)

                }                
                    
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);
    
    const handleBackClick =  () => {
        window.location.href = `/profile-form/false`;
    }
   
    const handleNextClick = async () => {       
       
       if (choosedLocation === null) {
            setLocationError("Please choose your location"); 
                       
        }
        else{
           try {      
            const response = await axios.post(`${API_URL}/add-location`, { 
                workingRadius: workingRadius,
                city: choosedLocation,
                latitude: selectedLatitude,
                longitude: selectedLongitude,
                token: token,  });
             //   console.log ("response",response); 
                if(response.data.success === true)  {
                    window.location.href = '/add-service'                   
                    const Selectedprovince = localStorage.getItem("provinceAddLocation")
                    localStorage.setItem("province",Selectedprovince)
                       
                    }           
        
                } catch (error) {
                console.error('Error:', error.message);
                }       
            }
    }

   


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px',   }}>
            
        <Box sx={{  width: '100%', height: '100%',display:'flex' }}>
      <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete = {50}
       unreadMessageCount={unreadMessageCount} ></SpHeader>
        </Box>
        {/* <Box sx={{  width: '100%', height: '100%',display:{ md: 'none', sm: 'none' , xs: 'flex'  }}}>
        <SpHeaderMobile serviceProviderDtls={serviceProviderDtls} percentageComplete = {65} 
         messageDetails={messageDetails} setMessageDetails={setMessageDetails} unreadMessageCount={unreadMessageCount}></SpHeaderMobile>
        </Box> */}
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>       
      
        <Box sx={{width: { md:  'calc(100% - 170px)', sm:  'calc(100% - 48px)', xs:  'calc(100% - 40px)' }, height: '100%',
         display: 'flex', flexDirection: 'column', gap:'28px',
        padding:{ md: '85px', sm: '24px', xs: '20px' }}}>
        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection:{ md: 'row', sm: 'row', xs: 'column', } , gap:'16px'}}>
            <Box sx={{width:{ md:'15%', sm: '15%', xs: '100%' } , height: '100%', display:  'flex', flexDirection: { md: 'column', sm: 'column', xs: 'row', },}}>
               <Progressbar currentStage={2} serviceProviderDtls={serviceProviderDtls} />
               
            </Box>
            <Box sx={{ display: { md: 'flex', sm: 'flex', xs: 'none' }}}>          
            <Divider orientation="vertical" flexItem /> </Box>           
            <Box sx={{width:{ md: '85%' , sm: '90%' , xs: 'calc(100%-32px)'  } , height: '100%', display: 'flex', flexDirection: 'column',
            borderRadius: '16px', background: Colors.grey_20, padding:{ md: '28px 28px 0 28px', sm: '12px 12px', xs: '16px' } ,}}>
            <Box sx={{width: '100%', height: '32px', display: 'flex', flexDirection: 'row',
                     justifyContent: 'space-between',}}>                
                <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', height: '100%',
                 gap: { md: '24px', sm: '16px', xs: '16px' },alignItems:'center'}}>
                    <TypographyComponent sx={{ fontSize: { md: '16px' , sm: '14px' , xs: '12px'  }, color: Colors.black_80 }}>
                        Working Radius
                    </TypographyComponent>
                    <Slider value={workingRadius}
                        onChange={(event) => handleDistanceChange(event)}
                        min={0}  max={200}
                        valueLabelDisplay="auto"  aria-labelledby="distance-slider"
                        sx={{ color: Colors.Hovering, width: { md: '70%' , sm: '60%' , xs: '50%'  } }}/>
                    <TypographyComponent sx={{ fontSize:{ md: '16px' , sm: '14px' , xs: '12px'  } , color: Colors.black_80 }}>
                        {workingRadius} KM
                    </TypographyComponent>
                </Box>
            </Box>
            {workingRadiusError && <TypographyComponent sx={{ fontSize: '14px', color: '#D74039' }}>{workingRadiusError}</TypographyComponent>}
         <Box>
         <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                     justifyContent: 'left',alignItems:'center',gap:'2%'}}>  
         <TypographyComponent sx={{ fontSize: { md: '16px' , sm:  '14px' , xs:'12px'  }, 
         color: Colors.black_80,paddingTop:'16px', display: 'inline' }}>
                            Set your work territory: <TypographyComponent sx={{ fontSize: { md: '16px' , sm:  '14px' , xs:'12px'  }, 
         color:choosedLocation==='Location not found'? Colors.error : Colors.black_80,paddingTop:'16px',display: 'inline' }}><span style={{ fontWeight: 500 }}>{choosedLocation}</span></TypographyComponent>
           </TypographyComponent>
           {(provinceError && choosedLocation !== 'Location not found') && <TypographyComponent sx={{fontSize: { md: '16px' , sm:  '14px' , xs:'12px'  }, 
           color: Colors.error ,paddingTop:'16px',}}>{provinceError}</TypographyComponent>}
           </Box>
         </Box>
           { serviceProviderDtls &&  <GoogleMap key={'googlemap123'} serviceProviderDtls={serviceProviderDtls} 
           editworkingradius = {true} setChoosedLocation = {setChoosedLocation}  setSelectedLatitude = {setSelectedLatitude}
           setSelectedLongitude = {setSelectedLongitude} setProvinceError = {setProvinceError} provinceError={provinceError} > </GoogleMap> }
            {locationError && <TypographyComponent sx={{ fontSize: '14px', color: '#D74039' }}>{locationError}</TypographyComponent>}                      
        </Box>              
        </Box>
       
        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap:'16px'}}>
        <Box sx={{width:{ md: '70%' , sm: '60%' , xs: '50%'  } , height: '100%', display: 'flex', flexDirection: 'row', gap:'16px'}}>
        </Box>
        { isEdit && <Box sx={{width: '30%', height: '100%', display: 'flex', flexDirection: 'row', gap:'16px'}}>
            <ButtonComponentWhite onClick={handleBackClick} >Back</ButtonComponentWhite>
            <ButtonComponent>Done</ButtonComponent>
        </Box>}
        { !isEdit &&  <Box sx={{width: '30%', height: '100%', display: 'flex', flexDirection: 'row', gap:'16px'}}>
            <ButtonComponentWhite sx={{fontSize: { md: '16px' , sm:  '14px' , xs:'12px'  }}} onClick={handleBackClick} >Back</ButtonComponentWhite>
            <ButtonComponent sx={{fontSize: { md: '16px' , sm:  '14px' , xs:'12px'  }}}  disabled={!!provinceError || choosedLocation==='Location not found'} onClick={handleNextClick}>Next</ButtonComponent>
        </Box>}
        </Box>
        </Box>
        </Box>
        </Grid>
    );
}

export default AddLocation;
