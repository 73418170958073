import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItemText, Box,
     ListItemButton, IconButton, Menu, MenuItem } from '@mui/material';
import adminlogo from '../Images/admin_logo.png';
import AdminCat from '../Components/AdminCat';
import AdminSer from '../Components/AdminSer';
import AdminType from '../Components/AdminType';
import AdminSp from '../Components/AdminSp';
import AdminSearchTags from '../Components/AdminSearchTags';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const options = ["Category", "Services", "Type of services", "Service providers", "Search tags"];

function AdminPanel() {
    // const token=localStorage.getItem('adminToken')
    const admin = localStorage.getItem('admin')
    const [selectedOption, setSelectedOption] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);


    const handleMenuToggle = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
            setExpanded(false);
        } else {
            setAnchorEl(event.currentTarget);
            setExpanded(true);
        }
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setExpanded(false); // Ensure expanded state is set to false when menu closes
    };
    const handleSignOut = () => {
        // Implement your sign-out logic here

        handleMenuClose();
        localStorage.removeItem('adminToken');
        localStorage.removeItem('admin');
        localStorage.removeItem('country_code');
        localStorage.removeItem('phone');
        window.location.href = '/';
    };

    const pagesComponents = [
        <AdminCat updateTrigger={updateTrigger}/>,
        <AdminSer updateTrigger={updateTrigger}/>,
        <AdminType updateTrigger={updateTrigger}/>,
        <AdminSp updateTrigger={updateTrigger}/>,
        <AdminSearchTags updateTrigger={updateTrigger}/>
    ];

    const handleListItemClick = (index) => {
        setSelectedOption(index);
        setUpdateTrigger(!updateTrigger); // Toggle the update trigger
    };

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar sx={{ minHeight: { xs: 86, md: 86 }, backgroundColor: '#5b5555', justifyContent: 'space-between' }}>
                    <Box sx={{ width: { xs: '92px', md: '220px' }, height: { xs: '36px', md: '38px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={adminlogo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ color: '#FFFFFF', }}>{admin}</Typography>
                        <IconButton
                            size="large"
                            aria-label="admin menu"
                            aria-controls="admin-menu"
                            aria-haspopup="true"
                            onClick={handleMenuToggle}
                            color="inherit"
                        >
                            {expanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <Menu
                            id="admin-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: '20%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '20%', boxSizing: 'border-box', backgroundColor: '#505051' },
                }}
            >
                <Toolbar sx={{ minHeight: { xs: 86, md: 86 } }} />
                <Box sx={{ overflow: 'auto' }}>
                    <List >
                        {options.map((text, index) => (
                            <ListItemButton key={text}
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 400,
                                    color: '#FFFFFF',
                                    backgroundColor: selectedOption === index ? '#0173AA' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#0173AA', // Change background color on hover
                                    },
                                }}
                                onClick={() => handleListItemClick(index)}>
                                <ListItemText primary={text}
                                    primaryTypographyProps={{
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        color: '#FFFFFF'
                                    }} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: 'calc(80% - 48px)',
                    bgcolor: 'background.default',
                    p: 3,
                }}
            >
                <Toolbar />
                {pagesComponents[selectedOption]}
            </Box>
        </Box>
    );
}

export default AdminPanel;
