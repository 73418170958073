import React from "react"
import Typography from '@mui/material/Typography';
import Colors from "./Colors";


 function TypographyNormal({ onClick, ...props }){
    
    return(
        <Typography  
            sx={{fontFamily:'Roboto',  fontWeight:400, fontSize:'14px', color: Colors.black_70 }}
                 {...props} onClick={onClick}>            
         </Typography>
    )
}

export default TypographyNormal;

