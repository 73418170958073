import { Box, FormControl, FormHelperText, IconButton, InputAdornment, Menu, MenuItem, Select, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TypographyComponent from './TypographyComponent';
import TextFieldComponent from './TextFieldComponent';
import Colors from './Colors';
import { Link } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from 'axios';
import { API_URL } from './Constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px'
}));
const FormChildBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

}));

const FormChildDoubleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  width: '100%'
}));
const FormGrandChildBox = styled(Box)(({ theme }) => ({
  flex: 1
}));



function EditForm(props) {
  const { businessName,contactPerson,setContactPerson,emailError, signInEmail,setSignInEmail,
    passwordError, signInPassword, showPassword, setShowPassword,
    countryCode, countryCodeError, setCountryCode, setCountryCodeError,
    phoneNumber, phoneNumberError, setPhoneNumber, setPhoneNumberError,
    postalCode, postalCodeError, province, provinceError, setProvince, setProvinceError,
    setPostalCode, setPostalCodeError, country, setCountry, countryError, setCountryError, 
    isEditEnabled } = props
//console.log(country,province)
  const [provinces, setProvinces] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    //  console.log("isEditEnabled",isEditEnabled);
    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`${API_URL}/get-provinces`);
        setProvinces(response.data); // Assume the API returns an array of province names
        // if (!isEditEnabled) {
        //   setProvince('Ontario');
        //   setProvinceError("");
        // }
        // setCountry('Canada');
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };

    fetchProvinces(); // Call the function
  }, []); // Empty dependency array ensures this runs only once after initial render


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const handleContactPersonChange = (event) => {
    let value = event.target.value;
    // Ensure the first character is always lowercase
    // if (value.length > 0) {
    //   value = value.charAt(0).toLowerCase() + value.slice(1);
    // }
    setContactPerson(value);
    // setCountryCodeError("");
  };
  const handleEmailChange = (event) => {
    let value = event.target.value;
    // Ensure the first character is always lowercase
    if (value.length > 0) {
      value = value.charAt(0).toLowerCase() + value.slice(1);
    }
    setSignInEmail(value);
    // setCountryCodeError("");
  };
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
    setCountryCodeError("");
  };

  const handleKeyDownCountryCode = (event) => {
    const key = event.key;
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
    }
  };

  const handleKeyDownPhone= (event) => {
    const key = event.key;
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
    }
  };

  
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setPhoneNumberError("");
  };

  const handleKeyDownPostalCode = (event) => {
    const key = event.key;
    if (!/^[a-zA-Z0-9]$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Home' && key !== 'End') {
      event.preventDefault();
    }
  };

  const handleProvinceChange = (event) => {
    // console.log("setProvince................", event.target.value);
    // setProvince(event.target.value);
    // setProvinceError("");
    setAnchorEl(event.currentTarget);
    setProvince(event.target.value);
    if (!event.target.value) {
      setProvinceError('Province is required');
    } else {
      setProvinceError('');
    }

  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProvinceSelect = (selectedProvince) => {
    setProvince(selectedProvince);
    handleMenuClose();
    setProvinceError("");
  };

  const handlePostalCodeChange = (event) => {
   // console.log("setPostalCode", event.target.value);
    setPostalCode(event.target.value);
    setPostalCodeError("");
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryError("");
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box p={{xs:'5%',sm:'1%',md:'1%'}}>
      <FormBox sx={{gap:{xs:'24px',sm:'32px',md:'32px'}}}>
        <FormChildBox>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>
            Business name*</TypographyComponent>
          <TextFieldComponent placeholder="George" value={businessName}>
          </TextFieldComponent>
        </FormChildBox>
        <FormChildBox>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>
          Contact person</TypographyComponent>
          <TextFieldComponent placeholder="Add contact person" 
           onChange={handleContactPersonChange}
           value={contactPerson}>
          </TextFieldComponent>
        </FormChildBox>
        <FormChildBox>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}
          >
            Email</TypographyComponent>
          <TextFieldComponent placeholder="Enter your email id"
            // error={!!emailError}
            value={signInEmail}
          onChange={handleEmailChange}
          >
          </TextFieldComponent>
          {emailError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{emailError}</TypographyComponent>}
        </FormChildBox>
        <FormChildBox>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}
          >
            Password*</TypographyComponent>
          <TextFieldComponent placeholder="Enter your password"
            // onChange={handlePasswordChange}
            error={!!passwordError}
            value={signInPassword}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={togglePasswordVisibility}
                    edge="end"
                    aria-label="toggle password visibility"
                    color="primary"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon sx={{ color: Colors.black_80 }} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={{ color: Colors.black_80 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextFieldComponent>
          {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
            <Link to="/change-password" color={Colors.link}
              underline="none" component="button" variant="body2"
              style={{ color: Colors.link, textDecoration: 'none', fontSize: '12px', fontWeight: 500 }}>
              Change Password
            </Link>
          </Box>
        </FormChildBox>
        <FormChildDoubleBox>
          <FormGrandChildBox>
            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>
              Country code*</TypographyComponent>
            <TextFieldComponent placeholder="+1"
              value={countryCode}
              onChange={handleCountryCodeChange}
              onKeyDown={handleKeyDownCountryCode}
              inputProps={{ maxLength: 3 }}
              error={!!countryCodeError}>
            </TextFieldComponent>
            {countryCodeError &&
              <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                {countryCodeError}</TypographyComponent>}
          </FormGrandChildBox>
          <FormGrandChildBox>
            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>
              Phone number*</TypographyComponent>
            <TextFieldComponent placeholder="Add phone number"
              // type='number'
              value={phoneNumber}
              onKeyDown={handleKeyDownPhone}
              inputProps={{ maxLength: 10 }}
              // inputProps={{
              //   min: 0,
              //   max: 9999999999, // Adjust the max value as needed
              //   inputMode: 'numeric',
              // }}
              // onChange={handlePhoneNumberChange} error={!!phoneNumberError}
              >
            </TextFieldComponent>
            {phoneNumberError &&
              <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                {phoneNumberError}</TypographyComponent>}
          </FormGrandChildBox>
        </FormChildDoubleBox>
        <FormChildDoubleBox>
          <FormGrandChildBox>
            <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555' }}>
              Postal code*</TypographyComponent>
            <TextFieldComponent placeholder=" M5S 2C6"
              value={postalCode}
              onKeyDown={handleKeyDownPostalCode}
              inputProps={{ maxLength: 6 }}
              error={!!postalCodeError} onChange={handlePostalCodeChange}>
            </TextFieldComponent>
            {postalCodeError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>
              {postalCodeError}</TypographyComponent>}
          </FormGrandChildBox>
          <FormGrandChildBox>
            {/* <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555' }}>
              Province*</TypographyComponent>
            <TextFieldComponent placeholder="Ontario"
              value={province}
              onChange={handleProvinceChange}
              error={!!provinceError}>
            </TextFieldComponent> */}
            {/* <Select
              labelId="province-label"
              value={province}
              onChange={handleProvinceChange}
              label="Province*"
              sx={{width:'100%'}}
            >
              {provinces.map((prov) => (
                <MenuItem key={prov.province_id} value={prov.province}>
                  {prov.province}
                </MenuItem>
              ))}
            </Select> */}
            {/* {provinceError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>
              {provinceError}</TypographyComponent>} */}
            <FormControl fullWidth error={!!provinceError}>
              <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555' }}>
                Province*
              </TypographyComponent>
              <TextFieldComponent
                placeholder="Ontario"
                value={province}
                onChange={handleProvinceChange}
                error={!!provinceError}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleMenuOpen}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  ),
                }}
                onClick={handleMenuOpen} // Open menu on text field click
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {provinces.map((prov) => (
                  <MenuItem key={prov.province_id} onClick={() => handleProvinceSelect(prov.province)}>
                    {prov.province}
                  </MenuItem>
                ))}
              </Menu>
              {provinceError && <FormHelperText>{provinceError}</FormHelperText>}
            </FormControl>
          </FormGrandChildBox>
        </FormChildDoubleBox>
        <FormChildBox>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>
            Country*</TypographyComponent>
          <TextFieldComponent placeholder="Canada"
            value={country}
            onChange={handleCountryChange}
            error={!!countryError}>
          </TextFieldComponent>
          {countryError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
            {countryError}</TypographyComponent>}
        </FormChildBox>

      </FormBox>
    </Box>
  )
}

export default EditForm