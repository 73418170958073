import { Grid, Box, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";
import ContactBackground from '../Images/ContactBackground.jpg';
import Contact from '../Images/contactcall.png';
import Colors from "../Components/Colors";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import { API_URL } from './Constants';
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

function CommunityUser() {

 
  return (
    <Grid container sx={{ width: '100%', height: '100%'}}>
          <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <UserHeader currentPage={"Community pages"}></UserHeader>
        </Box>
        <Box sx={{ width: '100%', height: '70vh', display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection:'column', maxWidth:'94%', justifyContent:'center',
         alignItems:'center', border:'1px  solid red'}}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection:'row',justifyContent:'center',
        alignItems:'center'}}>

        </Box> 

        </Box> 

        </Box> 
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <UserFooter></UserFooter>
        </Box> 

    </Grid>
  );
}

export default CommunityUser;
