import { Grid, Box, Typography, Select, MenuItem, TextField, InputAdornment, Button, Tabs, Tab, } from "@mui/material"
import SpHeader from "../Components/SpHeader";
import axios from "axios";
import { API_URL } from './Constants';
import SelectComponent from "../Components/SelectComponent";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import React from "react";
import IconButton from '@mui/material/IconButton';
import { useState, useEffect, useRef } from "react";
import StepperPhase2 from "../Components/StepperPhase2";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Divider from '@mui/material/Divider';
import Progressbar from "../Components/Progressbar";
import BusinessCardBack from "../Components/BusinescrdBack";
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';


function AddService() {

    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [mustSavePopup, setMustSavePopup] = useState(false);
    const [removeServicePopup, setRemoveServicePopup] = useState(false);
    const [removeServiceId, setRemoveServiceId] = useState('');
    const [removeServiceName, setRemoveServiceName] = useState('');
    const [removeIndex, setRemoveIndex] = useState('');
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [serviceDetailsDataToShow, setServiceDetailsDataToShow] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [allService, setAllService] = useState([]);
    const [isTypeShow, setIsTypeShow] = useState(false);
    const [typeNames, setTypeNames] = useState([]);
    const [typeIds, setTypeIds] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const [typesError, setTypesError] = useState("");

    const [multipleServiceName, setMultipleServiceName] = useState([]);
    const [multipleServiceId, setMultipleServiceId] = useState([]);
    const [multipleProfessionalName, setMultipleProfessionalName] = useState([]);
    const [multipleTypesName, setMultipleTypesName] = useState([]);
    const [multipleTypesId, setMultipleTypesId] = useState([]);
    const [multipleCertification, setMultipleCertification] = useState([]);
    const [multipleLisence, setMultipleLisence] = useState([]);
    const [multipleFranchise, setMultipleFranchise] = useState([]);
    const [multipleOfficeAddress, setMultipleOfficeAddress] = useState([]);

    const token = localStorage.getItem('token');
    const addservice = localStorage.getItem('addservice')
    const editservice = localStorage.getItem('editservice')

    console.log(addservice)
    console.log(editservice)


    async function fetchServiceProviderDetails() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            // console.log("service-provider-details", response.data)
            // console.log("service-provider-details", response.data.data.serviceProvider.service_details.length)
            if (response.data.data.serviceProvider.service_details.length === 0) {
                setIsCompleteProfile(false)
            }
            if (response.data.success) {
                setServiceProviderDtls(response.data.data.serviceProvider)
                console.log(response.data.data.serviceProvider.service_details)
                setServiceDetails(response.data.data.serviceProvider.service_details);
                const services = response.data.data.serviceProvider.service_details;
                const extractedServices = services.map(service => ({
                    service: service.services,
                    service_id: service.services_id
                }));
                setAllService(extractedServices);
                setMessageDetails(response.data.data.serviceProvider.message_details)
                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

            }
            else {
                console.error('Error:', response.data.error);
            }
        }
        catch (error) {
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);


    const [service, setService] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [serviceProfessionName, setServiceProfessionName] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [serviceError, setServiceError] = useState("");


    const fetchServices = async () => {
        try {
            const response = await axios.get(`${API_URL}/select_all_services`);
            setService(response.data);
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    useEffect(() => {
        console.log(allService)
        console.log(service)
        if (addservice === 'true') {
            const filteredServices = service.filter(serviceItem => (
                !allService.some(allServiceItem => (
                    allServiceItem.service_id === serviceItem.service_id
                ))
            ));
            setService(filteredServices);
        }

    }, [service]);

    const fetchTypes = async () => {
        try {
            const response = await axios.post(`${API_URL}/select_all_types`,
                { serviceId: serviceId, });
            setAllTypes(response.data);
            console.log(response.data)
            console.log(serviceId)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        if (serviceId) {
            fetchTypes();
        }
    }, [serviceId]);

    const handleServiceChange = async (value) => {
        const selectedValue = value;
        const selectedOption = service.find(option => option.service_id === selectedValue);
        setServiceName(selectedOption.services);
        setServiceId(selectedOption.service_id);
        setServiceProfessionName(selectedOption.professional_name)

        setServiceError('');
        setTypeNames([])
        setTypeIds([])
        setSelectedFranchise([])
        setSelectedCertifications([])
        setSelectedLicense([])
        setOfficeAddress('')

        setIsEditProfile(true)

    };

    const handleShowTypes = async () => {
        fetchTypes()
        setIsTypeShow(true)
        setTypesError('')
    };

    const searchRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsTypeShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef]);


    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event, typeId) => {
        const typeName = event.target.value;
        setTypeNames((prevSelectedNames) => {
            if (event.target.checked) {
                return [...prevSelectedNames, event.target.value];
            } else {
                return prevSelectedNames.filter((name) => name !== typeName);
            }
        });

        setTypeIds((prevSelectedIds) => {
            if (event.target.checked) {
                return [...prevSelectedIds, typeId];
            } else {
                return prevSelectedIds.filter((id) => id !== typeId);
            }
        });

        setIsEditProfile(true)

    };



    const [certifications, setCertifications] = useState('');
    const [isCertificationsAdd, setIsCertificationsAdd] = useState(false);
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [isCertficationIcon, setIsCertficationIcon] = useState(false);

    const handleInputCertificationsChange = (event) => {
        const certficteValue = event.target.value;
        setCertifications(event.target.value);
        setIsCertficationIcon(true)
        setIsEditProfile(true)
    };

    const handleCertificationOnBlur = (event) => {
        if (selectedCertifications.length <= 5) {
            handleAddCertification()
        }
    }

    const handleAddCertification = () => {
        setIsCertficationIcon(false)

        const trimmedCertifications = certifications.trim();

        if (trimmedCertifications !== '') {
            setSelectedCertifications((prevCertifications) => {
                const updatedCertifications = [...prevCertifications];
                if (editingIndex !== -1) {
                    updatedCertifications[editingIndex] = {
                        certifications: trimmedCertifications,
                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedCertifications.length < 5) {
                        updatedCertifications.push({
                            certifications: trimmedCertifications,
                        });
                    }
                }

                return updatedCertifications;
            });
            setCertifications('');
            setIsCertificationsAdd(true);
        }


    };


    const handleCertificationsShowButtonClick = async (certification, index) => {
        setCertifications(certification)
        setEditingIndex(index)

    }

    const handleCertificationsCloseButtonClick = (index) => {
        setIsEditProfile(true)
        const updatedCertifications = [...selectedCertifications];
        updatedCertifications.splice(index, 1);
        setSelectedCertifications(updatedCertifications);

    };


    const [license, setLicense] = useState('');
    const [isLicenseAdd, setIsLicenseAdd] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState([]);
    const [isLisenceIcon, setIsLisenceIcon] = useState(false);


    const handleInputLicenseChange = (event) => {
        setIsEditProfile(true)
        setIsLisenceIcon(true)
        setLicense(event.target.value);
        if (selectedLicense.length < 5) {
            const licenselist = selectedLicense.map(lic => lic.license).concat(event.target.value); // Add event.target.value to licenselist
            const finalLicenselist = licenselist.join(', ');
        }

    };

    const handleLicenseOnBlur = (event) => {
        if (selectedLicense.length <= 5) {
            handleAddLicense()
        }
    }

    const handleAddLicense = () => {
        setIsLisenceIcon(false)

        const trimmedLicense = license.trim();

        if (trimmedLicense !== '') {
            setSelectedLicense((prevLicense) => {
                const updatedLicense = [...prevLicense];

                if (editingIndex !== -1) {
                    updatedLicense[editingIndex] = {
                        license: trimmedLicense,

                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedLicense.length < 5) {
                        updatedLicense.push({
                            license: trimmedLicense,

                        });
                    }
                }

                return updatedLicense;
            });

            setLicense('');
            setIsLicenseAdd(true);

        }
    };


    const handleLicenseShowButtonClick = async (license, index) => {
        setLicense(license)
        setEditingIndex(index)
    }

    const handleLicenseCloseButtonClick = (index) => {
        const updatedLicense = [...selectedLicense];
        updatedLicense.splice(index, 1);
        setSelectedLicense(updatedLicense);
        setIsEditProfile(true)

    };

    const [franchise, setFranchise] = useState('');
    const [isFranchiseAdd, setIsFranchiseAdd] = useState(false);
    const [selectedFranchise, setSelectedFranchise] = useState([]);
    const [isFranchiseIcon, setIsFranchiseIcon] = useState(false);


    const handleInputFranchiseChange = (event) => {
        setIsEditProfile(true)
        setIsFranchiseIcon(true)
        setFranchise(event.target.value);
        if (selectedFranchise.length < 5) {
            let finalFranchiselist;
            const franchiselist = selectedFranchise.map(fr => fr.franchise).concat(event.target.value); // Add event.target.value to licenselist
            finalFranchiselist = franchiselist.join(', ');

        }
    };

    const handleFranchiseOnBlur = (event) => {
        if (selectedFranchise.length <= 5) {
            handleAddFranchise()
        }
    }

    const handleAddFranchise = () => {
        const trimmedFranchise = franchise.trim();
        setIsFranchiseIcon(false)
        if (trimmedFranchise !== '') {
            setSelectedFranchise((prevFranchise) => {
                const updatedFranchise = [...prevFranchise];

                if (editingIndex !== -1) {
                    updatedFranchise[editingIndex] = {
                        franchise: trimmedFranchise,
                    };
                    setEditingIndex(-1);
                } else {
                    if (selectedFranchise.length < 5) {
                        updatedFranchise.push({
                            franchise: trimmedFranchise,

                        });
                    }
                }

                return updatedFranchise;
            });

            setFranchise('');
            setIsFranchiseAdd(true);

        }
    };


    const handleFranchiseShowButtonClick = async (franchise, index) => {
        setFranchise(franchise)
        setEditingIndex(index)
    }

    const handleFranchiseCloseButtonClick = (index) => {
        const updatedFranchise = [...selectedFranchise];
        updatedFranchise.splice(index, 1);
        setSelectedFranchise(updatedFranchise);
        setIsEditProfile(true)

    };

    const handleEnterKeyPress = (event, field) => {
        if (event.key === 'Enter') {
            setIsFranchiseIcon(false)
            setIsCertficationIcon(false)
            setIsLisenceIcon(false)
            event.preventDefault();
            if (field === 'certifications' && selectedCertifications.length <= 5) {
                handleAddCertification();
            }
            else if (field === 'license' && selectedLicense.length <= 5) {
                handleAddLicense();
            }
            else if (field === 'franchise' && selectedFranchise.length <= 5) {
                handleAddFranchise();
            }
        }
    };

    const [OfficeAddress, setOfficeAddress] = useState('');

    const handleInputOfficeAddressChange = (event) => {
        setOfficeAddress(event.target.value)
        setIsEditProfile(true)

    };

    const [aboutUs, setAboutUs] = useState('');
    const [aboutUsError, setAboutUsError] = useState("");
    const [isAboutUsUpdated, setIsAboutUsUpdated] = useState(false);

    const handleInputAboutUsChange = (event) => {
        if (addservice === 'true') {
            setIsAboutUsUpdated(true)
        }
        setAboutUs(event.target.value)
        setAboutUsError('')

        setIsEditProfile(true)
    };

    const handleSaveButtonClick = async () => {
        if (serviceName.trim() === "") {
            setServiceError("Please choose your service");
        }
        else if (typeNames.length === 0) {
            setTypesError("Please choose your types of service");
        }
        else if (aboutUs.trim() === "") {
            setAboutUsError("Please add more about your self");

        }
        else if (addservice === 'true' && isAboutUsUpdated === false) {
            setAboutUsError("Please update 'About all my services' with the new service.");
        }
        else {
            if (addservice === 'true' && isAboutUsUpdated === true) {
                try {
                    const response = await axios.post(`${API_URL}/add-skills`, {
                        service: serviceName,
                        serviceid: serviceId,
                        profesionalname: serviceProfessionName,
                        type: typeNames,
                        typesid: typeIds,
                        certifications: selectedCertifications,
                        license: selectedLicense,
                        franchise: selectedFranchise,
                        Officeaddress: OfficeAddress,
                        aboutus: aboutUs,
                        token: token,
                    });
                    if (response.data.success === true) {
                        localStorage.removeItem('addservice');
                        window.location.href = '/sp-landing'

                    }
                } catch (error) {
                    console.error('Error:', error.message);
                }
            }

            if (editservice === 'true') {
                try {
                    const response = await axios.post(`${API_URL}/edit-skills`, {
                        service: serviceName,
                        serviceid: serviceId,
                        profesionalname: serviceProfessionName,
                        type: typeNames,
                        typesid: typeIds,
                        certifications: selectedCertifications,
                        license: selectedLicense,
                        franchise: selectedFranchise,
                        Officeaddress: OfficeAddress,
                        aboutus: aboutUs,
                        token: token,
                    });
                    if (response.data.success === true) {
                        localStorage.removeItem('editservice');
                        window.location.href = '/sp-landing'

                    }
                } catch (error) {
                    console.error('Error:', error.message);
                }
            }

        }


    };


    const [selectedTab, setSelectedTab] = useState(0);


    const handleChange = (event, newIndex) => {
        if (isEditProfile === true) {
            setMustSavePopup(true)
            setRemoveIndex(newIndex)
        }
        else {
            setSelectedTab(newIndex);
            setRemoveIndex(newIndex)
            setSelectedFranchise([])
            setSelectedCertifications([])
            setSelectedLicense([])
            const selectedService = multipleServiceName[newIndex];
            const professional = multipleProfessionalName[newIndex]
            console.log(multipleTypesName)
            console.log(professional)
            console.log(selectedService)
            setServiceName(selectedService.service)
            setServiceId(selectedService.Id)
            setServiceProfessionName(professional.service)

            // const filteredTypeNames = multipleTypesName.filter(item => item.Id === selectedService.Id).map(item => item.types);
            // setTypeNames(filteredTypeNames)

            // const foundTypeIds = multipleTypesId.filter(item => item.Id === selectedService.Id).map(item => item.typesId);
            // setTypeIds(foundTypeIds)


            //     const foundCertifications = multipleCertification
            //         .filter(item => item.Id === selectedService.Id)
            //         .flatMap(item => item.certification.split(', ').map(cert => ({ certifications: cert.trim() })))
            //         .filter(item => item.certifications);
            //         console.log(foundCertifications)
            //     setSelectedCertifications(foundCertifications);

            //    setIsCertificationsAdd(true)

            //     const foundLicenses = multipleLisence
            //     .filter(item => item.Id === selectedService.Id)
            //     .flatMap(item => item.license.split(', ').map(license => ({ license: license.trim() })))
            //     .filter(item => item.license);
            // setSelectedLicense(foundLicenses);
            // setIsLicenseAdd(true);


            // const foundFranchises = multipleFranchise
            //     .filter(item => item.Id === selectedService.Id)
            //     .flatMap(item => item.franchise.split(', ').map(franchise => ({ franchise: franchise.trim() })))
            //     .filter(item => item.franchise) || [];
            // setSelectedFranchise(foundFranchises);
            // setIsFranchiseAdd(true);

            const filteredTypeNames = multipleTypesName
                .filter(item => item.Id === selectedService.Id)
                .map(item => item.types);

            const uniqueTypeNames = Array.from(new Set(filteredTypeNames));
            setTypeNames(uniqueTypeNames);

            const foundTypeIds = multipleTypesId
                .filter(item => item.Id === selectedService.Id)
                .map(item => item.typesId);

            const uniqueTypeIds = Array.from(new Set(foundTypeIds));
            setTypeIds(uniqueTypeIds);

            const foundCertifications = multipleCertification
                .filter(item => item.Id === selectedService.Id)
                .flatMap(item => item.certification.split(',').map(cert => cert.trim()))
                .filter(cert => cert); // Remove empty strings

            const uniqueCertifications = Array.from(new Set(foundCertifications)).map(cert => ({ certifications: cert }));
            setSelectedCertifications(uniqueCertifications);
            setIsCertificationsAdd(true);

            const foundLicenses = multipleLisence
                .filter(item => item.Id === selectedService.Id)
                .flatMap(item => item.license.split(',').map(license => license.trim()))
                .filter(license => license); // Remove empty strings

            const uniqueLicenses = Array.from(new Set(foundLicenses)).map(license => ({ license }));
            setSelectedLicense(uniqueLicenses);
            setIsLicenseAdd(true);

            const foundFranchises = multipleFranchise
                .filter(item => item.Id === selectedService.Id)
                .flatMap(item => item.franchise.split(',').map(franchise => franchise.trim()))
                .filter(franchise => franchise); // Remove empty strings

            const uniqueFranchises = Array.from(new Set(foundFranchises)).map(franchise => ({ franchise }));
            setSelectedFranchise(uniqueFranchises);
            setIsFranchiseAdd(true);


            const foundOfficeAddress = multipleOfficeAddress.filter(item => item.Id === selectedService.Id).map(item => item.OfficeAddress) || '';
            setOfficeAddress(foundOfficeAddress[0]);
            console.log(foundOfficeAddress[0])
        }
    };


    const handleRemoveService = (indexToRemove) => {
        const selectedService = multipleServiceName[indexToRemove];
        console.log(selectedService)
        setRemoveServiceId(selectedService.Id)
        setRemoveServiceName(selectedService.service)
        setRemoveIndex(indexToRemove)
        setRemoveServicePopup(true)

    };

    const handleRemovePopUpYesButtonClick = async () => {

        try {
            const response = await axios.post(`${API_URL}/remove-skills`, {
                serviceid: removeServiceId,
                token: token,
            });
            if (response.data.success === true) {
                setRemoveServicePopup(false)
                setMultipleServiceName((prevNames) => prevNames.filter((_, index) => index !== removeIndex));
                setSelectedTab((prevSelectedTab) => (prevSelectedTab >= removeIndex && prevSelectedTab > 0) ? prevSelectedTab - 1 : prevSelectedTab);
                window.location.href = '/sp-landing'
            }
        } catch (error) {
            console.error('Error:', error.message);
        }


    };


    const handleRemoveServicePopUpCancelButtonClick = () => {
        setRemoveServicePopup(false)
    }

    useEffect(() => {

        const certificationslist = selectedCertifications.map(cert => cert.certifications).join(', ');
        const licenselist = selectedLicense.map(lic => lic.license).join(', ');
        const franchiselist = selectedFranchise.map(fr => fr.franchise).join(', ');
        const typeslist = typeNames.join(', ');
        const typesidlist = typeIds.join(', ');
        const typeDetails = typeNames.map((type, index) => ({
            types: type,
            types_id: typeIds[index]
        }));


        setServiceDetailsDataToShow({
            services: serviceName,
            servicesId: serviceId,
            types: typeslist,
            typesId: typesidlist,
            type_details: typeDetails,
            certifications: certificationslist,
            franchise: franchiselist,
            license: licenselist,
            office_address: OfficeAddress,
            about_us: aboutUs,
        });


    }, [serviceName, serviceId, typeNames, typeIds, selectedCertifications, selectedLicense, selectedFranchise, OfficeAddress, aboutUs]);




    useEffect(() => {
        if (editservice === 'true') {

            setMultipleServiceName((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    service: detail.services,
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });
            setMultipleServiceId((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });

            setMultipleProfessionalName((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    service: detail.professional_name,
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });

            setMultipleTypesName((prevServiceNames) => {
                const newServiceNames = serviceDetails.flatMap(detail =>
                    detail.type_details.map(type => ({
                        types: type.types,
                        Id: detail.services_id
                    }))
                );
                return [...prevServiceNames, ...newServiceNames];
            });
            setMultipleTypesId((prevServiceIds) => {
                const newServiceIds = serviceDetails.flatMap(detail =>
                    detail.type_details.map(type => ({
                        typesId: type.types_id,
                        Id: detail.services_id
                    }))
                );
                return [...prevServiceIds, ...newServiceIds];
            });

            if (serviceDetails.some(detail => detail.certifications)) {
                setMultipleCertification((prevServiceNames) => {
                    const newServiceNames = serviceDetails.map(detail => ({
                        certification: detail.certifications,
                        Id: detail.services_id
                    }));
                    return [...prevServiceNames, ...newServiceNames];
                });
            }
            if (serviceDetails.some(detail => detail.license)) {
                setMultipleLisence((prevServiceNames) => {
                    const newServiceNames = serviceDetails.map(detail => ({
                        license: detail.license,
                        Id: detail.services_id
                    }));
                    return [...prevServiceNames, ...newServiceNames];
                });
            }
            if (serviceDetails.some(detail => detail.franchise)) {
                setMultipleFranchise((prevServiceNames) => {
                    const newServiceNames = serviceDetails.map(detail => ({
                        franchise: detail.franchise,
                        Id: detail.services_id
                    }));
                    return [...prevServiceNames, ...newServiceNames];
                });
            }
            setMultipleOfficeAddress((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    OfficeAddress: detail.office_address,
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });
            if (serviceDetails.length > 0) {
                setAboutUs(serviceDetails[0].about_us);
            }
        }
        if (addservice === 'true') {
            setMultipleServiceName((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    service: detail.services,
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });
            setMultipleServiceId((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });
            setMultipleProfessionalName((prevServiceNames) => {
                const newServiceNames = serviceDetails.map(detail => ({
                    service: detail.professional_name,
                    Id: detail.services_id
                }));
                return [...prevServiceNames, ...newServiceNames];
            });
            if (serviceDetails.length > 0) {
                setAboutUs(serviceDetails[0].about_us);
            }
        }
    }, [serviceDetails]);



    useEffect(() => {

        if (addservice === 'true') {
            setServiceName('')
            setServiceProfessionName('')
            setServiceId('')
            setTypeNames([])
            setTypeIds([])
            setSelectedFranchise([])
            setSelectedCertifications([])
            setSelectedLicense([])
            setOfficeAddress('')
        }
        if (editservice === 'true') {
            if (multipleServiceName.length > 0) {
                setServiceName(multipleServiceName[selectedTab].service);
            }
            if (multipleServiceId.length > 0) {
                setServiceId(multipleServiceId[selectedTab].Id);
            }
            if (multipleProfessionalName.length > 0) {
                setServiceProfessionName(multipleProfessionalName[selectedTab].service);
            }
            // if (multipleTypesName.length > 0) {
            //     const selectedId = multipleServiceId[selectedTab].Id;
            //     const filteredTypeNames = multipleTypesName.filter(item => item.Id === selectedId);
            //     const typeNamesArray = filteredTypeNames.map(item => item.types);
            //     setTypeNames(typeNamesArray);
            // }
            if (multipleTypesName.length > 0) {
                const selectedId = multipleServiceId[selectedTab].Id;
                const filteredTypeNames = multipleTypesName.filter(item => item.Id === selectedId);
                const uniqueTypeNames = [...new Set(filteredTypeNames.map(item => item.types))];
                setTypeNames(uniqueTypeNames);
            }

            if (multipleTypesId.length > 0) {
                const selectedId = multipleServiceId[selectedTab].Id;
                const filteredTypeIds = multipleTypesId.filter(item => item.Id === selectedId);
                const typeIdsArray = filteredTypeIds.map(item => item.typesId);
                setTypeIds(typeIdsArray);
            }

            if (multipleCertification.length > 0 && multipleCertification[selectedTab].certification.trim() !== '') {
                const certificationsString = multipleCertification[selectedTab].certification;
                const certificationsList = certificationsString.split(',').map(cert => ({ certifications: cert.trim() }));
                setSelectedCertifications(certificationsList)
                setIsCertificationsAdd(true);
            }
            if (multipleLisence.length > 0 && multipleLisence[selectedTab].license.trim() !== '') {
                const certificationsString = multipleLisence[selectedTab].license;
                const certificationsList = certificationsString.split(',').map(cert => ({ license: cert.trim() }));
                setSelectedLicense(certificationsList)
                setIsLicenseAdd(true);
            }
            if (multipleFranchise.length > 0 && multipleFranchise[selectedTab].franchise.trim() !== '') {
                const certificationsString = multipleFranchise[selectedTab].franchise;
                const certificationsList = certificationsString.split(',').map(cert => ({ franchise: cert.trim() }));
                setSelectedFranchise(certificationsList)
                setIsFranchiseAdd(true);
            }
            if (multipleOfficeAddress.length > 0) {
                setOfficeAddress(multipleOfficeAddress[selectedTab].OfficeAddress);
            }
        }
    }, [multipleServiceName, multipleServiceId, multipleTypesName, multipleTypesId, multipleCertification, multipleLisence, multipleFranchise, multipleOfficeAddress]);


    const handleCancelButtonClick = () => {
        localStorage.removeItem('addservice');
        localStorage.removeItem('editservice');
        window.location.href = '/sp-landing'
    }

    const handleMustSavePopUpCancelButtonClick = () => {
        setMustSavePopup(false)
        setIsEditProfile(false)
        //window.location.reload();       
        console.log(removeIndex)
        setSelectedTab(removeIndex)
        const selectedService = multipleServiceName[removeIndex];
        const selectedServiceprofession = multipleProfessionalName[removeIndex];
        console.log(multipleTypesName)
        console.log(selectedService.Id)
        setServiceName(selectedService.service)
        setServiceId(selectedService.Id)
        setServiceProfessionName(selectedServiceprofession.service)

        // const filteredTypeNames = multipleTypesName.filter(item => item.Id === selectedService.Id).map(item => item.types);
        // setTypeNames(filteredTypeNames)

        // const foundTypeIds = multipleTypesId.filter(item => item.Id === selectedService.Id).map(item => item.typesId);
        // setTypeIds(foundTypeIds)

        // const foundCertifications = multipleCertification
        //     .filter(item => item.Id === selectedService.Id)
        //     .flatMap(item => item.certification.split(', ').map(cert => ({ certifications: cert.trim() })))
        //     .filter(item => item.certifications);
        // setSelectedCertifications(foundCertifications);

        // setIsCertificationsAdd(true)


        // const foundLicenses = multipleLisence
        //     .filter(item => item.Id === selectedService.Id)
        //     .flatMap(item => item.license.split(', ').map(license => ({ license: license.trim() })))
        //     .filter(item => item.license);
        // setSelectedLicense(foundLicenses);
        // setIsLicenseAdd(true);


        // const foundFranchises = multipleFranchise
        //     .filter(item => item.Id === selectedService.Id)
        //     .flatMap(item => item.franchise.split(', ').map(franchise => ({ franchise: franchise.trim() })))
        //     .filter(item => item.franchise) || [];
        // setSelectedFranchise(foundFranchises);
        // setIsFranchiseAdd(true);

        const filteredTypeNames = multipleTypesName
            .filter(item => item.Id === selectedService.Id)
            .map(item => item.types);

        const uniqueTypeNames = Array.from(new Set(filteredTypeNames));
        setTypeNames(uniqueTypeNames);

        const foundTypeIds = multipleTypesId
            .filter(item => item.Id === selectedService.Id)
            .map(item => item.typesId);

        const uniqueTypeIds = Array.from(new Set(foundTypeIds));
        setTypeIds(uniqueTypeIds);

        const foundCertifications = multipleCertification
            .filter(item => item.Id === selectedService.Id)
            .flatMap(item => item.certification.split(',').map(cert => cert.trim()))
            .filter(cert => cert); // Remove empty strings

        const uniqueCertifications = Array.from(new Set(foundCertifications)).map(cert => ({ certifications: cert }));
        setSelectedCertifications(uniqueCertifications);
        setIsCertificationsAdd(true);

        const foundLicenses = multipleLisence
            .filter(item => item.Id === selectedService.Id)
            .flatMap(item => item.license.split(',').map(license => license.trim()))
            .filter(license => license); // Remove empty strings

        const uniqueLicenses = Array.from(new Set(foundLicenses)).map(license => ({ license }));
        setSelectedLicense(uniqueLicenses);
        setIsLicenseAdd(true);

        const foundFranchises = multipleFranchise
            .filter(item => item.Id === selectedService.Id)
            .flatMap(item => item.franchise.split(',').map(franchise => franchise.trim()))
            .filter(franchise => franchise); // Remove empty strings

        const uniqueFranchises = Array.from(new Set(foundFranchises)).map(franchise => ({ franchise }));
        setSelectedFranchise(uniqueFranchises);
        setIsFranchiseAdd(true);


        const foundOfficeAddress = multipleOfficeAddress.filter(item => item.Id === selectedService.Id).map(item => item.OfficeAddress) || '';
        setOfficeAddress(foundOfficeAddress[0]);
        console.log(foundOfficeAddress[0])


    }

    const handleMustSavePopUpSaveButtonClick = async () => {
        if (serviceName.trim() === "") {
            setServiceError("Please choose your service");
        }
        else if (typeNames.length === 0) {
            setTypesError("Please choose your types of service");
        }
        else if (aboutUs.trim() === "") {
            setAboutUsError("Please add more about your self");
        }
        else {
            try {
                const response = await axios.post(`${API_URL}/edit-skills`, {
                    service: serviceName,
                    serviceid: serviceId,
                    profesionalname: serviceProfessionName,
                    type: typeNames,
                    typesid: typeIds,
                    certifications: selectedCertifications,
                    license: selectedLicense,
                    franchise: selectedFranchise,
                    Officeaddress: OfficeAddress,
                    aboutus: aboutUs,
                    token: token,
                });
                if (response.data.success === true) {
                    localStorage.removeItem('editservice');
                    window.location.href = '/sp-landing'

                }
            } catch (error) {
                console.error('Error:', error.message);
            }

        }


    };

    const removeDuplicates = (array) => {
        const uniqueArray = Array.from(new Set(array.map(a => a.service)))
            .map(service => {
                return array.find(a => a.service === service)
            });
        return uniqueArray;
    };

    const uniqueServiceNames = removeDuplicates(multipleServiceName);



    return (

        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={75}
                unreadMessageCount={unreadMessageCount} isCompleteProfile={isCompleteProfile}>
            </SpHeader>
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                width: '100%', height: '100%'
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', paddingTop: '2.5%', paddingBottom: '2.5%', gap: '20px',
                    width: '100%', maxWidth: { md: '96%', sm: '96%', xs: '90%' }, height: '100%', maxHeight: '94%',
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' }, width: '100%', height: '90%', gap: '16px',
                        alignItems: 'stretch'
                    }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center',
                            borderRight: { md: '1px solid #E6E6E6', sm: '1px solid #E6E6E6', xs: '0px solid #E6E6E6' },
                            width: { md: '18%', sm: '12%', xs: '100%' }, flex: 1,
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                width: '100%', maxWidth: { md: '80%', sm: '80%', xs: '100%' }
                            }}>
                                <Progressbar currentStage={3} serviceProviderDtls={serviceProviderDtls} />
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: { md: '42%', sm: '42%', xs: '100%' }, height: '100%', gap: '24px',
                            borderRight: { md: '1px solid #E6E6E6', sm: '1px solid #E6E6E6', xs: '0px solid #E6E6E6' }, paddingLeft: '1.5%'
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '100%', maxWidth: { md: '96%', sm: '96%', xs: '100%' }, gap: '16px',
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                    width: '100%', maxWidth: '95%', height: '100%', maxWidth: { md: '95%', sm: '95%', xs: '100%' }, gap: '16px',
                                }}>
                                    {/* {uniqueServiceNames.length > 0 && (
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                                width: '100%', height: '100%', gap: '8px', overflow: 'auto', whiteSpace: 'nowrap', border:'1px solid red',
                                                '&::-webkit-scrollbar': {
                                                    width: '4px',height:'8px', 
                                                  },
                                                  '&::-webkit-scrollbar-track': {
                                                    background: '#f1f1f1',
                                                  },
                                                  '&::-webkit-scrollbar-thumb': {
                                                    background: '#888', 
                                                    borderRadius: '4px', marginTop:'16px'
                                                  },
                                                  '&::-webkit-scrollbar-thumb:hover': {
                                                    background: '#555'
                                                  },}} >
                                            <Tabs  value={selectedTab}
                                                onChange={handleChange}
                                                aria-label="service tabs"
                                                style={{ width: 'fit-content' }}
                                                TabIndicatorProps={{ style: { backgroundColor: Colors.black_80 },  }}>
                                                {uniqueServiceNames.map((name, index) => (
                                                    <Tab disabled={addservice === 'true'}
                                                        key={index}
                                                        label={
                                                            <Box sx={{ display: 'flex', alignItems: 'center',}} >
                                                                <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px',
                                                                        fontWeight: '400', textTransform: 'none', color: Colors.black_80,}}>                                                                  
                                                                    {name.service}
                                                                </Typography>
                                                                {selectedTab === index && (
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleRemoveService(index);
                                                                        }}
                                                                        sx={{ marginLeft: '8px',}}>
                                                                        <CloseIcon sx={{ fontSize: '16px', color: Colors.black_80,}}/>
                                                                    </IconButton>
                                                                )}
                                                            </Box>
                                                        }

                                                    />
                                                ))}
                                            </Tabs>
                                        </Box>
                                    )} */}
                                    {uniqueServiceNames.length > 0 && (
                                        <Box
                                            sx={{
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                alignItems: 'left', width: '100%', height: '100%',
                                                gap: '8px', overflow: 'auto', whiteSpace: 'nowrap',
                                                padding: '8px',

                                                '&::-webkit-scrollbar': {
                                                    width: '4px',
                                                    height: '8px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    background: '#888',
                                                    borderRadius: '4px',
                                                    marginTop: '16px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                    background: '#555',
                                                },
                                            }}
                                        >
                                            <Tabs
                                                value={selectedTab}
                                                onChange={handleChange}
                                                aria-label="service tabs"
                                                variant="scrollable" // Enable scrolling
                                                scrollButtons="auto" // Show scroll buttons automatically
                                                style={{ width: 'fit-content' }}
                                                TabIndicatorProps={{ style: { backgroundColor: Colors.black_80 } }}
                                            >
                                                {uniqueServiceNames.map((name, index) => (
                                                    <Tab
                                                        disabled={addservice === 'true'}
                                                        key={index}
                                                        label={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Roboto', fontSize: '14px', fontWeight: '400',
                                                                        textTransform: 'none', color: Colors.black_80,
                                                                        overflow: 'hidden', textOverflow: 'ellipsis', // Ensures the text does not get cut off
                                                                        whiteSpace: 'nowrap', maxWidth: '100px', // Adjust this value as needed
                                                                    }}
                                                                >
                                                                    {name.service}
                                                                </Typography>
                                                                {selectedTab === index && (
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleRemoveService(index);
                                                                        }}
                                                                        sx={{ marginLeft: '8px' }}
                                                                    >
                                                                        <CloseIcon sx={{ fontSize: '16px', color: Colors.black_80 }} />
                                                                    </IconButton>
                                                                )}
                                                            </Box>
                                                        }
                                                        sx={{
                                                            minHeight: '48px', paddingLeft: '8px',
                                                            paddingRight: '8px', overflow: 'hidden',
                                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                                        }}
                                                    />
                                                ))}
                                            </Tabs>
                                        </Box>
                                    )}


                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                                    width: '100%', height: '100%',
                                }}>
                                    <TypographyHeading>
                                        Add your service
                                    </TypographyHeading>
                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                                    width: '100%', height: '100%', gap: '24px'
                                }}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <TypographyComponent>
                                            Services*
                                        </TypographyComponent>

                                        <SelectComponent
                                            value={serviceId} disabled={editservice === 'true'}
                                            onChange={(event) => handleServiceChange(event.target.value, event.target.textContent)}
                                            options={service.map(result => ({ value: result.service_id, label: result.services }))}
                                            error={!!serviceError} >
                                        </SelectComponent>
                                        {serviceError &&
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>
                                                {serviceError}</TypographyComponent>}


                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <TypographyComponent>
                                            Type of services*
                                        </TypographyComponent>
                                        <TextFieldComponent disabled={!serviceName}
                                            variant="outlined"
                                            onClick={() => handleShowTypes()}
                                            placeholder="Add your type of services"
                                            value={typeNames}
                                            error={!!typesError}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton disabled={!serviceName}
                                                        onClick={() => handleShowTypes()}>
                                                        {isTypeShow ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                    </IconButton>
                                                ),
                                            }} />

                                        {isTypeShow && (
                                            <Box sx={{
                                                width: { md: '95%', sm: '95%', xs: '90%' }, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                                alignItems: 'center', gap: '16px', position: 'relative',
                                            }}>

                                                <Box ref={searchRef} sx={{
                                                    width: '100%', padding: '16px 16px 16px 16px', position: 'absolute',
                                                    borderRadius: '16px', gap: '16px', maxHeight: '168px', overflow: 'auto', top: '0px',
                                                    height: 'fit-content', boxShadow: '0px 4px 4px 0px #00000025',
                                                    border: '1px solid', zIndex: 5,
                                                    borderColor: Colors.grey_60, background: Colors.white,
                                                    '&::-webkit-scrollbar': {
                                                        width: '11px',
                                                        height: '8px', // Added height for horizontal scrollbars
                                                        borderRadius: '16px',

                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: Colors.grey_60,
                                                        borderRadius: '16px',
                                                        border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        borderRadius: '16px',
                                                        backgroundColor: Colors.white,
                                                        marginTop: '10px',
                                                        marginBottom: '10px'
                                                    },

                                                }}>
                                                    {allTypes.map((result, index) => (

                                                        <Box key={index}
                                                            sx={{
                                                                width: '100%', display: 'flex', flexDirection: 'row',
                                                            }}>
                                                            <Box sx={{
                                                                width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                                cursor: 'pointer', '&:hover': {
                                                                    backgroundColor: Colors.quinary,
                                                                },
                                                            }}
                                                                onClick={() => handleCheckboxChange(
                                                                    { target: { checked: !typeNames.includes(result.types), value: result.types } },
                                                                    result.typeId
                                                                )}
                                                            >
                                                                <Checkbox
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    value={result.types} // Set value to the type
                                                                    checked={typeNames.includes(result.types)} // Check if this type is selected
                                                                    onChange={(event) => handleCheckboxChange(event, result.types_id)}
                                                                    sx={{
                                                                        '&.Mui-checked': {
                                                                            color: '#0026CC',
                                                                        },
                                                                    }}
                                                                />
                                                                <TypographyComponent sx={{
                                                                    fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,
                                                                    minWidth: 'fit-content'
                                                                }}> {result.types}</TypographyComponent>

                                                            </Box>
                                                        </Box>
                                                    ))}


                                                </Box>
                                            </Box>)}
                                        {typesError &&
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>
                                                {typesError}</TypographyComponent>}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Certifications
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent
                                            variant="outlined"
                                            placeholder="Add your certifications"
                                            value={certifications}
                                            onChange={(event) => handleInputCertificationsChange(event)}
                                            onBlur={handleCertificationOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'certifications')}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        disabled={selectedCertifications.length >= 5 || !isCertficationIcon}
                                                        onClick={() => handleAddCertification()}>
                                                        <AddIcon style={{ color: selectedCertifications.length >= 5 || !isCertficationIcon ? Colors.default : Colors.primary }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isCertificationsAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedCertifications.map((certification, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={certification.certifications}
                                                        onDelete={() => handleCertificationsCloseButtonClick(index)}
                                                        onClick={() => handleCertificationsShowButtonClick(certification.certifications, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '16px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}

                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> License
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent
                                            variant="outlined"
                                            placeholder="Add your license"
                                            value={license}
                                            onChange={(event) => handleInputLicenseChange(event)}
                                            onBlur={handleLicenseOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'license')}

                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton disabled={selectedLicense.length === 5 || !isLisenceIcon}
                                                        onClick={() => handleAddLicense()}>
                                                        <AddIcon style={{ color: selectedLicense.length === 5 || !isLisenceIcon ? Colors.default : Colors.primary }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isLicenseAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedLicense.map((license, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={license.license}
                                                        onDelete={() => handleLicenseCloseButtonClick(index)}
                                                        onClick={() => handleLicenseShowButtonClick(license.license, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '16px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Franchise
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent
                                            variant="outlined"
                                            placeholder="Add your franchise"
                                            value={franchise}
                                            onChange={(event) => handleInputFranchiseChange(event)}
                                            onBlur={handleFranchiseOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'franchise')}

                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton disabled={selectedFranchise.length === 5 || !isFranchiseIcon}
                                                        onClick={() => handleAddFranchise()}>
                                                        <AddIcon style={{ color: selectedFranchise.length === 5 || !isFranchiseIcon ? Colors.default : Colors.primary }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isFranchiseAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedFranchise.map((franchise, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={franchise.franchise}
                                                        onDelete={() => handleFranchiseCloseButtonClick(index)}
                                                        onClick={() => handleFranchiseShowButtonClick(franchise.franchise, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '16px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Office address
                                                <TypographyComponent sx={{ fontSize: '12px', color: Colors.grey_60, display: 'inline' }}> (Optional)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent placeholder="Add your office address"
                                            value={OfficeAddress}
                                            onChange={(event) => handleInputOfficeAddressChange(event)}>
                                        </TextFieldComponent>
                                    </Box>

                                    {/* <Box sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
                                        width: '100%', height: '100%', gap: '12px', paddingTop: '16px'
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Do you want to add more services?
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 4 services)
                                            </TypographyComponent>
                                        </div>
                                        <Button onClick={handleAddmoreService} disabled={multipleServiceName.length === 4}
                                            variant="text"
                                            startIcon={<AddIcon style={{ fontSize: '16px',
                                                 color:multipleServiceName.length === 4? Colors.default : Colors.primary }} />}
                                            sx={{
                                                textTransform: 'none', fontSize: '16px', color: Colors.primary, fontFamily: "Roboto",
                                                whiteSpace: 'nowrap', // Ensure text stays in one line
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // Remove background color on hover
                                                },
                                            }}
                                        >
                                            Add more services
                                        </Button>
                                    </Box> */}

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <TypographyComponent > About all my services (Please describe all services you do)*
                                        </TypographyComponent>

                                        <TextFieldComponent
                                            variant="outlined"
                                            multiline={true}
                                            rows={4}
                                            placeholder="Add more about yourself"
                                            value={aboutUs}
                                            onChange={handleInputAboutUsChange}
                                            error={!!aboutUsError} sx={{}}></TextFieldComponent>
                                        {aboutUsError &&
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>
                                                {aboutUsError}</TypographyComponent>}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center',
                                        width: '100%', height: '100%', gap: '12px', paddingTop: '16px'
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                            width: '100%', height: '100%', gap: '12px', maxWidth: '60%'
                                        }}>
                                            <ButtonComponentWhite onClick={handleCancelButtonClick}  >Cancel</ButtonComponentWhite>
                                            <ButtonComponent onClick={handleSaveButtonClick} >Save</ButtonComponent>
                                        </Box>
                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            width: { md: '40%', sm: '40%', xs: '100%' }, gap: '24px',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '275px', maxWidth: { md: '90%', sm: '90%', xs: '100%' }, gap: '24px',
                            }}>

                                <BusinessCardBack serviceDetails={serviceDetails} isEditEnable={false} serviceDetailsDataToShow={serviceDetailsDataToShow}
                                    setServiceDetailsDataToShow={setServiceDetailsDataToShow} serviceProviderDtls={serviceProviderDtls}

                                ></BusinessCardBack>
                            </Box>
                        </Box>



                    </Box>

                </Box>

            </Box>









            {mustSavePopup &&
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '2',

                }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '420px', sm: '350px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleMustSavePopUpCancelButtonClick} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '420px', sm: '350px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #FFFFFF',
                                borderRadius: '28px ',
                                boxShadow: 'none',
                                boxSizing: 'border-box',
                                zIndex: '3',
                                padding: '35px',
                                alignItems: 'center',

                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                                justifyContent: 'center', alignContent: 'center', height: '100%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    alignContent: 'center', width: '100%', gap: '16px'
                                }}>
                                    <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, fontWeight: 600, }}>
                                        Warning</TypographyComponent>

                                    <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                                        If you proceed without saving, the changes you made will be lost. Are you sure you want to continue?</TypographyComponent>

                                </Box>


                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                                    alignContent: 'center', alignItems: 'center', width: '100%'
                                }}>

                                    <ButtonComponentWhite onClick={handleMustSavePopUpCancelButtonClick} >Cancel</ButtonComponentWhite>
                                    <ButtonComponent onClick={handleMustSavePopUpSaveButtonClick} >Save</ButtonComponent>


                                </Box>


                            </Box>
                        </Box>
                    </Box>
                </Box>}


            {removeServicePopup &&
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '2',

                }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '420px', sm: '350px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleRemoveServicePopUpCancelButtonClick} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '420px', sm: '350px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #FFFFFF',
                                borderRadius: '28px ',
                                boxShadow: 'none',
                                boxSizing: 'border-box',
                                zIndex: '3',
                                padding: '35px',
                                alignItems: 'center',

                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                                justifyContent: 'center', alignContent: 'center', height: '100%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    alignContent: 'center', width: '100%', gap: '16px'
                                }}>
                                    <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, fontWeight: 600, }}>
                                        Delete</TypographyComponent>


                                    <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                                        Are you sure you want to delete {removeServiceName}?</TypographyComponent>

                                </Box>


                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                                    alignContent: 'center', alignItems: 'center', width: '100%'
                                }}>

                                    <ButtonComponentWhite onClick={handleRemoveServicePopUpCancelButtonClick} >No</ButtonComponentWhite>
                                    <ButtonComponent onClick={handleRemovePopUpYesButtonClick} >Yes</ButtonComponent>


                                </Box>


                            </Box>
                        </Box>
                    </Box>
                </Box>}





        </Grid>
    )
} export default AddService;