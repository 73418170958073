import React, { useEffect, useState } from 'react'
import SpHeader from '../Components/SpHeader';
import { Box, Breadcrumbs, Grid, IconButton, InputAdornment, Menu, MenuItem, Typography, colors, useMediaQuery, useTheme } from '@mui/material';
import Colors from '../Components/Colors';
import TypographyHeading from '../Components/TypographyHeading';
import { Link } from 'react-router-dom';
import StepperPhase2 from '../Components/StepperPhase2';
import TypographyNormal from '../Components/TypographyNormal';
import SelectComponent from '../Components/SelectComponent';
import TextFieldComponent from '../Components/TextFieldComponent';
import axios from 'axios';
import { API_URL } from './Constants';
import profile from '../Images/prof.png'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TypographyComponent from '../Components/TypographyComponent';
import camera from '../Images/camera_Icon.png';
import TypographyLabel from '../Components/TypographyLabel';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonComponentWhite from '../Components/ButtonComponentWhite';
import ButtonComponent from '../Components/ButtonComponent';

function Phase2SpProfileForm() {
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [businessName, setBusinessName] = useState("");
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [postalCodeError, setPostalCodeError] = useState("");
    const [provinceError, setProvinceError] = useState("");



    const [data, setData] = useState([]);
    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [btn, setbtn] = useState(1);
    const [selectedColor, setSelectedColor] = useState('#f9f9f9');

    const [countryCode, setCountryCode] = useState("");
    const [country, setCountry] = useState("Canada");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [province, setProvince] = useState("Ontario");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const theme = useTheme();
    // console.log("newprofile pic check   ,.,.,,.", newProfilePic)
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //  console.log("extrasmall", isXsScreen)
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //  console.log('small', isSmScreen)
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    //  console.log('med', isMdScreen)
    const token = localStorage.getItem('token');

    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteImage = async () => {
        // Logic for deleting the image
        try {
            const response = await axios.post(`${API_URL}/remove_profile_image`, { token });
            //  console.log("kmkl", response)
            if (response.data.success) {
                console.log("success");
                fetchServiceProviderDetails();
            }
        }
        catch (error) {
            console.log("error", error)
        }
        handleMenuClose();
    };

    const handleImageUpload = (e) => {
        // setUpdateImage(true);
        handleMenuClose();
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', (event) => {
            const imageUrl = URL.createObjectURL(event.target.files[0]);
            //  console.log("imageUrl...........", imageUrl);
            // setSelectedImage(imageUrl);
            // onImageChange(imageUrl);
            setNewProfilePic(imageUrl)
        });
        input.click();
    };

    const handleCancelButtonClick = () => {
        window.location.href = '/sp-landing'
    };
    const handleNextButtonClick = () => {
        window.location.href = '/sp-landing'
    };

    async function fetchServiceProviderDetails() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            // console.log("service-provider-details", response.data)
            // console.log("service-provider-details", response.data.data.serviceProvider.service_details.length)
            if (response.data.data.serviceProvider.service_details.length === 0) {
                setIsCompleteProfile(false)
            }
            if (response.data.success) {
                setServiceProviderDtls(response.data.data.serviceProvider)
                setMessageDetails(response.data.data.serviceProvider.message_details)
                console.log("service dataaa", response.data.data.serviceProvider)
                const profileImage = response.data.data.serviceProvider.profile_image;
                // const imgurl = `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`;
                const imgurl = profileImage ? `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${profileImage}` : null;
                //  console.log("image ", imgurl)
                setNewProfilePic(imgurl);
                setBusinessName(response.data.data.serviceProvider.business_name);
                setEmail(response.data.data.serviceProvider.email)
                setPassword(response.data.data.serviceProvider.password)


                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

            }
            else {
                console.error('Error:', response.data.error);
            }
        }
        catch (error) {
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);

    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={80}
                unreadMessageCount={unreadMessageCount} isCompleteProfile={isCompleteProfile}>
            </SpHeader>
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                width: '100%', height: '100%', background: Colors.grey_20
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    width: '100%', maxWidth: '96%', height: '100%', maxHeight: '94%', paddingTop: '2.5%', paddingBottom: '2.5%', gap: '20px'
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                        width: '100%', height: '10%', gap: '12px'
                    }}>
                        <TypographyHeading>Complete your profile</TypographyHeading>
                        <Breadcrumbs separator={<span style={{
                            paddingLeft: '2px', fontSize: '32px', lineHeight: '18px',
                            color: Colors.default
                        }}>›</span>} aria-label="breadcrumb">
                            <Link underline="hover"
                                style={{
                                    display: 'flex', alignItems: 'center', fontWeight: 400, fontSize: '16px', fontFamily: 'Roboto',
                                    color: Colors.default, textUnderlineOffset: '5px', gap: '2px'
                                }}
                                href="/">
                                <HomeOutlinedIcon sx={{ color: Colors.default, fontSize: '18px' }} />
                                Home
                            </Link>
                            <Typography sx={{
                                display: 'flex', alignItems: 'center', fontFamily: 'Roboto',
                                fontWeight: 400, fontSize: '16px', color: Colors.primary, gap: '2px'
                            }}>
                                <EditOutlinedIcon sx={{ color: Colors.primary, fontSize: '18px' }} />
                                Edit Profile
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch',
                        width: '100%', height: '90%', gap: '16px',
                    }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            width: '20%', flex: 1, background: Colors.white, paddingTop: '2.5%'
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '100%', maxWidth: '80%'
                            }}>
                                <StepperPhase2 currentStage={2}></StepperPhase2>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                            width: '80%', height: '100%', background: Colors.white, paddingTop: '2.5%',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                                width: '100%', height: '100%', maxWidth: '90%', gap: '24px',
                            }}>
                                <TypographyHeading sx={{
                                    fontFamily: 'Roboto', fontSize: '18px', fontWeight: 500, color: Colors.black_80,
                                    textAlign: 'left', width: '100%'
                                }}>
                                    My profile
                                </TypographyHeading>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '34px', alignItems: 'center' }}>

                                    <Box position="relative">
                                        <Box
                                            component="img"
                                            src={newProfilePic || profile}
                                            alt="Profile"
                                            sx={{
                                                width: '166px',
                                                height: '184px',
                                                border: '2px solid #ffffff',
                                                boxShadow: '0px 4px 4px 0px #00000040',
                                                // maxWidth: { md: '90px', sm: '65px', xs: '65px' },
                                                // maxHeight: { md: '90px', sm: '65px', xs: '65px' },
                                                // minWidth:{ md: '90px', sm: '65px', xs: '65px' },
                                                //     minHeight:{ md: '90px', sm: '65px', xs: '65px' },
                                            }}
                                        >
                                        </Box>
                                        <Box>
                                            <label htmlFor="imageUpload">
                                                <img src={camera} alt="camera" style={{
                                                    width: isSmScreen ? '20px' : '40px',
                                                    height: isSmScreen ? '20px' : '40px',
                                                    position: 'absolute', bottom: '5%', left: '93%',
                                                    transform: 'translate(-50%, 50%)', zIndex: 1, cursor: 'pointer'
                                                }}
                                                    onClick={handleMenuOpen}
                                                />
                                            </label>

                                            <Menu
                                                anchorEl={anchorEl}
                                                open={isMenuOpen}
                                                onClose={handleMenuClose}
                                                PaperProps={{
                                                    sx: {
                                                        position: 'absolute',
                                                        right: '30px',
                                                        top: '65px',
                                                        fontFamily: 'Roboto',
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                        width: '170px',
                                                        backgroundColor: Colors.grey_20,
                                                        borderRadius: '8px',
                                                        boxShadow: 'none',
                                                        zIndex: 1,
                                                        color: 'white',
                                                        padding: '8px',
                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={handleImageUpload}
                                                    sx={{
                                                        fontFamily: 'Roboto',
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                        color: Colors.black_80,
                                                        minHeight: '20px',
                                                        borderRadius: '4px',

                                                        '&:hover': {
                                                            backgroundColor: '#415FE2 !important ',
                                                            color: '#FFFFFF !important',
                                                            borderRadius: '8px'
                                                        },
                                                        '&:focus': {
                                                            backgroundColor: 'transparent', // Ensure background is transparent on focus
                                                            // color: '#FFFFFF !important',
                                                        },
                                                    }}
                                                >
                                                    Change Image
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleDeleteImage}
                                                    sx={{
                                                        fontFamily: 'Roboto',
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                        color: Colors.black_80,
                                                        minHeight: '20px',
                                                        borderRadius: '4px',
                                                        '&:hover': {
                                                            backgroundColor: '#415FE2',
                                                            color: '#FFFFFF !important',
                                                            borderRadius: '8px'
                                                        },
                                                    }}
                                                >
                                                    Remove Image
                                                </MenuItem>
                                            </Menu>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '8px' }}>
                                        <TypographyComponent sx={{ fontWeight: 500, fontSize: '28px', color: Colors.black_80 }}>
                                            {businessName}
                                        </TypographyComponent>
                                        <TypographyComponent sx={{ fontWeight: 400, fontSize: '24px', color: Colors.default }}>
                                            {serviceProviderDtls.professional_name}
                                        </TypographyComponent>
                                    </Box>

                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '24px' }}>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '16px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Business name*
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="George"
                                                // error={!!emailError}
                                                value={businessName}
                                            // onChange={handleEmailChange}
                                            >

                                            </TextFieldComponent>

                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Email*
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="Georgefloyd@gmail.com"
                                                // error={!!emailError}
                                                value={email}
                                            // onChange={handleEmailChange}
                                            >

                                            </TextFieldComponent>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '16px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Contact person(Optional)
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="George"
                                            // error={!!emailError}
                                            // value={businessName}
                                            // onChange={handleEmailChange}
                                            >

                                            </TextFieldComponent>

                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel sx={{ fontSize: '14px', color: Colors.black_70, }}
                                            >
                                                Password*</TypographyLabel>
                                            <TextFieldComponent placeholder="Enter your password"
                                                // onChange={handlePasswordChange}
                                                error={!!passwordError}
                                                value={password}
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={togglePasswordVisibility}
                                                                edge="end"
                                                                aria-label="toggle password visibility"
                                                                color="primary"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOutlinedIcon sx={{ color: Colors.black_80 }} />
                                                                ) : (
                                                                    <VisibilityOffOutlinedIcon sx={{ color: Colors.black_80 }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            ></TextFieldComponent>
                                            {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                                                <Link to="/change-password" color={Colors.link}
                                                    underline="none" component="button" variant="body2"
                                                    style={{ color: Colors.link, textDecoration: 'none', fontSize: '12px', fontWeight: 500 }}>
                                                    Change Password
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '16px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'row', gap: '8px' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                                <TypographyLabel>
                                                    Country code*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="+1"
                                                    // error={!!emailError}
                                                    value={countryCode}
                                                // onChange={handleEmailChange}
                                                >

                                                </TextFieldComponent>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                                                <TypographyLabel>
                                                    Phone number*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="Enter your phone number"
                                                    // error={!!emailError}
                                                    value={phoneNumber}
                                                // onChange={handleEmailChange}
                                                >

                                                </TextFieldComponent>
                                            </Box>


                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'row', gap: '8px' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                                <TypographyLabel>
                                                    Postal code*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="M5S 2C6"
                                                    // error={!!emailError}
                                                    value={countryCode}
                                                // onChange={handleEmailChange}
                                                >

                                                </TextFieldComponent>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                                                <TypographyLabel>
                                                    Province*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="Ontario"
                                                    // error={!!emailError}
                                                    value={phoneNumber}
                                                // onChange={handleEmailChange}
                                                >

                                                </TextFieldComponent>
                                            </Box>


                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '16px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Country*
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="Canada"
                                            // error={!!emailError}
                                            // value={businessName}
                                            // onChange={handleEmailChange}
                                            >

                                            </TextFieldComponent>

                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>


                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',width:'100%',flexDirection:'row',gap:'16px',justifyContent:'flex-end'}}>
                    <ButtonComponentWhite sx={{ width: '120px', height: '44px' }}
                                onClick={handleCancelButtonClick}>
                                Back
                            </ButtonComponentWhite>
                            <ButtonComponent sx={{ width: '120px', height: '44px' }}
                                onClick={handleNextButtonClick}>
                                Next
                            </ButtonComponent>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

export default Phase2SpProfileForm;