

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { IMG_URL } from "../Components/Constants";
import { useState, useEffect, useRef } from 'react';
import Colors from '../Components/Colors';
import TypographyComponent from '../Components/TypographyComponent';
import ButtonComponentWhite from '../Components/ButtonComponentWhite';
import ButtonComponent from '../Components/ButtonComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { API_URL } from '../Components/Constants';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import logo from '../Images/logo.png'
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Tabs, Tab, Divider, Switch, FormControlLabel, Avatar, TablePagination, List, ListItem, } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import AssociateHeader from '../Components/AssociateHeader';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import TextFieldComponent from '../Components/TextFieldComponent';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';




const StyledTableCell = styled(TableCell)({
  fontSize: 14,
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AssociateLanding() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isViewProfile, setIsViewProfile] = useState(false);
  const [assossiateName, setAssossiateName] = useState("");
  const [assossiateId, setAssossiateId] = useState("");
  const [searchGroupChar, setSearchGroupChar] = useState('');
  const [serviceProviderDtls, setServiceProviderDtls] = useState(null);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
  const [serviceProviderUrl, setServiceProviderUrl] = useState(null);
 // const [selectedTab, setSelectedTab] = useState(0);
  const [professionName, setProfessionName] = useState([]);
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const [serviceDtls, setServiceDtls] = useState([]);
  const [serviceDtlsShow, setServiceDtlsShow] = useState([]);
  const [paginatedServiceProviders, setPaginatedServiceProviders] = useState([]);
  const [page, setPage] = useState(0);
 

  const [selectedTab, setSelectedTab] = useState(() => {  
    return localStorage.getItem('selectedTab') ? parseInt(localStorage.getItem('selectedTab')) : 0;
  });
  const [value, setValue] = useState(selectedTab);

  useEffect(() => {    
    localStorage.setItem('selectedTab', value);
  }, [value]);

  const formatDateTime = (dateTimeString) => {
    const dateUTC = new Date(dateTimeString);
    const timeOptions = {
      timeZone: 'America/Toronto',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
    const formattedTime = timeFormatter.format(dateUTC);

    const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
    const day = String(dateInToronto.getDate()).padStart(2, '0');
    const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
    const year = dateInToronto.getFullYear();

    return `${day}-${month}-${year} ${formattedTime}`;
  };


  const navigate = useNavigate();

  const handleSignOutPopUpOkButtonClick = async () => {
    localStorage.removeItem('token');
    navigate('/signin', { replace: true });
  };

  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // Click outside handler
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Cleanup listener on unmount or when showDropdown changes
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);

  const handleMenuItemClick = (value) => {
    let sortedProviders;
    if (value === "name") {
      sortedProviders = [...serviceProviders].sort((a, b) => 
        a.serviceProvider.business_name.localeCompare(b.serviceProvider.business_name)
      );
    }
    if (value === "time") {
      sortedProviders = [...serviceProviders].sort((a, b) => {
       
        const normalizeDate = (dateString) => {         
          if (dateString.includes('T')) {
            return new Date(dateString);
          } else {          
            return new Date(dateString + 'T00:00:00.000Z');
          }
        };
        
        const dateA = normalizeDate(a.serviceProvider.joined_date);
        const dateB = normalizeDate(b.serviceProvider.joined_date);
        
        // Compare dates
        return dateB - dateA;
  });

    }
    setServiceProviders(sortedProviders);
    setPaginatedServiceProviders(sortedProviders)    
    setShowDropdown(false);
  }

  const fetchAssossiateDetails = async () => {
    const associatetocken = localStorage.getItem("associatetocken")
    try {
      const response = await axios.post(`${API_URL}/select_associate_details`,
        { token: associatetocken, });
      console.log(response.data.data)
      setAssossiateName(response.data.data.associate_name);
      setAssossiateId(response.data.data.associate_id)
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };

  useEffect(() => {
    fetchAssossiateDetails();

  }, []);

  async function handleAddServiceProviderClick() {
    localStorage.removeItem('ASPID')
    const isEdit = false; 
     window.location.href = `/associate_profile?edit=${isEdit}`;
   // window.location.href = '/associate_profile';
  }


  
  const [serviceProviders, setServiceProviders] = useState([]);

  const tabs = ['New Onboarding', 'Pending', 'Rejected', 'Accepted'];
  const colors = ['#F0624E', '#45DCDC', '#A14C93', '#F22B7E', '#5F90DB'];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setServiceProviders([])
    setPage(0)
  };

 
    const fetchServiceProviders = async () => {
      const status = tabs[value];
      try {
        const response = await axios.post(`${API_URL}/sp-details-with-tab`, { status });
        console.log(response.data.data)
        setServiceProviders(response.data.data || []);       
        setPaginatedServiceProviders(response.data.data || [])
      } catch (error) {
        console.error('Error fetching service providers:', error);
      }
    };
  useEffect(() => {
    fetchServiceProviders();
  }, [value]);


  
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const searchListRef = useRef(null);
  const searchBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchListRef.current && !searchListRef.current.contains(event.target) && !searchBoxRef.current.contains(event.target)) {
        setSearchGroupChar('');
        setFilteredProviders([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const handleSearchChange = (event) => {
  //   const value = event.target.value;
  //   setSearchGroupChar(value);

  //   if (value) {
  //     const filtered = serviceProviders.filter(provider =>
  //       provider.serviceProvider.business_name.toLowerCase().startsWith(value.toLowerCase())
  //     );
  //     setFilteredProviders(filtered);
  //   } else {
  //     setFilteredProviders([]);
  //   }
  // };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchGroupChar(value);
  
    if (value) {
      const filtered = serviceProviders.filter(provider => 
        provider.serviceProvider.business_name.toLowerCase().startsWith(value.toLowerCase()) ||
        provider.serviceProvider.phone_number.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredProviders(filtered);
    } else {
      setFilteredProviders([]);
    }
  };
  
  


  
  // const handleSelectProvider = (serviceProviderId) => {
  //   setSelectedProviderId(serviceProviderId);
  
  //   // Sort service providers by putting the selected one on top
  //   const sortedProviders = [
  //     serviceProviders.find(provider => provider.serviceProvider.service_provider_id === serviceProviderId), 
  //     ...serviceProviders.filter(provider => provider.serviceProvider.service_provider_id !== serviceProviderId)
  //   ];
  
  //   setServiceProviders(sortedProviders);  
  //   setPaginatedServiceProviders(sortedProviders); // Update pagination data with sorted list
  //   setFilteredProviders([]);
  //   setSearchGroupChar('');
  // };
  
  // // Pagination logic (make sure to reset paginated providers)
  // useEffect(() => {
  //   const paginatedProviders = serviceProviders.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  //   setPaginatedServiceProviders(paginatedProviders);
  // }, [page, serviceProviders]);

  const handleSelectProvider = (serviceProviderId) => {
    setSelectedProviderId(serviceProviderId);
  
    // Find the selected provider and other providers
    const selectedProvider = serviceProviders.find(provider => provider.serviceProvider.service_provider_id === serviceProviderId);
    const otherProviders = serviceProviders.filter(provider => provider.serviceProvider.service_provider_id !== serviceProviderId);
  
    // Create a new list with the selected provider at the top
    const sortedProviders = [selectedProvider, ...otherProviders];
  
    // Calculate the current page index
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
  
    // Ensure the selected provider is within the current page
    const providerIndex = sortedProviders.findIndex(provider => provider.serviceProvider.service_provider_id === serviceProviderId);
    if (providerIndex < startIndex || providerIndex >= endIndex) {
      // Move the provider to the top of the current page if it isn't already in view
      const paginatedProviders = sortedProviders.slice(startIndex, endIndex);
      if (providerIndex < startIndex) {
        const newPage = Math.floor(providerIndex / rowsPerPage);
        setPage(newPage);
      }
      setPaginatedServiceProviders(paginatedProviders);
    } else {
      setPaginatedServiceProviders(sortedProviders.slice(startIndex, endIndex));
    }
  
    // Update the full list of providers
    setServiceProviders(sortedProviders);
  
    // Clear search filters
    setFilteredProviders([]);
    setSearchGroupChar('');
  };
  
  

  useEffect(() => {
    // Calculate the start and end index for pagination
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(serviceProviders.length, (page + 1) * rowsPerPage);
    
    // Get the paginated list based on updated serviceProviders
    const paginatedProviders = serviceProviders.slice(startIndex, endIndex);
    
    setPaginatedServiceProviders(paginatedProviders);
  }, [page, serviceProviders]); // Depend on both page and serviceProviders
  
  
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
 

 

//   const paginatedProviders = serviceProviders
//   .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

// const sortedProviders = selectedProviderId
//   ? [serviceProviders.find(provider => provider.serviceProvider.service_provider_id === selectedProviderId), ...paginatedProviders.filter(provider => provider.serviceProvider.service_provider_id !== selectedProviderId)]
//   : paginatedProviders;


 

  const rowRefs = useRef({}); // To store references to table rows

  useEffect(() => {
    if (selectedProviderId && rowRefs.current[selectedProviderId]) {
      // Ensure row is visible in the viewport
      rowRefs.current[selectedProviderId].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedProviderId, page]);


  const handleEditProfileButtonClick = (id) => {
    localStorage.setItem("ASPID", id)
   // window.location.href = '/associate_profile'
   const isEdit = true; 
   window.location.href = `/associate_profile?edit=${isEdit}`;
  };

  const handleEditLocationButtonClick = (id) => {
    localStorage.setItem("ASPID", id)
   // window.location.href = '/associate_location'
   const isEdit = true; 
   window.location.href = `/associate_location?edit=${isEdit}`;
  };

  const handleEditServiceButtonClick = (id) => {
    localStorage.setItem("ASPID", id)
    localStorage.setItem('editservice', 'true');
    localStorage.removeItem('addservice');
   // window.location.href = '/associate_service'
   const isEdit = true; 
   window.location.href = `/associate_service?edit=${isEdit}`;
  };

  const handleAddServiceClick = (id) => {
    localStorage.setItem("ASPID", id)
    localStorage.setItem('addservice', 'true');
    localStorage.removeItem('editservice');
    const isEdit = true; 
    window.location.href = `/associate_service?edit=${isEdit}`;
  }
  const [checked, setChecked] = useState(false);
  const [profileIndex, setProfileIndex] = useState(0);
 
  const handleViewProfileClick = (id, index) => {
    console.log(id)
    setProfileIndex(index)
    fetchServiceProviderDetails(id)
    localStorage.setItem("changeId",id)
  };

  let searchServiceId
  let joinedDate
  let formattedDate
  async function fetchServiceProviderDetails(SpId) {

    try {
      const response = await axios.post(`${API_URL}/sp-details-with-SpId`, { SpId });
      console.log("service-provider-details", response.data)
      if (response.data.success) {
        setIsViewProfile(true)
       
        console.log("qr", response.data.data.serviceProvider)
        setServiceProviderDtls(response.data.data.serviceProvider)
        setServiceProviderQrCode(response.data.data.qrCode)
        setServiceProviderUrl(response.data.data.serviceProviderURL)
        setServiceDtls(response.data.data.serviceProvider.service_details) 
        console.log(response.data.data.serviceProvider.service_details[0].associate_status)
        setChecked(response.data.data.serviceProvider.service_details[0].associate_status === 'Pending')  
        setAlignment(response.data.data.serviceProvider.service_details[0].associate_status);     
        const profileImage = response.data.data.serviceProvider.profile_image;
        const imgurl = profileImage ? `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${profileImage}` : null;
        console.log(profileImage)
        setNewProfilePic(imgurl);
        searchServiceId = serviceDtls[0].services_id
        setSelectedTab(0)
        setSelectedServiceData(serviceDtls[0])
        joinedDate = new Date(serviceProviderDtls.joined_date);
        formattedDate = `${joinedDate.getDate()}/${joinedDate.getMonth() + 1}/${joinedDate.getFullYear()}`;    
       
       

      } else {
        console.error('Error:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }



  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const selectedService = serviceDtls[newValue];
    setServiceDtlsShow(selectedService)
  };

  useEffect(() => {
    if (selectedServiceData) {
      setServiceDtlsShow(selectedServiceData)
    }
  }, [selectedServiceData]);

  useEffect(() => {
    if (serviceDtls) {
      const extractedProfessionalNames = Array.isArray(serviceDtls)
        ? serviceDtls.map(detail => ({
          professional_name: detail.professional_name,
          services_id: detail.services_id
        }))
        : [{
          professional_name: serviceDtls.professional_name,
          services_id: serviceDtls.services_id
        }];
      setProfessionName(extractedProfessionalNames);
      // setServiceDtlsShow(serviceDtls[0])
    }
    setServiceDtlsShow(selectedService)
  }, [serviceDtls]);
  const selectedService = serviceDtls[selectedTab] || {};


  const handleCloseViewProfilePopUp = () => {
    localStorage.removeItem("ASPID")
    setIsViewProfile(false)
  };

  const [alignment, setAlignment] = useState('');

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      handleSwitchChange(event, newAlignment);
    }
  };

  const [buttons, setButtons] = useState([]);

  // Function to get buttons based on the selected tab
  const getButtonsForTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return ['New Onboarding', 'Pending'];
      case 1:
        return ['Pending', 'Rejected'];
      case 2:
        return ['Rejected', 'Pending'];
      case 3:
        return ['Accepted'];
      default:
        return [];
    }
  };

  useEffect(() => {
    console.log('Selected tab:', selectedTab);
    setButtons(getButtonsForTab(value));
  }, [value]);
  

  const handleSwitchChange = async (event, newAlignment) => {
    setChecked(newAlignment);
    console.log(event.target.value)
    let Statusvalue = event.target.value
  //   if(newAlignment === 'Pending'){
  //      Statusvalue = 'Pending'
  //   }
  //   else if(newAlignment === 'Rejected'){
  //      Statusvalue = 'Rejected'
  //   }
  //   else if(newAlignment === 'New Onboarding'){
  //     Statusvalue = 'New Onboarding'
  //  }
    const SpIdChange = localStorage.getItem("changeId")
    console.log(SpIdChange)
    console.log(Statusvalue)
    try {      
      const response = await axios.post(`${API_URL}/associate-enable-profile`, {          
          SpId: SpIdChange, 
          status: Statusvalue,
        });
        console.log(response.data.success)
        if(response.data.success === true)  {
           fetchServiceProviders();
           setIsViewProfile(false)
        }
          } catch (error) {
          console.error('Error:', error.message);
          }   
  };

  // const handleSwitchChange = async (event, newAlignment) => {
  //   setChecked(event.target.checked);
  //   console.log(event.target.checked)
  //   let Statusvalue
  //   if(event.target.checked === true){
  //      Statusvalue = 'Pending'
  //   }
  //   else{
  //      Statusvalue = 'Rejected'
  //   }
  //   const SpIdChange = localStorage.getItem("changeId")
  //   console.log(SpIdChange)
  //   console.log(Statusvalue)
  //   try {      
  //     const response = await axios.post(`${API_URL}/associate-enable-profile`, {          
  //         SpId: SpIdChange, 
  //         status: Statusvalue,
  //       });
  //       if(response.data.success === true)  {
  //          fetchServiceProviders();
  //          setIsViewProfile(false)
  //       }
  //         } catch (error) {
  //         console.error('Error:', error.message);
  //         }   
  // };

 

  const [comments, setComments] = useState({}); // State to store comments by service_provider_id

  const handleAddComment = async (e, spid) => {
    if (e.key === 'Enter' && comments[spid]?.trim() !== '') {
      console.log(spid,assossiateId,comments[spid])
      try {
        const response = await axios.post(`${API_URL}/associate-add-comment`, {
          spid: spid,
          associate_id: assossiateId, 
          comment: comments[spid]
        });

        if (response.data.success) {
         
          setComments((prevComments) => ({
            ...prevComments,
            [spid]: '', 
          }));
          fetchComments();
        }
      } catch (error) {
        console.error('Error adding comment:', error);        
      }
    }
  };

  const handleCommentChange = (e, spid) => {
    const value = e.target.value;
    setComments((prevComments) => ({
      ...prevComments,
      [spid]: value,
    }));

  };


  const fetchComments = async () => {
    try {
      const response = await axios.post(`${API_URL}/fetch_assossiate_comments`);
     console.log(response.data)
      if (response.data.success) {       
        const fetchedComments = response.data.comments.reduce((acc, comment) => {
          acc[comment.service_provider_id] = comment.associate_comments;
          return acc;
        }, {});
        setComments(fetchedComments);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
      // Handle error appropriately in the UI
    }
  };
useEffect(() => {
  fetchComments();
}, []);


  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>

      <AssociateHeader></AssociateHeader>

      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        width: '100%', height: '100%', paddingTop: '2.5%', paddingBottom: '2.5%',
      }}>

        <Box sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          width: '100%', height: '100%', maxWidth: '96%', gap: '16px'
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            width: '100%', height: '100%',
          }}>
            <TypographyComponent sx={{
              fontSize: { xs: '16px', md: '18px' }, paddingLeft: { xs: '8px', md: '0px' },
              fontColor: Colors.black_80
            }}>
              List of service providers
            </TypographyComponent>
            <ButtonComponent sx={{ width: 'fit-content', borderRadius: '8px' }} onClick={handleAddServiceProviderClick}>
              <AddIcon sx={{ paddingRight: '3px' }} />Add service provider </ButtonComponent>
          </Box>
        
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            width: '100%', height: '100%',
          }}>
          <Box sx={{ display: "flex", width: '62%', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '12px' }}>
  <Tabs
    value={value}
    onChange={handleChange}
    variant="scrollable"
    scrollButtons="auto"
    aria-label="service tabs"
    TabIndicatorProps={{ style: { display: 'none' } }}
    sx={{
      gap: '0px',
      '& .MuiTab-root': {
        padding: '0px',
        minWidth: 'unset !important',
      }
    }}
  >
    {tabs.map((tab, index) => (
      <Tab
        key={index}
        label={
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              color: Colors.black_80,
              cursor: 'pointer',
              minWidth: 'fit-content',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              border: '1px solid',
              borderColor: Colors.grey_30,
              padding: '12px 20px',
              borderRadius: '4px',
              textTransform: 'none',
              backgroundColor: value === index ? Colors.grey_60 : 'transparent',
              marginLeft: '0px',
            }}
          >
            {tab}
          </Typography>
        }
      />
    ))}
  </Tabs>
            </Box>

            <Box sx={{ display: "flex", width: '8%', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '12px', position: 'relative' }}>
              <TypographyComponent  onClick={() => setShowDropdown(!showDropdown)} sx={{ fontSize: '14px', color: Colors.black_80 }}>
                Sort by
              </TypographyComponent>

              {showDropdown ? (
                <KeyboardArrowUpIcon 
                  sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              ) : (
                <KeyboardArrowDownIcon  
                  sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              )} 
                          {showDropdown && (
                <Box ref={dropdownRef} className="dropdown__menu" sx={{
                  position: 'absolute', top: '40px', right: '0px', zIndex: 1000,
                  fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                  lineHeight: '20px', width: '170px', background: Colors.white,
                  borderRadius: '8px', boxShadow: '0px 4px 4px 0px #00000040',
                  padding: '8px', border: '1px solid', borderColor: Colors.grey_30
                }}>
                  <style>
                    {`
                      .dropdown__menu .MuiMenuItem-root:hover {
                        background-color: #415FE2;
                        color: #FFFFFF !important;
                      }
                      .dropdown-menu__triangle {
                        position: absolute;
                        top: -10px;
                        right: 10px;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid white;
                        border-top: 10px solid ${Colors.grey_30};
                        box-shadow: 0px 4px 4px 0px #00000040;
                      }
                    `}
                  </style>

                  <MenuItem onClick={() => handleMenuItemClick("name")} value="option2" style={{
                    fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                    fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                  }}>
                    Name
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("time")} value="option2" style={{
                    fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                    fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                  }}>
                    Created Time
                  </MenuItem>
                </Box>
              )}          
            </Box>



            <Box sx={{ position: 'relative', display: "flex", width: '25%', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '12px' }} ref={searchBoxRef}>
      <TextField
        variant="outlined"
        placeholder="Search"
        value={searchGroupChar}
        onChange={handleSearchChange}
        sx={{
          boxShadow: 'none', width: '100%', height: '38px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '28px', background: 'white', height: '38px',
            '& fieldset': {
              borderColor: '#CFC9C9',
            },
            '&:hover fieldset': {
              borderColor: '#313131',
            },
            color: '#313131', fontFamily: 'Roboto', fontSize: '14px', lineHeight: '18px',
            fontWeight: '400',
          },
        }}
      />

      {searchGroupChar && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '42px', // Adjust based on TextField height
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            zIndex: 1
          }}
          ref={searchListRef}
        >
          <List>
            {filteredProviders.length > 0 ? (
              filteredProviders.map(({ serviceProvider }) => (
                <ListItem
                  key={serviceProvider.service_provider_id}
                  sx={{
                    cursor: 'pointer',
                    padding: '10px',
                    '&:hover': { backgroundColor: '#f0f0f0' }
                  }}
                  onClick={() => handleSelectProvider(serviceProvider.service_provider_id)}
                >
                 <Typography>
                  {serviceProvider.business_name} ({serviceProvider.phone_number})
                </Typography>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <Typography>No results found</Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      )}
    </Box>

            </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            width: '100%', height: '100%',
          }}>
            {serviceProviders.length === 0 ? (
              <Typography sx={{paddingTop:'10%'}}>No service providers found for {tabs[value]} status</Typography>
            ) : (

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                  {paginatedServiceProviders.map(({ serviceProvider }, index) => (
                      <StyledTableRow
                      key={serviceProvider.service_provider_id}
               
                sx={{
                  backgroundColor: selectedProviderId === serviceProvider.service_provider_id ? '#f0f0f0' : 'transparent'
                }}
                    >
                        <StyledTableCell component="th" scope="row">
                          <Box sx={{
                            display: 'flex', flexDirection: 'row', gap: { md: '12px', sm: '16px', xs: '16px' },
                            width: '100%', alignItems: 'center', justifyContent: 'left', minWidth: '280px', maxWidth: '270px',
                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                          }}>
                            {serviceProvider.profile_image ? (
                              <Avatar alt="Profile Image" src={IMG_URL + serviceProvider.profile_image} sx={{
                                width: { md: '40px', sm: '40px', xs: '40px' },minWidth:'40px',
                                height: { md: '40px', sm: '40px', xs: '40px' }
                              }} />
                            ) : (
                              <Box
                                sx={{
                                  width: { md: '40px', sm: '40px', xs: '40px' },
                                  height: { md: '40px', sm: '40px', xs: '40px' },minWidth:'40px',
                                  borderRadius: '50%',
                                  backgroundColor: colors[index % colors.length],
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: { xs: '24px', md: '26px' },
                                  fontWeight: 'bold',
                                  color: Colors.white,
                                  textAlign: 'center',
                                  lineHeight: '40px',
                                }}
                              >
                                {serviceProvider?.business_name ? serviceProvider.business_name[0].toUpperCase() : ''}
                              </Box>)}
                            <Box sx={{
                              width: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column',
                              maxWidth: '98%', alignItems: 'left', justifyContent: 'center',
                            }}>
                              <TypographyComponent sx={{
                                fontSize: '16px',
                                color: Colors.black_80,
                                whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                              }}>
                                {serviceProvider.business_name}
                              </TypographyComponent>
                              <TypographyComponent sx={{
                                fontSize: '14px',
                                color: Colors.grey_60, 
                                overflow: 'hidden',  textOverflow: 'ellipsis',  whiteSpace: 'nowrap', textTransform: 'none',
                              }}>
                                {`${serviceProvider.service_details.map(detail => detail.professional_name).join(', ')}/${serviceProvider.city}`}
                              </TypographyComponent>
                              <TypographyComponent sx={{
                                fontSize: '14px',
                                color: Colors.grey_60,
                                whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                              }}>
                                {`+${serviceProvider.country_code} ${serviceProvider.phone_number}`}
                              </TypographyComponent>

                            </Box>

                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <span>{formatDateTime(serviceProvider.joined_date).split(' ')[0]}</span>
                            <span>
                              {formatDateTime(serviceProvider.joined_date).split(' ')[1]} {formatDateTime(serviceProvider.joined_date).split(' ')[2]}
                            </span>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>                        
                          <Box  onClick={value !== 2 && value !== 3 ? () => handleEditProfileButtonClick(serviceProvider.service_provider_id) : null}
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <EditIcon style={{ color: Colors.primary, fontSize: '16px' }} /><TypographyComponent sx={{ fontSize: '16px' }}> Profile</TypographyComponent>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box onClick={value !== 2 && value !== 3 ? () => handleEditLocationButtonClick(serviceProvider.service_provider_id) : null}                        
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <EditIcon style={{ color: Colors.primary, fontSize: '16px' }} /><TypographyComponent sx={{ fontSize: '16px' }}> Location</TypographyComponent>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box onClick={value !== 2 && value !== 3 ? () => handleEditServiceButtonClick(serviceProvider.service_provider_id) : null}                          
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <EditIcon style={{ color: Colors.primary, fontSize: '16px' }} /><TypographyComponent sx={{ fontSize: '16px' }}>Service</TypographyComponent>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box onClick={value !== 2 && value !== 3 ? () => handleAddServiceClick(serviceProvider.service_provider_id) : null}                          
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <AddIcon style={{ color: Colors.primary, fontSize: '20px' }} /><TypographyComponent sx={{ fontSize: '16px' }}>Service</TypographyComponent>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <ButtonComponentWhite sx={{ borderRadius: '10px', width:'fit-content' }} onClick={() => handleViewProfileClick(serviceProvider.service_provider_id, index)} >View Profile</ButtonComponentWhite>
                        </StyledTableCell>
                        <StyledTableCell>
                        <TextFieldComponent
                          placeholder="Add comments"
                          sx={{ borderRadius: '10px' }}
                          value={comments[serviceProvider.service_provider_id] || ''}
                          onChange={(e) => handleCommentChange(e, serviceProvider.service_provider_id)}
                          onKeyDown={(e) => handleAddComment(e, serviceProvider.service_provider_id)}
                        />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <span>{formatDateTime(serviceProvider.associate_status_time).split(' ')[0]}</span>
                            <span>
                              {formatDateTime(serviceProvider.associate_status_time).split(' ')[1]} {formatDateTime(serviceProvider.associate_status_time).split(' ')[2]}
                            </span>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                component="div"
                count={serviceProviders.length} // Use full list length
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[7]} // Keeping rowsPerPage fixed as 7
              />
              </TableContainer>
            )}
            
          </Box>

         
        </Box>

      </Box>




      {isViewProfile && 
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
          }}
        >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '500px', sm: '450px', xs: '320px' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton onClick={handleCloseViewProfilePopUp} sx={{
                width: '36px', height: '36px',

              }} >
                <CancelIcon sx={{
                  width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                    color: Colors.close,
                  },
                }} ></CancelIcon>
              </IconButton>
            </Box>
            <Box
              sx={{

                width: { md: '500px', sm: '450px', xs: '320px' },
                height: 'fit-content',
                backgroundColor: Colors.grey_20,
                border: '1px solid #E6E6E6',
                borderRadius: '16px ',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',
                zIndex: 3,
                padding: '35px',
                alignItems: 'center',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '11px',
                  height: '8px', // Added height for horizontal scrollbars
                  borderRadius: '16px',

              },
              '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.grey_60,
                  borderRadius: '16px',
                  border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
              },
              '&::-webkit-scrollbar-track': {
                  borderRadius: '16px',
                  backgroundColor: Colors.white,
                  marginTop: '20px',
                  marginBottom: '20px'
              },
              }}
            >

              <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
               
              }}>
              <Box  sx={{width: '100%', height: '100%', justifyContent: 'right',
                      display: 'flex', alignItems: 'center',}} >
              
               
               {/* <FormControlLabel
                  control={
                    <Switch
                     
                      checked={checked}
                      onChange={handleSwitchChange}
                      name="toggleSwitch"
                      disabled= {serviceDtlsShow.associate_status === 'Accepted' }
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: Colors.primary,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: Colors.primary,
                          opacity: 1, // Ensures there is no white overlay on the track
                        },
                        '& .MuiSwitch-thumb': {
                          color: Colors.white, 
                        },
                      }}
                    />
                  }

                />  */}
                 <ToggleButtonGroup
                                    value={alignment}
                                    exclusive
                                    disabled={alignment === 'Accepted' }
                                    onChange={(event, newAlignment) => handleSwitchChange(event, newAlignment)}
                                    sx={{
                                        backgroundColor: Colors.white,
                                        borderRadius: '10px',
                                        border: { xs: '1px solid' , md: '1px solid' },
                                        borderColor: Colors.secondary,
                                        overflow: 'hidden',
                                        gap: '2px', // Added gap between buttons
                                        '& .MuiToggleButton-root': {
                                        border: 'none',
                                        padding: { xs: '5px 8px', md: '5px 10px' },
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            backgroundColor: Colors.secondary,
                                            color: Colors.white,
                                            borderRadius: '10px',
                                            '&:hover': {
                                            backgroundColor: Colors.secondary, // Keep same background on hover
                                            color: Colors.white, // Keep same text color on hover
                                            }
                                        },        
                                        '&:not(.Mui-selected)': {
                                            backgroundColor: Colors.white,
                                            color: '#000',
                                            borderRadius: '10px',
                                            '&:hover': {
                                            backgroundColor: Colors.white, // Keep same background on hover
                                            color: '#000', // Keep same text color on hover
                                            }
                                        },
                                        '&:not(:first-of-type)': {
                                            marginLeft: '2px', // Adds a 2px gap between buttons
                                        },
                                        },
                                    }}   
                                    >
                                   {buttons.map((button) => (
                                  <ToggleButton key={button} value={button}>
                                    {button}
                                  </ToggleButton>
                                ))}
                                    </ToggleButtonGroup>
               
                </Box>
                <Box sx={{
                  display: 'flex', flexDirection: 'row', padding: '18px 44px 0px 8%',
                  gap: '20px', justifyContent: 'space-between', alignItems: 'stretch', 
                }}>

                  <Box  sx={{width: '22%', height: '100%', justifyContent: 'center',
                      display: 'flex', alignItems: 'flex-start'}} >
                        {console.log(newProfilePic)}
                    {(newProfilePic === null ||
                      newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' ||
                      newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/undefined') && (
                       
                        <Box
                          sx={{
                            width: { md: '75px', sm: '75px', xs: '56px' },
                            height: { md: '75px', sm: '75px', xs: '56px' },
                            borderRadius: '50%',
                            backgroundColor: profileIndex !== null ||  profileIndex !== undefined ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: { xs: '24px', md: '48px' },
                            fontWeight: 'bold',
                            color: Colors.white,
                            // border: '2px solid #ffffff',
                            // boxShadow: '0px 4px 4px 0px #00000040',
                          }}
                        >
                          {console.log(profileIndex)}
                          {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
                        </Box>
                      )}
                    {(newProfilePic &&
                      newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' &&
                      newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/undefined') && (
                        <img src={newProfilePic} alt="" style={{ width: '82px', height: '82px', borderRadius: '50%' }} />
                      )}
                  </Box>


                  <Box flex={3} sx={{
                    width: '75%', display: 'flex',
                    justifyContent: 'left', alignItems: 'flex-end', paddingBottom: '16px'
                  }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      <TypographyComponent sx={{ color: Colors.primary, fontSize: { md: '24px', sm: '24px', xs: '16px' },
                       fontWeight: 600, minWidth:'90%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap', }}>
                        {serviceProviderDtls.business_name}</TypographyComponent>
                      <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '12px' },
                       minWidth:'90%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap',  }}>
                        {serviceProviderDtls.contact_person}</TypographyComponent>
                    </Box>

                  </Box>


                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                  <Divider orientation="horizontal" flexItem sx={{
                    height: '100%', width: '8%', borderColor: Colors.black_80,
                    borderWidth: '1px', backgroundColor: Colors.black_80,
                  }} />
                  <div style={{ width: '15%' }}></div>
                  <Divider orientation="horizontal" flexItem sx={{
                    height: '100%', width: '72%', borderColor: Colors.black_80,
                    borderWidth: '1px', backgroundColor: Colors.black_80,
                  }} />
                </Box>
                <Box sx={{
                  height: '100%', padding: { md: '18px 9.8%', sm: '8px 8px', xs: '8px 8px' },
                  display: 'flex', flexDirection: 'row', gap: '16px'
                }}>
                  <Box sx={{
                    width: '20%', height: '100%', display: 'flex', flexDirection: 'column',
                    gap: '8px', justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, letterSpacing: '2px' }}>
                      {serviceProviderDtls.service_provider_code}
                    </TypographyComponent>
                  </Box>
                  <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {serviceProviderDtls && (
                      <Box sx={{
                        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '3px',
                        justifyContent: 'left', alignItems: 'center'
                      }}>
                        <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }}></PhoneAndroidIcon>
                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, paddingLeft: '5px' }}>
                          +{serviceProviderDtls.country_code}
                        </TypographyComponent>
                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                          {serviceProviderDtls.phone_number}
                        </TypographyComponent>
                      </Box>
                    )}
                    {serviceProviderDtls.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                      <EmailIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }}></EmailIcon>
                      <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' },
                       minWidth:'90%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap',    }}>
                        {serviceProviderDtls.email}
                      </TypographyComponent>
                    </Box>}
                    {serviceProviderDtls && (
                      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                        <LocationOnIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' } }} />
                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                          {serviceProviderDtls.city}, {serviceProviderDtls.province}, {serviceProviderDtls.country}

                        </TypographyComponent>

                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', }} >
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="service tabs"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    sx={{
                      gap: '0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {

                      }, '& .MuiTab-root': {  // Updated selector
                        padding: '0px',
                        minWidth: 'unset !important',  // Remove nested selectors
                      }
                    }}

                  >

                    {professionName.map((service, index) => (
                      <Tab
                        key={service.services_id}
                        label={
                          <Typography
                            sx={{
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 500,
                              color: Colors.black_80,
                              cursor: 'pointer',
                              minWidth: 'fit-content',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis', textAlign: 'center',
                              whiteSpace: 'nowrap', border: '1px solid', borderColor: Colors.grey_30, padding: selectedTab === index ? '10px 12px' : '8px 12px', borderRadius: '4px',
                              textTransform: 'none', backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent', marginLeft: '4px',
                              //   '&:hover': {
                              //     backgroundColor: Colors.grey_30,
                              //   },

                            }}
                          >
                            {console.log(service)}
                            {service.professional_name}
                          </Typography>
                        }
                      />
                    ))}
                  </Tabs>


                </Box>


                <Box sx={{ height: '100%', padding: { md: '18px 44px 18px 44px', sm: '18px 24px 18px 24px', xs: '16px 16px 16px 16px' }, display: 'flex', flexDirection: 'column', gap: '12px', }}>

                  <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                    justifyContent: 'left', alignItems: 'left'
                  }}>

                    <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}>
                    </PsychologyOutlinedIcon>
                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' }, minWidth: 'fit-content', }}>
                      Type of service :
                    </TypographyComponent>
                    {serviceDtlsShow.type_details && (
                      <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' },
                      minWidth:'66%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap',  }}>
                        {serviceDtlsShow.type_details.map(item => item.types).join(', ')}
                      </TypographyComponent>)}
                  </Box>
                  <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                    justifyContent: 'left', alignItems: 'left'
                  }}>
                    <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></ApartmentOutlinedIcon>
                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' }, minWidth: 'fit-content', }}>
                      Office :
                    </TypographyComponent>
                    {serviceDtlsShow.office_address && (
                      <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' },
                      minWidth:'85%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap', }}>
                        {serviceDtlsShow.office_address}
                      </TypographyComponent>)}
                  </Box>
                  <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                    justifyContent: 'left', alignItems: 'left'
                  }}>
                    <LanOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></LanOutlinedIcon>
                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' },  minWidth: 'fit-content', }}>
                      Franchise :
                    </TypographyComponent>
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' },
                   minWidth:'77%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap', }}>
                      {serviceDtlsShow.franchise}
                    </TypographyComponent>
                  </Box>
                  <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                    justifyContent: 'left', alignItems: 'left'
                  }}>
                    <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></BadgeOutlinedIcon>
                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' }, minWidth: 'fit-content', }}>
                      License No :
                    </TypographyComponent>
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' },
                  minWidth:'74%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap', }}>
                      {serviceDtlsShow.license}
                    </TypographyComponent>
                  </Box>
                  <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px',
                    justifyContent: 'left', alignItems: 'left'
                  }}>
                    <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: { md: '18px', sm: '18px', xs: '16px' }, paddingTop: '2px' }}></WysiwygOutlinedIcon>
                    <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' },minWidth: 'fit-content', }}>
                      Certification :
                    </TypographyComponent>
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' },
                  minWidth:'72%', overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap',  }}>
                      {serviceDtlsShow.certifications}
                    </TypographyComponent>
                  </Box>

                </Box>



                <Box sx={{
                  height: '100%', padding: { md: '18px 44px', sm: '18px 44px', xs: '18px' }, display: 'flex', flexDirection: 'row', gap: '8px',
                  overflow: 'hidden', wordWrap: 'break-word', maxHeight: '100%', justifyContent: 'left', alignItems: 'left'
                }}>
                  <TypographyComponent sx={{ color: Colors.default, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                    About :
                  </TypographyComponent>
                  <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                    {serviceDtlsShow.about_us}
                  </TypographyComponent>
                </Box>
                <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '14px' }, textAlign: 'center' }}>
                    Joined by {formatDateTime(serviceProviderDtls.joined_date).split(' ')[0]}
                  </TypographyComponent>

                </Box>
              </Box>








            </Box>
          </Box>
        </Box>
      }













    </Grid>
  )
} export default AssociateLanding