import Box from '@mui/material/Box';

import AppBar from "@mui/material/AppBar";

import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import logo from '../Images/logo.png'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import Toolbar from "@mui/material/Toolbar";
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypographyComponent from './TypographyComponent';
import CircularProgress from './CircularProgress';
import ButtonComponentWhite from './ButtonComponentWhite';
import ButtonComponent from './ButtonComponent';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Colors from './Colors';
import TextFieldComponent from './TextFieldComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function UserHeaderMobile() {
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuToggle = () => {
      setShowMenu(!showMenu);
    };


    const menus = [
      { label: "Home", link: "/" },      
      { label: "About us", link: "/about-us" },
      { label: "Contact us", link: "/contact-us"}
    ];

return (


<Grid container sx={{width: '100%', height: '100%' }}>
<AppBar position="static" sx={{ bgcolor: Colors.white, height:{ md:"56" , sm: "32"  , xs: "56"   } ,paddingLeft:'2.5%', width: '100%', paddingRight:'2.5%', 
boxShadow:'none'}}>
     <Toolbar disableGutters>
       <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center', paddingLeft: '18px',
            width:'30%'         }}>
           <Box sx={{ width: '100px', height: '40px', background: Colors.default }}>
          </Box>

           <IconButton   edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle}>
               <MenuIcon  style={{color:Colors.black_80}}/>
           </IconButton>
       </Box>
       <Box sx={{display: showMenu ? "block" : "none", position: "absolute", top: "100%",height: '100vh',width:'65%' , zIndex: 5,
           bgcolor: Colors.white, justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px',
           background:Colors.secondary,}}>
            <CloseOutlinedIcon onClick={() => setShowMenu(false)}  
            style={{width:'32px', height:'32px', color:Colors.white, cursor:'pointer'}}></CloseOutlinedIcon>
           {menus.map((menu) => (
           <Button onClick={handleMenuToggle}
              key={menu.label}
              component={Link}
              to={menu.link}
              sx={{display: "block", color: "white", textTransform: "capitalize", fontFamily: "poppins",
                 fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                 color:Colors.white,
                 '&:hover': { background: Colors.quinary, width: '100%',color:Colors.white }}}>
             {menu.label}
           </Button>  ))}
       </Box>
     
     
     </Toolbar>
</AppBar>

      
</Grid>
 );

}

export default UserHeaderMobile;
