import React, { useEffect, useState } from 'react';
import { Box,  Grid, IconButton, InputAdornment, Stack , AppBar, Container, Toolbar, TextField, Menu, MenuItem,} from "@mui/material";
import ProgressbarEdit from '../Components/ProgressbarEdit';
import TypographyComponent from '../Components/TypographyComponent';
import TypographyLabel from '../Components/TypographyLabel';
import TextFieldComponent from '../Components/TextFieldComponent';

import ButtonComponent from '../Components/ButtonComponent';
import ButtonComponentWhite from '../Components/ButtonComponentWhite';

import logo from '../Images/logo.png'
import axios from 'axios';
import { API_URL } from './Constants';
import { IMG_URL } from '../Components/Constants';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Colors from '../Components/Colors';
import SideBar from '../Components/SideBar';

import AssociateHeader from '../Components/AssociateHeader';
import AssociateSideBar from '../Components/AssociateSidebar';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';



function AssociateProfile() {
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [isProvince, setIsProvince] = useState(false);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [businessName, setBusinessName] = useState("");
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [postalCodeError, setPostalCodeError] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [updateImage, setUpdateImage] = useState(false);
    const [nameError, setNameError] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [selectedBgButton, setSelectedBgButton] = useState('b1')
    const [data, setData] = useState([]);
    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [btn, setbtn] = useState(1);
    const [selectedColor, setSelectedColor] = useState('#f9f9f9');
    const [profileError, setProfileError] = useState("");
    const [countryCode, setCountryCode] = useState("1");
    const [country, setCountry] = useState("Canada");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [province, setProvince] = useState("Ontario");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isEdit = params.get('edit') === 'true';
    console.log(isEdit);

    const isEditEnabled = 'true';
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    
    const token = localStorage.getItem('token');

    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsProvince(true)

    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteImage = async () => {
        // Logic for deleting the image
        try {
            const response = await axios.post(`${API_URL}/remove_profile_image`, { token });
            //  console.log("kmkl", response)
            if (response.data.success) {
                console.log("success");
               // fetchServiceProviderDetails();
            }
        }
        catch (error) {
            console.log("error")
        }
        handleMenuClose();
    };

    const handleImageUpload = (e) => {
        // setUpdateImage(true);
        handleMenuClose();
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', (event) => {
            const imageUrl = URL.createObjectURL(event.target.files[0]);
            //  console.log("imageUrl...........", imageUrl);
            // setSelectedImage(imageUrl);
            // onImageChange(imageUrl);
            setNewProfilePic(imageUrl)
        });
        input.click();
    };

    const handleCancelButtonClick = () => {
        window.location.href = '/associate_landing'
    };
    const [provinces, setProvinces] = useState([]);

    useEffect(() => {
        //  console.log("isEditEnabled",isEditEnabled);
        const fetchProvinces = async () => {
          try {
            const response = await axios.get(`${API_URL}/get-provinces`);
            setProvinces(response.data); // Assume the API returns an array of province names
            // if (!isEditEnabled) {
            //   setProvince('Ontario');
            //   setProvinceError("");
            // }
            // setCountry('Canada');
          } catch (error) {
            console.error('Error fetching provinces:', error);
          }
        };
    
        fetchProvinces(); // Call the function
      }, []); 

      const handleProvinceChange = (event) => {
        // console.log("setProvince................", event.target.value);
        // setProvince(event.target.value);
        // setProvinceError("");
        setAnchorEl(event.currentTarget);
        setProvince(event.target.value);
        if (!event.target.value) {
          setProvinceError('Province is required');
        } else {
          setProvinceError('');
        }
    
      };
    
      const handleProvinceSelect = (selectedProvince) => {
        setProvince(selectedProvince);
        handleMenuClose();
        setProvinceError("");
        setIsProvince(false)
      };

      const handleContactPersonChange = (event) => {
        let value = event.target.value;       
        setContactPerson(value);
       
      };

      const handleBusinessNameChange = (event) => {
        let value = event.target.value;       
        setBusinessName(value);
        setNameError("");
      };

      const handleEmailChange = (event) => {
        let value = event.target.value;      
        if (value.length > 0) {
          value = value.charAt(0).toLowerCase() + value.slice(1);
        }
        setEmail(value);
        setEmailError("");
       
      };
      const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
      };

      const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
        setProfileError("");
      };
      const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setCountryError("");
      };

      const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
          event.preventDefault();
        }
      };
    
      const handleKeyDownPhone= (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
          event.preventDefault();
        }
      };

      const handlePostalCodeChange = (event) => {      
         setPostalCode(event.target.value);
         setPostalCodeError("");
       };

       const SpId = localStorage.getItem("ASPID")

    async function fetchServiceProviderDetails() {
      
        try {
          
            const response = await axios.post(`${API_URL}/sp-details-with-SpId`, { SpId });
             console.log("service-provider-details", response.data)
            
           
            if (response.data.success) {
               
                setServiceProviderDtls(response.data.data.serviceProvider)
                setMessageDetails(response.data.data.serviceProvider.message_details)                         
                setBusinessName(response.data.data.serviceProvider.business_name);
                setContactPerson(response.data.data.serviceProvider.contact_person); 
                console.log(response.data.data.serviceProvider.contact_person)              
                setEmail(response.data.data.serviceProvider.email);
                setCity(response.data.data.serviceProvider.city)
                setCountryCode(response.data.data.serviceProvider.country_code);               
                setPhoneNumber(response.data.data.serviceProvider.phone_number);
                setPostalCode(response.data.data.serviceProvider.postal_code);
                const profileImage = response.data.data.serviceProvider.profile_image;               
                const imgurl = profileImage ? `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${profileImage}` : null;             
                setNewProfilePic(imgurl);
                if (response.data.data.serviceProvider.province != null) {
                setProvince(response.data.data.serviceProvider.province);
                }
                
                if (response.data.data.serviceProvider.country != null) {
                    setCountry(response.data.data.serviceProvider.country);
                        }

                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

               

            }
            else {
                console.error('Error:', response.data.error);
            }
        }
        catch (error) {
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchServiceProviderDetails();
    }, [SpId, isEdit]);


    const handleSaveButtonClick = async () => {

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
         if (countryCode.trim() === "") {
             setCountryCodeError("Please enter your countryCode");
             return;
         }
         else if (phoneNumber.trim() === "") {
             setPhoneNumberError("Please enter your phoneNumber");
             return;
         }
         else if (phoneNumber.trim() === "" && phoneNumber.length !== 10) {
            setProfileError('Phone number must be 10 digits'); 
            return;          
        }
         else if (province.trim() === "") {
             setProvinceError("Please enter your province");
             return;
         }
         else if (country.trim() === "") {
             setCountryError("Please enter your country");
             return;
         }
         else if (postalCode.trim() === "") {
             setPostalCodeError("Please enter your postalCode");
             return;
         }
         else if (businessName.trim() === "") {
            setNameError("Please enter your name");
            return;
        }
        else if (email.trim() !== "" &&  !emailRegex.test(email)) {
             setEmailError('Invalid email address');           
            return;
        }
         else {
            console.log("hii")
            const data1 = {                
                country: country,
                countryCode: countryCode,
                postalCode: postalCode,
                province: province,
                phoneNumber: phoneNumber,
                contactPerson:contactPerson,
                cardColor: selectedBgButton,
                cardNumber: btn,
                email: email,
                name: businessName,
                spId: SpId
            };
            console.log(updateImage)
             if (updateImage === true) {
                 const formData = new FormData();
                 console.log("formData.........", newProfilePic)
                 formData.append('data1', JSON.stringify(data1));
                 formData.append('profilepic', newProfilePic);
                 fetch(newProfilePic)
                     .then((res) => res.blob())
                     .then((blob) => {
                         formData.append('profilepic', blob, 'leboco');                        
                         axios.post(`${API_URL}/sp-associate-update-next`, formData)
                             .then((response) => {
                               //  console.log('Insert response:', response);
                                 if (response.data.success) {
                                     setUpdateImage(false);
                                     window.location.href = '/associate_landing';
                                   //  console.log("success");
                                 }
                                 else{
                                    console.log("error");
                                    setProfileError(response.data.message)
                                 }
                             })
                             .catch((error) => {
                                 console.log('Error inserting service provider:', error);
                             });
                     })
                     .catch((error) => {
                         console.log('Error fetching image:', error);
 
                     });
             }
             else {
                 try {
                     const response = await axios.post(`${API_URL}/sp-associate-update-without-image`, { data1 });
                     console.log("kmkl", response)
                     if (response.data.success) {
                         window.location.href = '/associate_landing';
                         console.log("success");
                     }
                     else{
                        console.log(response.data.message);
                        setProfileError(response.data.message)
                     }
                 }
                 catch (error) {
                    // console.log("error", error)
                 }
 
             }
         }
 
     };

     async function handleNextButtonClick() { 

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (businessName.trim() === "" || !businessName) {
            setNameError("Please enter your name");           
        }
        else if (!countryCode || countryCode.trim() === "") {
             setCountryCodeError("Please enter your countryCode");
            
         } else if (!phoneNumber || phoneNumber.trim() === "") {
             setPhoneNumberError("Please enter your phoneNumber");
             
         }         
         else if (phoneNumber.trim() === "" && phoneNumber.length !== 10) {
            setProfileError('Phone number must be 10 digits');           
        }
         else if (!province || province.trim() === "") {
             setProvinceError("Please enter your province");
           
         } else if (!country || country.trim() === "") {
             setCountryError("Please enter your country");
            
         } else if (!postalCode || postalCode.trim() === "") {
             setPostalCodeError("Please enter your postalCode");
            
         }
         else if (email.trim() !== "" && !emailRegex.test(email)) {            
           
              setEmailError('Invalid email address');
                   
        }
        
        
         else {
            console.log("hiii")
            if(localStorage.getItem("ASPID"))
            {
                const data1 = {                
                    country: country,
                    countryCode: countryCode,
                    postalCode: postalCode,
                    province: province,
                    phoneNumber: phoneNumber,
                    contactPerson:contactPerson,
                    cardColor: selectedBgButton,
                    cardNumber: btn,
                    email: email,
                    name: businessName,
                    spId: SpId
                };
                 if (updateImage === true) {
                     const formData = new FormData();
                     formData.append('data1', JSON.stringify(data1));
                     formData.append('profilepic', newProfilePic);
                     fetch(newProfilePic)
                         .then((res) => res.blob())
                         .then((blob) => {
                             formData.append('profilepic', blob, 'leboco');
                           //  console.log("formData.........", formData)
                             axios.post(`${API_URL}/sp-associate-update-next`, formData)
                                 .then((response) => {
                                 // console.log('Insert response:', response);
                                     if (response.data.success) {
                                         setUpdateImage(false);
                                         const isEdit = false; 
                                         window.location.href = `/associate_location?edit=${isEdit}`;
                                       //  console.log("success");
                                     }
                                     else{
                                       // console.log(error);
                                        console.log("error");
                                        setProfileError(response.data.message)
                                     }
                                 })
                                 .catch((error) => {
                                     console.log('Error inserting service provider:', error);
                                    // console.log(error)
                                 });
                         })
                         .catch((error) => {
                             console.log('Error fetching image:', error);
     
                         });
                 }
                 else {
                     try {
                         const response = await axios.post(`${API_URL}/sp-associate-update-without-image`, { data1 });
                         console.log("kmkl", response)
                         if (response.data.success) {
                            const isEdit = false; 
                             window.location.href = `/associate_location?edit=${isEdit}`;
                             console.log("success");
                         }
                         else{
                           // console.log(error);
                            setProfileError(response.data.message)
                         }
                     }
                     catch (error) {
                        // console.log("error", error)
                     }
     
                 }
            }
            else
              {
             const data1 = {                
                 country: country,
                 countryCode: countryCode,
                 postalCode: postalCode,
                 province: province,
                 phoneNumber: phoneNumber,
                 contactPerson:contactPerson,
                 cardColor: selectedBgButton,
                 cardNumber: btn,
                 email: email,
                 name: businessName
             };
             if (updateImage === true) {
                 const formData = new FormData();
                 formData.append('data1', JSON.stringify(data1));
              //   console.log("data1.........", data1)
                 formData.append('profilepic', newProfilePic);
                 fetch(newProfilePic)
                     .then((res) => res.blob())
                     .then((blob) => {
                         formData.append('profilepic', blob, 'leboco');
                      //   console.log("formData.........", formData)
                         axios.post(`${API_URL}/sp-associate-edit-next`, formData)
                             .then((response) => {
                                 console.log('Insert response:', response);
                                 if (response.data.success) {
                                    localStorage.setItem("ASPID",response.data.spId)
                                    const isEdit = false; 
                                    window.location.href = `/associate_location?edit=${isEdit}`;
                                     console.log("success")
                                 }
                                 else{
                                    console.log(response.data.message);   
                                    setProfileError(response.data.message)
                                 }
                             })
                             .catch((error) => {
                                console.error('Error inserting service provider:', error);
                            });
                     })
                     .catch((error) => {
                         console.error('Error fetching image:', error);
 
                     });
             } else {
                 try {
                     const response = await axios.post(`${API_URL}/sp-associate-save-without-image`, { data1 });
                     console.log("kmkl", response)
                     if (response.data.success) {
                        const isEdit = false; 
                         window.location.href = `/associate_location?edit=${isEdit}`;
                         localStorage.setItem("ASPID",response.data.spId)
                         console.log("success");
                     }
                     else{  
                        console.log(response.data.message);                     
                        setProfileError(response.data.message)
                     }
                 }
                 catch (error) {
                     console.log("error", error)
                 }
             }
            }
         }
     };


    return (

        <Box >

             <AssociateHeader></AssociateHeader>

       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    width: '100%', height: '100%', paddingTop: '2.5%', paddingBottom:'2.5%',  }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', 
                    width: '100%', height: '100%', maxWidth:'92%',gap:'32px' , }}>

              <Box sx={{ display: 'flex', flexDirection: 'row', width: '25%', height: '100%',  }}>
                <AssociateSideBar currentStage={1} setNewProfilePic={setNewProfilePic} newProfilePic={newProfilePic}
                    selectedImage={selectedImage} setSelectedImage={setSelectedImage} businessName={businessName}
                    updateImage={updateImage} setUpdateImage={setUpdateImage} serviceProviderDtls={serviceProviderDtls}>

                </AssociateSideBar>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', width: '5%', height: '100vh', }}>
                <Divider orientation="vertical" sx={{ backgroundColor: Colors.grey_30, height: '100%' }} />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    width: '70%', height: '80vh',  }}>
                   <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '32px',justifyContent: 'center', alignItems: 'center', }}>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '24px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Business name*
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="George"
                                                
                                            value={businessName}
                                            onChange={handleBusinessNameChange}
                                            error={!!nameError}
                                            >

                                            </TextFieldComponent>
                                            {nameError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{nameError}</TypographyComponent>}

                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Email(Optional)
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="Georgefloyd@gmail.com"                                            
                                             value={email}
                                             error={!!emailError}
                                            onChange={handleEmailChange}
                                            >
                                            </TextFieldComponent>
                                            {emailError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{emailError}</TypographyComponent>}
 
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '24px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                                            <TypographyLabel>
                                                Contact person(Optional)
                                            </TypographyLabel>
                                            <TextFieldComponent placeholder="George"
                                            // error={!!emailError}
                                            value={contactPerson}
                                           onChange={handleContactPersonChange}
                                            >

                                            </TextFieldComponent>

                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'row' ,gap:'8px'}}>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                                <TypographyLabel>
                                                    Country code*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="+1"
                                                    error={!!countryCodeError}
                                                    value={countryCode}
                                                onChange={handleCountryCodeChange}
                                                onKeyDown={handleKeyDownCountryCode}
                                                inputProps={{ maxLength: 3 }}
                                                >

                                                </TextFieldComponent>
                                                {countryCodeError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{countryCodeError}</TypographyComponent>}
 
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                                                <TypographyLabel>
                                                    Phone number*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="Enter your phone number"
                                                     error={!!phoneNumberError}
                                                    value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                onKeyDown={handleKeyDownPhone}
                                                inputProps={{ maxLength: 10 }}
                                                >

                                                </TextFieldComponent>
                                                {phoneNumberError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{phoneNumberError}</TypographyComponent>}
                                                {profileError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{profileError}</TypographyComponent>}
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '24px' }}>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'row', gap: '8px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                                <TypographyLabel>
                                                    Postal code*
                                                </TypographyLabel>
                                                <TextFieldComponent placeholder="M5S 2C6"
                                                error={!!postalCodeError}
                                                    value={postalCode}
                                                 onChange={handlePostalCodeChange}
                                                >

                                                </TextFieldComponent>
                                                {postalCodeError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{postalCodeError}</TypographyComponent>}
 
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                                                <TypographyLabel>
                                                    Province*
                                                </TypographyLabel>
                                                <TextFieldComponent
                                                placeholder="Ontario"
                                                value={province}
                                                onChange={handleProvinceChange}
                                                error={!!provinceError}
                                                InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={handleMenuOpen}>
                                                    <ArrowDropDownIcon />
                                                    </IconButton>
                                                ),
                                                }}
                                                onClick={handleMenuOpen} // Open menu on text field click
                                            />
                                            {isProvince  && <Box sx={{
                                                width: { md: '95%', sm: '95%', xs: '90%' }, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                                alignItems: 'center', gap: '16px', position: 'relative',
                                            }}>

                                                <Box  sx={{
                                                    width: '95%', padding: '16px 16px 16px 16px', position: 'absolute',
                                                    borderRadius: '16px', gap: '16px', maxHeight: '168px', overflow: 'auto', top: '16px',
                                                    height: 'fit-content', boxShadow: '0px 4px 4px 0px #00000025',
                                                    border: '1px solid', zIndex: 5,
                                                    borderColor: Colors.grey_60, background: Colors.white,
                                                    '&::-webkit-scrollbar': {
                                                        width: '11px',
                                                        height: '8px', // Added height for horizontal scrollbars
                                                        borderRadius: '16px',

                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: Colors.grey_60,
                                                        borderRadius: '16px',
                                                        border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        borderRadius: '16px',
                                                        backgroundColor: Colors.white,
                                                        marginTop: '10px',
                                                        marginBottom: '10px'
                                                    },

                                                }}>
                                          
                                               
                                                {provinces.map((prov) => (
                                                <MenuItem key={prov.province_id}                                               
                                                 onClick={() => handleProvinceSelect(prov.province)}>
                                                    {prov.province}
                                                </MenuItem>
                                                ))}
                                          
                                            </Box>
                                            </Box>}
                                            {provinceError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{provinceError}</TypographyComponent>}
                                            </Box>   


                                        </Box>
                                        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>                                        
                                      

                                        <TypographyLabel>
                                            Country*
                                        </TypographyLabel>
                                        <TextFieldComponent placeholder="Canada"
                                         error={!!countryError}
                                        value={country}
                                        onChange={handleCountryChange}
                                        >

                                        </TextFieldComponent>

                                        {countryError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{countryError}</TypographyComponent>}

                                        </Box>
                                    </Box>
                                  
                                          
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '24px' }}>

                   
                    {isEdit ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          marginLeft: 'auto',
          alignItems: 'center'
        }}>
          <ButtonComponentWhite 
            sx={{ width: '120px', height: '44px' }}
            onClick={handleCancelButtonClick}
          >
            Cancel
          </ButtonComponentWhite>
          <ButtonComponent 
            sx={{ width: '120px', height: '44px' }}
            onClick={handleSaveButtonClick}
          >
            Save
          </ButtonComponent>
        </Box>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          marginLeft: 'auto',
        }}>
          <ButtonComponentWhite 
            sx={{ width: '120px', height: '44px' }}
            onClick={handleCancelButtonClick}
          >
            Back
          </ButtonComponentWhite>
          <ButtonComponent 
            sx={{ width: '120px', height: '44px' }}
            onClick={handleNextButtonClick}
          >
            Next
          </ButtonComponent>
        </Box>
      )}
                </Box> 
                                   
                     </Box>
                                

                     
                    
               
                </Box>
              

           
            </Box>
            </Box>
        </Box>
    );
}

export default AssociateProfile;
