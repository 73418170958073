import React from "react"
import Button from '@mui/material/Button';
import Colors from "./Colors";

 function ButtonComponentWhite({ onClick, disabled, sx, ...props }){
    
    return(
        <Button variant="contained" disabled={disabled}
           onClick={onClick} 
            sx={{boxShadow: 'none',width:'100%', height:{ md:'48px' , sm: '48px', xs: '38px'  }, backgroundColor: Colors.white,
            borderRadius:'28px',color:Colors.primary,textTransform: 'none',fontFamily: 'Roboto',
            fontSize:'16px', fontWeight:'400',border:'1px solid ',borderColor: disabled ? Colors.grey_60 : Colors.primary,
            '&:hover': {
                background: Colors.quinary, textTransform: 'none',color:Colors.primary, boxShadow: 'none',
              },
              ...sx,
            }}
                 {...props}>            
         </Button>
    )
}
export default ButtonComponentWhite;

