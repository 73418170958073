import React, { useEffect, useState } from 'react';
import { Box, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Tabs, Tab, Select, MenuItem, TextField, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TypographyComponent from '../Components/TypographyComponent';
import TextFieldComponent from '../Components/TextFieldComponent';
import SelectComponent from '../Components/SelectComponent';
import AdminHead from '../Components/AdminHead';
import Colors from '../Components/Colors';
import ButtonComponent from '../Components/ButtonComponent';
import ButtonComponentWhite from '../Components/ButtonComponentWhite';
import axios from 'axios';
import { API_URL } from './Constants';

function AdminLanding() {
    const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false); // State to manage expansion
    const [isServicesExpanded, setIsServicesExpanded] = useState(false);
    const [isTypesExpanded, setIsTypesExpanded] = useState(false);
    const [isSPExpanded, setIsSPExpanded] = useState(false);
    const [isSearchTagExpanded, setIsSearchTagExpanded] = useState(false);

    const [activeTabCategory, setActiveTabCategory] = useState('view');
    const [selectedOptionCategory, setSelectedOptionCategory] = useState('');
    const [textFieldValueCategory, setTextFieldValueCategory] = useState('');
    const [addedCategory, setAddedCategory] = useState('');
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [activeTabSearchTag, setActiveTabSearchTag] = useState('service')


    const [activeTabService, setActiveTabService] = useState('view');
    const [activeTabSub, setActiveTabSub] = useState('view');


    const [selectedOptionServiceAdd, setSelectedOptionServiceAdd] = useState('');
    const [selectedOptionServiceCategoryView, setSelectedOptionServiceCategoryView] = useState('');
    const [selectedOptionServiceCategoryAdd, setSelectedOptionServiceCategoryAdd] = useState('');
    const [selectedOptionServiceCategoryEdit, setSelectedOptionServiceCategoryEdit] = useState('');
    const [selectedOptionServiceEdit, setSelectedOptionServiceEdit] = useState('');
    const [textFieldValueService, setTextFieldValueService] = useState('');
    const [textFieldValueServiceProfessionAdd, setTextFieldValueServiceProfessionAdd] = useState('');
    const [textFieldValueServiceAdd, setTextFieldValueServiceAdd] = useState('');
    const [addedService, setAddedService] = useState('');
    const [serviceDetails, setServiceDetails] = useState([]);
    const [serviceDetailsView, setServiceDetailsView] = useState([]);
    const [serviceDetailsAdd, setServiceDetailsAdd] = useState([]);

    const [activeTabTypes, setActiveTabTypes] = useState('view');
    const [typesDetails, setTypesDetails] = useState([]);
    const [selectedOptionTypeCategoryId, setSelectedOptionTypeCategoryId] = useState('');
    const [serviceDetailsType, setServiceDetailsType] = useState([]);
    const [selectedOptionTypeServiceId, setSelectedOptionTypeServiceId] = useState('');
    const [typesDetailsType, setTypesDetailsType] = useState([]);
    const [selectedOptionTypeTypeId, setSelectedOptionTypeTypeId] = useState('');
    const [textFieldValueTypes, setTextFieldValueTypes] = useState('');
    const [textFieldAddedType, setTextFieldAddedType] = useState('');

    const [serviceSearchTagDetails, setServiceSearchTagDetails] = useState([]);
    const [typesSearchTagDetails, setTypesSearchTagDetails] = useState([]);
    const [searchTagDetailsService, setSearchTagDetailsService] = useState([]);
    const [searchTagDetailsTypes, setSearchTagDetailsTypes] = useState([]);
    const [selectedOptionSearchTagServiceId, setSelectedOptionSearchTagServiceId] = useState('');
    const [editableSearchTags, setEditableSearchTags] = useState({});
    const [textFieldAddedServiceSearchTag, setTextFieldAddedServiceSearchTag] = useState('');
    const [selectedOptionSearchTagTypesId, setSelectedOptionSearchTagTypesId] = useState('');
    const [selectedOptionSearchTagTypesServiceId, setSelectedOptionSearchTagTypesServiceId] = useState('');
    const [textFieldAddedTypesSearchTag, setTextFieldAddedTypesSearchTag] = useState('');
    const [activeTabSp, setActiveTabSp] = useState('view');
    const [spDetails, setSpDetails] = useState([]);
    const [searchTagDetailsSp, setSearchTagDetailsSp] = useState([]);
    const [textFieldAddedSpSearchTag, setTextFieldAddedSpSearchTag] = useState('');
    const [selectedSpId, setSelectedSpId] = useState('');


    const [spData, setSpData] = useState([]);
    const [spActiveTab, setSpActiveTab] = useState('');
    const [selectedServiceProviderId, setSelectedServiceProviderId] = useState('');
    const [formData, setFormData] = useState([]);

    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [province, setProvince] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [workingRadius, setWorkingRadius] = useState('');
    const [country, setCountry] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [aboutUs, setAboutUs] = useState('');
    const [certifications, setCertifications] = useState('');
    const [license, setLicense] = useState('');
    const [franchise, setFranchise] = useState('');
    const [officeAddress, setOfficeAddress] = useState('');


    const handleTextFieldName = (event) => {
        setBusinessName(event.target.value)
    }
    const handleTextFieldEmail = (event) => {
        setEmail(event.target.value)
    }
    const handleTextFieldPhone = (event) => {
        setPhoneNumber(event.target.value)
    }
    const handleTextFieldProvince = (event) => {
        setProvince(event.target.value)
    }
    const handleTextFieldPostal = (event) => {
        setPostalCode(event.target.value)
    }
    const handleTextFieldworking = (event) => {
        setWorkingRadius(event.target.value)
    }
    const handleTextFieldCountry = (event) => {
        setCountry(event.target.value)
    }
    const handleTextFieldContact = (event) => {
        setContactPerson(event.target.value)
    }
    const handleTextFieldAbout = (event) => {
        setAboutUs(event.target.value)
    }
    useEffect(() => {
        const fetchData = async () => {
            if (selectedServiceProviderId) {
                const spData1 = await fetchSpDetailBySpId(selectedServiceProviderId);
                console.log("spData", spData1);
                setSpData(spData1);
                setFormData(spData1);
            }
        };

        fetchData();
    }, [selectedServiceProviderId]);

    const handleInputChange = (index, key, value) => {
        const updatedData = [...formData];
        updatedData[index][key] = value;
        setFormData(updatedData);
    };
    const keysToDisplay = [
        "business_name",
        "phone_number",
        "province",
        "postal_code",
        "city",
        "working_radius",
        "service_provider_code",
        "email_verify",
        "services",
        "types",
        "about_us",
        "certifications"
    ];

    const handleUpdateServiceProvider = async () => {
        console.log(selectedServiceProviderId)
        try {
            const response = await axios.post(`${API_URL}/update_service_providers`, {
                token, selectedServiceProviderId, businessName, email, phoneNumber, province, postalCode,
                workingRadius, country, contactPerson, aboutUs
            });
            if (response.data.success) {
                console.log('Tags updated successfully');
                // Optionally, fetch the updated tags again
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    const handleChangeServiceProviders = async (event) => {
        setFormData([]);
        const value = event.target.value;
        const selectedOption = serviceSearchTagDetails.find(option => option.service_id === value);
        const textContent = selectedOption ? selectedOption.services : '';
        console.log(value, textContent)
        setSelectedServiceProviderId(value); // Update selected dropdown value
        const spData1 = await fetchSpDetailBySpId(value);
        console.log("spData", spData1);
        setSpData(spData1);
        console.log("name", spData1.business_name)
        setBusinessName(spData1[0].business_name || '');
        setEmail(spData1[0].email || '');
        setPhoneNumber(spData1[0].phone_number || '');
        setProvince(spData1[0].province[0] || '');
        console.log(spData1[0].postal_code)
        setPostalCode(spData1[0].postal_code || '');
        // setCity(spData1.city || '');
        setWorkingRadius(spData1[0].working_radius || '');
        setCountry(spData1[0].country || '');
        setContactPerson(spData1[0].contact_person || '');
        setAboutUs(spData1[0].about_us || '');
        setCertifications(spData1[0].certifications || '');
        setLicense(spData1[0].license || '');
        setFranchise(spData1[0].franchise || '');
        setOfficeAddress(spData1[0].office_address || '');


        setFormData(spData1);

        // const searchTagData1 = await fetchSpSearchTagsDetailBySpId(value);
        // console.log("searchTagData1", searchTagData1)
        // setSearchTagDetailsSp(searchTagData1);

        // const editableTags = {};
        // searchTagData1.forEach(tag => {
        //     editableTags[tag.search_tag_id] = tag.search_tags ? tag.search_tags.split(',') : [];
        // });
        // console.log(editableTags);
        // setEditableSearchTags(editableTags);

    };

    const handleChangeSpList = async (event) => {

        const value = event.target.value;
        const selectedOption = serviceSearchTagDetails.find(option => option.service_id === value);
        const textContent = selectedOption ? selectedOption.services : '';
        console.log(value, textContent)
        setSelectedSpId(value); // Update selected dropdown value
        // const spData1 = await fetchSpDetailBySpId(value);
        // console.log("spData", spData1);
        // setSpData(spData1)
        const searchTagData1 = await fetchSpSearchTagsDetailBySpId(value);
        console.log("searchTagData1", searchTagData1)
        setSearchTagDetailsSp(searchTagData1);

        const editableTags = {};
        searchTagData1.forEach(tag => {
            editableTags[tag.search_tag_id] = tag.search_tags ? tag.search_tags.split(',') : [];
        });
        console.log(editableTags);
        setEditableSearchTags(editableTags);

    };
    const fetchSpSearchTagsDetailBySpId = async (spId) => {
        try {
            const response = await axios.post(`${API_URL}/get_spsearchtags_by_sp_id`, { token, spId });
            console.log("Fetched search tags", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };
    const fetchSpDetailBySpId = async (spId) => {
        try {
            const response = await axios.post(`${API_URL}/get_spdata_by_sp_id`, { token, spId });
            console.log("Fetched search tags", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };

    useEffect(() => {
        fetchAllServiceProviders();
    }, [])


    const handleSearchTagChange = (searchTagId, index, newValue) => {
        setEditableSearchTags(prevState => ({
            ...prevState,
            [searchTagId]: prevState[searchTagId].map((tag, i) => (i === index ? newValue : tag)),
        }));
    };



    const token = localStorage.getItem('token');
    const handleCategoryTabChange = (event, newValue) => {
        setActiveTabCategory(newValue); // Switch between tabs
    };
    const spTabChange = (event, newValue) => {
        setSelectedServiceProviderId('');
        setSpActiveTab(newValue); // Switch between tabs
    };


    const handleSubTabChange = (event, newValue) => {
        setSelectedOptionSearchTagTypesId('');
        setSelectedOptionSearchTagServiceId('');
        setActiveTabSub(newValue); // Switch between tabs
    };
    const handleSearchTagTabChange = (event, newValue) => {
        // setSelectedOptionSearchTagServiceId('');
        setEditableSearchTags({});
        setActiveTabSub('view')
        setActiveTabSearchTag(newValue); // Switch between tabs
    };

    const handleServiceTabChange = (event, newValue) => {
        setActiveTabService(newValue); // Switch between tabs
    };

    const handleTypesTabChange = (event, newValue) => {

        setActiveTabTypes(newValue); // Switch between tabs
        setTypesDetailsType([]);
        setServiceDetailsType([]);
        setSelectedOptionTypeCategoryId('');
        setSelectedOptionTypeServiceId('');

    };
    const handleSpTabChange = (event, newValue) => {
        fetchAllSearchTagServices()
        setActiveTabSp(newValue); // Switch between tabs


    };

    const handleTextFieldAddSpSearchTag = (event) => {
        setTextFieldAddedSpSearchTag(event.target.value); // Update text field value
    };
    const handleTextFieldChangeService = (event) => {
        setTextFieldValueService(event.target.value); // Update text field value
    };
    const handleTextFieldChangeProfessionAdd = (event) => {
        setTextFieldValueServiceProfessionAdd(event.target.value); // Update text field value
    };

    const handleTextFieldAddTypes = (event) => {
        setTextFieldAddedType(event.target.value); // Update text field value
    };
    const handleCategoriesExpandClick = () => {
        console.log("categoryclick");
        setIsCategoriesExpanded(!isCategoriesExpanded); // Toggle expansion state
    };



    const handleSearchTagExpandClick = () => {
        setIsSearchTagExpanded(!isSearchTagExpanded); // Toggle expansion state
    };
    const handleServicesExpandClick = () => {
        setIsServicesExpanded(!isServicesExpanded); // Toggle expansion state
    };
    const handleTypesExpandClick = () => {
        setIsTypesExpanded(!isTypesExpanded); // Toggle expansion state
    };
    const handleSPExpandClick = () => {
        setIsSPExpanded(!isSPExpanded); // Toggle expansion state
    };

    const handleDropdownChangeSearchTagServiceView = async (event) => {

        const value = event.target.value;
        const selectedOption = serviceSearchTagDetails.find(option => option.service_id === value);
        const textContent = selectedOption ? selectedOption.services : '';
        console.log(value, textContent)
        setSelectedOptionSearchTagServiceId(value); // Update selected dropdown value
        const types = await fetchTypesByServiceId(value);
        console.log("types", types);
        const searchTags = await fetchSearchTagsByServiceId(value);
        console.log("searchTags......", searchTags)
        setSearchTagDetailsService(searchTags);

        const editableTags = {};
        searchTags.forEach(tag => {
            editableTags[tag.search_tag_id] = tag.search_tags ? tag.search_tags.split(',') : [];
        });
        setEditableSearchTags(editableTags);

    };
    const SearchTagTypesViewServiceDropdown = async (event) => {

        const value = event.target.value;
        const selectedOption = serviceSearchTagDetails.find(option => option.service_id === value);
        const textContent = selectedOption ? selectedOption.services : '';
        console.log(value, textContent)
        setSelectedOptionSearchTagTypesServiceId(value); // Update selected dropdown value
        const types = await fetchTypesByServiceId(value);
        console.log("types", types);
        setTypesSearchTagDetails(types)
        // const searchTags = await fetchSearchTagsByServiceId(value);
        // console.log("searchTags......", searchTags)
        // setSearchTagDetailsService(searchTags);

        // const editableTags = {};
        // searchTags.forEach(tag => {
        //     editableTags[tag.search_tag_id] = tag.search_tags ? tag.search_tags.split(',') : [];
        // });
        // setEditableSearchTags(editableTags);

    };

    const fetchTypesByServiceId = async (typesId) => {
        try {
            const response = await axios.post(`${API_URL}/get_types_by_service_id`, { token, typesId });
            console.log("Fetched search tags", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };
    const handleDropdownChangeSearchTagTypesView = async (event) => {

        const value = event.target.value;
        const selectedOption = typesSearchTagDetails.find(option => option.types_id === value);
        const textContent = selectedOption ? selectedOption.types : '';
        console.log(value, textContent)
        setSelectedOptionSearchTagTypesId(value); // Update selected dropdown value
        const searchTags = await fetchSearchTagsByTypesId(value);
        console.log("searchTags......", searchTags)
        setSearchTagDetailsTypes(searchTags);

        const editableTags = {};
        searchTags.forEach(tag => {
            editableTags[tag.search_tag_id] = tag.search_tags ? tag.search_tags.split(',') : [];
        });
        console.log(editableTags);
        setEditableSearchTags(editableTags);

    };
    const fetchSearchTagsByTypesId = async (typesId) => {
        try {
            const response = await axios.post(`${API_URL}/get_search_tags_by_types_id`, { token, typesId });
            console.log("Fetched search tags", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };
    const fetchSearchTagsByServiceId = async (serviceId) => {
        try {
            const response = await axios.post(`${API_URL}/get_search_tags_by_service_id`, { token, serviceId });
            console.log("Fetched search tags", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };


    const handleDropdownChangeCategory = (event) => {
        const value = event.target.value;
        const selectedOption = categoryDetails.find(option => option.service_category_id === value);
        const textContent = selectedOption ? selectedOption.service_category : '';
        setSelectedOptionCategory(value); // Update selected dropdown value
        setTextFieldValueCategory(textContent)
    };
    const handleDropdownChangeServiceCategoryView = (event) => {
        const value = event.target.value;
        const selectedOption = categoryDetails.find(option => option.service_category_id === value);
        const textContent = selectedOption ? selectedOption.service_category : '';
        setSelectedOptionServiceCategoryView(value);

        // setTextFieldValueCategory(textContent)
    };
    const handleDropdownChangeServiceCategoryAdd = (event) => {

        const value = event.target.value;
        console.log("setSelectedOptionServiceCategoryAdd", value)
        const selectedOption = categoryDetails.find(option => option.service_category_id === value);
        const textContent = selectedOption ? selectedOption.service_category : '';
        console.log(selectedOption)
        setSelectedOptionServiceCategoryAdd(value);
        // setServiceDetails([]);

        // setTextFieldValueCategory(textContent)
    };

    const handleDropdownChangeServiceCategoryEdit = (event) => {
        const value = event.target.value;
        console.log("value", value)
        const selectedOption = categoryDetails.find(option => option.service_category_id === value);
        const textContent = selectedOption ? selectedOption.service_category : '';
        console.log("textContent,value", textContent, value)
        setSelectedOptionServiceCategoryEdit(value); // Update selected dropdown value
        // setTextFieldValueCategory(textContent)
        // fetchAllServices();
    };


    const handleDropdownChangeServiceEdit = (event) => {

        const value = event.target.value;
        const selectedOption = serviceDetails.find(option => option.service_id === value);
        const textContent = selectedOption ? selectedOption.services : '';
        console.log(value, textContent)
        setSelectedOptionServiceEdit(value); // Update selected dropdown value
        setTextFieldValueService(textContent)
    };



    const handleTextFieldChangeServiceAdd = (event) => {
        setTextFieldValueServiceAdd(event.target.value); // Update text field value
    };
    const handleTextFieldChangeCategory = (event) => {
        setTextFieldValueCategory(event.target.value); // Update text field value
    };
    const handleTextFieldChangeAddCategory = (event) => {
        setAddedCategory(event.target.value); // Update text field value
    };
    const handleTextFieldChangeTypes = (event) => {
        setTextFieldValueTypes(event.target.value); // Update text field value
    };
    const handleTextFieldAddServiceSearchTag = (event) => {
        setTextFieldAddedServiceSearchTag(event.target.value); // Update text field value
    };
    const handleTextFieldAddTypesSearchTag = (event) => {
        setTextFieldAddedTypesSearchTag(event.target.value); // Update text field value
    };
    useEffect(() => {
        fetchAllCategories();
        //    fetchServices();
    }, [])

    useEffect(() => {
        fetchAllServices();
    }, [selectedOptionServiceCategoryEdit])

    // useEffect(() => {
    //     fetchAllServicesAdd();
    // }, [selectedOptionServiceCategoryAdd])
    // async function fetchAllServicesAdd() {
    //     try {
    //         const response = await axios.post(`${API_URL}/get_all_services_basedon_catId_Add`,
    //             { token, selectedOptionServiceCategoryAdd });
    //         console.log("all services", response.data.data)
    //         if (response.data.success) {
    //             setServiceDetailsAdd(response.data.data)
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    useEffect(() => {
        fetchAllServicesView();
        // fetchAllServices();
    }, [selectedOptionServiceCategoryView])
    async function fetchAllServicesView() {
        try {
            const response = await axios.post(`${API_URL}/get_all_services_basedon_catId_view`,
                { token, selectedOptionServiceCategoryView });
            console.log("all services", response.data.data)
            if (response.data.success) {
                setServiceDetailsView(response.data.data)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    // async function fetchServices() {

    //     try {
    //         const response = await axios.post(`${API_URL}/get_all_services`, { token });
    //         console.log(response.data.data)
    //         if (response.data.success) {
    //             setAllServices(response.data.data)
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }
    async function fetchAllCategories() {

        try {
            const response = await axios.post(`${API_URL}/get_all_categories`, { token });
            console.log(response.data.data)
            if (response.data.success) {
                setCategoryDetails(response.data.data)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    async function fetchAllServiceProviders() {

        try {
            const response = await axios.post(`${API_URL}/get_all_sp`, { token });
            console.log(response.data.data)
            if (response.data.success) {
                setSpDetails(response.data.data)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    async function fetchAllServices() {
        try {
            const response = await axios.post(`${API_URL}/get_all_services_basedon_catId`, { token, selectedOptionServiceCategoryEdit });
            console.log("all services", response.data.data)
            if (response.data.success) {
                setServiceDetails(response.data.data)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    async function fetchAllSearchTagServices() {
        try {
            const response = await axios.post(`${API_URL}/get_all_services_searchtag`, { token });
            console.log("all services", response.data.data)
            if (response.data.success) {
                setServiceSearchTagDetails(response.data.data)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    const handleUpdateCategory = async () => {
        try {
            const response = await axios.post(`${API_URL}/update_category`, { token, selectedOptionCategory, textFieldValueCategory });
            console.log(response)
            fetchAllCategories();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdateService = async () => {
        try {
            const response = await axios.post(`${API_URL}/update_service`, { token, selectedOptionServiceEdit, textFieldValueService });
            console.log(response)
            // fetchAllCategories();
            fetchAllServices();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddCategory = async () => {
        try {
            const response = await axios.post(`${API_URL}/add_category`, { token, addedCategory });
            console.log(response);
            fetchAllCategories();
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleAddTypes = async () => {
        try {
            const response = await axios.post(`${API_URL}/add_types`, { token, textFieldAddedType, selectedOptionTypeServiceId });
            console.log(response);
            fetchAllCategories();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddService = async () => {
        console.log(token, selectedOptionServiceCategoryAdd, textFieldValueServiceAdd, textFieldValueServiceProfessionAdd)
        try {
            const response = await axios.post(`${API_URL}/add_service`, { token, selectedOptionServiceCategoryAdd, textFieldValueServiceAdd, textFieldValueServiceProfessionAdd });
            console.log(response);
            fetchAllCategories();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // async function fetchAllServices() {

    //     try {
    //         const response = await axios.post(`${API_URL}/get_all_services`, { token });
    //         console.log(response.data.data)
    //         if (response.data.success) {
    //             setTypeDetails(response.data.data)
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    const handleDropdownChangeTypeCategoryView = (event) => {

        const value = event.target.value;
        const selectedOption = categoryDetails.find(option => option.service_category_id === value);
        // const prof = selectedOption ? selectedOption.professional_name : 'Not Found';
        const textContent = selectedOption ? selectedOption.service_category : '';
        setSelectedOptionTypeCategoryId(value);
        // setSelectedOptionServiceAdd(value); // Update selected dropdown value
        // setTextFieldValueServiceAdd(textContent);
        // setTextFieldValueServiceProfessionAdd(prof)
    };
    // async function fetchAllServicesType() {
    //     try {
    //         const response = await axios.post(`${API_URL}/get_all_services_basedon_catId_type`,
    //             { token, selectedOptionTypeCategoryId });
    //         console.log("all services", response.data.data)
    //         if (response.data.success) {
    //             // setServiceDetailsView(response.data.data)
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    const fetchAllServicesType = async () => {

        try {
            const response = await axios.post(`${API_URL}/get_all_services_basedon_catId_type`,
                { token, selectedOptionTypeCategoryId });
            console.log("all services", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllServicesType();
            console.log(data)
            setServiceDetailsType(data);
        };

        fetchData();
    }, [selectedOptionTypeCategoryId]);


    const handleDropdownChangeTypeServiceView = (event) => {

        const value = event.target.value;
        const selectedOption = serviceDetailsType.find(option => option.service_id === value);
        const prof = selectedOption ? selectedOption.professional_name : 'Not Found';
        const textContent = selectedOption ? selectedOption.services : '';
        setSelectedOptionTypeServiceId(value);



        // setSelectedOptionServiceAdd(value); // Update selected dropdown value
        // setTextFieldValueServiceAdd(textContent);
        // setTextFieldValueServiceProfessionAdd(prof)
    };
    const fetchAllTypesType = async () => {

        try {
            const response = await axios.post(`${API_URL}/get_all_types_basedon_serviceId_type`,
                { token, selectedOptionTypeServiceId });
            console.log("all services", response.data.data);
            if (response.data.success) {
                return response.data.data;
            } else {
                console.error('Error:', response.data.error);
                return [];
            }
        } catch (error) {
            console.error('Error:', error.message);
            return [];
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllTypesType();
            console.log(data)
            setTypesDetailsType(data);
        };

        fetchData();
    }, [selectedOptionTypeServiceId]);

    const handleDropdownChangeTypeTypeView = (event) => {

        const value = event.target.value;
        const selectedOption = typesDetailsType.find(option => option.types_id === value);
        // const prof = selectedOption ? selectedOption.professional_name : 'Not Found';
        const textContent = selectedOption ? selectedOption.types : '';
        setSelectedOptionTypeTypeId(value);
        setTextFieldValueTypes(textContent);

        // setSelectedOptionServiceAdd(value); // Update selected dropdown value
        // setTextFieldValueServiceAdd(textContent);
        // setTextFieldValueServiceProfessionAdd(prof)
    };
    const handleUpdateTypes = async () => {
        try {
            const response = await axios.post(`${API_URL}/update_types`, { token, selectedOptionTypeServiceId, textFieldValueTypes });
            console.log(response)
            fetchAllCategories();
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        fetchAllSearchTagServices();
    }, []);

    const handleSaveChanges = async () => {
        const updatedValues = {};
        Object.keys(editableSearchTags).forEach(searchTagId => {
            updatedValues[searchTagId] = editableSearchTags[searchTagId].join(',');
        });
        console.log('Updated values:', updatedValues);
        try {
            const response = await axios.post(`${API_URL}/update_service_search_tags`, {
                token, updated_values: updatedValues
            });
            if (response.data.success) {
                console.log('Tags updated successfully');
                // Optionally, fetch the updated tags again
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    // const handleSaveChangesEdit = async () => {
    //     const updatedValues = {};
    //     Object.keys(editableSearchTags).forEach(searchTagId => {
    //         updatedValues[searchTagId] = editableSearchTags[searchTagId].join(',');
    //     });
    //     console.log('Updated values:', updatedValues);
    //     try {
    //         const response = await axios.post(`${API_URL}/update_service_search_tags`, {
    //             token, updated_values: updatedValues
    //         });
    //         if (response.data.success) {
    //             console.log('Tags updated successfully');
    //             // Optionally, fetch the updated tags again
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // };

    const handleSaveChangesServiceAdd = async () => {

        console.log('Updated values:', textFieldAddedServiceSearchTag, selectedOptionSearchTagServiceId);
        let searchTagId;
        if (searchTagDetailsService.length > 0) {
            searchTagId = searchTagDetailsService[0].search_tag_id;
            console.log("no data found", searchTagId);
        }


        try {
            const response = await axios.post(`${API_URL}/add_service_search_tags`, {
                token, textFieldAddedServiceSearchTag, selectedOptionSearchTagServiceId, searchTagId
            });
            if (response.data.success) {
                console.log('Tags added successfully');
                // Optionally, fetch the updated tags again
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleSaveChangesSpAdd = async () => {

        console.log('Updated values:', textFieldAddedSpSearchTag, selectedSpId);
        let searchTagId;
        if (searchTagDetailsSp.length > 0) {
            searchTagId = searchTagDetailsSp[0].search_tag_id;
            console.log("no data found", searchTagId);
        }
        try {
            const response = await axios.post(`${API_URL}/add_sp_search_tags`, {
                token, textFieldAddedSpSearchTag, selectedSpId, searchTagId
            });
            if (response.data.success) {
                console.log('Tags added successfully');
                fetchAllSearchTagServices();
                // Optionally, fetch the updated tags again
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const handleSaveChangesTypesAdd = async () => {

        console.log('Updated values:', textFieldAddedTypesSearchTag, selectedOptionSearchTagServiceId);
        let searchTagId;
        if (searchTagDetailsTypes.length > 0) {
            searchTagId = searchTagDetailsTypes[0].search_tag_id;
            console.log("no data found", searchTagId);
        }
        try {
            const response = await axios.post(`${API_URL}/add_type_search_tags`, {
                token, textFieldAddedTypesSearchTag, selectedOptionSearchTagTypesId, searchTagId
            });
            if (response.data.success) {
                console.log('Tags added successfully');
                // Optionally, fetch the updated tags again
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
                <AdminHead />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '40px 0px' }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginLeft: '34px', mr: '34px', borderBottom: '1px solid #E6E6E6'
                }}>
                    <TypographyComponent sx={{ fontSize: '24px', fontWeight: 400, fontColor: Colors.black_80 }}>
                        Categories
                    </TypographyComponent>
                    <IconButton
                        onClick={handleCategoriesExpandClick}
                    // aria-label="expand"
                    // size="large"
                    // color="primary"
                    // edge="end"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </Box>
                {isCategoriesExpanded && (
                    <Box sx={{
                        width: '100%', display: 'flex', flexDirection: 'column',

                    }}>
                        {/* Tabs for view, edit, add */}
                        <Tabs value={activeTabCategory}
                            onChange={handleCategoryTabChange} aria-label="tabs"
                            sx={{
                                mt: '8px', marginLeft: '34px', mr: '34px',
                                '& .MuiTabs-root': {
                                    borderBottom: '1px solid #E6E6E6',
                                    borderColor: 'divider',
                                    opacity: 0,
                                    marginBottom: '10px', // Add margin between tabs and content
                                },
                                '& .MuiTabs-indicator': {
                                    display: 'none', // Hide the indicator (underline)
                                },
                                '& .Mui-selected': {

                                    color: Colors.black_80,
                                },
                            }}>
                            <Tab label="View" value="view" />
                            <Tab label="Edit" value="edit" />
                            <Tab label="Add" value="add" />
                        </Tabs>
                        {/* Content based on active tab */}
                        <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                            {activeTabCategory === 'view' && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                    {categoryDetails.map(category => (
                                        <TypographyComponent key={category.service_category_id}>
                                            {category.service_category}
                                        </TypographyComponent>
                                    ))}
                                </Box>

                            )}
                            {activeTabCategory === 'edit' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '50%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select category
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionCategory}
                                                onChange={handleDropdownChangeCategory}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={categoryDetails.map(result => ({
                                                    value: result.service_category_id,
                                                    label: result.service_category
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        {/* Text Field */}
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Update category
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='update category'
                                                value={textFieldValueCategory}
                                                onChange={handleTextFieldChangeCategory}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onClick={() => setIsCategoriesExpanded(!isCategoriesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleUpdateCategory}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}
                            {activeTabCategory === 'add' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '50%', gap: '8px', flexDirection: 'row' }}>

                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Add category
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='Add category'
                                                value={addedCategory}
                                                onChange={handleTextFieldChangeAddCategory}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onClick={() => setIsCategoriesExpanded(!isCategoriesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleAddCategory}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginLeft: '34px', mr: '34px', borderBottom: '1px solid #E6E6E6'
                }}>
                    <TypographyComponent sx={{
                        fontSize: '24px', fontWeight: 400,
                        fontColor: Colors.black_80
                    }}>
                        Services
                    </TypographyComponent>
                    <IconButton
                        onClick={handleServicesExpandClick}
                    // aria-label="expand"
                    // size="large"
                    // color="primary"
                    // edge="end"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </Box>
                {isServicesExpanded && (
                    <Box sx={{
                        width: '100%', display: 'flex', flexDirection: 'column',

                    }}>
                        {/* Tabs for view, edit, add */}
                        <Tabs value={activeTabService}
                            onChange={handleServiceTabChange} aria-label="tabs"
                            sx={{
                                mt: '8px', marginLeft: '34px', mr: '34px',
                                '& .MuiTabs-root': {
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    opacity: 0,
                                    marginBottom: '10px', // Add margin between tabs and content
                                },
                                '& .MuiTabs-indicator': {
                                    display: 'none', // Hide the indicator (underline)
                                },
                                '& .Mui-selected': {
                                    backgroundColor: '#e0e0e0', // Background color for selected tab
                                    color: Colors.black_80,
                                },
                            }}>
                            <Tab label="View" value="view" />
                            <Tab label="Edit" value="edit" />
                            <Tab label="Add" value="add" />
                        </Tabs>
                        {/* Content based on active tab */}
                        <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                            {activeTabService === 'view' && (
                                <Box sx={{ display: 'flex', width: '50%', gap: '8px', flexDirection: 'row', mb: '34px' }}>
                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                        <TypographyComponent>
                                            Select category
                                        </TypographyComponent>
                                        <SelectComponent
                                            value={selectedOptionServiceCategoryView}
                                            onChange={handleDropdownChangeServiceCategoryView}
                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                            options={categoryDetails.map(result => ({
                                                value: result.service_category_id,
                                                label: result.service_category
                                            }))}
                                        // error={!!serviceError} 
                                        >
                                        </SelectComponent>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', }}>
                                        {serviceDetailsView.map(service => (
                                            <TypographyComponent key={service.service_id}>
                                                {service.services}
                                            </TypographyComponent>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                            {activeTabService === 'edit' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '75%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select category
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionServiceCategoryEdit}
                                                onChange={handleDropdownChangeServiceCategoryEdit}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={categoryDetails.map(result => ({
                                                    value: result.service_category_id,
                                                    label: result.service_category
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select service
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionServiceEdit}
                                                onChange={handleDropdownChangeServiceEdit}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={serviceDetails.map(result => ({
                                                    value: result.service_id,
                                                    label: result.services
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Update service
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='update service'
                                                value={textFieldValueService}
                                                onChange={handleTextFieldChangeService}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onclick={() => setIsServicesExpanded(!isServicesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleUpdateService}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}
                            {activeTabService === 'add' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '75%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select category
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionServiceCategoryAdd}
                                                onChange={handleDropdownChangeServiceCategoryAdd}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={categoryDetails.map(result => ({
                                                    value: result.service_category_id,
                                                    label: result.service_category
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        {/* <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                       <TypographyComponent>
                                           Select service
                                       </TypographyComponent>
                                       <SelectComponent
                                           value={selectedOptionServiceAdd}
                                           onChange={handleDropdownChangeServiceAdd}
                                           // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                           options={serviceDetailsAdd.map(result => ({
                                               value: result.service_id,
                                               label: result.services
                                           }))}
                                       // error={!!serviceError} 
                                       >
                                       </SelectComponent>
                                   </Box> */}
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Add service
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='add service'
                                                value={textFieldValueServiceAdd}
                                                onChange={handleTextFieldChangeServiceAdd}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Add professional name
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='add professional name'
                                                value={textFieldValueServiceProfessionAdd}
                                                onChange={handleTextFieldChangeProfessionAdd}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onclick={() => setIsServicesExpanded(!isServicesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleAddService}
                                        >
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginLeft: '34px', mr: '34px', borderBottom: '1px solid #E6E6E6'
                }}>
                    <TypographyComponent sx={{ fontSize: '24px', fontWeight: 400, fontColor: Colors.black_80 }}>
                        Type of services
                    </TypographyComponent>
                    <IconButton
                        onClick={handleTypesExpandClick}
                    // aria-label="expand"
                    // size="large"
                    // color="primary"
                    // edge="end"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </Box>
                {isTypesExpanded && (
                    <Box sx={{
                        width: '100%', display: 'flex', flexDirection: 'column',

                    }}>
                        {/* Tabs for view, edit, add */}
                        <Tabs value={activeTabTypes}
                            onChange={handleTypesTabChange} aria-label="tabs"
                            sx={{
                                mt: '8px', marginLeft: '34px', mr: '34px',
                                '& .MuiTabs-root': {
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    opacity: 0,
                                    marginBottom: '10px', // Add margin between tabs and content
                                },
                                '& .MuiTabs-indicator': {
                                    display: 'none', // Hide the indicator (underline)
                                },
                                '& .Mui-selected': {
                                    backgroundColor: '#e0e0e0', // Background color for selected tab
                                    color: Colors.black_80,
                                },
                            }}>
                            <Tab label="View" value="view" />
                            <Tab label="Edit" value="edit" />
                            <Tab label="Add" value="add" />
                        </Tabs>
                        {/* Content based on active tab */}
                        <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                            {activeTabTypes === 'view' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '75%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select category
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeCategoryId}
                                                onChange={handleDropdownChangeTypeCategoryView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={categoryDetails.map(result => ({
                                                    value: result.service_category_id,
                                                    label: result.service_category
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select service
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeServiceId}
                                                onChange={handleDropdownChangeTypeServiceView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={serviceDetailsType.map(result => ({
                                                    value: result.service_id,
                                                    label: result.services
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', }}>
                                            {typesDetailsType.map(type => (
                                                <TypographyComponent key={type.types_id}>
                                                    {type.types}
                                                </TypographyComponent>
                                            ))}
                                        </Box>
                                        {/* <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                        <TypographyComponent>
                                            Update service
                                        </TypographyComponent>
                                        <TextFieldComponent
                                            placeholder='update service'
                                            value={textFieldValueService}
                                            onChange={handleTextFieldChangeService}
                                            sx={{ marginBottom: '10px', width: '420px' }}>
                                        </TextFieldComponent>
                                    </Box> */}
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onclick={() => setIsTypesExpanded(!isTypesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleUpdateService}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>

                            )}
                            {activeTabTypes === 'edit' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '100%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select category
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeCategoryId}
                                                onChange={handleDropdownChangeTypeCategoryView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={categoryDetails.map(result => ({
                                                    value: result.service_category_id,
                                                    label: result.service_category
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select service
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeServiceId}
                                                onChange={handleDropdownChangeTypeServiceView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={serviceDetailsType.map(result => ({
                                                    value: result.service_id,
                                                    label: result.services
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select types
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeTypeId}
                                                onChange={handleDropdownChangeTypeTypeView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={typesDetailsType.map(result => ({
                                                    value: result.types_id,
                                                    label: result.types
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Update types
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='update service'
                                                value={textFieldValueTypes}
                                                onChange={handleTextFieldChangeTypes}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onclick={() => setIsTypesExpanded(!isTypesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleUpdateTypes}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}
                            {activeTabTypes === 'add' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '100%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select category
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeCategoryId}
                                                onChange={handleDropdownChangeTypeCategoryView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={categoryDetails.map(result => ({
                                                    value: result.service_category_id,
                                                    label: result.service_category
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select service
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedOptionTypeServiceId}
                                                onChange={handleDropdownChangeTypeServiceView}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={serviceDetailsType.map(result => ({
                                                    value: result.service_id,
                                                    label: result.services
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>
                                        </Box>
                                        {/* <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                        <TypographyComponent>
                                            Select types
                                        </TypographyComponent>
                                        <SelectComponent
                                            value={selectedOptionTypeTypeId}
                                            onChange={handleDropdownChangeTypeTypeView}
                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                            options={typesDetailsType.map(result => ({
                                                value: result.types_id,
                                                label: result.types
                                            }))}
                                        // error={!!serviceError} 
                                        >
                                        </SelectComponent>
                                    </Box> */}
                                        <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Add type of service
                                            </TypographyComponent>
                                            <TextFieldComponent
                                                placeholder='add type of service'
                                                value={textFieldAddedType}
                                                onChange={handleTextFieldAddTypes}
                                                sx={{ marginBottom: '10px', width: '420px' }}>
                                            </TextFieldComponent>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onclick={() => setIsTypesExpanded(!isTypesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleAddTypes}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginLeft: '34px', mr: '34px', borderBottom: '1px solid #E6E6E6'
                }}>
                    <TypographyComponent sx={{ fontSize: '24px', fontWeight: 400, fontColor: Colors.black_80 }}>
                        service providers
                    </TypographyComponent>
                    <IconButton
                        onClick={handleSPExpandClick}
                    // aria-label="expand"
                    // size="large"
                    // color="primary"
                    // edge="end"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </Box>
                {isSPExpanded && (

                    <Box sx={{
                        width: '100%', display: 'flex', flexDirection: 'column',

                    }}>
                        {/* Tabs for view, edit, add */}
                        <Tabs value={spActiveTab}
                            onChange={spTabChange} aria-label="tabs"
                            sx={{
                                mt: '8px', marginLeft: '34px', mr: '34px',
                                '& .MuiTabs-root': {
                                    borderBottom: '1px solid #E6E6E6',
                                    borderColor: 'divider',
                                    opacity: 0,
                                    marginBottom: '10px', // Add margin between tabs and content
                                },
                                '& .MuiTabs-indicator': {
                                    display: 'none', // Hide the indicator (underline)
                                },
                                '& .Mui-selected': {

                                    color: Colors.black_80,
                                },
                            }}>
                            <Tab label="View" value="view" />
                            <Tab label="Edit" value="edit" />
                        </Tabs>
                        {/* Content based on active tab */}
                        <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', mb: '34px' }}>
                            {spActiveTab === 'view' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '5%' }}>
                                    <Box sx={{ display: 'flex', width: '20%', flexDirection: 'column' }}>
                                        <TypographyComponent>
                                            Select service provider
                                        </TypographyComponent>
                                        <SelectComponent
                                            value={selectedServiceProviderId}
                                            onChange={handleChangeServiceProviders}
                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                            options={spDetails.map(result => ({
                                                value: result.service_provider_id,
                                                label: `${result.business_name} (${result.service_provider_code})`
                                            }))}
                                        // error={!!serviceError} 
                                        >
                                        </SelectComponent>

                                    </Box>


                                    <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                        {spData.map(spdata => (
                                            <Box key={spdata.service_provider_id} sx={{ marginBottom: '20px' }}>
                                                <TypographyComponent>
                                                    <strong>Name:</strong> {spdata.business_name}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Email:</strong> {spdata.email}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Country Code:</strong> {spdata.country_code}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Phone Number:</strong> {spdata.phone_number}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Province:</strong> {spdata.province}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Postal Code:</strong> {spdata.postal_code}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Working Radius:</strong> {spdata.working_radius}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Country:</strong> {spdata.country}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Active:</strong> {spdata.active}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Contact Person:</strong> {spdata.contact_person}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Services:</strong> {spdata.services}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Types:</strong> {spdata.types}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>About us:</strong> {spdata.about_us}
                                                </TypographyComponent>
                                                <TypographyComponent>
                                                    <strong>Certifications:</strong> {spdata.certification}
                                                </TypographyComponent>

                                            </Box>
                                        ))}

                                    </Box>
                                </Box>


                                //  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                //                 {spData.map(spdata => (
                                //                     <Box key={spdata.service_provider_id} sx={{ marginBottom: '20px' }}>
                                //                         <TypographyComponent>
                                //                             <strong>Name:</strong> {spdata.business_name}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Email:</strong> {spdata.email}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Country Code:</strong> {spdata.country_code}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Phone Number:</strong> {spdata.phone_number}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Province:</strong> {spdata.province}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Postal Code:</strong> {spdata.postal_code}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Working Radius:</strong> {spdata.working_radius}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Country:</strong> {spdata.country}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Active:</strong> {spdata.active}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Contact Person:</strong> {spdata.contact_person}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Services:</strong> {spdata.services}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Types:</strong> {spdata.types}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>About us:</strong> {spdata.about_us}
                                //                         </TypographyComponent>
                                //                         <TypographyComponent>
                                //                             <strong>Certifications:</strong> {spdata.certification}
                                //                         </TypographyComponent>

                                //                     </Box>
                                //                 ))}

                                //             </Box> 

                            )}
                            {spActiveTab === 'edit' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', width: '100%', gap: '8px', flexDirection: 'row' }}>
                                        <Box sx={{ display: 'flex', width: '25%', flexDirection: 'column' }}>
                                            <TypographyComponent>
                                                Select service provider
                                            </TypographyComponent>
                                            <SelectComponent
                                                value={selectedServiceProviderId}
                                                onChange={handleChangeServiceProviders}
                                                // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                options={spDetails.map(result => ({
                                                    value: result.service_provider_id,
                                                    label: `${result.business_name} (${result.service_provider_code})`
                                                }))}
                                            // error={!!serviceError} 
                                            >
                                            </SelectComponent>

                                        </Box>
                                        <Box sx={{ padding: 2, width: '60%' }}>


                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column',
                                                marginBottom: '20px'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Name:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='add name'
                                                        value={businessName}
                                                        onChange={handleTextFieldName}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Email:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='email'
                                                        value={email}
                                                        onChange={handleTextFieldEmail}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Phone number:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='Phone number'
                                                        value={phoneNumber}
                                                        onChange={handleTextFieldPhone}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Provine:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='Province'
                                                        value={province}
                                                        onChange={handleTextFieldProvince}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Postal code:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='Postal code'
                                                        value={postalCode}
                                                        onChange={handleTextFieldPostal}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Working radius:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='Working radius'
                                                        value={workingRadius}
                                                        onChange={handleTextFieldworking}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Country:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='Country'
                                                        value={country}
                                                        onChange={handleTextFieldCountry}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>Contact person:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='Contact person '
                                                        value={contactPerson}
                                                        onChange={handleTextFieldContact}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', gap: '8px',
                                                    justifyContent: 'center', alignItems: 'ceneter'
                                                }}>
                                                    <TypographyComponent>
                                                        <strong>About us:</strong>
                                                    </TypographyComponent>
                                                    <TextFieldComponent
                                                        placeholder='About us'
                                                        value={aboutUs}
                                                        onChange={handleTextFieldAbout}
                                                        sx={{ marginBottom: '10px', width: '420px' }}>
                                                    </TextFieldComponent>
                                                </Box>




                                            </Box>


                                        </Box>


                                    </Box>
                                    <Box sx={{ display: 'flex', width: '60%', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                                        <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                            onClick={() => setIsCategoriesExpanded(!isCategoriesExpanded)}>
                                            Cancel
                                        </ButtonComponentWhite>
                                        <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                            onClick={handleUpdateServiceProvider}>
                                            Save
                                        </ButtonComponent>
                                    </Box>
                                </Box>
                            )}

                        </Box>
                    </Box>


                )}

                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    marginLeft: '34px', mr: '34px', borderBottom: '1px solid #E6E6E6'
                }}>
                    <TypographyComponent sx={{ fontSize: '24px', fontWeight: 400, fontColor: Colors.black_80 }}>
                        search tags
                    </TypographyComponent>
                    <IconButton
                        onClick={handleSearchTagExpandClick}
                    // aria-label="expand"
                    // size="large"
                    // color="primary"
                    // edge="end"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </Box>
                {isSearchTagExpanded && (
                    <Box sx={{
                        width: '100%', display: 'flex', flexDirection: 'column',

                    }}>
                        {/* Tabs for view, edit, add */}
                        <Tabs value={activeTabSearchTag}
                            onChange={handleSearchTagTabChange} aria-label="tabs"
                            sx={{
                                mt: '8px',
                                marginLeft: '34px',
                                mr: '34px',
                                '& .MuiTabs-indicator': {
                                    backgroundColor: Colors.black_80, // Set the desired color for the bottom line
                                    height: '3px', // Set the desired height for the bottom line
                                },
                                '& .MuiTab-root': {
                                    textTransform: 'none',
                                    color: Colors.black_80, // Default text color
                                    '&.Mui-selected': {
                                        backgroundColor: 'transparent', // Remove the background color for the selected tab
                                        color: Colors.black_80, // Ensure the text color remains consistent
                                    },
                                },
                                '& .MuiTabs-root': {
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    opacity: 0,
                                    marginBottom: '10px', // Add margin between tabs and content
                                },
                            }}
                        >
                            <Tab label="Services" value="service" />
                            <Tab label="Types of services" value="types" />
                            <Tab label="Service provider" value="service provider" />
                        </Tabs>
                        {/* Content based on active tab */}
                        <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                            {activeTabSearchTag === 'service' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Tabs value={activeTabSub}
                                        onChange={handleSubTabChange} aria-label="tabs"
                                        sx={{
                                            mt: '8px', marginLeft: '34px', mr: '34px',
                                            '& .MuiTabs-root': {
                                                borderBottom: '1px solid',
                                                borderColor: 'divider',
                                                opacity: 0,
                                                marginBottom: '10px', // Add margin between tabs and content
                                            },
                                            '& .MuiTabs-indicator': {
                                                display: 'none', // Hide the indicator (underline)
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#e0e0e0', // Background color for selected tab
                                                color: Colors.black_80,
                                            },
                                        }}>
                                        <Tab label="View" value="view" />
                                        <Tab label="Edit" value="edit" />
                                        <Tab label="Add" value="add" />
                                    </Tabs>



                                    <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                                        {activeTabSub === 'view' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                    <TypographyComponent>
                                                        Select service
                                                    </TypographyComponent>
                                                    <SelectComponent
                                                        value={selectedOptionSearchTagServiceId}
                                                        onChange={handleDropdownChangeSearchTagServiceView}
                                                        // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                        options={serviceSearchTagDetails.map(result => ({
                                                            value: result.service_id,
                                                            label: result.services
                                                        }))}
                                                    // error={!!serviceError} 
                                                    >
                                                    </SelectComponent>

                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                    {searchTagDetailsService.map(searchTag => (
                                                        <TypographyComponent key={searchTag.search_tag_id}>
                                                            {searchTag.search_tags}
                                                        </TypographyComponent>
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                        {activeTabSub === 'edit' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select service
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedOptionSearchTagServiceId}
                                                            onChange={handleDropdownChangeSearchTagServiceView}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={serviceSearchTagDetails.map(result => ({
                                                                value: result.service_id,
                                                                label: result.services
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                        {searchTagDetailsService.map(searchTag => (
                                                            <Box key={searchTag.search_tag_id} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                                {Array.isArray(editableSearchTags[searchTag.search_tag_id]) &&
                                                                    editableSearchTags[searchTag.search_tag_id].map((tag, index) => (
                                                                        <TextField
                                                                            key={index}
                                                                            value={tag}
                                                                            onChange={(e) => handleSearchTagChange(searchTag.search_tag_id, index, e.target.value)}
                                                                        />
                                                                    ))
                                                                }

                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>

                                                    </Box>

                                                </Box>
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '4px' }}>
                                                    <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                                        onclick={() => setIsSearchTagExpanded(!isSearchTagExpanded)}>
                                                        Cancel
                                                    </ButtonComponentWhite>
                                                    <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                                        onClick={handleSaveChanges}
                                                    >
                                                        Save
                                                    </ButtonComponent>
                                                </Box>
                                            </Box>
                                        )}

                                        {activeTabSub === 'add' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select service
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedOptionSearchTagServiceId}
                                                            onChange={handleDropdownChangeSearchTagServiceView}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={serviceSearchTagDetails.map(result => ({
                                                                value: result.service_id,
                                                                label: result.services
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>
                                                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}> */}
                                                    {/* {searchTagDetailsService.map(searchTag => ( */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', }}>
                                                        {searchTagDetailsService.map(searchTag => (
                                                            <TypographyComponent key={searchTag.search_tag_id}>
                                                                {searchTag.search_tags}
                                                            </TypographyComponent>
                                                        ))}
                                                    </Box>
                                                    {/* ))} */}
                                                    {/* </Box> */}
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Add search tag
                                                        </TypographyComponent>
                                                        <TextFieldComponent
                                                            placeholder='add search tag'
                                                            value={textFieldAddedServiceSearchTag}
                                                            onChange={handleTextFieldAddServiceSearchTag}
                                                            sx={{ marginBottom: '10px', width: '420px' }}>
                                                        </TextFieldComponent>
                                                    </Box>

                                                </Box>
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '4px' }}>
                                                    <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                                        onclick={() => setIsSearchTagExpanded(!isSearchTagExpanded)}>
                                                        Cancel
                                                    </ButtonComponentWhite>
                                                    <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                                        onClick={handleSaveChangesServiceAdd}
                                                    >
                                                        Save
                                                    </ButtonComponent>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                            )}
                            {activeTabSearchTag === 'types' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Tabs value={activeTabSub}
                                        onChange={handleSubTabChange} aria-label="tabs"
                                        sx={{
                                            mt: '8px', marginLeft: '34px', mr: '34px',
                                            '& .MuiTabs-root': {
                                                borderBottom: '1px solid',
                                                borderColor: 'divider',
                                                opacity: 0,
                                                marginBottom: '10px', // Add margin between tabs and content
                                            },
                                            '& .MuiTabs-indicator': {
                                                display: 'none', // Hide the indicator (underline)
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#e0e0e0', // Background color for selected tab
                                                color: Colors.black_80,
                                            },
                                        }}>
                                        <Tab label="View" value="view" />
                                        <Tab label="Edit" value="edit" />
                                        <Tab label="Add" value="add" />
                                    </Tabs>

                                    <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                                        {activeTabSub === 'view' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                    <TypographyComponent>
                                                        Select service
                                                    </TypographyComponent>
                                                    <SelectComponent
                                                        value={selectedOptionSearchTagTypesServiceId}
                                                        onChange={SearchTagTypesViewServiceDropdown}
                                                        // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                        options={serviceSearchTagDetails.map(result => ({
                                                            value: result.service_id,
                                                            label: result.services
                                                        }))}
                                                    // error={!!serviceError} 
                                                    >
                                                    </SelectComponent>

                                                </Box>
                                                <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                    <TypographyComponent>
                                                        Select types
                                                    </TypographyComponent>
                                                    <SelectComponent
                                                        value={selectedOptionSearchTagTypesId}
                                                        onChange={handleDropdownChangeSearchTagTypesView}
                                                        // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                        options={typesSearchTagDetails.map(result => ({
                                                            value: result.types_id,
                                                            label: result.types
                                                        }))}
                                                    // error={!!serviceError} 
                                                    >
                                                    </SelectComponent>

                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                    {searchTagDetailsTypes.map(searchTag => (
                                                        <TypographyComponent key={searchTag.search_tag_id}>
                                                            {searchTag.search_tags}
                                                        </TypographyComponent>
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                        {activeTabSub === 'edit' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select service
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedOptionSearchTagTypesServiceId}
                                                            onChange={SearchTagTypesViewServiceDropdown}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={serviceSearchTagDetails.map(result => ({
                                                                value: result.service_id,
                                                                label: result.services
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select types
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedOptionSearchTagTypesId}
                                                            onChange={handleDropdownChangeSearchTagTypesView}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={typesSearchTagDetails.map(result => ({
                                                                value: result.types_id,
                                                                label: result.types
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                        {searchTagDetailsTypes.map(searchTag => (
                                                            <Box key={searchTag.search_tag_id} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                                {Array.isArray(editableSearchTags[searchTag.search_tag_id]) &&
                                                                    editableSearchTags[searchTag.search_tag_id].map((tag, index) => (
                                                                        <TextField
                                                                            key={index}
                                                                            value={tag}
                                                                            onChange={(e) => handleSearchTagChange(searchTag.search_tag_id, index, e.target.value)}
                                                                        />
                                                                    ))
                                                                }

                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>

                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '4px' }}>
                                                    <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                                        onclick={() => setIsSearchTagExpanded(!isSearchTagExpanded)}>
                                                        Cancel
                                                    </ButtonComponentWhite>
                                                    <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                                        onClick={handleSaveChanges}
                                                    >
                                                        Save
                                                    </ButtonComponent>
                                                </Box>
                                            </Box>
                                        )}

                                        {activeTabSub === 'add' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select service
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedOptionSearchTagTypesServiceId}
                                                            onChange={SearchTagTypesViewServiceDropdown}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={serviceSearchTagDetails.map(result => ({
                                                                value: result.service_id,
                                                                label: result.services
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select types
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedOptionSearchTagTypesId}
                                                            onChange={handleDropdownChangeSearchTagTypesView}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={typesSearchTagDetails.map(result => ({
                                                                value: result.types_id,
                                                                label: result.types
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>

                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Add search tag
                                                        </TypographyComponent>
                                                        <TextFieldComponent
                                                            id="add_type_tag"
                                                            placeholder='add search tag'
                                                            value={textFieldAddedTypesSearchTag}
                                                            onChange={handleTextFieldAddTypesSearchTag}
                                                            sx={{ marginBottom: '10px', width: '420px' }}>
                                                        </TextFieldComponent>
                                                    </Box>

                                                </Box>
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '4px' }}
                                                    onclick={handleSearchTagExpandClick}>
                                                    <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                                        onclick={() => setIsSearchTagExpanded(!isSearchTagExpanded)}>
                                                        Cancel
                                                    </ButtonComponentWhite>
                                                    <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                                        onClick={handleSaveChangesTypesAdd}
                                                    >
                                                        Save
                                                    </ButtonComponent>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}
                            {activeTabSearchTag === 'service provider' && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Tabs value={activeTabSp}
                                        onChange={handleSpTabChange} aria-label="tabs"
                                        sx={{
                                            mt: '8px', marginLeft: '34px', mr: '34px',
                                            '& .MuiTabs-root': {
                                                borderBottom: '1px solid',
                                                borderColor: 'divider',
                                                opacity: 0,
                                                marginBottom: '10px', // Add margin between tabs and content
                                            },
                                            '& .MuiTabs-indicator': {
                                                display: 'none', // Hide the indicator (underline)
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#e0e0e0', // Background color for selected tab
                                                color: Colors.black_80,
                                            },
                                        }}>
                                        <Tab label="View" value="view" />
                                        <Tab label="Edit" value="edit" />
                                        <Tab label="Add" value="add" />
                                    </Tabs>
                                    <Box sx={{ mt: '8px', marginLeft: '34px', mr: '34px', }}>
                                        {activeTabSp === 'view' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                    <TypographyComponent>
                                                        Select service provider
                                                    </TypographyComponent>
                                                    <SelectComponent
                                                        value={selectedSpId}
                                                        onChange={handleChangeSpList}
                                                        // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                        options={spDetails.map(result => ({
                                                            value: result.service_provider_id,
                                                            label: `${result.business_name} (${result.service_provider_code})`
                                                        }))}
                                                    // error={!!serviceError} 
                                                    >
                                                    </SelectComponent>

                                                </Box>
                                                <Box sx={{ display: 'flex', width: '420px', flexDirection: 'row' }}>
                                                    <TypographyComponent sx={{ width: '25%' }}>
                                                        <strong>search tags:
                                                        </strong>
                                                    </TypographyComponent>
                                                    {searchTagDetailsSp.length > 0 && searchTagDetailsSp[0].search_tags && (
                                                        <TypographyComponent sx={{ width: '60%' }}>
                                                            {searchTagDetailsSp[0].search_tags}
                                                        </TypographyComponent>
                                                    )}
                                                </Box>

                                                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                    {spData.map(spdata => (
                                                        <Box key={spdata.service_provider_id} sx={{ marginBottom: '20px' }}>
                                                            <TypographyComponent>
                                                                <strong>Name:</strong> {spdata.business_name}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Email:</strong> {spdata.email}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Country Code:</strong> {spdata.country_code}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Phone Number:</strong> {spdata.phone_number}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Province:</strong> {spdata.province}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Postal Code:</strong> {spdata.postal_code}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Working Radius:</strong> {spdata.working_radius}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Country:</strong> {spdata.country}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Active:</strong> {spdata.active}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Contact Person:</strong> {spdata.contact_person}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Services:</strong> {spdata.services}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Types:</strong> {spdata.types}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>About us:</strong> {spdata.about_us}
                                                            </TypographyComponent>
                                                            <TypographyComponent>
                                                                <strong>Certifications:</strong> {spdata.certification}
                                                            </TypographyComponent>

                                                        </Box>
                                                    ))}

                                                </Box> */}
                                            </Box>
                                        )}
                                        {activeTabSp === 'edit' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select service provider
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedSpId}
                                                            onChange={handleChangeSpList}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={spDetails.map(result => ({
                                                                value: result.service_provider_id,
                                                                label: `${result.business_name} (${result.service_provider_code})`
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>

                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                        {searchTagDetailsSp.map(searchTag => (
                                                            <Box key={searchTag.search_tag_id} sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                                {Array.isArray(editableSearchTags[searchTag.search_tag_id]) &&
                                                                    editableSearchTags[searchTag.search_tag_id].map((tag, index) => (
                                                                        <TextField
                                                                            key={index}
                                                                            value={tag}
                                                                            onChange={(e) => handleSearchTagChange(searchTag.search_tag_id, index, e.target.value)}
                                                                        />
                                                                    ))
                                                                }

                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>

                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '4px' }}>
                                                    <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                                        onclick={() => setIsSearchTagExpanded(!isSearchTagExpanded)}>
                                                        Cancel
                                                    </ButtonComponentWhite>
                                                    <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                                        onClick={handleSaveChanges}
                                                    >
                                                        Save
                                                    </ButtonComponent>
                                                </Box>
                                            </Box>
                                        )}
                                        {activeTabSp === 'add' && (
                                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Select service provider
                                                        </TypographyComponent>
                                                        <SelectComponent
                                                            value={selectedSpId}
                                                            onChange={handleChangeSpList}
                                                            // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                                            options={spDetails.map(result => ({
                                                                value: result.service_provider_id,
                                                                label: `${result.business_name} (${result.service_provider_code})`
                                                            }))}
                                                        // error={!!serviceError} 
                                                        >
                                                        </SelectComponent>

                                                    </Box>

                                                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '34px' }}>
                                                        {searchTagDetailsSp.map(searchTag => (
                                                            <Box key={searchTag.search_tag_id} sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                                {Array.isArray(editableSearchTags[searchTag.search_tag_id]) &&
                                                                    editableSearchTags[searchTag.search_tag_id].map((tag, index) => (
                                                                        <TextField
                                                                            key={index}
                                                                            value={tag}
                                                                            onChange={(e) => handleSearchTagChange(searchTag.search_tag_id, index, e.target.value)}
                                                                        />
                                                                    ))
                                                                }

                                                            </Box>
                                                        ))}
                                                    </Box> */}
                                                    <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                                        <TypographyComponent>
                                                            Add search tag
                                                        </TypographyComponent>
                                                        <TextFieldComponent
                                                            id="add_sp_tag"
                                                            placeholder='add search tag'
                                                            value={textFieldAddedSpSearchTag}
                                                            onChange={handleTextFieldAddSpSearchTag}
                                                            sx={{ marginBottom: '10px', width: '420px' }}>
                                                        </TextFieldComponent>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '4px' }}>
                                                    <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                                                        onclick={() => setIsSearchTagExpanded(!isSearchTagExpanded)}>
                                                        Cancel
                                                    </ButtonComponentWhite>
                                                    <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                                        onClick={handleSaveChangesSpAdd}
                                                    >
                                                        Save
                                                    </ButtonComponent>
                                                </Box>
                                            </Box>
                                        )}


                                    </Box>

                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Grid>
    )
}

export default AdminLanding;
