import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, useMediaQuery, useTheme } from "@mui/material";
import TypographyComponent from './TypographyComponent';
import profile from '../Images/profileIcon.png';
import { IMG_URL } from './Constants';
import bg1 from '../Images/bg1.jpg';
import bg2 from '../Images/bg2.jpg';
import bg3 from '../Images/bg3.jpg';
import bg4 from '../Images/bg4.jpg';
import bg5 from '../Images/bg5.jpg';
import Colors from '../Components/Colors';




function SpBusinessCardFront4(props) {
    const { serviceProviderDtls, serviceProviderOnchangeDtls, serviceDetails,
        selectedImage, serviceProviderQrCode, editbtn, backgroundColor, selectedBgButton,city } = props

        const colors = [ Colors.card_color_1,
            Colors.card_color_2,
            Colors.card_color_3,
            Colors.card_color_4,
            Colors.card_color_5];
            const cardColor = selectedBgButton ? selectedBgButton.replace('b', '') : '1';
            const profileIndex = parseInt(cardColor-1);
    
        const professionalNames = serviceDetails
        .filter(service => service.professional_name)
        .map(service => service.professional_name)
        .join(', ');
    
        let detail
    if (serviceDetails.length !== 0) {
        detail = serviceDetails[0];
      //  console.log(serviceDetails)
    }
 //   console.log("sp..4", serviceProviderDtls)
 //   console.log("backgroundColor  4@", backgroundColor)
 //   console.log("backgroundColor  4!", serviceProviderDtls.backgroundColor)
    const CardbgColor = selectedBgButton ? selectedBgButton :
        serviceProviderDtls.card_color;

    let CardbgImage;

    switch (CardbgColor) {
        case 'b1':
            CardbgImage = bg1;
            break;
        case 'b2':
            CardbgImage = bg2;
            break;
        case 'b3':
            CardbgImage = bg3;
            break;
        case 'b4':
            CardbgImage = bg4;
            break;
        case 'b5':
            CardbgImage = bg5;
            break;
        default:
            CardbgImage = bg1; // Default image if color doesn't match any case

    }
    const fontColor = Colors.white;
    const iconColor = Colors.white;
    function formatDate(dateString) {
        if (!dateString) {
            return ''; // or any default value you want to return
        }
        const parts = dateString.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    const handleClick = () => {
        // localStorage.setItem('businessCardEdit', 'true');
        // Check if editbtn is true before navigating

        const businessCardEdit = editbtn ? 'true' : 'false';
        window.location.href = `/profile-form/${businessCardEdit}`;

    }
    const theme = useTheme();
    // console.log("newprofile pic check   ,.,.,,.", newProfilePic)
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //  console.log("extrasmall", isXsScreen)
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //  console.log('small', isSmScreen)
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    //  console.log('med', isMdScreen)
    const token = localStorage.getItem('token');

    const avatarSize = isSmScreen ? '56px' : '90px';
    return (
        <Box sx={{
            width: 'calc(100% - 10%)', height: 'calc(100% - 48px)', display: 'flex', flexDirection: 'column', borderRadius: '16px',
            backgroundImage: `url(${CardbgImage})`, backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // padding: '18px 46px'
            pt:'30px',
            pb:'18px',
            pl:'5%',
            pr:'5%',
        }}>
            <Box sx={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'row', }}>
                <Box sx={{
                    width: '75%', height: '100%', display: 'flex', flexDirection: 'column',
                    alignItems: 'flex-start', justifyContent: 'center'

                }}>
                    <TypographyComponent sx={{
                        color: fontColor,
                        fontSize: { sm: '24px', md: '24px', xs: '16px' },
                        fontWeight: 700
                    }}>
                        {serviceProviderDtls.business_name}
                    </TypographyComponent>
                    <TypographyComponent sx={{
                                    color: Colors.white,
                                    fontSize: { sm: '16px', md: '16px', xs: '12px' },
                                    fontWeight: 400,
                                    maxWidth: '200px', /* Adjust this value as needed */
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {professionalNames}
                                </TypographyComponent>
                                {serviceProviderDtls.contact_person && <TypographyComponent sx={{
                                    color: Colors.white,
                                    fontSize: { sm: '16px', md: '16px', xs: '12px' }
                                    , fontWeight: 400
                                }}>
                                   Contact person: {serviceProviderDtls.contact_person}
                                </TypographyComponent>}

                    {/* <TypographyComponent sx={{ color: '#C5C5C5', fontSize: '12px' }}>
                        Joined by {formatDate(serviceProviderDtls.joined_date)}
                    </TypographyComponent>
                    <TypographyComponent sx={{ color: '#014C61', fontSize: '14px' }}>
                        {serviceProviderDtls.services}
                    </TypographyComponent> */}

                </Box>
              
                <Box sx={{ width: '25%', height: '100%', display: 'flex' , justifyContent:'center',alignItems:'center'}}>
                    {selectedImage ? (
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Profile"
                            sx={{
                                width: '100%',
                                minWidth: { sm: '82px', md: '82px', xs: '52px' },
                                maxHeight: { sm: '82px', md: '82px', xs: '52px' },
                                objectFit: 'cover',
                                borderRadius: '100px',
                            }}
                        />
                    ) : (
                        serviceProviderDtls?.profile_image ? (
                            <Box
                                component="img"
                                src={`${IMG_URL}${serviceProviderDtls.profile_image}`}
                                alt="Profile"
                                sx={{
                                    width: '100%',
                                    maxWidth: { sm: '82px', md: '82px', xs: '52px' },
                                    maxHeight: { sm: '82px', md: '82px', xs: '52px' },
                                    objectFit: 'cover',
                                    borderRadius: '100px',
                                }}
                            />
                        ) : (
                            // <Box
                            //     component="img"
                            //     src={profile}
                            //     alt="Profile"
                            //     sx={{
                            //         width: '100%',
                            //         objectFit: 'cover',
                            //         minWidth: { sm: '82px', md: '82px', xs: '52px' },
                            //         borderRadius: '100px',
                            //     }}
                            // />
                            <Box
                                    sx={{
                                        width: avatarSize,
                                        height: avatarSize,
                                        borderRadius: '100px',
                                        backgroundColor:  (profileIndex !== null || profileIndex !== undefined ) ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: isSmScreen ? '24px' : '48px',
                                        fontWeight: 'bold',
                                        color: Colors.white,
                                       // border: '2px solid #ffffff',
                                       // boxShadow: '0px 4px 4px 0px #00000040',
                                    }}
                                >
                                    {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
                                </Box>
                        )
                    )}
                </Box>  <Box sx={{
                    width: '5%', height: '100%',paddingLeft:'4%',
                    cursor: editbtn ? 'pointer' : 'default', justifyContent: 'center', alignItems: 'center'
                }}
                    onClick={handleClick}>
                    {editbtn && <EditOutlinedIcon style={{ color: Colors.white }} />}
                </Box>
            </Box>

            <Divider sx={{ width: '100%', marginLeft: '-20px', paddingTop: {xs:'8px',sm:'16px',md:'16px'}, }} />
            <Box sx={{
                width: '100%', height: '60%', display: 'flex', flexDirection: 'row',
                gap: '16px', paddingTop: {xs:'8px',sm:'16px',md:'16px'}
            }}>
                <Box sx={{
                    width: '20%', height: '100%',
                    display: 'flex', flexDirection: 'column', gap: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Box
                        component="img"
                        src={serviceProviderQrCode}
                        alt="qrcode"
                        sx={{
                            width: { xs: '48px', sm: '60px', md: '76px' }, // adjust these values as needed
                            height: { xs: '48px', sm: '60px', md: '76px' }, // adjust these values as needed
                        }}
                    />
                    <TypographyComponent sx={{ color: fontColor, fontSize: {sm:'14px',md:'14px',xs:'10px'}, letterSpacing: '2px' }}>
                        {serviceProviderDtls.service_provider_code}
                    </TypographyComponent>
                </Box>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', gap: '8px',
                    alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
                }}>
                    {serviceProviderOnchangeDtls && (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',
                         gap: '8px',alignItems:'center' }}>
                            <PhoneAndroidIcon sx={{
                                color: iconColor,
                                width: { sm: '26px', md: '20px', xs: '16px' },
                                height: { sm: '16px', md: '20px', xs: '26px' }
                            }}></PhoneAndroidIcon>
                            <TypographyComponent sx={{ color: fontColor, 
                                fontSize: { sm: '14px', md: '12px', xs: '8px' } }}>
                                +  {serviceProviderOnchangeDtls.countryCode}
                            </TypographyComponent>
                            <TypographyComponent sx={{ color: fontColor, 
                                fontSize: { sm: '14px', md: '12px', xs: '8px' }}}>
                                {serviceProviderOnchangeDtls.phoneNumber}
                            </TypographyComponent>
                        </Box>
                    )}
                      {(serviceProviderOnchangeDtls.signInEmail ) && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',
                     gap: '8px' ,alignItems:'center' }}>
                        <EmailIcon sx={{
                            color: CardbgColor !== '#313131' ? iconColor : "#FFFFFF",
                            width: { sm: '26px', md: '20px', xs: '16px' },
                            height: { sm: '16px', md: '20px', xs: '26px' }
                        }}></EmailIcon>
                        <TypographyComponent sx={{ color: fontColor, 
                            fontSize: { sm: '14px', md: '12px', xs: '8px' } }}>
                            {serviceProviderOnchangeDtls.signInEmail}
                        </TypographyComponent>
                    </Box>
                      )}
                    {serviceProviderOnchangeDtls && (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',
                         gap: '8px' ,alignItems:'center' }}>
                            <LocationOnIcon sx={{
                                color: iconColor,
                                width: { sm: '26px', md: '20px', xs: '16px' },
                                height: { sm: '16px', md: '20px', xs: '26px' }
                            }} />
                            <TypographyComponent sx={{ color: fontColor, 
                                fontSize: { sm: '14px', md: '12px', xs: '8px' } }}>
                                     {city ? `${city},` : ''} {serviceProviderOnchangeDtls.province}, {serviceProviderOnchangeDtls.country}
                            </TypographyComponent>
                            {/* {serviceProviderDtls.working_radius && (
                            <TypographyComponent sx={{ color: '#313131', fontSize: '14px' }}>
                                working from toronto but will be support in and around 100
                                {serviceProviderDtls.working_radius}
                                kms
                            </TypographyComponent>
                        )} */}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
export default SpBusinessCardFront4;