import { Box, List, ListItem, Avatar, MenuItem, IconButton, Select, Divider,
     InputBase, TextField, Button, Checkbox, FormControlLabel, Grid, Tabs, Tab, Typography, InputAdornment } from "@mui/material";
import TypographyComponent from "./TypographyComponent";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_URL } from "../Components/Constants";
import { IMG_URL } from "../Components/Constants";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonComponent from "./ButtonComponent";
import CancelIcon from '@mui/icons-material/Cancel';
import TextFieldComponent from "./TextFieldComponent";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Colors from "./Colors";
import ButtonComponentGrey from "./ButtonComponentGrey";
import { Link, useNavigate } from 'react-router-dom';
import ButtonComponentWhite from "./ButtonComponentWhite";
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import MessageIcon from '@mui/icons-material/Message';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link as RouterLink } from 'react-router-dom';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const ServiceProviders = ({ serviceProviders, setserviceProviders, selectedService, types, selectedTypeId,
    selectedType, setSelectedTypeId, setSelectedType, setSpIdChange, spIdChange, setTypeAllTypeId, serviceProviderUrl,
    serviceProviderQrCode, serviceDtls, setIsFilterClick, isFilterClickAndNoSp, isFilterClick }) => {
    const navigate = useNavigate();
   // console.log("serviceProviders in service.............", serviceProviders)
    // const service_category = sessionStorage.getItem("service_category");
    // const service_category_id = sessionStorage.getItem("service_category_id");
   
    const colors = ['#F0624E', '#45DCDC', '#A14C93', '#F22B7E', '#5F90DB'];

    const [alignment, setAlignment] = useState('accept');

    const handleAlignment = (event, newAlignment, phoneno, countryCodeno) => {
        console.log(newAlignment)
        console.log(event.target.value)
        if (event.target.value !== null) {
          setAlignment(event.target.value);
      
          if (event.target.value === 'accept') {
            handleEnableClick(phoneno, countryCodeno);
          } else if (event.target.value === 'reject') {
            console.log("Reject action triggered");
            handleDisableClick(phoneno, countryCodeno);
          }
        }
      };
      
    const [isAcceptRejectClick, setIsAcceptRejectClick] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [openQuote, setOpenQuote] = useState(false);
    const [singlespOpen, setSinglespOpen] = useState(false);
    const [singlespOpenProfile, setSinglespOpenProfile] = useState(false);
    const [otpPopUp, setOtpPopUp] = useState(false);
    const [otpPopUpAssossiate, setOtpPopUpAssossiate] = useState(false);
    const [disablePopUpAssossiate, setDisablePopUpAssossiate] = useState(false);
    const [passwordPopup, setPasswordPopup] = useState(false);
    const [confirmQuoteSend, setConfirmQuoteSend] = useState(false);
    const [choosedTypes, setChoosedTypes] = useState([]);
    const [checkboxesService, setCheckboxesService] = useState([]);
    const [typesName, setTypesName] = useState([]);
    const [typesshowName, setTypesshowName] = useState([]);
    const [typesId, setTypesId] = useState([]);
    const [typesError, setTypesError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const token = localStorage.getItem('token');
    const location = localStorage.getItem("Location");
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpAssossiate, setOtpAssossiate] = useState(['', '', '', '']);
    const [verificationAttempted, setVerificationAttempted] = useState(false);
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [checkboxes, setCheckboxes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState('');
    const [otpError, setOtpError] = useState("");
    const [isTermPopUp, setIsTermPopUp] = useState(false);
    const [isTermAssossiatePopUp, setIsTermAssossiatePopUp] = useState(false);
    const [professionNamelist, setProfessionNamelist] = useState("");
    const [searchServiceName, setSearchServiceName] = useState("");
    const typesString = serviceDtls.type_details?.map(item => item.types).join(', ');
    const typeIdString = serviceDtls.type_details?.map(item => item.types_id).join(', ');
    const [spMobileClickedIndex, setspMobileClickedIndex] = useState(null);
    const [professionName, setProfessionName] = useState([]);
    const [serviceDtlsShow, setServiceDtlsShow] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedServiceDataToShow, setSelectedServiceDataToShow] = useState([]);
    const [selectedServices, setSelectedServices] = useState('');
    const [serviceError, setServiceError] = useState("");
    const [otpAssossiateError, setOtAssossiateError] = useState("");
    const [clickCount, setClickCount] = useState(0);
    const [firstClickTime, setFirstClickTime] = useState(null);
    const [blockUntil, setBlockUntil] = useState(null);

    
    useEffect(() => {
        const timer = setTimeout(() => {
          if (serviceDtls && serviceDtls.length > 0 && serviceDtls[0]) {
            const status = serviceDtls[0].associate_status;
            if (status === "Accepted" || status === "Rejected") {
              setIsAcceptRejectClick(true);
            } else {
              setIsAcceptRejectClick(false);
            }
          } else {
            setIsAcceptRejectClick(false);
          }
        }, 1000);
        return () => clearTimeout(timer);
      }, [serviceDtls]); 
   
    let searchServiceId;
    searchServiceId = localStorage.getItem("serviceId") || localStorage.getItem("TypeserviceId") || localStorage.getItem("ServiceserviceId")
    console.log(searchServiceId)

    useEffect(() => {
        if(searchServiceId !== null){
        const tabIndex = serviceDtls.findIndex(service => service.services_id === parseInt(searchServiceId));
        console.log(tabIndex)
        if (tabIndex !== -1) { 
            setSelectedTab(tabIndex)
            setSelectedServiceDataToShow(serviceDtls[tabIndex]) 
            console.log(serviceDtls[tabIndex])
            console.log(tabIndex)
            }
        }
        else{
            if(serviceDtls&& localStorage.getItem("serviceId") === null && localStorage.getItem("TypeserviceId")  === null && localStorage.getItem("ServiceserviceId")){
                searchServiceId = serviceDtls[0].services_id}
                setSelectedTab(0)
                setSelectedServiceDataToShow(serviceDtls[0])  
                console.log(serviceDtls) 
            }
      }, [searchServiceId, serviceDtls]);
    
      useEffect(() => {
        if(selectedServiceDataToShow){
         setServiceDtlsShow(selectedServiceDataToShow)
       }
      }, [selectedServiceDataToShow]);
   let allTypeId
      useEffect(() => {
        if(types.length > 0){
        allTypeId =  types[0].services_id
       console.log(types)}
      }, [types, showDropdown]);
    
    // useEffect(() => {
    //     if (selectedServiceDataToShow) {
    //       console.log(selectedServiceDataToShow);
      
    //       setServiceDtlsShow(prev => {
    //         // Ensure prev is always an array
    //         const prevArray = Array.isArray(prev) ? prev : [];
      
    //         let updatedArray = [...prevArray];
      
    //         if (Array.isArray(selectedServiceDataToShow)) {
    //           selectedServiceDataToShow.forEach(service => {
    //             const isDuplicate = prevArray.some(item => item._id === service._id);
    //             if (!isDuplicate) {
    //               updatedArray.push(service);
    //             }
    //           });
    //         } else {
    //           const isDuplicate = prevArray.some(item => item._id === selectedServiceDataToShow._id);
    //           if (!isDuplicate) {
    //             updatedArray.push(selectedServiceDataToShow);
    //           }
    //         }
      
    //         return updatedArray;
    //       });
    //     }
    //   }, [selectedServiceDataToShow]);
      
      
      
   
      useEffect(() => {
        if (serviceDtls) {
          const extractedProfessionalNames = Array.isArray(serviceDtls)
            ? serviceDtls.map(detail => ({
                professional_name: detail.professional_name,
                services_id: detail.services_id
              }))
            : [{
                professional_name: serviceDtls.professional_name,
                services_id: serviceDtls.services_id
              }];
          setProfessionName(extractedProfessionalNames);
         // setServiceDtlsShow(serviceDtls[0])
        }
        setServiceDtlsShow(selectedServiceData) 
      }, [serviceDtls]);

      const selectedServiceData = serviceDtls[selectedTab] || {};

      const handleTabChange = (event, newValue) => {
        console.log(newValue)
        setSelectedTab(newValue);
        const selectedService = serviceDtls[newValue];
        setServiceDtlsShow(selectedService)
      };

    useEffect(() => {
        if (serviceDtls?.type_details) {
            setSendQuoteTypes(serviceDtls.type_details)
        } else {
            console.warn("serviceDtls or type_details is undefined");
            setSendQuoteTypes([]);
        }


    }, []);

    // useEffect(() => {
    //     const combinedTypeDetails = serviceDtls.flatMap(service => 
    //         service.type_details.map(typeDetail => ({
    //           ...typeDetail,
    //           services: service.services,
    //           serviceId: service.services_id,

    //         }))
    //       );
    //       setSendQuoteTypes(combinedTypeDetails);
         
      
    //     if (sendQuoteTypes) {
    //         const allTypeDetails = serviceDtls.flatMap(service =>
    //             service.type_details.map(detail => ({
    //               ...detail,
    //               services: service.services,
    //               serviceId: service.services_id,
    //             }))
    //           );
              
    //           console.log(allTypeDetails);
          
    //           const initialCheckboxes = allTypeDetails.map(data => ({
    //             types_id: data.types_id,
    //             types: data.types,
    //             services: data.services,
    //             serviceId: data.serviceId,
    //             checked: false, // Set initial checked state
    //           }));
    //           setCheckboxes(initialCheckboxes)

    //           console.log(searchServiceId)
    //           const initialCheckboxesService = allTypeDetails.map(data => ({
    //             types_id: data.types_id,
    //             types: data.types,
    //             services: data.services,
    //             serviceId: data.serviceId,
    //             checked: searchServiceId.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
    //         }));
        
    //         setCheckboxesService(initialCheckboxesService);
        
    //         const selectedServices = [...new Set(initialCheckboxesService
    //             .filter((checkbox) => checkbox.checked)
    //             .map((checkbox) => checkbox.services)
    //         )];
        
    //         setSelectedServices(selectedServices.join(', '));
    //     }
    //     // console.log(initialCheckboxes)
    // }, []);


    const fetchProfession = async () => {
        const serviceId = serviceDtls.services_id
       // console.log(serviceId)
        try {
          const response = await axios.post(`${API_URL}/select_profession`,
            { serviceId: searchServiceId , }); 
            console.log(response.data)        
             setProfessionNamelist(response.data[0].professional_name)
             setSearchServiceName(response.data[0].services)
        } catch (error) {
          console.error('Error fetching service categories:', error);
        }
      };
   
      useEffect(() => {      
        fetchProfession();
       
      }, [searchServiceId]);

      const handleCheckboxChange = (index, types, types_id, services) => (event) => {
        console.log(index, types, types_id, services, event.target.checked)
        const newCheckboxes = checkboxes.map((checkbox) => {
            if (checkbox.types_id === types_id) {
                return {
                    ...checkbox,
                    checked: event.target.checked,
                };
            }
            return checkbox;
        });
    
        setCheckboxes(newCheckboxes);    
       
        console.log (newCheckboxes)      
        const selected = newCheckboxes
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.types);            
           setSelectedTypes(newCheckboxes
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.types).join(', '));

            // setSelectedServices(newCheckboxes
            //     .filter((checkbox) => checkbox.checked)
            //     .map((checkbox) => checkbox.services).join(', ')); 
    
        // Update TextFieldComponent display
        const selectedText = selected.join(', ');
        setTypesshowName(selectedText); 
        setTypesError("")
    };

    // const handleCheckboxChangeService = (service, serviceId) => (event) => {
    //     const newCheckboxes = checkboxesService.map((checkbox) => {
    //         if (checkbox.serviceId === serviceId) {
    //             return {
    //                 ...checkbox,
    //                 checked: event.target.checked,
    //             };
    //         }
    //         return checkbox;
    //     });
    
    //     setCheckboxesService(newCheckboxes);
    
    //     const selectedServices = [...new Set(newCheckboxes
    //         .filter((checkbox) => checkbox.checked)
    //         .map((checkbox) => checkbox.services)
    //     )];
    
    //     setSelectedServices(selectedServices.join(', '));
    //     setServiceError("")
    // };


    
    const handleCheckboxChangeService = (service, serviceId) => (event) => {
        const newCheckboxesService = checkboxesService.map((checkbox) => {
          if (checkbox.serviceId === serviceId) {
            return {
              ...checkbox,
              checked: event.target.checked,
            };
          }
          return checkbox;
        });
      
        setCheckboxesService(newCheckboxesService);
      
        const selectedServices = [...new Set(newCheckboxesService
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => checkbox.services)
        )];
      
        setSelectedServices(selectedServices.join(', '));
        setServiceError("");
      
        if (!event.target.checked) {
          // Find and uncheck all corresponding types of service
          const associatedTypeIds = newCheckboxesService
            .filter((checkbox) => checkbox.serviceId === serviceId)
            .map((checkbox) => checkbox.types_id);
      
          const newCheckboxesType = checkboxes.map((checkbox) => {
            if (associatedTypeIds.includes(checkbox.types_id)) {
              return {
                ...checkbox,
                checked: false,
              };
            }
            return checkbox;
          });
      
          setCheckboxes(newCheckboxesType);
      
          const selectedTypes = newCheckboxesType
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.types);
      
          setSelectedTypes(selectedTypes.join(', '));
          setTypesshowName(selectedTypes.join(', '));
        }
      };


    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const [sendQuoteTypes, setSendQuoteTypes] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);


    // const handleOpenQuote = (id) => {
    //     const combinedTypeDetails = serviceDtls.flatMap(service => 
    //         service.type_details.map(typeDetail => ({
    //           ...typeDetail,
    //           services: service.services
    //         }))
    //       );
    //       setSendQuoteTypes(combinedTypeDetails);
    //       const allTypeDetails = serviceDtls.flatMap(service => service.type_details);
    //       const initialCheckboxes = allTypeDetails.map(data => ({
    //         types_id: data.types_id,
    //         types: data.types,
    //         checked: false, // Set initial checked state
    //       }));
      
    //       setCheckboxes(initialCheckboxes);
      
    //       const filteredTypes = allTypeDetails.map(item => item.types_id);
    //       setFilteredTypes(filteredTypes);

    //     setOtpError("")
    //     setChoosedTypes([])
    //     setTypesName([]);
    //     setTypesshowName([])
    //     setTypesId([])
    //     setTypesError("")
    //     setPhoneNumberError("");
    //     setPhoneNumber("");
    //     setOpenQuote(true);
    //     setSelectedTypes('')
    //     localStorage.setItem("ToId", id)
    //    // console.log(id)
    // };


    const handleOpenQuote = (id) => {
        console.log(id)
        console.log(serviceDtls)
        console.log(searchServiceId)
        const combinedTypeDetails = serviceDtls.flatMap(service => 
            service.type_details.map(typeDetail => ({
              ...typeDetail,
              services: service.services,
              serviceId: service.services_id,
            }))
          );
          setSendQuoteTypes(combinedTypeDetails);
          const allTypeDetails = serviceDtls.flatMap(service =>
            service.type_details.map(detail => ({
              ...detail,
              services: service.services,
              serviceId: service.services_id,
            }))
          );
          
          console.log(allTypeDetails);
      
          const initialCheckboxes = allTypeDetails.map(data => ({
            types_id: data.types_id,
            types: data.types,
            services: data.services,
            serviceId: data.serviceId,
            checked: false, // Set initial checked state
          }));
      
          setCheckboxes(initialCheckboxes);

         
      console.log(searchServiceId)
      console.log(allTypeDetails)
      let initialCheckboxesService
      console.log(searchServiceId)
    //  if(serviceDtls && localStorage.getItem("serviceId") === null && localStorage.getItem("TypeserviceId")  === null && localStorage.getItem("ServiceserviceId")){
        if(serviceDtls && searchServiceId === null){           
         console.log("hii")
         const sid = serviceDtls.map(item => item.services_id);
         initialCheckboxesService = allTypeDetails.map(data => ({
            types_id: data.types_id,
            types: data.types,
            services: data.services,
            serviceId: data.serviceId,
            checked: sid.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
        }));
    }
    else{
        console.log("hii")
       initialCheckboxesService = allTypeDetails.map(data => ({
        types_id: data.types_id,
        types: data.types,
        services: data.services,
        serviceId: data.serviceId,
        checked: searchServiceId.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
    }));
     }
    setCheckboxesService(initialCheckboxesService);

    const selectedServices = [...new Set(initialCheckboxesService
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.services)
    )];

    setSelectedServices(selectedServices.join(', '));
      
          const filteredTypes = allTypeDetails.map(item => item.types_id);
          setFilteredTypes(filteredTypes);
       
        setChoosedTypes([])
        setSelectedTypes('')       
        setTypesName([]);
        setTypesshowName([])
        setTypesId([])
        setTypesError("")
        setServiceError("")
        setPhoneNumberError("");
        setCountryCodeError("");
        setOtpError("")
        setPhoneNumber("");
        setCountryCode("1")
        setOtp(['', '', '', ''])
        setOpenQuote(true);
        localStorage.setItem("ToId", id)
        //  console.log(id)
        setVerificationAttempted(false)
    };

    const handleCloseQuote = () => {
        setOpenQuote(false);
    };


    const handleContactButtonClick = (id) => {
        console.log(id)
        console.log(serviceDtls)
        console.log(searchServiceId)
        const combinedTypeDetails = serviceDtls.flatMap(service => 
            service.type_details.map(typeDetail => ({
              ...typeDetail,
              services: service.services,
              serviceId: service.services_id,
            }))
          );
          setSendQuoteTypes(combinedTypeDetails);
          const allTypeDetails = serviceDtls.flatMap(service =>
            service.type_details.map(detail => ({
              ...detail,
              services: service.services,
              serviceId: service.services_id,
            }))
          );
          
          console.log(allTypeDetails);
      
          const initialCheckboxes = allTypeDetails.map(data => ({
            types_id: data.types_id,
            types: data.types,
            services: data.services,
            serviceId: data.serviceId,
            checked: false, // Set initial checked state
          }));
      
          setCheckboxes(initialCheckboxes);

         
      console.log(searchServiceId)
      console.log(allTypeDetails)
      let initialCheckboxesService
      console.log(searchServiceId)
    //  if(serviceDtls && localStorage.getItem("serviceId") === null && localStorage.getItem("TypeserviceId")  === null && localStorage.getItem("ServiceserviceId")){
        if(serviceDtls && searchServiceId === null){           
         console.log("hii")
         const sid = serviceDtls.map(item => item.services_id);
         initialCheckboxesService = allTypeDetails.map(data => ({
            types_id: data.types_id,
            types: data.types,
            services: data.services,
            serviceId: data.serviceId,
            checked: sid.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
        }));
    }
    else{
        console.log(searchServiceId)
       initialCheckboxesService = allTypeDetails.map(data => ({
        types_id: data.types_id,
        types: data.types,
        services: data.services,
        serviceId: data.serviceId,
        checked: searchServiceId.includes(data.serviceId) // Set checked to true if serviceId matches any in searchServiceIds
    }));
     }
    setCheckboxesService(initialCheckboxesService);

    const selectedServices = [...new Set(initialCheckboxesService
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.services)
    )];

    setSelectedServices(selectedServices.join(', '));
      
          const filteredTypes = allTypeDetails.map(item => item.types_id);
          setFilteredTypes(filteredTypes);
       
        setChoosedTypes([])
        setSelectedTypes('')       
        setTypesName([]);
        setTypesshowName([])
        setTypesId([])
        setTypesError("")
        setServiceError("")
        setPhoneNumberError("");
        setCountryCodeError("");
        setOtpError("")
        setPhoneNumber("");
        setCountryCode("1")
        setOtp(['', '', '', ''])
        setOpenQuote(true);
        localStorage.setItem("ToId", id)
        //  console.log(id)
        setVerificationAttempted(false)
    };
    // const handleArrowClick = () => {
    //     setShowBox(!showBox); // Toggle the visibility of the box
    //     console.log('showBox',showBox)
    // };

    const handleKeyDown = (index, e) => {
        setOtpError("")
        // Handle Enter key press to move to next TextField
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or new line
            if (otp[index].length === 1 && index < otp.length - 1) {
                const nextTextField = document.getElementById(`otp${index + 1}`);
                if (nextTextField) {
                    nextTextField.focus();
                }
            }
        }
    
        // Handle Backspace key press to clear current field and move focus
        if (e.key === 'Backspace') {
            e.preventDefault(); // Prevent default backspace behavior
            const updatedOtp = [...otp];
            updatedOtp[index] = ''; // Clear the current field
            setOtp(updatedOtp);
    
            // Move cursor to previous TextField if index is greater than 0
            if (index > 0) {
                const prevTextField = document.getElementById(`otp${index - 1}`);
                if (prevTextField) {
                    prevTextField.focus();
                }
            }
        }
    };

    
    const handleKeyDownAssossiate = (index, e) => {
        setOtpError("")
        // Handle Enter key press to move to next TextField
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or new line
            if (otpAssossiate[index].length === 1 && index < otpAssossiate.length - 1) {
                const nextTextField = document.getElementById(`otpAssossiate${index + 1}`);
                if (nextTextField) {
                    nextTextField.focus();
                }
            }
        }
    
        // Handle Backspace key press to clear current field and move focus
        if (e.key === 'Backspace') {
            e.preventDefault(); // Prevent default backspace behavior
            const updatedOtp = [...otpAssossiate];
            updatedOtp[index] = ''; // Clear the current field
            setOtpAssossiate(updatedOtp);
    
            // Move cursor to previous TextField if index is greater than 0
            if (index > 0) {
                const prevTextField = document.getElementById(`otpAssossiate${index - 1}`);
                if (prevTextField) {
                    prevTextField.focus();
                }
            }
        }
    };
    
    const handleInputChange = (index, value) => {
        setOtpError("")
        if (!isNaN(value) && value.length <= 1) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
        }
    };

    const handleInputChangeAssossiate = (index, value) => {
        setOtpError("")
        if (!isNaN(value) && value.length <= 1) {
            const updatedOtp = [...otpAssossiate];
            updatedOtp[index] = value;
            setOtpAssossiate(updatedOtp);
        }
    };

    const handleSendOtpClick  = async () => {
        setOtp(['', '', '', ''])
        let hasError = false;
        const anyChecked = checkboxes.some((checkbox) => checkbox.checked);
       // console.log("anyChecked", anyChecked)
        if (!anyChecked) {
          //  console.log("!!!!!1anyChecked", !anyChecked)
            setTypesError("Please choose your types of service");
            hasError = true;
        }
        if (phoneNumber.trim() === "") {
            setPhoneNumberError("Please add your phone number");
            hasError = true;
        }
      //  console.log("jdshj", countryCode.trim())
        if (countryCode.trim() === "" || countryCode.trim() === null) {
         //   console.log("....", countryCode.trim())
            setCountryCodeError("Please add your country code");
            hasError = true;
        }
        if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
            setPhoneNumberError('Phone number must be 10 digits');
            hasError = true;
        }
        if (hasError) {
            return; // Stop execution if there are errors
        }
        else {
            // setConfirmQuoteSend(true)
            // setOtpPopUp(true)
            try {
                const response = await axios.post(`${API_URL}/otp-creation`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    });

              //  console.log("response", response);

                if (response.data.success === true) {
                    setOtpPopUp(true)
                    setOpenQuote(false)
                }
                else {


                    // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                    console.log("an error occured");

                }
            }
            catch (error) {
                // setConfirmQuoteSend(true)
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }


        }
    };

    const handleSendOtpClickAssossiate  = async () => {
        const now = new Date();

            console.log(firstClickTime)
            if (firstClickTime === null) {
              setFirstClickTime(now);
            }       
            console.log(firstClickTime)
            const diffMinutes = (now - firstClickTime) / (1000 * 60);
            console.log("First Click Time:", firstClickTime);
            console.log("Now:", now);
            console.log("Difference in minutes:", diffMinutes);
           
            if (diffMinutes < 1) {
                console.log("diffMinutes")
              setClickCount(prevCount => prevCount + 1);
            } else {  
                console.log("diffMinutes1")           
              setClickCount(1);
              setFirstClickTime(now);
            }
           console.log(clickCount)
            if (clickCount === 2) {
                setOtAssossiateError('Warning: You have only two attempts left. Exceeding this limit may block OTP requests for 24 hours.');      
                return; 
            }
            if (clickCount === 4) {
                setOtAssossiateError('You have clicked too many times. Please try again later.');      
                return; 
            }
        
        setOtpAssossiate(['', '', '', ''])       
        setOtpError(false)
         console.log(serviceProviders.country_code, serviceProviders.phone_number)  
            try {
                const response = await axios.post(`${API_URL}/otp-creation`,
                    {
                        countryCode: serviceProviders.country_code,
                        phone: serviceProviders.phone_number,
                    });

                if (response.data.success === true) {
                    setOtpPopUpAssossiate(true) 
                    setOtpAssossiate(['', '', '', ''])  
                    setOtpError(false)                   
                }
                else {
                    // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                    console.log("an error occured");

                }
            }
            catch (error) {
                // setConfirmQuoteSend(true)
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }


        
    };


    
    const handleEnableClick = async (phoneno, countryCodeno) => {
        setOtpAssossiate(['', '', '', ''])       
        setOtpError(false)
        console.log(phoneno, countryCodeno)
           
            try {
                const response = await axios.post(`${API_URL}/otp-creation`,
                    {
                        countryCode: countryCodeno,
                        phone: phoneno,
                    });

                if (response.data.success === true) {
                    setOtpPopUpAssossiate(true) 
                    setOtpAssossiate(['', '', '', ''])  
                    setOtpError(false)  
                    setIsAcceptRejectClick(true)                  
                }
                else {
                    // setEmailGoogleSignUpError("The email address you provided is invalid. Please check and try again.")
                    console.log("an error occured");

                }
            }
            catch (error) {
                // setConfirmQuoteSend(true)
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }


        
    };

    const handleDisableClick = async (phoneno, countryCodeno) => {
        setDisablePopUpAssossiate(true)
    };
    // const [boxStates, setBoxStates] = useState(
    //     serviceProviders.map(() => false) // Initialize all boxes as hidden initially
    // );
    const [isShare, setIsShare] = useState(false);

    const handleShareButtonClick = () => {
        setIsShare(true)
    }
    const handleMobileShareButtonClick = () => {
        setIsShare(true)
        // if (navigator.share) {
        //     navigator.share({
        //         url: serviceProviderUrl
        //     }).then(() => {
        //         console.log('Share was successful');
        //     }).catch((error) => {
        //         console.error('Error sharing:', error);
        //     });
        // } else {
        //     console.error('Web Share API not supported');
        // }

    }
    const handleCloseOtpPopUp = () => {
        setOtpPopUp(false);
    }
    const handleCloseOtpPopUpAssossiate = () => {
        setOtpPopUpAssossiate(false);
    }

    
    const handleCopyButtonClick = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(serviceProviderUrl)
                .then(() => {
                   // console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);
                    // Fallback for copying manually
                    fallbackCopyTextToClipboard(serviceProviderUrl);
                });
        } else {
            console.warn('Clipboard API not supported, unable to copy text');
            // Fallback for copying manually
            fallbackCopyTextToClipboard(serviceProviderUrl);
        }
    };

    function fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed'; // To avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
          //  console.log('Fallback: Copying text ' + msg);
            setIsShare(false);
        } catch (err) {
            console.error('Fallback: Unable to copy:', err);
        }

        document.body.removeChild(textArea);
    }


    const handleSharePopUpCancelButtonClick = () => {
        setIsShare(false)
    }

    const handleClosePassWordPopup = () => {
        setPasswordPopup(false)
    }

    
    const handleCancelDisablePopup = () => {
        setDisablePopUpAssossiate(false)
    }
    

    const handleYesSendQuote = async () => {
        const formattedSelectedTypes = typesshowName.split(', ').map((type) => {
            const typeName = type.trim();
            const serviceName = checkboxesService.find(service => service.types === typeName)?.services;
        
            return `${typeName} (${serviceName})`;
        }).join(', '); 
       
        try {
            const response = await axios.post(`${API_URL}/sent-message`, {
                types: formattedSelectedTypes,
                user_phone_number: phoneNumber,
                countryCode: countryCode,
                location: location,
                spId: localStorage.getItem("ToId"),
            });
           // console.log("response", response);
            if (response.data.success === true) {
                setOpenQuote(false)
                setConfirmQuoteSend(false)
            }

        } catch (error) {
            console.error('Error:', error.message);
        }

    };

    const handleNoSendQuote = () => {
        setConfirmQuoteSend(false);
        setOpenQuote(true)
    };

    const [clickedIndex, setClickedIndex] = useState(0)
    const handleMenuItemClick = (id, type) => {
        console.log(id)
        setIsFilterClick(true)
        localStorage.removeItem("SPId")      
        setClickedIndex(0)       
        localStorage.removeItem("TypeId")
        setSelectedType(type); // Update the selected type
        setSelectedTypeId(id); // Update the selected type ID if needed
        setShowDropdown(false);
        if(selectedTypeId !== id){
            setserviceProviders([]);
        }
    }
   

    const handleMenuItemAllClick = (id) => {  
        console.log(id)     
        setIsFilterClick(false)
        localStorage.removeItem("SPId")
        setSelectedType('All');
        setserviceProviders([]);
      //  console.log(id)
        setTypeAllTypeId(id); // Update the selected type ID if needed
        setShowDropdown(false);
        setClickedIndex(0)
    }

    // const [selectedType, setSelectedType] = useState(null);

    const handleSpButtonClick = (id, index) => {
        setClickedIndex(index)
        localStorage.setItem("SPId", id)
        setSpIdChange(!spIdChange)
       // console.log(localStorage.getItem("SPId"))
        const screenSize = getScreenSize(); // Assuming you have a function to get the screen size

        setProviderStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    }

    const handleSpButtonClickSp = (id) => {
        localStorage.setItem("SPId", id)
        setSpIdChange(!spIdChange)
        //console.log(localStorage.getItem("SPId"))
        const screenSize = getScreenSize(); // Assuming you have a function to get the screen size
        setSinglespOpenProfile(!singlespOpenProfile);
        // if (screenSize === 'xs' || screenSize === 'mobile') {
        //     // Redirect to the desired page
        //     navigate(`/sp-profile/${id}`);
        // }
    }

    const getScreenSize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 576) {
            return 'xs'; // Extra Small devices (e.g., mobile phones)
        } else if (screenWidth >= 576 && screenWidth < 768) {
            return 'sm'; // Small devices (e.g., tablets)
        } else if (screenWidth >= 768 && screenWidth < 992) {
            return 'md'; // Medium devices (e.g., laptops)
        } else if (screenWidth >= 992 && screenWidth < 1200) {
            return 'lg'; // Large devices (e.g., desktops)
        } else {
            return 'xl'; // Extra Large devices
        }
    };

    const [providerStates, setProviderStates] = useState([]);
    // useEffect(() => {
    //     setProviderStates(serviceProviders.map(() => false));
    // }, [serviceProviders]);
    useEffect(() => {
        if (Array.isArray(serviceProviders)) {
            setProviderStates(serviceProviders.map(() => false));
        }
    }, [serviceProviders]);

    useEffect(() => {
        localStorage.setItem("profileIndex",clickedIndex)
    }, [clickedIndex]);

    

    const handleArrowClick = (index) => {
        //console.log('handleArrowClick', index)
        // setProviderStates((prevStates) => {
        //     const newStates = [...prevStates];
        //     newStates[index] = !newStates[index];
        //     return newStates;
        // });
        setClickedIndex(clickedIndex === index ? null : index);
    };
    // const handleInputTypesChange = (selectedTypes) => {
    //     console.log("selectedTypes", selectedTypes)
    //     if (choosedTypes.length < 5) {
    //         setChoosedTypes(selectedTypes)
    //         const typeNames = selectedTypes.map(type => {
    //             const foundType = types.find(item => item.types_id === type);
    //             return foundType ? foundType.types : null;
    //         });
    //         const typeIds = selectedTypes.map(type => type);
    //         const typesshowingName = typeNames.join(', ');
    //         setTypesName(typeNames);
    //         setTypesshowName(typesshowingName)
    //         setTypesId(typeIds);
    //         setTypesError("");
    //         console.log("selectedIds", typeNames, typeIds)
    //     }

    // };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
    };

    // const handleKeyDown = (index, e) => {
    //     // Handle Backspace key press to delete value
    //     if (e.key === 'Enter') {
    //         e.preventDefault(); // Prevent form submission or new line
    //         // Move cursor to next TextField if current value is not empty
    //         if (otp[index].length === 1 && index < otp.length - 1) {
    //             const nextTextField = document.getElementById(`otp${index + 1}`);
    //             if (nextTextField) {
    //                 nextTextField.focus();
    //             }
    //         }
    //     }

    //     // Handle Backspace key press to delete value
    //     if (e.key === 'Backspace') {
    //         const updatedOtp = [...otp];
    //         updatedOtp[index] = '';
    //         setOtp(updatedOtp);

    //         // Move cursor to previous TextField if current value is empty
    //         if (index > 0) {
    //             const prevTextField = document.getElementById(`otp${index - 1}`);
    //             if (prevTextField) {
    //                 prevTextField.focus();
    //             }
    //         }
    //     }
    // };

    const handleVerifyOTP = async () => {
        const isAnyFieldEmpty = otp.some((digit) => digit.length === 0);
        setVerificationAttempted(true);
        if (isAnyFieldEmpty) {
            console.log("field empty")
        } else {
            const response = await axios.post(`${API_URL}/otp-verification`,
                {
                    countryCode: countryCode,
                    phone: phoneNumber,
                    otp: otp,

                });

          //  console.log(" verify response", response);

            if (response.data.success === true) {
                console.log("otp verification success")
                setOtpPopUp(false);
                setConfirmQuoteSend(true)
                setOtp(['', '', '', ''])
            }
            else{
                setOtpError(response.data.message)
              }
        }
    };

    
    const handleVerifyOTPAssossiate = async () => {
        setPasswordError("")
        setNewPasswordError("")
        setConfirmPasswordError("")
        const isAnyFieldEmpty = otpAssossiate.some((digit) => digit.length === 0);
        setVerificationAttempted(true);
        if (isAnyFieldEmpty) {
            console.log("field empty")
        } else {
            const response = await axios.post(`${API_URL}/otp-verification`,
                {
                    countryCode: serviceProviders.country_code,
                    phone: serviceProviders.phone_number,
                    otp: otpAssossiate,

                });

          //  console.log(" verify response", response);

            if (response.data.success === true) {
                console.log("otp verification success")
                setOtpPopUpAssossiate(false);
                if (alignment === "accept") {
                    setPasswordPopup(true)
                  }
                   else if (alignment === "reject") {
                     setDisablePopUpAssossiate(true)
                   }
              
                setOtpAssossiate(['', '', '', ''])
            }
            else{
                setOtpError(response.data.message)
              }
        }
    };

    // const handleInputChange = (index, value) => {
    //     // Check if the input value is a number and update the OTP state
    //     if (!isNaN(value) && value.length <= 1) {
    //         const updatedOtp = [...otp];
    //         updatedOtp[index] = value;
    //         setOtp(updatedOtp);
    //     }
    // };
   
  //  };
    // const handleCheckboxChange = (index) => (event) => {
    //     const newCheckboxes = [...checkboxes];
    //     newCheckboxes[index].checked = event.target.checked;
    //     setCheckboxes(newCheckboxes);
    //   };

    const handleArrowClickSp = () => {
        setSinglespOpenProfile(!singlespOpenProfile);
    };
    const [open, setOpen] = useState(false);

    const handleToggle = (e) => {
        e.stopPropagation();
       // setOpen((prevOpen) => !prevOpen);
       setOpen(!open)
    };
    const [serviceOpen, setServiceOpen] = useState(false);
    const handleToggleService = (e) => {
        e.stopPropagation();
       // setServiceOpen((prevOpen) => !prevOpen);
       setServiceOpen(!serviceOpen)
    };

  
 
    const boxRef = useRef(null);
    const buttonRef = useRef(null);
    const serviceRef = useRef(null);
    const serviceboxRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            boxRef.current && !boxRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setOpen(false);

        }
    };

    const handleClickOutsideService = (event) => {
        if (
            serviceboxRef.current && !serviceboxRef.current.contains(event.target) &&
            serviceRef.current && !serviceRef.current.contains(event.target)
        ) {
            setServiceOpen(false);

        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [open]);

    useEffect(() => {
        if (serviceOpen) {
            document.addEventListener('mousedown', handleClickOutsideService);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideService);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideService);
        };

    }, [serviceOpen]);


    const handleTermsAndCondtionClick = () => {
        setIsTermPopUp(true)
        setOpenQuote(false)
    };

    const handleCloseTerm = () => {
        setIsTermPopUp(false)
        setOpenQuote(true)
    };

    
    const handleCloseAssossiateTerm = () => {
        setIsTermAssossiatePopUp(false)
       
    };


    const firstOtpInputRef = useRef(null);

    useEffect(() => {
        if (otpPopUp && firstOtpInputRef.current) {
            firstOtpInputRef.current.focus();
        }
    }, [otpPopUp]);

    const groupByService = (checkboxes) => {
        return checkboxes.reduce((groups, checkbox) => {
            if (!groups[checkbox.services]) {
                groups[checkbox.services] = [];
            }
            groups[checkbox.services].push(checkbox);
            return groups;
        }, {});
    };
    
    const filteredCheckboxes = checkboxes.filter(checkbox => selectedServices.includes(checkbox.services));
    const groupedCheckboxes = groupByService(filteredCheckboxes);
    

      const uniqueCheckboxes = checkboxesService.filter((checkbox, index, self) =>
        index === self.findIndex((c) => (
          c.services === checkbox.services
        ))
      );

      const handlePaste = (event) => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === otp.length) {
          const newOtp = [...otp];
          for (let i = 0; i < otp.length; i++) {
            newOtp[i] = pastedData[i] || '';
          }
          setOtp(newOtp);
          event.preventDefault();
        }
      };

      const handlePasteAssossiate = (event) => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === otpAssossiate.length) {
          const newOtp = [...otpAssossiate];
          for (let i = 0; i < otpAssossiate.length; i++) {
            newOtp[i] = pastedData[i] || '';
          }
          setOtpAssossiate(newOtp);
          event.preventDefault();
        }
      };

      const [newPassword, setNewPassword] = useState("");
      const [newPasswordError, setNewPasswordError] = useState("");
      const [confirmPassword, setConfirmPassword] = useState("");
      const [confirmPasswordError, setConfirmPasswordError] = useState("");
      const [passwordError, setPasswordError] = useState("");
     
      const [showNewPassword, setShowNewPassword] = useState(false);
      const [showConfirmPassword, setShowConfirmPassword] = useState(false);
      const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);


      const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
        setPasswordError("")
        setNewPasswordError("")
        setConfirmPasswordError("")
      };
    
      const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setPasswordError("")
        setNewPasswordError("")
        setConfirmPasswordError("")
      };
    
    
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
    
        if (newPassword.trim() === '' || confirmPassword.trim() === '') {
         
         
          if (newPassword.trim() === "") {
            setNewPasswordError("Please enter password");
           
          }
          else {
            setNewPasswordError("");
          }
          if (confirmPassword.trim() === "") {
            setConfirmPasswordError("Please enter confirm password");
           
          }
          else {
            setConfirmPasswordError("");
          }
          return;
        }
        if (newPassword !== confirmPassword) {
            setPasswordError("New password and confirm password do not match.");
            return;
          }
          try {
           
            const spid = serviceProviders.service_provider_id;
           console.log(serviceProviders.service_provider_id)
            const response = await axios.post(`${API_URL}/create-new-password-assossiate`, { spid, newPassword });
            console.log("response message",response.data)
            if (response.data.success===true) {
                console.log("response message",response.data)
                 window.location.href = '/signin'
              }
              if (response.data.success===false) {
              //  console.log("response message",response.data.message)
                setPasswordError(response.data.message)
              }
    
          }
          catch (error) {
            console.log('Error updating password. Please try again later.');
          }
        
      };

      
      const handleYesDisablePopup = async() => {
       
          try {
          const spid = serviceProviders.service_provider_id;
           console.log(serviceProviders.service_provider_id)
            const response = await axios.post(`${API_URL}/disable-assossiate-status`, { spid });
            console.log("response message",response.data)
            if (response.data.success===true) {
                setDisablePopUpAssossiate(false)
                console.log("response message",response.data)
                 window.location.href = '/'
                 setIsAcceptRejectClick(true) 
              }
              if (response.data.success===false) {             
                setPasswordError(response.data.message)
              }
    
          }
          catch (error) {
            console.log('Error updating password. Please try again later.');
          }
        
      };

      const handleTermsClickAssossiate = () => {        
        setIsTermAssossiatePopUp(true)
    }


    return (
        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'row', gap: '16px',
                justifyContent: 'space-between', alignItems: 'flex-start',
            }}>
                {!localStorage.getItem('SPName') && (
                <TypographyComponent sx={{ fontSize: { xs: '16px', md: '18px' }, paddingLeft: { xs: '8px', md: '0px' },
                 fontColor: Colors.black_80 }}>
                    List of {professionNamelist}s
                </TypographyComponent>)}
                {localStorage.getItem('SPName') && (
                <TypographyComponent sx={{ fontSize: { xs: '16px', md: '18px' }, paddingLeft: { xs: '8px', md: '0px' },
                 fontColor: Colors.black_80 ,height: { xs: '20px', md: '24px' }}}>
                  
                </TypographyComponent>)}
             

{!localStorage.getItem('SPName') && (
           <Box sx={{
            width: 'fit-content', height: '100%', display: 'flex',
            justifyContent: 'flex-end', position: 'relative',
        }}>
            <Box onClick={() => setShowDropdown(!showDropdown)} sx={{
                width: '100%', minWidth: '98px', paddingLeft: '10px', paddingRight: '10px',
                borderRadius: '28px', display: 'flex', border: '1px solid',
                backgroundColor: Colors.white, alignItems: 'center', borderColor: 'white',
                justifyContent: 'center', gap: '5px', cursor: 'pointer'
            }}>
                <TypographyComponent sx={{
                    color: Colors.black_80, overflow: 'hidden',
                    textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '110px'
                }}> {selectedType || 'Filter'} </TypographyComponent>
                <KeyboardArrowDownIcon style={{ color: Colors.black_80 }} onClick={() => setShowDropdown(!showDropdown)}>
                </KeyboardArrowDownIcon>

            </Box>
            {showDropdown  && types.length > 0 && (
                <Box className="dropdown__menu" onClick={() => setShowDropdown(!showDropdown)}
                 sx={{
                    position: 'absolute',
                    right: '0px',
                    top: '35px',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    width: '100%',
                    minWidth: { xs: '290px', md: '348px' },
                    border: '1px solid',
                    borderColor: Colors.grey_60,
                    background: Colors.white,
                    borderRadius: '16px',
                    boxShadow: '0px 4px 4px 0px #00000025',
                    zIndex: 1,
                    color: 'white',
                    padding: '8px',
                    overflow: 'hidden',
                    minHeight: 'fit-content',
                    maxHeight: 'fit-content', 
                }}>
                    <style>
                        {`
        .dropdown__menu .MuiMenuItem-root:hover {
            width: 100%;
            background-color: ${Colors.quinary} !important;
            color: ${Colors.black_80} !important;
          }
         

        .dropdown__menu .MuiMenuItem-root {
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis;
            max-width: 100%;
        }
       
    `}
                    </style>
                  
                    <MenuItem
                       onClick={selectedType !== 'All' ? () => handleMenuItemAllClick(allTypeId) : null}
                        value={'All'}
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '16px',
                            color:selectedType === 'All' ? Colors.white : Colors.black_80,
                            fontWeight: '400',
                            lineHeight: '20px',
                            minHeight: '20px',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            width: '100%',
                            backgroundColor: selectedType === 'All' ? Colors.primary : 'inherit',
                           // cursor: selectedType === 'All' ? 'not-allowed' : 'pointer',
                        }}
                    >
                        All
                    </MenuItem>

                        {types.map((type) => (
                            <MenuItem
                                key={type.types_id}
                                onClick={ () => handleMenuItemClick(type.types_id, type.types)}
                                value={type.types_id}
                                sx={{
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                    color: selectedType === type.types ? Colors.white :Colors.black_80,
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: { xs: '290px', md: '348px' },
                                    width: '100%',
                                    display: 'block',
                                    backgroundColor: selectedType === type.types ? Colors.primary : 'inherit',
                                }}
                            >
                                {type.types}
                            </MenuItem>
                        ))}
                    </Box>
                )}
            </Box>
        )}
  

            </Box>
      
             {isFilterClick && serviceProviders.length > 0 && (
                <>
                    {Array.isArray(serviceProviders) &&  (
                        <Box sx={{
                            width: '100%', display: 'flex', flexDirection: 'column', gap: '4px', paddingTop: '8px',
                            maxWidth: { md: '95%', sm: '95%', xs: '100%' }
                        }}>
                            {serviceProviders.map((provider, index) => (
                                <>
                                    <Box onClick={() => handleSpButtonClick(provider.service_provider_id, index)}
                                     key={provider.service_provider_id} sx={{
                                        width: { md: '(100%-32px)', sm: '(100%-32px)', xs: '(100%-24px)' }, display: 'flex',
                                        flexDirection: 'row', padding: { md: '16px', sm: '16px', xs: '14px' }, borderRadius: '8px',
                                        alignItems: 'center', justifyContent: 'space-between',
                                        backgroundColor: index === clickedIndex ? Colors.primary : 'white',
                                        '&:hover': {
                                            backgroundColor: index === clickedIndex ? Colors.primary : Colors.quinary,
                                        },
                                    }}>                                         
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', gap: { md: '20px', sm: '16px', xs: '16px' },
                                            width: '100%',
                                           
                                            cursor: 'pointer',
                                        }} >
                                            {provider.profile_image && (
                                                <Avatar alt="Leboco" src={IMG_URL + provider.profile_image} sx={{
                                                    width: { md: '75px', sm: '40px', xs: '40px' },
                                                    height: { md: '75px', sm: '40px', xs: '40px' }
                                                }} />
                                            )}
                                            {!provider.profile_image && (
                                               
                                                <Box
                                                sx={{
                                                    width: { md: '75px', sm: '40px', xs: '40px' },
                                                    height: { md: '75px', sm: '40px', xs: '40px' },
                                                    borderRadius: '100px',
                                                    backgroundColor: colors[index % colors.length],
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: {xs:'24px', md: '48px'} ,
                                                    fontWeight: 'bold',
                                                    color: Colors.white,
                                                  //  border: '2px solid #ffffff',
                                                   // boxShadow: '0px 4px 4px 0px #00000040',
                                                }}
                                            >
                                              {provider?.business_name ? provider.business_name[0].toUpperCase() : ''}
                                            </Box>
                                            )}

                                            <Box sx={{  width:  'calc(100%-75px)', display: 'flex', flexDirection: 'column',
                                                 maxWidth: '98%' , alignItems: 'left',   justifyContent: 'center', }}>
                                                <TypographyComponent sx={{
                                                    fontSize: '16px',
                                                    color: index === clickedIndex ? Colors.white : Colors.black_80,
                                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                }}>
                                                    {provider.business_name}
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                    fontSize: '14px',
                                                    color: index === clickedIndex ? Colors.white : Colors.grey_60,
                                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                }}>                                                   
                                                    {`${searchServiceName}/${provider.city}`}
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                    fontSize: '14px',
                                                    color: index === clickedIndex ? Colors.white : Colors.grey_60,
                                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                }}>
                                                    {`+${provider.country_code} ${provider.phone_number}`}
                                                </TypographyComponent>

                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row', width: '10%',
                                            justifyContent: 'center'
                                        }}>
                                           
                                            <Box sx={{
                                                display: { md: 'flex', sm: 'flex', xs: 'none' }, alignItems: 'center', flexDirection: 'row', width: '8%'
                                                , minWidth: '5%', justifyContent: 'center',
                                            }}>
                                                {index === clickedIndex && (
                                                    <KeyboardArrowRightIcon
                                                        sx={{
                                                            display: { xs: 'none', sm: 'flex', md: 'flex' }, width: '24px', color: Colors.white

                                                        }}
                                                    />)}
                                            </Box>
                                            <Box sx={{
                                                display: { md: 'none', sm: 'none', xs: 'flex' }, alignItems: 'center', flexDirection: 'row', width: '8%',
                                                minWidth: '5%', justifyContent: 'center',
                                            }}>
                                                {index === clickedIndex ? (
                                                    <KeyboardArrowDownIcon
                                                        sx={{
                                                            display: index === clickedIndex ? {
                                                                xs: 'flex',
                                                                sm: 'none',
                                                                md: 'none'
                                                            } : 'none', width: '24px', color: Colors.white, zIndex: 9
                                                        }}
                                                      
                                                        onClick={(e) => {
                                                            e.stopPropagation(); 
                                                            handleArrowClick(index);
                                                        }}
                                                    />
                                                ) : (
                                                    < KeyboardArrowUpIcon
                                                        sx={{
                                                            display: index === clickedIndex ? {
                                                                xs: 'flex',
                                                                sm: 'none',
                                                                md: 'none'
                                                            } : 'none', width: '24px', color: Colors.white, zIndex: 9
                                                        }}
                                                      
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleArrowClick(index);
                                                        }}
                                                    />
                                                )}
                                            </Box>

                                        </Box>
                                    </Box>
                                    {index === clickedIndex && (
                                        <Box key={index} sx={{
                                            width: '100% ', height: '100% ', display: {
                                                xs: 'flex',
                                                sm: 'none', md: 'none'
                                            },
                                            flexDirection: 'column',
                                            justifyContent: 'center', alignItems: 'center', gap: '16px', maxWidth: '100%'
                                        }}>

                                            <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                                alignItems: 'center', gap: '16px',
                                            }}>


                                            </Box>

                                            <Box sx={{
                                                width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                                alignItems: 'center', gap: '16px',
                                            }}>
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_80 }}>Profile</TypographyComponent>

                                            </Box>
                                            <Box sx={{
                                                width: 'calc(100% - 36px)', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                                alignItems: 'center', padding: '0px 18px 0px 18px', borderRadius: '18px', backgroundColor: Colors.grey_20,

                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
                                                }}>
                                                    <Box sx={{
                                                        display: 'flex', flexDirection: 'row', padding: '8px 16px',
                                                        gap: '20px', justifyContent: 'space-betwwen', alignItems: 'center', paddingTop: '20px'
                                                    }}>
                                                        <Box sx={{ width: '20%', height: '100%', }}>
                                                            {provider.profile_image && (
                                                                <Avatar alt="Leboco" src={IMG_URL + provider.profile_image} sx={{ width: 60, height: 60 }} />
                                                            )}
                                                            {!provider.profile_image && (
                                                              
                                                                <Box
                                                                sx={{
                                                                    width: '60px',
                                                                    height: '60px',
                                                                    borderRadius: '100px',
                                                                    backgroundColor:  colors[index % colors.length],
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    fontSize: {xs:'24px', md: '48px'} ,
                                                                    fontWeight: 'bold',
                                                                    color: Colors.white,
                                                                   // border: '2px solid #ffffff',
                                                                  //  boxShadow: '0px 4px 4px 0px #00000040',
                                                                }}
                                                            >
                                                              {provider?.business_name ? provider.business_name[0].toUpperCase() : ''}
                                                            </Box>
                                                            )}
                                                        </Box>

                                                        <Box sx={{
                                                            width: '75%', height: '100%', display: 'flex',
                                                            justifyContent: 'center', alignItems: 'flex-start',
                                                        }}>
                                                            <Box sx={{ width: '100%', height: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                                <TypographyComponent sx={{ color: Colors.primary, fontSize: '16px', fontWeight: 600 }}>
                                                                    {provider.business_name}</TypographyComponent>
                                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>
                                                                    {provider.contact_person}</TypographyComponent> 
                                                            </Box>

                                                        </Box>

                                                        <Box sx={{ width: '5%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' } }}>
                                                            <ShareOutlinedIcon onClick={handleShareButtonClick}
                                                                sx={{
                                                                    color: Colors.default, cursor: 'pointer', '&:hover': {
                                                                        color: Colors.black_80,
                                                                    },
                                                                }} />
                                                        </Box>
                                                        <Box sx={{
                                                            width: '15%', height: '100%', gap: '25%',
                                                            display: { md: 'none', sm: 'none', xs: 'flex' }
                                                        }}>
                                                            <MessageOutlinedIcon onClick={() => handleOpenQuote(provider.service_provider_id)}
                                                                sx={{
                                                                    color: Colors.default, cursor: 'pointer', '&:hover': {
                                                                        color: Colors.black_80,
                                                                    },
                                                                }} />
                                                            <ShareOutlinedIcon onClick={handleMobileShareButtonClick}
                                                                sx={{
                                                                    color: Colors.default, cursor: 'pointer', '&:hover': {
                                                                        color: Colors.black_80,
                                                                    },
                                                                }} />
                                                        </Box>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                                                        <Divider orientation="horizontal" flexItem sx={{
                                                            height: '100%', width: '5%', borderColor: Colors.black_80,
                                                            borderWidth: '1px'
                                                        }} />
                                                        <div style={{ width: '20%' }}></div>
                                                        <Divider orientation="horizontal" flexItem sx={{
                                                            height: '100%', width: '75%', borderColor: Colors.black_80,
                                                            borderWidth: '1px'
                                                        }} />
                                                    </Box>
                                                    <Box sx={{ height: '100%', padding: '16px 16px', display: 'flex', flexDirection: 'row', gap: '16px', }}>
                                                        <Box sx={{
                                                            width: '15%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', letterSpacing: '1px' }}>
                                                                {provider.service_provider_code}
                                                            </TypographyComponent>
                                                        </Box>
                                                        <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                          
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '2px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: '14px' }}></PhoneAndroidIcon>
                                                                <a href={`tel:+${provider.country_code}${provider.phone_number}`} style={{ textDecoration: 'none' }}>
                                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', paddingLeft: '6px' }}>
                                                                        +{provider.country_code} {provider.phone_number}
                                                                    </TypographyComponent>
                                                                </a>
                                                            </Box>
                                                           
                                                            {provider.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <EmailIcon sx={{ color: Colors.default, fontSize: '14px' }}></EmailIcon>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {provider.email}
                                                                </TypographyComponent>
                                                            </Box>}
                                                           
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <LocationOnIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {provider.city}, {provider.province}, {provider.country}

                                                                </TypographyComponent>

                                                            </Box>
                                                            
                                                        </Box>
                                                    </Box>
                                                    <Box   sx={{display: 'flex',flexDirection: 'row', width: '100%',  }} >
                                                <Tabs
                                                    value={selectedTab}
                                                    onChange={handleTabChange}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="service tabs"
                                                    TabIndicatorProps={{ style: { display: 'none' } }} 
                                                    sx={{gap:'0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {
                                                    padding:'0px', minWidth: 'unset !important', 
                                                    
                                                    }}}
                                                
                                                >
                                                    {professionName.map((service, index) => (
                                                    <Tab
                                                        key={service.services_id}
                                                        label={
                                                        <Typography
                                                            sx={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: { md: '14px', sm: '14px', xs: '12px' },
                                                            fontWeight: 500,
                                                            color: Colors.black_80,
                                                            cursor: 'pointer',
                                                            minWidth: 'fit-content',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',border:'1px solid', borderColor: Colors.grey_30,padding: selectedTab === index ?'10px 12px' : '8px 12px',borderRadius:'4px',
                                                            textTransform: 'none',backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent',marginLeft:'4px',
                                                        
                                                            }}
                                                        >
                                                        { console.log(service)}
                                                            {service.professional_name }
                                                        </Typography>
                                                        }
                                                    />
                                                    ))}
                                                </Tabs>

        
                                                 </Box> 
                                                    {console.log(Array.isArray(serviceDtlsShow))}
                                                    {console.log((serviceDtlsShow))}
                                                {/* {Array.isArray(serviceDtlsShow) && serviceDtlsShow.map((service, serviceIndex) => (*/}
                                                    <Box 
                                                        key={serviceDtlsShow.services_id}
                                                        sx={{
                                                        height: '100%',
                                                        padding: '16px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '12px',
                                                        }}
                                                    >
                                                        <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: '8px',
                                                            justifyContent: 'left',
                                                            alignItems: 'left',
                                                        }}
                                                        >
                                                        <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                        <TypographyComponent sx={{ color: Colors.default, fontSize: '12px', minWidth: 'fit-content' }}>
                                                            Type of service :
                                                        </TypographyComponent>
                                                        {serviceDtlsShow.type_details && (
                                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                            {serviceDtlsShow.type_details.map(item => item.types).join(', ')}
                                                            </TypographyComponent>
                                                        )}
                                                        </Box>
                                                        <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: '8px',
                                                            justifyContent: 'left',
                                                            alignItems: 'left',
                                                        }}
                                                        >
                                                        <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                        <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                            Office :
                                                        </TypographyComponent>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                            {serviceDtlsShow.office_address}
                                                        </TypographyComponent>
                                                        </Box>
                                                        <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: '8px',
                                                            justifyContent: 'left',
                                                            alignItems: 'left',
                                                        }}
                                                        >
                                                        <LanOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                        <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                            Franchise :
                                                        </TypographyComponent>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                            {serviceDtlsShow.franchise}
                                                        </TypographyComponent>
                                                        </Box>
                                                        <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: '8px',
                                                            justifyContent: 'left',
                                                            alignItems: 'left',
                                                        }}
                                                        >
                                                        <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                        <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                            License No :
                                                        </TypographyComponent>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                            {serviceDtlsShow.license}
                                                        </TypographyComponent>
                                                        </Box>
                                                        <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: '8px',
                                                            justifyContent: 'left',
                                                            alignItems: 'left',
                                                        }}
                                                        >
                                                        <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                        <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                        Certification :
                                                        </TypographyComponent>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                            {serviceDtlsShow.certifications}
                                                        </TypographyComponent>
                                                        </Box>
                                                        <Box
                                                        sx={{
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: '8px',
                                                            overflow: 'hidden',
                                                            wordWrap: 'break-word',
                                                            maxHeight: '100%',
                                                            justifyContent: 'left',
                                                            alignItems: 'left',
                                                        }}
                                                        >
                                                        <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                            About :
                                                        </TypographyComponent>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                            {serviceDtlsShow.about_us}
                                                        </TypographyComponent>
                                                        </Box>
                                                    </Box>
                                                    {/* ))} */}



                                                    <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>

                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', textAlign: 'center' }}>
                                                            Joined by {new Date(provider.joined_date).toLocaleDateString('en-GB')}
                                                        </TypographyComponent>

                                                    </Box>

                                                </Box>


                                            </Box>

                                        </Box>
                                    )}
                                </>
                            ))}

                        </Box>
                    )}</>
            )} 

            {!isFilterClick && serviceProviders.length > 0 && (
                < >
                    {Array.isArray(serviceProviders) && serviceProviders.length > 0 && (
                        <Box key={serviceProviders.service_provider_id} sx={{
                            width: '100%', display: 'flex', flexDirection: 'column', gap: '4px', paddingTop: '8px',
                            maxWidth: { md: '95%', sm: '95%', xs: '100%' }
                        }}>

                            {serviceProviders.map((provider, index) => (
                                <>
                               
                                    <Box onClick={() => handleSpButtonClick(provider.service_provider_id, index)} key={index} sx={{
                                        width: { md: '(100%-32px)', sm: '(100%-32px)', xs: '(100%-24px)' }, display: 'flex',
                                        flexDirection: 'row', padding: { md: '16px', sm: '16px', xs: '14px' }, borderRadius: '8px',
                                        alignItems: 'center', justifyContent: 'space-between',
                                        backgroundColor: index === clickedIndex ? Colors.primary : 'white',
                                        '&:hover': {
                                            backgroundColor: index === clickedIndex ? Colors.primary : Colors.quinary,
                                        },
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', gap: { md: '20px', sm: '16px', xs: '16px' },
                                            width: '100%',
                                            
                                            cursor: 'pointer',
                                        }} >
                                            {provider.profile_image && (
                                                <Avatar alt="Leboco" src={IMG_URL + provider.profile_image} sx={{
                                                    width: { md: '75px', sm: '40px', xs: '40px' },
                                                    height: { md: '75px', sm: '40px', xs: '40px' }
                                                }} />
                                            )}
                                            {!provider.profile_image && (
                                                // <AccountCircleIcon sx={{
                                                //     width: { md: '75px', sm: '40px', xs: '40px' },
                                                //     height: { md: '75px', sm: '40px', xs: '40px' }, color: Colors.grey_60
                                                // }} />
                                                <Box
                                                sx={{
                                                    width: { md: '75px', sm: '40px', xs: '40px' },
                                                    height: { md: '75px', sm: '40px', xs: '40px' },
                                                    borderRadius: '100px',
                                                    backgroundColor:  colors[index % colors.length],
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: {xs:'24px', md: '48px'} ,
                                                    fontWeight: 'bold',
                                                    color: Colors.white,
                                                   // border: '2px solid #ffffff',
                                                   // boxShadow: '0px 4px 4px 0px #00000040',
                                                }}
                                            >
                                              {provider?.business_name ? provider.business_name[0].toUpperCase() : ''}
                                            </Box>
                                            )}

                                            <Box sx={{ width:  'calc(100%-75px)', display: 'flex', flexDirection: 'column',
                                                 maxWidth: '98%' , alignItems: 'left',   justifyContent: 'center', }}>
                                                <TypographyComponent sx={{
                                                    fontSize: '16px',
                                                    color: index === clickedIndex ? Colors.white : Colors.black_80,
                                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                }}>
                                                    {provider.business_name}
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                    fontSize: '14px',
                                                    color: index === clickedIndex ? Colors.white : Colors.grey_60,
                                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                }}>
                                                    {`${searchServiceName}/${provider.city}`}
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                    fontSize: '14px',
                                                    color: index === clickedIndex ? Colors.white : Colors.grey_60,
                                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                }}>
                                                    {`+${provider.country_code} ${provider.phone_number}`}
                                                </TypographyComponent>

                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row', width: '10%',
                                            justifyContent: 'center'
                                        }}>
                                            {/* <Box sx={{
                                        display: 'flex', alignItems: 'center', flexDirection: 'row', width: '70%', justifyContent: 'center',

                                    }}>
                                        <ButtonComponentGrey onClick={() => handleOpenQuote(provider.service_provider_id)}>
                                            Contact
                                        </ButtonComponentGrey>
                                    </Box> */}
                                            <Box sx={{
                                                display: { md: 'flex', sm: 'flex', xs: 'none' }, alignItems: 'center', flexDirection: 'row', width: '8%'
                                                , minWidth: '5%', justifyContent: 'center',
                                            }}>
                                                {index === clickedIndex && (
                                                    <KeyboardArrowRightIcon
                                                        sx={{
                                                            display: { xs: 'none', sm: 'flex', md: 'flex' }, width: '24px', color: Colors.white

                                                        }}
                                                    />)}
                                            </Box>
                                            <Box sx={{
                                                display: { md: 'none', sm: 'none', xs: 'flex' }, alignItems: 'center', flexDirection: 'row', width: '8%',
                                                minWidth: '5%', justifyContent: 'center',
                                            }}>
                                                {index === clickedIndex ? (
                                                    <KeyboardArrowDownIcon
                                                        sx={{
                                                            display: index === clickedIndex ? {
                                                                xs: 'flex',
                                                                sm: 'none',
                                                                md: 'none'
                                                            } : 'none', width: '24px', color: Colors.white, zIndex: 9
                                                        }}
                                                        // onClick={() => handleArrowClick(index)}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent the event from propagating to the parent box
                                                            handleArrowClick(index);
                                                        }}
                                                    />
                                                ) : (
                                                    < KeyboardArrowUpIcon
                                                        sx={{
                                                            display: index === clickedIndex ? {
                                                                xs: 'flex',
                                                                sm: 'none',
                                                                md: 'none'
                                                            } : 'none', width: '24px', color: Colors.white, zIndex: 9
                                                        }}
                                                        // onClick={() => handleArrowClick(index)}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent the event from propagating to the parent box
                                                            handleArrowClick(index);
                                                        }}
                                                    />
                                                )}
                                            </Box>

                                        </Box>
                                    </Box>
                                    {index === clickedIndex && (
                                        <Box key={index} sx={{
                                            width: '100% ', height: '100% ', display: {
                                                xs: 'flex',
                                                sm: 'none', md: 'none'
                                            },
                                            flexDirection: 'column',
                                            justifyContent: 'center', alignItems: 'center', gap: '16px', maxWidth: '100%'
                                        }}>

                                            <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                                alignItems: 'center', gap: '16px',
                                            }}>


                                            </Box>

                                            <Box sx={{
                                                width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                                alignItems: 'center', gap: '16px',
                                            }}>
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_80 }}>Profile</TypographyComponent>

                                            </Box>
                                            <Box sx={{
                                                width: 'calc(100% - 36px)', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                                alignItems: 'center', padding: '0px 18px 0px 18px', borderRadius: '18px', backgroundColor: Colors.grey_20,

                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
                                                }}>
                                                    <Box sx={{
                                                        display: 'flex', flexDirection: 'row', padding: '8px 16px',
                                                        gap: '20px', justifyContent: 'space-betwwen', alignItems: 'center', paddingTop: '20px'
                                                    }}>
                                                        <Box sx={{ width: '20%', height: '100%', }}>
                                                            {provider.profile_image && (
                                                                <Avatar alt="Leboco" src={IMG_URL + provider.profile_image} sx={{ width: 60, height: 60 }} />
                                                            )}
                                                            {!provider.profile_image && (
                                                                // <AccountCircleIcon style={{ width: 60, height: 60, color: Colors.grey_60 }} />
                                                                <Box
                                                                sx={{
                                                                    width: '60px',
                                                                    height: '60px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor:  colors[index % colors.length],
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    fontSize: {xs:'24px', md: '48px'} ,
                                                                    fontWeight: 'bold',
                                                                    color: Colors.white,
                                                                  //  border: '2px solid #ffffff',
                                                                   // boxShadow: '0px 4px 4px 0px #00000040',
                                                                }}
                                                            >
                                                              {provider?.business_name ? provider.business_name[0].toUpperCase() : ''}
                                                            </Box>
                                                            )}
                                                        </Box>

                                                        <Box sx={{
                                                            width: '75%', height: '100%', display: 'flex',
                                                            justifyContent: 'center', alignItems: 'flex-start',
                                                        }}>
                                                            <Box sx={{ width: '100%', height: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                                <TypographyComponent sx={{ color: Colors.primary, fontSize: '16px', fontWeight: 600 }}>
                                                                    {provider.business_name}</TypographyComponent>                                                              
                                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                        {provider.contact_person}</TypographyComponent>
                                                               
                                                            </Box>

                                                        </Box>

                                                        <Box sx={{ width: '5%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' } }}>
                                                            <ShareOutlinedIcon onClick={handleShareButtonClick}
                                                                sx={{
                                                                    color: Colors.default, cursor: 'pointer', '&:hover': {
                                                                        color: Colors.black_80,
                                                                    },
                                                                }} />
                                                        </Box>
                                                        <Box sx={{
                                                            width: '15%', height: '100%', gap: '25%',
                                                            display: { md: 'none', sm: 'none', xs: 'flex' }
                                                        }}>
                                                            <MessageOutlinedIcon onClick={() => handleOpenQuote(provider.service_provider_id)}
                                                                sx={{
                                                                    color: Colors.default, cursor: 'pointer', '&:hover': {
                                                                        color: Colors.black_80,
                                                                    },
                                                                }} />
                                                            <ShareOutlinedIcon onClick={handleMobileShareButtonClick}
                                                                sx={{
                                                                    color: Colors.default, cursor: 'pointer', '&:hover': {
                                                                        color: Colors.black_80,
                                                                    },
                                                                }} />
                                                        </Box>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                                                        <Divider orientation="horizontal" flexItem sx={{
                                                            height: '100%', width: '5%', borderColor: Colors.black_80,
                                                            borderWidth: '1px'
                                                        }} />
                                                        <div style={{ width: '20%' }}></div>
                                                        <Divider orientation="horizontal" flexItem sx={{
                                                            height: '100%', width: '75%', borderColor: Colors.black_80,
                                                            borderWidth: '1px'
                                                        }} />
                                                    </Box>
                                                    <Box sx={{ height: '100%', padding: '16px 16px', display: 'flex', flexDirection: 'row', gap: '16px', }}>
                                                        <Box sx={{
                                                            width: '15%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', letterSpacing: '1px' }}>
                                                                {provider.service_provider_code}
                                                            </TypographyComponent>
                                                        </Box>
                                                        <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                            {/* {serviceProviderDtls && ( */}
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '2px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: '14px' }}></PhoneAndroidIcon>
                                                                <a href={`tel:+${provider.country_code}${provider.phone_number}`} style={{ textDecoration: 'none' }}>
                                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', paddingLeft: '6px' }}>
                                                                        +{provider.country_code} {provider.phone_number}
                                                                    </TypographyComponent>
                                                                </a>
                                                            </Box>
                                                            {/* )} */}
                                                            {provider.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <EmailIcon sx={{ color: Colors.default, fontSize: '14px' }}></EmailIcon>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {provider.email}
                                                                </TypographyComponent>
                                                            </Box>}
                                                            {/* {serviceProviderDtls && ( */}
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <LocationOnIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {provider.city}, {provider.province}, {provider.country}

                                                                </TypographyComponent>

                                                            </Box>
                                                            {/* )} */}
                                                        </Box>
                                                    </Box>
                                                    
        <Box   sx={{display: 'flex',flexDirection: 'row', width: '100%',  }} >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="service tabs"
            TabIndicatorProps={{ style: { display: 'none' } }} 
            sx={{gap:'0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {
               padding:'0px' , minWidth: 'unset !important', 
               
            }}}
           
          >
            {professionName.map((service, index) => (
              <Tab
                key={service.services_id}
                label={
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize:  { md: '14px', sm: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: Colors.black_80,
                      cursor: 'pointer',
                      minWidth: 'fit-content',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',border:'1px solid', borderColor: Colors.grey_30,padding: selectedTab === index ?'10px 12px' : '8px 12px',borderRadius:'4px',
                      textTransform: 'none',backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent',marginLeft:'4px',
                    //   '&:hover': {
                    //     backgroundColor: Colors.grey_30,
                    //   },
                     
                    }}
                  >
                  { console.log(service)}
                    {service.professional_name }
                  </Typography>
                }
              />
            ))}
          </Tabs>

        
        </Box> 
{console.log(serviceDtlsShow)}
                                                    {/* {serviceDtlsShow.map((service, serviceIndex) => ( */}
                                                        <Box sx={{ height: '100%', padding: '16px 16px 16px 16px', display: 'flex', flexDirection: 'column', gap: '12px', }}>

                                                            <Box sx={{
                                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
                                                                gap: '8px', justifyContent: 'left', alignItems: 'left'
                                                            }}>
                                                                <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></PsychologyOutlinedIcon>
                                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px', minWidth: 'fit-content', }}>
                                                                    Type of service :
                                                                </TypographyComponent>
                                                                {serviceDtlsShow.type_details && (
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                   {serviceDtlsShow.type_details.map(item => item.types).join(', ')} 
                                                                   </TypographyComponent>)}
                                                            </Box>
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></ApartmentOutlinedIcon>
                                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                                    Office :
                                                                </TypographyComponent>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {serviceDtlsShow.office_address}
                                                                </TypographyComponent>
                                                            </Box>
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <LanOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></LanOutlinedIcon>
                                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                                    Franchise :
                                                                </TypographyComponent>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {serviceDtlsShow.franchise}
                                                                </TypographyComponent>
                                                            </Box>
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></BadgeOutlinedIcon>
                                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                                    License No :
                                                                </TypographyComponent>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {serviceDtlsShow.license}
                                                                </TypographyComponent>
                                                            </Box>
                                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                                <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></WysiwygOutlinedIcon>
                                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                                Certification :
                                                                </TypographyComponent>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {serviceDtlsShow.certifications}
                                                                </TypographyComponent>
                                                            </Box>



                                                            <Box sx={{
                                                                height: '100%', display: 'flex', flexDirection: 'row',
                                                                gap: '8px', overflow: 'hidden', wordWrap: 'break-word', maxHeight: '100%',
                                                                justifyContent:'left',alignItems:'left'
                                                            }}>
                                                                 <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                                 About :
                                                                </TypographyComponent>
                                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                                    {serviceDtlsShow.about_us}
                                                                </TypographyComponent>
                                                            </Box>
                                                        </Box>
                                                    {/* ))} */}


                                                    <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>

                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', textAlign: 'center' }}>
                                                            Joined by {new Date(provider.joined_date).toLocaleDateString('en-GB')}
                                                        </TypographyComponent>

                                                    </Box>

                                                </Box>


                                            </Box>

                                        </Box>
                                    )}
                                </>
                            ))}

                        </Box>
                    )}</>
            )}
            
            {isFilterClick && serviceProviders.length === 0 && (
                <Box sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row' }, width: '100%', height: '70vh', 
                justifyContent: 'center', alignItems: 'center',maxWidth:{md: '100%', sm: '100%', xs: '90%' }}} >
                    <TypographyComponent sx={{
                        fontSize: { md: '18px', sm: '18px', xs: '14px' }, color: Colors.grey_60,
                      
                    }}> Oops! We couldn't find any service providers in this category.</TypographyComponent>
                </Box>)}

            {localStorage.getItem('SPName') && !localStorage.getItem('SPCode') &&  (
                <Box sx={{
                    width: '100%', display: 'flex', flexDirection: 'column', gap: '4px', paddingTop: '16px', maxWidth: '100%'
                }}>
                    <>
                        <Box onClick={() => handleSpButtonClickSp(serviceProviders.service_provider_id)} sx={{
                            width: { md: '(100%-32px)', sm: '(100%-32px)', xs: '(100%-24px)' },
                            padding: { md: '16px', sm: '16px', xs: '14px' }, display: 'flex', flexDirection: 'row', borderRadius: '8px',
                            alignItems: 'center', justifyContent: 'space-between',
                            backgroundColor: Colors.primary,
                            '&:hover': {
                                backgroundColor: Colors.primary,
                            },
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', gap: { md: '20px', sm: '16px', xs: '16px' }, width: '100%',
                               
                                cursor: 'pointer',
                            }} >
                                {serviceProviders.profile_image && (
                                    <Avatar alt="Leboco" src={IMG_URL + serviceProviders.profile_image}
                                        sx={{ width: { md: '75px', sm: '40px', xs: '40px' }, height: { md: '75px', sm: '40px', xs: '40px' } }} />
                                )}
                                {!serviceProviders.profile_image && (
                                    // <AccountCircleIcon
                                    //     sx={{ width: { md: '75px', sm: '40px', xs: '40px' }, 
                                    //     height: { md: '75px', sm: '40px', xs: '40px' }, color: Colors.grey_60 }} />
                                    <Box
                                    sx={{
                                        width: { md: '75px', sm: '40px', xs: '40px' }, 
                                        height: { md: '75px', sm: '40px', xs: '40px' },
                                        borderRadius: '100px',
                                        backgroundColor: colors[0],
                                        display: 'flex',
                                        alignItems: 'center',textAlign:'center',
                                        justifyContent: 'center',
                                        fontSize: {xs:'24px', md: '48px'} ,
                                        fontWeight: 'bold',
                                        color: Colors.white,
                                       // border: '2px solid #ffffff',
                                        //boxShadow: '0px 4px 4px 0px #00000040',
                                    }}
                                >
                                    {/* {console.log("serviceProviders",serviceProviders)} */}
                                    {serviceProviders?.business_name ? serviceProviders.business_name[0].toUpperCase() : ''}
                                  
                                </Box>
                                )}

                                <Box sx={{  width:  'calc(100%-75px)', display: 'flex', flexDirection: 'column',
                                                 maxWidth: '98%' , alignItems: 'left',   justifyContent: 'center', }}>
                                    <TypographyComponent sx={{
                                        fontSize: '16px', color: Colors.white,
                                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                    }}>
                                        {serviceProviders.business_name}
                                    </TypographyComponent>

                                    <TypographyComponent sx={{
                                        fontSize: '14px', color: Colors.white,
                                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                    }}>
                                        {console.log(searchServiceName)}
                                        {`${searchServiceName}/${serviceProviders.city}`}
                                    </TypographyComponent>
                                    <TypographyComponent sx={{
                                        fontSize: '14px', color: Colors.white,
                                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                    }}>
                                        {`+${serviceProviders.country_code} ${serviceProviders.phone_number}`}
                                    </TypographyComponent>

                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', alignItems: 'center', flexDirection: 'row', width: '10%',
                                justifyContent: 'center',
                            }}>
                                {/* <Box sx={{
                        display: 'flex', alignItems: 'center', flexDirection: 'row', width: '70%', justifyContent: 'center',
                       

                    }}>
                        <ButtonComponentGrey  onClick={() => handleContactButtonClick(serviceProviders.service_provider_id)}>
                            Contact
                        </ButtonComponentGrey>
                    </Box> */}
                                <Box sx={{
                                    display: { md: 'flex', sm: 'flex', xs: 'none' }, alignItems: 'center', flexDirection: 'row', width: '8%'
                                    , minWidth: '5%', justifyContent: 'center',
                                }}>
                                    <KeyboardArrowRightIcon
                                        sx={{
                                            display: {
                                                xs: 'none', // Hide the icon on xs screens
                                                sm: 'flex', md: 'flex'
                                                // Add other display values for different screen sizes if needed
                                            }, width: '24px', color: Colors.white
                                            // Add any other styles for the icon
                                        }}
                                    />
                                </Box>
                                <Box sx={{
                                    display: { md: 'none', sm: 'none', xs: 'flex' }, alignItems: 'center', flexDirection: 'row', width: '8%',
                                    minWidth: '5%', justifyContent: 'center',
                                }}>
                                    {singlespOpenProfile ? (
                                        < KeyboardArrowUpIcon
                                            sx={{ display: { xs: 'flex', sm: 'none', md: 'none' }, width: '24px', color: Colors.white }}
                                            onClick={handleArrowClickSp}

                                        />
                                    ) : (
                                        <KeyboardArrowDownIcon
                                            sx={{ display: { xs: 'flex', sm: 'none', md: 'none' }, width: '24px', color: Colors.white }}
                                            onClick={handleArrowClickSp}
                                        />
                                    )}


                                </Box>

                            </Box>
                        </Box>

                        {singlespOpenProfile &&
                            <Box sx={{
                                width: '100% ', height: '100% ', display: {
                                    xs: 'flex',
                                    sm: 'none', md: 'none'
                                },
                                flexDirection: 'column',
                                justifyContent: 'center', alignItems: 'center', gap: '16px', maxWidth: '100%'
                            }}>

                                <Box sx={{
                                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    alignItems: 'center', gap: '16px',
                                }}>


                                </Box>

                                <Box sx={{
                                    width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                    alignItems: 'center', gap: '16px',
                                }}>
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_80 }}>Profile</TypographyComponent>

                                </Box>
                                <Box sx={{
                                    width: 'calc(100% - 36px)', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                    alignItems: 'center', padding: '0px 18px 0px 18px', borderRadius: '18px', backgroundColor: Colors.grey_20,

                                }}>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', padding: '8px 16px',
                                            gap: '20px', justifyContent: 'space-betwwen', alignItems: 'center', paddingTop: '20px'
                                        }}>
                                            <Box sx={{ width: '20%', height: '100%', }}>
                                                {serviceProviders.profile_image && (
                                                    <Avatar alt="Leboco" src={IMG_URL + serviceProviders.profile_image} sx={{ width: 60, height: 60 }} />
                                                )}
                                                {!serviceProviders.profile_image && (
                                                    // <AccountCircleIcon 
                                                    // style={{ width: 60, height: 60, 
                                                    //     color: Colors.grey_60 }} />
                                                    <Box
                    sx={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '100px',
                        backgroundColor: colors[0],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: {xs:'24px', md: '48px'} ,
                        fontWeight: 'bold',
                        color: Colors.white,
                      //  border: '2px solid #ffffff',
                      //  boxShadow: '0px 4px 4px 0px #00000040',
                    }}
                >
                    {serviceProviders?.business_name ? serviceProviders.business_name[0].toUpperCase() : ''}
                </Box>
                                                )}
                                            </Box>

                                            <Box sx={{
                                                width: '75%', height: '100%', display: 'flex',
                                                justifyContent: 'center', alignItems: 'flex-start',
                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: '100%', textAlign: 'left', display: 'flex',
                                                    flexDirection: 'column',
                                                }}>
                                                    <TypographyComponent sx={{ color: Colors.primary, fontSize: '16px', fontWeight: 600 }}>
                                                        {serviceProviders.business_name}</TypographyComponent>

                                                   <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                        {serviceProviders.contact_person}</TypographyComponent> 

                                                </Box>

                                            </Box>

                                            <Box sx={{ width: '5%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' } }}>
                                                <ShareOutlinedIcon onClick={handleShareButtonClick}
                                                    sx={{
                                                        color: Colors.default, cursor: 'pointer', '&:hover': {
                                                            color: Colors.black_80,
                                                        }
                                                    }} />
                                            </Box>
                                            <Box sx={{ width: '15%', height: '100%', display: { md: 'none', sm: 'none', xs: 'flex' }, gap: '25%' }}>
                                                <MessageOutlinedIcon onClick={() => handleContactButtonClick(serviceProviders.service_provider_id)}
                                                    sx={{
                                                        color: Colors.default, cursor: 'pointer', '&:hover': {
                                                            color: Colors.black_80,
                                                        }
                                                    }} />
                                                <ShareOutlinedIcon onClick={handleMobileShareButtonClick}
                                                    sx={{
                                                        color: Colors.default, cursor: 'pointer', '&:hover': {
                                                            color: Colors.black_80,
                                                        }
                                                    }} />


                                            </Box>

                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                                            <Divider orientation="horizontal" flexItem sx={{
                                                height: '100%', width: '5%', borderColor: Colors.black_80,
                                                borderWidth: '1px'
                                            }} />
                                            <div style={{ width: '20%' }}></div>
                                            <Divider orientation="horizontal" flexItem sx={{
                                                height: '100%', width: '75%', borderColor: Colors.black_80,
                                                borderWidth: '1px'
                                            }} />
                                        </Box>
                                        <Box sx={{ height: '100%', padding: '16px 16px', display: 'flex', flexDirection: 'row', gap: '16px', }}>
                                            <Box sx={{
                                                width: '15%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '10px', letterSpacing: '2px' }}>
                                                    {serviceProviders.service_provider_code}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                {/* {serviceProviderDtls && ( */}
                                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '2px', justifyContent: 'left', alignItems: 'left' }}>
                                                    <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: '14px' }}></PhoneAndroidIcon>
                                                    <a href={`tel:+${serviceProviders.country_code}${serviceProviders.phone_number}`} style={{ textDecoration: 'none' }}>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', paddingLeft: '6px' }}>
                                                            +{serviceProviders.country_code} {serviceProviders.phone_number}
                                                        </TypographyComponent>
                                                    </a>
                                                </Box>
                                                {/* )} */}
                                                {serviceProviders.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                    <EmailIcon sx={{ color: Colors.default, fontSize: '14px' }}></EmailIcon>
                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                        {serviceProviders.email}
                                                    </TypographyComponent>
                                                </Box>}
                                                {/* {serviceProviderDtls && ( */}
                                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                    <LocationOnIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                        {serviceProviders.city}, {serviceProviders.province}, {serviceProviders.country}

                                                    </TypographyComponent>

                                                </Box>
                                                {/* )} */}
                                            </Box>
                                        </Box>
                                        <Box   sx={{display: 'flex',flexDirection: 'row', width: '100%',  }} >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="service tabs"
            TabIndicatorProps={{ style: { display: 'none' } }} 
            sx={{gap:'0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {
               padding:'0px',  minWidth: 'unset !important', 
               
            }}
           
            
        }
           
          >
            {professionName.map((service, index) => (
              <Tab
                key={service.services_id}
                label={
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize:  { md: '14px', sm: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: Colors.black_80,
                      cursor: 'pointer',
                      minWidth: 'fit-content',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',border:'1px solid', borderColor: Colors.grey_30,padding: selectedTab === index ?'10px 12px' : '8px 12px',borderRadius:'4px',
                      textTransform: 'none',backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent',marginLeft:'4px',
                    
                     
                    }}
                  >
                  { console.log(service)}
                    {service.professional_name }
                  </Typography>
                }
              />
            ))}
          </Tabs>

        
        </Box> 

                                        <Box sx={{ height: '100%', padding: '16px 16px 16px 16px', display: 'flex', flexDirection: 'column', gap: '12px', }}>

                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></PsychologyOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px', minWidth: 'fit-content', }}>
                                                    Type of service :
                                                </TypographyComponent>
                                                {serviceDtlsShow.type_details && (
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.type_details.map(item => item.types).join(', ')} 
                                                    </TypographyComponent>)}
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></ApartmentOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                    Office :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.office_address}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <LanOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></LanOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                    Franchise :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.franchise}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></BadgeOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                    License No :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.license}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></WysiwygOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                 Certification :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.certifications}
                                                </TypographyComponent>
                                            </Box>



                                            <Box sx={{
                                                height: '100%', display: 'flex',flexDirection: 'row', gap: '8px',
                                                overflow: 'hidden', wordWrap: 'break-word', maxHeight: '100%',
                                                justifyContent:'left',alignItems:'left'
                                            }}>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                About :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.about_us}
                                                </TypographyComponent>
                                            </Box>
                                        </Box>



                                        <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>

                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', textAlign: 'center' }}>
                                                Joined by {new Date(serviceProviders.joined_date).toLocaleDateString('en-GB')}
                                            </TypographyComponent>

                                        </Box>

                                    </Box>


                                </Box>

                            </Box>}

                    </>


                </Box>

            )}

              {localStorage.getItem('SPName') && localStorage.getItem('SPCode') && (
                <Box sx={{
                    width: '100%', display: 'flex', flexDirection: 'column', gap: '4px', paddingTop: '16px', maxWidth: '100%'
                }}>
                    <>
                    <Box sx={{
                    width: '100%', display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '16px', maxWidth: '100%'
                }}>
                        <Box  sx={{
                            width: { md: '(100%-32px)', sm: '(100%-32px)', xs: '(100%-24px)' },
                            padding: { md: '16px', sm: '16px', xs: '14px' }, display: 'flex', flexDirection: 'row', borderRadius: '8px',
                            alignItems: 'center', justifyContent: 'space-between',
                            backgroundColor: Colors.primary,
                            '&:hover': {
                                backgroundColor: Colors.primary,
                            },
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', gap: { md: '20px', sm: '16px', xs: '16px' }, width: '100%',
                               
                                cursor: 'pointer',
                            }} >
                                {serviceProviders.profile_image && (
                                    <Avatar alt="Leboco" src={IMG_URL + serviceProviders.profile_image}
                                        sx={{ width: { md: '75px', sm: '40px', xs: '40px' }, height: { md: '75px', sm: '40px', xs: '40px' } }} />
                                )}
                                {!serviceProviders.profile_image && (
                                    // <AccountCircleIcon
                                    //     sx={{ width: { md: '75px', sm: '40px', xs: '40px' }, 
                                    //     height: { md: '75px', sm: '40px', xs: '40px' }, color: Colors.grey_60 }} />
                                    <Box
                                    sx={{
                                        width: { md: '75px', sm: '40px', xs: '40px' }, 
                                        height: { md: '75px', sm: '40px', xs: '40px' },
                                        borderRadius: '100px',
                                        backgroundColor: colors[0],
                                        display: 'flex',
                                        alignItems: 'center',textAlign:'center',
                                        justifyContent: 'center',
                                        fontSize: {xs:'24px', md: '48px'} ,
                                        fontWeight: 'bold',
                                        color: Colors.white,
                                       // border: '2px solid #ffffff',
                                        //boxShadow: '0px 4px 4px 0px #00000040',
                                    }}
                                >
                                    {/* {console.log("serviceProviders",serviceProviders)} */}
                                    {serviceProviders?.business_name ? serviceProviders.business_name[0].toUpperCase() : ''}
                                  
                                </Box>
                                )}

                                <Box sx={{  width:  'calc(100% - 75px)', display: 'flex', flexDirection: 'row',
                                                 maxWidth: '98%' , alignItems: 'center',   justifyContent: 'space-between', }}>
                                    

                                    <TypographyComponent sx={{
                                        fontSize: '14px', color: Colors.white,
                                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                    }}>
                                        {console.log(searchServiceName)}
                                        {`${searchServiceName}/${serviceProviders.city}`}
                                    </TypographyComponent>

                                    {/* <ButtonComponentWhite onClick={() => handleEnableClick(serviceProviders.phone_number, serviceProviders.country_code)}  sx={{width:'30%'}}>Enable</ButtonComponentWhite>
                                     */}
                                    {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <ToggleButtonGroup
                                    value={alignment}
                                    exclusive
                                    onChange={(event, newAlignment) => handleAlignment(event, newAlignment, serviceProviders.phone_number, serviceProviders.country_code)}
                                    sx={{
                                        backgroundColor: Colors.white,
                                        borderRadius: '10px',
                                        border: { xs: '1px solid white', md: '2px solid white' },
                                        overflow: 'hidden',
                                        gap: '2px', // Added gap between buttons
                                        '& .MuiToggleButton-root': {
                                        border: 'none',
                                        padding: { xs: '5px 10px', md: '10px 20px' },
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            backgroundColor: Colors.secondary,
                                            color: Colors.white,
                                            borderRadius: '10px',
                                            '&:hover': {
                                            backgroundColor: Colors.secondary, // Keep same background on hover
                                            color: Colors.white, // Keep same text color on hover
                                            }
                                        },        
                                        '&:not(.Mui-selected)': {
                                            backgroundColor: Colors.white,
                                            color: '#000',
                                            borderRadius: '10px',
                                            '&:hover': {
                                            backgroundColor: Colors.white, // Keep same background on hover
                                            color: '#000', // Keep same text color on hover
                                            }
                                        },
                                        '&:not(:first-of-type)': {
                                            marginLeft: '2px', // Adds a 2px gap between buttons
                                        },
                                        },
                                    }}
                                    >
                                    <ToggleButton value="accept">Accept</ToggleButton>
                                    <ToggleButton value="reject">Reject</ToggleButton>
                                    </ToggleButtonGroup>

                                    </Box> */}


                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', alignItems: 'center', flexDirection: 'row', width: '10%',
                                justifyContent: 'center',
                            }}>
                                
                                <Box sx={{
                                    display: { md: 'flex', sm: 'flex', xs: 'none' }, alignItems: 'center', flexDirection: 'row', width: '8%'
                                    , minWidth: '5%', justifyContent: 'center',
                                }}>
                                    <KeyboardArrowRightIcon
                                        sx={{
                                            display: {
                                                xs: 'none', // Hide the icon on xs screens
                                                sm: 'flex', md: 'flex'
                                                // Add other display values for different screen sizes if needed
                                            }, width: '24px', color: Colors.white
                                            // Add any other styles for the icon
                                        }}
                                    />
                                </Box>

                              



                                <Box sx={{
                                    display: { md: 'none', sm: 'none', xs: 'flex' }, alignItems: 'center', flexDirection: 'row', width: '8%',
                                    minWidth: '5%', justifyContent: 'center',
                                }}>
                                    {singlespOpenProfile ? (
                                        < KeyboardArrowUpIcon
                                            sx={{ display: { xs: 'flex', sm: 'none', md: 'none' }, width: '24px', color: Colors.white }}
                                            onClick={handleArrowClickSp}

                                        />
                                    ) : (
                                        <KeyboardArrowDownIcon
                                            sx={{ display: { xs: 'flex', sm: 'none', md: 'none' }, width: '24px', color: Colors.white }}
                                            onClick={handleArrowClickSp}
                                        />
                                    )}


                                </Box>

                            </Box>
                        </Box>
                        <Box
                                sx={{
                                    gap: { md: '6px', sm: '4px', xs: '2px' }, display: 'flex', flexDirection: 'row',
                                    alignItems: 'center', flexWrap: 'wrap',paddingBottom:{ md: '16px', sm: '16px', xs: '8px' }
                                }}>
                                <TypographyComponent
                                    sx= {{fontSize: { md: '16px', sm: '16px', xs: '12px' }, color: Colors.black_70,}}  >
                                    By clicking the Accept button, you agree to our&nbsp;
                                </TypographyComponent>
                                <TypographyComponent
                                    sx={{fontSize: { md: '16px', sm: '16px', xs: '12px' }, color: Colors.link, cursor: 'pointer',
                                    }}
                                    onClick={handleTermsClickAssossiate}  >
                                    terms & condition
                                </TypographyComponent>
                            </Box>
                            </Box>

                        {singlespOpenProfile &&
                            <Box sx={{
                                width: '100% ', height: '100% ', display: {
                                    xs: 'flex',
                                    sm: 'none', md: 'none'
                                },
                                flexDirection: 'column',
                                justifyContent: 'center', alignItems: 'center', gap: '16px', maxWidth: '100%'
                            }}>

                                <Box sx={{
                                    width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    alignItems: 'center', gap: '16px',
                                }}>


                                </Box>

                                <Box sx={{
                                    width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                    alignItems: 'center', gap: '16px',
                                }}>
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_80 }}>Profile</TypographyComponent>

                                </Box>
                                <Box sx={{
                                    width: 'calc(100% - 36px)', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                    alignItems: 'center', padding: '0px 18px 0px 18px', borderRadius: '18px', backgroundColor: Colors.grey_20,

                                }}>
                                    <Box sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
                                    }}>
                                         <Box sx={{
                                       display: 'flex',width:'100%',  justifyContent: 'right', alignItems: 'center',
                                       gap:'16px',height:'95%', paddingTop:'5%'
                                        
                                    }}>
                                       <ButtonComponent disabled={isAcceptRejectClick} onClick={() => handleEnableClick(serviceProviders.phone_number, serviceProviders.country_code)}
                                       sx={{width:'100%', height:'38px'}}>Accept</ButtonComponent>  
                                       <ButtonComponentWhite disabled={isAcceptRejectClick} onClick={() => handleDisableClick(serviceProviders.phone_number, serviceProviders.country_code)}
                                        sx={{width:'100%', height:'38px'}}>Reject</ButtonComponentWhite> 
                                                          </Box> 


                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', padding: '8px 16px',
                                            gap: '20px', justifyContent: 'space-betwwen', alignItems: 'center', paddingTop: '20px'
                                        }}>
                                            
                                            <Box sx={{ width: '20%', height: '100%', }}>
                                                {serviceProviders.profile_image && (
                                                    <Avatar alt="Leboco" src={IMG_URL + serviceProviders.profile_image} sx={{ width: 60, height: 60 }} />
                                                )}
                                                {!serviceProviders.profile_image && (
                                                    // <AccountCircleIcon 
                                                    // style={{ width: 60, height: 60, 
                                                    //     color: Colors.grey_60 }} />
                                                    <Box
                    sx={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '100px',
                        backgroundColor: colors[0],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: {xs:'24px', md: '48px'} ,
                        fontWeight: 'bold',
                        color: Colors.white,
                      //  border: '2px solid #ffffff',
                      //  boxShadow: '0px 4px 4px 0px #00000040',
                    }}
                >
                    {serviceProviders?.business_name ? serviceProviders.business_name[0].toUpperCase() : ''}
                </Box>
                                                )}
                                            </Box>

                                            <Box sx={{
                                                width: '75%', height: '100%', display: 'flex',
                                                justifyContent: 'center', alignItems: 'flex-start',
                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: '100%', textAlign: 'left', display: 'flex',
                                                    flexDirection: 'column',
                                                }}>
                                                    
                                                         <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                         {`${professionName.map(detail => detail.professional_name).join(', ')}`}</TypographyComponent>



                                                </Box>

                                            </Box>

                                            {/* <Box sx={{ width: '5%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' } }}>
                                                <ShareOutlinedIcon onClick={handleShareButtonClick}
                                                    sx={{
                                                        color: Colors.default, cursor: 'pointer', '&:hover': {
                                                            color: Colors.black_80,
                                                        }
                                                    }} />
                                            </Box>
                                            <Box sx={{ width: '15%', height: '100%', display: { md: 'none', sm: 'none', xs: 'flex' }, gap: '25%' }}>
                                                <MessageOutlinedIcon onClick={() => handleContactButtonClick(serviceProviders.service_provider_id)}
                                                    sx={{
                                                        color: Colors.default, cursor: 'pointer', '&:hover': {
                                                            color: Colors.black_80,
                                                        }
                                                    }} />
                                                <ShareOutlinedIcon onClick={handleMobileShareButtonClick}
                                                    sx={{
                                                        color: Colors.default, cursor: 'pointer', '&:hover': {
                                                            color: Colors.black_80,
                                                        }
                                                    }} />


                                            </Box> */}

                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                                            <Divider orientation="horizontal" flexItem sx={{
                                                height: '100%', width: '5%', borderColor: Colors.black_80,
                                                borderWidth: '1px'
                                            }} />
                                            <div style={{ width: '20%' }}></div>
                                            <Divider orientation="horizontal" flexItem sx={{
                                                height: '100%', width: '75%', borderColor: Colors.black_80,
                                                borderWidth: '1px'
                                            }} />
                                        </Box>
                                        <Box sx={{ height: '100%', padding: '16px 16px', display: 'flex', flexDirection: 'row', gap: '16px', }}>
                                            <Box sx={{
                                                width: '15%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '10px', letterSpacing: '2px' }}>
                                                    {serviceProviders.service_provider_code}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                {/* {serviceProviderDtls && ( */}
                                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '2px', justifyContent: 'left', alignItems: 'left' }}>
                                                    <PhoneAndroidIcon sx={{ color: Colors.default, fontSize: '14px' }}></PhoneAndroidIcon>
                                                    {/* <a href={`tel:+${serviceProviders.country_code}${serviceProviders.phone_number}`} style={{ textDecoration: 'none' }}>
                                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', paddingLeft: '6px' }}>
                                                            +{serviceProviders.country_code} {serviceProviders.phone_number}
                                                        </TypographyComponent>
                                                    </a> */}
                                                </Box>
                                                {/* )} */}
                                                {serviceProviders.email && <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                    <EmailIcon sx={{ color: Colors.default, fontSize: '14px' }}></EmailIcon>
                                                    {/* <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                        {serviceProviders.email}
                                                    </TypographyComponent> */}
                                                </Box>}
                                                {/* {serviceProviderDtls && ( */}
                                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                    <LocationOnIcon sx={{ color: Colors.default, fontSize: '14px' }} />
                                                    {/* <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                        {serviceProviders.city}, {serviceProviders.province}, {serviceProviders.country}

                                                    </TypographyComponent> */}

                                                </Box>
                                                {/* )} */}
                                            </Box>
                                        </Box>
                                        <Box   sx={{display: 'flex',flexDirection: 'row', width: '100%',  }} >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="service tabs"
            TabIndicatorProps={{ style: { display: 'none' } }} 
            sx={{gap:'0px', '.css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {
               padding:'0px',  minWidth: 'unset !important', 
               
            }}
           
            
        }
           
          >
            {professionName.map((service, index) => (
              <Tab
                key={service.services_id}
                label={
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize:  { md: '14px', sm: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: Colors.black_80,
                      cursor: 'pointer',
                      minWidth: 'fit-content',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',border:'1px solid', borderColor: Colors.grey_30,padding: selectedTab === index ?'10px 12px' : '8px 12px',borderRadius:'4px',
                      textTransform: 'none',backgroundColor: selectedTab === index ? Colors.grey_30 : 'transparent',marginLeft:'4px',
                    
                     
                    }}
                  >
                  { console.log(service)}
                    {service.professional_name }
                  </Typography>
                }
              />
            ))}
          </Tabs>

        
        </Box> 

                                        <Box sx={{ height: '100%', padding: '16px 16px 16px 16px', display: 'flex', flexDirection: 'column', gap: '12px', }}>

                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <PsychologyOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></PsychologyOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px', minWidth: 'fit-content', }}>
                                                    Type of service :
                                                </TypographyComponent>
                                                {serviceDtlsShow.type_details && (
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.type_details.map(item => item.types).join(', ')} 
                                                    </TypographyComponent>)}
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <ApartmentOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></ApartmentOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                    Office :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.office_address}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <LanOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></LanOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                    Franchise :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.franchise}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <BadgeOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></BadgeOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                    License No :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.license}
                                                </TypographyComponent>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'left', alignItems: 'left' }}>
                                                <WysiwygOutlinedIcon sx={{ color: Colors.default, fontSize: '14px' }}></WysiwygOutlinedIcon>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                 Certification :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.certifications}
                                                </TypographyComponent>
                                            </Box>



                                            <Box sx={{
                                                height: '100%', display: 'flex',flexDirection: 'row', gap: '8px',
                                                overflow: 'hidden', wordWrap: 'break-word', maxHeight: '100%',
                                                justifyContent:'left',alignItems:'left'
                                            }}>
                                                <TypographyComponent sx={{ color: Colors.default, fontSize: '12px' }}>
                                                About :
                                                </TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px' }}>
                                                    {serviceDtlsShow.about_us}
                                                </TypographyComponent>
                                            </Box>
                                        </Box>



                                        <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>

                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '12px', textAlign: 'center' }}>
                                                Joined by {new Date(serviceProviders.joined_date).toLocaleDateString('en-GB')}
                                            </TypographyComponent>

                                        </Box>

                                    </Box>


                                </Box>

                            </Box>}

                    </>


                </Box>

            )}





{openQuote && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseQuote} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>

                        <Box
                            sx={{

                                width: { md: '450px', sm: '400px', xs: '85%' },
                                // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                                display: 'flex',
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                '&::-webkit-scrollbar': {
                                    width: '11px',
                                    height: '8px', // Added height for horizontal scrollbars
                                    borderRadius: '16px',

                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: Colors.grey_60,
                                    borderRadius: '16px',
                                    border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                },
                                '&::-webkit-scrollbar-track': {
                                    borderRadius: '16px',
                                    backgroundColor: Colors.white,
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                },
                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '22px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>
                                    <TypographyComponent sx={{
                                        fontSize: { md: '18px', sm: '18px', xs: '16px' }, fontWeight: 600,
                                        color: Colors.black_80
                                    }}>
                                        Send a message
                                    </TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'flex-start', width: '100%',
                                    }}>
                                        <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Select the service you are looking for*
                                        </TypographyComponent>

                                      

                                        <Box display="flex" alignItems="center" position="relative" width="100%">
                                            <TextFieldComponent
                                                variant="outlined"
                                                value={selectedServices}
                                                placeholder="Select service"
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton ref={serviceRef} onClick={handleToggleService}>
                                                            {serviceOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    ),
                                                }}
                                                error={!!serviceError}
                                            />
                                            {serviceOpen && (
                                                <Box
                                                    ref={serviceboxRef}
                                                    position="absolute"
                                                    top="100%"
                                                    left="0"
                                                    right="0"
                                                    mt={1}
                                                    p={2}
                                                    bgcolor="white"
                                                    border="1px solid rgba(0, 0, 0, 0.12)"
                                                    boxShadow={3}
                                                    zIndex={1}
                                                    display="flex"
                                                    flexDirection="column"
                                                    borderRadius="8px"
                                                    sx={{
                                                        overflow: 'auto',height:'content-width',maxHeight:'150px',
                                                        overflowX: 'hidden',
                                                        '&::-webkit-scrollbar': {
                                                            width: '11px',
                                                            height: '8px', // Added height for horizontal scrollbars
                                                            borderRadius: '16px',
                        
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            backgroundColor: Colors.grey_60,
                                                            borderRadius: '16px',
                                                            border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                        },
                                                        '&::-webkit-scrollbar-track': {
                                                            borderRadius: '16px',
                                                            backgroundColor: Colors.white,
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        },
                                                    }}
                                                >                                                   
                                                    {uniqueCheckboxes.map((checkbox, index) => (
                                                        <Box key={checkbox.types_id} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',
                                                            alignItems:'center',gap:'2px'
                                                        }}>
                                                             {console.log(uniqueCheckboxes)}
                                                        <Checkbox
                                                            checked={checkbox.checked} sx={{ '&.Mui-checked': {
                                                                color: '#0026CC',
                                                            },}}
                                                            onChange={handleCheckboxChangeService(checkbox.services, checkbox.serviceId)}
                                                        />                                                      
                                                        <TypographyComponent sx={{ fontSize:{ md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70 }}>
                                                        {checkbox.services}</TypographyComponent>
                                                        </Box>
                                                    ))}
                                                    </Box>
                                                )}
                                                
                                               
                                        </Box>

                                        {serviceError &&
                                            <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                {serviceError}</TypographyComponent>}
                                    </Box>

                                    
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'flex-start', width: '100%',
                                    }}>
                                        <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                            Select the type of service you are looking for*
                                        </TypographyComponent>

                                      

                                        <Box display="flex" alignItems="center" position="relative" width="100%">
                                            <TextFieldComponent
                                                variant="outlined"
                                                value={selectedTypes}
                                                placeholder="Select type of service"
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton ref={buttonRef} onClick={handleToggle}>
                                                           {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    ),
                                                }}
                                                error={!!typesError}
                                            />
                                            {open && (
                                                <Box
                                                    ref={boxRef}
                                                    position="absolute"
                                                    top="100%"
                                                    left="0"
                                                    right="0"
                                                    mt={1}
                                                    p={2}
                                                    bgcolor="white"
                                                    border="1px solid rgba(0, 0, 0, 0.12)"
                                                    boxShadow={3}
                                                    zIndex={1}
                                                    display="flex"
                                                    flexDirection="column"
                                                    borderRadius="8px"
                                                    sx={{
                                                        overflow: 'auto',height:'content-width',maxHeight:'150px',
                                                        overflowX: 'hidden',
                                                        '&::-webkit-scrollbar': {
                                                            width: '11px',
                                                            height: '8px', // Added height for horizontal scrollbars
                                                            borderRadius: '16px',
                        
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            backgroundColor: Colors.grey_60,
                                                            borderRadius: '16px',
                                                            border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
                                                        },
                                                        '&::-webkit-scrollbar-track': {
                                                            borderRadius: '16px',
                                                            backgroundColor: Colors.white,
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        },
                                                    }}
                                                >
                                                     {Object.keys(groupedCheckboxes).map((service) => (
                                                    <Box key={service} mb={2}>                                                   
                                                    <TypographyComponent sx={{ fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.default }}>
                                                    {service}</TypographyComponent>
                                                    {groupedCheckboxes[service].map((checkbox, index) => (
                                                        <Box key={checkbox.types_id} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',
                                                            alignItems:'center',gap:'2px'
                                                        }}>
                                                             {console.log(checkboxes)}
                                                        <Checkbox
                                                            checked={checkbox.checked} sx={{ '&.Mui-checked': {
                                                                color: '#0026CC',
                                                            },}}
                                                            onChange={handleCheckboxChange(index, checkbox.types, checkbox.types_id, checkbox.services)}
                                                        />                                                      
                                                        <TypographyComponent sx={{ fontSize:{ md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70 }}>
                                                        {checkbox.types}</TypographyComponent>
                                                        </Box>
                                                    ))}
                                                    </Box>
                                                ))}
                                                
                                                </Box>
                                            )}
                                        </Box>

                                        {typesError &&
                                            <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                {typesError}</TypographyComponent>}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'flex-start', width: '100%'
                                    }}>
                                        <Box sx={{ width: { md: '25%', sm: '25%', xs: '35%' } }}>
                                            <TypographyComponent >Country*</TypographyComponent>
                                            <TextFieldComponent placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                inputProps={{ maxLength: 3 }}
                                                sx={{
                                                    fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                        '-webkit-appearance': 'none',
                                                        margin: 0
                                                    },
                                                    '& input[type=number]': {
                                                        '-moz-appearance': 'textfield'
                                                    }
                                                }}
                                                value={countryCode}
                                                onChange={handleCountryCodeChange} error={!!countryCodeError}></TextFieldComponent>
                                            {countryCodeError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{countryCodeError}</TypographyComponent>}
                                        </Box>
                                        <Box sx={{ width: { md: '75%', sm: '75%', xs: '65%' } }}>
                                            <TypographyComponent sx={{ fontSize: { md: '16px', sm: '16px', xs: '14px' } }}>
                                                Phone number*
                                            </TypographyComponent>
                                            <TextFieldComponent placeholder="Add phone number"
                                                onKeyDown={handleKeyDownPhone}
                                                inputProps={{ maxLength: 10 }}
                                                value={phoneNumber}
                                                sx={{
                                                    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                        '-webkit-appearance': 'none',
                                                        margin: 0
                                                    },
                                                    '& input[type=number]': {
                                                        '-moz-appearance': 'textfield'
                                                    }
                                                }}
                                                onChange={handlePhoneNumberChange} error={!!phoneNumberError}>
                                            </TextFieldComponent>
                                            {phoneNumberError &&
                                                <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                                                    {phoneNumberError}</TypographyComponent>}
                                        </Box>

                                    </Box>
                                    <TypographyComponent sx={{ fontSize: '12px', }}>
                                        By clicking the send button, you agree to our{' '}
                                        <Link onClick={handleTermsAndCondtionClick} style={{ textDecoration: 'none', color: Colors.link }}>
                                            Terms and conditions
                                        </Link>.
                                    </TypographyComponent>

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', gap: '16px',
                                    marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                }}>
                                    <Box sx={{ width: '40%' }}>
                                        <ButtonComponent onClick={handleSendOtpClick}>Send</ButtonComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            )}

           {confirmQuoteSend && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '400px', sm: '400px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleNoSendQuote} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: { md: '420px', sm: '420px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '22px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'flex-start', width: '100%'
                                    }}>
                                        <TypographyComponent>
                                            Thank you for submitting your quote request.
                                        </TypographyComponent>
                                        {/* <TypographyComponent>
                                          You have selected {typesshowName}services, along with the phone number +{countryCode} {phoneNumber}. 
                                        </TypographyComponent> */}
                                       <TypographyComponent>
    You have selected {typesshowName.split(', ').map((type, index) => {
        const typeName = type.trim();
        const serviceName = checkboxesService.find(service => service.types === typeName)?.services;

        return (
            <span key={index}>
                {typeName} ({serviceName})
                {index < typesshowName.split(', ').length - 1 ? ', ' : ''}
            </span>
        );
    })} services, along with the phone number +{countryCode} {phoneNumber}.
</TypographyComponent>
                                        <TypographyComponent>
                                            Are you sure you want to proceed?"
                                        </TypographyComponent>

                                    </Box>

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px',
                                    marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                }}>
                                    <Box sx={{
                                        width: '20%', display: 'flex', flexDirection: 'row', gap: '16px',
                                    }}>  </Box>
                                    <Box sx={{
                                        width: '80%', display: 'flex', flexDirection: 'row', gap: '16px',
                                        justifyContent: 'right',
                                    }}>
                                        <ButtonComponentWhite onClick={handleNoSendQuote}>No</ButtonComponentWhite>
                                        <ButtonComponent onClick={handleYesSendQuote}>Yes</ButtonComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}

            {isShare &&
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '2',

                }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleSharePopUpCancelButtonClick} sx={{
                                position: 'fixed', width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '35%', sm: '35%', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: Colors.white,
                                border: '1px solid',
                                borderColor: Colors.white,
                                borderRadius: '16px ',
                                boxShadow: 'none',
                                boxSizing: 'border-box',
                                zIndex: '3',
                                padding: '20px',
                                alignItems: 'center',

                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                justifyContent: 'center', alignContent: 'center', height: '100%',
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                }}>
                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '18px', textAlign: 'left' }}>
                                        Share link
                                    </TypographyComponent>

                                    <Box sx={{
                                        width: 'calc(100%-32px)', height: '28px', border: '1px solid', padding: '10px 16px 10px 16px',
                                        borderColor: Colors.grey_60, borderRadius: '28px', display: 'flex', flexDirection: 'row',
                                    }}>
                                        <InputBase
                                            value={serviceProviderUrl}
                                            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent' }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Roboto', fontSize: '16px',
                                                    '::placeholder': {
                                                        fontFamily: 'Roboto', fontSize: '16px',
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        width: 'calc(100%)', height: '100%',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    }}
                                    >
                                        <ButtonComponent sx={{ width: 'fit-content', padding: '16px' }} onClick={handleCopyButtonClick}><ContentCopyIcon style={{ paddingRight: '5px' }} />Copy link</ButtonComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>}

                {isTermPopUp && (
                <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                }}
            >
                <Box sx={{width:'100%', height:'100%',gap:'5px',
                 justifyContent:'center',alignItems:'center', position: 'fixed',
                   display:'flex',flexDirection:'column', }}>
                 <Box sx={{width:{ md: '450px', sm: '400px', xs: '85%' }, height:'36px',
                 justifyContent:'right',alignItems:'right', display:'flex',}}>
                    <IconButton onClick={handleCloseTerm} sx={{
                         width: '36px', height: '36px',
                       
                    }} >
                        <CancelIcon sx={{
                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                color: Colors.close,
                            },
                        }} ></CancelIcon>
                    </IconButton>
                  </Box>
               
                <Box
                    sx={{
                       
                        width: { md: '450px', sm: '400px', xs: '85%' },
                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                        display:'flex',
                        height:{ md: '400px', sm: '400px', xs: '45%' },
                        backgroundColor: '#fff',
                        border: '1px solid #E6E6E6',
                        borderRadius: '28px ',
                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                        boxSizing: 'border-box',
                        zIndex: 3,
                        padding: '35px',
                        alignItems: 'center',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: '12px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#7f7f7f',
                            borderRadius: '28px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#e0e0e0',
                            borderRadius: '28px',
                        },
                    }}
                >

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column',
                                justifyContent: 'flex-start', gap: '22px',
                                alignItems: 'flex-start', width: '100%'
                            }}>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'flex-start', gap: '8px',
                                    alignItems: 'flex-start', width: '100%',
                                }}>
                                    <TypographyComponent sx={{fontWeight:600, fontFamily:'24px'}}>
                                        Terms And Condition
                                    </TypographyComponent>
                                   

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'flex-start', gap: '8px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>
                                    <TypographyComponent>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    
                                    </TypographyComponent>
                                   

                                </Box>

                            </Box>
                           
                        </Box>
                    </Box>
                    </Box>
                </Box>
            )}

            {otpPopUp &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '568px', sm: '450px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseOtpPopUp} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '568px', sm: '450px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '20px',
                                    alignItems: 'center', width: '100%'
                                }}>
                                    <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                        OTP Verification </TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'center', width: '100%', paddingTop: typesError ? '0px' : '15px'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                        We have sent you a one-time password to the mobile number
                                        <span style={{ color: '#626262' }}> XXXXXX{phoneNumber.slice(-4)}</span>  </TypographyComponent>
                                        <TypographyComponent>
                                            Enter your OTP here
                                        </TypographyComponent>
                                        <Box display="flex" justifyContent="center" mt={2}>

                                            {otp.map((digit, index) => (
                                                <TextField
                                                    key={index}
                                                    id={`otp${index}`}
                                                    value={digit}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                    onPaste={handlePaste}
                                                    error={(verificationAttempted && !digit) || !!otpError}
                                                    onInput={(e) => {
                                                        const input = e.target.value;
                                                        const digitValue = input.replace(/\D/, '').slice(0, 1); // Ensure it's a single digit number
                                                        handleInputChange(index, digitValue);
                                                        if (digitValue && index < otp.length - 1) {
                                                            const nextTextField = document.getElementById(`otp${index + 1}`);
                                                            if (nextTextField) {
                                                                nextTextField.focus();
                                                            }
                                                        }
                                                    }}
                                                    inputRef={index === 0 ? firstOtpInputRef : null}                                                    
                                                    style={{
                                                        width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        {otpError && (
                                    <Box display="flex" justifyContent="center" mt={1}>
                                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                        </TypographyComponent>
                                    </Box>
                                )}
                                        <TypographyComponent>Don't receive the OTP?</TypographyComponent>
                                        {/* <Link href="/forgot-password" style={{ textDecoration: 'none', color: Colors.link }}>
                                        Resend OTP</Link> */}
                                        <Button
                                            onClick={handleSendOtpClick}
                                            sx={{
                                                textDecoration: 'none',
                                                color: Colors.link,
                                                textTransform: 'none',
                                                padding: 0,
                                                minWidth: 'unset',
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // This removes the hover effect
                                                },
                                            }}
                                        >
                                            Resend OTP
                                        </Button>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', marginTop: '16px', justifyContent: 'center',
                                        alignContent: 'center', alignItems: 'center', width: '100%'
                                    }}>
                                        <Box sx={{ width: '100%' }}>
                                            <ButtonComponent onClick={handleVerifyOTP} >Verify</ButtonComponent>
                                        </Box>

                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

{otpPopUpAssossiate &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '568px', sm: '450px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseOtpPopUpAssossiate} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '568px', sm: '450px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '20px',
                                    alignItems: 'center', width: '100%'
                                }}>
                                    <TypographyComponent sx={{ fontSize: '18px', fontWeight: 700, color: '#000000', }}>
                                        OTP Verification </TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'flex-start', gap: '8px',
                                        alignItems: 'center', width: '100%', paddingTop: typesError ? '0px' : '15px'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                                        We have sent you a one-time password to the mobile number
                                        <span style={{ color: '#626262' }}> XXXXXX{serviceProviders.phone_number.slice(-4)}</span>  </TypographyComponent>
                                        <TypographyComponent>
                                            Enter your OTP here
                                        </TypographyComponent>
                                        <Box display="flex" justifyContent="center" mt={2}>

                                            {otpAssossiate.map((digit, index) => (
                                                <TextField
                                                    key={index}
                                                    id={`otpAssossiate${index}`}
                                                    value={digit}
                                                    onKeyDown={(e) => handleKeyDownAssossiate(index, e)}
                                                    onPaste={handlePasteAssossiate}
                                                    error={(verificationAttempted && !digit) || !!otpError}
                                                    onInput={(e) => {
                                                        const input = e.target.value;
                                                        const digitValue = input.replace(/\D/, '').slice(0, 1); // Ensure it's a single digit number
                                                        handleInputChangeAssossiate(index, digitValue);
                                                        if (digitValue && index < otpAssossiate.length - 1) {
                                                            const nextTextField = document.getElementById(`otpAssossiate${index + 1}`);
                                                            if (nextTextField) {
                                                                nextTextField.focus();
                                                            }
                                                        }
                                                    }}
                                                    inputRef={index === 0 ? firstOtpInputRef : null}                                                    
                                                    style={{
                                                        width: '48px', height: '56px', margin: '0 5px', background: '#f0f0f0',
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        {otpError && (
                                    <Box display="flex" justifyContent="center" mt={1}>
                                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{otpError}
                                        </TypographyComponent>
                                    </Box>
                                )}
                                        <TypographyComponent>Don't receive the OTP?</TypographyComponent>
                                        {/* <Link href="/forgot-password" style={{ textDecoration: 'none', color: Colors.link }}>
                                        Resend OTP</Link> */}
                                        <Button
                                            onClick={handleSendOtpClickAssossiate}
                                            sx={{
                                                textDecoration: 'none',
                                                color: Colors.link,
                                                textTransform: 'none',
                                                padding: 0,
                                                minWidth: 'unset',
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // This removes the hover effect
                                                },
                                            }}
                                        >
                                            Resend OTP
                                        </Button>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', marginTop: '16px', justifyContent: 'center',
                                        alignContent: 'center', alignItems: 'center', width: '100%'
                                    }}>
                                        <Box sx={{ width: '100%' }}>
                                            <ButtonComponent onClick={handleVerifyOTPAssossiate} >Verify</ButtonComponent>
                                        </Box>

                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

{passwordPopup &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '568px', sm: '450px', xs: '90%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleClosePassWordPopup} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '568px', sm: '450px', xs: '90%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >


                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%',gap:'16px'}}>
                                <TypographyComponent sx={{fontSize:'18px', fontWeight:600, color: Colors.black_80, textAlign:'center'}}>Set your password</TypographyComponent>
                                <TypographyComponent sx={{fontSize:'16px', fontWeight:400, color: Colors.grey_60, textAlign:'center'}}>Create strong and secure password that protect your account.</TypographyComponent>
                           
                           
                                <Box sx={{ gap: '8px' }}>
                <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Password*</TypographyComponent>
                <TextFieldComponent placeholder="Enter your password"
                    onChange={handleNewPasswordChange} 
                    error={!!passwordError || newPasswordError}
                    id='new_pass'
                    type={showNewPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleNewPasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    color="primary">
                                    {showNewPassword ? (
                                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextFieldComponent>
                {newPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{newPasswordError}</TypographyComponent>}
            </Box>
            <Box sx={{ gap: '8px' }}>
                <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Confirm Password*</TypographyComponent>
                <TextFieldComponent placeholder="Enter your password"
                    onChange={handleConfirmPasswordChange} error={!!passwordError || confirmPasswordError}
                    id='confirm_pass'
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleConfirmPasswordVisibility}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    color="primary">
                                    {showConfirmPassword ? (
                                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    ) : (
                                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextFieldComponent>
                {confirmPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{confirmPasswordError}</TypographyComponent>}
            </Box>
            <Box sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}> 
            {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>
                    {passwordError}</TypographyComponent>}
                    </Box>
            <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
                <ButtonComponent onClick={handleSubmit}>Confirm</ButtonComponent>
              
            </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }


{disablePopUpAssossiate && (
                <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                }}
            >
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '420px', sm: '420px', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCancelDisablePopup} sx={{
                            width: '36px', height: '36px',

                        }} >
                            <CancelIcon sx={{
                                width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                    color: Colors.close,
                                },
                            }} ></CancelIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{

                            width: { md: '420px', sm: '420px', xs: '85%' },
                            height: 'fit-content',
                            backgroundColor: '#fff',
                            border: '1px solid #E6E6E6',
                            borderRadius: '28px ',
                            boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                            boxSizing: 'border-box',
                            zIndex: 3,
                            padding: '35px',
                            alignItems: 'center',
                            overflow: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '12px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#7f7f7f',
                                borderRadius: '28px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#e0e0e0',
                                borderRadius: '28px',
                            },
                        }}
                    >



                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', gap: '22px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', gap: '8px',
                                        alignItems: 'center', width: '100%'
                                    }}>
                                        <TypographyComponent sx={{ fontSize: '18px', color: '#000000', fontWeight: 600,  }}>
                                            Reject
                                        </TypographyComponent>
                                        
                                        <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70, textAlign:'center' }}>
                                        If you reject, you won't be able to use your profile. Are you sure you want to proceed?"
                                        </TypographyComponent>

                                    </Box>

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px',
                                    marginTop: '16px', justifyContent: 'center', alignItems: 'flex-end'
                                }}>
                                    <Box sx={{
                                        width: '20%', display: 'flex', flexDirection: 'row', gap: '16px',
                                    }}>  </Box>
                                    <Box sx={{
                                        width: '80%', display: 'flex', flexDirection: 'row', gap: '16px',
                                        justifyContent: 'right',
                                    }}>
                                        <ButtonComponentWhite onClick={handleCancelDisablePopup}>No</ButtonComponentWhite>
                                        <ButtonComponent onClick={handleYesDisablePopup}>Yes</ButtonComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}

{/* {isTermAssossiatePopUp && (
                <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                }}
            >
                <Box sx={{width:'100%', height:'100%',gap:'5px',
                 justifyContent:'center',alignItems:'center', position: 'fixed',
                   display:'flex',flexDirection:'column', }}>
                 <Box sx={{width:{ md: '450px', sm: '400px', xs: '85%' }, height:'36px',
                 justifyContent:'right',alignItems:'right', display:'flex',}}>
                    <IconButton onClick={handleCloseAssossiateTerm} sx={{
                         width: '36px', height: '36px',
                       
                    }} >
                        <CancelIcon sx={{
                            width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                color: Colors.close,
                            },
                        }} ></CancelIcon>
                    </IconButton>
                  </Box>
               
                <Box
                    sx={{
                       
                        width: { md: '450px', sm: '400px', xs: '85%' },
                        // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                        display:'flex',
                        height:'fit-content',
                        backgroundColor: '#fff',
                        border: '1px solid #E6E6E6',
                        borderRadius: '28px ',
                        boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                        boxSizing: 'border-box',
                        zIndex: 3,
                        padding: '35px',
                        alignItems: 'center',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: '12px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#7f7f7f',
                            borderRadius: '28px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#e0e0e0',
                            borderRadius: '28px',
                        },
                    }}
                >

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column',
                                justifyContent: 'flex-start', gap: '22px',
                                alignItems: 'flex-start', width: '100%'
                            }}>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'flex-start', gap: '8px',
                                    alignItems: 'flex-start', width: '100%',
                                }}>
                                    <TypographyComponent sx={{fontWeight:600, fontFamily:'24px'}}>
                                        Terms And Condition
                                    </TypographyComponent>
                                   

                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column',
                                    justifyContent: 'flex-start', gap: '8px',
                                    alignItems: 'flex-start', width: '100%'
                                }}>
                                    <TypographyComponent>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    
                                    </TypographyComponent>
                                   

                                </Box>

                            </Box>
                           
                        </Box>
                    </Box>
                    </Box>
                </Box>
            )} */}

{isTermAssossiatePopUp && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '540px', sm: '540px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCloseAssossiateTerm} sx={{
                                width: '36px', height: '36px',

                            }} >
                                <CancelIcon sx={{
                                    width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                        color: Colors.close,
                                    },
                                }} ></CancelIcon>
                            </IconButton>
                        </Box>

                        <Box
                            sx={{

                                width: { md: '540px', sm: '540px', xs: '85%' },
                                //height: 'fit-content',
                                display: 'flex', flexDirection: 'column',
                                height: { md: '80%', sm: '80%', xs: '85%' },
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '28px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px 35px 35px 35px',
                                justifyContent: 'center', alignItems: 'center',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                '&::-webkit-scrollbar': {
                                    width: '12px',

                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',

                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                    marginTop: '24px',
                                    marginBottom: '24px'
                                },
                            }}
                        >






                            <Box sx={{
                                width: '100%', height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                alignItems: 'left', gap: '24px', paddingBottom: '5%', paddingTop: '10%' 
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                                    gap: '16px',
                                }}>
                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '26px', xs: '22px' }, lineHeight: '24px', fontWeight: 700,
                                        color: Colors.black_80,
                                    }}>
                                        Terms And Conditions
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '24px', xs: '20px' }, lineHeight: '24px',
                                        fontWeight: 700, color: Colors.black_80,
                                    }}>
                                        Last Updated: May 15, 2024
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                                    gap: '16px',
                                }}>




                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                                        fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                                    }}>
                                        User types
                                    </Typography>
                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                                        fontWeight: 400, color: Colors.black_80,
                                    }}>
                                        <strong>Public users:</strong> Individuals who use BroHandy to search for and contact service providers. These users can browse listings without creating an account, ensuring ease of access and privacy.<br />
                                        <strong>Service provider:</strong> Businesses or individual entrepreneurs who register on BroHandy to list their services. They provide business contact details and information about their offerings. Service Providers are responsible for the accuracy and legality of the information they post.
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                                        fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                                    }}>
                                        Disclaimer
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                                        fontWeight: 400, color: Colors.black_80,
                                    }}>
                                        BroHandy operates on a best-effort basis to ensure the availability and reliability of the data on our platform. However, BroHandy is not responsible for any losses, deceit, or misrepresentation that may arise from the information provided by service providers or the actions of public users. Users should exercise due diligence before engaging in any transactions.
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                                        fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                                    }}>
                                        Terms and conditions for service providers
                                    </Typography>

                                    <Typography sx={{
                                        fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                                        fontWeight: 400, color: Colors.black_80, paddingBottom: '35px'
                                    }}>
                                        <strong>Account responsibility: </strong> Service Providers must ensure the security of their account details and notify BroHandy immediately if they suspect their account has been compromised. If a Service Provider wishes to delete their profile, they may do so, but they remain responsible for any legal issues arising from their profile while it was active.<br />
                                        <strong>Content and conduct: </strong> Service Providers must ensure that the data they provide does not include offensive, illegal content, and does not pertain to adult, sexual, or narcotic services. All Service Providers must be over the age of 18.<br />
                                        <strong>Privacy and data usage: </strong> Service Providers agree that by entering their business contact details on BroHandy, they relinquish any privacy claims over this information. It is intended solely for business use and to be visible to Public Users of BroHandy.<br />
                                        <strong>Legal liability: </strong> Service Providers are solely responsible for any legal issues that arise from their services or the use of BroHandy. This includes ensuring compliance with local laws and regulations relevant to their service offering.<br />
                                        <strong>Modifications and continuity: </strong> BroHandy reserves the right to modify these terms and conditions at any time. Continued use of the platform after such changes constitutes acceptance of the new terms.

                                    </Typography>


                                </Box>
                            </Box>




                        </Box>
                    </Box>
                </Box>
            )}


        </Box>

    )

}
export default ServiceProviders;