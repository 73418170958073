import { Box, styled } from '@mui/material'
import React, { useState } from 'react'
import TypographyComponent from './TypographyComponent';
import Frame1 from '../Images/Frame1.jpg';
import Frame2 from '../Images/Frame2.jpg';
import Frame3 from '../Images/Frame3.jpg';
import Frame4 from '../Images/Frame4.jpg';
import Frame5 from '../Images/Frame5.jpg';
import ImageButton from './ImageButton';
import Colors from './Colors';
import SpBusinessCardFront1 from '../Components/SpBusinessCardFront1';
import SpBusinessCardFront2 from '../Components/SpBusinessCardFront2';
import SpBusinessCardFront3 from '../Components/SpBusinessCardFront3';
import SpBusinessCardFront4 from '../Components/SpBusinessCardFront4';
import SpBusinessCardFront5 from '../Components/SpBusinessCardFront5';


const EditRightBarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  alignItems: {sm:'flex-start',md:'flex-start',xs:'center'},
  gap: '22px',
  height:'fit-content'
}));
const ImageButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: {md:'space-between',sm:'space-between',xs:'flex-start'},
  gap: '8px'
}));
const ColorButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: {md:'space-between',sm:'space-between',xs:'flex-start'},
  gap: '8px'
}));
const BusinessCardBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '190px',
  [theme.breakpoints.up('sm')]: {
    width: '378px',
    height: '200px',
  },
  [theme.breakpoints.up('md')]: {
    width: '506px',
    height: '264px',
  },
}));

function EditRightBar(props){
  const{btn, country, postalCode, phoneNumber, province, newProfilePic, signInEmail, 
    signInPassword, countryCode, businessName,
     data, serviceDetails,
  serviceProviderQrCode, selectedColor, selectedImage, selectedBgButton,setbtn,setSelectedColor,
  setSelectedBgButton,city}=props
 // console.log("signInEmail")
const srdetails = serviceDetails
// console.log(btn, country, postalCode, phoneNumber, province, newProfilePic, signInEmail, signInPassword, countryCode, businessName, data, 
 // serviceProviderQrCode, selectedColor, selectedImage, selectedBgButton,setbtn,setSelectedColor,
 // setSelectedBgButton);
//  console.log(srdetails)
  const cardStyleButtons = [
    { id: 1, imgSrc: Frame1, altText: "Button 1" },
    { id: 2, imgSrc: Frame2, altText: "Button 2" },
    { id: 3, imgSrc: Frame3, altText: "Button 3" },
    { id: 4, imgSrc: Frame4, altText: "Button 4" },
    { id: 5, imgSrc: Frame5, altText: "Button 5" },
  ];
  const handleStyleButtonClick = (buttonNumber) => {
    // Handle button click logic
  //  console.log(`Button ${buttonNumber} Clicked!`);
    const btnIndex = buttonNumber ; // Adjust index to zero-based
  setbtn(btnIndex);
  }; 
    const colorOptions = [
      Colors.card_color_1,
      Colors.card_color_2,
      Colors.card_color_3,
      Colors.card_color_4,
      Colors.card_color_5,
    ];
    const handleColorButtonClick = (color) => {
      setSelectedColor(color);
      switch (color) {
          case Colors.card_color_1:               
              setSelectedBgButton('b1');                           
              break;
          case Colors.card_color_2:
              setSelectedBgButton('b2')
              break;
          case Colors.card_color_3:
              setSelectedBgButton('b3')
              break;
          case Colors.card_color_4:
              setSelectedBgButton('b4')
              break;
          case Colors.card_color_5:
              setSelectedBgButton('b5')
              break;
          default:
              setSelectedBgButton('b1')
      }
   
  };
  const components = [
    SpBusinessCardFront1, 
    SpBusinessCardFront2,
    SpBusinessCardFront3,
    SpBusinessCardFront4,
    SpBusinessCardFront5,
  ];
  // const validBtn = Math.max(Math.min(btn, components.length ), 0);
  // console.log("btn",btn)
  // console.log("validBtn",validBtn)
  const SelectedComponent = components[btn-1];

  return (
    <EditRightBarBox flex={4} p={{xs:'5%',sm:'1%',md:'1%'}}>
      <TypographyComponent sx={{ fontSize: '20px', color: '#313131' }}>
        Customize your business card
      </TypographyComponent>
      <ImageButtonBox>
        {cardStyleButtons.map((button) => (
          <ImageButton
            key={button.id}
            id={button.id}
            onClick={() => handleStyleButtonClick(button.id)}
            imgSrc={button.imgSrc}
            altText={button.altText}
            sx={{
              width: { xs: '54px', sm: '66px', md: '88px' }, height: '100%',
              padding: '0px',
              '&:hover img': {
                transform: 'scale(1.1)', // Apply hover effect
              },
            }}
          />
        ))}
      </ImageButtonBox>
      <TypographyComponent sx={{ fontSize: '20px', color: '#313131' }}>
        Change color theme
      </TypographyComponent>
      <ColorButtonBox>
      {colorOptions.map((color, index) => (
        <div
          key={index}
          onClick={() => handleColorButtonClick(color)}
          style={{
            width: '29px',
            height: '29px',
            borderRadius: '50%',
            backgroundColor: color,
            border: selectedColor === color ? '1px solid #014C61' : '1px solid transparent',
            cursor: 'pointer',
            marginRight: '5px', // Add some spacing between color options
          }}
        />
      ))}
      </ColorButtonBox>
      <BusinessCardBox>
      {SelectedComponent && (
        <SelectedComponent
          serviceProviderOnchangeDtls={{
            country,
            postalCode,
            phoneNumber,
            province,
            newProfilePic,
            signInEmail,
            signInPassword,
            countryCode,
            businessName,
          }}
          serviceProviderDtls={data}
          serviceDetails = {srdetails}
          serviceProviderQrCode={serviceProviderQrCode}
          editbtn={false}
          backgroundColor={selectedColor}
          newProfilePic={newProfilePic}
          selectedImage={selectedImage}
          selectedBgButton={selectedBgButton}
          city={city}
        />
      )}
      </BusinessCardBox>
    </EditRightBarBox>
  )
}

export default EditRightBar