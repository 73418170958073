import { Box, Button, MenuItem, Paper, Select, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from './Constants';
import TypographyComponent from './TypographyComponent';
import TextFieldComponent from './TextFieldComponent';

function AdminSearchTags({updateTrigger}) {

    const token = localStorage.getItem('adminToken');
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    useEffect(() => {
        // Code to fetch or update data goes here
        console.log('Adminsearchtag component updated');
    }, [updateTrigger]);

    return (
        <Box sx={{ mt: 4 }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Services Search tags" />
                <Tab label="Types of Services Search tags" />
                <Tab label="Service provider Search tags" />
            </Tabs>
            {activeTab === 0 && <FirstTabContent token={token} />}
            {activeTab === 1 && <SecondTabContent token={token} />}
            {activeTab === 2 && <ThirdTabContent token={token} />}
        </Box>
    )
}
function FirstTabContent({ token }) {
    const [serviceData, setServiceData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [newSearchTagInput, setNewSearchTagInput] = useState('');
    const [addingServiceSearchTag, setAddingServiceSearchTag] = useState(false);
    const [newSearchTags, setNewSearchTags] = useState([]);
    const [searchTagData, setSearchTagData] = useState([]);
    const [selectedSearchTag, setSelectedSearchTag] = useState('');

    useEffect(() => {
        fetchAllServiceSearchtags();
    }, [token]);

    const handleEditClick = (row) => {
        setAddingServiceSearchTag(false);
        setSelectedSearchTag('');
        setEditId(row.search_tag_id);

        // Check if row.search_tags is not null, undefined, and is a string
        if (row.search_tags && typeof row.search_tags === 'string') {
            console.log(row.search_tags);
            const tagsArray = row.search_tags.split(',').map(tag => tag.trim());
            setNewSearchTags(tagsArray);
        } else {
            // Handle the case where search_tags is null, undefined, or not a string
            console.error('search_tags is null, undefined, or not a string');
            setNewSearchTags([]);  // Optionally set it to an empty array or handle as needed
        }
    };

    const handleTagChange = (index, value) => {
        const updatedTags = [...newSearchTags];
        updatedTags[index] = value;
        setNewSearchTags(updatedTags);
    };
    const handleSaveClick = async (service) => {
        console.log(newSearchTags)
        if (newSearchTags.some(tag => tag === null || tag === undefined || tag.trim() === '')) {
            alert('Please remove any null or empty tags.');
            return;
        }

        // Remove duplicate tags by converting to a Set and back to an array
        const uniqueTags = [...new Set(newSearchTags.map(tag => tag.trim()))];

        // Check if there are any valid tags left after filtering
        if (uniqueTags.length === 0) {
            alert('Please provide at least one valid search tag.');
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/admin_update_service_Searchtags`, {
                token,
                search_tag_id: service.search_tag_id,
                search_tags: uniqueTags
            });

            if (response.data.success) {
                // Update the local state with the new service name
                const updatedTagsString = newSearchTags.join(', ');
                setSearchTagData((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.search_tag_id === service.search_tag_id
                            ? { ...srv, search_tags: updatedTagsString }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewSearchTags([]);
    };

    useEffect(() => {
        // fetchAllServices();
        fetchAllServiceSearchtags();
    }, []);
    async function fetchAllServiceSearchtags() {
        try {
            const response = await axios.post(`${API_URL}/admin_get_all_service_searchtags`, { token });
            console.log(response.data.data);
            if (response.data.success) {
                setSearchTagData(response.data.data);
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    // async function fetchAllServices() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_services`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setServiceData(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    const handleAddClick = () => {
        setEditId(null);
        setNewSearchTags([]);
        setAddingServiceSearchTag(true);
    };

    const handleAddSaveClick = async () => {
        console.log("clicked Add")
        console.log('selectedSearchTag,newSearchTagInput', selectedSearchTag, newSearchTagInput)
        if (newSearchTagInput && !newSearchTags.includes(newSearchTagInput.trim())) {
            try {
                const response = await axios.post(`${API_URL}/admin_add_service_searchtag`, {
                    token,
                    search_tag_id: selectedSearchTag,
                    new_search_tag: newSearchTagInput.trim()
                });

                if (response.data.success) {
                    // setServiceDetai([...serviceDetails, response.data.data]);
                    // setAddingService(false);
                    // setSelectedCategory('');
                    // setProfessionalName('');
                   
                    setAddingServiceSearchTag(false);
                } else {
                    console.error('Error:', response.data.error);
                }
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
        else {
            // Handle cases where the input is null or a duplicate
            if (!newSearchTagInput) {
                alert('Search tag cannot be empty.');
            } else if (newSearchTags.includes(newSearchTagInput.trim())) {
                alert('This search tag already exists.');
            }
        }
    };

    const handleAddCancelClick = () => {
        setAddingServiceSearchTag(false);
        setSelectedSearchTag('');
        // setProfessionalName('');
    };
    const handleStatusToggleService = async (type) => {
        const newStatus = type.status === 'Active' ? 'Inactive' : 'Active';
        try {
            const response = await axios.post(`${API_URL}/admin_update_search_tag_status`, {
                token,
                type_id: type.types_id,
                status: newStatus
            });

            if (response.data.success) {
                setSearchTagData((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.search_tag_id === type.search_tag_id
                            ? { ...srv, status: newStatus }
                            : srv
                    )
                );
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyComponent variant="h6">Service search tag Management</TypographyComponent>
                <Button variant="contained" color="primary" onClick={handleAddClick}>Add Service search tag</Button>
            </Box>
            {addingServiceSearchTag && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                    <Select
                        value={selectedSearchTag}
                        onChange={(e) => setSelectedSearchTag(e.target.value)}
                        displayEmpty
                        sx={{ mr: 2, width: '20%' }}
                    >
                        <MenuItem value="" disabled>Select Service</MenuItem>
                        {searchTagData.map((service) => (
                            <MenuItem key={service.search_tag_id} value={service.search_tag_id}>
                                {service.search_tag_type_value}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextFieldComponent
                        label="New Search tag"
                        value={newSearchTagInput}
                        onChange={(e) => setNewSearchTagInput(e.target.value)}
                        sx={{ mr: 2, width: '25%' }}
                    />


                    <Button variant="contained" color="primary" onClick={handleAddSaveClick}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={handleAddCancelClick} sx={{ ml: 1 }}>Cancel</Button>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Search tag ID</TableCell>
                            <TableCell>Service </TableCell>
                            <TableCell>Search tag</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchTagData.map((row) => (
                            <TableRow key={row.search_tag_id}>
                                <TableCell>{row.search_tag_id}</TableCell>
                                <TableCell>{row.search_tag_type_value}</TableCell>
                                <TableCell>
                                    {editId === row.search_tag_id ? (
                                        newSearchTags.map((tag, index) => (
                                            <TextFieldComponent
                                                key={index}
                                                value={tag}
                                                onChange={(e) => handleTagChange(index, e.target.value)}
                                                sx={{ mr: 1, width: '150px', gap: '8px' }}
                                            />
                                        ))
                                    ) : (
                                        row.search_tags
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={row.status === 'Active'}
                                        onChange={() => handleStatusToggleService(row)}

                                    />
                                </TableCell>
                                <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.search_tag_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={() => handleEditClick(row)}>Edit</Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
function SecondTabContent({ token }) {
    const [editId, setEditId] = useState(null);
    const [newSearchTagInput, setNewSearchTagInput] = useState('');
    const [addingTypesSearchTag, setAddingTypesSearchTag] = useState(false);
    const [newSearchTags, setNewSearchTags] = useState([]);
    const [searchTagData, setSearchTagData] = useState([]);
    const [selectedSearchTag, setSelectedSearchTag] = useState('');

    

    const handleEditClick = (row) => {
        setAddingTypesSearchTag(false);
        setSelectedSearchTag('');
        setEditId(row.search_tag_id);

        // Check if row.search_tags is not null, undefined, and is a string
        if (row.search_tags && typeof row.search_tags === 'string') {
            console.log(row.search_tags);
            const tagsArray = row.search_tags.split(',').map(tag => tag.trim());
            setNewSearchTags(tagsArray);
        } else {
            // Handle the case where search_tags is null, undefined, or not a string
            console.error('search_tags is null, undefined, or not a string');
            setNewSearchTags([]);  // Optionally set it to an empty array or handle as needed
        }
    };

    const handleTagChange = (index, value) => {
        const updatedTags = [...newSearchTags];
        updatedTags[index] = value;
        setNewSearchTags(updatedTags);
    };
    const handleSaveClick = async (service) => {
        console.log(newSearchTags)
        if (newSearchTags.some(tag => tag === null || tag === undefined || tag.trim() === '')) {
            alert('Please remove any null or empty tags.');
            return;
        }

        // Remove duplicate tags by converting to a Set and back to an array
        const uniqueTags = [...new Set(newSearchTags.map(tag => tag.trim()))];
        try {
            const response = await axios.post(`${API_URL}/admin_update_types_Searchtags`, {
                token,
                search_tag_id: service.search_tag_id,
                search_tags: uniqueTags
            });

            if (response.data.success) {
                // Update the local state with the new service name
                const updatedTagsString = newSearchTags.join(', ');
                setSearchTagData((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.search_tag_id === service.search_tag_id
                            ? { ...srv, search_tags: updatedTagsString }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewSearchTags([]);
    };

    useEffect(() => {
        // fetchAllServices();
        fetchAllTypesSearchtags();
    }, []);
    async function fetchAllTypesSearchtags() {
        try {
            const response = await axios.post(`${API_URL}/admin_get_all_Types_searchtags`, { token });
            console.log("all types searchtag.............",response.data.data);
            if (response.data.success) {
                setSearchTagData(response.data.data);
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    // async function fetchAllServices() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_services`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setServiceData(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    const handleAddClick = () => {
        setEditId(null);
        setNewSearchTags([]);
        setAddingTypesSearchTag(true);
    };

    const handleAddSaveClick = async () => {
        console.log("clicked Add")
        console.log('selectedSearchTag,newSearchTagInput', selectedSearchTag, newSearchTagInput)
        if (newSearchTagInput && !newSearchTags.includes(newSearchTagInput.trim())) {
            try {
                const response = await axios.post(`${API_URL}/admin_add_types_searchtag`, {
                    token,
                    search_tag_id: selectedSearchTag,
                    new_search_tag: newSearchTagInput
                });

                if (response.data.success) {
                    // setServiceDetai([...serviceDetails, response.data.data]);
                    // setAddingService(false);
                    // setSelectedCategory('');
                    // setProfessionalName('');
                    setAddingTypesSearchTag(false);
                } else {
                    console.error('Error:', response.data.error);
                }
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
        else {
            // Handle cases where the input is null or a duplicate
            if (!newSearchTagInput) {
                alert('Search tag cannot be empty.');
            } else if (newSearchTags.includes(newSearchTagInput.trim())) {
                alert('This search tag already exists.');
            }
        }

    };

    const handleAddCancelClick = () => {
        setAddingTypesSearchTag(false);
        setSelectedSearchTag('');
        // setProfessionalName('');
    };
    const handleStatusToggleTypes = async (type) => {
        const newStatus = type.status === 'Active' ? 'Inactive' : 'Active';
        try {
            const response = await axios.post(`${API_URL}/admin_update_search_tag_status`, {
                token,
                type_id: type.types_id,
                status: newStatus
            });

            if (response.data.success) {
                setSearchTagData((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.search_tag_id === type.search_tag_id
                            ? { ...srv, status: newStatus }
                            : srv
                    )
                );
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyComponent variant="h6">Types search tag Management</TypographyComponent>
                <Button variant="contained" color="primary" onClick={handleAddClick}>Add Types search tag</Button>
            </Box>
            {addingTypesSearchTag && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                    <Select
                        value={selectedSearchTag}
                        onChange={(e) => setSelectedSearchTag(e.target.value)}
                        displayEmpty
                        sx={{ mr: 2, width: '20%' }}
                    >
                        <MenuItem value="" disabled>Select Types</MenuItem>
                        {searchTagData.map((service) => (
                            <MenuItem key={service.search_tag_id} value={service.search_tag_id}>
                                {service.search_tag_type_value}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextFieldComponent
                        label="New Search tag"
                        value={newSearchTagInput}
                        onChange={(e) => setNewSearchTagInput(e.target.value)}
                        sx={{ mr: 2, width: '25%' }}
                    />


                    <Button variant="contained" color="primary" onClick={handleAddSaveClick}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={handleAddCancelClick} sx={{ ml: 1 }}>Cancel</Button>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Search tag ID</TableCell>
                            <TableCell>Types </TableCell>
                            <TableCell>Search tag</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchTagData.map((row) => (
                            <TableRow key={row.search_tag_id}>
                                <TableCell>{row.search_tag_id}</TableCell>
                                <TableCell>{row.search_tag_type_value}</TableCell>
                                <TableCell>
                                    {editId === row.search_tag_id ? (
                                        newSearchTags.map((tag, index) => (
                                            <TextFieldComponent
                                                key={index}
                                                value={tag}
                                                onChange={(e) => handleTagChange(index, e.target.value)}
                                                sx={{ mr: 1, width: '150px', gap: '8px' }}
                                            />
                                        ))
                                    ) : (
                                        row.search_tags
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={row.status === 'Active'}
                                        onChange={() => handleStatusToggleTypes(row)}

                                    />
                                </TableCell>
                                <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.search_tag_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={() => handleEditClick(row)}>Edit</Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
function ThirdTabContent({ token }) {

    const [editId, setEditId] = useState(null);
    const [newSearchTagInput, setNewSearchTagInput] = useState('');
    const [addingSpSearchTag, setAddingSpSearchTag] = useState(false);
    const [newSearchTags, setNewSearchTags] = useState([]);
    const [searchTagData, setSearchTagData] = useState([]);
    const [selectedSearchTag, setSelectedSearchTag] = useState('');

    const handleEditClick = (row) => {
        setAddingSpSearchTag(false);
        setSelectedSearchTag('');
        setEditId(row.search_tag_id);

        // Check if row.search_tags is not null, undefined, and is a string
        if (row.search_tags && typeof row.search_tags === 'string') {
            console.log(row.search_tags);
            const tagsArray = row.search_tags.split(',').map(tag => tag.trim());
            setNewSearchTags(tagsArray);
        } else {
            // Handle the case where search_tags is null, undefined, or not a string
            console.error('search_tags is null, undefined, or not a string');
            setNewSearchTags([]);  // Optionally set it to an empty array or handle as needed
        }
    };

    const handleTagChange = (index, value) => {
        const updatedTags = [...newSearchTags];
        updatedTags[index] = value;
        setNewSearchTags(updatedTags);
    };
    const handleSaveClick = async (service) => {
        console.log(newSearchTags)
        if (newSearchTags.some(tag => tag === null || tag === undefined || tag.trim() === '')) {
            alert('Please remove any null or empty tags.');
            return;
        }

        // Remove duplicate tags by converting to a Set and back to an array
        const uniqueTags = [...new Set(newSearchTags.map(tag => tag.trim()))];
        try {
            const response = await axios.post(`${API_URL}/admin_update_sp_Searchtags`, {
                token,
                search_tag_id: service.search_tag_id,
                search_tags: uniqueTags
            });

            if (response.data.success) {
                // Update the local state with the new service name
                const updatedTagsString = newSearchTags.join(', ');
                setSearchTagData((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.search_tag_id === service.search_tag_id
                            ? { ...srv, search_tags: updatedTagsString }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewSearchTags([]);
    };

    useEffect(() => {
        // fetchAllServices();
        fetchAllSpSearchtags();
    }, []);
    async function fetchAllSpSearchtags() {
        try {
            const response = await axios.post(`${API_URL}/admin_get_all_sp_searchtags`, { token });
            console.log(response.data.data);
            if (response.data.success) {
                setSearchTagData(response.data.data);
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }


    const handleAddClick = () => {
        setEditId(null);
        setNewSearchTags([]);
        setAddingSpSearchTag(true);
    };

    const handleAddSaveClick = async () => {
        console.log("clicked Add")
        console.log('selectedSearchTag,newSearchTagInput', selectedSearchTag, newSearchTagInput)
        if (newSearchTagInput && !newSearchTags.includes(newSearchTagInput.trim())) {
            try {
                const response = await axios.post(`${API_URL}/admin_add_sp_searchtag`, {
                    token,
                    search_tag_id: selectedSearchTag,
                    new_search_tag: newSearchTagInput
                });

                if (response.data.success) {
                    // setServiceDetai([...serviceDetails, response.data.data]);
                    setAddingSpSearchTag(false);
                    setSelectedSearchTag('');
                } else {
                    console.error('Error:', response.data.error);
                }
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
        else {
            // Handle cases where the input is null or a duplicate
            if (!newSearchTagInput) {
                alert('Search tag cannot be empty.');
            } else if (newSearchTags.includes(newSearchTagInput.trim())) {
                alert('This search tag already exists.');
            }
        }
    };

    const handleAddCancelClick = () => {
        setAddingSpSearchTag(false);
        setSelectedSearchTag('');
        // setProfessionalName('');
    };
    const handleStatusToggleSp = async (type) => {
        const newStatus = type.status === 'Active' ? 'Inactive' : 'Active';
        try {
            console.log("type", type)
            const response = await axios.post(`${API_URL}/admin_update_search_tag_status`, {
                token,
                type_id: type.search_tag_id,
                status: newStatus
            });

            if (response.data.success) {
                setSearchTagData((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.search_tag_id === type.search_tag_id
                            ? { ...srv, status: newStatus }
                            : srv
                    )
                );
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyComponent variant="h6">Service provider search tag Management</TypographyComponent>
                <Button variant="contained" color="primary" onClick={handleAddClick}>Add Service provider search tag</Button>
            </Box>
            {addingSpSearchTag && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                    <Select
                        value={selectedSearchTag}
                        onChange={(e) => setSelectedSearchTag(e.target.value)}
                        displayEmpty
                        sx={{ mr: 2, width: '20%' }}
                    >
                        <MenuItem value="" disabled>Select Service Provider</MenuItem>
                        {searchTagData.map((service) => (
                            <MenuItem key={service.search_tag_id} value={service.search_tag_id}>
                                {service.search_tag_type_value}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextFieldComponent
                        label="New Search tag"
                        value={newSearchTagInput}
                        onChange={(e) => setNewSearchTagInput(e.target.value)}
                        sx={{ mr: 2, width: '25%' }}
                    />


                    <Button variant="contained" color="primary" onClick={handleAddSaveClick}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={handleAddCancelClick} sx={{ ml: 1 }}>Cancel</Button>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Search tag ID</TableCell>
                            <TableCell>Service provider </TableCell>
                            <TableCell>Search tag</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchTagData.map((row) => (
                            <TableRow key={row.search_tag_id}>
                                <TableCell>{row.search_tag_id}</TableCell>
                                <TableCell>{row.search_tag_type_value}</TableCell>
                                <TableCell>
                                    {editId === row.search_tag_id ? (
                                        newSearchTags.map((tag, index) => (
                                            <TextFieldComponent
                                                key={index}
                                                value={tag}
                                                onChange={(e) => handleTagChange(index, e.target.value)}
                                                sx={{ mr: 1, width: '150px', gap: '8px' }}
                                            />
                                        ))
                                    ) : (
                                        row.search_tags
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={row.status === 'Active'}
                                        onChange={() => handleStatusToggleSp(row)}

                                    />
                                </TableCell>
                                <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.search_tag_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={() => handleEditClick(row)}>Edit</Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}


export default AdminSearchTags;