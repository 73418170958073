import { Box, Button, Menu, MenuItem, Paper, Step, StepContent, StepIcon, StepLabel, Stepper, Typography, styled } from '@mui/material'
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypographyComponent from './TypographyComponent';
import { useState, useEffect } from 'react';
import camera from '../Images/camera_Icon.png';
import profile from '../Images/profile.png';
import Colors from './Colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';
import { API_URL } from './Constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const CustomIcon = styled(DoneIcon)({
    color: Colors.primary, // Change the color of the icon
    // border:'1px solid blue',
    borderRadius: '50px',
    background: Colors.quinary
});
const SideBarBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px'
}));
const ImageBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: { md: 'column', xs: 'row' },
    alignItems: 'center',
    height: { xs: '60px', md: '136px', sm: '136px' },
    width: { xs: '60px', md: '136px', sm: '136px' },
}));
const ProgressBarBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: 'column',
    alignItems: 'left',
}));
const CompletedIcon = styled('div')({
    '& svg': {
        fill: 'green' // Apply the color you want for completed steps' icons
    }
});

const AssociateSideBar = ({ currentStage, serviceProviderDtls, setNewProfilePic, setUpdateImage, newProfilePic, setSelectedImage,
     selectedImage, selectedBgButton, businessName }) => {
    console.log("serviceProviderDtls",selectedBgButton)
    
    const theme = useTheme();
    // console.log("newprofile pic check   ,.,.,,.", newProfilePic)
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //  console.log("extrasmall", isXsScreen)
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //  console.log('small', isSmScreen)
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    //  console.log('med', isMdScreen)
    const SpId = localStorage.getItem('ASPID');

    const avatarSize = isSmScreen ? '48px' : '136px';

    const orientation = isSmScreen ? 'horizontal' : 'vertical';
    const width = isSmScreen ? '100%' : '400';
    const stages = [
        { number: 1, name: "Profile" },
        { number: 2, name: "Add your location" },
        { number: 3, name: "Add/Edit skills" }
    ];

    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleImageUpload = (e) => {
        setUpdateImage(true);
        handleMenuClose();
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', (event) => {
            const imageUrl = URL.createObjectURL(event.target.files[0]);
            //  console.log("imageUrl...........", imageUrl);
            setSelectedImage(imageUrl);
            // onImageChange(imageUrl);
            setNewProfilePic(imageUrl)
        });
        input.click();
    };

    const handleChangeImage = () => {
        // Logic for changing the image
        handleMenuClose();
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const fetchProfileImage = async () => {
        try {
            const response = await axios.post(`${API_URL}/sp-details-with-SpId`, { SpId });
            console.log(response)
            const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
            // console.log("imgurl", imgurl);
            setNewProfilePic(imgurl);
            setSelectedImage(imgurl);
        }
        catch (error) {

        }
    }
    const handleDeleteImage = async () => {
        // Logic for deleting the image
        try {
            const response = await axios.post(`${API_URL}/remove_profile_image_assossiate`, { SpId });
            //  console.log("kmkl", response)
            if (response.data.success) {
                
                console.log("success");
                fetchProfileImage();

            }
        }
        catch (error) {
            console.log("error", error)
        }
        handleMenuClose();
    };

    const steps = [
        'Profile',
        'Add your location',
        'Add/Edit skills',
    ];

    const [isProfileEdit, setIsProfileEdit] = useState(false);

    useEffect(() => {
        if (serviceProviderDtls && serviceProviderDtls.profile_image) {
            if (serviceProviderDtls.service_details.length > 0) {
                setIsProfileEdit(true)
            }

        }
    }, [serviceProviderDtls]);
    const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous screen
  };
  const colors = [ Colors.card_color_1,
    Colors.card_color_2,
    Colors.card_color_3,
    Colors.card_color_4,
    Colors.card_color_5];
    const cardColor = selectedBgButton ? selectedBgButton.replace('b', '') : '1';
    const profileIndex = parseInt(cardColor-1);
console.log(profileIndex)
    return (
        <>
            <Box sx={{ paddingLeft: '5%' }}>

                <TypographyComponent sx={{
                    color: Colors.black_80, fontSize: '16px',
                    display: isSmScreen ? 'flex' : 'none'
                }}>
                     <ArrowBackIcon  sx={{ marginRight: '8px', cursor: 'pointer' }}
                       onClick={handleBackClick}/>
                    Complete your profile
                </TypographyComponent>
            </Box>

            <SideBarBox flex={2} p={{ xs: '5%', sm: '1%', md: '1%' }} sx={{
                flexDirection: isSmScreen ? 'row' : 'column',
                gap: isSmScreen ? '10px' : '30px'
            }}>
                  <TypographyComponent sx={{
                    color: Colors.black_80, fontSize: '16px',
                    display: isSmScreen ? 'none' : 'flex'
                }}>
                     <ArrowBackIcon  sx={{ marginRight: '8px', cursor: 'pointer' }}
                       onClick={handleBackClick}/>
                    Complete your profile
                </TypographyComponent>
               { currentStage === 1 && <ImageBox>
                    <Box>
                        <label htmlFor="imageUpload">
                            <img src={camera} alt="camera" style={{
                                width: isSmScreen ? '20px' : '40px',
                                height: isSmScreen ? '20px' : '40px',
                                position: 'absolute', bottom: '10%', left: '72%',
                                transform: 'translate(-50%, 50%)', zIndex: 1, cursor: 'pointer'
                            }} onClick={handleMenuOpen} />
                        </label>                                         
                       
                        <Menu
                            anchorEl={anchorEl}
                            open={isMenuOpen}
                            onClose={handleMenuClose}
                            PaperProps={{
                                sx: {
                                    position: 'absolute',
                                    right: '30px',
                                    top: '65px',
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    width: '170px',
                                    backgroundColor: Colors.grey_20,
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    zIndex: 1,
                                    color: 'white',
                                    padding: '8px',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={handleImageUpload}
                                sx={{
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: Colors.black_80,
                                    minHeight: '20px',
                                    borderRadius: '4px',

                                    '&:hover': {
                                        backgroundColor: '#415FE2 !important ',
                                        color: '#FFFFFF !important',
                                        borderRadius: '8px'
                                    },
                                    '&:focus': {
                                        backgroundColor: 'transparent', // Ensure background is transparent on focus
                                        // color: '#FFFFFF !important',
                                    },
                                }}
                            >
                                Upload Image
                            </MenuItem>
                            <MenuItem
                                onClick={handleDeleteImage}
                                sx={{
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: Colors.black_80,
                                    minHeight: '20px',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        backgroundColor: '#415FE2',
                                        color: '#FFFFFF !important',
                                        borderRadius: '8px'
                                    },
                                }}
                            >
                                Remove Image
                            </MenuItem>
                        </Menu>

                    </Box>
                    {newProfilePic && (!newProfilePic.endsWith('undefined') &&
                        !newProfilePic.endsWith('null')) ? (
                        <img
                            src={newProfilePic}
                            alt="1stProfile"
                            style={{
                                color: Colors.grey_60,
                                width: isSmScreen ? '48px' : '136px',
                                height: isSmScreen ? '48px' : '136px',
                                zIndex: 0,
                                borderRadius: '100px'
                            }}
                        />
                    ) : (
                        
                        <Box
                            sx={{
                                width: avatarSize,
                                height: avatarSize,
                                borderRadius: '100px',
                                backgroundColor: profileIndex !== null ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: isSmScreen ? '24px' : '48px',
                                fontWeight: 'bold',
                                color: Colors.white,
                              //  border: '2px solid #ffffff',
                              //  boxShadow: '0px 4px 4px 0px #00000040',
                            }}
                        >
                          {serviceProviderDtls?.business_name
  ? serviceProviderDtls.business_name.charAt(0).toUpperCase() 
  : (businessName ? businessName.charAt(0).toUpperCase() : '')}
                        </Box>
                    )}
                </ImageBox>}

                { currentStage !== 1 &&
                 <Box sx={{ width: { md: '136px', sm: '100px', xs: '80px' } }}>
                 {!newProfilePic && (
                     // <AccountCircleIcon sx={{ color: Colors.grey_60, 
                     //     width: { md: '136px', sm: '100px', xs: '80px' }, 
                     //     height: { md: '136px', sm: '100px', xs: '80px' } }} />
                     <Box
                     sx={{
                         width: avatarSize,
                         height: avatarSize,
                         borderRadius: '50%',
                         backgroundColor:  (profileIndex !== null || profileIndex !== undefined ) ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         fontSize: isSmScreen ? '24px' : '48px',
                         fontWeight: 'bold',
                         color: Colors.white,
                        // border: '2px solid #ffffff',
                        // boxShadow: '0px 4px 4px 0px #00000040',
                     }}
                 >
                    {serviceProviderDtls?.business_name
  ? serviceProviderDtls.business_name.charAt(0).toUpperCase() 
  : (businessName ? businessName.charAt(0).toUpperCase() : '')}
                 </Box>
                 )}
                 {newProfilePic && (
                     <Box sx={{ width: { md: '136px', sm: '100px', xs: '60px' }, height: { md: '136px', sm: '100px', xs: '60px' }, objectFit: 'cover', borderRadius: '50%' }}>
                         <img src={newProfilePic} alt="" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                     </Box>
                 )}
             </Box>

                  }

              


                <Box sx={{ maxWidth: { width }, height: 'auto' }}>


                    <Box id='stagesBox' sx={{
                        display: 'flex', flexDirection: { xs: 'row', sm: 'row', md: 'column' },
                        alignItems: 'left'
                    }}>
                        {stages.map((stage, index) => (
                            <React.Fragment key={stage}>
                                <Box id='checkdoneBox' sx={{
                                    display: 'flex', alignItems: 'center',
                                    flexDirection: { xs: 'column', sm: 'column', md: 'row' }
                                }}>
                                    {isProfileEdit ? (
                                        <Box id="isedDone"
                                            sx={{
                                                width: '31px',
                                                height: '31px',
                                                borderRadius: '50%',
                                                backgroundColor: Colors.quinary,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: index === currentStage - 1 ? '1px solid ' : 'none',
                                                borderColor: Colors.secondary,
                                                color: Colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                        </Box>
                                    ) : index < currentStage - 1 ? (
                                        <Box id='doneB'
                                            sx={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: Colors.quinary,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: Colors.primary,
                                                fontWeight: 400,
                                            }}
                                        >
                                            <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                        </Box>
                                    ) : index === currentStage - 1 || index === currentStage || index === currentStage + 1 ? (
                                        <Box id='doneIc'
                                            sx={{
                                                width: '31px',
                                                height: '31px',
                                                borderRadius: '50%',
                                                backgroundColor: Colors.quinary,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: index === currentStage - 1 ? '1px solid ' : 'none',
                                                borderColor: Colors.secondary,
                                                color: Colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {stage.number}
                                        </Box>
                                    ) : (
                                        <Box id='doneIcon'
                                            sx={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: Colors.quinary,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: Colors.primary,
                                                fontWeight: 400,
                                            }}
                                        >
                                            <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                        </Box>
                                    )}
                                    {/* <Box id='nameBox' sx={{ paddingLeft: '10px' }}>
                                        {stage.name}
                                    </Box> */}
                                    <TypographyComponent sx={{
                                        paddingLeft: '10px', fontSize: { md: '16px', sm: '16px', xs: '10px' },
                                        color: Colors.black_70
                                    }}> {stage.name}</TypographyComponent>
                                </Box>
                                {index < stages.length - 1 && (
                                    // <Box
                                    //     sx={{
                                    //         marginLeft: '15px',
                                    //         width: '2px',
                                    //         height: '100px', // Adjust as needed
                                    //         backgroundColor: Colors.quinary,
                                    //     }}
                                    // />
                                    <Box id='lineBox'
                                        sx={{
                                            margin: isSmScreen || isMdScreen ? '15px 0px 15px 0px' : '0px 15px 0px 15px',
                                            width: isSmScreen || isMdScreen ? '50%' : '2px',
                                            height: isSmScreen || isMdScreen ? '2px' : '100px',
                                            backgroundColor: Colors.quinary,
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </Box>

                </Box>

            </SideBarBox>
        </>
    )
}

export default AssociateSideBar