import React from "react";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Colors from "./Colors";

function TextFieldComponent({ onChange, InputProps, sx, error, multiline, ...props }) {
  return (
    <TextField
      variant="outlined"
      placeholder={props.placeholder}
      onChange={onChange}
      InputProps={InputProps}
      multiline={multiline}
      rows={multiline ? 4 : 1}
      sx={{
        boxShadow: 'none',
        width: '100%',
        height: multiline ? 'auto' : '48px',    
        '& .MuiOutlinedInput-root': {
          borderRadius: '28px',
          background: 'white',     
          '& fieldset': {
            borderColor: error ? '#FF4105 !important' : '#CFC9C9', // Custom border color
          },
          '&:hover fieldset': {
            borderColor: error ? '#FF4105' : '#313131', // Custom border color on hover
          },
          // '&.Mui-focused fieldset': {
          //   borderColor: error ? Colors.error : '#CFC9C9', // Custom border color when focused
          // },
          height: multiline ? 'auto' : '48px',
          color: '#313131',
          fontFamily: 'Roboto',
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: '400',
         
        },
        // '& .MuiOutlinedInput-notchedOutline': {
        //   borderColor: error ? Colors.error : '#CFC9C9', // Custom border color
        // },
        
        ...sx,
      }}
      {...props}
    />
  );
}

export default TextFieldComponent;
