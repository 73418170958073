import { Grid, Box, Typography, Select, MenuItem, TextField, InputAdornment } from "@mui/material"
import SpHeader from "../Components/SpHeader";
import axios from "axios";
import { API_URL } from './Constants';
import SelectComponent from "../Components/SelectComponent";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import React from "react";
import { useState, useEffect } from "react";
import StepperPhase2 from "../Components/StepperPhase2";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Googlemap from "../Components/GooglemapComponent";


function AddLocationPhase2() {

    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);

    const [choosedLocation, setChoosedLocation] = useState(null);
    const [selectedLatitude, setSelectedLatitude] = useState(null);
    const [selectedLongitude, setSelectedLongitude] = useState(null);

    async function fetchServiceProviderDetails() {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
         // console.log("service-provider-details", response.data)
         // console.log("service-provider-details", response.data.data.serviceProvider.service_details.length)
          if(response.data.data.serviceProvider.service_details.length === 0 ){
            setIsCompleteProfile(false)
        }
          if (response.data.success) {
            setServiceProviderDtls(response.data.data.serviceProvider)
            setMessageDetails(response.data.data.serviceProvider.message_details)
           
            
            const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
              if (message.read === 'Unread') {
                  return acc + 1;
              }
              return acc;
          }, 0);
          setUnreadMessageCount(unreadCount);
          
        }
        else {
            console.error('Error:', response.data.error);
          }
     } 
     catch (error) {
          console.error('Error:', error.message);
        }
      }
      useEffect(() => {
        fetchServiceProviderDetails();
      }, []);


      const [service, setService] = useState([]);   
      const [serviceName, setServiceName] = useState(''); 
      const [serviceId, setServiceId] = useState('');      
      const [serviceError, setServiceError] = useState(""); 

      const fetchServices = async () => {
        try {
          const response = await axios.get(`${API_URL}/select_all_services`);
          setService(response.data);         
        } catch (error) {
          console.error('Error fetching service categories:', error);
        }
      };
    
      useEffect(() => {
        fetchServices();
      }, []);

      const handleInputServiceChange = async (name, id) => {         
            setServiceName(name);
            setServiceId(id);            
            setServiceError('');
      };

    return (

        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete = {40}
              unreadMessageCount={unreadMessageCount} isCompleteProfile={isCompleteProfile}>                
            </SpHeader>
            <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', 
                width:'100%', height:'100%', background: Colors.grey_20  }}>
                     <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', 
                      width:'100%', maxWidth:'96%',height:'100%', maxHeight:'94%', paddingTop:'2.5%', paddingBottom:'2.5%' ,gap:'20px'}}>
                        <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'left', 
                          width:'100%',height:'10%',gap:'12px'}}>
                            <TypographyHeading>Complete your profile</TypographyHeading>
                            <Breadcrumbs separator={<span style={{ paddingLeft: '2px', fontSize: '32px', lineHeight:'18px', 
                            color: Colors.default  }}>›</span>}  aria-label="breadcrumb">
                            <Link underline="hover"
                              style={{ display: 'flex', alignItems: 'center', fontWeight:400, fontSize:'16px',fontFamily:'Roboto',
                                color: Colors.default , textUnderlineOffset:'5px', gap:'2px' }}                              
                              href="/">
                              <HomeOutlinedIcon sx={{ color: Colors.default , fontSize: '18px'}}  />
                              Home
                            </Link>
                            <Typography sx={{ display: 'flex', alignItems: 'center' ,fontFamily:'Roboto',  
                            fontWeight:400, fontSize:'16px', color: Colors.primary, gap:'2px' }}>
                              <EditOutlinedIcon sx={{ color: Colors.primary , fontSize: '18px'}}  />
                              Edit Profile
                            </Typography> 
                         </Breadcrumbs>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:'stretch', 
                          width:'100%',height:'90%', gap:'16px'}}>
                            <Box sx={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:'center', 
                            width:'20%',flex:1,background: Colors.white,paddingTop:'2.5%'}}>
                                 <Box sx={{display:'flex',flexDirection:'column', width:'100%',height:'100%',
                                    maxWidth:'80%',}}>
                             <StepperPhase2 currentStage={0}></StepperPhase2>   
                             </Box>
                            </Box>  
                            <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', 
                            width:'80%',height:'97.5%',background: Colors.white, paddingTop:'2.5%',paddingBottom:'2.5%'}}>
                                 <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', 
                            width:'100%',height:'100%', maxWidth:'90%'}}>
                             <Googlemap key={'googlemap123'} serviceProviderDtls={serviceProviderDtls} 
                                editworkingradius = {true} setChoosedLocation = {setChoosedLocation}  setSelectedLatitude = {setSelectedLatitude}
                                setSelectedLongitude = {setSelectedLongitude}  > </Googlemap>
                        </Box>
                            </Box>   

                        </Box>

                     </Box>

            </Box>














            

        </Grid>
  )
} export default AddLocationPhase2;