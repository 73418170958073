import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, StepConnector, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Colors from './Colors';



const steps = ['Add your location', 'Add your personal details', 'Add your skills'];

// Custom Step Icon Component
const CustomStepIcon = ({ active, completed }) => {
    const iconStyle = {
        backgroundColor: Colors.grey_30,
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        border: active ?'2px solid':'0px solid',
        borderColor: Colors.grey_60,
    };

    const innerCircleStyle = {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: completed ? 'green' : active ? 'blue' : Colors.grey_30,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: completed ? 'none' : 'block',
    };
    const activeCircleStyle = {
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        backgroundColor: 'blue',
        border: '2px solid white',
        display: active ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };



    return (
        <div style={iconStyle}>
            {completed ? (
                <>
                    <div style={innerCircleStyle}></div>
                    <CheckCircleIcon style={{ color: 'green', fontSize: '18px' }} />
                </>
            ) : (
                <>
                    <div style={innerCircleStyle}></div>
                    <div style={activeCircleStyle}>
                        <RadioButtonUncheckedIcon style={{ color: 'white', fontSize: '18px' }} />
                    </div>
                </>
            )}
        </div>
    );
};

const CustomStepConnector = () => {
    return (
        
        <StepConnector
        sx={{
          marginLeft: '15px',
          '& .MuiStepConnector-line': {
            display: 'block',
            borderColor: Colors.grey_60,
            borderLeftStyle: 'solid',
            borderLeftWidth: '3px',
            minHeight: '110px',
          },
        }}
      />
    );
};

const CustomStepConnectorMobile = () => {
    return (
        
        <StepConnector
        sx={{
           marginBottom:'18px',
          '& .MuiStepConnector-line': {
            display: 'block',
            borderColor: Colors.grey_60,
            borderLeftStyle: 'solid', 
            borderTopWidth:'3px', 
            minWidth:'100%' ,
            marginLeft:'-100%',
            marginRight:'-100%'       
           
          },
        }}
      />
    );
};



const StepperPhase2 = ({ currentStage }) => {
    const [activeStep, setActiveStep] = useState(currentStage);    
  

    return (
        <>
        <Box sx={{display:{ md: 'flex', sm: 'flex', xs: 'none' }, flexDirection:'column', justifyContent:'center', alignItems:'left', gap:'24px'}}>
             <Typography sx={{ fontFamily: 'Roboto', fontSize: '18px', fontWeight: 500, color: Colors.black_70,}}>
             Complete your profile
             </Typography>
            <Stepper orientation='vertical' activeStep={activeStep} connector={<CustomStepConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>                       
                        <StepLabel StepIconComponent={CustomStepIcon} style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: 0 }}>
                            <Typography sx={{fontFamily: 'Roboto', fontSize: '14px', fontWeight: 500, color: Colors.black_70,}}>
                                {label}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        <Box sx={{display:{ md: 'none', sm: 'none', xs: 'flex' }, flexDirection:'column', justifyContent:'center', alignItems:'left', gap:'24px'}}>
             <Typography sx={{ fontFamily: 'Roboto', fontSize: '18px', fontWeight: 500, color: Colors.black_70,}}>
             Complete your profile
             </Typography>
           <Stepper orientation="horizontal" activeStep={activeStep} connector={<CustomStepConnectorMobile />}>
        {steps.map((label, index) => (
          <Step key={label} sx={{ padding: 0 }}>
            <StepLabel StepIconComponent={CustomStepIcon} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0,  margin: 0, }}>
              <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: 500, color: Colors.black_70, paddingTop:'8px' }}>
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper> 
       
            
        </Box>
        </>
    );
};

export default StepperPhase2;
