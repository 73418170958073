import React from "react";
import IconButton from '@mui/material/IconButton';

function ImageButton({ onClick, imgSrc, altText, ...props }) {
    return (
        <IconButton 
            onClick={onClick}
            {...props}
        >
            <img src={imgSrc} alt={altText} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </IconButton>
    );
}

export default ImageButton;
