

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Colors from './Colors';

import adminlogo from '../Images/admin_logo.png'


const pages = ['Home', 'About us', 'Contact us'];

function AdminHead(props) {
  const {  currentPage } = props
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState('');


  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleMenuItemClick = (page) => {
    setSelectedPage(page);
  };
  const [showDropdown, setShowDropdown] = useState(false);

 
  const dropdownRef = useRef(null);
  const profilePicRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      profilePicRef.current &&
      !profilePicRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  return (
    <AppBar position="static" sx={{
      backgroundColor: Colors.black_70,
      height: { xs: '48px', md: '86px' }, justifyContent: 'center',
      boxShadow: 'none', background: currentPage === 'Home' ? 'white' : 'transparent'
    }}>
      <Container maxWidth="94%" sx={{ backgroundColor: Colors.black_70, marginLeft: '0px', height: { xs: '48px', md: '86px' } }}>
        <Toolbar disableGutters sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ width: { xs: '92px', md: '220px' }, height: { xs: '36px', md: '38px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={adminlogo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'left', alignItems: 'center' }}>
            <IconButton
              size="large"
              aria-label="open drawer"
              onClick={handleOpenDrawer}
              color={Colors.black_80}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={openDrawer}
              onClose={handleCloseDrawer}
              sx={{
                '& .MuiDrawer-paper': {
                  backgroundColor: Colors.secondary, // Your desired background color
                },
                backdropFilter: 'none', // Remove blur effect
              }}
              BackdropProps={{ // Customize backdrop properties
                sx: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, // Semi-transparent black background
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <IconButton
                  aria-label="close drawer"
                  onClick={handleCloseDrawer}
                  sx={{ ml: 'auto', color: 'white' }} // Move the close button to the right
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{ width: 200 }}
              >

                {pages.map((page) => (
                  <Link
                    key={page}
                    to={page === 'Home' ? '/sp-landing' : page === 'About us' ? '/spabout-us' : '/spcontact-us'}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <MenuItem
                      selected={selectedPage === page}
                      onClick={() => handleMenuItemClick(page)}
                      sx={{
                        color: "white", display: "block", textTransform: "none", background: currentPage === page ? '#FFFFFF33' : Colors.secondary,
                        fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: currentPage === page ? 600 : 400,
                        '&:hover': { color: "white", bgcolor: 'white', fontWeight: "600" }
                      }}
                    >
                      {page}
                    </MenuItem>
                  </Link>
                ))}
              </Box>
            </Drawer>
          </Box>

          <Box sx={{
            flexGrow: 1, display: { xs: 'none', md: 'flex' }, height: '100%', paddingLeft: '54px', justifyContent: 'left', alignItems: 'center'
          }}>

          </Box>
        
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AdminHead;

