import { Box, Grid, InputBase, Divider, } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import axios from "axios";
import { API_URL } from './Constants';
import { useEffect, useState } from "react";
import SpBusinessCardFront1 from '../Components/SpBusinessCardFront1';
import SpBusinessCardFront2 from '../Components/SpBusinessCardFront2';
import SpBusinessCardFront3 from '../Components/SpBusinessCardFront3';
import SpBusinessCardFront4 from '../Components/SpBusinessCardFront4';
import SpBusinessCardFront5 from '../Components/SpBusinessCardFront5';
import SpBusinessCardBack from "../Components/SpBusinessCardBack";
import SpMessage from "../Components/SpMessage";
import Slider from '@mui/material/Slider';
import GoogleMap from '../Components/GooglemapComponent';
import ButtonComponent from "../Components/ButtonComponent";
import SpHeader from "../Components/SpHeader";
import Colors from "../Components/Colors";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SpHeaderMobile from "../Components/SpHeaderMobile";
import SpMessageMobile from "../Components/SpMessagesMobile";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import SpFooter from "../Components/SpFooter";
import AddIcon from '@mui/icons-material/Add';

function SpLanding() {

  const [isCompleteProfile, setIsCompleteProfile] = useState(true);
  const token = localStorage.getItem('token');  
  const [buttonNumber, setButtonNumber] = useState(null);
  const [serviceSkillButtonName, setServiceSkillButtonName] = useState('');
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);
  const [serviceDetailsDataToShow, setServiceDetailsDataToShow] = useState([]);
  const [selectedLatitude, setSelectedLatitude] = useState(null);
  const [selectedLongitude, setSelectedLongitude] = useState(null);
  const [choosedLocation, setChoosedLocation] = useState(null);
  const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
  const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [messageDetails, setMessageDetails] = useState([]);
  const [editMap, setEditMap] = useState(false);
  const [editMapTry, setEditMapTry] = useState(false);
  const [percentageset, setPercentageset] = useState(0);
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [province, setProvince] = useState("");
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [serviceProviderUrl, setServiceProviderUrl] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [welcomePopUp, setWelcomePopUp] = useState(false);
  const [provinceError, setProvinceError] = useState("");
  const [city, setCity] = useState('');
  const [selectedBgButton, setSelectedBgButton] = useState(null)
  const [serviceName, setServiceName] = useState('');
  const [workingRadius, setWorkingRadius] = useState('');
  const [provinceName, setProvinceName] = useState('');

  async function fetchServiceProviderDetails() {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
    //  console.log("service-provider-details", response.data)
    //  console.log("card_no", response.data.data.serviceProvider.card_number)
      if (response.data.success) {
     //   console.log("service-provider-details from landing", response.data.data.serviceProvider)
        console.log("service-details", response.data.data.serviceProvider.service_details)
       // console.log("qr", response.data.data.qrCode)
       // console.log("card_no", response.data.data.serviceProvider.card_number)
        const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
        setServiceProviderDtls(response.data.data.serviceProvider)
        setServiceDetails(response.data.data.serviceProvider.service_details)
        setCity(response.data.data.serviceProvider.city)
      //  setMessageDetails(response.data.data.serviceProvider.message_details)
      // setMessageDetails(
      //   response.data.data.serviceProvider.message_details.sort((a, b) => b.message_id - a.message_id)
      // );
      const sortedMessages = response.data.data.serviceProvider.message_details.sort((a, b) => a.message_id - b.message_id);
      // Update the state with sorted messages
      setMessageDetails(sortedMessages);

      //  console.log(response.data.data.serviceProvider.message_details)
       
        if (response.data.data.serviceProvider.service_details.length === 0) {
          setIsCompleteProfile(false)
        }
        const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
          if (message.read === 'Unread') {
            return acc + 1;
          }
          return acc;
        }, 0);
        setUnreadMessageCount(unreadCount);      
        setServiceDetailsDataToShow(response.data.data.serviceProvider.service_details)
        setServiceProviderQrCode(response.data.data.qrCode)
       // console.log(response.data.data.qrCode)
        setServiceProviderUrl(response.data.data.serviceProviderURL)
       // console.log(response.data.data.serviceProviderURL)
        setServiceName(response.data.data.services)
        setServiceSkillButtonName(response.data.data.services)
        setButtonNumber(response.data.data.serviceProvider.card_number)
        setWorkingRadius(response.data.data.serviceProvider.working_radius)
        setSelectedBgButton(response.data.data.serviceProvider.card_color)
        console.log(response.data.data.serviceProvider.card_color)
      //  console.log(response.data.data.serviceProvider.longitude)
        // setSelectedLatitude(response.data.data.serviceProvider.latitude)
        // setSelectedLongitude(response.data.data.serviceProvider.longitude)
        setBusinessName(response.data.data.serviceProvider.business_name);
        setSignInEmail(response.data.data.serviceProvider.email);
        setSignInPassword(response.data.data.serviceProvider.password);
        setCountry(response.data.data.serviceProvider.country);
        setCountryCode(response.data.data.serviceProvider.country_code);
        setPhoneNumber(response.data.data.serviceProvider.phone_number);
        setPostalCode(response.data.data.serviceProvider.postal_code);
        setProvince(response.data.data.serviceProvider.province);
        setNewProfilePic({ imageUrl: imgurl });
      //  console.log(response.data.data.serviceProvider.service_details)
        if (response.data.data.serviceProvider.service_details.length === 0) {
          setWelcomePopUp(true)
        }
      } else {
        console.error('Error:', response.data.error);
      }



    } catch (error) {
      console.error('Error:', error.message);
    }
  }
  useEffect(() => {
    const intervalId = setInterval(fetchServiceProviderDetails, 1000);
    return () => clearInterval(intervalId); 
  }, [choosedLocation, workingRadius, messageDetails])
  // useEffect(() => {
  //   // Define a function to fetch service provider details
  //   const fetchDetails = () => {
  //     fetchServiceProviderDetails();
  //   };
  
  //   // Call fetchDetails after 3 seconds of page loading
  //   const timer = setTimeout(fetchDetails, 500);
  
  //   // Clean up the timer to prevent memory leaks
  //   return () => clearTimeout(timer);
  
  // }, [choosedLocation, workingRadius, messageDetails]);
  

  useEffect(() => {
    localStorage.removeItem('addservice');
    localStorage.removeItem('editservice');

    if (serviceProviderDtls.postal_code === null || serviceProviderDtls.postal_code === undefined) {
      setPercentageset(50)

    }
    else if (serviceProviderDtls.city === null || serviceProviderDtls.city === undefined) {

      setPercentageset(65)

    }
    else if (!serviceProviderDtls.service_details || serviceProviderDtls.service_details.length === 0) {

      setPercentageset(80)

    }
    else {

      setPercentageset(100)
    }
  }, []);


  const handleDistanceChange = async (event) => {
    setWorkingRadius(event.target.value)
   // console.log("handleDistanceChangeevent", event.target.value)

  };

  const handleMapEditClick = () => {
    setEditMap(true)
    localStorage.setItem('edit', 'true');
   // console.log("Edit button clicked");
  };
  const handleMapDoneClick = async () => {
    setEditMap(true)
    try {
      const response = await axios.post(`${API_URL}/add-location`, {
        workingRadius: workingRadius,
        city: choosedLocation,
        latitude: selectedLatitude,
        longitude: selectedLongitude,
        token: token,
      });
     // console.log("response", response);
      if (response.data.success === true) {
        setEditMap(false)
      }

    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleWelcomPopUpButtonClick = () => {
    localStorage.setItem('addservice', 'true');
    if (serviceProviderDtls.postal_code === null || serviceProviderDtls.postal_code === undefined) {
      setPercentageset(50)
      window.location.href = `/profile-form/false`;
    }
    else if (serviceProviderDtls.city === null || serviceProviderDtls.city === undefined) {
      window.location.href = '/add-location';
      setPercentageset(65)
    }
    else if (!serviceProviderDtls.service_details || serviceProviderDtls.service_details.length === 0) {
      localStorage.setItem('addservice', 'true');
      window.location.href = '/add-service';
      setPercentageset(80)
    }
    else {
      setPercentageset(100)
      setIsCompleteProfile(true)
    }

  };

  const [isShare, setIsShare] = useState(false);

  const handleShareButtonClick = () => {
    setIsShare(true)
    localStorage.removeItem("serviceId") 
    localStorage.removeItem("TypeserviceId")
    localStorage.removeItem("ServiceserviceId")
  }

  const handleSharePopUpCancelButtonClick = () => {
    setIsShare(false)
  }

  // const handleCopyButtonClick = () => {
  //   navigator.clipboard.writeText(serviceProviderUrl)
  //     .then(() => {
  //       console.log('Link copied to clipboard');
  //       setIsShare(false)
  //     })
  //     .catch((error) => {
  //       console.error('Failed to copy:', error);
  //     });
  // }

  const handleCopyButtonClick = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(serviceProviderUrl)
        .then(() => {
          console.log('Link copied to clipboard');
          setIsShare(false);
        })
        .catch((error) => {
          console.error('Failed to copy:', error);
        });
    } else {
      // Fallback for browsers that do not support Clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = serviceProviderUrl;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        console.log('Link copied to clipboard using fallback');
        setIsShare(false);
      } catch (error) {
        console.error('Failed to copy using fallback:', error);
      }
      document.body.removeChild(textArea);
    }
  };
  

 // console.log(buttonNumber);
 // console.log("Condition evaluated:", parseInt(buttonNumber) === 3);


  useEffect(() => {
    let blinkTimeout;

    if (editMapTry) {
      blinkTimeout = setTimeout(() => {
        setEditMapTry(false);
      }, 3000); // Blink for 3 seconds
    }

    return () => {
      clearTimeout(blinkTimeout);
    };
  }, [editMapTry]);


  const handleIframeClick = () => {
    setEditMapTry(true);
  };

  const handleSliderMouseDown = () => {
    setEditMapTry(true)
  }


  useEffect(() => {   
    const storedProvince = localStorage.getItem('province');
    if (storedProvince) {
      setProvinceName(storedProvince);
    }
    else{
      setProvinceName(serviceProviderDtls.province)
    }
  }, [serviceProviderDtls.province, localStorage.getItem('province')]);


  const [mapCoordinates, setMapCoordinates] = useState({
    latitude: '',
    longitude: ''
  });

  useEffect(() => {
    let latitude
    let longitude
    if(localStorage.getItem('Latitude')){
       latitude = localStorage.getItem('Latitude')
       longitude = localStorage.getItem('Longitude') 
    }
    else{
      latitude = serviceProviderDtls.latitude;
      longitude =  serviceProviderDtls.longitude;
    }
    setMapCoordinates({
      latitude,
      longitude
    });
  }, [serviceProviderDtls.latitude, serviceProviderDtls.longitude, localStorage.getItem('Latitude')]);
  

  useEffect(() => {
//console.log(serviceProviderDtls)
//console.log(localStorage.getItem("province"))
  if(serviceProviderDtls.province){

    if (serviceProviderDtls.province === provinceName) {     
      setProvinceError("")
    }
    else{     
      setProvinceError(" The chosen location does not belong to the selected province. ")
    }
   }
   else{
    setProvinceError("")
   }
  }, [serviceProviderDtls.province, provinceName]);


  const handleAddmoreServiceClick = () => {
    localStorage.setItem('addservice', 'true');
    localStorage.removeItem('editservice');
    window.location.href = '/add-service'
  }


  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
      {/* <Box sx={{  width: '100%', height: '100%',display:{ md: 'none', sm: 'none' , xs: 'flex'  }}}>
        <SpHeaderMobile serviceProviderDtls={serviceProviderDtls} percentageComplete = {80}
        messageDetails={messageDetails} unreadMessageCount={unreadMessageCount} setMessageDetails={setMessageDetails}></SpHeaderMobile>
        </Box>  */}
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={percentageset}
          unreadMessageCount={unreadMessageCount} serviceDetails={serviceDetails} currentPage={"Home"}
          isCompleteProfile={isCompleteProfile}></SpHeader>
      </Box>

      <Box sx={{
        width: '100%', height: '100%', display: 'flex',
        flexDirection: { md: 'column', sm: 'column', xs: 'row' }, justifyContent: 'center', alignItems: 'center'
      }}>
        <Box sx={{
          width: { md: 'calc(100%)', sm: 'calc(100%)', xs: 'calc(100%)' },
          height: '100%', maxWidth: { md: '96%', sm: '98%', xs: '90%' }, maxHeight: { md: '96%', sm: '98%', xs: '90%' }, display: 'flex',
          flexDirection: { md: 'row', sm: 'row', xs: 'column' }, gap: { md: '24px', sm: '16px', xs: '16px' },overflow:'hidden',
          alignItems: 'stretch', paddingTop: '16px',
        }}>
          <Box sx={{
            display: { md: 'none', sm: 'none', xs: 'flex' }, width: '100%', height: 'width-content',
            flexDirection: 'column', gap: '16px',
          }}>
            <TypographyComponent sx={{ fontWeight: 700, fontSize: '18px', color: Colors.black_80 }}>Messages </TypographyComponent>
            <SpMessageMobile messageDetails={messageDetails} setMessageDetails={setMessageDetails}></SpMessageMobile>
          </Box>
          <Box sx={{
            width: { md: '70% ', sm: '70% ', xs: '100% ' }, height: '100%', display: 'flex', flexDirection: 'column',
            gap: '24px',height:'100%'
          }}>
            <Box sx={{
              width: '100%', height: '40%', display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' },
              gap: '24px', alignItems: 'stretch',
            }}>
              <Box sx={{
                flex: 1, width: { md: 'calc(50%)', sm: 'calc(50%)', xs: 'calc(100%)' },
                display: 'flex', flexDirection: 'column', gap: '8px',
              }}>
                <Box sx={{ width: '100%', justifyContent: 'space-between', display: 'flex',}}>
                  <TypographyComponent sx={{ fontWeight: 700, fontSize: '18px', color: Colors.black_80, height:'32px' }}>
                    Contact Details
                  </TypographyComponent>
                  <ShareOutlinedIcon onClick={handleShareButtonClick}
                    sx={{  color: Colors.default, cursor: 'pointer', '&:hover': {
                        color: Colors.black_80,} }} />
                </Box>
                {buttonNumber === null && (
                  <SpBusinessCardFront1 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton} />
                )}
                {buttonNumber === undefined && (
                  <SpBusinessCardFront1 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton} />
                )}
                {buttonNumber === 0 && (
                  <SpBusinessCardFront1 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton}/>
                )}
                {buttonNumber === 1 && (
                  <SpBusinessCardFront1 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton}/>
                )}
                {buttonNumber === 2 && (
                  <SpBusinessCardFront2 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton}/>
                )}
                {buttonNumber === 3 && (
                  <SpBusinessCardFront3 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton}/>
                )}
                {buttonNumber === 4 && (
                  <SpBusinessCardFront4 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton}/>
                )}
                {buttonNumber === 5 && (
                  <SpBusinessCardFront5 serviceProviderDtls={serviceProviderDtls}
                    serviceDetails={serviceDetails}
                    serviceProviderOnchangeDtls={{
                      country,
                      postalCode,
                      phoneNumber,
                      province,
                      newProfilePic,
                      signInEmail, signInPassword,
                      countryCode, businessName
                    }}
                    serviceProviderQrCode={serviceProviderQrCode} editbtn={true}  city={city} selectedBgButton={selectedBgButton}/>
                )}
              </Box>
              <Divider orientation="horizontal" flexItem
                sx={{
                  display: { md: 'none', sm: 'none', xs: 'flex' }
                }}
              />

              <Box sx={{
                flex: 1, width: { md: 'calc(50%)', sm: 'calc(50%)', xs: 'calc(100%)' }, display: 'flex',
                flexDirection: 'column', gap:'8px',
              }}>
                <Box sx={{width:'100%', display: 'flex',flexDirection:'row',
                 justifyContent:'space-between', alignItems:'center',}}>
                <TypographyComponent sx={{ fontWeight: 700, fontSize: '18px', color: Colors.black_80 }}>Service
                </TypographyComponent>
                <ButtonComponentWhite onClick={handleAddmoreServiceClick} sx={{width:'fit-content', height:'32px'}}  >
                  <AddIcon></AddIcon>Add more services</ButtonComponentWhite>
                </Box>
               
                <SpBusinessCardBack serviceDetails={serviceDetails} isEditEnable={true} serviceDetailsDataToShow={serviceDetailsDataToShow}
                  setServiceDetailsDataToShow={setServiceDetailsDataToShow} serviceProviderDtls={serviceProviderDtls}  >

                </SpBusinessCardBack>

              </Box>
            </Box>
            <Divider orientation="horizontal" flexItem
              sx={{
                display: { md: 'none', sm: 'none', xs: 'flex' }
              }}
            />
            {!editMap && (<Box sx={{
              width: 'calc(100%-56px)', height: { md: '60vh', sm: '44vh', xs: '50vh' }, display: 'flex',
              flexDirection: 'column', gap: '24px',
              borderRadius: '16px', background: '#F9F9F9', padding: '28px 28px',
            }}>
              { !provinceError &&  <Box sx={{
                width: '100%', height: '32px', display: 'flex', flexDirection: 'row', gap: '32px',
                alignItems: 'center', justifyContent: 'space-between'
              }}>
                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80 }}>
                  Click here to update the location and working radius
                </TypographyComponent>
                <ButtonComponent sx={{ width: '17%', animation: editMapTry ? 'blink-animation 1s infinite' : 'none', }} onClick={handleMapEditClick}>
                  Edit</ButtonComponent>
              </Box>}
              { provinceError &&  <Box sx={{
                width: '100%', height: '32px', display: 'flex', flexDirection: 'row', gap: '32px',
                alignItems: 'center', justifyContent: 'space-between'
              }}>
                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80 }}>
                <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>{provinceError}</TypographyComponent>
                </TypographyComponent>
                <ButtonComponent sx={{ width: '17%', animation: editMapTry ? 'blink-animation 1s infinite' : 'none', }} onClick={handleMapEditClick}>
                  Edit</ButtonComponent>
              </Box>}
              <Box sx={{
                width: '100%', height: '42px', display: 'flex',
                flexDirection: { md: 'row', sm: 'row', xs: 'column' },
                justifyContent: 'space-between'
              }}>

                <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80 }}>
                  Set your work territory: <span style={{ fontWeight: 500 }}>{serviceProviderDtls.city}</span>
                </TypographyComponent>
                <Box sx={{
                  display: 'flex', flexDirection: 'row', width: { md: '55%', sm: '55%', xs: '100%' },
                  height: '100%', alignItems: 'center', justifyContent: 'right',
                  gap: { md: '24px', sm: '16px', xs: '16px' },
                }}>
                  <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, color: Colors.black_80 }}>
                    Working Radius
                  </TypographyComponent>
                  <Slider
                    value={workingRadius}
                    onMouseDown={handleSliderMouseDown}
                    min={0}
                    max={200}
                    valueLabelDisplay="auto"
                    aria-labelledby="distance-slider"
                    sx={{ color: Colors.grey_30, width: { md: '250px', sm: '150px', xs: '52%' }, cursor: 'default' }}
                  />
                  <TypographyComponent sx={{ fontSize: { md: '16px', sm: '14px', xs: '14px' }, color: Colors.black_80 }}>
                    {serviceProviderDtls.working_radius} KM
                  </TypographyComponent>
                </Box>
               
              </Box>
              <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <iframe id="map-iframe"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs&q=${mapCoordinates.latitude},${mapCoordinates.longitude}&zoom=12&maptype=roadmap`}

                ></iframe>

                <div id="circle-overlay"
                  style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                  <svg height="200px" width="200px">
                    <circle cx="50%" cy="50%" radius={workingRadius} stroke="#1A46B766" strokeWidth="2" fill="#99BFEBCC" fillOpacity={0.35} />
                  </svg>
                </div>

                <div onClick={handleIframeClick} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, }}></div>

              </div>

            </Box>)}
            {editMap && <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <GoogleMap serviceProviderDtls={serviceProviderDtls} editworkingradius={false}
                setChoosedLocation={setChoosedLocation} setSelectedLatitude={setSelectedLatitude}
                setSelectedLongitude={setSelectedLongitude} choosedLocation={choosedLocation}
                selectedLatitude={selectedLatitude} selectedLongitude={selectedLongitude}
                setEditMap={setEditMap} setProvinceError={setProvinceError} provinceError={provinceError}></GoogleMap>
            </div>}
          
            {/* <TypographyComponent sx={{ fontWeight: 700, fontSize: '18px', color: Colors.black_80 }}>1{ localStorage.getItem("province")} </TypographyComponent>
            <TypographyComponent sx={{ fontWeight: 700, fontSize: '18px', color: Colors.black_80 }}>2{ serviceProviderDtls.province} </TypographyComponent> */}
          </Box>
          <Box sx={{ flex: 1, width: { md: '30%', sm: '30%', xs: '0%' },display: { md: 'flex', sm: 'flex', xs: 'none' },
           flexDirection: 'column', gap: '8px', overflowY: 'auto' ,maxHeight:'830px' }}>
            <TypographyComponent sx={{ fontWeight: 700, fontSize: '18px', color: Colors.black_80 }}>Messages </TypographyComponent>
            <SpMessage messageDetails={messageDetails} setMessageDetails={setMessageDetails}></SpMessage>
          </Box>
        </Box>
      </Box>

      {welcomePopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(249, 249, 249, .6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >

          <Box
            sx={{
              position: 'absolute',
              width: { md: '23%', sm: '40%', xs: '90%' },
              height: 'fit-content',
              backgroundColor: Colors.quinary,
              border: '1px solid #E6E6E6',
              borderRadius: '28px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',

            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

                <TypographyComponent sx={{ fontSize: '20px', fontWeight: 700, color: Colors.black_70, textalign: 'center' }}>
                  Welcome!</TypographyComponent>
                <TypographyComponent sx={{ fontSize: '18px', fontWeight: 500, color: Colors.black_70, textalign: 'center' }}>
                  Finish setting up your profile</TypographyComponent>

              </Box>

              <Box sx={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

                <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                  Complete your profile by adding services and location to ensure visibility and local matching .quick, essential step for better client connections </TypographyComponent>

              </Box>

              <Box sx={{
                display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px', justifyContent: 'center',
                alignContent: 'center', alignItems: 'center'
              }}>
                <Box sx={{ width: '70%' }}>
                  <ButtonComponent onClick={handleWelcomPopUpButtonClick} >Complete profile</ButtonComponent>
                </Box>

              </Box>

            </Box>
          </Box>
        </Box>}

      {isShare &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '35%', sm: '70%', xs: '85%' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton onClick={handleSharePopUpCancelButtonClick} sx={{
                width: '36px', height: '36px',
              }} >
                <CancelIcon sx={{
                  width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                    color: Colors.close,
                  },
                }} ></CancelIcon>
              </IconButton>
            </Box>

            <Box
              sx={{

                width: { md: '35%', sm: '70%', xs: '85%' },
                height: 'fit-content',
                backgroundColor: Colors.white,
                border: '1px solid',
                borderColor: Colors.white,
                borderRadius: '16px ',
                boxShadow: 'none',
                boxSizing: 'border-box',
                zIndex: '3',
                padding: '20px',
                alignItems: 'center',

              }}
            >

              <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                justifyContent: 'center', alignContent: 'center', height: '100%',
              }}>
                <Box sx={{
                  display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                  justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                }}>
                  <TypographyComponent sx={{ color: Colors.black_80, fontSize: '18px', textAlign: 'left' }}>
                    Share link
                  </TypographyComponent>

                  <Box sx={{
                    width: 'calc(100%-32px)', height: '28px', padding: '10px 16px 10px 16px', border: '1px solid',
                    borderColor: Colors.grey_60, borderRadius: '28px', display: 'flex', flexDirection: 'row',
                  }}>
                    <InputBase
                      value={serviceProviderUrl}
                      style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent' }}
                      inputProps={{
                        style: {
                          fontFamily: 'Roboto', fontSize: '16px',
                          '::placeholder': {
                            fontFamily: 'Roboto', fontSize: '16px',
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{
                    width: 'calc(100%-32px)', height: '100%', padding: '10px 16px 10px 16px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'right',
                  }}
                  >
                    <ButtonComponent sx={{ width: 'fit-content', padding: '18px' }} onClick={handleCopyButtonClick}><ContentCopyIcon style={{ paddingRight: '5px' }} />Copy link</ButtonComponent>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>}
      <Box sx={{ width: '100%', height: '100%', display: 'flex', paddingTop: '25px' }}>
        <SpFooter></SpFooter>
      </Box>
    </Grid>
  )
}
export default SpLanding; 

