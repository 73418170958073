import React from "react"
import Button from '@mui/material/Button';
import Colors from "./Colors";

 function ButtonComponentGrey({ onClick, sx, ...props }){
    
    return(
        <Button variant="contained" 
           onClick={onClick} 
            sx={{boxShadow: 'none',width:'100%', height:{ md:'48px' , sm: '48px', xs: '38px'  }, backgroundColor:Colors.quinary,
            borderRadius:'28px',color:Colors.black_80,textTransform: 'none',fontFamily: 'Roboto',
            fontSize:'16px', fontWeight:'400',
            '&:hover': {
                background: Colors.white ,textTransform: 'none',color: Colors.Hovering,boxShadow: 'none',
                border:'1px solid', borderColor:Colors.Hovering
              },
              ...sx,
            }}
                 {...props}>            
         </Button>
    )
}
export default ButtonComponentGrey;

