import { Box, Grid, Stack } from "@mui/material"
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_URL } from './Constants';
import SearchComponent from "../Components/SearchComponent";
import UserHeader from "../Components/UserHeader";
import ProfileComponent from "../Components/ProfileComponent";
import ButtonComponent from "../Components/ButtonComponent";
import TypographyComponent from "../Components/TypographyComponent";
import Divider from '@mui/material/Divider';
import ServiceProviders from "../Components/ServiceProviders";
import UserFooter from "../Components/UserFooter";
import Colors from "../Components/Colors";




async function getCoordinates(location) {
  const apiKey = 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs';
  const encodedLocation = encodeURIComponent(location);
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
      throw new Error('Location not found');
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error.message);
    throw error;
  }
}

function SearchDataNew() {
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDtls, setServiceDtls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [serviceProviderUrl, setServiceProviderUrl] = useState(null);
    const [types, setTypes] = useState([]);
    const [services, setservices] = useState([]);
    const service = localStorage.getItem("service")
    const serviceId = localStorage.getItem("serviceId")
    const Type = localStorage.getItem("Type")
    const TypeId = localStorage.getItem("TypeId")
    const [selectedService, setSelectedService] = useState(null);
    const [serviceProviders, setserviceProviders] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const location = localStorage.getItem("Location")
    const userLocation = localStorage.getItem("Location")
    const [spIdChange, setSpIdChange] = useState(false);
    const [spIdDefaultValue, setSpIdDefaultValue] = useState(null);
    const [typeAllTypeId, setTypeAllTypeId] = useState(null);
    const [isFilterClick, setIsFilterClick] = useState(false);
    const UserSelctLat =  localStorage.getItem("SelectedLat")
    const UserSelctLng = localStorage.getItem("SelectedLng")
    const [isFilterClickAndNoSp, setIsFilterClickAndNoSp] = useState(false);
    let ChoosedTypeId
    
    const [isServiceProvider, setIsServiceProvider] = useState(false);
  
    useEffect(() => {
    // console.log(serviceProviders)
         setLoading(true)
        setIsServiceProvider(true);       
     
    }, []);

    useEffect(() => {
      setIsFilterClick(false)
      if (isServiceProvider) {
        setTimeout(() => {         
          setLoading(false); // Stop loading after the delay
        }, 1500); // 30 seconds delay
      }
    }, [isServiceProvider]); 

    useEffect(() => {
     // console.log( serviceProviders)
      const fetchService = async () => {
        try {
        //  console.log('serviceId:', serviceId);
        //  console.log('TypeId:', TypeId);
          let selectServiceId;
          if (serviceId || (typeAllTypeId && selectedType === 'All')) {
        //    console.log('Hiii')
            // const coordinates = await getCoordinates(location);
            const coordinates = { latitude: UserSelctLat, longitude: UserSelctLng }
          //  console.log('Coordinates:', coordinates);
            if (typeAllTypeId) {
              selectServiceId = typeAllTypeId;
            }
            else {
              selectServiceId = serviceId
            }
            const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers_with_service`, {
              service_id: selectServiceId,
              location: userLocation,
              coordinates: coordinates
            });
          //  console.log(serviceProvidersResponse.data.serviceProviders)
            if(!isFilterClick)
            {
              setserviceProviders(serviceProvidersResponse.data.serviceProviders);
              console.log(serviceProvidersResponse.data.serviceProviders)
              setLoading(false)}
          //  console.log(serviceProvidersResponse.data.serviceProviders.length)
            if(serviceProvidersResponse.data.serviceProviders.length !== 0){
              setIsServiceProvider(false);
              setLoading(false)
            }
            const sp = serviceProvidersResponse.data.serviceProviders[0]
          //  console.log(localStorage.getItem("SPId"))
            localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
            if (!localStorage.getItem("SPId")) {
             // console.log('Entering if condition');
              localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
            } else {
              console.log('SPId not found in localStorage');
            }
  
  
          }
          console.log(selectedType, selectedTypeId)
          if ((TypeId && selectedType !== 'All') || (selectedTypeId && selectedType !== 'All')) {
  
            if (localStorage.getItem("TypeId")) {
              ChoosedTypeId = localStorage.getItem("TypeId")             
              setSelectedType(Type)
              setSelectedTypeId(localStorage.getItem("TypeId"))
            }
            else {
              ChoosedTypeId = selectedTypeId
              console.log(ChoosedTypeId)
            }
            //const coordinates = await getCoordinates(location);
            const coordinates = { latitude: UserSelctLat, longitude: UserSelctLng }
          //  console.log('ChoosedTypeId:', ChoosedTypeId);
            const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers_with_types`, {
              type_id: ChoosedTypeId,
              location: userLocation,
              coordinates: coordinates
            });
            console.log(serviceProvidersResponse.data)
           
         
           // console.log(serviceProvidersResponse.data.serviceProviders.length)
            if(serviceProvidersResponse.data.serviceProviders.length > 0){
              setIsServiceProvider(false);
              setLoading(false)   
              setserviceProviders(serviceProvidersResponse.data.serviceProviders); 
              serviceProvidersResponse.data.serviceProviders.forEach(provider => {
                console.log(provider.business_name);
              });
                    
            }
         //   {console.log(isFilterClick)}
            if(serviceProvidersResponse.data.error === 'No service providers found' && isFilterClick){
              setIsFilterClickAndNoSp(true)
             
            }
            // if(serviceProvidersResponse.data.serviceProviders.length > 0 && isFilterClick){
            //   setIsFilterClickAndNoSp(false)
              
            // }

            const sp = serviceProvidersResponse.data.serviceProviders[0]
            console.log(serviceProvidersResponse.data.serviceProviders[0].service_provider_id)
            localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
          //  console.log(localStorage.getItem("SPId"))
            if (!localStorage.getItem("SPId")) {
            console.log('Entering if condition');
              localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
            } else {
              console.log('SPId not found in localStorage');
            }
  
  
          }
          if(localStorage.getItem('SPName') ){
            const spId = localStorage.getItem('SPId');
            console.log(localStorage.getItem('SPName'))
            console.log(localStorage.getItem('SPCode'))
           console.log(localStorage.getItem('SPId'))
        try {
          const response = await axios.post(`${API_URL}/sp-details`, { spId });
       //   console.log("sp-details", response.data.data.serviceProvider)
          if (response.data.success) {
          //  console.log(response.data.data.serviceProvider) 
            setServiceProviderDtls(response.data.data.serviceProvider)
            setServiceDtls(response.data.data.serviceProvider.service_details)
            console.log("service-details", response.data.data.serviceProvider.service_details[0])
            setServiceProviderQrCode(response.data.data.qrCode)
          //  console.log(response.data.data.serviceProviderURL)
            setServiceProviderUrl(response.data.data.serviceProviderURL)
            setserviceProviders(response.data.data.serviceProvider);
            setLoading(false)
         //   console.log(response.data.data.serviceProvider.length)
            if(response.data.data.serviceProvider.length !== 0){
              setIsServiceProvider(false);
              setLoading(false)
              const response3 = await axios.post(`${API_URL}/select_all_types`,
              { serviceId: response.data.data.serviceProvider.service_details[0].services_id, });
               setTypes(response3.data);
             //  console.log("all types", response3.data)
            }
          } else {
            console.error('Error:', response.data.error);
          }
        } catch (error) {
          console.error('Error:', error.message);
        }
          }
          // if((localStorage.getItem('SPName') && localStorage.getItem('SPCode'))){
          //   console.log(localStorage.getItem('SPName'))
          //   console.log(localStorage.getItem('SPCode'))
          //   const spId = localStorage.getItem('SPId');
          
          //   console.log(localStorage.getItem('SPId'))
          // try {
          // const response = await axios.post(`${API_URL}/sp-details-assossiate`, { spId });
          //   console.log("sp-details", response.data.data.serviceProvider)
          // if (response.data.success) {
          // //  console.log(response.data.data.serviceProvider) 
          //   setServiceProviderDtls(response.data.data.serviceProvider)
          //   setServiceDtls(response.data.data.serviceProvider.service_details)
          //   console.log("service-details", response.data.data.serviceProvider.service_details[0])
          //   setServiceProviderQrCode(response.data.data.qrCode)
          // //  console.log(response.data.data.serviceProviderURL)
          //   setServiceProviderUrl(response.data.data.serviceProviderURL)
          //   setserviceProviders(response.data.data.serviceProvider);
          //   setLoading(false)
          // //   console.log(response.data.data.serviceProvider.length)
          //   if(response.data.data.serviceProvider.length !== 0){
          //     setIsServiceProvider(false);
          //     setLoading(false)
          //     const response3 = await axios.post(`${API_URL}/select_all_types`,
          //     { serviceId: response.data.data.serviceProvider.service_details[0].services_id, });
          //     setTypes(response3.data);
          //   //  console.log("all types", response3.data)
          //   }
          // } else {
          //   console.error('Error:', response.data.error);
          // }
          // } catch (error) {
          // console.error('Error:', error.message);
          // }
          // }


  
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    //  console.log(getCoordinates(location))
      fetchService();
    }, [serviceId, selectedTypeId, selectedType, ChoosedTypeId, ]);
  
    let SelectedServiceId
    const fetchTypes = async () => {
     // console.log(localStorage.getItem("serviceId"))
     // console.log(localStorage.getItem("TypeserviceId"))
      try {
        if (localStorage.getItem("serviceId")) {
          SelectedServiceId = localStorage.getItem("serviceId")
        }
        else {
          SelectedServiceId = localStorage.getItem("TypeserviceId")
        }
        const response = await axios.post(`${API_URL}/select_all_types`,
          { serviceId: SelectedServiceId, });
        setTypes(response.data);
       // console.log("all types", response.data)
      } catch (error) {
        console.error('Error fetching service categories:', error);
      }
    };
    useEffect(() => {
      if (localStorage.getItem("serviceId") || localStorage.getItem("TypeserviceId")) {
        fetchTypes();
      }
  
    }, [serviceId, selectedTypeId, selectedType, ChoosedTypeId]);
  
  
    async function fetchServiceProviderDetails() {
      const spId = localStorage.getItem('SPId');
     // console.log("spId", spId)
      try {
        const response = await axios.post(`${API_URL}/sp-details`, { spId });
      //  console.log("service-provider-details", response.data.data.serviceProvider)
        if (response.data.success) {
        //  console.log(response.data.data.serviceProvider)
          setServiceProviderDtls(response.data.data.serviceProvider)
          setServiceDtls(response.data.data.serviceProvider.service_details)
         console.log("service-details", response.data.data.serviceProvider)
          setServiceProviderQrCode(response.data.data.qrCode)
          setServiceProviderUrl(response.data.data.serviceProviderURL)
        } else {
          console.error('Error:', response.data.error);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
    useEffect(() => {
      fetchServiceProviderDetails();
    }, [spIdChange, localStorage.getItem('SPId')]);

   
   


  return (
    <Box sx={{display:'flex', flexDirection: 'column', width:'100%', height:'100%', justifyContent:'center', alignItems:'center',
   
    }}>
    < UserHeader />
    <Box sx={{ display: 'flex',width:'100%', height:'100%', flexDirection: 'row', gap: '16px',justifyContent:'center', alignItems:'center',
         maxWidth:'95%', maxHeight:'95%', paddingTop: { xs: '16px', md: '0px' } }}>
        <SearchComponent isHome={false}></SearchComponent>
    </Box>
   
   
    <Box sx={{display:'flex', flexDirection: { xs: 'column', md: 'row' }, width:'100%', height:'100%', justifyContent:'center', alignItems:'center'}}>
    {loading && isServiceProvider  && (
                        <Box sx={{display:'flex', flexDirection: { xs: 'column', md: 'row' }, width:'100%', height:'70vh', justifyContent:'center', alignItems:'center'}} >
                        <TypographyComponent sx={{ fontSize: { md: '18px' , sm: '18px' , xs:'14px' }, color: Colors.grey_60, 
                                        minWidth:'fit-content' }}> Loading...</TypographyComponent>
                        </Box>)}
   
    {!loading && isServiceProvider &&(
    <Box sx={{display:'flex', flexDirection: { xs: 'column', md: 'row' }, width:'100%', height:'70vh',
    justifyContent:'center', alignItems:'center', maxWidth:{md: '100%', sm: '100%', xs: '90%' }}} >
    <TypographyComponent sx={{ fontSize: { md: '18px' , sm: '18px' , xs:'14px' }, color: Colors.grey_60, 
                    minWidth:'fit-content' }}> Oops! We couldn't find any service providers in this category.</TypographyComponent>
    </Box>)}
    {!loading && !isServiceProvider &&(
    <Box sx={{display:'flex', flexDirection: { xs: 'column', md: 'row' }, width:'100%', height:'100%', justifyContent:{ xs: 'center', md:  'flex-start' }, 
            alignItems:{ xs: 'center', md:  'flex-start' },
         maxWidth:{ md: '95%' , sm: '95%' , xs:'98%' }, maxHeight:'95%', gap:'2%',paddingTop:'2.5%', paddingBottom:'2.5%'
    }}> 
      
       <Box  
            sx={{
                display: 'flex', flexDirection: 'column', overflowY: 'auto', width: { md: '50%' , sm: '50%' , xs:'100%' },
                gap: '48px', maxHeight: '650px',maxWidth:{ md: '90%' , sm: '90%' , xs:'98%' },
              '&::-webkit-scrollbar': {
                width: { md: '8px', sm: '8px', xs: '0px' },
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#d9d9d9',            
                borderRadius: '10px',
                borderRight: { md: '2px solid #d9d9d9', sm: '2px solid #d9d9d9', xs: '0px solid #d9d9d9' }
              },
              '&::-webkit-scrollbar-track': {
                borderRight: { md: '2px solid #d9d9d9', sm: '2px solid #d9d9d9', xs: '0px solid #d9d9d9' }
              },

            }}>  
           


          

            <ServiceProviders serviceProviders={serviceProviders} setserviceProviders={setserviceProviders}
                selectedService={selectedService} types={types} selectedType={selectedType}
                setSelectedType={setSelectedType} setSelectedTypeId={setSelectedTypeId}
                selectedTypeId={selectedTypeId} setSpIdChange={setSpIdChange}
                spIdChange={spIdChange} setTypeAllTypeId={setTypeAllTypeId}  serviceDtls={serviceDtls}
                serviceProviderQrCode={serviceProviderQrCode} serviceProviderUrl={serviceProviderUrl}
                setIsFilterClick = {setIsFilterClick} isFilterClick={isFilterClick} isFilterClickAndNoSp = {isFilterClickAndNoSp} > </ServiceProviders>
        </Box>
       
        <Box 
            sx={{
                display: { xs: 'none', md: 'flex' }, flexDirection: 'column', width: { md: '50%' , sm: '50%' , xs:'100%' },
                gap: '48px',maxWidth:{ md: '90%' , sm: '90%' , xs:'95%' },
            }}>
         { localStorage.getItem('SPId')&&   <ProfileComponent serviceProviderDtls={serviceProviderDtls} isContactEnable={false}
                serviceDtls={serviceDtls} serviceProviderQrCode={serviceProviderQrCode} serviceProviderUrl={serviceProviderUrl}>
            </ProfileComponent>}
        </Box>
        
    </Box>)}   
    </Box>
    
    <Box sx={{display:'flex', width:'100%', bottom:0, position:'relative'}}>
            < UserFooter />
            </Box>
</Box>
  )
}

export default SearchDataNew