import { Box, InputBase, Typography, Avatar } from "@mui/material"
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { API_URL } from './Constants';
import TypographyComponent from "./TypographyComponent";
import { useState, useEffect, useRef } from "react";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonComponent from "../Components/ButtonComponent";
import { GoogleMap, useLoadScript, MarkerF, Circle, Autocomplete } from '@react-google-maps/api';
import Colors from '../Components/Colors';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IMG_URL } from "../Components/Constants";

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const defaultLat = 43.732506485371125;
const defaultLng = -79.76642535889613;

const aggregateServices = (data) => {
  return data.reduce((acc, curr) => {
    const { service_provider_id, business_name, service_provider_code, profile_image } = curr;
    
    if (!acc[service_provider_id]) {
      acc[service_provider_id] = {
        business_name,
        service_provider_code,
        profile_image,
        service_provider_id,
        services: curr.services,
        services_id: curr.services_id
      };
    } else {
      acc[service_provider_id].services += `, ${curr.services}`;
      acc[service_provider_id].services_id += `, ${curr.services_id}`;
    }
    
    return acc;
  }, {});
};

function SearchComponent(props) {
  const { isHome } = props
  const colors = ['#F0624E', '#45DCDC', '#A14C93', '#F22B7E', '#5F90DB'];
  const [searhCharEdit, setsearhCharEdit] = useState('true');
  const [searhCharLocation, setsearhCharLocation] = useState('');
  const [searhCharLocationpopup, setsearhCharLocationpopup] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedLatLng, setSelectedLatLng] = useState([defaultLat, defaultLng]);
  const [isPlaceEdit, setIsPlaceEdit] = useState(false);
  const searchInputRef = useRef(null);
  useEffect(() => {
    const storedLat = parseFloat(localStorage.getItem("SelectedLat"));
    const storedLng = parseFloat(localStorage.getItem("SelectedLng"));

    if (!isNaN(storedLat) && !isNaN(storedLng)) {
      setSelectedLatLng([storedLat, storedLng]);
    }
  }, []);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const AutocompleteOptions = {
    componentRestrictions: { country: 'CA' }, // Limit results to Canada
  };

  // const onPlaceChanged = () => {
    
  //   if (autocomplete !== null) {
  //     let city = 'Location not found';
  //     const place = autocomplete.getPlace();
  //    // console.log('Place object:', place);

  //     if (place.geometry) {
  //       const { lat, lng } = place.geometry.location;
  //     console.log(place.geometry.location)
  //    console.log(place.geometry.location.lat())
  //      console.log( place.geometry.location.lng())
  //       const newLatLng = [
  //         place.geometry.location.lat(),
  //         place.geometry.location.lng()
  //       ];
  //       setSelectedLatLng(newLatLng);
  //       setIsPlaceEdit(true)
  //       localStorage.setItem("SelectedLat", newLatLng[0]);
  //       localStorage.setItem("SelectedLng", newLatLng[1]);
  //     //  console.log(lat(), lng())   
         
  //       place.address_components.forEach(component => {
  //         if (component.types.includes('locality')) {
  //           city = component.long_name;
  //         //  console.log( component.long_name)
  //         }
  //       });
  //       setChoosedLocationpopup(city)       
  //       setsearhCharLocationpopup(city);
  //     //  console.log('Selected LatLng:', selectedLatLng);
  //     //  console.log('Chosen Location:', city);
  //       setLastSelectedLocation(city)
  //       setsearhCharLocation(choosedLocationpopup);
  //       setChoosedLocationmap(city)
  //       setChoosedLocation(choosedLocationmap)
  //     } else {
  //       console.log('No geometry found for the selected place.');
  //     }
  //   } else {
  //     console.log('Autocomplete is null');
  //   }
  // };

  const getCityNameFromLatLng = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.status === 'OK' && data.results.length > 0) {
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('locality')) {
              return component.long_name; // City name
            }
          }
        }
        // If no locality found, return a more general administrative area
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('administrative_area_level_1')) {
              return component.long_name; // State or province name
            }
          }
        }
      } else {
        console.error('Error fetching city name:', data.status, data.error_message || '');
        return null;
      }
    } catch (error) {
      console.error('Error fetching city name:', error);
      return null;
    }
  };
  
  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      let city = 'Location not found';
      const place = autocomplete.getPlace();
      
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const newLatLng = [lat, lng];
  
        // console.log(place.geometry.location);
        // console.log(lat);
        // console.log(lng);
  
        setSelectedLatLng(newLatLng);
        setIsPlaceEdit(true);
        localStorage.setItem("SelectedLat", lat);
        localStorage.setItem("SelectedLng", lng);
  
        let cityFound = false;
        place.address_components.forEach(component => {
          if (component.types.includes('locality')) {
            city = component.long_name;
            cityFound = true;
          }
        });
  
        if (!cityFound) {
          city = await getCityNameFromLatLng(lat, lng);
        }
        setChoosedLocationpopup(city)       
       setsearhCharLocationpopup(city);            
              setLastSelectedLocation(city)
              setsearhCharLocation(choosedLocationpopup);
              setChoosedLocationmap(city)
              setChoosedLocation(choosedLocationmap)
      } else {
        console.log('No geometry found for the selected place.');
      }
    } else {
      console.log('Autocomplete is null');
    }
  };



  useEffect(() => {
    if (localStorage.getItem("SelectedLatLng")) {
      setSelectedLatLng(localStorage.getItem("SelectedLatLng"))
      setsearhCharLocation(localStorage.getItem("Location"))
      setChoosedLocationpopup(localStorage.getItem("selectedLocationMap"))
      setsearhCharLocationpopup(localStorage.getItem("selectedLocationMap"));
      setLastSelectedLocation(localStorage.getItem("selectedLocationMap"))
      setsearhCharLocation(localStorage.getItem("selectedLocationMap"));
      setChoosedLocationmap(localStorage.getItem("selectedLocationMap"))
      setChoosedLocation(localStorage.getItem("selectedLocationMap"))
    //  console.log(localStorage.getItem("SelectedLatLng"))
    }

  }, []);




  useEffect(() => {
    if (localStorage.getItem("Location")) {
    //  console.log("User Selected Location", localStorage.getItem("Location"))
    //  console.log("User Selected Latitude", localStorage.getItem("SelectedLat"))
    //  console.log("User Selected Longitude", localStorage.getItem("SelectedLng"))
      setLastSelectedLocation(localStorage.getItem("Location"))
      setsearhCharLocation(localStorage.getItem("Location"))
      setsearhCharLocationpopup(localStorage.getItem("Location"))
      // const storedLat = parseFloat(localStorage.getItem("SelectedLat"));
      // const storedLng = parseFloat(localStorage.getItem("SelectedLng"));  
      // setSelectedLatLng([storedLat, storedLng]);
    }
    else {
      const fetchLocation = async () => {
        try {
        //  console.log("hiii")
          const response = await fetch('https://ipapi.co/json/');
         // console.log(response)
          const data = await response.json();
       //   console.log(data)
          const city = data.city;
          setsearhCharLocation(city);
          localStorage.setItem("Location", city)
          const latitude = data.latitude;
          const longitude = data.longitude;
          setSelectedLatLng([latitude, longitude])
          localStorage.setItem("SelectedLat", latitude)
          localStorage.setItem("SelectedLng", longitude)
         // console.log(city)

        } catch (error) {
          console.error('Error fetching location:', error);
        }
      };
      fetchLocation();
    }

  }, []);


  const [mainSearchValue, setMainSearchValue] = useState('');
  const [searchResultServices, setSearchResultServices] = useState([]);
  const [searchResultTypes, setSearchResultTypes] = useState([]);
  const [searchResultSP, setSearchResultSP] = useState([]);
  const [searchResultSPCode, setSearchResultSPCode] = useState([]);

  const handleMainSearch = async (event) => {
    setSearchError("")
    setsearhCharEdit(false)
      localStorage.removeItem("service") ||
      localStorage.removeItem("serviceId") ||
      localStorage.removeItem("Type") ||
      localStorage.removeItem("TypeId") ||
      localStorage.removeItem("TypeserviceId") ||
      localStorage.removeItem("SPName") ||
      localStorage.removeItem("SPId")
      localStorage.removeItem("ServiceserviceId")
    try {
      const char = event.target.value
      setMainSearchValue(char)
    //  console.log("char", event.target.value)
      const response = await axios.post(`${API_URL}/select-search`,
        {
          searchValue: event.target.value,
        });
      setSearchResultServices(response.data.serviceResult)
      setSearchResultTypes(response.data.typesResult)
      setSearchResultSP(response.data.SpResult)
      const aggregatedSpCodeResult = Object.values(aggregateServices(response.data.SpCodeResult));
      setSearchResultSPCode(aggregatedSpCodeResult)
      console.log(aggregatedSpCodeResult)
      if (response.data.serviceResult.length > 0 || response.data.typesResult.length > 0 || response.data.SpResult.length > 0 || response.data.SpCodeResult.length > 0) {
        setShowResults(true);
      } else {
        setShowResults(false);
      }
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };
  const [searchError, setSearchError] = useState("");



  const handleSearchIconClick = async (value) => {

    if (localStorage.getItem("service") ||
      localStorage.getItem("serviceId") ||
      localStorage.getItem("Type") ||
      localStorage.getItem("TypeId") ||
      localStorage.getItem("TypeserviceId") ||
      localStorage.getItem("SPName") ||
      localStorage.getItem("SPId")) {
      window.location.href = '/search-data'
    }

  
    try {
      const response = await axios.post(`${API_URL}/select-search`,
        {
          searchValue: value,
        });
       // console.log(value)
      setSearchResultServices(response.data.serviceResult)
      setSearchResultTypes(response.data.typesResult)
      setSearchResultSP(response.data.SpResult)
      const aggregatedSpCodeResult = Object.values(aggregateServices(response.data.SpCodeResult));
      setSearchResultSPCode(aggregatedSpCodeResult)
      console.log(aggregatedSpCodeResult)
     
    //  console.log("response search result", response.data.sperror)
      // if (response.data.serviceResult.length > 0 || response.data.typesResult.length > 0 || response.data.SpResult.length > 0) {
      //   setShowResults(true);
      //  // value not included in any responce shows error here
      // }
     if(response.data.sperror === true){
      setSearchError("We couldn't find any data based on your search. Please choose one from the list.")
     }
     else{
      setSearchError('');
     }
      const isValueIncluded =       
      searchResultServices.includes(value) ||       
      searchResultTypes.includes(value) ||       
      searchResultSP.includes(value);

    if (isValueIncluded || '') {
      setShowResults(true);
      setSearchError(''); // Clear any previous error message
    }  
      else {
        setSearchError("We couldn't find any data based on your search. Please choose one from the list.")
        setShowResults(false);
      }
      
    } catch (error) {
     // setSearchError("We couldn't find any data based on your search. Please choose one from the list.")
      console.error('Error fetching service categories:', error);
    }
  };

  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);


  const handleServiceClick = (name, id, category, category_id) => {
   // console.log(name)
    setMainSearchValue(name)
    localStorage.setItem("service", name)
    localStorage.setItem("service_category", category)
    localStorage.setItem("service_category_id", category_id)
    localStorage.setItem("serviceId", id)
    localStorage.removeItem("Type")
    localStorage.removeItem("TypeId")
    localStorage.removeItem("TypeserviceId")
    localStorage.removeItem("SPName")
    localStorage.removeItem("SPCode")
    localStorage.removeItem("SPId")
    window.location.href = '/search-data'
  };

  const handleTypeClick = (name, id, sid) => {
  //  console.log(name)
    setMainSearchValue(name)
    localStorage.setItem("Type", name)
    localStorage.setItem("TypeId", id)
    localStorage.setItem("TypeserviceId", sid)
    localStorage.removeItem("service")
    localStorage.removeItem("serviceId")
    localStorage.removeItem("SPName")
    localStorage.removeItem("SPCode")
    localStorage.removeItem("SPId")
    window.location.href = '/search-data'
  };

  const handleSPClick = (name, id, sid) => {
   // console.log(id)
    setMainSearchValue(name)
    localStorage.setItem("SPName", name)    
    localStorage.setItem("SPId", id)
    localStorage.removeItem("SPCode")
    localStorage.setItem("ServiceserviceId", sid)
    window.location.href = '/search-data';
  };

  const handleSPCodeClick = (name, code, id, sid) => { 
    console.log(name, code, id, sid)  
    let sidString = sid; 
  if (typeof sid !== 'string') {
    sidString = String(sid);
  }
  const firstServiceId = sidString.split(',')[0].trim();
  
    console.log(firstServiceId)
     setMainSearchValue(code)
     localStorage.setItem("SPName", name)
     localStorage.setItem("SPCode", code)
     localStorage.setItem("SPId", id)
     localStorage.setItem("ServiceserviceId", firstServiceId)
     window.location.href = '/search-data';
   };

  

  useEffect(() => {

    if (localStorage.getItem("service")) {
      setMainSearchValue(localStorage.getItem("service"))
    }
    if (localStorage.getItem("Type")) {
      setMainSearchValue(localStorage.getItem("Type"))
    }
    if (localStorage.getItem("SPName")) {
      setMainSearchValue(localStorage.getItem("SPName"))
    }
    if (localStorage.getItem("SPCode")) {
      setMainSearchValue(localStorage.getItem("SPCode"))
    }
    if (isHome) {
      setMainSearchValue('')
    }


  }, []);

  const [isEditLocation, setIsEditLocation] = useState(false);

  const handleLocationClick = () => {
    setIsEditLocation(true)    
    const storedLat = parseFloat(localStorage.getItem("SelectedLat"));
    const storedLng = parseFloat(localStorage.getItem("SelectedLng"));  
    setSelectedLatLng([storedLat, storedLng]);
    localStorage.setItem("SelectedLatSave", storedLat)
    localStorage.setItem("SelectedLngSave", storedLng) 
  }

  const handleEditLocationPopUpCancelButtonClick = () => {
    setIsEditLocation(false)
    setSelectedLatLng('')
    if(isPlaceEdit){
      const storedLat = parseFloat(localStorage.getItem("SelectedLatSave"));
      const storedLng = parseFloat(localStorage.getItem("SelectedLngSave"));  
      localStorage.setItem("SelectedLat", storedLat)
      localStorage.setItem("SelectedLng", storedLng)
      setsearhCharLocationpopup(localStorage.getItem("selectedLocationMap"));      
      setSelectedLatLng([storedLat, storedLng]);
      setIsPlaceEdit(false)
   
    }
  }

 
  const [selectedLongitude, setSelectedLongitude] = useState(null);
  const [choosedLocation, setChoosedLocation] = useState(null);
  const [choosedLocationmap, setChoosedLocationmap] = useState(null);
  const [choosedLocationpopup, setChoosedLocationpopup] = useState(null);
  const [lastSelectedLocation, setLastSelectedLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const findNearestCity = async (location) => {
    const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location.lat},${location.lng}&radius=5000&type=locality&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        return data.results[0].name;
      } else {
        console.error('Error fetching nearest city:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching nearest city:', error);
      return null;
    }
  };


  const handleMapClick = async (event) => {
    try {
    //  console.log("Map clicked", event);  // Log the entire event object
  
      const newLatLng = [event.latLng.lat(), event.latLng.lng()];
    //  console.log("New LatLng", newLatLng);  // Log the new latitude and longitude array
  
      setSelectedLatLng(newLatLng);
      setChoosedLocationmap(event.latLng);
      setIsPlaceEdit(true);
  
     // console.log("event.latLng", event.latLng);  // Log the latLng object directly
      //setLastSelectedLocation(event.latLng);
    //  console.log("map click", event.latLng);
  
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs`;
    //  console.log("Fetching URL:", url);  // Log the URL being fetched
  
      const response = await fetch(url);
      const data = await response.json();
     // console.log("Geocode response data:", data);  // Log the response data
  
      if (data.status === 'OK' && data.results.length > 0) {
        let city = 'Location not found';
        const addressComponents = data.results[0].address_components;
        const locality = addressComponents.find(
          (component) => component.types.includes('locality')
        );
        if (locality) {
          city = locality.long_name;
        } else {
          const nearestCity = await findNearestCity(data.results[0].geometry.location);
          if (nearestCity) {
            city = nearestCity;
          }
        }
  
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
      //  console.log("City:", city);
      //  console.log("User Clicked Location:", city);
      //  console.log("User Clicked Latitude:", lat);
      //  console.log("User Clicked Longitude:", lng);
        setLastSelectedLocation(city);
        setChoosedLocationpopup(city);
        //setsearhCharLocation(city);
        setsearhCharLocationpopup(city)
      } else {
        console.error('Error fetching location details:', data.error_message || 'No results found');
      }
    } catch (error) {
      console.error('Error in handleMapClick:', error.message);
    }
  };
  

  
  const selectedLat =  selectedLatLng[0] || 43.732506485371125;
  const selectedLng =  selectedLatLng[1] ||-79.76642535889613;




  const handleEditLocationPopUpChangeButtonClick = () => {
    setIsEditLocation(false)
    localStorage.setItem("selectedLocationMap", lastSelectedLocation)
    localStorage.setItem("SelectedLat", selectedLatLng[0])
    localStorage.setItem("SelectedLng", selectedLatLng[1])
    localStorage.setItem("SelectedLatLng", selectedLatLng)
    setsearhCharLocation(choosedLocationpopup);
    localStorage.setItem("Location", choosedLocationpopup)
    setChoosedLocation(choosedLocationmap)
   
  }




  // useEffect(() => {
  //   if (isHome && searchInputRef.current) {
  //     searchInputRef.current.focus();
  //   }
  // }, [isHome]);


  return (
    <Box sx={{
      width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '4px'
    }}>
      <Box sx={{
        width: '100%', height: '100%', display: 'flex',
        flexDirection: { md: isHome ? 'column' : 'row', sm: isHome ? 'column' : 'row', xs: 'column' },
        gap: { md: '16px', xs: '8px' },
      }}>
        <Box sx={{
          width: { md: isHome ? '80%' : '30%', sm: isHome ? '80%' : '30%', xs: 'calc(100%-32px)' }, height: '33px',
          padding: '10px 16px 10px 16px', border: '1px solid', borderColor: Colors.default,
          borderRadius: '28px', marginTop: { md: '12px', xs: '0px' }, gap: '8px', display: 'flex', flexDirection: 'row',
          '&:hover': {
            borderColor: Colors.black_80,
            '& svg': {
              color: Colors.black_80,
            },
            '& input': {
              color: Colors.black_80, // Change input text color on hover
            },
            '& button': {
              color: Colors.black_80, // Change button color on hover
            },
          },
        }}>
          <LocationOnOutlinedIcon sx={{
            color: Colors.grey_60, height: '24px', width: '24px', paddingTop: '5px',
          }} />
          <InputBase
            placeholder="Add your location"
            onClick={handleLocationClick}
            value={searhCharLocation}
            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', color: Colors.default }}
            inputProps={{
              style: {
                fontFamily: 'Roboto', fontSize: '16px',
                '::placeholder': {
                  fontFamily: 'Roboto', fontSize: '16px',
                },
              },
            }}
          />
          <EditOutlinedIcon onClick={handleLocationClick}
            sx={{
              color: Colors.black_80, height: '24px', width: '24px', paddingTop: '5px', cursor: 'pointer',
              paddingRight: isHome ? { md: '12px', sm: '12px', xs: '2px' } : '0px'
            }} />
        </Box>
        <Box sx={{
          width: { md: isHome ? '80%' : '70%', sm: isHome ? '80%' : '70%', xs: 'calc(100%-32px)' }, height: '33px',
          padding: '10px 16px 10px 16px', border:  isHome ? '2px solid': '1px solid' , borderColor: Colors.default,
          borderRadius: '28px', marginTop: { md: '12px', xs: '8px' }, gap: '20px', display: 'flex', flexDirection: 'row',
          position: 'relative',
          '&:hover': {
            borderColor: Colors.black_80,

            '& input': {
              color: Colors.black_80, // Change input text color on hover
            },
            '& button': {
              color: Colors.black_80, // Change button color on hover
            },
            '& input::placeholder': {
              color: Colors.black_80, opacity: 1 // Change placeholder color on hover
            },
          },
        }}
        >

          <InputBase
            placeholder="Search here"
            ref={searchInputRef}
            onChange={(event) => handleMainSearch(event)}
            value={mainSearchValue}
            style={{
              flex: 1, border: 'none', outline: 'none', background: 'transparent', color: Colors.default, paddingLeft: '6px'
            }}
            inputProps={{
              autoFocus: true,
              style: {
                fontFamily: 'Roboto', fontSize: '16px', color: Colors.default,
                '::placeholder': {
                  fontFamily: 'Roboto', fontSize: '16px', color: Colors.default, paddingLeft: '24px'
                },
                '&:hover::placeholder': {
                  color: Colors.black_80,
                },
              },

            }}
          />
          <Box onClick={() => handleSearchIconClick(mainSearchValue)} disabled={searhCharEdit === 'false'} sx={{
            position: 'absolute', right: '0', top: 0, backgroundColor: Colors.primary, borderRadius: '0 28px 28px 0',
            height: '33px', padding: '10px 16px 10px 16px', justifyContent: 'center', alignContent: 'center', display: 'flex',
            width: { md: '60px', sm: '50px', xs: '30px' }, cursor: 'pointer', '&:hover': {
              backgroundColor: Colors.Hovering,
            },
          }}>
            <SearchIcon onClick={() => handleSearchIconClick(mainSearchValue)}
              sx={{ color: Colors.white, height: '24px', width: '24px', textAlign: 'center', paddingTop: '3px' }} />
          </Box>
        </Box>
       {searchError && isHome  && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{searchError}
       </TypographyComponent>}

      </Box>
      {searchError && !isHome  && <TypographyComponent sx={{ paddingLeft:'33%',paddingTop:'12px',fontSize: '12px', color: Colors.error }}>{searchError}
      </TypographyComponent>}
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
        alignItems: 'center', gap: '16px',
      }}>

        {showResults && (
          <Box sx={{
            width: isHome ? '100%' : '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
            alignItems: 'center', gap: '16px', position: 'relative',
          }}>
            <Box sx={{ width: isHome ? '0%' : '30%' }}></Box>
            <Box ref={searchRef} sx={{
              width: { md: isHome ? '80%' : '66%', sm: isHome ? '80%' : '66%', xs: isHome ? '88%' : '88%' }, padding: '16px 16px 16px 16px', position: 'absolute', [isHome ? 'left' : 'right']: 0,
              borderRadius: '16px', gap: '16px', maxHeight: { md: '225px', sm: '225px', xs: '162px' }, overflow: 'auto', top: '0px',
              height: 'fit-content', boxShadow: '0px 4px 4px 0px #00000025', border: '1px solid', zIndex: 5,
              borderColor: Colors.grey_60, background: Colors.white,
              '&::-webkit-scrollbar': {
                width: '11px',
                height: '8px', // Added height for horizontal scrollbars
                borderRadius: '16px',

            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.grey_60,
                borderRadius: '16px',
                border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: '16px',
                backgroundColor: Colors.white,
                marginTop: '10px',
                marginBottom: '10px'
            },

            }}>
              {searchResultServices.map((result, index) => (
                <Box onClick={() => handleServiceClick(result.services, result.service_id, result.service_category, result.service_category_id)}
                  key={index} sx={{
                    width: 'calc(100% -16px)', display: 'flex', flexDirection: 'row', padding: '8px',
                    cursor: 'pointer', '&:hover': {
                      backgroundColor: Colors.quinary,
                    },
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
                    <WorkOutlineOutlinedIcon sx={{ width: '18px', height: '18px', color: Colors.grey_60 }} />

                    <TypographyComponent sx={{
                      fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,
                      minWidth: 'fit-content'
                    }}> {result.services} </TypographyComponent>
                    <TypographyComponent sx={{
                      fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.grey_60,
                      whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      minWidth: `calc((100% - 16px) - (${result.services.length} + 18px))`
                    }}>  ({result.service_category})
                    </TypographyComponent>

                  </div>
                </Box>

              ))}
              {searchResultTypes.map((result, index) => (

                <Box key={index} onClick={() => handleTypeClick(result.types, result.types_id, result.service_id)}
                  sx={{
                    width: 'calc(100% -16px)', display: 'flex', flexDirection: 'row', padding: '8px',
                    cursor: 'pointer', '&:hover': {
                      backgroundColor: Colors.quinary,
                    },
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
                    <PsychologyOutlinedIcon sx={{ width: '19px', height: '19px', color: Colors.grey_60, }} />
                    <TypographyComponent sx={{
                      fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,
                      minWidth: 'fit-content'
                    }}> {result.types}</TypographyComponent>
                    <TypographyComponent sx={{
                      fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.grey_60,
                      whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      minWidth: `calc((100% - 16px) - (${result.services.length} + 18px))`
                    }}>   ({result.services})
                    </TypographyComponent>

                  </div>
                </Box>

              ))}
             
              {searchResultSP.map((result, index) => (

                <Box key={index} onClick={() => handleSPClick(result.business_name, result.service_provider_id, result.services_id)}
                  sx={{
                    width: 'calc(100% -16px)', display: 'flex', flexDirection: 'row', padding: '8px',
                    cursor: 'pointer', '&:hover': {
                      backgroundColor: Colors.quinary,
                    },
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
                  {result.profile_image &&                  
                     <Avatar alt="Leboco" src={IMG_URL + result.profile_image} 
                     sx={{ width: '19px', height: '19px' }} />}
                    {!result.profile_image &&                  
                    //  <PersonOutlineOutlinedIcon sx={{ width: '19px', height: '19px', color: Colors.grey_60, }} />
                    <Box
                    sx={{
                        width: '19px',
                        height: '19px',
                        borderRadius: '50%',
                        backgroundColor:  colors[index % colors.length],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: {xs:'16px', md: '14px'} ,
                        fontWeight: 'bold',
                        color: Colors.white,
                      //  border: '2px solid #ffffff',
                       // boxShadow: '0px 4px 4px 0px #00000040',
                    }}
                >
                  {result?.business_name ? result.business_name[0].toUpperCase() : ''}
                </Box>
                    }
                    <TypographyComponent sx={{
                      fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,
                      minWidth: 'fit-content'
                    }}>  {result.business_name}
                    </TypographyComponent>
                    <TypographyComponent sx={{
                      fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.grey_60,
                      whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                      minWidth: `calc((100% - 16px) - (${result.services.length} + 18px))`
                    }}> ({result.services})
                    </TypographyComponent>

                  </div>
                </Box>
              ))}

              {searchResultSPCode.map((result, index) => (

              <Box key={index} 
              onClick={() => handleSPCodeClick(result.business_name, result.service_provider_code,result.service_provider_id, result.services_id)}
                sx={{
                  width: 'calc(100% -16px)', display: 'flex', flexDirection: 'row', padding: '8px',
                  cursor: 'pointer', '&:hover': {
                    backgroundColor: Colors.quinary,
                  },
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
                {result.profile_image &&                  
                  <Avatar alt="Leboco" src={IMG_URL + result.profile_image} 
                  sx={{ width: '19px', height: '19px' }} />}
                  {!result.profile_image &&                  
                  //  <PersonOutlineOutlinedIcon sx={{ width: '19px', height: '19px', color: Colors.grey_60, }} />
                  <Box
                  sx={{
                      width: '19px',
                      height: '19px',
                      borderRadius: '50%',
                      backgroundColor:  colors[index % colors.length],
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: {xs:'16px', md: '14px'} ,
                      fontWeight: 'bold',
                      color: Colors.white,
                    //  border: '2px solid #ffffff',
                    // boxShadow: '0px 4px 4px 0px #00000040',
                  }}
              >
                {result?.business_name ? result.business_name[0].toUpperCase() : ''}
              </Box>
                  }
                  <TypographyComponent sx={{
                    fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,
                    minWidth: 'fit-content'
                  }}>  {result.service_provider_code}
                  </TypographyComponent>
                  <TypographyComponent sx={{
                    fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.grey_60,
                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                    minWidth: `calc((100% - 16px) - (${result.services.length} + 18px))`
                  }}> ({result.services})
                  </TypographyComponent>

                </div>
              </Box>
              ))}
            </Box>
          </Box>)}


      </Box>
      {isEditLocation &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { md: '60%', sm: '60%', xs: '85%' },
              height: '70%',
              backgroundColor: Colors.white,
              border: '1px solid ',
              borderColor: Colors.white,
              borderRadius: '16px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '20px',
              alignItems: 'center',

            }}
          >
            <IconButton onClick={handleEditLocationPopUpCancelButtonClick} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: { md: '78%', sm: '78%', xs: '84%' }, top: { md: '8%', sm: '8%', xs: '9%' }
            }} >
              <CancelIcon sx={{
                width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                  color: Colors.close,
                },
              }} ></CancelIcon>
            </IconButton>
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
              justifyContent: 'center', alignContent: 'center', height: '100%',
            }}>
              <Box sx={{
                display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' }, width: '100%', gap: '8px',
                justifyContent: { md: 'space-between', sm: 'space-between', xs: 'left' }, alignItems: { md: 'center', sm: 'center', xs: 'left' },
                alignContent:{ md: 'center', sm: 'center', xs: 'left' } , height:'fit-content', 
              }}>
                <div>
                  <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, display: 'inline', paddingLeft: '5px' }}>
                    Your location :  </TypographyComponent>
                  <TypographyComponent sx={{ fontSize: '16px', color: Colors.grey_60, display: 'inline' }}>{searhCharLocationpopup}</TypographyComponent>
                </div>
               <Box sx={{display:'flex',flexDirection:'column',width:{ md: '50%', sm: '50%', xs: '100%' }
               }}>
               
                <Autocomplete id="autocomplete_id" onLoad={onLoad} onPlaceChanged={onPlaceChanged} options={AutocompleteOptions} style={{width:'100% !important'}}>
                  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width:'100%', }}>
                   <SearchIcon
                      style={{ position: 'absolute', right: '2.5%', pointerEvents: 'none', color: 'gray' }}
                    /> 
                    <InputBase                    
                      placeholder="Search here"
                      sx={{
                         border: 'none', outline: 'none', background: 'transparent', color: 'black',
                        paddingLeft: '8px', border: '1px solid', borderColor: Colors.default, borderRadius: '16px',
                        height: { md: '38px', sm: '38px', xs: '36px' },width:'100%'
                      }}
                      inputProps={{
                        style: {
                          fontFamily: 'Roboto', fontSize: '16px', color: 'black',width: '100%',
                          '::placeholder': {
                            fontFamily: 'Roboto', fontSize: '16px', color: 'black', paddingLeft: '24px'
                          },
                          '&:hover::placeholder': {
                            color: 'black'
                          }
                        }
                      }}
                    />
                  </Box> 
                </Autocomplete>
                </Box>
              </Box> 
     
              <GoogleMap
                mapContainerStyle={{ ...mapContainerStyle, cursor: 'pointer !important' }}
                zoom={12}
                center={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }}
              // center={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }}
                onClick={handleMapClick}
              >
                {selectedLatLng && (
                  <MarkerF position={{ lat: selectedLatLng[0], lng: selectedLatLng[1] }} />
                )}
              </GoogleMap>

              <Box sx={{
                display: 'flex', flexDirection: 'row', width: '100%', gap: '16px',
                justifyContent: 'right', alignContent: 'right', height: '40px', right: 0
              }}>
                <ButtonComponent disabled={searhCharLocationpopup === 'Location not found'} sx={{ width: { md: '25%', sm: '35%', xs: '58%' }, }} onClick={handleEditLocationPopUpChangeButtonClick}>Change location</ButtonComponent>
              </Box>
            </Box>
          </Box>
        </Box>}

    </Box>
  )
} export default SearchComponent