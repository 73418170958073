import { Avatar, Box, Divider, Grid, IconButton, InputAdornment, List, ListItem, MenuItem, Stack } from "@mui/material";
import TextFieldComponent from "../Components/TextFieldComponent";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import TypographyComponent from "../Components/TypographyComponent";
import ListComponent from "../Components/ListComponent";
import SelectComponent from "../Components/SelectComponent";
import SearchComponent from "../Components/SearchComponent";
import UserHeader from "../Components/UserHeader";
import profile from "../Images/profileIcon.png";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import VisibilityOutlinedIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import axios from "axios";
import { API_URL } from './Constants';
import ServiceProviders from "../Components/ServiceProviders";
import ProfileComponent from "../Components/ProfileComponent";
import Colors from "../Components/Colors";
import UserFooter from "../Components/UserFooter";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

async function getCoordinates(location) {
    const apiKey = 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs';
    const encodedLocation = encodeURIComponent(location);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&key=${apiKey}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { latitude: lat, longitude: lng };
      } else {
        throw new Error('Location not found');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error.message);
      throw error;
    }
  }
  



function ServiceData() {
    const [loading, setLoading] = useState(true);
    const items = ["Electrician", "Plumbing", "HVAC", "Roofing", "HVAC", "Roofing"];
    const [value, setValue] = useState("");
    const [selectedOption, setSelectedOption] = useState("All");
    const [showDropdown, setShowDropdown] = useState(false);
    const [searhCharLocation, setsearhCharLocation] = useState('');

    const [mainSearchValue, setMainSearchValue] = useState('');
    const [searchResultServices, setSearchResultServices] = useState([]);
    const [searchResultTypes, setSearchResultTypes] = useState([]);
    const [searchResultSP, setSearchResultSP] = useState([]);

    const service_category = localStorage.getItem("service_category");
    const service_category_id = localStorage.getItem("service_category_id");
    const UserSelctLat =  localStorage.getItem("SelectedLat")
    const UserSelctLng = localStorage.getItem("SelectedLng")
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [selectedType, setSelectedType] = useState(null);
    const [selectedTypeId, setSelectedTypeId] = useState(null);

    const [selectedService, setSelectedService] = useState(null);

    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDtls, setServiceDtls] = useState([]);

    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [serviceProviderUrl, setServiceProviderUrl] = useState(null);

    const [spIdChange, setSpIdChange] = useState(false);
    const [isFilterClickAndNoSp, setIsFilterClickAndNoSp] = useState(false);
    const location = localStorage.getItem("Location")
    const userLocation = localStorage.getItem("Location")
    const spId = localStorage.getItem('SPId');
    const [typeAllTypeId, setTypeAllTypeId] = useState(null);
    const [selectedIdFtime, setSelectedIdFtime] = useState(null);

    const [selectedId, setSelectedId] = useState(null);

    const [isServiceProvider, setIsServiceProvider] = useState(false);
  
    useEffect(() => {
    // console.log(serviceProviders)
         setLoading(true)
        setIsServiceProvider(true); 
          
     
    }, []);
   
    const [isFilterClick, setIsFilterClick] = useState(false);

    async function fetchservice() {
         try {
             const response = await axios.post(`${API_URL}/sp-details`, { spId });
           console.log("service-provider-details", response.data.data.serviceProvider)
             if (response.data.success) {
               
                const serviceDetails = response.data.data.serviceProvider.service_details;    
                const sortedServiceDetails = serviceDetails.sort((a, b) => a.service_id - b.service_id);
                setServiceDtls(sortedServiceDetails);
                console.log(sortedServiceDetails)
                setSelectedId(response.data.data.serviceProvider.service_details[0].services_id);
              
             } else {
                 console.error('Error:', response.data.error);
             }
           
         } catch (error) {
            
             console.error('Error:', error.message);
         }
     }
     useEffect(() => {
        fetchservice();
    }, []);

    async function fetchServiceProviderDetails() {

       // console.log(spId);
        // const spId = 40;
        try {
            const response = await axios.post(`${API_URL}/sp-details`, { spId });
          console.log("service-provider-details", response.data.data.serviceProvider)
            if (response.data.success) {
              //  console.log(response.data.data.serviceProvider)
                setServiceProviderDtls(response.data.data.serviceProvider)
                //setServiceDtls(response.data.data.serviceProvider.service_details)
                const serviceDetails = response.data.data.serviceProvider.service_details;    
                const sortedServiceDetails = serviceDetails.sort((a, b) => a.service_id - b.service_id);
                setServiceDtls(sortedServiceDetails);
                console.log(sortedServiceDetails)
               // setSelectedIdFtime(response.data.data.serviceProvider.service_details[0].services_id);
             //   console.log("id", response.data.data.serviceProvider.service_details[0].services_id)

             //   console.log("service-details", response.data.data.serviceProvider.service_details[0])
                setServiceProviderQrCode(response.data.data.qrCode)
                setServiceProviderUrl(response.data.data.serviceProviderURL)
                setSelectedServiceId(response.data.data.serviceProvider[0].service_provider_id)
                localStorage.setItem("SPId", response.data.data.serviceProvider[0].service_provider_id);
                console.log(response.data.data.serviceProvider.length)
                if(response.data.data.serviceProvider.length !== 0){
                    setIsServiceProvider(false);
                    setLoading(false)
                    const response3 = await axios.post(`${API_URL}/select_all_types`,
                    { serviceId: response.data.data.serviceProvider[0].services_id, });
                    console.log(response3.data)
                    setTypes(response3.data);
                   console.log("all types", response3.data)
                  }
            } else {
                console.error('Error:', response.data.error);
            }
           // console.log('loading',loading)
            // setLoading(false);
        } catch (error) {
            // setLoading(false);
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchServiceProviderDetails();
    }, [spId]);
    const fetchService = async () => {
        try {
          //  console.log('serviceId:', selectedServiceId);
          //  console.log('TypeId:', selectedTypeId);

            if (selectedServiceId || (typeAllTypeId && selectedType === 'All')) {
                let selectServiceId;
                const coordinates = { latitude: UserSelctLat, longitude: UserSelctLng }
               // console.log('Coordinates:', coordinates);
                if (selectedServiceId) {
                  
                    selectServiceId = selectedServiceId
                }
                else {
                    selectServiceId = typeAllTypeId;
                }
              //  console.log(typeAllTypeId)
              //  console.log(selectServiceId)
                const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers_with_service`, {
                    service_id: selectServiceId,
                    location: userLocation,
                    coordinates: coordinates
                });
               // console.log(serviceProvidersResponse)
              //  console.log("hiii")
              //  console.log(isFilterClick)
                if(!isFilterClick)
                   
                    if( serviceProvidersResponse.data.error === 'No service providers found'){
                        setIsServiceProvider(true);
                        setLoading(false)
                       // console.log("hiii") 
                    }
                    else
                    {
                       //  console.log("hiii")
                        setserviceProviders(serviceProvidersResponse.data.serviceProviders);
                        setIsServiceProvider(false);
                        setLoading(false)
                   }
                    // console.log(serviceProvidersResponse.data.serviceProviders.length)
                    // if(serviceProvidersResponse.data.serviceProviders.length !== 0){
                    //   setIsServiceProvider(false);
                    //   setLoading(false)
                    // }
                   // console.log(serviceProvidersResponse)
                   
                const sp = serviceProvidersResponse.data.serviceProviders[0]
               // console.log("hiii")
               // console.log(localStorage.getItem("SPId"))
                if (!localStorage.getItem("SPId")) {
                  //  console.log('Entering if condition');
                    localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);
                } else {
                    console.log('SPId not found in localStorage');
                }


            }
           // console.log("selected typeid...............", selectedTypeId)
            if ((selectedTypeId && selectedType !== 'All') || (selectedTypeId && selectedType !== 'All')) {
                const coordinates = { latitude: UserSelctLat, longitude: UserSelctLng }
               // console.log('Coordinates:', coordinates);
                const serviceProvidersResponse = await axios.post(`${API_URL}/select_service_providers_with_types`, {
                    type_id: selectedTypeId,
                    location: userLocation,
                   coordinates: coordinates
                });
               console.log(serviceProvidersResponse.data.serviceProviders)
             
               // setLoading(false)
                if(serviceProvidersResponse.data.serviceProviders.length > 0){
                    setIsServiceProvider(false);
                    setLoading(false)
                    setserviceProviders(serviceProvidersResponse.data.serviceProviders);
                  }
                const sp = serviceProvidersResponse.data.serviceProviders[0]
               // console.log(sp.service_provider_id)
                if(serviceProvidersResponse.data.serviceProviders[0]){
               // console.log(localStorage.getItem("SPId"))
                localStorage.setItem("SPId", serviceProvidersResponse.data.serviceProviders[0].service_provider_id);}
                // if (!localStorage.getItem("SPId")) {
                //     console.log('Entering if condition');
                //     localStorage.setItem("SPId", sp.service_provider_id);
                // } else {
                //     console.log('SPId not found in localStorage');
                // }
            }
            // setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            // setLoading(false);
        }
    };
    useEffect(() => {
       
       // console.log(getCoordinates(location))
        fetchService();
    }, [selectedTypeId, selectedServiceId, selectedType]);


    const selectType = async () => {
        try {
           console.log(selectedId)
            const response = await axios.post(`${API_URL}/select_all_types`,
                { serviceId: selectedId, });
            setTypes(response.data);
           console.log("all types", response.data)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    }


    useEffect(() => {
        selectType();
    }, [selectedId]);

    const [types, setTypes] = useState([]);
    const [services, setservices] = useState([]);
    const [serviceProviders, setserviceProviders] = useState([]);

    const handleServiceClick = async (index, id, service) => {   
        console.log(id)   
        console.log(service)   
        setIsFilterClick(false)       
        setSelectedType('All');    
        setserviceProviders([])
        setLoading(true)
        setSelectedServiceId(id);
        setSelectedIndex(index);
        setSelectedService(service);
        localStorage.removeItem("SPId")
        localStorage.setItem("serviceId",id)
        setSelectedId(id);
        selectType(id);
        setTypes([])
        // try {
        //     let selectedId;
        //     if(id===null){
        //       selectedId=  serviceDtls[0].services_Id;
        //     }
        //     else{
        //         selectedId=id
        //     }
        //     const response = await axios.post(`${API_URL}/select_all_types`,
        //         { serviceId: selectedId, });
        //     setTypes(response.data);
        //     console.log("all types", response.data)
        // } catch (error) {
        //     console.error('Error fetching service categories:', error);
        // }

    };

    

    // useEffect(() => {
    //     const fetchServices = async () => {
    //         try {

    //             const response = await axios.post(`${API_URL}/select_services`, { service_category_id });
    //             setservices(response.data);
    //             //  console.log("category", response.data)
    //         } catch (error) {
    //             console.error('Error fetching service categories:', error);
    //         }
    //     }
    //     fetchServices();
    // }, []);

    // useEffect(() => {
    //     const fetchServiceProviders = async () => {

    //         try {
    //             const serviceId = services.length > 0 ? services[0].service_id : null;
    //             if (serviceId) {
    //                 const response = await axios.post(`${API_URL}/select_service_providers`, { service_id: serviceId });
    //                 setserviceProviders(response.data);
    //                 // console.log("category", response.data)
    //             } else {
    //                 console.warn('No services available to fetch service providers.');
    //             }
    //             //  console.log("category", response.data)
    //         } catch (error) {
    //             console.error('Error fetching service categories:', error);
    //         }
    //     }
    //     fetchServiceProviders();
    // }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch services
                const coordinates = { latitude: UserSelctLat, longitude: UserSelctLng }
               // console.log('Coordinates:', coordinates);
                const servicesResponse = await axios.post(`${API_URL}/select_services`, { service_category_id });
                const serviceDetails = servicesResponse.data;
                const sortedServiceDetails = serviceDetails.sort((a, b) => a.service_id - b.service_id);
                setservices(sortedServiceDetails);
               // console.log("services", servicesResponse.data);
                setSelectedService(servicesResponse.data[0].services);
                setSelectedServiceId(servicesResponse.data[0].service_id)
                localStorage.setItem("serviceId",servicesResponse.data[0].service_id)
                fetchService();
                // Fetch service providers based on the first result
                // if (servicesResponse.data[0].service_id) {
                //     const serviceId = servicesResponse.data[0].service_id;
                //     const serviceProvidersResponseforservice = await axios.post(`${API_URL}/select_service_providers_with_service`, {
                //         service_id: serviceId,
                //         location: userLocation,
                //         coordinates: coordinates
                //     });
                //     console.log(serviceProvidersResponseforservice)
                //     if( serviceProvidersResponseforservice.data.error === 'No service providers found'){
                //         setIsServiceProvider(true);
                        
                //     }
                //     else
                //     {
                //         setserviceProviders(serviceProvidersResponseforservice.data.serviceProviders);
                //         setIsServiceProvider(false);
                //         setLoading(false)
                //    }

                // } else {
                //     console.warn('No services available to fetch service providers.');
                // }
                if (servicesResponse.data.length > 0) {
                    const serviceId = servicesResponse.data[0].service_id;
                    const response = await axios.post(`${API_URL}/select_all_types`, {
                        serviceId: serviceId,
                    });
                    setTypes(response.data);
                    console.log("Initial types data:", response.data);
                }
                else {
                    console.warn('No services available to fetch types.');
                }
                // setLoading(false)

            } catch (error) {
                // setLoading(false)
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    // const handleChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };

    // const handleMenuItemClick = (id, type) => {
    //     setSelectedType(type); // Update the selected type
    //     setSelectedTypeId(id); // Update the selected type ID if needed
    //     setShowDropdown(false);
    // }
    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                const city = data.city;
                setsearhCharLocation(city);
            } catch (error) {
                console.error('Error fetching location:', error);
            }
        };

        fetchLocation();
    }, []);



    // const options = [
    //     { label: "All", value: "All" },
    //     { label: "Installation of  Electrical Equipment's & maintenance", value: "   Installation of  Electrical Equipment's & maintenance" },
    //     { label: "Pot light installation", value: "  Pot light installation" },
    // ];

    const handleCloseIconClick = () => {
        setValue("");
    };
    const handleSearchIconClick = () => {
        console.log("search click");
    };


    // useEffect(() => {
    //     console.log(serviceProviders.length )
    //     if(serviceProviders.length === 0){
    //        setIsServiceProvider(false);  
    //     }
    //        setIsServiceProvider(true);

    //    }, [serviceProviders]);

    useEffect(() => {
        setIsFilterClick(false)
        if (isServiceProvider) {
          setTimeout(() => {         
            setLoading(false); // Stop loading after the delay
          }, 1800); // 30 seconds delay
        }
      }, [isServiceProvider]);

    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', 
            alignItems: 'center',
        }}>
            < UserHeader />
            <Box sx={{
                display: 'flex', width: '100%', height: '100%', flexDirection: 'row', gap: '16px', justifyContent: 'center', alignItems: 'center',
                maxWidth: '95%', maxHeight: '95%',paddingTop: { xs: '16px', md: '0px' }
            }}>
                <SearchComponent isHome={false}></SearchComponent>
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'row', width: '100%', height: '100%', justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', height: '100%', justifyContent: { xs: 'center', md: 'flex-start' },
                    alignItems: { xs: 'center', md: 'flex-start' },
                    maxWidth: '95%', maxHeight: '95%', gap: '2%', paddingTop: '2.5%', paddingBottom: '2.5%'
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '20%' }, maxWidth: { xs: '97%', md: '20%' },minWidth:{ xs: '97%', md: '20%' } ,height: '100%',
                        gap: '48px',
                    }}>
                        <Box sx={{ width: '100%', display: { xs: 'none', md: 'block' } }}>
                            {service_category && (
                                <>
                                    <TypographyComponent sx={{ fontSize: '18px', fontColor: Colors.black_80 }}>
                                        {service_category}
                                    </TypographyComponent>
                                    <Box sx={{ width: '100%', }}>
                                        <List>
                                            {services.map((service, index) => (
                                                <ListItem
                                                    key={index}
                                                    onClick={() => handleServiceClick(index, service.service_id,
                                                        service.services)}
                                                    sx={{
                                                        backgroundColor: selectedIndex === index ? Colors.primary : "inherit",
                                                        color: selectedIndex === index ? Colors.white : "inherit",
                                                        borderRadius: '8px', justifyContent: 'space-between',
                                                        "&:hover": {
                                                            backgroundColor: selectedIndex === index ? Colors.primary : Colors.quinary,
                                                            borderRadius: '8px',
                                                            // color: Colors.white,
                                                            cursor: 'pointer'
                                                        },
                                                    }}
                                                >
                                                    {service.services}
                                                    {selectedIndex === index && (<ChevronRightIcon style={{ color: Colors.white }}></ChevronRightIcon>)}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box sx={{
                            display: { xs: 'flex', md: 'none' }, overflowX: 'auto',
                            flexDirection: 'column', gap: '8px', maxWidth: '100%',
                        }}>
                            {service_category && (
                                <>
                                    <TypographyComponent sx={{ fontSize: '18px', fontColor: Colors.black_80 }}>
                                        {service_category}
                                    </TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', maxWidth: '100%',
                                        overflowX: 'auto', flexDirection: 'row', gap: '8px', paddingBottom: '16px'
                                    }}>
                                        {services.map((service, index) => (

                                            <Box
                                                sx={{
                                                    border: '1px solid ' + Colors.grey_60,

                                                    borderRadius: '8px',
                                                    padding: '4px 4px',
                                                    cursor: 'pointer',
                                                    width: 'fit-content',
                                                    height: '28px', alignContent: 'center',
                                                    whiteSpace: 'nowrap', justifyContent: 'center', alignItems: 'center',
                                                    "&:hover": {
                                                        backgroundColor: Colors.primary,
                                                        borderRadius: '8px',
                                                        color: Colors.white,
                                                        cursor: 'pointer'
                                                    },
                                                }}
                                                onClick={() => handleServiceClick(index, service.service_id,
                                                    service.services)}
                                            >
                                                {service.services}
                                            </Box>
                                        ))}
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                   
                    {loading && isServiceProvider  && (
                         <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', 
                         height: '70vh', justifyContent: 'center', alignItems: 'center' }} >
                         <TypographyComponent sx={{
                             fontSize: { md: '18px', sm: '18px', xs: '14px' }, color: Colors.grey_60,
                             minWidth: 'fit-content'
                         }}> Loading...</TypographyComponent>
                     </Box>
                    )}
                     {loading && !isServiceProvider  && (
                         <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', 
                         height: '70vh', justifyContent: 'center', alignItems: 'center' }} >
                         <TypographyComponent sx={{
                             fontSize: { md: '18px', sm: '18px', xs: '14px' }, color: Colors.grey_60,
                             minWidth: 'fit-content'
                         }}> Loading...</TypographyComponent>
                     </Box>
                    )}
                   
              {!loading && isServiceProvider &&(               
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', 
                        height: '70vh', justifyContent: 'center', alignItems: 'center', maxWidth:{md: '100%', sm: '100%', xs: '90%' } }} >
                            <TypographyComponent sx={{
                                fontSize: { md: '18px', sm: '18px', xs: '14px' }, color: Colors.grey_60,
                                minWidth: 'fit-content'
                            }}> Oops! We couldn't find any service providers in this category.</TypographyComponent>
                        </Box>)}
                      
                        {!loading && !isServiceProvider &&(
                        <>
                            <Box
                                sx={{
                                    display: 'flex', flexDirection: 'column', overflowY: 'auto', width: { md: '40%', sm: '40%', xs: '100%' },
                                    gap: '48px', maxHeight: '650px', maxWidth: { md: '90%', sm: '90%', xs: '98%' },
                                    '&::-webkit-scrollbar': {
                                        width: { md: '8px', sm: '8px', xs: '0px' }, // Width of the scrollbar
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#d9d9d9',
                                        borderRadius: '10px',
                                        borderRight: { md: '2px solid #d9d9d9', sm: '2px solid #d9d9d9', xs: '0px solid #d9d9d9' },
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        borderRight: { md: '2px solid #d9d9d9', sm: '2px solid #d9d9d9', xs: '0px solid #d9d9d9' },
                                    },
                                }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: { md: '98%', sm: '98%', xs: '100%' },
                                    gap: '48px', justifyContent: 'flex-start', alignItems: 'center', paddingTop: { md: '0%', sm: '0%', xs: '5%' }
                                }}>

<ServiceProviders serviceProviders={serviceProviders} setserviceProviders={setserviceProviders}
                selectedService={selectedService} types={types} selectedType={selectedType}
                setSelectedType={setSelectedType} setSelectedTypeId={setSelectedTypeId}
                selectedTypeId={selectedTypeId} setSpIdChange={setSpIdChange}
                spIdChange={spIdChange} setTypeAllTypeId={setTypeAllTypeId}  serviceDtls={serviceDtls}
                serviceProviderQrCode={serviceProviderQrCode} serviceProviderUrl={serviceProviderUrl}
                setIsFilterClick = {setIsFilterClick} setLoading= {setLoading}
                setIsServiceProvider ={setIsServiceProvider} isFilterClick={isFilterClick} isFilterClickAndNoSp = {isFilterClickAndNoSp}> </ServiceProviders>
                                </Box>
                            </Box>

                            <Box sx={{
                                display: { xs: 'none', md: 'flex' }, flexDirection: 'column', width: { md: '40%', sm: '40%', xs: '100%' },
                                gap: '48px', maxWidth: { md: '90%', sm: '90%', xs: '98%' },
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '98%' },
                                    gap: '48px', justifyContent: 'center', alignItems: 'center',
                                }}>
                                  { localStorage.getItem('SPId')&&   <ProfileComponent serviceProviderDtls={serviceProviderDtls} isContactEnable={false}
                                        serviceDtls={serviceDtls} serviceProviderQrCode={serviceProviderQrCode} serviceProviderUrl={serviceProviderUrl}>
                                    </ProfileComponent>}
                                </Box>
                            </Box>
                        </>)}
                     

                </Box>
            </Box>
            <Box sx={{display:'flex', width:'100%',height:'100%',bottom:0, flexDirection:'column'}}>
            < UserFooter />
            </Box>
            
        </Box>
       
    )

}
export default ServiceData;