
import { Grid, Box } from "@mui/material";
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";
import UserHeaderMobile from "../Components/UserHeaderMobile";
import aboutus from "../Images/aboutUs.jpg"
import TypographyComponent from "../Components/TypographyComponent";
import Colors from "../Components/Colors";
import SpHeader from "../Components/SpHeader";
import SpFooter from "../Components/SpFooter";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from './Constants';

function SpAboutUs() {

        const [isCompleteProfile, setIsCompleteProfile] = useState(true);
        const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
        const [serviceDetails, setServiceDetails] = useState([]);
        const [messageDetails, setMessageDetails] = useState([]);
        const [unreadMessageCount, setUnreadMessageCount] = useState(0);
      

        async function fetchServiceProviderDetails() {
                const token = localStorage.getItem('token');
                try {
                  const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
                
                  if (response.data.success) {
                
                    console.log("service-details", response.data.data.serviceProvider.service_details)
                 
                    const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
                    setServiceProviderDtls(response.data.data.serviceProvider)
                    setServiceDetails(response.data.data.serviceProvider.service_details)
                    setMessageDetails(
                    response.data.data.serviceProvider.message_details.sort((a, b) => b.message_id - a.message_id)
                  );
                    if (response.data.data.serviceProvider.service_details.length === 0) {
                      setIsCompleteProfile(false)
                    }
                    const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                      if (message.read === 'Unread') {
                        return acc + 1;
                      }
                      return acc;
                    }, 0);
                    setUnreadMessageCount(unreadCount);      
                 
                  } else {
                    console.error('Error:', response.data.error);
                  }
            
            
            
                } catch (error) {
                  console.error('Error:', error.message);
                }
              }
              useEffect(() => {
                fetchServiceProviderDetails();
              }, [messageDetails])

             return (
                <Grid container sx={{ width: '100%', height: '100%' }}>
                        <Box sx={{
                                backgroundImage: `url(${aboutus})`, justifyContent: 'center',
                                alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',
                                display: 'flex', flexDirection: 'column', width: '100%', height: '100%'
                        }}>
                                <Box sx={{ width: '100%', display: 'flex' }}>
                                <SpHeader currentPage = {"About us"} isCompleteProfile = {true} 
                                serviceProviderDtls={serviceProviderDtls}
                                unreadMessageCount={unreadMessageCount} serviceDetails={serviceDetails}></SpHeader>
                                </Box>
                                <Box sx={{
                                        width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '5%',
                                        gap:'24px', maxWidth: '90%', margin: '0 auto'
                                }}>
                                         <Box sx={{
            width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
          }}>
            <Box sx={{
              width: { md: '14%', xs: '25%' }, height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center',
              background: Colors.quinary, borderRadius: '36px', padding: '10px'
            }}>
              <TypographyComponent sx={{
                fontFamily: 'Roboto', fontWeight: 400, fontSize: { md: '28px', xs: '16px' }, color: Colors.black_70
              }}>
                About us
              </TypographyComponent>
            </Box>
          </Box>
                                        <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'left',
                                                gap: '16px', flexDirection: 'column'
                                        }}>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 600, fontSize: '40px', color: Colors.black_70
                                                }}>
                                                        Discover excellence in every local service you need!
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70
                                                }}>
                                                        Thank you for your interest in connecting with us! We value your feedback, questions, and any inquiries you may have. Please use the form below to provide your details and message. If you prefer, you can also reach us directly through the contact information provided. We are dedicated to ensuring that your experience with us is positive and fulfilling, and we appreciate your patience as we strive to offer you the best service. Whether you have a question about our products, need support with an order, or simply want to share your thoughts, we are here to help. Thank you for choosing to engage with us – we look forward to hearing from you!
                                                        Welcome to Bro Handy, your trusted community marketplace for finding and advertising local services. at bro handy , we understand the importance of convienience and reliability when it comes to hiring service providers. Thats why we’ve created a user friendly platform that connects the public with a diverse array of local professionals, from plumbers and electricians to tutors and personal trainers.
                                                </TypographyComponent>
                                               
                                        </Box>
                                        <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'left',
                                                gap: '16px', flexDirection: 'column'
                                        }}>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 600, fontSize: '32px', color: Colors.black_70
                                                }}>
                                                       Our mission
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70
                                                }}>
                                                       Our mission is simple: to empower consumers with the information they need to make informed hiring decisions and to enable service providers to grow their business by reaching a broader clientele . we strive to operate with a commitment to realiability and trust, ensuring that all data on our platform is presented with the utmost accuracy and responsibility
                                                </TypographyComponent>
                                        </Box>
                                        <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'left',
                                                gap: '16px', flexDirection: 'column'
                                        }}>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 600, fontSize: '32px', color: Colors.black_70
                                                }}>
                                                      Our users
                                                </TypographyComponent>
                                                <Box>
                                                        <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 600, fontSize: '32px', color: Colors.black_70
                                                }}  >
                                                        Bro Handy serves two main types of users:
                                                        </TypographyComponent>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70
                                                }}>
                                                     <span>Public Users:</span>  Individuals looking for services can browse provider profiles, compare offerings, and contact providers directly through our platform. No personal data is required to search for 
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70
                                                }} >
                                                <span>Service providers:</span> Professionals and businesses can upload their profiles, showcasing their skills, services, and contact information to attract new clients.
                                                </TypographyComponent>
                                                </Box>
                                               
                                        </Box>
                                        <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'left',
                                                gap: '16px', flexDirection: 'column'
                                        }}>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 600, fontSize: '32px', color: Colors.black_70
                                                }}>
                                                       Our Commitment
                                                </TypographyComponent>
                                                <TypographyComponent sx={{
                                                        fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70
                                                }}>
                                                      We operate on a best-effort basis to keep our services available and reliable at all times. At Bro Handy, we believe in transparency and accountability. We take extensive measures to ensure the accuracy and reliability of the data provided on our platform, though we remind users to conduct their own diligence. service providers.
                                                </TypographyComponent>
                                        </Box>
                                        <Box>
                                                <TypographyComponent sx={{fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70}}>
                                                Join us at Bro Handy – where your next service provider is just a few clicks away!
                                                </TypographyComponent>
                                        </Box>
                                        <Box>

                                        </Box>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 'auto' }}>
                                        <SpFooter />
                                </Box>
                        </Box>
                </Grid>
        );

}

export default SpAboutUs;