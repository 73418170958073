import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Typography, Button, Box, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ServiceProviders from '../Components/ServiceProviders';
import axios from 'axios';
import { API_URL } from '../Components/Constants';
import ButtonComponent from '../Components/ButtonComponent';
import TypographyComponent from '../Components/TypographyComponent';
import Colors from '../Components/Colors';
import MessageIcon from '@mui/icons-material/Message';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

async function getCoordinates(location) {
    const apiKey = 'AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs';
    const encodedLocation = encodeURIComponent(location);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            return { latitude: lat, longitude: lng };
        } else {
            throw new Error('Location not found');
        }
    } catch (error) {
        console.error('Error fetching coordinates:', error.message);
        throw error;
    }
}


function TestSpProfileForm() {
    const location = localStorage.getItem("Location")
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const service_category = localStorage.getItem("service_category");
    const service_category_id = localStorage.getItem("service_category_id");
    const [selectedService, setSelectedService] = useState(null);
    const userLocation = localStorage.getItem("Location")
    const [typeAllTypeId, setTypeAllTypeId] = useState(null);

    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDtls, setServiceDtls] = useState([]);

    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [serviceProviderUrl, setServiceProviderUrl] = useState(null);
    const [openList, setOpenList] = useState([false, false, false]); // Initial state for each list item

    const handleToggle = (index) => {
        setOpenList((prevOpenList) => {
            const updatedOpenList = [...prevOpenList]; // Create a copy of the state array
            updatedOpenList[index] = !updatedOpenList[index]; // Toggle the state for the clicked item
            return updatedOpenList; // Return the updated state
        });
    };

    return (
        <List>
            <ListItem button onClick={() => handleToggle(0)}>
                <ListItemText primary={<Typography variant="h6">Item 1</Typography>} />
                {openList[0] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openList[0]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button>
                        <Box sx={{
                            width: { md: 'calc(100%-40px )', sm: 'calc(100%-30px )', xs: 'calc(100%-20px )' },
                            height: { md: 'calc(100%-40px )', sm: 'calc(100%-30px )', xs: 'calc(100%-20px )' }, display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', alignItems: 'center', gap: '16px', padding: { md: '20px', sm: '15px', xs: '10px' }
                        }}>

                            <Box sx={{
                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                alignItems: 'center', gap: '16px',
                            }}>

                                {/* <ButtonComponent onClick={handleViewCardButtonClick} sx={{width:'25%'}}>
                     <RemoveRedEyeOutlinedIcon style={{paddingRight:'5px'}}/>View Card</ButtonComponent> */}
                                <ButtonComponent sx={{ width: { md: '25%', sm: '35%', xs: '35%' } }}
                                //  onClick={() => handleContactButtonClick(serviceProviderDtls.service_provider_id)}
                                >
                                    <MessageIcon style={{ paddingRight: '5px' }} />Contact</ButtonComponent>

                            </Box>

                            <Box sx={{
                                width: 'calc(100% )', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                alignItems: 'center', gap: '16px',
                            }}>
                                <TypographyComponent sx={{ fontSize: '20px', color: Colors.black_80 }}>Profile</TypographyComponent>

                            </Box>
                            <Box sx={{
                                width: 'calc(100% - 36px)', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                alignItems: 'center', padding: '0px 18px 0px 18px', borderRadius: '18px', backgroundColor: Colors.grey_20,
                            }}>
                                <Box sx={{
                                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
                                }}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', padding: '18px 44px 0px 8%',
                                        gap: '20px', justifyContent: 'space-betwwen', alignItems: 'center'
                                    }}>
                                        <Box sx={{ width: '20%', height: '100%', minWidth: '80px', }}>
                                            {/* {newProfilePic === null && (
                    <AccountCircleIcon style={{ width: '100%', height: '100%', color:Colors.grey_60 }} />  )}
                    {newProfilePic && (
                    <img src={newProfilePic} alt="" style={{width: '100%',  height: '100%', }} />
                    )} */}
                                        </Box>

                                        <Box sx={{
                                            width: '75%', height: '100%', display: 'flex',
                                            justifyContent: 'center', alignItems: 'flex-start',
                                        }}>
                                            <Box sx={{ width: '100%', height: '100%', textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <TypographyComponent sx={{ color: Colors.primary, fontSize: '24px', fontWeight: 600 }}>
                                                    {serviceProviderDtls.business_name}</TypographyComponent>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                    {serviceDtls.services}</TypographyComponent>
                                            </Box>

                                        </Box>

                                        {/* <Box sx={{ width: '5%', height: '100%',display:{ md:'flex' , sm:'flex' , xs: 'none'  }}}> 
                        <ShareOutlinedIcon onClick={handleShareButtonClick}  style={{color:Colors.black_80, cursor:'pointer'}}/>
                    </Box>
                    <Box sx={{ width: '5%', height: '100%',display:{ md:'none' , sm:'none' , xs: 'flex'  }}}> 
                        <ShareOutlinedIcon onClick={handleMobileShareButtonClick}  style={{color:Colors.black_80, cursor:'pointer'}}/>
                    </Box> */}

                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '-5px' }}>
                                        <Divider orientation="horizontal" flexItem sx={{
                                            height: '100%', width: '5%', borderColor: Colors.black_80,
                                            borderWidth: '2px'
                                        }} />
                                        <div style={{ width: '15%' }}></div>
                                        <Divider orientation="horizontal" flexItem sx={{
                                            height: '100%', width: '72%', borderColor: Colors.black_80,
                                            borderWidth: '2px'
                                        }} />
                                    </Box>
                                    <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                                        <Box sx={{
                                            width: '20%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '100%' }} />
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px', letterSpacing: '2px' }}>
                                                {serviceProviderDtls.service_provider_code}
                                            </TypographyComponent>
                                        </Box>
                                        <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            {serviceProviderDtls && (
                                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                    <PhoneAndroidIcon sx={{ color: Colors.black_80 }}></PhoneAndroidIcon>
                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                        +  {serviceProviderDtls.country_code}
                                                    </TypographyComponent>
                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                        {serviceProviderDtls.phone_number}
                                                    </TypographyComponent>
                                                </Box>
                                            )}
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                <EmailIcon sx={{ color: Colors.black_80 }}></EmailIcon>
                                                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                    {serviceProviderDtls.email}
                                                </TypographyComponent>
                                            </Box>
                                            {serviceProviderDtls && (
                                                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                    <LocationOnIcon sx={{ color: Colors.black_80 }} />
                                                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                        {serviceProviderDtls.city}, {serviceProviderDtls.province}, {serviceProviderDtls.country}

                                                    </TypographyComponent>

                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: '100%', padding: '18px 44px 18px 44px', display: 'flex', flexDirection: 'column', gap: '12px', }}>

                                        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px', }}>
                                            <PsychologyOutlinedIcon sx={{ color: Colors.black_80 }}></PsychologyOutlinedIcon>
                                            <TypographyComponent sx={{ color: Colors.black_70, fontSize: '16px', minWidth: 'fit-content', }}>
                                                Type of service :
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                {serviceDtls.types}
                                            </TypographyComponent>
                                        </Box>
                                        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                            <ApartmentOutlinedIcon sx={{ color: Colors.black_80 }}></ApartmentOutlinedIcon>
                                            <TypographyComponent sx={{ color: Colors.black_70, fontSize: '16px' }}>
                                                Office :
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                {serviceDtls.office_address}
                                            </TypographyComponent>
                                        </Box>
                                        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                            <LanOutlinedIcon sx={{ color: Colors.black_80 }}></LanOutlinedIcon>
                                            <TypographyComponent sx={{ color: Colors.black_70, fontSize: '16px' }}>
                                                Franchise :
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                {serviceDtls.franchise}
                                            </TypographyComponent>
                                        </Box>
                                        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                            <BadgeOutlinedIcon sx={{ color: Colors.black_80 }}></BadgeOutlinedIcon>
                                            <TypographyComponent sx={{ color: Colors.black_70, fontSize: '16px' }}>
                                                License No :
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                {serviceDtls.license}
                                            </TypographyComponent>
                                        </Box>
                                        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                            <WysiwygOutlinedIcon sx={{ color: Colors.black_80 }}></WysiwygOutlinedIcon>
                                            <TypographyComponent sx={{ color: Colors.black_70, fontSize: '16px' }}>
                                                Certified by :
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                                {serviceDtls.certifications}
                                            </TypographyComponent>
                                        </Box>

                                    </Box>
                                    <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                        <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                                            {serviceDtls.about_us}
                                        </TypographyComponent>
                                    </Box>
                                    <Box sx={{ height: '100%', padding: '18px 44px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                        {/* <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px', textAlign:'center' }}>
                Joined by {formattedDate}   
                </TypographyComponent>   */}

                                    </Box>
                                </Box>


                            </Box>

                        </Box>
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Subitem 2" />
                    </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={() => handleToggle(1)}>
                <ListItemText primary={<Typography variant="h6">Item 2</Typography>} />
                {openList[1] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openList[1]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button>
                        <ListItemText primary="Subitem 1" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Subitem 2" />
                    </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={() => handleToggle(2)}>
                <ListItemText primary={<Typography variant="h6">Item 3</Typography>} />
                {openList[2] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openList[2]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button>
                        <ListItemText primary="Subitem 1" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Subitem 2" />
                    </ListItem>
                </List>
            </Collapse>
        </List>
    );
}

export default TestSpProfileForm;
