import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Colors from './Colors'
import TypographyComponent from './TypographyComponent'
import SelectComponent from './SelectComponent'
import TextFieldComponent from './TextFieldComponent'
import ButtonComponentWhite from './ButtonComponentWhite'
import ButtonComponent from './ButtonComponent'
import axios from 'axios'
import { API_URL } from './Constants'



function AdminCategory() {
    const [activeTab, setActiveTab] = useState('view');
    const [selectedOption, setSelectedOption] = useState('');
    const [textFieldValue, setTextFieldValueCategory] = useState('');
    const [addedCategory, setAddedCategory] = useState('');
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const token = localStorage.getItem('token');

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue); 
    };
    const handleCancel = (event, newValue) => {
        setAddedCategory(''); 
    };
    const handleCategoryListChange = (event) => {
        const value = event.target.value;
        const selectedOption = categoryDetails.find(option => option.service_category_id === value);
        const textContent = selectedOption ? selectedOption.service_category : '';
        setSelectedOption(value); // Update selected dropdown value
        setTextFieldValueCategory(textContent)
    };
    const handleTextFieldChange = (event) => {
        setTextFieldValueCategory(event.target.value); // Update text field value
    };
    const handleUpdateCategory = async () => {
        try {
            const response = await axios.post(`${API_URL}/update_category`, { token, selectedOption, textFieldValue });
            console.log(response)
            fetchAllCategories();
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleTextFieldChangeAddCategory = (event) => {
        const value = event.target.value;
        setAddedCategory(value); // Update text field value
        if (value.trim() !== '') {
            setError(false);
            setErrorMessage('');
        }
    };
    const handleAddCategory = async () => {
        try {
            if (addedCategory.trim() === '') {
                setError(true);
                setErrorMessage('Category cannot be empty');
            } else {
                setError(false);
                setErrorMessage('');
                const response = await axios.post(`${API_URL}/add_category`, { token, addedCategory });
                console.log(response);
                fetchAllCategories();
                setAddedCategory('');

            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    async function fetchAllCategories() {

        try {
            const response = await axios.post(`${API_URL}/get_all_categories`, { token });
            console.log(response.data.data)
            if (response.data.success) {
                setCategoryDetails(response.data.data)
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchAllCategories();
    }, [])

    return (
        <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'column',

        }}>
            {/* Tabs for view, edit, add */}
            <Tabs value={activeTab}
                onChange={handleTabChange} aria-label="tabs"
                sx={{
                    mt: '8px', marginLeft: '34px', mr: '34px',
                    '& .MuiTabs-root': {
                        borderBottom: '1px solid #E6E6E6',
                        borderColor: 'divider',
                        opacity: 0,
                        marginBottom: '10px',
                       
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .Mui-selected': {
                        color: '#5b5555 !important', // Change text color of selected tab
                      },
                }}>
                <Tab label="View" value="view" sx={{ fontSize: '20px',textTransform:'capitalize' }}/>
                <Tab label="Edit" value="edit" sx={{ fontSize: '20px',textTransform:'capitalize' }}/>
                <Tab label="Add" value="add" sx={{ fontSize: '20px',textTransform:'capitalize' }}/>
            </Tabs>
            {/* Content based on active tab */}
            <Box sx={{ mt: '50px', marginLeft: '34px', mr: '34px', }}>
                {activeTab === 'view' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', mb: '34px' }}>
                        {categoryDetails.map(category => (
                            <TypographyComponent key={category.service_category_id} 
                            sx={{fontSize:'20px',color:Colors.black_70}}>
                                {category.service_category}
                            </TypographyComponent>
                        ))}
                    </Box>
                )}
                {activeTab === 'edit' && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', width: '75%', gap: '8px', flexDirection: 'row' }}>
                            <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                <TypographyComponent>
                                    Select category
                                </TypographyComponent>
                                <SelectComponent
                                    value={selectedOption}
                                    onChange={handleCategoryListChange}
                                    // onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                    options={categoryDetails.map(result => ({
                                        value: result.service_category_id,
                                        label: result.service_category
                                    }))}
                                // error={!!serviceError} 
                                >
                                </SelectComponent>
                            </Box>
                            {/* Text Field */}
                            <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                <TypographyComponent>
                                    Update category
                                </TypographyComponent>
                                <TextFieldComponent
                                    placeholder='update category'
                                    value={textFieldValue}
                                    onChange={handleTextFieldChange}
                                    sx={{ marginBottom: '10px', width: '420px' }}>
                                </TextFieldComponent>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                            {/* <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                           
                            >
                                Cancel
                            </ButtonComponentWhite> */}
                            <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                onClick={handleUpdateCategory}>
                                Save
                            </ButtonComponent>
                        </Box>
                    </Box>
                )}
                {activeTab === 'add' && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', width: '75%', gap: '8px', flexDirection: 'row' }}>

                            <Box sx={{ display: 'flex', width: '420px', flexDirection: 'column' }}>
                                <TypographyComponent>
                                    Add category
                                </TypographyComponent>
                                <TextFieldComponent
                                    placeholder='Add category'
                                    value={addedCategory}
                                    onChange={handleTextFieldChangeAddCategory}
                                    error={error}
                                    helperText={errorMessage}
                                    sx={{ marginBottom: '10px', width: '420px' }}>
                                </TextFieldComponent>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-end' }}>
                            <ButtonComponentWhite sx={{ width: '96px', height: '32px' }}
                            onClick={handleCancel}
                            >
                                Cancel
                            </ButtonComponentWhite>
                            <ButtonComponent sx={{ width: '96px', height: '32px' }}
                                onClick={handleAddCategory}>
                                Save
                            </ButtonComponent>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default AdminCategory