import React from 'react';
import { Select, MenuItem } from '@mui/material';
import Colors from './Colors';

const SelectComponent = ({ value, disabled,  error, options, onChange, multiple, ...rest }) => {
  return (
    <Select
      value={value}
      error={error}
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      sx={{
        color: disabled ? Colors.black_70 : Colors.black_70,
        width: "100%",
        height: "48px",
        borderRadius: "28px",
        fontFamily: "Roboto",
        fontSize: { md:'14px', sm: '12px', xs: '12px' },
        fontWeight: "400",
        borderColor:error ? '#FF4105 !important' : '#CFC9C9',
        ...rest, // Allow customization through props
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor:error ? '#FF4105 !important' : "#313131",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor:error ? '#FF4105 !important' : "#313131 ",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor:error ? '#FF4105 !important' : '#CFC9C9',
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor:error ? '#FF4105 !important' : '#CFC9C9',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? '#FF4105 !important' :'#313131',
        },
       
       '& .MuiSvgIcon-root': {
          marginRight: '16px', // Adjust the margin to move the dropdown icon right
           },
           '&::-webkit-scrollbar': {
            width: '11px',
            height: '8px', // Added height for horizontal scrollbars
            borderRadius: '16px',
          
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: Colors.grey_60,
            borderRadius: '16px',
            border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '16px',
            backgroundColor: Colors.white,
              marginTop:'10px',
            marginBottom:'10px'
          },
        
      }}
      MenuProps={{
        sx: {
          color: Colors.black_70 ,
          marginTop: "20px",
          maxHeight: "244px",
          borderRadius: "12px",
        },
      }}
      renderValue={(selected) => {
        const selectedItem = options.find((option) => option.value === selected);
        return selectedItem ? (
          <span style={{ padding: { md:'2px 2px', sm: '2px 2px', xs: '1px' }, borderRadius: '4px', }}>
            {selectedItem.label}
          </span>
        ) : null;
      }}
    >
      {options.map((option) => (
        <MenuItem 
          key={option.value} 
          value={option.value} 
          sx={{
            fontFamily: "Roboto", 
            fontSize: { md:'14px', sm: '14px', xs: '14px' },
            fontWeight: "400",
            margin:{ md:'8px', sm: '8px', xs: '0px' },          
            color: Colors.black_70,
            "&:hover": {
              backgroundColor: "#ECF4FB",
            },
            "&.Mui-selected": {
              backgroundColor: "#415FE2",
              color: "#ffffff",
            },            
            "&.Mui-selected:hover": {
              backgroundColor: "#415FE2",
              color: "#ffffff",
            },
            
            "&.MuiSelect-root": {
              backgroundColor: "#ECF4FB",
              color: "#ffffff",
            }, 
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectComponent;