import React from 'react';
import { Typography, List, ListItem, ListItemText, Box, Grid } from '@mui/material';
import Colors from '../Components/Colors';
import UserFooter from '../Components/UserFooter';
import UserHeader from '../Components/UserHeader';

function SpTermsAndCondition() {
    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
            <UserHeader ></UserHeader>
        </Box>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
         alignItems: 'center',paddingTop: '2.5%', paddingBottom: '2.5%', }}>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', background: Colors.grey_20
            }}>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                    maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', gap: '24px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '26px', xs: '22px' }, lineHeight: '24px', fontWeight: 700,
                        color: Colors.black_80,
                    }}>
                        Terms And Conditions
                    </Typography>

                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '24px', xs: '20px' }, lineHeight: '24px',
                        fontWeight: 700, color: Colors.black_80,
                    }}>
                        Last Updated: May 15, 2024
                    </Typography>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                        gap: '16px', justifyContent: 'center'
                    }}>
                       
                       
                       

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                          User types
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                            <strong>Public users:</strong> Individuals who use BroHandy to search for and contact service providers. These users can browse listings without creating an account, ensuring ease of access and privacy.<br />                            
                            <strong>Service provider:</strong> Businesses or individual entrepreneurs who register on BroHandy to list their services. They provide business contact details and information about their offerings. Service Providers are responsible for the accuracy and legality of the information they post.
                        </Typography>
                       
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Disclaimer
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                            BroHandy operates on a best-effort basis to ensure the availability and reliability of the data on our platform. However, BroHandy is not responsible for any losses, deceit, or misrepresentation that may arise from the information provided by service providers or the actions of public users. Users should exercise due diligence before engaging in any transactions.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Terms and conditions for service providers
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             <strong>Account responsibility: </strong> Service Providers must ensure the security of their account details and notify BroHandy immediately if they suspect their account has been compromised. If a Service Provider wishes to delete their profile, they may do so, but they remain responsible for any legal issues arising from their profile while it was active.<br />
                             <strong>Content and conduct: </strong> Service Providers must ensure that the data they provide does not include offensive, illegal content, and does not pertain to adult, sexual, or narcotic services. All Service Providers must be over the age of 18.<br />
                             <strong>Privacy and data usage: </strong> Service Providers agree that by entering their business contact details on BroHandy, they relinquish any privacy claims over this information. It is intended solely for business use and to be visible to Public Users of BroHandy.<br />
                             <strong>Legal liability: </strong> Service Providers are solely responsible for any legal issues that arise from their services or the use of BroHandy. This includes ensuring compliance with local laws and regulations relevant to their service offering.<br />
                             <strong>Modifications and continuity: </strong> BroHandy reserves the right to modify these terms and conditions at any time. Continued use of the platform after such changes constitutes acceptance of the new terms.
                            
                        </Typography>

                       
                    </Box>
                </Box>
            </Box>
        </Box>
        <UserFooter></UserFooter>
        </Grid>
    );
}

export default SpTermsAndCondition;
