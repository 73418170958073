import { AppBar, Toolbar, Typography, styled } from '@mui/material'
import React from 'react'
import TypographyComponent from './TypographyComponent'
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { Box } from '@mui/material';
const StyledToolbar =styled(Toolbar)(
    {
        display:'flex',
        justifyContent:'space-between'
    }
)

const StyledMenuBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px"
  }));
  

export const Navbar = () => {
  return (
   <AppBar position='sticky'>
    <StyledToolbar>

        <TypographyComponent sx={{display:{xs:'none',sm:'block',md:'block'}}}>
            LEBOCO
        </TypographyComponent>
        <GroupWorkIcon sx={{display:{xs:'block',sm:'none',md:'none'}}}/>
       
          <Typography variant="h6" component="div">
            Home
          </Typography>
          <Typography variant="h6" component="div">
            About Us
          </Typography>
          <Typography variant="h6" component="div">
            Contact Us
          </Typography>
          <StyledMenuBox>
            test
        </StyledMenuBox>
    </StyledToolbar>
   
    
   </AppBar>
  )
}
