import { Box, Stepper, Step, StepLabel, styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypographyComponent from './TypographyComponent';
import Colors from './Colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { API_URL } from './Constants';

const CustomIcon = styled(DoneIcon)({
    color: Colors.primary,
    borderRadius: '50px',
    background: Colors.quinary
});

const SideBarBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px'
}));

const ImageBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '136px',
    width: '136px'
}));

const ProgressBarBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
}));

const CompletedIcon = styled('div')({
    '& svg': {
        fill: 'green'
    }
});

const SideBar = ({ currentStage}) => {

    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const avatarSize = isSmScreen ? '48px' : '136px';

    const orientation = isSmScreen ? 'horizontal' : 'vertical';
    const width = isSmScreen ? '100%' : '400px';

    const stages = [
        { number: 1, name: "Profile" },
        { number: 2, name: "Add location" },
        { number: 3, name: "Add/Edit skills" }
    ];

    const [newProfilePic, setNewProfilePic] = useState(null);
    const [isProfileEdit, setIsProfileEdit] = useState(false);
    const token = localStorage.getItem('token');
    let profileIndex;
    const colors = [ Colors.card_color_1,
        Colors.card_color_2,
        Colors.card_color_3,
        Colors.card_color_4,
        Colors.card_color_5];
    const [serviceProviderDtls, setServiceProviderDtls] = useState(null);
    const [crdcolor, setCrdcolor] = useState(null);
     async function fetchServiceProviderDetails() {
       
        try {
            const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            console.log("service-provider-details", response.data)
            if (response.data.success) {
              console.log(response.data.data.serviceProvider.card_color)
              setCrdcolor(response.data.data.serviceProvider.card_color)
            
              console.log("qr", response.data.data.serviceProvider)
                setServiceProviderDtls(response.data.data.serviceProvider)
                // setMessageDetails(response.data.data.serviceProvider.message_details)
                // const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                //     if (message.read === 'Unread') {
                //         return acc + 1;
                //     }
                //     return acc;
                // }, 0);
                // setUnreadMessageCount(unreadCount);
                              
                    
            } else {
              
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }
    
    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);
      console.log(serviceProviderDtls)
     
       
              const cardColor = crdcolor ? crdcolor.replace('b', '') : '1';
              profileIndex = parseInt(cardColor-1);

    useEffect(() => {        
        if (serviceProviderDtls && serviceProviderDtls.profile_image) {
            if (serviceProviderDtls.service_details.length > 0) {
                setIsProfileEdit(true);
            }
            const imgurl = `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${serviceProviderDtls.profile_image}`;
            setNewProfilePic(imgurl);
        }
    }, [serviceProviderDtls]);


    

     


      

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: { md: 'column', sm: 'column', xs: 'column' }, 
            alignItems: 'left', height: '30%',width:'100%'  }}>
                <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px', paddingBottom: '10px', display: { md: 'none', sm: 'none', xs: 'flex' } }}>
                    Complete your profile
                </TypographyComponent>
                <Box sx={{ display: 'flex', flexDirection: { md: 'column', sm: 'column', xs: 'row' }, alignItems: 'left', height: '30%', width: '100%', gap: { md: '24px', sm: '24px', xs: '16px' } }}>
                    <Box sx={{ width: { md: '136px', sm: '100px', xs: '80px' } }}>
                        {!newProfilePic && (
                            // <AccountCircleIcon sx={{ color: Colors.grey_60, 
                            //     width: { md: '136px', sm: '100px', xs: '80px' }, 
                            //     height: { md: '136px', sm: '100px', xs: '80px' } }} />
                            <Box
                            sx={{
                                width: avatarSize,
                                height: avatarSize,
                                borderRadius: '50%',
                                backgroundColor:  (profileIndex !== null || profileIndex !== undefined ) ? colors[parseInt(profileIndex) % colors.length] : colors[0],
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: isSmScreen ? '24px' : '48px',
                                fontWeight: 'bold',
                                color: Colors.white,
                               // border: '2px solid #ffffff',
                               // boxShadow: '0px 4px 4px 0px #00000040',
                            }}
                        >
                           {serviceProviderDtls?.business_name?.[0]?.toUpperCase()}
                        </Box>
                        )}
                        {newProfilePic && (
                            <Box sx={{ width: { md: '136px', sm: '100px', xs: '60px' }, height: { md: '136px', sm: '100px', xs: '60px' }, objectFit: 'cover', borderRadius: '50%' }}>
                                <img src={newProfilePic} alt="" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                            </Box>
                        )}
                    </Box>

                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px', display: { md: 'flex', sm: 'flex', xs: 'none' } }}>
                        Complete your profile
                    </TypographyComponent>
                    <Box sx={{ width: { xs: '100%' },display:'flex',  }}>
                        <Box sx={{ display: 'flex',width: { xs: '100%' }, flexDirection: isSmScreen ? 'row' : 'column', alignItems: 'left' }}>
                            {stages.map((stage, index) => (
                                <React.Fragment key={stage.name}>
                                    <Box sx={{ display: 'flex', flexDirection: isSmScreen ? 'column' : 'row', alignItems: 'center' }}>
                                        {isProfileEdit ? (
                                            <Box
                                                sx={{
                                                    width: '31px',
                                                    height: '31px',
                                                    borderRadius: '50%',
                                                    backgroundColor: Colors.quinary,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: index === currentStage - 1 ? '1px solid ' : 'none',
                                                    borderColor: Colors.secondary,
                                                    color: Colors.primary,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                            </Box>
                                        ) : index < currentStage - 1 ? (
                                            <Box
                                                sx={{
                                                    width: '32px',
                                                    height: '32px',
                                                    borderRadius: '50%',
                                                    backgroundColor: Colors.quinary,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: Colors.primary,
                                                    fontWeight: 400,
                                                }}
                                            >
                                                <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                            </Box>
                                        ) : index === currentStage - 1 || index === currentStage || index === currentStage + 1 ? (
                                            <Box
                                                sx={{
                                                    width: '31px',
                                                    height: '31px',
                                                    borderRadius: '50%',
                                                    backgroundColor: Colors.quinary,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: index !== currentStage ? '1px solid ' : 'none',
                                                    borderColor: Colors.secondary,
                                                    color: Colors.primary,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {stage.number}
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: '32px',
                                                    height: '32px',
                                                    borderRadius: '50%',
                                                    backgroundColor: Colors.quinary,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: Colors.primary,
                                                    fontWeight: 400,
                                                }}
                                            >
                                                <DoneIcon style={{ fontSize: '16px', fontWeight: 'bold' }} />
                                            </Box>
                                        )}
                                        <Box sx={{ paddingLeft:{ md:'10px', sm: '10px', xs: '0' }, fontSize:{ md:'16px', sm: '16px', xs: '10px' } ,  }}>
                                            {stage.name}
                                        </Box>
                                    </Box>
                                    {index < stages.length - 1 && (
                                        <Box
                                            sx={{
                                               justifyContent:'center',alignItems:'center',
                                                marginTop:isSmScreen ? '5%' : '0',
                                                marginLeft: isSmScreen ? '0' : '15px',
                                                width: isSmScreen ? '100%' : '2px',
                                                height: isSmScreen ? '2px' : '100px',
                                                backgroundColor: Colors.quinary,
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default SideBar;
