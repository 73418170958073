import { Box, Container, Grid, IconButton, InputAdornment } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import { useState } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from "axios";
import { API_URL } from './Constants';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Colors from '../Components/Colors';
import mainImage from '../Images/mainImage.png'
import mainBg from '../Images/mainBg.png'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [backendError, setBackendError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);

  const token = localStorage.getItem('token');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (oldPassword.trim() === "" || newPassword.trim() === "" || confirmPassword.trim() === "") {
      if (oldPassword.trim() === "") {
        setOldPasswordError("Please enter old password");       
      }
      else {
        setOldPasswordError("");
      }
      if (newPassword.trim() === "") {
        setNewPasswordError("Please enter new password");
        
      }
      else {
        setNewPasswordError("");
      }
      if (confirmPassword.trim() === "") {
        setConfirmPasswordError("Please enter confirm password");
        
      }
      else {
        setConfirmPasswordError("");
      }
      return;
    }
    
      if (newPassword !== confirmPassword) {
        setBackendError('');
        setPasswordError("New password and confirm password do not match.");
        return;
      }
      try {
        const response = await axios.post(`${API_URL}/change-password`, { token, oldPassword, newPassword });
       // console.log("response", response)
        if (response.data.success===true) {
          setChangePasswordPopUp(true)
          setPasswordError("");
          setNewPassword('');
          setOldPassword('');
          setConfirmPassword("");
        }
        if (response.data.success===false) {
         // console.log("response message",response.data.message)
         setPasswordError("");    
         if(response.data.message==="The entered old password is incorrect.")  
          {
            setOldPasswordError('The entered old password is incorrect.')
          } else{
            setBackendError(response.data.message)
          } 
         
        }
        // if (response.status !== 200) {
        //   const { error } = response.data;
        //   setPasswordError(error);
        // } else {
        //   setChangePasswordPopUp(true)
        //   setPasswordError(null);
        //   setNewPassword('');
        //   setOldPassword('');
        // }

      }
      catch (error) {
        console.log('Error updating password. Please try again later.');
      }
   
  };

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setOldPasswordError("");
    setPasswordError("");
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setPasswordError("");
    setNewPasswordError("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordError("");
    setConfirmPasswordError("");
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlechangePasswordPopUpClose = () => {
    window.location.href = `/profile-form/true`;
  }

  return (
    <Grid sx={{ width: '100%', height: '100vh', margin: 0, padding: 0, display: 'flex', flexDirection: {xs:"column",sm:"row",md:"row"} }}>
      <Box sx={{
        width:{ md: '45%', sm:  '45%' , xs: '90%'  }, height: '100%', backgroundImage: `url(${mainBg})`, backgroundSize: 'cover',
        backgroundPosition: 'center', padding: { md:'5% 10%', sm: '5% 10%' , xs: '5%'  }, display: 'flex', flexDirection: 'column',
        gap: '24px'
      }}>
        <Box sx={{
          width: '100%', height: '30%', display: 'flex', flexDirection: 'column',
          gap: '16px'
        }}>
          <TypographyComponent sx={{ fontSize: '60px', color: Colors.white, fontWeight: 800 }}>Welcome</TypographyComponent>
          <TypographyComponent sx={{ fontSize: '14px', color: Colors.white, fontWeight: 600 }}>
          Find the help you need, right in your neighborhood.
          </TypographyComponent>
        </Box>
        <Box sx={{
          width: { md: 'calc(100% + 38%)', sm: 'calc(100% + 38%)' , xs: '100%'  }, height: 'calc(70%)', display: 'flex', flexDirection: 'row',
          paddingLeft:  { md:'38%', sm: '38%' , xs: '0%'  },paddingTop: { md:'0%', sm: '0%' , xs: '5%'  }
        }}>
          <img src={mainImage} alt="" style={{ width: '100%', height: '100%', }} />
        </Box>
      </Box>
      <Box sx={{
        width:{ md: '55%', sm:  '55%' , xs: '90%'  } , height: '100%', padding: { md:'5% 10%', sm: '5% 10%' , xs: '5%'  }, 
        display: 'flex', flexDirection: 'column', gap: '48px',
        justifyContent: 'center'
      }}>
        <Box sx={{ gap: '8px' }}>
          <TypographyComponent sx={{ fontSize: '32px', color: '#5B5555', }}>Change password</TypographyComponent>
          <TypographyComponent sx={{ fontSize: '16px', color: '#454545', }}>Create strong and secure password that protect your account.</TypographyComponent>
        </Box>
        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
          <Box sx={{ gap: '8px' }}>
            <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Old Password*</TypographyComponent>
            <TextFieldComponent placeholder="Enter your password"
              onChange={handleOldPasswordChange}
              value={oldPassword}
              //   error={!!passwordError}
              id='old_pass'
              error={oldPasswordError}
              type={showOldPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleOldPasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                      color="primary">
                      {showOldPassword ? (
                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}></TextFieldComponent>
            {oldPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>
              {oldPasswordError}</TypographyComponent>}
          </Box>
          <Box sx={{ gap: '8px' }}>
            <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>New Password*</TypographyComponent>
            <TextFieldComponent placeholder="Enter your password"
              onChange={handleNewPasswordChange}
              error={passwordError || newPasswordError}
              value={newPassword}
              id='new_pass'
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleNewPasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                      color="primary">
                      {showNewPassword ? (
                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}></TextFieldComponent>
            {newPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{newPasswordError}</TypographyComponent>}
          </Box>
          <Box sx={{ gap: '8px' }}>
            <TypographyComponent sx={{ fontSize: '14px', color: '#5B5555', }}>Confirm Password*</TypographyComponent>
            <TextFieldComponent placeholder="Enter your password"
              onChange={handleConfirmPasswordChange}
              error={passwordError || confirmPasswordError}
              value={confirmPassword}
              id='confirm_pass'
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                      color="primary">
                      {showConfirmPassword ? (
                        <VisibilityOutlinedIcon sx={{ color: '#C5C5C5' }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ color: '#C5C5C5' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}></TextFieldComponent>
            {confirmPasswordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{confirmPasswordError}</TypographyComponent>}
           
          </Box>
<Box sx={{display:'flex',alignItems:'center',width:'100%',justifyContent:'center'}}>
        {backendError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039', }}>{backendError}</TypographyComponent>}
        {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039', }}>{passwordError}</TypographyComponent>}
        </Box>
        </Box>
       
        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', }}>
          
          <ButtonComponent onClick={handleSubmit}>Save password</ButtonComponent>
        </Box>
      </Box>
      {/* {changePasswordPopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: {
                md: '424px'
                // , sm: '400px', xs: '300px'
              },
              height: ' 32%',
              backgroundColor: '#fff',
              border: '1px solid #E6E6E6',
              borderRadius: '28px ',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '12px' // Set the width of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#7f7f7f', // Set the color of the thumb
                borderRadius: '28px', // Set the border radius of the thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#e0e0e0', // Set the color of the track
                borderRadius: '28px', // Set the border radius of the track
              },
            }}
          >
            <IconButton onClick={handlechangePasswordPopUpClose} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: '61.7%', top: '28%'
            }} >
              <CancelIcon sx={{
                width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                  color: Colors.close,
                },
              }} ></CancelIcon>
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '25px' }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>
                <CheckCircleRoundedIcon sx={{ color: '#0BEA14', width: '45px', height: '45px' }} />
                
              </Box>

              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 700, color: '#313131' }}>
                  Password changed!
                </TypographyComponent>
                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 400, color: '#1a1a1a' }}>
                  Your password  has been changed successfully!
                </TypographyComponent>

              </Box>


             


            </Box>
          </Box>
        </Box>} */}

        {/* {changePasswordPopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { md: '424px', sm: '400px', xs: '300px' },
              height:'width-content' ,
              backgroundColor: '#fff',
              border: '1px solid #E6E6E6',
              borderRadius: '28px ',
              boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '12px' // Set the width of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#7f7f7f', // Set the color of the thumb
                borderRadius: '28px', // Set the border radius of the thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#e0e0e0', // Set the color of the track
                borderRadius: '28px', // Set the border radius of the track
              },
            }}
          >
            <IconButton onClick={handlechangePasswordPopUpClose} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: { md:'62.5%', sm:'72%', xs: '80%' }, top:{ md:'25%', sm:'32%', xs:'31%' } 
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color:Colors.quinary, '&:hover': {
                      color: Colors.close,
                    }, }} ></CancelIcon>
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '25px' }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>
                <div style={{ borderRadius: '50%', background: '#0BEA14', width: '45px', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DoneOutlinedIcon style={{ width: '36px', height: '36px', color: 'white' }} />
                </div>
              </Box>

              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%'
              }}>

<TypographyComponent sx={{ fontSize: '16px', fontWeight: 700, color: '#313131' }}>
                  Password changed!
                </TypographyComponent>
                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 400, color: '#1a1a1a' }}>
                  Your password  has been changed successfully!
                </TypographyComponent>
              </Box>
             


            </Box>
          </Box>
        </Box>} */}

        {changePasswordPopUp && (
               <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',

            }}
            >
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '424px', sm: '400px', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handlechangePasswordPopUpClose} sx={{
                            position: 'fixed', width: '36px', height: '36px',

                        }} >
                            <CancelIcon sx={{
                                width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                                    color: Colors.close,
                                },
                            }} ></CancelIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{

                            width: { md: '424px', sm: '400px', xs: '85%' },
                            height: { md: '200px', sm: '400px', xs: 'fit-content' },
                            backgroundColor: Colors.white,
                            border: '1px solid',
                            borderColor: Colors.white,
                            borderRadius: '16px ',
                            boxShadow: 'none',
                            boxSizing: 'border-box',
                            zIndex: '3',
                            padding: { md: '32px', sm: '32px', xs: '24px' },
                            alignItems: 'center',gap:'24px'

                        }}
                    >
                               
                       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                      alignContent: 'center', width: '100%'}}>
                      <div style={{ borderRadius: '50%', background: '#0BEA14', width: '45px', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <DoneOutlinedIcon style={{ width: '36px', height: '36px', color: 'white' }} />
                      </div>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'center', gap: '8px',
                                        alignItems: 'center', width: '100%', paddingTop:'16px'}}>
                                     <TypographyComponent sx={{ fontSize: '16px', fontWeight: 700, color: '#313131',  }}>
                  Password changed!
                </TypographyComponent>
                <TypographyComponent sx={{ fontSize: '16px', fontWeight: 400, color: '#1a1a1a', textAlign:'center' }}>
                  Your password  has been changed successfully!
                </TypographyComponent>  

                                    </Box>

                               
                              
                           
                        </Box>
                    </Box>
                </Box>
            )}

    </Grid>
  )
}
export default ChangePassword;