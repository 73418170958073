import { Avatar, AvatarGroup, Box, Button, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import ShareIcon from '@mui/icons-material/Share';


function CommunityData() {
  return (
    <Box mb={4}>
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Avatar alt="Kannur friends" src="path_to_avatar" />
          <Box ml={2}>
            <Typography variant="h5">Kannur friends</Typography>
            <Typography variant="body2" color="textSecondary">
              Thaliparamba, Kannur
            </Typography>
            <AvatarGroup max={4}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
        <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
      </AvatarGroup>
            <Typography variant="body2">
              +105 Members
            </Typography>
          </Box>
          <Button variant="contained" color="primary" startIcon={<ShareIcon />} sx={{ marginLeft: 'auto' }}>
            Share
          </Button>
        </Box>
        <Typography variant="body2" mt={2}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </Typography>
      </CardContent>
    </Card>
  </Box>
  )
}

export default CommunityData