import { Box, Grid} from "@mui/material"
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from './Constants';
import SearchComponent from "../Components/SearchComponent";
import UserHeader from "../Components/UserHeader";
import ProfileComponent from "../Components/ProfileComponent";
import ButtonComponent from "../Components/ButtonComponent";
import TypographyComponent from "../Components/TypographyComponent";
import UserHeaderMobile from "../Components/UserHeaderMobile";
import UserFooter from "../Components/UserFooter";
import { useParams } from 'react-router-dom';


function ProfileData() {
  const { spCode } = useParams();
//console.log(spCode)
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDtls, setServiceDtls] = useState([]);
  
    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [serviceProviderUrl, setServiceProviderUrl] = useState(null);

    async function fetchServiceProviderDetails() {
      const spId = localStorage.getItem('SPId');
    //  console.log(localStorage.getItem('SPId'))
        try {
          const response = await axios.post(`${API_URL}/sp-details`, { spId });
        //  console.log("sp-details", response.data.data.serviceProvider)
          if (response.data.success) {
           // console.log(response.data.data.serviceProvider) 
            setServiceProviderDtls(response.data.data.serviceProvider)
            setServiceDtls(response.data.data.serviceProvider.service_details[0])
          //  console.log("service-details", response.data.data.serviceProvider.service_details[0])
            setServiceProviderQrCode(response.data.data.qrCode)
          //  console.log(response.data.data.serviceProviderURL)
            setServiceProviderUrl(response.data.data.serviceProviderURL)
          } else {
            console.error('Error:', response.data.error);
          }
        } catch (error) {
          console.error('Error:', error.message);
        }
      }
      useEffect(() => {
        fetchServiceProviderDetails();
      }, []);
  


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
          
        <Box sx={{  width: '100%', height: '100%',display: 'flex' }}>
        <UserHeader></UserHeader>
        </Box> 
            <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', gap: '16px'}}>
                <Box sx={{width:{ md:'45%', sm: '45%' , xs: '100%'  }, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center', gap: '16px'}}>
             <ProfileComponent serviceProviderDtls= {serviceProviderDtls} isContactEnable = {true} 
             serviceDtls= {serviceDtls} serviceProviderQrCode= {serviceProviderQrCode} serviceProviderUrl= {serviceProviderUrl}></ProfileComponent>
              </Box> 
            </Box>
            <Box sx={{  width: '100%', height: '100%',display: 'flex' }}>
        <UserFooter></UserFooter>
        </Box>
        </Grid>
    )
} export default ProfileData