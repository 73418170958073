import React from "react"
import Typography from '@mui/material/Typography';
import Colors from "./Colors";


 function TypographyHeading({ onClick, ...props }){
    
    return(
        <Typography  
            sx={{fontFamily:'Roboto !important',  fontWeight:500, fontSize:'18px', color: Colors.black_80 }}
                 {...props} onClick={onClick}>            
         </Typography>
    )
}

export default TypographyHeading;

