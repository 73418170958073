import { Box, Button, Grid } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useEffect, useState } from "react";
import Colors from "../Components/Colors";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import axios from "axios";
import { API_URL } from './Constants';
import SpHeader from "../Components/SpHeader";
import SpFooter from "../Components/SpFooter";

function SpMessageMobile(){ 
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
  const [isMsg, setIsMsg] = useState(false); 
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);  
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const token = localStorage.getItem('token');
  const [messageDetails, setMessageDetails] = useState([]);
  async function fetchServiceProviderDetails() {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
       // console.log("service-provider-details", response.data)
      //  console.log("card_no", response.data.data.serviceProvider.card_number)
        if (response.data.success) {
          //  console.log("service-provider-details from landing", response.data.data.serviceProvider)
          //  console.log("service-details", response.data.data.serviceProvider.service_details)
          //  console.log("qr", response.data.data.qrCode)
         //   console.log("card_no", response.data.data.serviceProvider.card_number)
            const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
            setServiceProviderDtls(response.data.data.serviceProvider)
            const sortedMessages = response.data.data.serviceProvider.message_details.sort((a, b) => a.message_id - b.message_id);
      
            setMessageDetails(sortedMessages);
            console.log(sortedMessages)
            console.log(response.data.data.serviceProvider)

            const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
              if (message.read === 'Unread') {
                  return acc + 1;
              }
              return acc;
          }, 0);
          setUnreadMessageCount(unreadCount);
        }
    } catch (error) {
        console.error('Error:', error.message);
    }
}
useEffect(() => {
    fetchServiceProviderDetails();
    const intervalId = setInterval(fetchServiceProviderDetails, 1000);
    return () => clearInterval(intervalId); 
    },[messageDetails])

  useEffect(() => {
   // console.log(messageDetails)
    if(Array.isArray(messageDetails) && messageDetails.length > 0) {
      setIsMsg(true)   
    }
    else{
      setIsMsg(false)  
    }
     
   }, [messageDetails]);

   const [isReadDelete, setIsReadDelete] = useState(false); 

   const handleIconClick = (index, id) => {
   // console.log(id)
    setIsReadDelete(!isReadDelete)  
    setSelectedMessageIndex(index)
    setSelectedMessageId(id)
   };

   const [isDeletePopUp, setIsDeletePopUp] = useState(false); 

   const handleMenuItemClick = async(value) => {  

    if (value === "read") {
      try {      
        const response = await axios.post(`${API_URL}/update-read-status`, {           
           token: token,
           msgId: selectedMessageId, 
             });
         //   console.log ("response",response); 
            if(response.data.success === true)  {
              const updatedMessageDetails = messageDetails.map((message, index) => {
                if (index === selectedMessageIndex) {
                  return { ...message, read: 'read' };
                }
                return message;
              });
              // Update messageDetails state
              setMessageDetails(updatedMessageDetails);
            }
            } catch (error) {
            console.error('Error:', error.message);
            }       
        
     
    }
    if (value === "delete") {
      setIsDeletePopUp(true)
    }
    setIsReadDelete(false);
  }

  
  const handleDeletePopUpCancelButtonClick = () => {
    setIsDeletePopUp(false)
   };

   const handleDeletePopUpOkButtonClick = async() => {
    try {      
      const response = await axios.post(`${API_URL}/delete-message`, {           
         token: token,
         msgId: selectedMessageId, 
           });
         // console.log ("response",response); 
          if(response.data.success === true)  {
            console.log('Message deleted successfully');       
            const updatedMessageDetails = messageDetails.filter((message, index) => index !== selectedMessageIndex);     
            setMessageDetails(updatedMessageDetails);
            setIsDeletePopUp(false)
          }
          } catch (error) {
          console.error('Error:', error.message);
          }       
   };
   
   const handleAllRead = async() => {
    try {      
      const response = await axios.post(`${API_URL}/mark-all-as-read`, {           
         token: token,        
           });
        //  console.log ("response",response); 
          if(response.data.success === true)  {
            const updatedMessageDetails = messageDetails.map((message, index) => {
              if (index === selectedMessageIndex) {
                return { ...message, read: 'read' };
              }
              return message;
            });
            // Update messageDetails state
            setMessageDetails(updatedMessageDetails);
          }
          } catch (error) {
          console.error('Error:', error.message);
          }     
   };

   const [isCompleteProfile, setIsCompleteProfile] = useState(true);

  //  const formatDateTime = (dateTimeString) => {
  //   const [datePart, timePart] = dateTimeString.split(' ');
  //   const [year, month, day] = datePart.split('-');
  //   let [hour, minute, second] = timePart.split(':');
  
  //   // Convert to 12-hour format
  //   const ampm = hour >= 12 ? 'PM' : 'AM';
  //   hour = hour % 12;
  //   hour = hour ? hour : 12; // The hour '0' should be '12'
  
  //   return `${day}-${month}-${year} ${hour}:${minute} ${ampm}`;
  // };

  const formatDateTime = (dateTimeString) => {
    // Split the input string into date and time parts
    const [datePart, timePart] = dateTimeString.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hour, minute, second] = timePart.split(':');
  
    // Create a new Date object in UTC
    const dateUTC = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  
    // Define options for formatting the time in Canada time zone
    const timeOptions = {
      timeZone: 'America/Toronto',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour format with AM/PM
    };
  
    // Create a formatter for the time
    const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
  
    // Format the time
    const formattedTime = timeFormatter.format(dateUTC);
  
    // Get the components of the date in the desired time zone
    const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
    const dayInToronto = String(dateInToronto.getDate()).padStart(2, '0');
    const monthInToronto = String(dateInToronto.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const yearInToronto = dateInToronto.getFullYear();
  
    // Combine date and time with the desired format (dd-mm-yyyy)
    return `${dayInToronto}-${monthInToronto}-${yearInToronto} ${formattedTime}`;
  };
  // Example usage:
  // const dateTimeString = '2024-07-20 18:45:30';
  // const formattedDateTime = formatDateTime(dateTimeString);
  //console.log(formattedDateTime); // Output: "20-07-2024 06:45:30 PM"
  

    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px',   }}> 
          <Box sx={{  width: '100%', height: '100%',display: 'flex' }}>
          <SpHeader isCompleteProfile={isCompleteProfile} serviceProviderDtls={serviceProviderDtls} 
        messageDetails={messageDetails} setMessageDetails={setMessageDetails} unreadMessageCount={unreadMessageCount}></SpHeader>
       
        </Box> 
    <Box sx={{width:'calc(100%-56px)', height: messageDetails.length > 2 ? 'fit-content' : '70vh',display:'flex', flexDirection:'column', borderRadius:'16px',
      background:Colors.grey_20,gap:'27px',position:'relative', padding:'28px 16px', overflow:'auto', 
      '&::-webkit-scrollbar': {
        width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7f7f7f',
        borderRadius: '28px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#e0e0e0',
        borderRadius: '28px',
    },}}>
        <TextFieldComponent placeholder="Search" sx={{ position: 'absolute', top: 20, width:'94%' }}></TextFieldComponent>
       {!isMsg && <Box sx={{width:'100%', height:'100vh', display:'flex', flexDirection:'column', justifyContent:'center',
          alignItems:'center',gap:'8px'}}>
        <TypographyComponent sx={{ color: Colors.grey_60, fontSize: '24px'}}>No messages</TypographyComponent>
        <QuestionAnswerIcon sx={{color:Colors.grey_60,width:'48px', height:'48px'}}></QuestionAnswerIcon>
        </Box>}
        {isMsg && 
         <Box sx={{width:'100%', height:'calc(100%-60px)', display:'flex', flexDirection:'column', justifyContent:'left',
          alignItems:'center',gap:'20px',paddingTop: '60px' }}>
            <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'right',
          alignItems:'center', }}>
             <TypographyComponent onClick={handleAllRead} 
             sx={{ color: Colors.link, fontSize: '16px', fontWeight: 400, cursor:'pointer' }}>Mark all as read
                   </TypographyComponent>
                   </Box>
                   {messageDetails.slice().reverse().map((message, index) => (
                <Box key={index} sx={{width:'calc(100%-24px)', height:'100%', display:'flex', flexDirection:'column',padding:'12px',
                background: message.read === 'read' ? Colors.white : Colors.quinary, borderRadius:'8px', position:'relative'}}>
                   <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                   <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column',}}>
                   <TypographyComponent sx={{ color: Colors.black_80, fontSize: '20px', fontWeight: 700 }}>User {messageDetails.length - index}

                   </TypographyComponent>
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                    Seeking {message.type_of_service} service within the {message.location} area. If you're available, reach out to me at this number {message.user_phone_number} 
                    </TypographyComponent>
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '10px',textAlign:'right', paddingTop:'8px' }}>
                    {formatDateTime(message.send_time)} 
                    </TypographyComponent>
                    </Box>
                    <MoreVertIcon style={{cursor:'pointer'}} onClick={() => handleIconClick(index, message.message_id)}></MoreVertIcon>
                    </Box>
                    {isReadDelete && selectedMessageIndex === index && (

                    <Box className="dropdown__menu" sx={{
                      position: 'absolute', right: '30px', top: '10px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                      lineHeight: '20px', width: '200px', background: Colors.grey_20, borderRadius: '8px', boxShadow: 'none', zIndex: 1,
                      color: 'white', padding: '8px'
                    }}>
                      <style>
                        {`
                    .dropdown__menu .MuiMenuItem-root:hover {
                    background-color: #415FE2;
                    color: #FFFFFF !important;
                    }
                    `}
                      </style>

                <MenuItem onClick={() => handleMenuItemClick("read")} value="option2" style={{
                  fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                  fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                }}>
                  Mark as read
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("delete")} value="option2" style={{
                  fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                  fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                }}>
                  Delete
                </MenuItem>
              </Box>

              )}   
                </Box>                
            ))}  
            
        </Box>}

        {isDeletePopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}>
             <Box sx={{width:'100%', height:'100%',gap:'5px',
                     justifyContent:'center',alignItems:'center', position: 'fixed',
                       display:'flex',flexDirection:'column', }}>
                     <Box sx={{width:{ md: '375px', sm: '380px', xs: '85%' }, height:'36px',
                     justifyContent:'right',alignItems:'right', display:'flex',}}>
                    <IconButton onClick={handleDeletePopUpCancelButtonClick} sx={{
               width: '36px', height: '36px',
              
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close}} ></CancelIcon>
            </IconButton>
            </Box>
          <Box
            sx={{
            
              width: { md: '375px', sm: '380px', xs: '85%' },
              height: 'fit-content',
              backgroundColor: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '28px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',

            }}
          >
           
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
              justifyContent: 'center', alignContent: 'center', height: '100%'
            }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%', gap: '16px'
              }}>
                <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                Are you sure you want to delete this message from user {messageDetails.length - selectedMessageIndex} ?</TypographyComponent>

              </Box>


              <Box sx={{
                display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                alignContent: 'center', alignItems: 'center', width: '100%'
              }}>
                <ButtonComponentWhite onClick={handleDeletePopUpCancelButtonClick}>  Cancel </ButtonComponentWhite>
                <ButtonComponent onClick={handleDeletePopUpOkButtonClick}> Delete </ButtonComponent>
               

              </Box>


            </Box>
          </Box>
          </Box>
        </Box>}


    </Box>
    <Box sx={{  width: '100%', height: '100%',display: 'flex',}}>
          <SpFooter></SpFooter>
       
        </Box>
    </Grid>
    )
}
export default SpMessageMobile;