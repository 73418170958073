import { Grid, Box, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import UserHeader from "../Components/UserHeader";
import UserFooter from "../Components/UserFooter";
import ContactBackground from '../Images/ContactBackground.jpg';
import Contact from '../Images/contactcall.png';
import Colors from "../Components/Colors";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import { API_URL } from './Constants';
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

function ContactUs() {

  const [nickName, setNickName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSuccessMessage, setIsSuccessMessage] = useState(false)
  const [nickNameError, setNickNameError] = useState('');
  const [messageError, setMessageError] = useState('');


  const handleNickNameChange = (event) => {
    setNickName(event.target.value);
    setNickNameError('')
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
    setPhoneError('')
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setMessageError('')
  };

  // const handleFormSubmit = async () => {
  //   if(nickName.trim()==='' && phoneNo.trim()==='' && message .trim()==='' ){
  //     console.log(nickName)
  //     console.log(phoneNo)
  //     console.log(message)
  //   }
  //   else
  //  { const response = await axios.post(`${API_URL}/contact-form`, {
  //     nickName: nickName,     
  //     phoneNo: phoneNo,
  //     message: message,
  //   });
  //   console.log(response)
  //   if (response.status === 200) {
  //     setNickName('')      
  //     setPhoneNo('')
  //     setMessage('')
  //     setIsSuccessMessage(true)
  //   }
  //   else {

  //   }}
  // };
  const [phoneError, setPhoneError] = useState("");
  const handleFormSubmit = async (event) => {
    event.preventDefault();

  // Reset previous errors
  setNickNameError('');
  setPhoneError('');
  setMessageError('');

  // Validate fields
  let isValid = true;
  if (!nickName) {
    setNickNameError('Nick name is required');
    isValid = false;
  }
  const phoneRegex = /^\d{10,12}$/;
  if (!phoneNo) {
    setPhoneError('Phone number is required');
    isValid = false;
  } else if (!phoneRegex.test(phoneNo)) {
    setPhoneError('Phone number must be 10 to 12 digits');
    isValid = false;
  }

  if (!message) {
    setMessageError('Message is required');
    isValid = false;
  }

  // if (!nickName) {
  //   setNickNameError('Nick name is required');
  //   isValid = false;
  // }

    // if (nickName.trim() === '' && phoneNo.trim() === '' && message.trim() === '') {
    //   console.log('All fields are empty.');
    // }
    // else if (phoneNo.trim() !== '' && phoneNo.length !== 10) {
    //   setPhoneError('Phone number must be 10 digits');
    // }
    if (isValid) {
      try {
        const response = await axios.post(`${API_URL}/contact-form`, {
          nickName: nickName,
          phoneNo: phoneNo,
          message: message,
        });

        // console.log(response);

        if (response.status === 200) {
          setNickName('');
          setNickNameError('')
          setPhoneNo('');
          setPhoneError('');
          setMessage('');
          setMessageError('');
          setIsSuccessMessage(true);
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
      }
    }
  };


  useEffect(() => {
    if (isSuccessMessage) {
      const timer = setTimeout(() => {
        setIsSuccessMessage(false);
      }, 8000); // 30 seconds      
      return () => clearTimeout(timer);
    }
  }, [isSuccessMessage]);

  const handleKeyDownPhone = (event) => {
    const key = event.key;
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
    }
  };

  const [isTermPopUp, setIsTermPopUp] = useState(false);

  const handleTermsAndCondtionClick = () => {
    setIsTermPopUp(true)

  };

  const handleCloseTerm = () => {
    setIsTermPopUp(false)

  };

  return (
    <Grid container sx={{ width: '100%', height: '100%' }}>
      <Box sx={{
        backgroundImage: `url(${ContactBackground})`, justifyContent: 'center',
        alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%'
      }}>
        <Box sx={{ width: '100%', display: 'flex' }}>
          <UserHeader currentPage={"Contact us"} />
        </Box>
        <Box sx={{
          width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '5%',
          maxWidth: '90%', margin: '0 auto', gap: '36px', paddingBottom: { md: 0, xs: '5%' }
        }}>
          <Box sx={{
            width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
          }}>
            <Box sx={{
              width: { md: '14%', xs: '25%' }, height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center',
              background: Colors.quinary, borderRadius: '36px', padding: '10px'
            }}>
              <TypographyComponent sx={{
                fontFamily: 'Roboto', fontWeight: 400, fontSize: { md: '28px', xs: '16px' }, color: Colors.black_70
              }}>
                Contact us
              </TypographyComponent>
            </Box>
          </Box>
          <Box sx={{
            width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'left',
            gap: '16px', flexDirection: 'column'
          }}>
            <TypographyComponent sx={{
              fontFamily: 'Roboto', fontWeight: 600, fontSize: { md: '40px', xs: '24px' }, color: Colors.black_70
            }}>
              We're Here for You
            </TypographyComponent>
            <TypographyComponent sx={{
              fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70, lineHeight: { md: '32px', xs: '24px' }
            }}>
              Thank you for your interest in connecting with us! We value your feedback, questions, and any inquiries you may have. Please use the form below to provide your details and message. If you prefer, you can also reach us directly through the contact information provided. We are dedicated to ensuring that your experience with us is positive and fulfilling, and we appreciate your patience as we strive to offer you the best service. Whether you have a question about our products, need support with an order, or simply want to share your thoughts, we are here to help. Thank you for choosing to engage with us – we look forward to hearing from you!

            </TypographyComponent>
            <TypographyComponent sx={{
              fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: Colors.black_70
            }}>


              If you have any questions about this privacy statement or our treatment of the
              information you provide us, please write to us by email at <a href="mailto:contact@brohandy.com">contact@brohandy.com</a>
            </TypographyComponent>
          </Box>
          {!isSuccessMessage &&
            <Box sx={{
              width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
              alignItems: 'center', flexDirection: 'row',
            }}>
              <Box sx={{
                width: { md: '70%', xs: '100%' }, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                gap: '16px', flexDirection: 'column', border: '1px solid', borderColor: Colors.primary,
                borderRadius: '34px', background: 'white', zIndex: 1
              }}>
                <Box sx={{
                  width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  gap: '16px', flexDirection: 'column', maxWidth: '90%', paddingTop: '5%', paddingBottom: '5%'
                }}>
                  <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: '100%', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>Nick name</TypographyComponent>
                      <TextFieldComponent
                        variant="outlined"
                        value={nickName}
                        placeholder="Enter your Nick name"
                        error={!!nickNameError}
                        onChange={handleNickNameChange}
                        sx={{
                          boxShadow: 'none', width: '100%', height: { md: '44px', xs: '36px' },
                          borderRadius: '28px', background: 'white',
                          '& .MuiInputBase-root': {
                            height: { md: '44px', xs: '36px' }, color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "36px",
                            fontWeight: "400", borderRadius: '28px',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #313131', outline: 'none',
                          }

                        }} />
                         {nickNameError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{nickNameError}</TypographyComponent>}
                    </Box>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>Phone number</TypographyComponent>
                      <TextFieldComponent
                        variant="outlined"
                        value={phoneNo}
                        placeholder="Enter your phone number"
                        onChange={handlePhoneNoChange}
                        onKeyDown={handleKeyDownPhone}
                        inputProps={{ maxLength: 12 }}
                        error={!!phoneError}
                        sx={{
                          boxShadow: 'none', width: '100%', height: { md: '44px', xs: '36px' },
                          borderRadius: '28px', background: 'white',
                          '& .MuiInputBase-root': {
                            height: { md: '44px', xs: '36px' }, color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "36px",
                            fontWeight: "400", borderRadius: '28px',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #313131', outline: 'none',
                          }

                        }} />
                        {phoneError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{phoneError}</TypographyComponent>}
                    </Box>
                    {/* <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>Phone number</TypographyComponent>
                      <TextFieldComponent
                        variant="outlined"
                        value={phoneNo}
                        placeholder="Enter your phone number"
                        onChange={handlePhoneNoChange}
                        onKeyDown={handleKeyDownPhone}
                        inputProps={{ maxLength: 12 }}
                        error={!!phoneError}
                        sx={{
                          boxShadow: 'none', width: '100%', height: { md: '44px', xs: '36px' }, 
                          borderRadius: '28px', background: 'white',
                          '& .MuiInputBase-root': {
                            height: { md: '44px', xs: '36px' }, color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "36px",
                            fontWeight: "400", borderRadius: '28px',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #313131', outline: 'none',
                          },
                          '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0
                          },
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield'
                          }

                        }} />
                         <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                    {phoneError}
                  </TypographyComponent>
                    </Box> */}
                  </Box>
                 

                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>Message</TypographyComponent>

                      <TextField variant="outlined" multiline rows={3} placeholder="Type your queries"
                        value={message}
                        onChange={handleMessageChange}
                        error={!!messageError}
                        sx={{
                          boxShadow: 'none', width: '100%', height: 'auto',
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '28px', background: 'white',
                            '& fieldset': {
                              borderColor: '#CFC9C9', // Custom border color
                            },
                            '&:hover fieldset': {
                              borderColor: '#313131', // Custom border color on hover
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#313131', // Custom border color when focused
                            },
                            '&.Mui-focused': {
                              boxShadow: 'none',
                            },
                            height: 'auto', color: '#313131', fontFamily: 'Roboto',
                            fontSize: '14px', lineHeight: '36px', fontWeight: '400',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CFC9C9', // Custom border color
                          },

                        }}
                      />
                       {messageError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{messageError}</TypographyComponent>}
                    </Box>
                  </Box>
                  <TypographyComponent sx={{ fontSize: '14px', textAlign: 'left', width: '100%' }}>
                    By clicking the submit button, you agree to our{' '}
                    <Link onClick={handleTermsAndCondtionClick} style={{ textDecoration: 'none', color: Colors.link }}>
                      Terms and conditions
                    </Link>.
                  </TypographyComponent>

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <ButtonComponent onClick={handleFormSubmit} variant="contained" type="submit" sx={{ height: { md: '52px', xs: '38px' }, width: { md: '35%', xs: '47%' } }}>
                      Submit
                    </ButtonComponent>
                  </Box>

                </Box>
              </Box>
              <Box sx={{
                width: '30%', height: '100%', display: { md: 'flex', xs: 'none' }, justifyContent: 'left', alignItems: 'left',
                flexDirection: 'row', marginLeft: '-10px',
              }}>
                <img
                  src={`${Contact}`}
                  alt=""
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>

            </Box>}
          {isSuccessMessage &&
            <Box sx={{
              width: '100%', height: '420px', display: 'flex', justifyContent: 'center',
              alignItems: 'center', flexDirection: 'row', gap: '8px',
            }}>
              <Box sx={{
                width: '100%',  height: {xs:'180px',md:'74px',sm:'180px'}, display: 'flex', justifyContent: 'center',
                alignItems: 'center', flexDirection: 'row', gap: '8px', background: Colors.grey_20,
                borderRadius: '12px'
              }}>
                <CheckCircleIcon style={{ color: '#0BEA14', backgroundColor: '#FFFFFF', borderRadius: '50%', }} />
                <TypographyComponent sx={{
                  fontFamily: 'Roboto', fontWeight: 400, fontSize: '18px', color: Colors.black_70
                }}>
                  Your message has been sent successfully. We appreciate you reaching out to us. Our team will get back to you as soon as possible.
                </TypographyComponent>
              </Box>
            </Box>}
          {isTermPopUp && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
              }}>
                <Box sx={{
                  width: { md: '450px', sm: '400px', xs: '85%' }, height: '36px',
                  justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                  <IconButton onClick={handleCloseTerm} sx={{
                    width: '36px', height: '36px',

                  }} >
                    <CancelIcon sx={{
                      width: '36px', height: '36px', color: Colors.quinary, '&:hover': {
                        color: Colors.close,
                      },
                    }} ></CancelIcon>
                  </IconButton>
                </Box>

                <Box
                  sx={{

                    width: { md: '450px', sm: '400px', xs: '85%' },
                    // height: { md: ' 61%', sm: ' 61%', xs: '58%' },
                    display: 'flex',
                    height: { md: '400px', sm: '400px', xs: '45%' },
                    backgroundColor: '#fff',
                    border: '1px solid #E6E6E6',
                    borderRadius: '28px ',
                    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                    boxSizing: 'border-box',
                    zIndex: 999,
                    padding: '35px',
                    alignItems: 'center',
                    overflow: 'auto',
                    overflowX: 'hidden',
                    '&::-webkit-scrollbar': {
                      width: '12px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#7f7f7f',
                      borderRadius: '28px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#e0e0e0',
                      borderRadius: '28px',
                    },
                  }}
                >

                  <Box sx={{
                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'

                  }}>
                    <Box sx={{
                      display: 'flex', flexDirection: 'column',
                      justifyContent: 'flex-start', gap: '22px',
                      alignItems: 'flex-start', width: '100%'
                    }}>

                      <Box sx={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'flex-start', gap: '8px',
                        alignItems: 'flex-start', width: '100%',
                      }}>
                        <TypographyComponent sx={{ fontWeight: 600, fontFamily: '24px' }}>
                          Terms And Condition
                        </TypographyComponent>


                      </Box>
                      <Box sx={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'flex-start', gap: '8px',
                        alignItems: 'flex-start', width: '100%'
                      }}>
                        <TypographyComponent>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                        </TypographyComponent>


                      </Box>

                    </Box>

                  </Box>
                </Box>
              </Box>
            </Box>
          )}

        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 'auto' }}>
          <UserFooter />
        </Box>
      </Box>

    </Grid>
  );
}

export default ContactUs;
