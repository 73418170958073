const Colors = {
    primary: '#415FE2',
    secondary: '#2A2483',
    tertiary: '#5160B1',
    quinary:'#ECF4FB',
    white:'#FFFFFF',
    white_80:'#F5F5F5',
    grey_20:'#F9F9F9',
    grey_30:'#E6E6E6',
    grey_60:'#C5C5C5',
    black_70:'#5B5555',
    black_80:'#313131',
    error:'#FF4105',
    link:'#2D96D1',
    default:'#9D9D9D',  
    close:'#8EB8E6',
    Hovering:'#0026CC',
    card_color_1:'#3C51CB',
    card_color_2:'#E22A7F',
    card_color_3:'#A14C93',
    card_color_4:'#F0624E',
    card_color_5:'#45DCDC',
  };
  
  export default Colors;