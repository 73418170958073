import { Grid, Box } from "@mui/material"
import SpHeader from "../Components/SpHeader";
import axios from "axios";
import { API_URL } from './Constants';
import Progressbar from "../Components/Progressbar";
import Divider from '@mui/material/Divider';
import SelectComponent from "../Components/SelectComponent";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import React from "react";
import { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SpBusinessCardBack from "../Components/SpBusinessCardBack";
import { Select, MenuItem, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import Colors from "../Components/Colors";
import SpHeaderMobile from "../Components/SpHeaderMobile";
import { useMediaQuery } from '@mui/material';

function SpSkills() {


  const [isCompleteProfile, setIsCompleteProfile] = useState(true);
  const [serviceDetailsDataToShow, setServiceDetailsDataToShow] = useState([]);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [serviceDetailsUpdate, setServiceDetailsUpdate] = useState([]);
  const token = localStorage.getItem('token');
  const isEditSkill = localStorage.getItem('isEditSkill');
  const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
  const [isServiceDetails, setIsServiceDetails] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageDetails, setMessageDetails] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [isCertficationIcon, setIsCertficationIcon] = useState(false);
  const [isLisenceIcon, setIsLisenceIcon] = useState(false);
  const [isFranchiseIcon, setIsFranchiseIcon] = useState(false);

  const handleServiceAddPopUpCancelButtonClick = () => {
    setServiceAddPopUp(false)
  }

  async function fetchServiceProviderDetails() {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
      // console.log("service-provider-details", response.data)
      // console.log("service-provider-details", response.data.data.serviceProvider.service_details.length)
      if (response.data.data.serviceProvider.service_details.length === 0) {
        setIsCompleteProfile(false)
      }
      if (response.data.success) {
        setServiceProviderDtls(response.data.data.serviceProvider)
        setMessageDetails(response.data.data.serviceProvider.message_details)


        const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
          if (message.read === 'Unread') {
            return acc + 1;
          }
          return acc;
        }, 0);
        setUnreadMessageCount(unreadCount);
        console.log("service_details", response.data.data.serviceProvider)
        //  console.log("qr", response.data.data.qrCode)
        if (response.data.data.serviceProvider.service_details) {
          const serviceDtls = response.data.data.serviceProvider.service_details[0]
          const typesString = serviceDtls.type_details?.map(item => item.types).join(', ');
          const typeIdString = serviceDtls.type_details?.map(item => item.types_id).join(', ');
          setService([{
            "service_id": 0,
            "services": response.data.data.serviceProvider.service_details[0].services,
            "services_category_id": 0
          }])
          setTypes([{
            "types_id": 0,
            "types": serviceDtls.type_details[0].types_id,
            "services_id": 0
          }])
          // console.log(serviceDtls.type_details[0].types_id)
          // console.log(typesString)
          // console.log(typeIdString)
          //  console.log(serviceDtls.type_details?.map(item => item.types_id))
          setServiceName(response.data.data.serviceProvider.service_details[0].services)
          setChoosedTypes(serviceDtls.type_details?.map(item => item.types_id));




          setServiceDetails(response.data.data.serviceProvider.service_details[0])
          setServiceDetailsUpdate(response.data.data.serviceProvider.service_details[0])
          setIsServiceDetails(true)

          //  console.log(response.data.data.serviceProvider.service_details[0].services_id)
          setServiceId(response.data.data.serviceProvider.service_details[0].services_id)
          // const typesArray = response.data.data.serviceProvider.service_details[0].types.split(', ');

          setTypesName(serviceDtls.type_details?.map(item => item.types));
          setTypesId(serviceDtls.type_details?.map(item => item.types_id));

          //  console.log(response.data.data.serviceProvider.service_details[0].certifications)
          if (response.data.data.serviceProvider.service_details[0].certifications) {

            const certificationsString = response.data.data.serviceProvider.service_details[0].certifications;
            const certificationsList = certificationsString.split(',').map(cert => ({ certifications: cert.trim() }));
            setSelectedCertifications(certificationsList)
            setIsCertificationsAdd(true)
          }
          else {
            // setSelectedCertifications(response.data.data.serviceProvider.service_details[0].certifications)
            setIsCertificationsAdd(false)
          }

          if (response.data.data.serviceProvider.service_details[0].license) {
            const lisenceString = response.data.data.serviceProvider.service_details[0].license;
            const lisenceList = lisenceString.split(',').map(cert => ({ license: cert.trim() }));
            setSelectedLicense(lisenceList)
            setIsLicenseAdd(true)
          }
          else {
            //  setSelectedLicense(response.data.data.serviceProvider.service_details[0].license)
            setIsLicenseAdd(false)
          }

          if (response.data.data.serviceProvider.service_details[0].franchise) {
            const franchiseString = response.data.data.serviceProvider.service_details[0].franchise;
            const franchiseList = franchiseString.split(',').map(cert => ({ franchise: cert.trim() }));
            setSelectedFranchise(franchiseList)
            setIsFranchiseAdd(true)
          }
          else {
            // setSelectedFranchise(response.data.data.serviceProvider.service_details[0].franchise)
            setIsFranchiseAdd(false)
          }

          setOfficeAddress(response.data.data.serviceProvider.service_details[0].office_address)
          setAboutUs(response.data.data.serviceProvider.service_details[0].about_us)

        }

      } else {
        console.error('Error:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }
  useEffect(() => {
    fetchServiceProviderDetails();
  }, []);
  const [service, setService] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [serviceAddPopUp, setServiceAddPopUp] = useState(false);
  const [serviceError, setServiceError] = useState("");

  const [types, setTypes] = useState([]);
  const [choosedTypes, setChoosedTypes] = useState([]);
  const [typesName, setTypesName] = useState([]);
  const [typesId, setTypesId] = useState([]);
  const [typesError, setTypesError] = useState("");

  const handleInputServiceChange = async (name, id) => {
    if (isServiceDetails === true) {
      setServiceName(name);
      setServiceId(id);
      setServiceError('');
      setTypes([]);
      setChoosedTypes([]);
      setTypesName([]);
      setTypesId([]);
      setTypesError('');
      setCertifications('');
      setIsCertificationsAdd(false);
      setSelectedCertifications([]);
      setLicense('');
      setIsLicenseAdd(false);
      setSelectedLicense([]);
      setFranchise('');
      setIsFranchiseAdd(false);
      setSelectedFranchise([]);
      setOfficeAddress('');
      setAboutUs('');
      setAboutUsError('');
    }
    else {
      localStorage.setItem('isEditSkill', true);
      setIsServiceDetails(false)
      setServiceName(name);
      setServiceId(id);
      setTypesName([]);
      setServiceError('');
    }

  };

  const handleInputTypesChange = (selectedTypes) => {
    // console.log("selectedTypes", selectedTypes)
    // if (choosedTypes.length < 5) {
    setChoosedTypes(selectedTypes)
    const typeNames = selectedTypes.map(type => {
      const foundType = types.find(item => item.types_id === type);
      return foundType ? foundType.types : null;
    });
    const typeIds = selectedTypes.map(type => type);
    setTypesName(typeNames);
    setTypesId(typeIds);
    setTypesError("");
    //  console.log("selectedIds", typeNames, typeIds)
    // }

  };



  const fetchServices = async () => {
    try {
      const response = await axios.get(`${API_URL}/select_all_services`);
      setService(response.data);
      // console.log("all services",response.data)
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);


  const fetchTypes = async () => {
    try {
      const response = await axios.post(`${API_URL}/select_all_types`,
        { serviceId: serviceId, });
      setTypes(response.data);
      // console.log("all types",response.data)
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };

  useEffect(() => {
    if (serviceId) {
      fetchTypes();
    }
  }, [serviceId]);


  const [certifications, setCertifications] = useState('');
  const [isCertificationsAdd, setIsCertificationsAdd] = useState(false);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);


  const handleInputCertificationsChange = (event) => {
    const certficteValue = event.target.value;
    setCertifications(event.target.value);
    setIsCertficationIcon(true)


    if (selectedCertifications.length < 5) {
      const licenselist = selectedLicense.map(lic => lic.license).join(', '); // Extracting certifications
      const franchiselist = selectedFranchise.map(fr => fr.franchise).join(', '); // Extracting licenses
      let finalCertificationslist;
      const certificationslist = selectedCertifications.map(cert => cert.certifications).concat(event.target.value); // Add event.target.value to licenselist
      finalCertificationslist = certificationslist.join(', ');

      const typeslist = typesName.join(', ')
      const typesidlist = typesId.join(', ')
      const typeDetails = choosedTypes.map(typeId => {
        const type = types.find(t => t.types_id === typeId);
        return {
          types_id: typeId,
          types: type ? type.types : '' // Fallback to 'Unknown' if type is not found
        };
      });

      setServiceDetailsDataToShow({
        services: serviceName,
        servicesId: serviceId,
        types: typeslist,
        typesId: typesidlist,
        type_details: typeDetails,
        certifications: finalCertificationslist,
        franchise: franchiselist,
        license: licenselist,
        office_address: OfficeAddress,
        about_us: aboutUs

      });

    }
  };

  const handleCertificationOnBlur = (event) => {
    // console.log("key up", event.target.value)
    if (selectedCertifications.length < 5) {
      handleAddCertification()
    }
  }
  const handleAddCertification = () => {
    setIsCertficationIcon(false)
    const trimmedCertifications = certifications.trim();

    if (trimmedCertifications !== '') {
      setSelectedCertifications((prevCertifications) => {
        const updatedCertifications = [...prevCertifications];

        if (editingIndex !== -1) {
          updatedCertifications[editingIndex] = {
            certifications: trimmedCertifications,

          };
          setEditingIndex(-1);
        } else {
          updatedCertifications.push({
            certifications: trimmedCertifications,

          });
        }

        return updatedCertifications;
      });

      setCertifications('');
      setIsCertificationsAdd(true);
    }

  };


  const handleCertificationsShowButtonClick = async (certification, index) => {
    setCertifications(certification)
    setEditingIndex(index)
  }

  const handleCertificationsCloseButtonClick = (index) => {
    const updatedCertifications = [...selectedCertifications];
    updatedCertifications.splice(index, 1);
    setSelectedCertifications(updatedCertifications);

  };

  const [license, setLicense] = useState('');
  const [isLicenseAdd, setIsLicenseAdd] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState([]);



  const handleInputLicenseChange = (event) => {
    setIsLisenceIcon(true)
    setLicense(event.target.value);
    if (selectedLicense.length < 5) {
      const certificationslist = selectedCertifications.map(cert => cert.certifications).join(', '); // Extracting certifications
      const franchiselist = selectedFranchise.map(fr => fr.franchise).join(', '); // Extracting licenses
      let finalLicenselist;
      const licenselist = selectedLicense.map(lic => lic.license).concat(event.target.value); // Add event.target.value to licenselist
      finalLicenselist = licenselist.join(', ');

      const typeslist = typesName.join(', ')
      const typesidlist = typesId.join(', ')
      const typeDetails = choosedTypes.map(typeId => {
        const type = types.find(t => t.types_id === typeId);
        return {
          types_id: typeId,
          types: type ? type.types : '' // Fallback to 'Unknown' if type is not found
        };
      });

      setServiceDetailsDataToShow({
        services: serviceName,
        servicesId: serviceId,
        types: typeslist,
        typesId: typesidlist,
        type_details: typeDetails,
        certifications: certificationslist,
        franchise: franchiselist,
        license: finalLicenselist,
        office_address: OfficeAddress,
        about_us: aboutUs

      });

    }

  };

  const handleLicenseOnBlur = (event) => {
    // console.log("key up", event.target.value)
    if (selectedLicense.length < 5) {
      handleAddLicense()
    }
  }

  const handleAddLicense = () => {
    setIsLisenceIcon(false)
    const trimmedLicense = license.trim();

    if (trimmedLicense !== '') {
      setSelectedLicense((prevLicense) => {
        const updatedLicense = [...prevLicense];

        if (editingIndex !== -1) {
          updatedLicense[editingIndex] = {
            license: trimmedLicense,

          };
          setEditingIndex(-1);
        } else {
          updatedLicense.push({
            license: trimmedLicense,

          });
        }

        return updatedLicense;
      });

      setLicense('');
      setIsLicenseAdd(true);
    }
  };


  const handleLicenseShowButtonClick = async (license, index) => {
    setLicense(license)
    setEditingIndex(index)
  }

  const handleLicenseCloseButtonClick = (index) => {
    const updatedLicense = [...selectedLicense];
    updatedLicense.splice(index, 1);
    setSelectedLicense(updatedLicense);

  };


  const [franchise, setFranchise] = useState('');
  const [isFranchiseAdd, setIsFranchiseAdd] = useState(false);
  const [selectedFranchise, setSelectedFranchise] = useState([]);



  const handleInputFranchiseChange = (event) => {
    setIsFranchiseIcon(true)
    setFranchise(event.target.value);
    if (selectedFranchise.length < 5) {
      const certificationslist = selectedCertifications.map(cert => cert.certifications).join(', '); // Extracting certifications
      const licenselist = selectedLicense.map(lic => lic.license).join(', '); // Extracting licenses
      let finalFranchiselist;
      const franchiselist = selectedFranchise.map(fr => fr.franchise).concat(event.target.value); // Add event.target.value to licenselist
      finalFranchiselist = franchiselist.join(', ');

      const typeslist = typesName.join(', ')
      const typesidlist = typesId.join(', ')
      const typeDetails = choosedTypes.map(typeId => {
        const type = types.find(t => t.types_id === typeId);
        return {
          types_id: typeId,
          types: type ? type.types : '' // Fallback to 'Unknown' if type is not found
        };
      });
      setServiceDetailsDataToShow({
        services: serviceName,
        servicesId: serviceId,
        types: typeslist,
        typesId: typesidlist,
        type_details: typeDetails,
        certifications: certificationslist,
        franchise: finalFranchiselist,
        license: licenselist,
        office_address: OfficeAddress,
        about_us: aboutUs

      });

    }
  };

  const handleFranchiseOnBlur = (event) => {
    // console.log("key up", event.target.value)
    if (selectedFranchise.length < 5) {
      handleAddFranchise()
    }
  }

  const handleAddFranchise = () => {
    const trimmedFranchise = franchise.trim();
    setIsFranchiseIcon(false)
    if (trimmedFranchise !== '') {
      setSelectedFranchise((prevFranchise) => {
        const updatedFranchise = [...prevFranchise];

        if (editingIndex !== -1) {
          updatedFranchise[editingIndex] = {
            franchise: trimmedFranchise,
          };
          setEditingIndex(-1);
        } else {
          updatedFranchise.push({
            franchise: trimmedFranchise,

          });
        }

        return updatedFranchise;
      });

      setFranchise('');
      setIsFranchiseAdd(true);
    }
  };


  const handleFranchiseShowButtonClick = async (franchise, index) => {
    setFranchise(franchise)
    setEditingIndex(index)
  }

  const handleFranchiseCloseButtonClick = (index) => {
    const updatedFranchise = [...selectedFranchise];
    updatedFranchise.splice(index, 1);
    setSelectedFranchise(updatedFranchise);

  };


  const handleEnterKeyPress = (event, field) => {
    if (event.key === 'Enter') {
      setIsFranchiseIcon(false)
      setIsCertficationIcon(false)
      setIsLisenceIcon(false)
      event.preventDefault();
      if (field === 'certifications' && selectedCertifications.length < 5) {
        handleAddCertification();
      }
      else if (field === 'license' && selectedLicense.length < 5) {
        handleAddLicense();
      }
      else if (field === 'franchise' && selectedFranchise.length < 5) {
        handleAddFranchise();
      }
    }
  };
  const handleUpdateUsestate = () => {


  }

  const [OfficeAddress, setOfficeAddress] = useState('');
  // const [selectedOfficeAddress, setSelectedOfficeAddress] = useState([]);

  const handleInputOfficeAddressChange = (event) => {
    setOfficeAddress(event.target.value)

  };

  const [aboutUs, setAboutUs] = useState('');
  const [aboutUsError, setAboutUsError] = useState("");
  // const [selectedAboutUs, setSelectedAboutUs] = useState([]);

  const handleInputAboutUsChange = (event) => {
    setAboutUs(event.target.value)
    setAboutUsError('')
  };


  useEffect(() => {
    // console.log(choosedTypes)
    // console.log(types)
    const certificationslist = selectedCertifications.map(cert => cert.certifications).join(', ');
    const licenselist = selectedLicense.map(lic => lic.license).join(', ');
    const franchiselist = selectedFranchise.map(fr => fr.franchise).join(', ');
    const typeslist = typesName.join(', ');
    const typesidlist = typesId.join(', ');
    const typeDetails = choosedTypes.map(typeId => {
      const type = types.find(t => t.types_id === typeId);
      return {
        types_id: typeId,
        types: type ? type.types : '' // Fallback to 'Unknown' if type is not found
      };
    });

    setServiceDetailsDataToShow({
      services: serviceName,
      servicesId: serviceId,
      types: typeslist,
      typesId: typesidlist,
      type_details: typeDetails,
      certifications: certificationslist,
      franchise: franchiselist,
      license: licenselist,
      office_address: OfficeAddress,
      about_us: aboutUs
    });

  }, [isEditSkill, serviceName, serviceId, typesName, typesId, selectedCertifications, selectedLicense, selectedFranchise, OfficeAddress, aboutUs, serviceDetailsUpdate, types]);




  const handleBackButtonClick = () => {
    window.location.href = '/add-location'
  }



  const handleSaveButtonClick = async () => {
    // console.log(aboutUs.trim())
    setIsSave(true)
    handleUpdateUsestate()
    // console.log(!Array.isArray(typesName))
    //  console.log(typesName.length === 0)
    if (serviceName.trim() === "" || typesName.length === 0 || aboutUs.trim() === "") {

      if (serviceName.trim() === "") {
        setServiceError("Please choose your service");

      }

      if (!Array.isArray(typesName) || typesName.length === 0) {
        setTypesError("Please choose your types of service");


      }

      if (aboutUs.trim() === "") {
        setAboutUsError("Please add more about your self");

      }

      return;

    }

    const trimmedCertifications = Array.isArray(certifications) ? certifications[0].trim() : certifications.trim();
    if (
      certifications &&
      trimmedCertifications !== '' &&
      selectedCertifications.length < 5 &&
      !selectedCertifications.some(
        (certification) =>
          certification.certifications.trim() === trimmedCertifications
      )
    ) {
      setSelectedCertifications((prevCertifications) => [...prevCertifications, { certifications: trimmedCertifications }]);


    }

    const trimmedLicense = Array.isArray(license) ? license[0].trim() : license.trim();



    if (
      license &&
      trimmedLicense !== '' &&
      selectedLicense.length < 5 &&
      !selectedLicense.some(
        (License) =>
          License.license.trim() === trimmedLicense
      )
    ) {
      setSelectedLicense((prevLicense) => [
        ...prevLicense,
        { license: trimmedLicense },
      ]);

    }



    const trimmedFranchise = Array.isArray(franchise) ? franchise[0].trim() : franchise.trim();



    if (
      franchise &&
      trimmedFranchise !== '' &&
      selectedFranchise.length < 5 &&
      !selectedFranchise.some(
        (Franchise) =>
          Franchise.franchise.trim() === trimmedFranchise
      )
    ) {
      setSelectedFranchise((prevFranchise) => [
        ...prevFranchise,
        { franchise: trimmedFranchise },
      ]);

    }

    //  console.log("insert selectedCertifications",selectedCertifications)
    //  console.log("insert selectedFranchise",selectedFranchise)
    //  console.log("insert selectedLicense",selectedLicense)

    try {
      const response = await axios.post(`${API_URL}/add-skills`, {
        service: serviceName,
        serviceid: serviceId,
        type: typesName,
        typesid: typesId,
        certifications: selectedCertifications,
        license: selectedLicense,
        franchise: selectedFranchise,
        Officeaddress: OfficeAddress,
        aboutus: aboutUs,
        token: token,
      });
      // console.log ("response",response); 
      if (response.data.success === true) {
        window.location.href = '/sp-landing'
        setIsSave(false)
      }
    } catch (error) {
      console.error('Error:', error.message);
    }


  };



  // useEffect(() => {


  //   console.log("selectedCertifications",selectedCertifications)
  //    console.log("selectedFranchise",selectedFranchise)
  //    console.log("selectedLicense",selectedLicense)

  // }, [ ]);

  const handleRemoveType = (typeId) => {
    const updatedSelectedTypes = choosedTypes.filter(type => type !== typeId);
    setChoosedTypes(updatedSelectedTypes)
    const typeNames = updatedSelectedTypes.map(type => {
      const foundType = types.find(item => item.types_id === type);
      return foundType ? foundType.types : null;
    });
    const typeIds = updatedSelectedTypes.map(type => type);
    setTypesName(typeNames);
    setTypesId(typeIds);
    setTypesError("");
    // console.log("selectedIds", typeNames, typeIds)
  };


  return (

    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
          <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={80}
            unreadMessageCount={unreadMessageCount} isCompleteProfile={isCompleteProfile} ></SpHeader>
        </Box>
        {/* <Box sx={{  width: '100%', height: '100%',display:{ md: 'none', sm: 'none' , xs: 'flex'  }}}>
        <SpHeaderMobile serviceProviderDtls={serviceProviderDtls} percentageComplete = {80} 
         messageDetails={messageDetails} setMessageDetails={setMessageDetails} unreadMessageCount={unreadMessageCount}></SpHeaderMobile>
        </Box> */}

        <Box sx={{
          width: { md: 'calc(100% - 110px)', sm: 'calc(100% - 40px)', xs: 'calc(100% - 40px)' }, height: '100%', display: 'flex', flexDirection: 'column', gap: '28px',
          padding: { md: '55px', sm: '20px', xs: '20px' }
        }}>
          <Box sx={{
            width: '100%', height: '100%', display: 'flex',
            flexDirection: { md: 'row', sm: 'row', xs: 'column' }, gap: { md: '16px', sm: '12px', xs: '16px' }
          }}>
            <Box sx={{
              width: { md: '15%', sm: '12%', xs: '90%' }, height: '100%', display: 'flex',
              flexDirection: { md: 'column', sm: 'column', xs: 'column', },
            }}>
              <Progressbar currentStage={3} serviceProviderDtls={serviceProviderDtls} />

            </Box>
            <Divider sx={{ orientation: { md: "vertical", sm: "vertical", xs: "horizontal" }, }} flexItem />
            <Box sx={{
              width: { md: '85%', sm: '85%', xs: '100%' }, height: '100%', display: 'flex', flexDirection: { md: 'row', sm: 'row', xs: 'column' }, justifyContent: 'center',
              alignItems: 'center', gap: { md: '16px', sm: '12px', xs: '16px' }
            }}>
              <Box sx={{
                flex: 1, width: { md: '50%  !important', sm: '50%  !important', xs: '100%' }, height: '100%', display: 'flex', flexDirection: 'column', gap: '28px',
                borderRadius: '16px', padding: { md: '28px 28px', sm: '28px 28px', xs: '20px 20px' },
              }}>
                <TypographyComponent sx={{ fontWeight: 700, fontSize: '16px', color: Colors.black_80 }}>Add your services
                </TypographyComponent>

                <Box sx={{ gap: '28px', display: 'flex', flexDirection: 'column', }}>
                  <Box sx={{ gap: '8px' }}>
                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Service*
                    </TypographyComponent>
                    <Select id="service_id" value={serviceName}

                      sx={{
                        color: Colors.black_80, width: '100%', height: '48px', borderRadius: '28px',
                        fontFamily: "Roboto", fontSize: "14px", lineHeight: "24px", fontWeight: "400", paddingRight: '14px',
                        border: serviceError ? `1px solid ${Colors.error}` : 'none',
                        '& .MuiSvgIcon-root': {
                          marginRight: '14px', // Adjust the margin to move the dropdown icon right
                        },
                        '&::-webkit-scrollbar': {
                          width: '8px', // Width of the scrollbar
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: Colors.default,
                          borderRadius: '10px',
                          borderRight: '2px solid #d9d9d9'
                        },
                        '&::-webkit-scrollbar-track': {

                          borderRight: '2px solid #d9d9d9'
                        },
                        boxShadow: '#00000025'
                      }}
                      MenuProps={{
                        sx: {
                          marginTop: '4px', maxHeight: '250px',
                        },
                      }} >
                      {service.map((result, index) => (
                        <MenuItem key={result.service_id} value={result.services}
                          onClick={() => handleInputServiceChange(result.services, result.service_id)} sx={{
                            backgroundColor: serviceName === result.services ? '#415FE2 !important' : Colors.white,
                            fontFamily: "Roboto", fontSize: "14px", lineHeight: "24px", fontWeight: "400",
                            color: serviceName === result.services ? 'white' : Colors.black_80,
                            '&:hover': {
                              backgroundColor: serviceName === result.services ? '#415FE2 !important' : Colors.quinary,
                              color: serviceName === result.services ? 'white' : Colors.black_80,
                            },
                          }}>
                          {result.services}
                        </MenuItem>
                      ))}


                    </Select>
                    {serviceError &&
                      <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                        {serviceError}</TypographyComponent>}
                  </Box>

                  <Box sx={{ gap: '8px' }}>
                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}>Type of services*
                      {/* <TypographyComponent sx={{ fontSize: '12px', color: Colors.grey_60, display: 'inline' }}> (maximum 5 type of service)</TypographyComponent> */}
                    </TypographyComponent>
                    <Select id="type_id"
                      value={choosedTypes}
                      disabled={!serviceName}
                      onChange={(event) => handleInputTypesChange(event.target.value)}

                      renderValue={(selected) => (
                        <div>
                          {selected.map((value, index) => (
                            <span key={value}>
                              {types.find((type) => type.types_id === value)?.types}
                              {index < selected.length - 1 && ', '}
                            </span>
                          ))}
                        </div>
                      )}
                      multiple
                      sx={{
                        color: Colors.black_80,
                        width: '100%', height: '48px', borderRadius: '28px', fontFamily: 'Roboto', fontSize: '12px', lineHeight: '18px',
                        fontWeight: '400', justifyContent: 'space-between', border: typesError ? `1px solid ${Colors.error}` : 'none',
                        '& .MuiSvgIcon-root': {
                          marginRight: '14px', // Adjust the margin to move the dropdown icon right
                        },
                        '&::-webkit-scrollbar': {
                          width: '8px', // Width of the scrollbar
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: Colors.default,
                          borderRadius: '10px',
                          borderRight: '2px solid #d9d9d9',
                        },
                        '&::-webkit-scrollbar-track': {
                          borderRight: '2px solid #d9d9d9',
                        },
                      }}
                      MenuProps={{
                        sx: {
                          marginTop: '4px',
                          maxHeight: '250px',
                        },
                      }}
                    >
                      {types.map((result) => (
                        <MenuItem key={result.types_id} value={result.types_id}
                          sx={{
                            fontFamily: 'Roboto', fontSize: '14px', lineHeight: '24px', fontWeight: '400',
                            backgroundColor: choosedTypes.includes(result.types_id) ? '#415FE2 !important' : Colors.white,
                            color: choosedTypes.includes(result.types_id) ? 'white' : Colors.black_80,
                            '&:hover': {
                              backgroundColor: choosedTypes.includes(result.types_id) ? '#415FE2 !important' : Colors.quinary,
                              color: choosedTypes.includes(result.types_id) ? 'white' : Colors.black_80,
                            },
                          }}>
                          <span>{result.types}</span>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent menu from closing when icon is clicked
                              handleRemoveType(result.types_id);
                            }}
                            size="small"
                            sx={{ marginLeft: 'auto' }} // Align close icon to the right
                          >
                            <CloseIcon style={{ fontSize: '14px', color: choosedTypes.includes(result.types_id) ? 'white' : Colors.black_80, }} />
                          </IconButton>
                        </MenuItem>
                      ))}
                    </Select>
                    {typesError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                      {typesError}</TypographyComponent>}
                  </Box>



                  <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
                    <div>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Certifications
                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                        </TypographyComponent>
                      </TypographyComponent>
                    </div>
                    <TextFieldComponent
                      variant="outlined"
                      placeholder="Add your certifications"
                      value={certifications}
                      onChange={(event) => handleInputCertificationsChange(event)}
                      onBlur={handleCertificationOnBlur}
                      onKeyDown={(event) => handleEnterKeyPress(event, 'certifications')}
                      // sx={{
                      //   boxShadow: 'none', width: '100%', height: '48px', border: '1px solid #CFC9C9',
                      //   borderRadius: '28px', background: 'white',
                      //   '& .MuiInputBase-root': {
                      //     height: '48px', color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "36px",
                      //     fontWeight: "400", borderRadius: '28px',
                      //   },
                      //   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      //     border: '1px solid #313131', outline: 'none',
                      //   }

                      // }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            disabled={selectedCertifications.length === 5 || !isCertficationIcon}
                            onClick={() => handleAddCertification()}>
                            <AddIcon style={{ color: selectedCertifications.length === 5 || !isCertficationIcon ? Colors.default : Colors.primary }} />
                          </IconButton>
                        ),
                      }}
                    />
                    {isCertificationsAdd && (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
                        {[...Array(5)].map((_, index) => (
                          <Box key={index} sx={{
                            display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative',
                            padding: { md: '8px 10px', sm: '4px 4px', xs: '6px 6px' }, background: Colors.quinary, flex: 1, borderRadius: '16px',
                            width: 'fit-content', border: '1px solid #C5C5C5', gap: { md: '8px', sm: '4px', xs: '6px' },
                            visibility: index < selectedCertifications.length ? 'visible' : 'hidden', // Control visibility based on selected certifications
                          }}>
                            {index < selectedCertifications.length && (
                              <>
                                <Typography sx={{
                                  fontSize: '14px', fontWeight: 400, color: Colors.black_70, fontFamily: 'Roboto',
                                  lineHeight: '16.8px', cursor: 'pointer', width: 'fit-content', maxWidth: { md: '65px', sm: '35px', xs: '35px' },
                                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                }}
                                  onClick={() => handleCertificationsShowButtonClick(selectedCertifications[index].certifications, index)}>
                                  {selectedCertifications[index].certifications}
                                </Typography>
                                <CloseIcon sx={{
                                  width: { md: '16px', sm: '12px', xs: '12px' }, height: { md: '16px', sm: '12px', xs: '12px' },
                                  cursor: 'pointer', color: Colors.black_80, position: 'absolute',
                                  right: { md: '8px', sm: '4px', xs: '4px' }
                                }}
                                  onClick={() => handleCertificationsCloseButtonClick(index)} />
                              </>
                            )}
                          </Box>
                        ))}
                      </div>
                    )}

                  </Box>



                  <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
                    <div>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> License
                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                        </TypographyComponent>
                      </TypographyComponent>
                    </div>
                    <TextFieldComponent
                      variant="outlined"
                      placeholder="Add your license"
                      value={license}
                      onChange={(event) => handleInputLicenseChange(event)}
                      onBlur={handleLicenseOnBlur}
                      onKeyDown={(event) => handleEnterKeyPress(event, 'license')}
                      // sx={{
                      //   boxShadow: 'none', width: '100%', height: '48px', border: '1px solid #CFC9C9',
                      //   borderRadius: '28px', background: 'white',
                      //   '& .MuiInputBase-root': {
                      //     height: '48px', color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "36px",
                      //     fontWeight: "400", borderRadius: '28px',
                      //   },
                      //   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      //     border: '1px solid #313131', outline: 'none',
                      //   }

                      // }}
                      InputProps={{
                        endAdornment: (
                          <IconButton disabled={selectedLicense.length === 5 || !isLisenceIcon}
                            onClick={() => handleAddLicense()}>
                            <AddIcon style={{ color: selectedLicense.length === 5 || !isLisenceIcon ? Colors.default : Colors.primary }} />
                          </IconButton>
                        ),
                      }}
                    />

                    {isLicenseAdd && (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
                        {[...Array(5)].map((_, index) => (
                          <Box key={index} sx={{
                            display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative',
                            padding: { md: '8px 10px', sm: '4px 4px', xs: '6px 6px' }, background: Colors.quinary, flex: 1, borderRadius: '16px',
                            width: 'fit-content', border: '1px solid #C5C5C5', gap: { md: '8px', sm: '4px', xs: '6px' },
                            visibility: index < selectedLicense.length ? 'visible' : 'hidden', // Control visibility based on selected certifications
                          }}>
                            {index < selectedLicense.length && (
                              <>
                                <Typography sx={{
                                  fontSize: '14px', fontWeight: 400, color: Colors.black_70, fontFamily: 'Roboto',
                                  lineHeight: '16.8px', cursor: 'pointer', width: 'fit-content', maxWidth: { md: '65px', sm: '25px', xs: '25px' },
                                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                }}
                                  onClick={() => handleLicenseShowButtonClick(selectedLicense[index].license, index)}>
                                  {selectedLicense[index].license}
                                </Typography>
                                <CloseIcon sx={{
                                  width: { md: '16px', sm: '12px', xs: '12px' },
                                  height: { md: '16px', sm: '12px', xs: '12px' }, cursor: 'pointer', color: Colors.black_80, position: 'absolute', right: { md: '8px', sm: '4px', xs: '4px' }
                                }}
                                  onClick={() => handleLicenseCloseButtonClick(index)} />
                              </>
                            )}
                          </Box>
                        ))}
                      </div>
                    )}
                  </Box>


                  <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
                    <div>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Franchise
                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                        </TypographyComponent>
                      </TypographyComponent>
                    </div>
                    <TextFieldComponent
                      variant="outlined"
                      placeholder="Add your franchise"
                      value={franchise}
                      onChange={(event) => handleInputFranchiseChange(event)}
                      onBlur={handleFranchiseOnBlur}
                      onKeyDown={(event) => handleEnterKeyPress(event, 'franchise')}
                      // sx={{
                      //   boxShadow: 'none', width: '100%', height: '48px', border: '1px solid #CFC9C9',
                      //   borderRadius: '28px', background: 'white',
                      //   '& .MuiInputBase-root': {
                      //     height: '48px', color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "36px",
                      //     fontWeight: "400", borderRadius: '28px',
                      //   },
                      //   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      //     border: '1px solid #313131', outline: 'none',
                      //   }

                      // }}
                      InputProps={{
                        endAdornment: (
                          <IconButton disabled={selectedFranchise.length === 5 || !isFranchiseIcon}
                            onClick={() => handleAddFranchise()}>
                            <AddIcon style={{ color: selectedFranchise.length === 5 || !isFranchiseIcon ? Colors.default : Colors.primary }} />
                          </IconButton>
                        ),
                      }}
                    />
                    {isFranchiseAdd && (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
                        {[...Array(5)].map((_, index) => (
                          <Box key={index} sx={{
                            display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative',
                            gap: '8px', padding: '8px 10px', background: Colors.quinary, flex: 1,
                            width: 'fit-content', border: '1px solid #C5C5C5', gap: '8px', borderRadius: '16px',
                            visibility: index < selectedFranchise.length ? 'visible' : 'hidden', // Control visibility based on selected certifications
                          }}>
                            {index < selectedFranchise.length && (
                              <>
                                <Typography sx={{
                                  fontSize: '14px', fontWeight: 400, color: Colors.black_70, fontFamily: 'Roboto',
                                  lineHeight: '16.8px', cursor: 'pointer', width: 'fit-content', maxWidth: '65px',
                                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                }}
                                  onClick={() => handleFranchiseShowButtonClick(selectedFranchise[index].franchise, index)}>
                                  {selectedFranchise[index].franchise}
                                </Typography>
                                <CloseIcon sx={{
                                  width: { md: '16px', sm: '12px', xs: '12px' }, height: { md: '16px', sm: '12px', xs: '12px' }, cursor: 'pointer', color: Colors.black_80, position: 'absolute', right: { md: '8px', sm: '4px', xs: '4px' }
                                }}
                                  onClick={() => handleFranchiseCloseButtonClick(index)} />
                              </>
                            )}
                          </Box>
                        ))}
                      </div>
                    )}

                  </Box>


                  <Box sx={{ gap: '8px' }}>
                    <div>
                      <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Office address
                        <TypographyComponent sx={{ fontSize: '12px', color: Colors.grey_60, display: 'inline' }}> (Optional)
                        </TypographyComponent>
                      </TypographyComponent>
                    </div>
                    <TextFieldComponent placeholder="Add your office address"
                      value={OfficeAddress}
                      onChange={(event) => handleInputOfficeAddressChange(event)}>
                    </TextFieldComponent>
                  </Box>

                  <Box sx={{ gap: '8px' }}>
                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> About us*
                    </TypographyComponent>
                    <TextField variant="outlined" multiline rows={4} placeholder="Add more about your self"
                      value={aboutUs}
                      onChange={(event) => handleInputAboutUsChange(event)}
                      sx={{
                        width: "100%", fontFamily: "Roboto", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
                        border: !!aboutUsError ? `1px solid ${Colors.error}` : '1px solid #CFC9C9', borderRadius: '28px', background: 'white',
                        '& .MuiInputBase-root': {
                          height: '99px', color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "18px",
                          fontWeight: "400", borderRadius: '28px',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #313131', outline: 'none',
                        }
                      }}
                    />
                  </Box>
                  {aboutUsError &&
                    <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>
                      {aboutUsError}</TypographyComponent>}

                </Box>

                <Box sx={{ width: '100%', height: '100%', display: { md: 'flex', sm: 'flex', xs: 'none' }, flexDirection: 'row', gap: '16px' }}>
                  <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                  </Box>
                  {isEditSkill && <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    <ButtonComponentWhite onClick={handleBackButtonClick} >Cancel</ButtonComponentWhite>
                    <ButtonComponent onClick={handleSaveButtonClick}>Save</ButtonComponent>
                  </Box>}
                  {!isEditSkill && <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    <ButtonComponentWhite onClick={handleBackButtonClick}  >Back</ButtonComponentWhite>
                    <ButtonComponent onClick={handleSaveButtonClick}>Save</ButtonComponent>
                  </Box>}

                </Box>

              </Box>
              <Box sx={{
                flex: 1, width: { md: '35%', sm: '38%', xs: '100%' }, height: '275px', display: 'flex', flexDirection: 'column', gap: '28px',
                borderRadius: '16px', justifyContent: 'center',
              }}>
                <SpBusinessCardBack serviceDetails={serviceDetails} isEditEnable={false} serviceDetailsDataToShow={serviceDetailsDataToShow}
                  setServiceDetailsDataToShow={setServiceDetailsDataToShow} serviceProviderDtls={serviceProviderDtls}
                ></SpBusinessCardBack>
              </Box>

              <Box sx={{ width: '100%', height: '100%', display: { md: 'none', sm: 'none', xs: 'flex' }, flexDirection: 'row', gap: '16px' }}>
                <Box sx={{ width: '20%', height: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                </Box>
                {isEditSkill && <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                  <ButtonComponentWhite onClick={handleBackButtonClick} >Cancel</ButtonComponentWhite>
                  <ButtonComponent onClick={handleSaveButtonClick}>Save</ButtonComponent>
                </Box>}
                {!isEditSkill && <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                  <ButtonComponentWhite onClick={handleBackButtonClick}  >Back</ButtonComponentWhite>
                  <ButtonComponent onClick={handleSaveButtonClick}>Save</ButtonComponent>
                </Box>}

              </Box>
            </Box>

          </Box>
        </Box>
      </Box>
      {serviceAddPopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton onClick={handleServiceAddPopUpCancelButtonClick} sx={{
                width: '36px', height: '36px',

              }} >
                <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close }} ></CancelIcon>
              </IconButton>
            </Box>
            <Box
              sx={{

                width: { md: '375px', sm: '380px', xs: '85%' },
                height: 'fit-content',
                backgroundColor: '#FFFFFF',
                border: '1px solid #FFFFFF',
                borderRadius: '28px ',
                boxShadow: 'none',
                boxSizing: 'border-box',
                zIndex: '3',
                padding: '35px',
                alignItems: 'center',

              }}
            >

              <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                justifyContent: 'center', alignContent: 'center', height: '100%'
              }}>
                <Box sx={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                  alignContent: 'center', width: '100%'
                }}>

                  <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                    Please add your service details. This step is essential for completing your profile and making it easier for someone to find your services.  </TypographyComponent>

                </Box>


                <Box sx={{
                  display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                  alignContent: 'center', alignItems: 'center', width: '100%'
                }}>

                  <ButtonComponent onClick={handleServiceAddPopUpCancelButtonClick}> Ok </ButtonComponent>


                </Box>


              </Box>
            </Box>
          </Box>
        </Box>}



    </Grid>
  )
} export default SpSkills;