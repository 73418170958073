import React from 'react';
import { Box, Button, Typography, Container, Grid, Card, CardContent, Avatar, Link } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

function CommunityUserView() {
  const popularServices = [
    { name: 'Plumber', icon: 'plumbing' },
    { name: 'Electrician', icon: 'electrical_services' },
    { name: 'Roofing', icon: 'roofing' },
    { name: 'HVAC Technician', icon: 'hvac' },
    { name: 'Shutters & Blinds', icon: 'window' },
  ];

  const otherServices = [
    { name: 'Plumber', icon: 'plumbing' },
    { name: 'Electrician', icon: 'electrical_services' },
    { name: 'Roofing', icon: 'roofing' },
  ];

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Community pages  Kannur friends
        </Typography>
      </Box>
      <Box mb={4}>
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Avatar alt="Group" src="path_to_avatar" />
              <Box ml={2}>
                <Typography variant="h5">Kannur friends</Typography>
                <Typography variant="body2" color="textSecondary">
                  Thaliparamba, Kannur
                </Typography>
                <Typography variant="body2">
                  +105 Members
                </Typography>
              </Box>
              <Button variant="contained" color="primary" startIcon={<ShareIcon />} sx={{ marginLeft: 'auto' }}>
                Share
              </Button>
            </Box>
            <Typography variant="body2" mt={2}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Popular services
        </Typography>
        <Grid container spacing={2}>
          {popularServices.map((service, index) => (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Card>
                <CardContent>
                  <Typography align="center">
                    <span className="material-icons">{service.icon}</span>
                    <Typography variant="body1">{service.name}</Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Other services
        </Typography>
        <Grid container spacing={2}>
          {otherServices.map((service, index) => (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Card>
                <CardContent>
                  <Typography align="center">
                    <span className="material-icons">{service.icon}</span>
                    <Typography variant="body1">{service.name}</Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4} display="flex" justifyContent="space-between" bgcolor="primary.main" color="primary.contrastText" p={2}>
        <Link href="#">Contact us</Link>
        <Link href="#">Disclaimer</Link>
        <Link href="#">Youtube</Link>
        <Link href="#">About us</Link>
        <Link href="#">Cookie policy</Link>
        <Link href="#">Privacy policy</Link>
        <Link href="#">Terms of use</Link>
        <Link href="#">Instagram</Link>
        <Link href="#">Facebook</Link>
        <Button variant="contained" color="secondary">Service provider area</Button>
        <Button variant="contained" color="secondary">Community admin area</Button>
      </Box>
    </Container>
  );
}

export default CommunityUserView;
