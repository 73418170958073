import React from "react"
import Button from '@mui/material/Button';
import Colors from "./Colors";

 function ButtonComponent({ onClick, sx, ...props }){
    
    return(
        <Button variant="contained" 
           onClick={onClick} 
            sx={{boxShadow: 'none',width:'100%', height:{ md:'48px' , sm: '48px', xs: '38px'  }, backgroundColor: Colors.primary,
            borderRadius:'28px',color:Colors.white, textTransform: 'none',fontFamily: 'Roboto',
            fontSize:'16px', fontWeight:'400',
            '&:hover': {
                background:Colors.Hovering ,textTransform: 'none',color:Colors.white, boxShadow: 'none',
              },
              ...sx,
            }}
                 {...props}>            
         </Button>
    )
}
export default ButtonComponent;

