import { Grid, Box, Typography, Select, MenuItem, TextField, InputAdornment, Button, Tabs, Tab, } from "@mui/material"
import SpHeader from "../Components/SpHeader";
import axios from "axios";
import { API_URL } from './Constants';
import SelectComponent from "../Components/SelectComponent";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import React from "react";
import IconButton from '@mui/material/IconButton';
import { useState, useEffect, useRef } from "react";
import StepperPhase2 from "../Components/StepperPhase2";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Divider from '@mui/material/Divider';
import Progressbar from "../Components/Progressbar";
import BusinessCardBack from "../Components/BusinescrdBack";
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';


function AddService() {

    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [serviceDetailsDataToShow, setServiceDetailsDataToShow] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [isTypeShow, setIsTypeShow] = useState(false);
    const [typeNames, setTypeNames] = useState([]);
    const [typeIds, setTypeIds] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const [typesError, setTypesError] = useState("");

    const [multipleServiceName, setMultipleServiceName] = useState([]);
    const [multipleServiceId, setMultipleServiceId] = useState([]);
    const [multipleTypesName, setMultipleTypesName] = useState([]);
    const [multipleTypesId, setMultipleTypesId] = useState([]);
    const [multipleCertification, setMultipleCertification] = useState([]);
    const [multipleLisence, setMultipleLisence] = useState([]);
    const [multipleFranchise, setMultipleFranchise] = useState([]);


    async function fetchServiceProviderDetails() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            // console.log("service-provider-details", response.data)
            // console.log("service-provider-details", response.data.data.serviceProvider.service_details.length)
            if (response.data.data.serviceProvider.service_details.length === 0) {
                setIsCompleteProfile(false)
            }
            if (response.data.success) {
                setServiceProviderDtls(response.data.data.serviceProvider)
                setMessageDetails(response.data.data.serviceProvider.message_details)


                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

            }
            else {
                console.error('Error:', response.data.error);
            }
        }
        catch (error) {
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);


    const [service, setService] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [serviceProfessionName, setServiceProfessionName] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [serviceError, setServiceError] = useState("");

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${API_URL}/select_all_services`);
            setService(response.data);
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const fetchTypes = async () => {
        try {
            const response = await axios.post(`${API_URL}/select_all_types`,
                { serviceId: serviceId, });
            setAllTypes(response.data);
            console.log(response.data)
            console.log(serviceId)
        } catch (error) {
            console.error('Error fetching service categories:', error);
        }
    };

    useEffect(() => {
        if (serviceId) {
            fetchTypes();
        }
    }, [serviceId]);

    const handleInputServiceChange = async (value) => {
        const selectedValue = value;
        const selectedOption = service.find(option => option.service_id === selectedValue);
        setServiceName(selectedOption.services);
        setServiceId(selectedOption.service_id);
        setServiceProfessionName(selectedOption.professional_name)
        setServiceError('');
        setTypeNames([])
        setTypeIds([])
    };

    const handleShowTypes = async () => {
        fetchTypes()
        setIsTypeShow(true)
        setTypesError('')
    };

    const searchRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsTypeShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef]);

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event, typeId) => {
        const typeName = event.target.value;
        setTypeNames((prevSelectedNames) => {
            if (event.target.checked) {
                return [...prevSelectedNames, event.target.value];
            } else {
                return prevSelectedNames.filter((name) => name !== typeName);
            }
        });

        setTypeIds((prevSelectedIds) => {
            if (event.target.checked) {
                return [...prevSelectedIds, typeId];
            } else {
                return prevSelectedIds.filter((id) => id !== typeId);
            }
        });
    };



    const [certifications, setCertifications] = useState('');
    const [isCertificationsAdd, setIsCertificationsAdd] = useState(false);
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [isCertficationIcon, setIsCertficationIcon] = useState(false);

    const handleInputCertificationsChange = (event) => {
        const certficteValue = event.target.value;
        setCertifications(event.target.value);
        setIsCertficationIcon(true)
    };

    const handleCertificationOnBlur = (event) => {
        // console.log("key up", event.target.value)
        if (selectedCertifications.length < 5) {
            handleAddCertification()
        }
    }
    const handleAddCertification = () => {
        setIsCertficationIcon(false)
        const trimmedCertifications = certifications.trim();

        if (trimmedCertifications !== '') {
            setSelectedCertifications((prevCertifications) => {
                const updatedCertifications = [...prevCertifications];

                if (editingIndex !== -1) {
                    updatedCertifications[editingIndex] = {
                        certifications: trimmedCertifications,

                    };
                    setEditingIndex(-1);
                } else {
                    updatedCertifications.push({
                        certifications: trimmedCertifications,

                    });
                }

                return updatedCertifications;
            });

            setCertifications('');
            setIsCertificationsAdd(true);
        }

    };


    const handleCertificationsShowButtonClick = async (certification, index) => {
        setCertifications(certification)
        setEditingIndex(index)
    }

    const handleCertificationsCloseButtonClick = (index) => {
        const updatedCertifications = [...selectedCertifications];
        updatedCertifications.splice(index, 1);
        setSelectedCertifications(updatedCertifications);

    };


    const [license, setLicense] = useState('');
    const [isLicenseAdd, setIsLicenseAdd] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState([]);
    const [isLisenceIcon, setIsLisenceIcon] = useState(false);


    const handleInputLicenseChange = (event) => {
        setIsLisenceIcon(true)
        setLicense(event.target.value);
        if (selectedLicense.length < 5) {
            const licenselist = selectedLicense.map(lic => lic.license).concat(event.target.value); // Add event.target.value to licenselist
            const finalLicenselist = licenselist.join(', ');
        }

    };

    const handleLicenseOnBlur = (event) => {
        if (selectedLicense.length < 5) {
            handleAddLicense()
        }
    }

    const handleAddLicense = () => {
        setIsLisenceIcon(false)
        const trimmedLicense = license.trim();

        if (trimmedLicense !== '') {
            setSelectedLicense((prevLicense) => {
                const updatedLicense = [...prevLicense];

                if (editingIndex !== -1) {
                    updatedLicense[editingIndex] = {
                        license: trimmedLicense,

                    };
                    setEditingIndex(-1);
                } else {
                    updatedLicense.push({
                        license: trimmedLicense,

                    });
                }

                return updatedLicense;
            });

            setLicense('');
            setIsLicenseAdd(true);
        }
    };


    const handleLicenseShowButtonClick = async (license, index) => {
        setLicense(license)
        setEditingIndex(index)
    }

    const handleLicenseCloseButtonClick = (index) => {
        const updatedLicense = [...selectedLicense];
        updatedLicense.splice(index, 1);
        setSelectedLicense(updatedLicense);

    };

    const [franchise, setFranchise] = useState('');
    const [isFranchiseAdd, setIsFranchiseAdd] = useState(false);
    const [selectedFranchise, setSelectedFranchise] = useState([]);
    const [isFranchiseIcon, setIsFranchiseIcon] = useState(false);


    const handleInputFranchiseChange = (event) => {
        setIsFranchiseIcon(true)
        setFranchise(event.target.value);
        if (selectedFranchise.length < 5) {
            let finalFranchiselist;
            const franchiselist = selectedFranchise.map(fr => fr.franchise).concat(event.target.value); // Add event.target.value to licenselist
            finalFranchiselist = franchiselist.join(', ');

        }
    };

    const handleFranchiseOnBlur = (event) => {
        if (selectedFranchise.length < 5) {
            handleAddFranchise()
        }
    }

    const handleAddFranchise = () => {
        const trimmedFranchise = franchise.trim();
        setIsFranchiseIcon(false)
        if (trimmedFranchise !== '') {
            setSelectedFranchise((prevFranchise) => {
                const updatedFranchise = [...prevFranchise];

                if (editingIndex !== -1) {
                    updatedFranchise[editingIndex] = {
                        franchise: trimmedFranchise,
                    };
                    setEditingIndex(-1);
                } else {
                    updatedFranchise.push({
                        franchise: trimmedFranchise,

                    });
                }

                return updatedFranchise;
            });

            setFranchise('');
            setIsFranchiseAdd(true);
        }
    };


    const handleFranchiseShowButtonClick = async (franchise, index) => {
        setFranchise(franchise)
        setEditingIndex(index)
    }

    const handleFranchiseCloseButtonClick = (index) => {
        const updatedFranchise = [...selectedFranchise];
        updatedFranchise.splice(index, 1);
        setSelectedFranchise(updatedFranchise);

    };

    const handleEnterKeyPress = (event, field) => {
        if (event.key === 'Enter') {
            setIsFranchiseIcon(false)
            setIsCertficationIcon(false)
            setIsLisenceIcon(false)
            event.preventDefault();
            if (field === 'certifications' && selectedCertifications.length < 5) {
                handleAddCertification();
            }
            else if (field === 'license' && selectedLicense.length < 5) {
                handleAddLicense();
            }
            else if (field === 'franchise' && selectedFranchise.length < 5) {
                handleAddFranchise();
            }
        }
    };

    const [aboutUs, setAboutUs] = useState('');
    const [aboutUsError, setAboutUsError] = useState("");


    const handleInputAboutUsChange = (event) => {
        setAboutUs(event.target.value)
        setAboutUsError('')
    };

    const handleAddmoreService = () => {
        if (serviceName.trim() === "") {
            setServiceError("Please choose your service"); 
                  
        }
       
        if (typeNames.length === 0 ) {
            setTypesError("Please choose your types of service");                      
        }
        else{
            setMultipleServiceName((prevServiceNames) => [
                ...prevServiceNames,
                { service: serviceName, Id: serviceId }
              ]);              
            setServiceName('');
           
            setMultipleTypesName((prevTypesName) => [
                ...prevTypesName,
                { types: typeNames, Id: serviceId }
              ]);
            setTypeNames([]);

            setMultipleTypesId((prevTypesName) => [
                ...prevTypesName,
                { typesId: typeIds, Id: serviceId }
              ]);
            setTypeIds([]);

            setMultipleCertification((prevTypesName) => [
                ...prevTypesName,
                { certification: selectedCertifications, Id: serviceId }
              ]);           
            setSelectedCertifications([])
            setCertifications('')

            setMultipleLisence((prevTypesName) => [
                ...prevTypesName,
                { lisence: selectedLicense, Id: serviceId }
              ]);           
            setSelectedLicense([])
            setLicense('')
            
            setMultipleFranchise((prevTypesName) => [
                ...prevTypesName,
                { franchise: selectedFranchise, Id: serviceId }
              ]);           
            setSelectedFranchise([])
            setFranchise('')            

            setMultipleServiceId((prevServiceNames) => [...prevServiceNames, serviceId]);
            setServiceId('');
       }

        
    };


    const [selectedTab, setSelectedTab] = useState(0);

  
    const handleChange = (event,newIndex) => {
        setSelectedTab(newIndex);
        const selectedService = multipleServiceName[newIndex];       
        console.log(selectedService)
        console.log(selectedService.Id)
        setServiceName(selectedService.service)
        setServiceId(selectedService.Id)
        
        const filteredTypeNames = multipleTypesName.filter(item => item.Id === selectedService.Id).map(item => item.types);                
        setTypeNames(filteredTypeNames)

        const foundTypeIds = multipleTypesId.find(item => item.Id === selectedService.Id)?.typesId ;
        setTypeIds(foundTypeIds)

        const foundCertifications = multipleCertification.find(item => item.Id === selectedService.Id)?.certification || [];
        setSelectedCertifications(foundCertifications)
        setIsCertificationsAdd(true)

        const foundLicenses = multipleLisence.find(item => item.Id === selectedService.Id)?.lisence || [];
        setSelectedLicense(foundLicenses)
        setIsLicenseAdd(true)

        const foundFranchises = multipleFranchise.find(item => item.Id === selectedService.Id)?.franchise || [];
       setSelectedFranchise(foundFranchises)
       setIsFranchiseAdd(true)
           
        
      };

    const handleRemoveService = (indexToRemove) => {
        setMultipleServiceName((prevNames) => prevNames.filter((_, index) => index !== indexToRemove));
        setSelectedTab((prevSelectedTab) => (prevSelectedTab >= indexToRemove && prevSelectedTab > 0) ? prevSelectedTab - 1 : prevSelectedTab);
    };


    return (

        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={80}
                unreadMessageCount={unreadMessageCount} isCompleteProfile={isCompleteProfile}>
            </SpHeader>
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                width: '100%', height: '100%'
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', paddingTop: '2.5%', paddingBottom: '2.5%', gap: '20px',
                    width: '100%', maxWidth: '96%', height: '100%', maxHeight: '94%',
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%', gap: '16px', alignItems: 'stretch' }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center',
                            borderRight: '1px solid', borderColor: Colors.grey_30,
                            width: { md: '18%', sm: '12%', xs: '90%' }, flex: 1
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                width: '100%', maxWidth: '80%'
                            }}>
                                <Progressbar currentStage={3} serviceProviderDtls={serviceProviderDtls} />
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '42%', height: '100%', gap: '24px',
                            borderRight: '1px solid', borderColor: Colors.grey_30, paddingLeft: '1.5%'
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '100%', maxWidth: '95%', gap: '16px'
                            }}>

                                {multipleServiceName.length > 0 && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'left',
                                            width: '100%',
                                            height: '100%',
                                            gap: '8px',
                                        }}
                                    >
                                        <Tabs
                                            value={selectedTab}
                                            onChange={handleChange}
                                            aria-label="service tabs"
                                            style={{ width: 'fit-content' }}
                                            TabIndicatorProps={{
                                                style: { backgroundColor: Colors.black_80 }, // Customize the underline color here
                                            }}
                                        >
                                            {multipleServiceName.map((name, index) => (
                                                <Tab
                                                    key={index}
                                                    label={
                                                        <Box
                                                            sx={{
                                                                display: 'flex', alignItems: 'center',
                                                            }} >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Roboto', fontSize: '14px',
                                                                    fontWeight: '400', textTransform: 'none', color: Colors.black_80,
                                                                }}
                                                            >
                                                                {name.service}
                                                            </Typography>
                                                            {selectedTab === index && (
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleRemoveService(index);
                                                                    }}
                                                                    sx={{
                                                                        marginLeft: '8px',
                                                                    }}
                                                                >
                                                                    <CloseIcon
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            color: Colors.black_80,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    }
                                                    
                                                />
                                            ))}
                                        </Tabs>
                                    </Box>
                                )}

                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                                    width: '100%', height: '100%',
                                }}>
                                    <TypographyHeading>
                                        Add your service
                                    </TypographyHeading>
                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                                    width: '100%', height: '100%', gap: '24px'
                                }}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <TypographyComponent>
                                            Services*
                                        </TypographyComponent>

                                        <SelectComponent
                                            value={serviceId}
                                            onChange={(event) => handleInputServiceChange(event.target.value, event.target.textContent)}
                                            options={service.map(result => ({ value: result.service_id, label: result.services }))}
                                            error={!!serviceError} >
                                        </SelectComponent>
                                        {serviceError &&
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>
                                                {serviceError}</TypographyComponent>}


                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <TypographyComponent>
                                            Type of services*
                                        </TypographyComponent>
                                        <TextFieldComponent disabled={!serviceName}
                                            variant="outlined"
                                            onClick={() => handleShowTypes()}
                                            placeholder="Add your type of services"
                                            value={typeNames.join(', ')}
                                            error={!!typesError}                                           
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton disabled={!serviceName}
                                                        onClick={() => handleShowTypes()}>
                                                        {isTypeShow ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                    </IconButton>
                                                ),
                                            }} />

                                        {isTypeShow && (
                                            <Box sx={{
                                                width: '95%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                                alignItems: 'center', gap: '16px', position: 'relative',
                                            }}>

                                                <Box ref={searchRef} sx={{
                                                    width: '100%', padding: '16px 16px 16px 16px', position: 'absolute',
                                                    borderRadius: '16px', gap: '16px', maxHeight: '150px', overflow: 'auto', top: '0px',
                                                    height: 'fit-content', boxShadow: '0px 4px 4px 0px #00000025', border: '1px solid', zIndex: 5,
                                                    borderColor: Colors.grey_60, background: Colors.white,

                                                }}>
                                                    {allTypes.map((result, index) => (

                                                        <Box key={index}                                                           
                                                            sx={{
                                                                width: '100%', display: 'flex', flexDirection: 'row',
                                                            }}>
                                                            <Box sx={{
                                                                width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                                cursor: 'pointer', '&:hover': {
                                                                    backgroundColor: Colors.quinary,
                                                                },
                                                            }}
                                                            onClick={() => handleCheckboxChange(
                                                                { target: { checked: !typeNames.includes(result.types), value: result.types } }, 
                                                                result.typeId
                                                              )}
                                                            >
                                                                <Checkbox
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    value={result.types} // Set value to the type
                                                                    checked={typeNames.includes(result.types)} // Check if this type is selected
                                                                    onChange={(event) => handleCheckboxChange(event, result.types_id)}
                                                                    sx={{
                                                                        '&.Mui-checked': {
                                                                            color: '#0026CC',
                                                                        },
                                                                    }}
                                                                />
                                                                <TypographyComponent sx={{
                                                                    fontSize: { md: '14px', sm: '14px', xs: '12px' }, color: Colors.black_70,
                                                                    minWidth: 'fit-content'
                                                                }}> {result.types}</TypographyComponent>

                                                            </Box>
                                                        </Box>
                                                    ))}


                                                </Box>
                                            </Box>)}
                                            {typesError &&
                                    <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>
                                        {typesError}</TypographyComponent>}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Certifications
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent
                                            variant="outlined"
                                            placeholder="Add your certifications"
                                            value={certifications}
                                            onChange={(event) => handleInputCertificationsChange(event)}
                                            onBlur={handleCertificationOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'certifications')}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        disabled={selectedCertifications.length === 5 || !isCertficationIcon}
                                                        onClick={() => handleAddCertification()}>
                                                        <AddIcon style={{ color: selectedCertifications.length === 5 || !isCertficationIcon ? Colors.default : Colors.primary }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isCertificationsAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedCertifications.map((certification, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={certification.certifications}
                                                        onDelete={() => handleCertificationsCloseButtonClick(index)}
                                                        onClick={() => handleCertificationsShowButtonClick(certification.certifications, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '16px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}

                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> License
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent
                                            variant="outlined"
                                            placeholder="Add your license"
                                            value={license}
                                            onChange={(event) => handleInputLicenseChange(event)}
                                            onBlur={handleLicenseOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'license')}

                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton disabled={selectedLicense.length === 5 || !isLisenceIcon}
                                                        onClick={() => handleAddLicense()}>
                                                        <AddIcon style={{ color: selectedLicense.length === 5 || !isLisenceIcon ? Colors.default : Colors.primary }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isLicenseAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedLicense.map((license, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={license.license}
                                                        onDelete={() => handleLicenseCloseButtonClick(index)}
                                                        onClick={() => handleLicenseShowButtonClick(license.license, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '16px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px',
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Franchise
                                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 5 franchises)
                                                </TypographyComponent>
                                            </TypographyComponent>
                                        </div>
                                        <TextFieldComponent
                                            variant="outlined"
                                            placeholder="Add your franchise"
                                            value={franchise}
                                            onChange={(event) => handleInputFranchiseChange(event)}
                                            onBlur={handleFranchiseOnBlur}
                                            onKeyDown={(event) => handleEnterKeyPress(event, 'franchise')}

                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton disabled={selectedFranchise.length === 5 || !isFranchiseIcon}
                                                        onClick={() => handleAddFranchise()}>
                                                        <AddIcon style={{ color: selectedFranchise.length === 5 || !isFranchiseIcon ? Colors.default : Colors.primary }} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        {isFranchiseAdd && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap', // Ensure chips do not wrap to the next line
                                                    overflowX: 'auto', // Allow horizontal scrolling if chips exceed container width
                                                    maxWidth: '100%', // Ensure the container does not overflow horizontally
                                                    '&::-webkit-scrollbar': {
                                                        display: 'none', // Hide scrollbar for cleaner appearance
                                                    },
                                                }}
                                            >
                                                {selectedFranchise.map((franchise, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={franchise.franchise}
                                                        onDelete={() => handleFranchiseCloseButtonClick(index)}
                                                        onClick={() => handleFranchiseShowButtonClick(franchise.franchise, index)}
                                                        deleteIcon={<HighlightOffIcon />}
                                                        sx={{
                                                            backgroundColor: Colors.quinary,
                                                            borderRadius: '16px',
                                                            border: '1px solid #C5C5C5',
                                                            gap: '8px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: Colors.quaternary,
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
                                        width: '100%', height: '100%', gap: '12px', paddingTop: '16px'
                                    }}>
                                        <div>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}> Do you want to add more services?
                                            </TypographyComponent>
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.grey_60, display: 'inline' }}> (Optional, maximum 4 services)
                                            </TypographyComponent>
                                        </div>
                                        <Button onClick={handleAddmoreService} disabled={multipleServiceName.length === 4}
                                            variant="text"
                                            startIcon={<AddIcon style={{ fontSize: '16px',
                                                 color:multipleServiceName.length === 4? Colors.default : Colors.primary }} />}
                                            sx={{
                                                textTransform: 'none', fontSize: '16px', color: Colors.primary, fontFamily: "Roboto",
                                                whiteSpace: 'nowrap', // Ensure text stays in one line
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // Remove background color on hover
                                                },
                                            }}
                                        >
                                            Add more services
                                        </Button>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                                        width: '100%', height: '100%', gap: '12px', paddingTop: '16px'
                                    }}>
                                        <TypographyComponent > About me*
                                        </TypographyComponent>
                                        <TextField variant="outlined" multiline rows={4} placeholder="Add more about your self"
                                            value={aboutUs}
                                            onChange={(event) => handleInputAboutUsChange(event)}
                                            sx={{
                                                width: "100%", fontFamily: "Roboto", fontSize: "12px", lineHeight: "18px", fontWeight: "400",
                                                border: !!aboutUsError ? `1px solid ${Colors.error}` : '1px solid #CFC9C9', borderRadius: '28px', background: 'white',
                                                '& .MuiInputBase-root': {
                                                    height: '99px', color: Colors.black_80, fontFamily: "Roboto", fontSize: "14px", lineHeight: "18px",
                                                    fontWeight: "400", borderRadius: '28px',
                                                },
                                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #313131', outline: 'none',
                                                }
                                            }}
                                        />
                                        {aboutUsError &&
                                            <TypographyComponent sx={{ fontSize: '14px', color: Colors.error }}>
                                                {aboutUsError}</TypographyComponent>}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center',
                                        width: '100%', height: '100%', gap: '12px', paddingTop: '16px'
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                            width: '100%', height: '100%', gap: '12px', maxWidth: '60%'
                                        }}>
                                            <ButtonComponentWhite  >Cancel</ButtonComponentWhite>
                                            <ButtonComponent >Save</ButtonComponent>
                                        </Box>
                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            width: '40%', height: '90vh', gap: '24px',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '275px', maxWidth: '90%', gap: '24px',
                            }}>

                                <BusinessCardBack serviceDetails={serviceDetails} isEditEnable={false} serviceDetailsDataToShow={serviceDetailsDataToShow}
                                    setServiceDetailsDataToShow={setServiceDetailsDataToShow} serviceProviderDtls={serviceProviderDtls}
                                ></BusinessCardBack>
                            </Box>
                        </Box>



                    </Box>

                </Box>

            </Box>
















        </Grid>
    )
} export default AddService;