import React from 'react';
import TypographyComponent from '../Components/TypographyComponent';
import { Box, Grid } from '@mui/material';
import Colors from '../Components/Colors';

const CookiePolicy = () => {
  return (
    <Box sx={{width:'100%', height:'100%', justifyContent:'center',alignItems:'center', display:'flex'}}>
    <Box sx={{ width:'100%', maxWidth:'80%', height:'100%',display:'flex', flexDirection:'column',gap:'16px', 
    paddingTop:'24px',paddingBottom:'24px'}}>
        <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '32px', color: Colors.black_80, textAlign:'center', paddingBottom:'24px'}}>Generic Cookie Policy</TypographyComponent>
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
      Please read this cookie policy ("cookie policy", "policy") carefully before using [website] website ("website", "service") operated by [name] ("us", "we", "our").
      </TypographyComponent>
      </Box>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '28px', color: Colors.black_80}}>What are cookies?</TypographyComponent>
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
        Cookies are simple text files that are stored on your computer or mobile device by a website’s
        server. Each cookie is unique to your web browser. It will contain some anonymous information
        such as a unique identifier, website’s domain name, and some digits and numbers.
      </TypographyComponent>
      </Box>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '28px', color: Colors.black_80}}>What types of cookies do we use?</TypographyComponent>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'row',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '22px', color: Colors.black_80}}>Necessary cookies : 
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
        Necessary cookies allow us to offer you the best possible experience when accessing and
        navigating through our website and using its features. For example, these cookies let us
        recognize that you have created an account and have logged into that account.
      </TypographyComponent>
      </TypographyComponent>
      </Box>
      </Box>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'row',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '22px', color: Colors.black_80}}>Functionality cookies : 
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
        Functionality cookies let us operate the site in accordance with the choices you make. For
        example, we will recognize your username and remember how you customized the site during
        future visits.
      </TypographyComponent>
      </TypographyComponent>
      </Box>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'row',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '22px', color: Colors.black_80}}>Analytical cookies : 
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
        These cookies enable us and third-party services to collect aggregated data for statistical
        purposes on how our visitors use the website. These cookies do not contain personal
        information such as names and email addresses and are used to help us improve your user
        experience of the website.
      </TypographyComponent>
      </TypographyComponent>
      </Box>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '28px', color: Colors.black_80}}>How to delete cookies?</TypographyComponent>
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
        If you want to restrict or block the cookies that are set by our website, you can do so through
        your browser setting. Alternatively, you can visit www.internetcookies.com, which contains
        comprehensive information on how to do this on a wide variety of browsers and devices. You
        will find general information about cookies and details on how to delete cookies from your
        device.
      </TypographyComponent>
      </Box>
      <Box sx={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',gap:'8px',}}>
      <TypographyComponent sx={{fontWeight:500, fontSize: '28px', color: Colors.black_80}}>Contacting us</TypographyComponent>
      <TypographyComponent sx={{fontWeight:400, fontSize: '16px', color: Colors.black_70}}>
        If you have any questions about this policy or our use of cookies, please contact us.
      </TypographyComponent>
      </Box>
      </Box>
    </Box>
  );
}

export default CookiePolicy;
