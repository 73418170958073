import React from 'react';
import { Typography, List, ListItem, ListItemText, Box, Grid } from '@mui/material';
import Colors from '../Components/Colors';
// import UserFooter from '../Components/UserFooter';
// import UserHeader from '../Components/UserHeader';
import SpHeader from '../Components/SpHeader';
import SpFooter from '../Components/SpFooter';
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from './Constants';

function DisclaimerSp() {

    const [isCompleteProfile, setIsCompleteProfile] = useState(true);   
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  

    async function fetchServiceProviderDetails() {
            const token = localStorage.getItem('token');
            try {
              const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            
              if (response.data.success) {
            
                console.log("service-details", response.data.data.serviceProvider.service_details)
             
                const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
                setServiceProviderDtls(response.data.data.serviceProvider)
                setServiceDetails(response.data.data.serviceProvider.service_details)
                setMessageDetails(
                response.data.data.serviceProvider.message_details.sort((a, b) => b.message_id - a.message_id)
              );
                if (response.data.data.serviceProvider.service_details.length === 0) {
                  setIsCompleteProfile(false)
                }
                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                  if (message.read === 'Unread') {
                    return acc + 1;
                  }
                  return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);      
             
              } else {
                console.error('Error:', response.data.error);
              }
        
        
        
            } catch (error) {
              console.error('Error:', error.message);
            }
          }
          useEffect(() => {
            fetchServiceProviderDetails();
          }, [messageDetails])


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
            <SpHeader isCompleteProfile = {isCompleteProfile} 
                                serviceProviderDtls={serviceProviderDtls}
                                unreadMessageCount={unreadMessageCount} serviceDetails={serviceDetails}></SpHeader>
        </Box>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
         alignItems: 'center',paddingTop: '2.5%', paddingBottom: '2.5%', }}>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', background: Colors.grey_20
            }}>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                    maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', gap: '24px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '26px', xs: '22px' }, lineHeight: '24px', fontWeight: 700,
                        color: Colors.black_80,
                    }}>
                        Disclaimer
                    </Typography>

                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '24px', xs: '20px' }, lineHeight: '24px',
                        fontWeight: 700, color: Colors.black_80,
                    }}>
                        Last Updated: May 15, 2024
                    </Typography>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                        gap: '16px', justifyContent: 'center'
                    }}>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '22px', xs: '18px' }, lineHeight: '24px',
                            fontWeight: 700, color: Colors.black_80, textAlign: 'left'
                        }}>
                            User types
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Public users
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80, gap: '16px'
                        }}>
                            <strong>Description:</strong> Individuals who access the website to search for service providers. They can browse listings, read reviews, and contact service providers through the website. <br />
                            <strong>Responsibilities:</strong> Public users are responsible for the accuracy of any communication or agreement made with service providers. They should verify the credentials and trustworthiness of service providers before making any commitments or transactions.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Service providers
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                            <strong>Description:</strong> Businesses or individuals who offer services and choose to upload their profiles to the website. This includes providing details such as services offered, rates, contact information, and other relevant business information. <br />
                            <strong>Responsibilities:</strong> Service providers are responsible for the accuracy and legality of the information they post. They must manage their profiles and respond to inquiries or feedback from public users.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Disclaimer
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>

                            <strong>General:</strong> BroHandy acts solely as an intermediary platform connecting public users with service providers. We do not guarantee the accuracy, completeness, or timeliness of the information provided by users and are not responsible for any losses or damages arising from your use of this information. <br />
                            <strong>Limitation of liability:</strong> BroHandy is not responsible for any deceit, misrepresentation, or losses that may result from the information provided by both public users and service providers. We encourage all users to exercise caution and due diligence during their interactions. <br />
                            <strong>In case of fraud by pseudo users:</strong> Service providers are advised to exercise caution and verify the identity and legitimacy of public users before engaging in any transactions. BroHandy is not liable for any losses or damages resulting from interactions with fraudulent or pseudo users. Service providers should report any suspicious activities to the platform to help maintain a safe and trustworthy environment.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             <strong>Changes to this privacy statement: </strong> We may update this disclaimer statement from time to time.  <br />
                             
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize:  '16px' , lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80, textAlign: 'left'
                        }}>
                          If you have any questions about this privacy statement or our treatment of the information you provide us, please write to us by email at <a href="mailto:contact@brohandy.com">contact@brohandy.com</a>.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
        <SpFooter></SpFooter>
        </Grid>
    );
}

export default DisclaimerSp;
