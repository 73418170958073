import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const AdminTable = () => {
  const rows = [
    // Add your data here
    { id: 'Pat9878', name: 'Ashley Fanning', date: '10-Jan-2021', doctor: 'Dr. Sarah Ingram', status: 'Treated', charges: '₹1500', action: 'Received' },
    // Add more rows as needed
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Patient ID</TableCell>
            <TableCell>Patient Name</TableCell>
            <TableCell>Visit Date</TableCell>
            <TableCell>Doctor</TableCell>
            <TableCell>Treatment Status</TableCell>
            <TableCell>Charges</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.doctor}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.charges}</TableCell>
              <TableCell>{row.action}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary">{row.action}</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminTable;
