import { Grid, Box, Typography, Select, MenuItem, TextField, InputAdornment } from "@mui/material"
import SpHeader from "../Components/SpHeader";
import axios from "axios";
import { API_URL } from './Constants';
import SelectComponent from "../Components/SelectComponent";
import TypographyComponent from "../Components/TypographyComponent";
import TextFieldComponent from "../Components/TextFieldComponent";
import ButtonComponent from "../Components/ButtonComponent";
import ButtonComponentWhite from "../Components/ButtonComponentWhite";
import ButtonComponentGrey from "../Components/ButtonComponentGrey";
import React from "react";
import { useState, useEffect } from "react";
import StepperPhase2 from "../Components/StepperPhase2";
import Colors from "../Components/Colors";
import TypographyNormal from "../Components/TypographyNormal";
import TypographyHeading from "../Components/TypographyHeading";
import SpMessage from "../Components/SpMessage";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SpFooter from "../Components/SpFooter";

function SpLandingPhase2() {

    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
    const [serviceProviderUrl, setServiceProviderUrl] = useState(null);
    const [newProfilePic, setNewProfilePic] = useState(null);


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                   <Box sx={{ paddingLeft: '0px', paddingTop:'24px' }}>
                   <Typography>{children}</Typography>
               </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    async function fetchServiceProviderDetails() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            if (response.data.data.serviceProvider.service_details.length === 0) {
                setIsCompleteProfile(false)
            }
            if (response.data.success) {
                console.log(response.data.data.serviceProvider)
                setServiceProviderDtls(response.data.data.serviceProvider)
                setServiceDetails(response.data.data.serviceProvider.service_details)
                setMessageDetails(response.data.data.serviceProvider.message_details)
                setServiceProviderQrCode(response.data.data.qrCode)
                setServiceProviderUrl(response.data.data.serviceProviderURL)
                setNewProfilePic(`https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`);
               
                
                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                    if (message.read === 'Unread') {
                        return acc + 1;
                    }
                    return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

            }
            else {
                console.error('Error:', response.data.error);
            }
        }
        catch (error) {
            console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        fetchServiceProviderDetails();
    }, []);


    const [selectedServiceValue, setSelectedServiceValue] = useState(0);

    const handleChangeService = (event, newValue) => {
        setSelectedServiceValue(newValue);
    };


    const Latitude = localStorage.getItem('Latitude');
    const Longitude = localStorage.getItem('Longitude');


    return (

        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            <SpHeader serviceProviderDtls={serviceProviderDtls} percentageComplete={0}
                unreadMessageCount={unreadMessageCount} isCompleteProfile={isCompleteProfile}>
            </SpHeader>
            <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                width: '100%', height: '100%', background: Colors.grey_20
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch',
                    width: '100%', maxWidth: '96%', height: '100%', paddingTop: '2.5%', paddingBottom:'2.5%',
                     gap: '24px'
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center',
                        width: '70%', height: '100%',
                    }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center',
                        width: '100%', height: '100%',gap:'24px' 
                    }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',borderRadius:'8px',
                            width: '90%', height: '50%', background: 'linear-gradient(to bottom, #E6E6E6 42%, white 42%)',
                            padding:' 2.5%  5%'
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'right',
                                width: '100%', height: '36px', gap: '24px'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', maxWidth: '60%',
                                    width: '100%', height: '100%', gap: '24px',
                                }}>
                                    <ButtonComponentGrey><BadgeOutlinedIcon sx={{ paddingRight: '4px' }} />Preview Card</ButtonComponentGrey>
                                    <ButtonComponentWhite><ModeEditOutlineOutlinedIcon sx={{ paddingRight: '4px' }} />Edit Profile</ButtonComponentWhite>
                                    <ButtonComponent><ShareOutlinedIcon sx={{ paddingRight: '4px' }} />Share</ButtonComponent>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch',
                                width: '100%', height: '100%', gap: '48px',paddingTop:'24px'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                    width: '18%',  gap: '20px'
                                }}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                        width: '100%', height: '50%', boxShadow: ' 0px 4px 4px 0px #00000040',border:'2px solid white'
                                    }}>
                                        {newProfilePic === null || newProfilePic === 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' && (
                                            <PersonIcon sx={{width: '100%', height: '100%', }} />)}
                                        {newProfilePic !== 'https://mynewsbuckets.s3.ca-central-1.amazonaws.com/null' && (
                                            <img src={newProfilePic} alt="" style={{ width: '100%', height: '100%', }} />
                                        )}
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                        width: '100%', height: '50%', maxWidth: '70%',
                                    }}>
                                        <img src={serviceProviderQrCode} alt="qrcode" style={{ width: '100%', height: '60%' }} />
                                        <TypographyComponent sx={{ letterSpacing: '2px' }}>
                                            {serviceProviderDtls.service_provider_code}
                                        </TypographyComponent>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left',
                                    width: '82%', flex:1 
                                }}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', }}>
                                    <TypographyComponent sx={{ color: Colors.black_70, fontSize: '32px', fontWeight: 500, textTransform: 'uppercase' }}>
                                        {serviceProviderDtls.business_name}</TypographyComponent>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                                        width: '100%', gap: '2px'
                                    }}>
                                        {serviceProviderDtls.city && <TypographyComponent>{serviceProviderDtls.city}</TypographyComponent>}
                                        {serviceProviderDtls.province && <TypographyComponent>,{serviceProviderDtls.province}</TypographyComponent>}
                                        {serviceProviderDtls.country && <TypographyComponent>,{serviceProviderDtls.country}</TypographyComponent>}
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                                        width: '100%', gap: '16px', paddingTop: '16px'
                                    }}>
                                        {serviceProviderDtls.concatperson && <> <TypographyHeading>Contact person : </TypographyHeading>
                                            <TypographyComponent>{serviceProviderDtls.concatperson}</TypographyComponent></>}
                                        {serviceProviderDtls.phone_number && <> <TypographyHeading>Phone : </TypographyHeading>
                                            <TypographyComponent>+{serviceProviderDtls.country_code} {serviceProviderDtls.phone_number}</TypographyComponent></>}
                                        {serviceProviderDtls.email && <> <TypographyHeading>Email id : </TypographyHeading>
                                            <TypographyComponent>{serviceProviderDtls.email}</TypographyComponent></>}
                                    </Box>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                                         paddingTop:'48px' }}>
                                    <Tabs value={selectedServiceValue} onChange={handleChangeService} 
                                      aria-label="service details tabs"
                                        sx={{
                                            '& .MuiTab-root': {
                                                borderRadius: '4px', backgroundColor: '#FFFFFF', color: Colors.black_80,
                                                textTransform: 'none', padding:'10px 0px 10px 0px',height:'32px'
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: Colors.quinary, color: Colors.primary,
                                            },
                                            '& .MuiTabs-indicator': {
                                                display: 'none',
                                            },
                                        }}>
                                        {serviceDetails.map((detail, index) => (
                                            <Tab key={index} label={detail.services} {...a11yProps(index)} />
                                        ))}
                                    </Tabs>
                                    {serviceDetails.map((detail, index) => (
                                        <TabPanel key={index} value={selectedServiceValue} index={index}>
                                           <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
                                            gap:'16px',padding: '0px',margin:0 }} >
                                           <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%',gap:'2px' }} >
                                           <TypographyHeading>Types of services</TypographyHeading>                                          
                                           <TypographyNormal>  {detail.type_details.map(td => td.types).join(', ')}</TypographyNormal>
                                          </Box>                                           
                                           <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', height: '100%', }} >
                                           <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%',gap:'2px' }} >
                                           <TypographyHeading>Certifications</TypographyHeading>                                          
                                           <TypographyNormal> {detail.certifications}</TypographyNormal>
                                          </Box>
                                          <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%',gap:'2px' }} >
                                           <TypographyHeading>License No</TypographyHeading>                                          
                                           <TypographyNormal>  {detail.license}</TypographyNormal>
                                          </Box>
                                          
                                           </Box>
                                           <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', height: '100%', }} >
                                           <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%',gap:'2px' }} >
                                           <TypographyHeading>Office address</TypographyHeading>                                           
                                           <TypographyNormal>  {detail.office_address}</TypographyNormal>
                                          </Box>
                                          <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%',gap:'2px' }} >
                                           <TypographyHeading>Franchise</TypographyHeading>                                          
                                           <TypographyNormal>  {detail.franchise}</TypographyNormal>
                                          </Box>
                                          
                                           </Box>
                                           </Box>
                                        </TabPanel>
                                    ))}
                                     </Box>
                                </Box>
                            </Box>

                        </Box>

                        <Box sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                        width: '100%', height: '10%', background:Colors.white,borderRadius:'8px'
                        }}> 
                         <Box sx={{display: 'flex', flexDirection: 'column', width: '90%', height: '100%',gap:'8px',padding:' 2.5%  5%' }} >
                                           <TypographyHeading>About me</TypographyHeading> 
                                           {serviceDetails.map((detail, index) => (                                                                                   
                                           <TypographyNormal key={index}>{detail.about_us}</TypographyNormal> ))}
                         </Box>
                        </Box>

                          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center',
                               width: '100%', height: '60vh', background:Colors.white,borderRadius:'8px',paddingBottom:'2.5%'}}>
                                 <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center',
                               width: '100%', height: '100%', maxWidth:'90%'}}>
                                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
                                   width: '100%', height: '82px',}}>
                                     <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                                   width: '100%', height: '100%',gap:'4px'}}>
                                  <TypographyHeading >Your selected work territory : </TypographyHeading> <TypographyNormal >{serviceProviderDtls.city}</TypographyNormal>
                                  </Box>
                                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center',
                                   width: '100%', height: '100%',gap:'4px'}}>
                                  <TypographyHeading >Working Radius : </TypographyHeading> <TypographyNormal >{serviceProviderDtls.working_radius}KM</TypographyNormal>
                                  </Box>
                                  </Box>
                                 <iframe id="map-iframe"
                                    width="100%"
                                    height="100%"
                                    frameborder="0"
                                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAv9_19m7eWD1EwkYrdG5VoBCIXQ2jUmDs&q=${Latitude},${Longitude}&zoom=12&maptype=roadmap`}

                                    ></iframe> 
                            </Box>
                        </Box>
                     
                      </Box>  
                    </Box>

                    <Box sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left',
                        width: '30%',flex:1
                    }}>
                        <SpMessage></SpMessage>
                    </Box>


                </Box>
            </Box>
            <SpFooter></SpFooter>
        </Grid>
    )
} export default SpLandingPhase2;