// import React from 'react';
// import Box from '@mui/material/Box';
// import AppBar from "@mui/material/AppBar";
// import { Button} from "@mui/material";
// import { Link } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import TypographyComponent from './TypographyComponent';
// import CircularProgress from './CircularProgress';
// import MenuItem from '@mui/material/MenuItem';
// import ButtonComponentWhite from './ButtonComponentWhite';
// import ButtonComponent from './ButtonComponent';
// import IconButton from '@mui/material/IconButton';
// import CancelIcon from '@mui/icons-material/Cancel';

// function UserHeader(props){

// const {currentPage} = props
// console.log(currentPage)
 

//   const menus = [
//     { label: "Home", link: "/" },      
//     { label: "About us", link: "/about-us" },
//     { label: "Contact us", link: "/contact-us"}
//   ];

  
     
 
//   return(
//     <AppBar position="static" sx={{ bgcolor: "#FFF", height: "86px", width: '100%', boxShadow:'none' }}>
//       <Box sx={{display: "flex" ,width:'100%',flexDirection:'row',justifyContent:'left',alignItems:'left',height: "86px",}}> 
//         <Box sx={{display: "flex" ,width:'10%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//           <Box sx={{width:'100px', height:'40px', background:'#D9D9D9'}}>            
//           </Box>
//         </Box>
//         <Box sx={{display: "flex" ,flexDirection:'row',gap:'5%',width:'70%',justifyContent:'left',alignItems:'center', }}>
//           {menus.map((menu) => (
//             <Button  key={menu.label} 
//               component={Link}
//               to={menu.link}s
//               sx={{my: 2, color: "#313131", display: "block",  textTransform: "capitalize",
//                    fontFamily: "Roboto",fontSize: "16px", lineHeight: "36px", fontWeight:menu.label === currentPage ? 700 : 400,
//                    '&:hover': { color: "#313131",bgcolor:'white', fontWeight: "700"}}}>
//               {menu.label}
//             </Button>
//           ))} 
//         </Box>
       
//       </Box>
     
 
//     </AppBar>
//   );
// }

// export default UserHeader;

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../Images/logo.png'
import Colors from './Colors';

const pages = ['Home', 'About us', 'Contact us'];

function Header(props) {
    const {currentPage} = props
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [selectedPage, setSelectedPage] = React.useState('');

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleMenuItemClick = (page) => {
        setSelectedPage(page);
    };

    return (
        <AppBar position="static" sx={{
            height:{ xs: '48px', md: '86px' } , justifyContent: 'center',
            boxShadow: 'none', background: currentPage==='Home'? 'white': 'transparent' 
        }}>
            <Container maxWidth="xl" sx={{ marginLeft: '0px', height: { xs: '48px', md: '86px' }, }}>
                <Toolbar disableGutters sx={{ height: '100%' }}>
                    {/* <Box sx={{width:'100px', height:'40px', background:'#D9D9D9'}}></Box> */}
                    <Box sx={{width:  { xs: '92px', md: '220px' } , height: { xs: '36px', md: '38px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="open drawer"
                            onClick={handleOpenDrawer}
                            color={Colors.black_80}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"
                            open={openDrawer}
                            onClose={handleCloseDrawer}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    backgroundColor: Colors.secondary, // Your desired background color
                                },
                                backdropFilter: 'none', // Remove blur effect
                            }}
                            BackdropProps={{ // Customize backdrop properties
                                sx: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, // Semi-transparent black background
                            }}
                            
                        >
                            <Box sx={{ display: 'flex' }}>
                                <IconButton
                                    aria-label="close drawer"
                                    onClick={handleCloseDrawer}
                                    sx={{ ml: 'auto', color: 'white' }} // Move the close button to the right
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box
                                sx={{ width: 200 }}                               
                            >
                                {/* {pages.map((page, index) => (
                                    <MenuItem
                                        key={page}
                                        selected={selectedPage === page}
                                        onClick={() => handleMenuItemClick(page)}
                                        sx={{
                                            color: "white", display: "block",  textTransform: "none",background:currentPage === page? '#FFFFFF33': Colors.secondary,
                                            fontFamily: "Roboto",fontSize: "16px", lineHeight: "36px", fontWeight: currentPage === page? 600 :  400,
                                            '&:hover': { color: "white",bgcolor:'white', fontWeight: "600"}
                                        }}
                                    >
                                        <Link to={`/${page.toLowerCase().replace(' ', '-')}`} style={{ textDecoration: 'none', color: 'inherit' }}>{page}</Link>
                                    </MenuItem>
                                ))} */}
                                  {pages.map((page) => (
                  <Link 
                    key={page}
                    to={`/${page.toLowerCase().replace(' ', '-')}`} 
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <MenuItem
                      selected={selectedPage === page}
                      onClick={() => handleMenuItemClick(page)}
                      sx={{
                        color: "white", display: "block", textTransform: "none", background: currentPage === page ? '#FFFFFF33' : Colors.secondary,
                        fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: currentPage === page ? 600 : 400,
                        '&:hover': { color: "white", bgcolor: 'white', fontWeight: "600" }
                      }}
                    >
                      {page}
                    </MenuItem>
                  </Link>
                ))}
                            </Box>
                        </Drawer>
                    </Box>

                    <Box sx={{
                        flexGrow: 1, display: { xs: 'none', md: 'flex' }, height: '100%',paddingLeft:'54px'
                    }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                sx={{
                                    color: "#313131", display: "block",  textTransform: "none",paddingRight:'56px',
                                    fontFamily: "Roboto",fontSize: "16px", lineHeight: "36px", fontWeight:  currentPage === page? 700 :  400,
                                    '&:hover': { color: "#313131",bgcolor:'white', fontWeight: "700"}
                                }}
                            >
                                <Link to={`/${page.toLowerCase().replace(' ', '-')}`} style={{ textDecoration: 'none', color: 'inherit' }}>{page}</Link>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;