import Box from '@mui/material/Box';

import AppBar from "@mui/material/AppBar";

import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import logo from '../Images/logo.png'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import Toolbar from "@mui/material/Toolbar";
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypographyComponent from './TypographyComponent';
import CircularProgress from './CircularProgress';
import ButtonComponentWhite from './ButtonComponentWhite';
import ButtonComponent from './ButtonComponent';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Colors from './Colors';
import TextFieldComponent from './TextFieldComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Badge from '@mui/material/Badge';
import axios from 'axios';
import { API_URL } from './Constants';

function SpHeaderMobile(props) {
    const { serviceProviderDtls, percentageComplete, serviceDetails, messageDetails, setMessageDetails, unreadMessageCount} = props

    const [showDropdown, setShowDropdown] = useState(false);
    const [isSignOutPopUp, setIsSignOutPopUp] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const handleMenuItemClick = (value) => {
  
      if (value === "signout") {
        setIsSignOutPopUp(true);
      }
      setShowDropdown(false);
    }
    const handleSignOutPopUpOkButtonClick = async () => {
      localStorage.removeItem('token');
    };
  
    const handleSignOutPopUpCancelButtonClick = () => {
      setIsSignOutPopUp(false);
  
    };
  
  
    const menus = [
      { label: "Home", link: "/sp-landing" },
      { label: "About us", },
      { label: "Contact us", }
    ];
   

    const completionPercentage = { percentageComplete };
  
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [isCompleteProfile, setIsCompleteProfile] = useState(false);
  
    useEffect(() => {
      if (serviceProviderDtls && serviceProviderDtls.profile_image) {
        const imgurl = `https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${serviceProviderDtls.profile_image}`;
      //  console.log("image ", imgurl)
        setNewProfilePic(imgurl);
      }
      if (serviceDetails && serviceDetails.lengh > 0){
        setIsCompleteProfile(true)
      }
  
    }, [serviceProviderDtls]);

    const [showMenu, setShowMenu] = useState(false);
//console.log(showMenu)
    const handleMenuToggle = () => {
      setShowMenu(!showMenu);
    };

    const handleMessageClick = () => {
      window.location.href = '/message';    
    };

    const [isMsg, setIsMsg] = useState(false);   
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const token = localStorage.getItem('token');
  
    useEffect(() => {
     // console.log(messageDetails)
      if(Array.isArray(messageDetails) && messageDetails.length > 0) {
        setIsMsg(true)   
      }
      else{
        setIsMsg(false)  
      }
       
     }, [messageDetails]);
  
     const [isReadDelete, setIsReadDelete] = useState(false); 
  
     const handleIconClick = (index, id) => {
     // console.log(id)
      setIsReadDelete(!isReadDelete)
      setSelectedMessageIndex(index )
      setSelectedMessageId(id)
     };
  
     const [isDeletePopUp, setIsDeletePopUp] = useState(false); 
  
     const handleMessageMenuItemClick = async(value) => {  
  
      if (value === "read") {
        try {      
          const response = await axios.post(`${API_URL}/update-read-status`, {           
             token: token,
             msgId: selectedMessageId, 
               });
             // console.log ("response",response); 
              if(response.data.success === true)  {
                const updatedMessageDetails = messageDetails.map((message, index) => {
                  if (index === selectedMessageIndex) {
                    return { ...message, read: 'read' };
                  }
                  return message;
                });
                // Update messageDetails state
                setMessageDetails(updatedMessageDetails);
              }
              } catch (error) {
              console.error('Error:', error.message);
              }       
          
       
      }
      if (value === "delete") {
        setIsDeletePopUp(true)
      }
      setIsReadDelete(false);
    }
  
    
    const handleDeletePopUpCancelButtonClick = () => {
      setIsDeletePopUp(false)
     };
  
     const handleDeletePopUpOkButtonClick = async() => {
      try {      
        const response = await axios.post(`${API_URL}/delete-message`, {           
           token: token,
           msgId: selectedMessageId, 
             });
           // console.log ("response",response); 
            if(response.data.success === true)  {
            //  console.log('Message deleted successfully');       
              const updatedMessageDetails = messageDetails.filter((message, index) => index !== selectedMessageIndex);     
              setMessageDetails(updatedMessageDetails);
              setIsDeletePopUp(false)
            }
            } catch (error) {
            console.error('Error:', error.message);
            }       
     };
     
     const handleAllRead = async() => {
      try {      
        const response = await axios.post(`${API_URL}/mark-all-as-read`, {           
           token: token,        
             });
           // console.log ("response",response); 
            if(response.data.success === true)  {
              const updatedMessageDetails = messageDetails.map((message, index) => {
                if (index === selectedMessageIndex) {
                  return { ...message, read: 'read' };
                }
                return message;
              });
              // Update messageDetails state
              setMessageDetails(updatedMessageDetails);
            }
            } catch (error) {
            console.error('Error:', error.message);
            }     
     };

return (


<Grid container sx={{width: '100%', height: '100%' }}>
<AppBar position="static" sx={{ bgcolor: Colors.white, height:{ md:"56" , sm: "32"  , xs: "56"   } , width: '100%', paddingRight:'2.5%', 
boxShadow:'none'}}>
     <Toolbar disableGutters>
       <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center', alignItems: 'center',paddingLeft:'2.7%',
            width:'30%'         }}>
           <Box sx={{ width: '100px', height: '40px', background: Colors.default }}>
          </Box>

           <IconButton   edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle}>
               <MenuIcon  style={{color:Colors.black_80}}/>
           </IconButton>
       </Box>
       <Box sx={{display: showMenu ? "block" : "none", position: "absolute", top: "0",height: '100vh',width:'65%' , zIndex: 5,
           bgcolor: Colors.white, justifyContent: 'flex-start', borderRadius: ' 0px 0px 8px 8px', paddingBottom: '20px',
           background:Colors.secondary,}}>
            <Box sx={{width:'100%',display:'flex', flexDirection:'row', justifyContent:'right', alignItems:'center',padding:'8px 0px',
          }}>
            <CloseOutlinedIcon onClick={() => setShowMenu(false)}  
            style={{width:'32px', height:'32px', color:Colors.white, cursor:'pointer',paddingRight:'8px' }}></CloseOutlinedIcon>
            </Box>
          
           {menus.map((menu) => (
           <Button onClick={handleMenuToggle}
              key={menu.label}
              component={Link}
              to={menu.link}
              sx={{display: "block", color: "white", textTransform: "capitalize", fontFamily: "poppins",
                 fontSize: "14px", lineHeight: "21px", fontWeight: "400", textAlign: 'left', borderRadius: '0px',
                 color:Colors.white,
                 '&:hover': { background: Colors.quinary, width: '100%',color:Colors.white }}}>
             {menu.label}
           </Button>  ))}
       </Box>
       <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
        gap: '12px', marginLeft: 'auto', }}>
       { isCompleteProfile && <>
          <CircularProgress radius={16} strokeWidth={2} progress={percentageComplete} />
          <TypographyComponent sx={{ color: '#000000', fontSize: '18px' }}>Completed</TypographyComponent>
          </>}
          <Badge badgeContent={unreadMessageCount} color='error' overlap="circular" >
          <ChatBubbleOutlineIcon color='action' onClick={handleMessageClick} style={{ height: "36px", width: '36px',
             color: Colors.black_80,   cursor: 'pointer',}}></ChatBubbleOutlineIcon>
          </Badge>
          {!newProfilePic && (
            <AccountCircleIcon onClick={() => setShowDropdown(!showDropdown)} style={{ height: "36px", width: '36px',
             color: Colors.grey_60,  cursor: 'pointer', }} />)}
          {newProfilePic && (
            <img src={newProfilePic} alt="" onClick={() => setShowDropdown(!showDropdown)} style={{
              height: "36px", width: '36px', objectFit: 'cover', borderRadius: '50%',
              cursor: 'pointer', 
            }} />
          )}
         {showDropdown && (

<Box className="dropdown__menu" sx={{
  position: 'absolute', right: '10px', top: '55px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
  lineHeight: '20px', width: '100px', background: Colors.grey_20, borderRadius: '8px', boxShadow: 'none', zIndex: 1,
  color: 'white', padding: '8px',height:'100%'
}}>
  <style>
    {`
.dropdown__menu .MuiMenuItem-root:hover {
background-color: #415FE2;
color: #FFFFFF !important;
}
`}
  </style>

  <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
    fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
    fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
  }}>
    Sign out
  </MenuItem>
</Box>

)}
       </Box>
     
     </Toolbar>
</AppBar>
{showMessage &&
        <Box sx={{
          position: 'fixed',
          top: '56px',
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 'calc(100% - 40px)',
              height: 'calc(100% - 40px)',
              backgroundColor: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '28px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '20px',
              overflow:'auto',
              alignItems: 'center',
              '&::-webkit-scrollbar': {
                width: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#7f7f7f',
                borderRadius: '28px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#e0e0e0',
                borderRadius: '28px',
            },
            }}
          >
            
            <Box sx={{
             width:'calc(100%-56px)', height:'100%' ,display:'flex', flexDirection:'column', borderRadius:'16px',
             background:Colors.grey_20,gap:'27px',position:'relative', padding:'28px 16px', 
            }}>
               <TextFieldComponent placeholder="Search" sx={{ position: 'absolute', top: 20, width:'94%' }}></TextFieldComponent>
       {!isMsg && <Box sx={{width:'100%', height:'100vh', display:'flex', flexDirection:'column', justifyContent:'center',
          alignItems:'center',gap:'8px'}}>
        <TypographyComponent sx={{ color: Colors.grey_60, fontSize: '24px'}}>No messages</TypographyComponent>
        {/* <QuestionAnswerIcon sx={{color:Colors.grey_60,width:'48px', height:'48px'}}></QuestionAnswerIcon> */}
        </Box>}
        {isMsg && 
         <Box sx={{width:'100%', height:'calc(100%-60px)', display:'flex', flexDirection:'column', justifyContent:'left',
          alignItems:'center',gap:'20px',paddingTop: '60px' }}>
            <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'right',
          alignItems:'center', }}>
             <TypographyComponent onClick={handleAllRead} 
             sx={{ color: Colors.link, fontSize: '16px', fontWeight: 400, cursor:'pointer' }}>Mark all as read
                   </TypographyComponent>
                   </Box>
            {messageDetails.map((message, index) => (
                <Box key={index} sx={{width:'calc(100%-24px)', height:'100%', display:'flex', flexDirection:'column',padding:'12px',
                background: message.read === 'read' ? Colors.white : Colors.quinary, borderRadius:'8px', position:'relative'}}>
                   <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                   <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column',}}>
                   <TypographyComponent sx={{ color: Colors.black_80, fontSize: '20px', fontWeight: 700 }}>User {index + 1}
                   </TypographyComponent>
                    <TypographyComponent sx={{ color: Colors.black_80, fontSize: '16px' }}>
                    Seeking {message.type_of_service} service within the {message.location} area. If you're available, reach out to me at this number {message.user_phone_number} 
                    </TypographyComponent>
                    </Box>
                    <MoreVertIcon style={{cursor:'pointer'}} onClick={() => handleIconClick(index, message.message_id)}></MoreVertIcon>
                    </Box>
                    {isReadDelete && selectedMessageIndex === index && (

                    <Box className="dropdown__menu" sx={{
                      position: 'absolute', right: '30px', top: '10px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                      lineHeight: '20px', width: '200px', background: Colors.grey_20, borderRadius: '8px', boxShadow: 'none', zIndex: 1,
                      color: 'white', padding: '8px'
                    }}>
                      <style>
                        {`
                    .dropdown__menu .MuiMenuItem-root:hover {
                    background-color: #415FE2;
                    color: #FFFFFF !important;
                    }
                    `}
                      </style>

                <MenuItem onClick={() => handleMessageMenuItemClick("read")} value="option2" style={{
                  fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                  fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                }}>
                  Mark as read
                </MenuItem>
                <MenuItem onClick={() => handleMessageMenuItemClick("delete")} value="option2" style={{
                  fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                  fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                }}>
                  Delete
                </MenuItem>
              </Box>

              )}   
                </Box>                
            ))}  
            
        </Box>}

        {isDeletePopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '375px',
              height: '35%',
              backgroundColor: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '28px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',

            }}
          >
            <IconButton onClick={handleDeletePopUpCancelButtonClick} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: '60.5%', top: '26.5%'
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close}} ></CancelIcon>
            </IconButton>
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
              justifyContent: 'center', alignContent: 'center', height: '100%'
            }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%', gap: '16px'
              }}>
                <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                Are you sure you want to delete this message from user {selectedMessageIndex + 1}</TypographyComponent>

              </Box>


              <Box sx={{
                display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                alignContent: 'center', alignItems: 'center', width: '100%'
              }}>

                <ButtonComponent onClick={handleDeletePopUpOkButtonClick}> Delete </ButtonComponent>
                <ButtonComponentWhite onClick={handleDeletePopUpCancelButtonClick}>  Cancel </ButtonComponentWhite>

              </Box>


            </Box>
          </Box>
        </Box>}



            </Box>
          </Box>
        </Box>}
{isSignOutPopUp &&
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2',

        }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '300px',
              height: '35%',
              backgroundColor: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '28px ',
              boxShadow: 'none',
              boxSizing: 'border-box',
              zIndex: '3',
              padding: '35px',
              alignItems: 'center',

            }}
          >
            <IconButton onClick={handleSignOutPopUpCancelButtonClick} sx={{
              position: 'fixed', width: '36px', height: '36px',
              left: '68%', top: '26.5%'
            }} >
              <CancelIcon sx={{ width: '36px', height: '36px', color: Colors.close}} ></CancelIcon>
            </IconButton>
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
              justifyContent: 'center', alignContent: 'center', height: '100%'
            }}>
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                alignContent: 'center', width: '100%', gap: '16px'
              }}>
                <TypographyComponent sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                  Sign out</TypographyComponent>

                <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_70 }}>
                  Are you sure you would like to sign out your account?</TypographyComponent>

              </Box>


              <Box sx={{
                display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                alignContent: 'center', alignItems: 'center', width: '100%'
              }}>

                <ButtonComponent onClick={handleSignOutPopUpOkButtonClick}> Sign out </ButtonComponent>
                <ButtonComponentWhite onClick={handleSignOutPopUpCancelButtonClick}>  Cancel </ButtonComponentWhite>

              </Box>


            </Box>
          </Box>
        </Box>}
      
</Grid>
 );

}

export default SpHeaderMobile;
