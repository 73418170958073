
import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import TypographyComponent from "../Components/TypographyComponent"
import TextFieldComponent from "../Components/TextFieldComponent"
import ButtonComponent from "../Components/ButtonComponent"
import { API_URL } from "../Components/Constants"
import GoogleIcon from '../Images/google.png';
import { useState, useEffect } from "react";
import axios from "axios";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useGoogleLogin } from '@react-oauth/google';
import Colors from '../Components/Colors';
import mainImage from '../Images/mainImage.png'
import mainBg from '../Images/mainBg.png'

function SignIn() {


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('service_provider')) {
            const updateEmailVerify = async () => {
                try {
                    const response = await axios.post(`${API_URL}/update-email-verify`, {
                        token: queryParams.get('service_provider')
                    });
                  //  console.log('Update email verification response:', response);
                } catch (error) {
                    console.error('Error:', error.message);
                }
            };

            updateEmailVerify();
        }
    }, []);

    const [signInEmail, setSignInEmail] = useState("");
    const [signInPassword, setSignInPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isCheckedSignIn, setisCheckedSignIn] = useState(false);
    const [emailGoogleSignInError, setEmailGoogleSignInError] = useState("");
    const [testpurpose, settestpurpose] = useState("");
    const [loginError, setLoginError] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (event) => {
        setSignInEmail(event.target.value);
        setEmailError("");
        const lebrememberMeValue = JSON.parse(localStorage.getItem('lebrememberMeValue')) || [];
        const foundData = lebrememberMeValue.find((data) => data.email === event.target.value);
        // console.log("foundData", rememberMeValue)
        if (foundData) {
            setSignInEmail(event.target.value)
            setSignInPassword(foundData.password)
            setisCheckedSignIn(true);
        }
    };

    const handlePasswordChange = (event) => {
        setSignInPassword(event.target.value);
        setPasswordError("");
    };

    const handleCheckboxChange = (event) => {
        setisCheckedSignIn(event.target.checked);
    };

    const handleSignUpClick = () => {
        window.location.href = '/signup'
    }

    const handleForgotPassword = () => {
        window.location.href = '/password-recovery-send-mail'
    };

    const handleSignInClick = async () => {

        if (signInEmail.trim() === "") {
            setEmailError("Please enter your email id");
            return;
        }
        else if (signInPassword.trim() === "") {
            setPasswordError("Please enter your password");
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/signin`, {
                email: signInEmail,
                password: signInPassword,
                rememberMe: isCheckedSignIn,
            });
           // console.log("response", response.data);
           if(response.data.email_verify==='ENABLE'){
            if(response.data.active==='yes'){
                localStorage.setItem('token', response.data.token);
                window.location.href = '/sp-landing'
            }
            else{
                setLoginError('your profile is not active');
                return;
            }
           
           }
           if(response.data.email_verify==='PENDING'){
            setLoginError('please verify your mailid');
            return;

           }
           
            if (isCheckedSignIn) {
                const email = signInEmail; 
                const password = signInPassword;
                const lebrememberMeValue = JSON.parse(localStorage.getItem('lebrememberMeValue')) || [];
                const newRememberMe = { email, password };
                lebrememberMeValue.push(newRememberMe);
               // console.log("newRememberMe:", newRememberMe);
                localStorage.setItem('lebrememberMeValue', JSON.stringify(lebrememberMeValue));
                //console.log("rememberMeValue",)

            }
        }
        catch (error) {
            console.error('Error signing in:', error);
            if (error.response && error.response.data && error.response.data.error) {
                setLoginError(error.response.data.error);
            } else {
                setLoginError('An error occurred during sign-in');
            }
        }

    };

    const handleGoogleLoginSuccess = async (codeResponse) => {

        try {
          //  console.log("argresponse", codeResponse);
            const { access_token } = codeResponse;
            //   debugger;    
            const response = await axios.post(`${API_URL}/signin-with-google`,
                {
                    access_token: codeResponse.access_token,

                });

            if (response.data.token) {
                console.log('User signed up successfully');
                const token = response.data.token
               // console.log('token', response.data.token);
                localStorage.setItem('token', response.data.token);
                window.location.href = '/sp-landing'


            } else {
                console.error('Failed to sign up user:', response.statusText);
                // Handle signup failure
            }
        } catch (error) {
            console.error('Error signing up user:', error);
            // Handle error
        }
    };

    const login = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
    });

    //   const login = useGoogleLogin({

    //     onSuccess: async (codeResponse) => {
    //       console.log(codeResponse);
    //       try {
    //         console.log("Hiiiiii");
    //         const { access_token } = codeResponse;

    //         // Fetch user information from Google API using the access token
    //         const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
    //           headers: {
    //             Authorization: `Bearer ${access_token}`,
    //           },
    //         });

    //         if (!userInfoResponse.ok) {
    //           throw new Error('Failed to fetch user information from Google API');
    //         }

    //         // Parse the response to get user information
    //         const userInfo = await userInfoResponse.json();

    //         // Extract name and email from userInfo
    //         const { name, email } = userInfo;

    //         // Now you have the user's name and email, you can use them as needed
    //         console.log('User Name:', name);
    //         console.log('User Email:', email);

    //         // Redirect to another page after successful login
    //        // window.location.href = '/addskills';
    //       } catch (error) {
    //         console.error('Error handling Google login success:', error);
    //         // Handle error
    //       }
    //     },
    //     // Make sure to define handleGoogleLoginFailure if needed
    //   });


    return (
        <Stack direction={{ xs: "column", sm: "row", md: "row" }} height='100vh' spacing={2} justifyContent="space-between">
            <Box flex={2} p={{ xs: '5%', sm: '5%', md: '5%' }} sx={{
                backgroundImage: `url(${mainBg})`, height: 'auto',
                backgroundSize: 'cover', position: 'relative',
            }}>
                <Box sx={{ flex: 1 }}>
                    <TypographyComponent sx={{ fontSize: { xs: '26px', sm: '42px', md: '60px' }, color: Colors.white, fontWeight: 800 }}>Welcome</TypographyComponent>
                    <TypographyComponent sx={{ fontSize: { xs: '8px', sm: '10px', md: '14px' }, color: Colors.white, fontWeight: 400 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    </TypographyComponent>
                </Box>
                <Box sx={{
                    flex: 1,
                    width: { xs: '50%', sm: '100%', md: '100%' }, 
                    maxWidth: '100%',
                    // left:'50%',
                    position: { xs: 'absolute', sm: 'absolute', md: 'absolute' },
                    transform: { xs: 'translateY(30%) translateX(36%)', sm: 'translateX(20%)', md: 'translateX(20%)' },
                     top: {xs:'23%',sm:'30%',md:'40%'},height:{xs:'max(100%,190px)',sm: '300px', md: '400px',}
                }}>
                    <img src={mainImage} alt="Main" style={{ width: '100%'  }} />
                </Box>


            </Box>
            <Box flex={4} p={{ xs: '5%', sm: '10%', md: '10%' }} sx={{
                display: 'flex',m:'0px',pt:'5%',
                flexDirection: 'column', gap: { xs: '16px', md: '36px' },  height: 'auto',
            }}>
                <Box sx={{ gap: '8px' }}>
                    <TypographyComponent sx={{ fontSize: { xs: '20px', sm: '24px', md: '32px' }, color: '#5B5555', }}>Lets sign in </TypographyComponent>

                </Box>
                <Box sx={{ gap: { xs: '16px', md: '24px' }, display: 'flex', flexDirection: 'column', }}>

                    <Box sx={{ gap: '8px' }}>
                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}
                        >
                            Email*</TypographyComponent>
                        <TextFieldComponent placeholder="Enter your email id"
                            error={!!emailError}
                            onChange={handleEmailChange}>
                        </TextFieldComponent>
                        {emailError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{emailError}</TypographyComponent>}
                        {/* {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: '#D74039' }}>{passwordError}</TypographyComponent>} */}
                    </Box>
                    <Box >
                        <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70 }}
                        >
                            Password*</TypographyComponent>
                        <TextFieldComponent placeholder="Enter your password"
                            onChange={handlePasswordChange}
                            error={!!passwordError}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            color="primary"
                                        >
                                            {showPassword ? (
                                                <VisibilityOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                            ) : (
                                                <VisibilityOffOutlinedIcon sx={{ color: Colors.grey_60 }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        ></TextFieldComponent>
                        {passwordError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{passwordError}</TypographyComponent>}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                                <Checkbox
                                    checked={isCheckedSignIn}
                                    onChange={(event) => handleCheckboxChange(event)}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            width: '19.8px', // Adjusted width of the tick icon
                                            height: '19px', // Adjusted height of the tick icon
                                        },
                                        '&.Mui-checked': {
                                            '& .MuiIconButton-label': {
                                                backgroundColor: 'white', // Change the background color of the checkbox when checked
                                                borderRadius: '4px', // Add border radius to make it square
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: Colors.primary, // Change the color of the tick when checked
                                            },
                                        },
                                    }}/>
                                <TypographyComponent sx={{ fontSize: '14px', color: Colors.black_70, }}>
                                    Remember me
                                </TypographyComponent>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'right', width: '100%', justifyContent: 'right' }}>
                                <ButtonComponent variant="text" sx={{
                                    justifyContent: 'flex-end',
                                    fontSize: '14px', fontWeight: 400, color: Colors.black_70, fontFamily: 'Roboto', lineHeight: '16.8px',
                                    textTransform: 'none', background: 'none', textDecoration: 'underline', textUnderlineOffset: '5px',
                                    '&:hover': { background: 'none', textDecoration: 'underline', textUnderlineOffset: '5px',color: Colors.black_80 }
                                }}
                                    onClick={handleForgotPassword}
                                >Forgot password?
                                </ButtonComponent>
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',gap:'16px'}}>
                    <Box sx={{ gap: { xs: '16px', md: '24px' }, display: 'flex', flexDirection: 'column', }}>
                        <ButtonComponent onClick={handleSignInClick}>Sign in</ButtonComponent>
                        {loginError && <TypographyComponent sx={{ fontSize: '12px', color: Colors.error }}>{loginError}</TypographyComponent>}
                        <Button onClick={() => login()} variant="contained" size="large" sx={{
                            width: '100%', height: { md:'48px' , sm: '48px', xs: '38px'  }, color: Colors.black_70, borderRadius: '28px', border: '1px solid #C5C5C5',
                            backgroundColor: '#fff', textTransform: 'none', boxShadow: 'none',
                            '&:hover': { color: Colors.black_70, background: Colors.grey_20, boxShadow: 'none', },
                        }} startIcon={<img src={GoogleIcon} alt="Google Logo"
                            style={{ width: '24px', height: '24px',}} />}>Sign in with google

                        </Button>
                    </Box>
                    <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <TypographyComponent sx={{ fontSize: '16px', color: Colors.black_80, }}>Don’t have an account?</TypographyComponent>
                        <TypographyComponent sx={{ fontSize: '16px', color: Colors.link, cursor: 'pointer',fontWeight:600, '&:hover': { color: '#00669F',fontWeight:600}}}
                            onClick={handleSignUpClick}> Sign up</TypographyComponent>

                    </Box>
                    </Box>
                </Box>
                
            </Box>

        </Stack>
        
    )

}
export default SignIn;