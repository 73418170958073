
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SignUp from './Pages/SignUp';
import TermsAndCondition from './Pages/SpTermsAndCondition';
import SignIn from './Pages/SignIn';
import SpLanding from './Pages/SpLanding';
import AddLocation from './Pages/AddLocation';
import SpProfileForm from './Pages/SpProfileForm';
import AddSkills from './Pages/AddSkills';
import SpBusinessCardFront1 from './Components/SpBusinessCardFront1';
import SpBusinessCardFront2 from './Components/SpBusinessCardFront2';
import ChangePassword from './Pages/ChangePassword';
import SpSkills from './Pages/SpSkill';
import PasswordRecoverySendMail from './Pages/PasswordRecoverySendMail';
import PasswordRecovery from './Pages/PasswordRecovery';
import UserLanding from './Pages/UserLanding';
import ServiceData from './Pages/ServiceData';
import ProfileData from './Pages/ProfileData';
import SearchData from './Pages/SearchData';
import SpProfile from './Pages/SpProfile';
import TestPasswordRecovery from './Pages/test';
import { Navbar } from './Components/Navbar';
import SpMessageMobile from './Pages/SpMessageMobile';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import SearchDataNew from './Pages/SearchDataNew';
import CookiePolicy from './Pages/CookiePolicy';
import SpAboutUs from './Pages/SpAboutUs';
import SpContactUs from './Pages/SpContactUs';
import SignUpWithPhone from './Pages/SignUpWithPhone';
import SignInWithPhone from './Pages/SignInWithPhone';
import TermsAndConditionsOfContact from './Components/TermsAndConditionsOfContact';
import Disclaimer from './Pages/Disclaimer';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfUse from './Pages/TermsAndCondition';
import DisclaimerSp from './Pages/DisclaimerSp';
import PrivacyPolicySp from './Pages/PrivacyPolicySp';
import TermsOfUseSp from './Pages/TermsAndConditionSp';
import StepperPhase2 from './Components/StepperPhase2';
import AddServicePhase2 from './Pages/AddServicePhase2';
import SpLandingPhase2 from './Pages/SpLandingPhase2';
import AddLocationPhase2 from './Pages/AddLocationPhase2';
import Phase2SpProfileForm from './Pages/Phase2SpProfileForm';
import AddService from './Pages/AddService';
import AdminLanding from './Pages/AdminLanding';
import AdminCategory from './Components/AdminCategory';
import AdminPanel from './Pages/AdminPanel';
import AdminTable from './Components/AdminTable';
import AdminLogin from './Pages/AdminLogin';
import ProtectedRoute from './Components/ProtectedRoute';
import AdminCat from './Components/AdminCat';
import CommunityUserView from './Pages/CommunityUserView';
import CommunityData from './Components/CommunityData';
import CommunityUser from './Pages/CommunityUser';
import ProtectedRouteUser from './Components/ProtectedRouteUser';
import PrivateRoute from './Components/PrivateRoute';
import AssossiateRoute from './Components/AssossiateRoute';
import AssociateLogin from './Pages/AssociateLogin';
import AssociateLanding from './Pages/AssociateLanding';
import AssociateProfile from './Pages/AssociateProfile';
import AssociateLocation from './Pages/AssociateLocation';
import AssociateService from './Pages/AssociateService';




function App() {

  const token = localStorage.getItem('token');
  console.log(localStorage.getItem('token'))
  return (
    <div style={{ height: '100%' }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UserLanding />} />
          {/* <Route path="/signup" element={<SignUp/>} />  */}
          <Route path="/signup" element={<SignUpWithPhone />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          {/* <Route path="/signin" element={<SignIn/>} /> */}
          <Route path="/signin" element={<SignInWithPhone />} />
          <Route path="/password-recovery-send-mail" element={<PasswordRecoverySendMail />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
          {/* <Route path="/sp-landing" element={<ProtectedRouteUser>
            <SpLanding />
          </ProtectedRouteUser>
          } /> */}
          <Route path="/sp-landing" element={<PrivateRoute><SpLanding /></PrivateRoute>} />
          <Route path="/profile-form/:businessCardEdit" element={<PrivateRoute><SpProfileForm /></PrivateRoute>} />
          <Route path="/add-location" element={<PrivateRoute><AddLocation /></PrivateRoute>} />
          <Route path="/add-skills" element={<PrivateRoute><AddSkills /></PrivateRoute>} />
          <Route path="/add-service" element={<PrivateRoute><AddService /></PrivateRoute>} />

          <Route path="/sp-skills" element={<SpSkills />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/front1" element={<SpBusinessCardFront1 />} />
          <Route path="/front2" element={<SpBusinessCardFront2 />} />
          <Route path="/home" element={<UserLanding />} />
          <Route path="/profile-data" element={<ProfileData />} />
          <Route path="/search-data" element={<SearchData />} />
          <Route path="/sp-profile/:spCode" element={<SpProfile />} />
          <Route path="/service-data" element={<ServiceData />} />
          {/* <Route path="/test" element={<TestPasswordRecovery/>} /> */}
          <Route path="/nav" element={<Navbar />} />
          <Route path="/message" element={<SpMessageMobile />} />
          <Route path="/sp2" element={<SpBusinessCardFront2 />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/search-data-new" element={<SearchDataNew />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/spabout-us" element={<SpAboutUs />} />
          <Route path="/spcontact-us" element={<SpContactUs />} />
          {/* <Route path="/signup-with-phone" element={<SignUpWithPhone/>} /> */}
          {/* <Route path="/signin-with-phone" element={<SignInWithPhone/>} /> */}
          <Route path="/contact-terms-and-conditions" element={<TermsAndConditionsOfContact />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/sp-disclaimer" element={<DisclaimerSp />} />
          <Route path="/sp-privacy-policy" element={<PrivacyPolicySp />} />
          <Route path="/sp-terms-of-use" element={<TermsOfUseSp />} />

          {/* <Route path="/community-pages" element={<CommunityUser/>} /> */}


          <Route path="/stepper" element={<StepperPhase2 />} />
          <Route path="/add-service2" element={<AddServicePhase2 />} />
          <Route path="/sp-landing2" element={<SpLandingPhase2 />} />
          <Route path="/add-location2" element={<AddLocationPhase2 />} />
          <Route path="/sp-profile-form-p2" element={<Phase2SpProfileForm />} />

          <Route path="/admin_login" element={<AdminLogin />} />
          <Route path="/admin_category" element={<AdminCategory />} />
          <Route path="/admin_table" element={<AdminTable />} />
          <Route path="/admin_panel" element={<ProtectedRoute>
            <AdminPanel />
          </ProtectedRoute>
          } />


          <Route path="/associate_login" element={<AssociateLogin/>} />
          <Route path="/associate_landing" element={<AssossiateRoute><AssociateLanding /></AssossiateRoute>} />
          <Route path="/associate_profile" element={<AssossiateRoute><AssociateProfile /></AssossiateRoute>} />
          <Route path="/associate_location" element={<AssossiateRoute><AssociateLocation /></AssossiateRoute>} />
          <Route path="/associate_service" element={<AssossiateRoute><AssociateService /></AssossiateRoute>} /> 

          <Route path="*" element={<Navigate to="/" />} />

          {/* <Route path="/community_user_view" element={<CommunityUserView/>} />
      <Route path="/community_data" element={<CommunityData/>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


