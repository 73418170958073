import React from 'react';
import { Typography, List, ListItem, ListItemText, Box, Grid } from '@mui/material';
import Colors from '../Components/Colors';
//import UserFooter from '../Components/UserFooter';
//import UserHeader from '../Components/UserHeader';
import SpHeader from '../Components/SpHeader';
import SpFooter from '../Components/SpFooter';
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from './Constants';

function TermsOfUseSp() {
    const [isCompleteProfile, setIsCompleteProfile] = useState(true);   
    const [serviceProviderDtls, setServiceProviderDtls] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  

    async function fetchServiceProviderDetails() {
            const token = localStorage.getItem('token');
            try {
              const response = await axios.post(`${API_URL}/sp-landing-details`, { token });
            
              if (response.data.success) {
            
                console.log("service-details", response.data.data.serviceProvider.service_details)
             
                const imgurl = ` https://mynewsbuckets.s3.ca-central-1.amazonaws.com/${response.data.data.serviceProvider.profile_image}`
                setServiceProviderDtls(response.data.data.serviceProvider)
                setServiceDetails(response.data.data.serviceProvider.service_details)
                setMessageDetails(
                response.data.data.serviceProvider.message_details.sort((a, b) => b.message_id - a.message_id)
              );
                if (response.data.data.serviceProvider.service_details.length === 0) {
                  setIsCompleteProfile(false)
                }
                const unreadCount = response.data.data.serviceProvider.message_details.reduce((acc, message) => {
                  if (message.read === 'Unread') {
                    return acc + 1;
                  }
                  return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);      
             
              } else {
                console.error('Error:', response.data.error);
              }
        
        
        
            } catch (error) {
              console.error('Error:', error.message);
            }
          }
          useEffect(() => {
            fetchServiceProviderDetails();
          }, [messageDetails])


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>
            
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
          
            <SpHeader isCompleteProfile = {isCompleteProfile} 
                                serviceProviderDtls={serviceProviderDtls}
                                unreadMessageCount={unreadMessageCount} serviceDetails={serviceDetails}></SpHeader>
        </Box>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
         alignItems: 'center',paddingTop: '2.5%', paddingBottom: '2.5%', }}>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', background: Colors.grey_20
            }}>
                <Box sx={{
                    width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left',
                    maxWidth: { md: '85%', xs: '90%' }, paddingTop: '2.5%', paddingBottom: '2.5%', maxHeight: '90%', gap: '24px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '26px', xs: '22px' }, lineHeight: '24px', fontWeight: 700,
                        color: Colors.black_80,
                    }}>
                        Terms And Conditions
                    </Typography>

                    <Typography sx={{
                        fontFamily: 'Roboto', fontSize: { md: '24px', xs: '20px' }, lineHeight: '24px',
                        fontWeight: 700, color: Colors.black_80,
                    }}>
                        Last Updated: May 15, 2024
                    </Typography>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'left',
                        gap: '16px', justifyContent: 'center'
                    }}>
                       
                       
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80, gap: '16px'
                        }}>
                            Welcome to BroHandy! These terms and conditions outline the rules and regulations for the use of BroHandy's Website, located at <a href="https://www.brohandy.com">www.brohandy.com</a> 
                           
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80, gap: '16px'
                        }}>
                          By accessing this website we assume you accept these terms and conditions. Do not continue to use BroHandy if you do not agree to take all of the terms and conditions stated on this page.
                           
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                           Definitions
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '28px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                            <strong>Service:</strong> The service provided by BroHandy as described in the relative terms (if available) and on this platform.<br />
                            <strong>Public user:</strong> Any individual or entity that uses our website to search for service providers.<br />
                            <strong>Service provider:</strong> Any individual or entity that uses our website to post information about their services.
                        </Typography>
                       
                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            License
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             Unless otherwise stated, BroHandy and/or its licensors own the intellectual property rights for all material on BroHandy. All intellectual property rights are reserved. You may access this from BroHandy for your own personal use subjected to restrictions set in these terms and conditions.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            User responsibilities
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                             <strong>Public users: </strong> You are responsible for ensuring that the details you provide are truthful and accurate. You agree not to use the website for any unlawful activity.<br />
                             <strong>Service providers: </strong> You must ensure that the profiles and services you provide comply with applicable laws and standards. You are responsible for the accuracy and truthfulness of the information provided. It is crucial to maintain the confidentiality of your account details; you are responsible for all activities under your account.<br />
                            
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Privacy policy
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                           Please review our Privacy Policy, which also governs your use of BroHandy, to understand our practices.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Prohibited activities
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                           You may not use BroHandy for any unlawful or prohibited activities, including but not limited to fraud, abuse, or to transmit or store illegal material.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Disclaimer of warranties
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                           BroHandy does not guarantee the accuracy, completeness, or usefulness of any information on the platform nor does it endorse any service providers. The platform does not provide any warranties regarding the quality, safety, or legality of the services advertised. Please see disclaimer section for more details.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Limitation of liability
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                           BroHandy shall not be liable for any damages or loss resulting from your use of the platform. This limitation of liability applies to damages of any kind, including direct, indirect, incidental, or consequential damages. Please see disclaimer section for more details.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Termination
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                          We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Links to other websites
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                          Our Service may contain links to third-party web sites or services that are not owned or controlled by BroHandy.<br />
                          BroHandy has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that BroHandy shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Modifications to terms
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                         We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Governing law
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: '16px', lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80,
                        }}>
                        These terms shall be governed and construed in accordance with the laws of Your Country & State], without regard to its conflict of law provisions.
                        </Typography>


                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize: { md: '20px', xs: '16px' }, lineHeight: '24px',
                            fontWeight: 600, color: Colors.black_80, textAlign: 'left'
                        }}>
                            Contact us
                        </Typography>

                        <Typography sx={{
                            fontFamily: 'Roboto', fontSize:  '16px' , lineHeight: '24px',
                            fontWeight: 400, color: Colors.black_80, textAlign: 'left'
                        }}>
                          If you have any questions about these Terms, please contact us. <a href="mailto:contact@brohandy.com">contact@brohandy.com</a>.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
        <SpFooter></SpFooter>
        </Grid>
    );
}

export default TermsOfUseSp;
